import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UrlContext from "../context/Url.context";

function MemberItem(props) {
  const { orgMember, folder, setCancelIdx } = props;
  const urlContext = useContext(UrlContext);
  const navigate = useNavigate();

  const itemClickHandler = () => {
    navigate(`/${folder}/otherprofile/sole/${orgMember.userIdx}`, {
      state: {},
    });
  };

  const defaultImgPath = "/assets/images/photos/img-p-sole.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (orgMember) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: orgMember && orgMember.userIdx,
            imageStatus: "U",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  //
  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <div className="card card--style-thumb">
      <div
        className="card__thumb card__thumb--round-corners-full"
        onClick={itemClickHandler}
        style={{ cursor: "pointer" }}
      >
        <img src={imgPath} alt="" onError={changeDefaultImg} />
      </div>
      <Link to={`/${folder}/otherprofile/sole/${orgMember.userIdx}`}>
        <h4 className="card__username text-aline-center">
          {orgMember && orgMember.nickname}
        </h4>
      </Link>
      {orgMember && orgMember.userIdx !== sessionStorage.idx ? (
        <div className="tag_user">일반</div>
      ) : (
        <div
          className="button button--small-full button--gray open-popup"
          data-popup="conform_secession"
          onClick={() => setCancelIdx(orgMember && orgMember.idx)}
        >
          회원 탈퇴
        </div>
      )}
    </div>
  );
}

export default MemberItem;
