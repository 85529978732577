import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import axios from "axios";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";

function DetailA(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const detailList = location.state && location.state.detailList;
  const modi = location.state && location.state.modi;

  const [okonline, setOkonline] = useState({
    title: detailList && detailList.title,
    area: detailList && detailList.area,
    content: detailList && detailList.content,
    openStatus: detailList && detailList.openStatus,
  });

  const [expert, setExpert] = useState({});
  const [user, setUser] = useState({});
  const handleChangeForm = (e) => {
    setOkonline({
      ...okonline,
      [e.target.name]: e.target.value,
    });
  };

  const conformClick = (e) => {
    if (expert === undefined) {
      alert(
        `${okonline.area} 담당 전문가가 지정되지 않아 온라인 상담 문의가 불가능합니다.`
      );
      return;
    }

    if (okonline.title === "" || okonline.title === undefined) {
      alert("제목을 입력해주세요.");
      return;
    }

    if (okonline.area === "" || okonline.area === undefined) {
      alert("분야를 선택해주세요.");
      return;
    }

    if (okonline.content === "" || okonline.content === undefined) {
      alert("상세 내용을 입력해주세요.");
      return;
    }

    if (okonline.openStatus === "" || okonline.openStatus === undefined) {
      alert("공개 여부를 선택해주세요.");
      return;
    }

    let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation`;
    let data = {
      userIdx: sessionStorage.idx,
      title: okonline.title,
      area: okonline.area,
      expertIdx: expert.idx,
      content: okonline.content,
      openStatus: okonline.openStatus,
      consultationStatus: "W",
      isDeleted: "N",
      createdUid: sessionStorage.idx,
    };

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (modi && modi) {
      let modiurl = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/${detailList.idx}`;
      axios
        .put(modiurl, data, config)
        .then((res) => {
          navigate(`/sole/okonline/detail`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(url, data, config)
        .then((res) => {
          const data = res.data.head;

          if (data.resultCode === "00") {
            navigate(`/sole/okonline/detail`);
          } else {
            alert("온라인 상담 등록에 오류가 발생하였습니다.");
            console.log(data.resultMsg);
          }
        })
        .catch((err) => {
          alert("온라인 상담 등록에 오류가 발생하였습니다.");
          console.log(err.response);
        });
    }
  };

  const expertTagView = () => {
    switch (okonline.area) {
      case "법률":
        return "법률";
      case "세무":
        return "세무";
      case "법무":
        return "법무";
      case "건축":
        return "건축";
      case "노무":
        return "노무";
      default:
        return "법률";
    }
  };

  const tagView = () => {
    switch (okonline.area) {
      case "법률":
        return "law";
      case "세무":
        return "tax";
      case "법무":
        return "legal";
      case "건축":
        return "cons";
      case "노무":
        return "labor";
      default:
        return "law";
    }
  };

  const expertView = () => {
    if (okonline.area !== "" && okonline.area !== undefined) {
      if (expert) {
        return (
          <div className="user-profile mb-20">
            <div className="user-profile__thumb">
              <img src="/assets/images/photos/img-p-expert.jpg" alt="" />
            </div>
            <div className="item-center">
              <a className={`caption__category tag_expert_${tagView()}`}>
                {expertTagView()} 전문가
              </a>
            </div>
            <div className="user-profile__name">{expert && expert.name}</div>
            <div className="user-profile__info">
              소개글 : {expert && expert.bio}
            </div>
            <div className="buttons buttons--centered">
              <div className="info-box">
                <span>전화 상담</span> {expert && expert.phone_consultation_num}
                건
              </div>
              <div className="info-box">
                <span>온라인 상담</span>{" "}
                {expert && expert.online_consultation_num}건
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="user-profile mb-20">
            <div className="user-profile__name point-red">
              죄송합니다. 현재 {okonline.area} 담당 전문가가 지정되지
              않았습니다.
            </div>
          </div>
        );
      }
    }
  };

  const [hideContent, setHideContent] = useState(true);

  // 전문가 프로필 조회s
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/expert/profile/selected_uid?area=${okonline.area}`;

        axios.get(URL).then((res) => {
          const resJson = res.data.data[0];
          setExpert(resJson);
        });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [okonline.area]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="문의하기" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="fieldsets">
              <div className="form">
                <form className="pt-40" id="ContactForm">
                  <div className="form__row">
                    <h4>제목</h4>
                    <input
                      type="text"
                      name="title"
                      value={okonline.title}
                      onChange={handleChangeForm}
                      className="form__input required"
                      placeholder="제목"
                    />
                  </div>
                  <div className="form__row mt-20">
                    <h4>상담분야</h4>
                    <div className="form__select">
                      <select
                        name="area"
                        className="required"
                        value={okonline.area}
                        onChange={handleChangeForm}
                      >
                        <option value="" disabled selected>
                          선택
                        </option>
                        <option value="법률">법률</option>
                        <option value="세무">세무</option>
                        <option value="법무">법무</option>
                        <option value="건축">건축</option>
                        <option value="노무">노무</option>
                      </select>
                    </div>
                  </div>
                  {expertView()}
                  <div className="form__row mt-20">
                    <h4>상세</h4>
                    <textarea
                      name="content"
                      className="form__textarea required"
                      placeholder="사실관계 및 질문의 요지를 1,000자 이내로 입력해주세요."
                      value={okonline.content}
                      onChange={handleChangeForm}
                    />
                  </div>
                  <div className="form__row mt-20">
                    <h4>공개여부</h4>
                    <div className="form__select">
                      <select
                        name="openStatus"
                        className="required"
                        value={okonline.openStatus}
                        onChange={handleChangeForm}
                      >
                        <option value disabled selected>
                          선택
                        </option>
                        <option value="O">공개</option>
                        <option value="C">비공개</option>
                      </select>
                    </div>
                  </div>
                  <h4 className="mt-48">온라인 상담 답변제한 질의유형</h4>
                  <ul className="custom-listing custom-listing--checked mb-20">
                    <li>
                      행정기관, 공법인, 관리능력 또는 자력이 충분한 전문성을
                      가지고 있는 단체나 기업 등의 질의
                    </li>
                    <li>
                      행정관청, 수사기관, 법원 등에 계속 중인 사안의 처리결과
                      내지 양형을 예상하는 질의
                    </li>
                    <li>
                      단순한 실무상 절차에 관련된 사항으로서 담당기관에 문의함이
                      타당한 질의
                    </li>
                    <li>
                      내용이 불분명하거나 질문의 정확한 취지가 드러나지 않는
                      질의
                    </li>
                    <li>단순한 탄원성 질의</li>
                    <li>
                      동일인이 동일한 내용으로 2회 이상 중복하여 하는
                      질의(동일인은 서로 다른 내용으로 각 분야별 월 2회까지
                      온라인 상담이 가능합니다.)
                    </li>
                    <li>
                      구체적 사건의 해결이 아닌 단순한 학리적 해석을 구하는 질의
                    </li>
                  </ul>
                  <div className="form__row mt-40">
                    <input
                      type="button"
                      name="submit"
                      data-popup="conform_cancle"
                      className="form__submit button button--gray button--50 open-popup"
                      id="submit"
                      value="취소"
                    />
                    <input
                      type="button"
                      name="submit"
                      className="form__submit button button--main button--50 open-popup"
                      data-popup="conform_save"
                      id="submit"
                      value="저장"
                    />
                  </div>
                  <div className="space-h-100" />
                </form>
              </div>
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        popupType="save"
        text="정말 저장하시겠습니까?"
        conformClick={conformClick}
      />
      <ConformPopup
        popupType="cancle"
        text="정말 취소하시겠습니까?"
        conformClick={() => navigate(-1)}
      />
      {/* <Navigationbar/> */}
    </Fragment>
  );
}

export default DetailA;
