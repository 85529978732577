import React from "react";

const SharePopup = ({ activityIdx, beneType, partType }) => {
  const onClickShareBtn = (type) => {
    let url = "";

    if (type === "P") {
      url = `https://volunteer.seocho.go.kr/org/activity/detailbene/${activityIdx}`;
    } else {
      url = `https://volunteer.seocho.go.kr/org/activity/detailpart/${activityIdx}`;
    }

    if (!document.queryCommandSupported("copy")) {
      return alert("공유하기가 지원되지 않는 브라우저 입니다.");
    }

    const textarea = document.createElement("textarea");
    textarea.value = url;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = "fixed";

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    document.execCommand("copy");
    document.body.removeChild(textarea);

    alert("링크가 복사되었습니다.");
  };

  return (
    <div id={`popup--conform_share`}>
      <div
        className={`popup popup--centered popup--shadow popup--conform_share`}
      >
        <div className="popup__close">
          <a className="close-popup" data-popup={`conform_share`}>
            <img src="/assets/images/icons/blue/close.svg" alt="" />
          </a>
        </div>
        <div className="popup__icon">
          <img src="/assets/images/icons/blue/alert.svg" alt="" />
        </div>
        <h2 className="popup__title">공유하기</h2>
        <p className="popup__text">공유하실 유형을 선택해주세요.</p>
        <div className="form__row mt-40">
          <input
            data-popup={`conform_share`}
            style={{ cursor: "pointer" }}
            type="submit"
            name="submit"
            className={
              partType === "X"
                ? "form__submit button button--gray button--full close-popup"
                : "form__submit button button--gray button--50 close-popup"
            }
            id="submit"
            value="수요자에게 공유"
            onClick={() => onClickShareBtn("B")}
          />
          <input
            data-popup={`conform_share`}
            style={{ cursor: "pointer" }}
            type="submit"
            name="submit"
            className={
              beneType === "X"
                ? "form__submit button button--main button--full close-popup"
                : "form__submit button button--main button--50 close-popup"
            }
            id="submit"
            value="활동자에게 공유"
            onClick={() => onClickShareBtn("P")}
          />
        </div>
      </div>
    </div>
  );
};

export default SharePopup;
