import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router";
import ChooseGroupList from "../../../components/ChooseGroupList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function ChooseMemberGroup(props) {
  const location = useLocation();

  const activityIdx = location.state && location.state.activityIdx;
  const activityScheduleIdx =
    location.state && location.state.activityScheduleIdx;
  const actRequestIdx = location.state && location.state.actRequestIdx;
  const page = location.state && location.state.page;
  const isMyGroup =
    location.state && location.state.isMyGroup
      ? location.state.isMyGroup
      : false;

  const [groupData, setGroupData] = useState([]);

  const [hideContent, setHideContent] = useState(true);

  // 그룹별 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        axios
          .get(
            `${process.env.REACT_APP_USER_SERVICE_API}/group_list/total/${sessionStorage.idx}`
          )
          .then((res) => {
            const resGroupData = res.data.data;
            // console.log("그룹별 조회");
            // console.log(resGroupData);

            setGroupData(resGroupData);
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동자 배치하기" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar pt-40 mb-24">
              <h1 className="page__title">내 회원 그룹({groupData.length})</h1>
            </div>
            <div className="cart cart--page">
              <div
                className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal"
                id="item1"
              >
                {groupData &&
                  groupData.map((group, index) => (
                    <ChooseGroupList
                      key={index}
                      group={group}
                      page={page}
                      activityIdx={activityIdx}
                      activityScheduleIdx={activityScheduleIdx}
                      actRequestIdx={actRequestIdx}
                      isMyGroup={isMyGroup}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default ChooseMemberGroup;
