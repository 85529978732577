import axios from "axios";
import React, { useEffect, useState, useCallback, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";

import BeneRequestList from "../../../components/BeneRequestList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";
import ConformPopup from "../../common/popup/ConformPopup";

function ListRequest(props) {
  const { actIdx } = useParams();
  const navigate = useNavigate();

  const [beneList, setBeneList] = useState(null);
  const [totalRows, setTotalRows] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);

  const getBeneList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/request/${actIdx}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: pageNumber,
          count: 5,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            activityIdx: actIdx,
            activityDate: data[i].activityDate,
            activityTime: data[i].activityTime,
            scheduleIdx: data[i].id,
            status: data[i].helpStatus,
            beneType: data[i].beneType,
            beneOrgTitle: Object.keys(data[i]).includes("beneOrgTitle")
              ? data[i].beneOrgTitle
              : null,
            orgIdx: data[i].helpUserIdx,
            joinNum: data[i].joinNum,
            recruitNum: data[i].recruitNum,
            helpIdx: data[i].helpIdx,
          });
        }

        if (pageNumber === 1) {
          setBeneList(ary);
        } else {
          setBeneList(beneList.concat(ary));
        }

        setTotalRows(totalRows);
      }
    } catch (e) {
      alert("도움 요청 목록 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [actIdx, pageNumber]);

  const [updateScheduleInfo, setUpdateScheduleInfo] = useState({
    helpIdx: 0,
    status: "",
    scheduleIdx: 0,
    hasPartcipants: false,
  });

  const updateSelectedScheduleInfo = (idx, status, sIdx, hasPartcipants) => {
    setUpdateScheduleInfo({
      helpIdx: idx,
      status: status,
      scheduleIdx: sIdx,
      hasPartcipants: hasPartcipants,
    });
  };

  const updateRequestScheduleStatus = async () => {
    let data = new Object();

    data.activityIdx = actIdx;
    data.activityScheduleIdx = updateScheduleInfo.helpIdx;
    data.updatedUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/request/${updateScheduleInfo.status}`;

    try {
      const response = await axios.put(url, data);

      setPageNumber(1);
      getBeneList();

      // if (response.status === 201) {
      //   setPageNumber(1);
      //   getBeneList();
      // }
    } catch (e) {
      // alert("스케줄 상태 변경 중, 오류가 발생하였습니다.");
      console.log(e);

      setPageNumber(1);
      getBeneList();
    }
  };

  const onClickUpdateStatusBtn = () => {
    if (
      updateScheduleInfo.status === "cancel-accept" &&
      updateScheduleInfo.hasPartcipants
    ) {
      alert(
        "해당일정에 활동자가 있습니다. 활동자 일정 제외 후 일정취소 해주세요."
      );

      navigate(
        `/org/profile/listactivity/${actIdx}/${updateScheduleInfo.scheduleIdx}`
      );
      return;
    }

    updateRequestScheduleStatus();
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getBeneList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getBeneList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="도움 요청 목록" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar">
              <h2>도움 요청 목록 ({totalRows})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {beneList &&
                beneList.map((scheduleInfo) => (
                  <BeneRequestList
                    scheduleInfo={scheduleInfo}
                    updateSelectedScheduleInfo={updateSelectedScheduleInfo}
                    key={scheduleInfo.helpIdx}
                  />
                ))}
              {beneList && totalRows > beneList.length && (
                <div className="buttons buttons--centered mb-20">
                  <div
                    className="button button--more"
                    onClick={() =>
                      pageNumber * 5 < totalRows
                        ? setPageNumber(pageNumber + 1)
                        : null
                    }
                  >
                    더 보기
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        popupType="accept"
        conformClick={onClickUpdateStatusBtn}
        text="승인하시겠습니까?"
      />
      <ConformPopup
        popupType="dismissal"
        conformClick={onClickUpdateStatusBtn}
        text="반려하시겠습니까?"
      />
      <ConformPopup
        popupType="cancleA"
        conformClick={onClickUpdateStatusBtn}
        text="승인을 취소하시겠습니까?"
      />
      <ConformPopup
        popupType="cancleR"
        conformClick={onClickUpdateStatusBtn}
        text="반려을 취소하시겠습니까?"
      />
      <Navigationbar />
    </Fragment>
  );
}

export default ListRequest;
