const CheckLoginInfo = () => {
  const userIdx = window.sessionStorage.getItem("idx");

  return new Promise((resolve, reject) => {
    if (userIdx === undefined || userIdx === null) {
      alert("로그인이 필요한 페이지 입니다.");
      window.location.href = "/";
      // resolve(false);
      reject(new Error("올바르지 못한 경로입니다."));
    } else {
      resolve(true);
    }
  });
};

export default CheckLoginInfo;
