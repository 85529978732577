import React, {
    useEffect,
    useContext,
    useState,
    Fragment,
    useCallback,
} from "react";
import {Link, useNavigate} from "react-router-dom";
import ActivityItem from "../../../components/ActivityItem";
import ActivityMemberList from "../../../components/ActivityMemberList";
import GroupList from "../../../components/GroupList";
import GroupMemberItem from "../../../components/GroupMemberItem";
import Navigation from "../../../components/Navigation";
import Panel from "../../../components/Panel";
import GroupContext from "../../../context/Group.context";
import axios from "axios";
import ConformPopup from "../../common/popup/ConformPopup";
import OrgProfile from "../../../components/OrgProfile";
import CheckLoginInfo from "../../../services/login-validation";

function Org(props) {
    const navigate = useNavigate();
    const groupContext = useContext(GroupContext);

    // DB 데이터
    const [orgMemberData, setOrgMemberData] = useState([]);
    const [reqOrgMemberData, setReqOrgMemberData] = useState([]);
    const [orgData, setOrgData] = useState();
    const [activityData, setActivityData] = useState([]);
    const [groupData, setGroupData] = useState([]);

    const [helpActData, setHelpActData] = useState([]);
    const [partActData, setPartActData] = useState([]);

    const [requestActCount, setRequestActCount] = useState(0);
    const [helpActCount, setHelpActCount] = useState(0);
    const [partRequestActCount, setPartRequestActCount] = useState(0);

    const [helpScheduleCnt, setHelpScheduleCnt] = useState(null);

    // 더보기 페이징
    const [activityPage, setActivitiyPage] = useState(1);
    const [orgMemberPage, setOrgMemberPage] = useState(1);
    const [reqOrgMemberPage, setReqOrgMemberPage] = useState(1);

    const [helpActPage, setHelpActPage] = useState(1);
    const [partActPage, setPartActPage] = useState(1);

    const offsetActivityPage = 6;

    const offsetOrgMemberPage = 8;

    const getOrgData = useCallback(() => {
        // 기관 상세 조회
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/org/${sessionStorage.idx}`;
        axios
            .get(url)
            .then((res) => {
                setOrgData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // 소속 회원 조회
    const getOrgMemberData = useCallback(() => {
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${sessionStorage.idx}`;
        axios.get(url).then((res) => {
            // const orgMemberArray = [];
            const {data, totalRows} = res.data;
            const orgMemberList = [];

            for (let i = 0; i < data.length; i++) {
                orgMemberList.push({
                    img: Object.keys(data[i]).includes("img")
                        ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${data[i].img}`
                        : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`,
                    userIdx: data[i].userIdx,
                    nickname: data[i].nickname,
                    idx: data[i].idx,
                    name: data[i].name,
                    status: data[i].status,
                });
            }

            setOrgMemberData(orgMemberList);
        });
    }, []);

    // 소속 신청한 회원 조회
    const getReqOrgMemberData = useCallback(() => {
        const url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/status/${sessionStorage.idx}?status=W`;
        axios
            .get(url)
            .then((res) => {
                const orgMemberArray = [];
                const orgMemberList = res.data.data;

                axios
                    .all(
                        orgMemberList.map((orgMember) => {
                            return axios.get(
                                `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
                            );
                        })
                    )
                    .then((res) => {
                        res.map((org, index) => {
                            orgMemberArray.push(
                                Object.assign(org.data, orgMemberList[index])
                            );
                        });

                        setReqOrgMemberData(orgMemberArray);
                    })
                    .catch((err) => {
                        console.log(err.response);
                    });
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    // 활동 조회
    const getActivityData = useCallback(() => {
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/activity`;
        axios
            .get(url)
            .then((res) => {
                const json = res.data.data;

                // 활동의 기관명 추가
                json.forEach((item) => {
                    axios
                        .get(`${process.env.REACT_APP_USER_SERVICE_API}/org/${item.orgIdx}`)
                        .then((res) => {
                            item.orgTitle = res.data.orgTitle;

                            setActivityData(
                                json &&
                                json.filter((json) => json.orgIdx === sessionStorage.idx)
                            );
                        })
                        .catch((err) => {
                            console.log(err.response);
                        });
                });
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    // 그룹 조회
    const getGroupData = useCallback(() => {
        // axios URL
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/group_list/total/${sessionStorage.idx}`;
        axios
            .get(url)
            .then((res) => {
                setGroupData(res.data.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    // 도움 요청한 활동수 조회
    const getHelpActCount = useCallback(() => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/count/${sessionStorage.idx}`;
        axios
            .get(URL)
            .then((res) => {
                setHelpActCount(res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    // 도움 받은 활동 조회
    const getHelpActData = useCallback(() => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/request/done/${sessionStorage.idx}`;
        axios
            .get(URL)
            .then((res) => {
                const data = res.data.data;

                let ary = [];

                data.forEach((data) => {
                    ary.push({
                        idx: data.idx,
                        activityDate: data.activityDate,
                        address1: data.address1,
                        address2: data.address2,
                        createdAt: data.createdAt,
                        orgIdx: data.orgIdx,
                        orgTitle: data.orgTitle,
                        activityType: data.activityType,
                        title: data.title,
                        activityIdx: data.activityIdx,
                    });
                });

                setHelpActData(ary);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    // 참여요청 받은 수 조회
    const getRequestActCount = useCallback(() => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_request/list/count/${sessionStorage.idx}`;
        axios
            .get(URL)
            .then((res) => {
                setRequestActCount(res.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    // 참여한 활동 조회
    const getPartActData = useCallback(() => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/activity/participate/${sessionStorage.idx}`;
        axios
            .get(URL)
            .then((res) => {
                const data = res.data.data;

                let ary = [];

                data.forEach((data) => {
                    ary.push({
                        // idx: data.idx,
                        activityDate: data.activityDate,
                        address1: data.address1,
                        address2: data.address2,
                        helpUserIdx: data.helpUserIdx,
                        orgIdx: data.orgIdx,
                        orgTitle: data.orgTitle,
                        activityType: data.activityType,
                        title: data.activityTitle,
                        activityIdx: data.activityIdx,
                        content: data.content,
                    });
                });

                setPartActData(ary);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);

    // 도움 요청한 일정 cnt 조회
    const getHelpScheduleCnt = useCallback(async () => {
        const url = `${
            process.env.REACT_APP_USER_SERVICE_API
        }/request/help-schedule/cnt/org/${window.sessionStorage.getItem("idx")}`;

        try {
            const response = await axios.get(url);

            if (response.status === 200) {
                setHelpScheduleCnt(response.data);
            }
        } catch (e) {
            alert("도움 요청한 일정 수 조회 중, 오류가 발생하였습니다.");
            console.log(e);
            setHelpScheduleCnt(0);
        }
    }, []);

    const [hideContent, setHideContent] = useState(true);

    useEffect(() => {
        CheckLoginInfo().then(() => {
            setHideContent(false);
        });
    }, []);

    useEffect(() => {
        getOrgData();
        getOrgMemberData();
        getReqOrgMemberData();
        getActivityData();
        getGroupData();
        getHelpActCount();
        getHelpActData();
        getRequestActCount();
        getPartActData();
        getHelpScheduleCnt();
    }, [
        getActivityData,
        getGroupData,
        getHelpActCount,
        getHelpActData,
        getOrgData,
        getOrgMemberData,
        getPartActData,
        getReqOrgMemberData,
        getRequestActCount,
        getHelpScheduleCnt,
    ]);

    const addPage = (setPageFunc, currentPage) => {
        setPageFunc(currentPage + 1);
    };

    // 페이지 이동 Click Handler
    const addActClickHandler = () => {
        navigate(`/org/profileaddact/addact`, {state: {}});
    };

    const requestOperationClickHandler = () => {
        navigate(`/org/profile/requestoperation`, {state: {}});
    };

    const listActivityHistoryClickHandler = () => {
        navigate(`/org/profile/listactivityhistoryall`, {state: {}});
    };

    const addGroupClickHandler = () => {
        groupContext.clearGroup();
        navigate(`/org/profile/addgroup`, {state: {}});
    };

    const chatDGroupClickHandler = () => {
        navigate(`/org/chat/chatd/o2all`, {
            state: {
                orgId: window.sessionStorage.getItem("idx"),
                senderType: "o",
            },
        });
    };

    const userSearchClickHandler = () => {
        navigate(`/org/profile/usersearch`, {
            state: {
                search: "user",
            },
        });
    };

    const requestOperationConformListClickHandler = () => {
        navigate(`/org/profile/requestoperationconformlist`);
    };

    const requestToParticipateList = () => {
        navigate(`/org/profile/requesttoparticipatelist`);
    };

    const viewTagList = ["activityType", "isPrivate", "beneType", "partType"];
    const viewOnlyActType = ["activityType"];

    // 소속 신청한 회원 탭 삭제 및 승인 버튼 클릭된 orgMember idx
    const [selOrgMemberIdx, setSelOrgMemberIdx] = useState();

    // status => 신청(W)/일반(U)/관리자(A)/탈퇴(Q)/반려(N)
    const updateOrgMemberStatus = (orgMemberIdx, status) => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/status/${orgMemberIdx}?status=${status}`;
        axios
            .put(URL)
            .then((res) => {
                navigate(0);
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    // 소속 신청 승인
    const acceptOrgMember = () => {
        updateOrgMemberStatus(selOrgMemberIdx, "U");
    };

    // 소속 신청 반려
    const deleteOrgMember = () => {
        updateOrgMemberStatus(selOrgMemberIdx, "N");
    };

    if (hideContent) {
        return null;
    }

    return (
        <Fragment>
            <Panel/>
            <div className="page page--main" data-page="profile">
                {/* PAGE CONTENT */}
                <div className="page__content page_section">
                    <header className="header top-0">
                        <div className="header__inner">
                            <div className="header__icon"/>
                            <div className="header__icon">
                                <Link to="/org/profileedit/setting">
                                    <img
                                        src="../../assets/images/icons/black/settings.svg"
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                    </header>
                    <OrgProfile org={orgData} type="profile" folder="org"/>
                    {window.sessionStorage.getItem("orgType") !== "A" && (
                        <div className="form__row mt-40">
                            <input
                                onClick={addActClickHandler}
                                style={{cursor: "pointer"}}
                                type="submit"
                                name="submit"
                                className="form__submit button button--main button--full"
                                id="submit"
                                value="활동 등록하기"
                            />
                        </div>
                    )}
                    <div className={`${window.sessionStorage.getItem("orgType") === "CA" && 'tabs'} tabs--style1 mb-20`}>
                        {/*<div className={`tabs tabs--style1 mb-20`}>*/}
                        {window.sessionStorage.getItem("orgType") === "CA" && (
                            <>
                                <input
                                    type="radio"
                                    name="tabs2"
                                    className="tabs__radio"
                                    id="tab1"
                                    defaultChecked={true}
                                />
                                <label
                                    className={'tabs__label tabs__left tabs__label--12'}
                                    htmlFor="tab1"
                                >
                                    운영한 활동
                                </label>
                            </>
                        )}
                        {window.sessionStorage.getItem("orgType") !== "A" && (
                            <div className={window.sessionStorage.getItem("orgType") === "CA" && 'tabs__content'}>
                                <div className="form__row mb-40">
                                    <div
                                        className="checkbox-option checkbox-option--fulls"
                                        onClick={requestOperationClickHandler}
                                    >
                                        <label>
                                            활동 의뢰 목록 <span>({requestActCount})</span>
                                        </label>
                                    </div>
                                    <div
                                        className="checkbox-option checkbox-option--fulls"
                                        onClick={listActivityHistoryClickHandler}
                                    >
                                        <label>
                                            활동 내역 보기 <span/>
                                        </label>
                                    </div>
                                    {/* <div className="checkbox-option checkbox-option--fulls">
                                  <label>
                                    활동 통계 보기 <span />
                                  </label>
                                </div> */}
                                </div>
                                <h2>운영한 활동 ({activityData.length})</h2>
                                <div className="cards cards--12" id="loadlist-01">
                                    {activityData &&
                                        activityData
                                            .map((activity) => (
                                                <ActivityItem
                                                    key={activity.idx}
                                                    url={`/org/profile/actdetail/${activity.idx}`}
                                                    folder="org"
                                                    activity={activity}
                                                    viewTagList={viewTagList}
                                                />
                                            ))
                                            .slice(0, activityPage * offsetActivityPage)}
                                </div>
                                <div className="buttons buttons--centered mb-20">
                                    {activityData.length > activityPage * offsetActivityPage && (
                                        <div
                                            id=""
                                            className="button button--more"
                                            onClick={() => {
                                                addPage(setActivitiyPage, activityPage);
                                            }}
                                        >
                                            더보기
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {window.sessionStorage.getItem("orgType") === "CA" && (
                            <>
                                <input
                                    type="radio"
                                    name="tabs2"
                                    className="tabs__radio"
                                    id="tab2"
                                />
                                <label
                                    className={'tabs__label tabs__right tabs__label--12'}
                                    htmlFor="tab2"
                                >
                                    도움받은 활동
                                </label>
                            </>
                        )}
                        {window.sessionStorage.getItem("orgType") !== "C" &&
                            (<div className={window.sessionStorage.getItem("orgType") === "CA" && 'tabs__content'}>
                                    <div className="form__row mb-40">
                                        <div
                                            className="checkbox-option checkbox-option--fulls"
                                            onClick={requestOperationConformListClickHandler}
                                        >
                                            <label>
                                                의뢰한 활동 목록 <span>({helpActCount})</span>
                                            </label>
                                        </div>
                                        <div
                                            className="checkbox-option checkbox-option--fulls"
                                            onClick={() => navigate("/org/profile/help-schedule")}
                                        >
                                            <label>
                                                도움 요청한 활동 목록 <span>({helpScheduleCnt})</span>
                                            </label>
                                        </div>
                                    </div>
                                    <h2>도움받은 활동 ({helpActData.length})</h2>
                                    <div className="cards cards--12" id="loadlist-01">
                                        {helpActData &&
                                            helpActData
                                                .map((activity, i) => (
                                                    <ActivityItem
                                                        key={i}
                                                        url={`/org/activity/detailbene/${activity.activityIdx}`}
                                                        folder="org"
                                                        activity={activity}
                                                        viewTagList={viewOnlyActType}
                                                    />
                                                ))
                                                .slice(0, helpActPage * offsetActivityPage)}
                                    </div>
                                    <div className="buttons buttons--centered mb-20">
                                        {helpActData.length > helpActPage * offsetActivityPage ? (
                                            <div
                                                id=""
                                                className="button button--more"
                                                onClick={() => {
                                                    addPage(setHelpActPage, helpActPage);
                                                }}
                                            >
                                                더보기
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            )}
                        {/*<input*/}
                        {/*    type="radio"*/}
                        {/*    name="tabs2"*/}
                        {/*    className="tabs__radio hidden"*/}
                        {/*    id="tab3"*/}
                        {/*/>*/}
                        {/*<label*/}
                        {/*    className="tabs__label tabs__right tabs__label--4 hidden"*/}
                        {/*    htmlFor="tab3"*/}
                        {/*>*/}
                        {/*    참여한 활동*/}
                        {/*</label>*/}
                        {/*<div className="tabs__content">*/}
                        {/*    /!* <div className="form__row mb-40">*/}
                        {/*        <div*/}
                        {/*          className="checkbox-option checkbox-option--fulls"*/}
                        {/*          onClick={requestToParticipateList}*/}
                        {/*        >*/}
                        {/*          <label>*/}
                        {/*            참여 요청받은 목록 <span>({partRequestActCount})</span>*/}
                        {/*          </label>*/}
                        {/*        </div>*/}
                        {/*      </div> *!/*/}
                        {/*    <h2>참여한 활동 ({partActData.length})</h2>*/}
                        {/*    <div className="cards cards--12" id="loadlist-01">*/}
                        {/*        {partActData &&*/}
                        {/*            partActData*/}
                        {/*                .map((activity) => (*/}
                        {/*                    <ActivityItem*/}
                        {/*                        key={activity.idx}*/}
                        {/*                        url={`/org/activity/detailpart/${activity.activityIdx}`}*/}
                        {/*                        folder="org"*/}
                        {/*                        activity={activity}*/}
                        {/*                        viewTagList={viewOnlyActType}*/}
                        {/*                    />*/}
                        {/*                ))*/}
                        {/*                .slice(0, partActPage * offsetActivityPage)}*/}
                        {/*    </div>*/}
                        {/*    <div className="buttons buttons--centered mb-20">*/}
                        {/*        {partActData.length > partActPage * offsetActivityPage ? (*/}
                        {/*            <div*/}
                        {/*                id=""*/}
                        {/*                className="button button--more"*/}
                        {/*                onClick={() => {*/}
                        {/*                    addPage(setPartActPage, partActPage);*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                더보기*/}
                        {/*            </div>*/}
                        {/*        ) : (*/}
                        {/*            ""*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="tabs tabs--style1 mb-20">
                        <input
                            type="radio"
                            name="tabs3"
                            className="tabs__radio"
                            id="tab4"
                            defaultChecked="checked"
                        />
                        <label
                            className="tabs__label tabs__left tabs__label--12"
                            htmlFor="tab4"
                        >
                            소속 회원
                        </label>
                        <div className="tabs__content">
                            <div className="d-flex justify-space align-items-center">
                                <h2 className="lh-60">회원 그룹({groupData.length})</h2>
                            </div>
                            <div className="form__row">
                                <input
                                    onClick={addGroupClickHandler}
                                    style={{cursor: "pointer"}}
                                    type="submit"
                                    name="submit"
                                    className="form__submit button button--primary button--full"
                                    id="submit"
                                    value="그룹 만들기"
                                />
                            </div>
                            <div className="form__row">
                                {groupData &&
                                    groupData.map((group, idx) => (
                                        <GroupList
                                            key={idx}
                                            addition={false}
                                            group={group}
                                            isFinished={false}
                                        />
                                    ))}
                            </div>
                            <div className="d-flex justify-space align-items-center pt-40">
                                <h2 className="lh-60">총({orgMemberData.length})</h2>
                                {/* <input
                  onClick={chatDGroupClickHandler}
                  style={{ cursor: "pointer" }}
                  type="button"
                  name="submit"
                  className="form__submit button button--main"
                  value="그룹 메세지하기"
                /> */}
                            </div>
                            <div className="form__row">
                                <input
                                    onClick={userSearchClickHandler}
                                    style={{cursor: "pointer"}}
                                    type="submit"
                                    name="submit"
                                    className="form__submit button button--primary button--full"
                                    id="submit"
                                    value="+ 회원 추가"
                                />
                            </div>
                            <div className="cards cards--14">
                                {orgMemberData &&
                                    orgMemberData
                                        .map((orgMember) => (
                                            <GroupMemberItem
                                                key={orgMember.idx}
                                                folder="org"
                                                title="삭제"
                                                orgMember={orgMember}
                                                setOrgMemberData={setOrgMemberData}
                                            />
                                        ))
                                        .slice(0, orgMemberPage * offsetOrgMemberPage)}
                                <div className="buttons buttons--centered mb-20">
                                    {orgMemberData.length >
                                    orgMemberPage * offsetOrgMemberPage ? (
                                        <div
                                            id=""
                                            className="button button--more"
                                            onClick={() => {
                                                addPage(setOrgMemberPage, orgMemberPage);
                                            }}
                                        >
                                            더보기
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                        <input
                            type="radio"
                            name="tabs3"
                            className="tabs__radio"
                            id="tab5"
                        />
                        <label
                            className="tabs__label tabs__right tabs__label--12"
                            htmlFor="tab5"
                        >
                            소속 신청한 회원
                        </label>
                        <div className="tabs__content">
                            <div className="d-flex justify-space align-items-center">
                                <h2 className="lh-60">총({reqOrgMemberData.length})</h2>
                            </div>
                            <div className="cards cards--11">
                                {reqOrgMemberData &&
                                    reqOrgMemberData
                                        .map((orgMember) => (
                                            <ActivityMemberList
                                                btnType="groupRequest"
                                                part={orgMember}
                                                setSelActListIdx={setSelOrgMemberIdx}
                                            />
                                        ))
                                        .slice(0, reqOrgMemberPage * offsetActivityPage)}
                            </div>
                            <div className="buttons buttons--centered mb-20">
                                {reqOrgMemberData.length >
                                reqOrgMemberPage * offsetActivityPage ? (
                                    <div
                                        id=""
                                        className="button button--more"
                                        onClick={() => {
                                            addPage(setReqOrgMemberPage, reqOrgMemberPage);
                                        }}
                                    >
                                        더보기
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="space-h-100"/>
                </div>
            </div>
            {/* PAGE END */}
            <div id="bottom-toolbar_org" className="bottom-toolbar_org">
                <Navigation folder={"org"}/>
            </div>

            <ConformPopup
                popupType="accept"
                text="승인하시겠습니까?"
                conformClick={acceptOrgMember}
            />
            <ConformPopup
                popupType="delete"
                text="정말 삭제하시겠습니까?"
                conformClick={deleteOrgMember}
            />
        </Fragment>
    );
}

export default Org;
