import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router";

import SockJsClient from "react-stomp";
import chatAPI from "../../../services/chatapi";

import Panel from "../../../components/Panel";
import ChatHeader from "../../../components/ChatHeader";
import ChatItem from "../../../components/ChatItem";
import ChatInput from "../../../components/ChatInput";
import PopupListChat from "../../common/popup/PopupListChat";

const SCOKET_URL = `${process.env.REACT_APP_CHAT_SERVICE_API}/ws-chat`;

const ChatDGroup = () => {
  const { type, id } = useParams();

  let key = 0;

  const [chatRoomInfo, setChatRoomInfo] = useState(null);
  const [messages, setMessages] = useState([]);

  const onMessageReceived = (msg) => {
    setMessages(messages.concat(msg));
  };

  const onSendMessage = async (msgText) => {
    try {
      chatAPI.sendMessage(window.sessionStorage.getItem("nickname"), msgText);
    } catch (e) {
      alert("메시지를 보내는 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  useEffect(() => {
    const getChatHistory = async () => {
      const url = `${process.env.REACT_APP_CHAT_SERVICE_API}/api/v1/sole/chat/${type}`;

      try {
        const response = await axios.get(url, {
          params: {
            sender: window.sessionStorage.getItem("idx"),
            target: id,
          },
        });

        if (response.status === 200) {
          const { my_img, topic, title, history } = response.data;

          setChatRoomInfo({
            title: title,
            topic: topic,
          });

          let ary = [];
          let historyKeys = Object.keys(history);

          for (let i = 0; i < history.length; i++) {
            for (let j = 0; j < historyKeys.length; j++) {
              if (historyKeys[j] === "me") {
                ary.push({
                  status: "sent",
                  img: my_img,
                  content: history[i][j].msg,
                  created_time: history[i][j].date,
                });
              } else if (historyKeys[j] === "other") {
                ary.push({
                  status: "received",
                  name: history[i][j].id,
                  img: history[i][j].other_img,
                  content: history[i][j].msg,
                  created_time: history[i][j].date,
                });
              }
            }
          }

          setMessages(ary);
        }
      } catch (e) {
        alert("채팅 목록을 불러오는 중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
    // getChatHistory();

    setChatRoomInfo({
      title: "활동 제목(12 2021년 10월 21일 오전 10시",
      topic: "/topic/group",
    });
  }, [type, id]);

  return (
    <Fragment>
      {chatRoomInfo && (
        <SockJsClient
          url={SCOKET_URL}
          topics={[`${chatRoomInfo.topic}`]}
          onMessage={(msg) => onMessageReceived(msg)}
          debug={false}
        />
      )}
      <Panel />
      <div className="page page--main" data-page="chatgs">
        {chatRoomInfo && (
          <ChatHeader title={chatRoomInfo.title} popup="chatgs" />
        )}

        {/* PAGE CONTENT */}
        <div className="page__content page_section page_section--with-header-68">
          <ul className="chat">
            {messages.map((msgInfo) => (
              <ChatItem
                status={
                  msgInfo.sender === window.sessionStorage.getItem("nickname")
                    ? "sent"
                    : "received"
                }
                msgInfo={msgInfo}
                folder="sole"
                created_time={"오후 04시 10분"}
                key={key++}
              />
            ))}
          </ul>
        </div>
      </div>
      {/* PAGE END */}
      {/* Bottom navigation */}
      <div id="bottom-toolbar-conversations" className="bottom-toolbar">
        <ChatInput onSendMessage={onSendMessage} />
      </div>
      <PopupListChat popupType="chatgs" />
    </Fragment>
  );
};

export default ChatDGroup;
