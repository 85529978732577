import React, { useEffect, useState, Fragment } from "react";
import InviteMemberItem from "../../../components/InviteMemberItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import axios from "axios";
import { useLocation } from "react-router-dom";
import CheckLoginInfo from "../../../services/login-validation";

function UserSearch(props) {
  const location = useLocation();
  const search = location.state && location.state.search;

  const [userData, setUserData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [userPage, setUserPage] = useState(1);

  const offsetPage = 8;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  const [hideContent, setHideContent] = useState(true);

  // 개인 회원 조회 및 기관 소속 회원 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        let url = "";
        if (search === "user") {
          url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/notlike/users`;
          axios.get(url).then((res) => {
            setUserData(res.data);
          });
        } else {
          url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${sessionStorage.idx}`;
          axios.get(url).then((res) => {
            const resJson = res.data.data;

            axios
              .all(
                resJson.map((orgMember) => {
                  return axios.get(
                    `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
                  );
                })
              )
              .then((res) => {
                res.map((user, index) => {
                  resJson[index].nickname = user.data.nickname;
                });

                setUserData(resJson);
              });
          });
        }

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const searchKeywordChangeHandler = (e) => {
    setSearchKeyword(e.target.value);
  };

  const searchUserClickHandler = () => {
    let url = "";
    if (searchKeyword !== "") {
      if (search === "user") {
        url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/search/notlike/${searchKeyword}`;
        axios.get(url).then((res) => {
          setUserData(res.data);
        });
      } else {
        const params = {
          nickname: searchKeyword,
          orgIdx: sessionStorage.idx,
        };

        console.log(params);

        url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/nickname/`;
        axios
          .get(url, {
            params: params,
          })
          .then((res) => {
            console.log(res.data);
            setUserData(res.data.data);
          });
      }
    } else {
      if (search === "user") {
        url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/notlike/users`;
        axios.get(url).then((res) => {
          setUserData(res.data);
        });
      } else {
        url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${sessionStorage.idx}`;
        axios.get(url).then((res) => {
          //console.log(res.data.data);

          axios
            .all(
              res.data.data.map((orgMember) => {
                return axios.get(
                  `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
                );
              })
            )
            .then((res) => {
              res.map((user, index) => {
                res.splice(index, 1, user.data);
              });

              //console.log(res);

              setUserData(res);
            });
        });
      }
    }
  };

  const enterKeyupHandler = () => {
    if (window.event.keyCode === 13) {
      searchUserClickHandler();
    }
  };

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="사용자 검색" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68">
          <div className="search__form mt-40">
            <form>
              <input hidden="hidden" />
              <input
                type="text"
                className="search__input"
                placeholder="검색..."
                value={searchKeyword}
                onChange={searchKeywordChangeHandler}
                onKeyUp={enterKeyupHandler}
              />
              <input
                type="button"
                className="search__submit"
                value="Send"
                onClick={searchUserClickHandler}
              />
            </form>
          </div>
          <div className="page__title-bar mt-48">
            <h2>회원 ({userData && userData.length})</h2>
          </div>
          <div className="cards cards--14">
            {userData &&
              userData
                .map((user) => (
                  <InviteMemberItem
                    key={user.idx}
                    user={user}
                    invite="추가"
                    folder="org"
                    search={location.state && location.state.search}
                  />
                ))
                .slice(0, userPage * offsetPage)}
            <div className="buttons buttons--centered mb-20">
              {userData.length > userPage * offsetPage ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => {
                    addPage(setUserPage, userPage);
                  }}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form__row mt-40">
            {/* <input onClick="location.href='#'" style={{cursor: 'pointer'}} type="submit" name="submit" data-popup="conform_cancle" className="form__submit button button--gray button--50 open-popup" id="submit" value="취소" />
            <input onClick="location.href='#'" style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--main button--50" id="submit" value="확인" /> */}
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default UserSearch;
