import React, { Fragment, useState } from "react";
import SockJsClient from "react-stomp";

import chatAPI from "../../../services/chatapi";

import ChatHeader from "../../../components/ChatHeader";
import ChatInput from "../../../components/ChatInput";
import ChatItem from "../../../components/ChatItem";
import Panel from "../../../components/Panel";
import PopupListChat from "../../common/popup/PopupListChat";

const SCOKET_URL = `${process.env.REACT_APP_CHAT_SERVICE_API}/ws-chat`;

const ChatDAll = () => {
  let key = 0;
  const [messages, setMessages] = useState([]);

  const onMessageReceived = (msg) => {
    setMessages(messages.concat(msg));
  };

  const onSendMessage = async (msgText) => {
    try {
      chatAPI.sendMessage(window.sessionStorage.getItem("nickname"), msgText);
    } catch (e) {
      alert("메시지를 보내는 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  return (
    <Fragment>
      <SockJsClient
        url={SCOKET_URL}
        topics={["/topic/group"]}
        onMessage={(msg) => onMessageReceived(msg)}
        debug={false}
      />
      <Panel />
      <div className="page page--main" data-page="chat">
        <ChatHeader
          title="활동 제목(12) 2021년 10월 21일 오전 10시"
          popup="chatall"
        />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          <ul className="chat">
            {messages.map((msgInfo) => (
              <ChatItem
                status={
                  msgInfo.sender === window.sessionStorage.getItem("nickname")
                    ? "sent"
                    : "received"
                }
                folder="org"
                content={msgInfo.content}
                name={msgInfo.sender}
                created_time={"오후 04시 10분"}
                key={key++}
              />
            ))}
          </ul>
        </div>
      </div>
      {/* PAGE END */}
      {/* Bottom navigation */}
      <div id="bottom-toolbar-conversations" className="bottom-toolbar">
        <ChatInput onSendMessage={onSendMessage} />
      </div>
      <PopupListChat popupType="chatall" />
    </Fragment>
  );
};

export default ChatDAll;
