import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ConformDelete from "../popup/ConformDelete";
import CheckLoginInfo from "../../../services/login-validation";

function QnaDetailMine() {
  const navigate = useNavigate();
  const { qnaIdx } = useParams();
  const [qnaInfo, setQnaInfo] = useState(null);
  const [isAnswered, setIsAnswered] = useState(null);

  const deleteQna = async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/qna`;

    try {
      const response = await axios.delete(url, {
        params: {
          idxs: qnaIdx,
        },
      });

      if (response.status === 200) {
        alert("삭제되었습니다.");
        navigate(-1);
      }
    } catch (e) {
      alert("문의/답변을 삭제 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const deleteClick = () => {
    deleteQna();
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getQnaInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getQnaInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/qna/${qnaIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setQnaInfo({
            title: response.data.title,
            nickName: response.data.nickname,
            createDate: response.data.createdAtStr,
            viewCount: response.data.clickCnt,
            userIdx: response.data.userIdx,
            content: response.data.content,
            answeredDate: Object.keys(response.data).includes("answeredAtStr")
              ? response.data.answeredAtStr
              : "-", // 답변일
            answer: Object.keys(response.data).includes("answer")
              ? response.data.answer
              : "-",
          });

          setIsAnswered(
            Object.keys(response.data).includes("answer") ? true : false
          );
        }
      } catch (e) {
        alert("문의하기 상세조회 중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
  }, [qnaIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="문의하기" />
        {/* PAGE CONTENT */}
        {qnaInfo && (
          <div className="page__content  page_section--with-header-68">
            {/* SLIDER SIMPLE */}
            <div className="page_section">
              <div className="d-flex justify-space align-items-center pt-40"></div>
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li className="mt-20">
                    <h2>{qnaInfo.title}</h2>
                  </li>
                  <li>
                    <span>작성자 : </span>
                    <Link to={`/sole/otherprofile/sole/${qnaInfo.userIdx}`}>
                      {qnaInfo.nickName}
                    </Link>
                  </li>
                  <li>
                    <span>글번호 : </span>
                    {qnaIdx}
                  </li>
                  <li>
                    <span>등록일 : </span>
                    {qnaInfo.createDate}
                  </li>
                  <li>
                    <span>조회수 : </span>
                    {qnaInfo.viewCount}
                  </li>
                  <li>
                    <span>상세 : </span>
                  </li>
                  <li>{qnaInfo.content}</li>
                </ul>
              </div>
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li>
                    <span>답변일 : </span>
                    {qnaInfo.answeredDate}
                  </li>
                  <li>
                    <span>답변 : </span>
                  </li>
                  <li>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: qnaInfo.answer,
                      }}
                    ></div>
                  </li>
                </ul>
              </div>
              {/* 답글이 달리기 전 보이는 버튼 수정 가능*/}
              {isAnswered ? (
                <div className="form__row mt-40">
                  <input
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{ cursor: "pointer" }}
                    type="submit"
                    name="submit"
                    data-popup="conform_cancle"
                    className="form__submit button button--gray button--50 open-popup"
                    id="submit"
                    value="취소"
                  />
                  <input
                    onClick="location.href='#'"
                    style={{ cursor: "pointer" }}
                    type="submit"
                    name="submit"
                    data-popup="conform_delete"
                    className="form__submit button button--gray button--50 open-popup"
                    id="submit"
                    value="삭제"
                  />
                </div>
              ) : (
                <div className="form__row mt-40">
                  <input
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{ cursor: "pointer" }}
                    type="submit"
                    name="submit"
                    data-popup="conform_cancle"
                    className="form__submit button button--gray button--333 open-popup"
                    id="submit"
                    value="취소"
                  />
                  <input
                    onClick="location.href='#'"
                    style={{ cursor: "pointer" }}
                    type="submit"
                    name="submit"
                    data-popup="conform_delete"
                    className="form__submit button button--gray button--333 open-popup"
                    id="submit"
                    value="삭제"
                  />
                  <input
                    onClick={() => {
                      navigate(`/common/community/qnaedit/${qnaIdx}`);
                    }}
                    style={{ cursor: "pointer" }}
                    type="submit"
                    name="submit"
                    className="form__submit button button--gray button--333"
                    id="submit"
                    value="수정하기"
                  />
                </div>
              )}

              <div className="space-h-100" />
            </div>
            <div className="space-h-100" />
          </div>
        )}
      </div>
      <ConformDelete conformClick={deleteClick} />
    </Fragment>
  );
}

export default QnaDetailMine;
