import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityItem from "../../../components/ActivityItem";
import MemberItem from "../../../components/MemberItem";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import axios from "axios";
import OrgProfile from "../../../components/OrgProfile";
import CheckLoginInfo from "../../../services/login-validation";
import ActivitySwiperItem from "../../../components/ActivitySwiperItem";

function Org(props) {
  const navigate = useNavigate();
  const { orgIdx } = useParams();

  // DB 데이터
  const [orgData, setOrgData] = useState();
  const [activityData, setActivityData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);
  const [orgMemberData, setOrgMemberData] = useState([]);

  const [helpActData, setHelpActData] = useState([]);
  const [partActData, setPartActData] = useState([]);

  // 더보기 처리
  const [activityPage, setActivitiyPage] = useState(1);
  const [consumerPage, setConsumerPage] = useState(1);
  const [orgMemberPage, setOrgMemberPage] = useState(1);

  const [helpActPage, setHelpActPage] = useState(1);
  const [partActPage, setPartActPage] = useState(1);

  const [closedActivityData, setClosedActivityData] = useState([]);

  const offsetActivityPage = 6;
  const offsetOrgMemberPage = 8;

  const viewTagList = ["activityType", "isPrivate", "beneType", "partType"];

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 기관 상세조회
  const getOrgData = () => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/org/${orgIdx}`;
    axios
      .get(url)
      .then((res) => {
        // console.log(res.data);
        setOrgData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 활동 조회
  const getActivity = () => {
    // axios URL
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/${orgIdx}`;
    axios.get(url).then((res) => {
      const json = res.data.data;
      // console.log(res.data.data);

      // 활동의 기관명 추가
      json.map((item) => {
        axios
          .get(`${process.env.REACT_APP_USER_SERVICE_API}/org/${item.orgIdx}`)
          .then((res) => {
            item.orgTitle = res.data.orgTitle;

            // console.log(json);
            setActivityData(
              json &&
              json.filter(
                (json) =>
                  (json.partType === "A" ||
                    json.partType === "U" ||
                    json.partType === "O") &&
                  json.isPrivate === "O"
              )
            );
            setConsumerData(
              json &&
              json.filter(
                (json) =>
                  (json.beneType === "A" ||
                    json.beneType === "U" ||
                    json.beneType === "O") &&
                  json.isPrivate === "O"
              )
            );

            setClosedActivityData(
              json &&
              json.filter(
                (json) =>
                  (json.beneType === "E" || json.partType === "E") &&
                  json.isPrivate === "O"
              )
            );
          });
      });
    });
  };

  // 회원 소속 기관 조회
  const getOrgMeberData = () => {
    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${orgIdx}`
      )
      .then((res) => {
        // console.log("== 회원 소속 기관 조회 ==");
        // console.log(res.data.data);

        const orgMemberList = res.data.data;
        // console.log(orgMemberList);

        axios
          .all(
            res.data.data.map((orgMember) => {
              return axios.get(
                `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
              );
            })
          )
          .then((res2) => {
            res2.map((user, index) => {
              // console.log("== 데이터 조회 ==");
              // console.log(user);
              orgMemberList[index].nickname = user.data.nickname;
            });

            // console.log(orgMemberList);
            setOrgMemberData(orgMemberList);
          });
      });
  };

  // 도움 받은 활동 조회
  const getHelpActData = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/request/done/${orgIdx}`;
    axios
      .get(URL)
      .then((res) => {
        const data = res.data.data;

        let ary = [];

        data.forEach((data) => {
          ary.push({
            idx: data.idx,
            activityDate: data.activityDate,
            address1: data.address1,
            address2: data.address2,
            helpUserIdx: data.helpUserIdx,
            orgIdx: data.orgIdx,
            orgTitle: data.orgTitle,
            activityType: data.activityType,
            title: data.title,
            activityIdx: data.activityIdx,
            content: data.content,
          });
        });

        setHelpActData(ary);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 참여한 활동 조회
  const getPartActData = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/activity/participate/${orgIdx}`;
    axios
      .get(URL)
      .then((res) => {
        const data = res.data.data;

        let ary = [];

        data.forEach((data) => {
          ary.push({
            // idx: data.idx,
            activityDate: data.activityDate,
            address1: data.address1,
            address2: data.address2,
            helpUserIdx: data.helpUserIdx,
            orgIdx: data.orgIdx,
            orgTitle: data.orgTitle,
            activityType: data.activityType,
            title: data.activityTitle,
            activityIdx: data.activityIdx,
            content: data.content,
          });
        });

        setPartActData(ary);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getOrgData();
        getActivity();
        getOrgMeberData();
        getHelpActData();
        getPartActData();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const newactrequest = () => {
    navigate(`/org/otherprofile/activityrequest/${orgIdx}`, {
      state: {
        orgProfile: orgData,
      },
    });
  };

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content page_section">
          <SubHeader />
          <OrgProfile org={orgData} type="otherprofile" folder="org" />
          <div className="tabs tabs--style1 mb-20">
            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab1"
              defaultChecked="checked"
            />
            <label
              className="tabs__label tabs__left tabs__label--12"
              htmlFor="tab1"
            >
              운영한 활동
            </label>
            <div className="tabs__content">
              <h2>활동자 모집 ({activityData.length})</h2>
              <div className="cards cards--12" id="loadlist-01">
                {activityData &&
                  activityData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/org/activity/detailpart/${activity.idx}`}
                        folder="org"
                        activity={activity}
                        viewTagList={viewTagList}
                      />
                    ))
                    .slice(0, activityPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {activityData.length > activityPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setActivitiyPage, activityPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form__row mt-40">
                <input
                  onClick={newactrequest}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--full"
                  id="submit"
                  value="신규 활동 의뢰하기"
                />
              </div>
              <div className="page__title-bar mt-48">
                <h2>도움이 필요하신가요? ({consumerData.length})</h2>
              </div>
              <div className="cards cards--12" id="loadlist-02">
                {consumerData &&
                  consumerData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/org/activity/detailbene/${activity.idx}`}
                        folder="org"
                        activity={activity}
                        viewTagList={viewTagList}
                      />
                    ))
                    .slice(0, consumerPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {consumerData.length > consumerPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setConsumerPage, consumerPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="page__title-bar">
                <h2>
                  진행한 활동({closedActivityData && closedActivityData.length})
                </h2>
                {closedActivityData && closedActivityData.length > 0 && (
                  <div className="page__title-right">
                    <div className="swiper-button-prev slider-thumbs__prev" />
                    <div className="swiper-button-next slider-thumbs__next" />
                  </div>
                )}
              </div>
              <div
                className="swiper-container slider-thumbs slider-init mb-20"
                data-paginationtype="progressbar"
                data-spacebetweenitems={10}
                data-itemsperview="auto"
              >
                <div className="swiper-wrapper">
                  {closedActivityData &&
                    closedActivityData
                      .map((activity) => (
                        <ActivitySwiperItem
                          key={activity.idx}
                          activity={activity}
                        />
                      ))
                      .slice(0, activityPage * offsetActivityPage)}
                </div>
                {closedActivityData && closedActivityData.length > 0 && (
                  <div className="swiper-pagination slider-thumbs__pagination" />
                )}
              </div>
            </div>
            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab2"
            />
            <label
              className="tabs__label tabs__right tabs__label--12"
              htmlFor="tab2"
            >
              도움받은 활동
            </label>
            <div className="tabs__content">
              <h2>도움받은 활동 ({helpActData.length})</h2>
              <div className="cards cards--12" id="loadlist-01">
                {helpActData &&
                  helpActData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/org/activity/detailbene/${activity.activityIdx}`}
                        folder="org"
                        activity={activity}
                        viewTagList={viewTagList}
                      />
                    ))
                    .slice(0, helpActPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {helpActData.length > helpActPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setHelpActPage, helpActPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab3"
            />
            <label
              className="tabs__label tabs__right tabs__label--4 hidden"
              htmlFor="tab3"
            >
              참여한 활동
            </label>
            <div className="tabs__content">
              <h2>참여한 활동 ({partActData.length})</h2>
              <div className="cards cards--12" id="loadlist-01">
                {partActData &&
                  partActData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/org/activity/detailpart/${activity.activityIdx}`}
                        folder="org"
                        activity={activity}
                        viewTagList={viewTagList}
                      />
                    ))
                    .slice(0, partActPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {partActData.length > partActPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setPartActPage, partActPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="tabs tabs--style1 mb-20">
            <input
              type="radio"
              name="tabs3"
              className="tabs__radio"
              id="tab3"
              defaultChecked="checked"
            />
            <div className="d-flex justify-space align-items-center pt-40">
              <h2>소속 회원({orgMemberData.length})</h2>
            </div>
            <div className="cards cards--14">
              {orgMemberData &&
                orgMemberData.map((orgMember) => (
                  <MemberItem
                    key={orgMember.idx}
                    folder="org"
                    orgMember={orgMember}
                  />
                ))}
            </div>
            <div className="buttons buttons--centered mb-20">
              {orgMemberData.length > orgMemberPage * offsetOrgMemberPage ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => {
                    addPage(setOrgMemberPage, orgMemberPage);
                  }}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      {/* Social Icons Popup */}
      <div id="popup-social" />
      {/* Alert */}
      <div id="popup-alert" />
      <div id="popup--conform_cancle" />
      <div id="popup--conform_dismissal" />
      <div id="popup--conform_accept" />
      <div id="popup--conform_part" />
      <div id="popup--conform_delete" />
      <div id="popup--conform_save" />
      <div id="popup--recruitment" />
      <div id="popup-cancle-and-delete" />
      <div id="popup--conform_application-group" />
      <div id="popup--declaration" />
      {/* Notifications */}
      <div id="popup-notifications" />
    </Fragment>
  );
}

export default Org;
