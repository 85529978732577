import React from 'react';

const ConformSave = (props) => {
  const {conformClick} = props;

  return (
    <div id="popup--conform_save">
      <div className="popup popup--centered popup--shadow popup--conform_save">
        <div className="popup__close"><a href="#" className="close-popup" data-popup="conform_save"><img src="/assets/images/icons/blue/close.svg" alt="" /></a></div>
        <div className="popup__icon"><img src="/assets/images/icons/blue/alert.svg" alt="" /></div>
        <h2 className="popup__title">확인 !</h2>
        <p className="popup__text">해당 내용을 저장하시겠습니까?</p>
        <div className="form__row mt-40">
          <input data-popup="conform_save" style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--gray button--50 close-popup" id="submit" value="취소" />
          <input data-popup="conform_save" onClick={conformClick} style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--main button--50 close-popup" id="submit" value="확인" />
        </div>
      </div>
    </div>
  );
}

export default ConformSave;