import React, { useEffect, useState, Fragment, useCallback } from "react";
import axios from "axios";

import ActivityRequestList from "../../../components/ActivityRequestList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";

function ActRequestApplication(props) {
  const [applicationPageNumber, setApplicationPageNumber] = useState(1);
  const onChangePageNumber = () => {
    setApplicationPageNumber(applicationPageNumber + 1);
  };

  const [requestPageNumber, setRequestPageNumber] = useState(1);
  const onChangeRequestPageNumber = () => {
    setRequestPageNumber(requestPageNumber + 1);
  };

  const [applicationCnt, setApplicationCnt] = useState(0);
  const [requestCnt, setRequestCnt] = useState(0);

  const [applicationList, setApplicationList] = useState([]);
  const [activityList, setActivityList] = useState([]);

  const [selectedInfo, setSelectedInfo] = useState({
    isRequest: null,
    idx: null,
  });

  const getApplicationList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/application/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: applicationPageNumber,
          count: 5,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].actListIdx,
            activityIdx: data[i].activityIdx,
            img: Object.keys(data[i]).includes("image")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${data[i].image}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            title: data[i].title !== null ? data[i].title : "활동 제목",
            activityType: data[i].activityType,
            address: `${data[i].address1} ${Object.keys(data[i]).includes("address2") ? data[i].address2 : ""
              }`,
            nickName: data[i].userNickname,
            userIdx: data[i].userIdx,
            activityDate: data[i].activityDate,
            activityTime: data[i].activityTime,
            activityTimeEnd : data[i].activityTimeEnd,
            recruitNum: data[i].joinNum,
            totalRecruitNum: data[i].recruitNum,
            recruitState: data[i].joinNum >= data[i].recruitNum ? "Y" : "N",
            beneType: data[i].beneType,
            beneOrgTitle: Object.keys(data[i]).includes("beneOrgTitle")
              ? data[i].beneOrgTitle
              : null,
            beneOrgIdx: Object.keys(data[i]).includes("beneOrgIdx")
              ? data[i].beneOrgIdx
              : null,
            activityState: data[i].volunteer,
          });
        }

        setApplicationCnt(totalRows);

        if (applicationPageNumber === 1) {
          setApplicationList(ary);
        } else {
          setApplicationList(applicationList.concat(ary));
        }
      }
    } catch (e) {
      alert("활동 참여 신청 목록 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [applicationPageNumber]);

  const getRequestList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/act/request/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: requestPageNumber,
          count: 5,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].actRequestIdx,
            activityIdx: data[i].activityIdx,
            img:
              Object.keys(data[i]).includes("image") && data[i].image != null
                ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${data[i].image}`
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            title: data[i].title !== null ? data[i].title : "활동 제목",
            activityType: data[i].activityType,
            address: `${data[i].address1} ${Object.keys(data[i]).includes("address2") ? data[i].address2 : ""
              }`,
            nickName: data[i].userNickname,
            userIdx: data[i].userIdx,
            activityDate: data[i].activityDate,
            activityTime: data[i].activityTime,
            activityTimeEnd : data[i].activityTimeEnd,
            recruitNum: data[i].joinNum,
            totalRecruitNum: data[i].recruitNum,
            recruitState: data[i].joinNum >= data[i].recruitNum ? "Y" : "N",
            beneType: data[i].beneType,
            beneOrgTitle: Object.keys(data[i]).includes("beneOrgTitle")
              ? data[i].beneOrgTitle
              : null,
            beneOrgIdx: Object.keys(data[i]).includes("beneOrgIdx")
              ? data[i].beneOrgIdx
              : null,
            activityState: data[i].volunteer,
          });
        }

        setRequestCnt(totalRows);

        if (requestPageNumber === 1) {
          setActivityList(ary);
        } else {
          setActivityList(activityList.concat(ary));
        }
      }
    } catch (e) {
      alert("활동 참여 요청 목록 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [requestPageNumber]);

  const cancelActivity = () => {
    if (selectedInfo.isRequest) {
      cancelRejectRequest();
    } else {
      deleteActList();
    }
  };

  const rejectActivity = () => {
    rejectRequest();
  };

  const deleteActivity = () => {
    deleteActList();
  };

  const acceptActivity = () => {
    acceptRequest();
  };

  const deleteActList = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-list/${selectedInfo.idx
      }/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.delete(url);

      if (response.status === 200) {
        alert("처리되었습니다.");
        setApplicationPageNumber(1);
        getApplicationList();
      }
    } catch (e) {
      console.log(e);
      alert("처리 중, 오류가 발생하였습니다.");
    }
  };

  const acceptRequest = async () => {
    let data = new Object();
    data.idx = selectedInfo.idx;
    data.userIdx = window.sessionStorage.getItem("idx");
    data.updatedUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-request/status/accept`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        alert("처리되었습니다.");
        getRequestList();
      }
    } catch (e) {
      alert("처리 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const rejectRequest = async () => {
    let data = new Object();
    data.idx = selectedInfo.idx;
    data.userIdx = window.sessionStorage.getItem("idx");
    data.updatedUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-request/status/reject`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        alert("처리되었습니다.");
        getRequestList();
      }
    } catch (e) {
      alert("처리 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const cancelRejectRequest = async () => {
    let data = new Object();
    data.idx = selectedInfo.idx;
    data.userIdx = window.sessionStorage.getItem("idx");
    data.updatedUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-request/status/cancel-reject`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        alert("처리되었습니다.");
        getRequestList();
      }
    } catch (e) {
      alert("처리 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const selectedScheduleInfo = (selected, isRequest) => {
    setSelectedInfo({
      isRequest: isRequest,
      idx: selected,
    });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getApplicationList();
        getRequestList();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getApplicationList, getRequestList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 참여 신청/요청" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar">
              <h2>활동 참여 신청 목록({applicationCnt})</h2>
            </div>
            <div className="cards cards--11">
              {applicationList.map((activity) => (
                <ActivityRequestList
                  folder="sole"
                  activity={activity}
                  status={activity.activityState}
                  selectedScheduleInfo={selectedScheduleInfo}
                />
              ))}
              {applicationCnt > applicationList.length ? (
                <div className="buttons buttons--centered mb-20">
                  <div
                    className="button button--more"
                    onClick={onChangePageNumber}
                  >
                    더 보기
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="page_section">
            <div className="page__title-bar">
              <h2>활동 요청 목록({requestCnt})</h2>
            </div>
            <div className="cards cards--11">
              {activityList.map((activity) => (
                <ActivityRequestList
                  folder="sole"
                  activity={activity}
                  status={activity.activityState}
                  selectedScheduleInfo={selectedScheduleInfo}
                  isRequest={true}
                />
              ))}
              {requestCnt > activityList.length ? (
                <div className="buttons buttons--centered mb-20">
                  <div
                    className="button button--more"
                    onClick={onChangeRequestPageNumber}
                  >
                    더 보기
                  </div>
                </div>
              ) : null}
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        text="취소하시겠습니까?"
        popupType="cancle"
        conformClick={cancelActivity}
      />
      <ConformPopup
        text="활동 요청을 반려하시겠습니까?"
        popupType="dismissal"
        conformClick={rejectActivity}
      />
      <ConformPopup
        text="활동 참여 신청 내역을 삭제하시겠습니까?"
        popupType="delete"
        conformClick={deleteActivity}
      />
      <ConformPopup
        text="활동 요청을 승인하시겠습니까?"
        popupType="success"
        conformClick={acceptActivity}
      />
      <Navigationbar />
    </Fragment>
  );
}

export default ActRequestApplication;
