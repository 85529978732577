import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UrlContext from "../context/Url.context";
import GroupContext from "../context/Group.context";

function InviteMemberItem(props) {
  const groupContext = useContext(GroupContext);
  const navigate = useNavigate();

  const {
    search,
    invite,
    user,
    folder,
    addFunc,
    checkFunc,
    deleteFunc,
    groupData,
  } = props;

  const [inviteText, setInviteText] = useState(invite);

  const [imgPath, setImgPath] = useState(
    `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`
  );

  useEffect(() => {
    if (user) {
      const url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;

      axios
        .get(url, {
          params: {
            pkIdx:
              user && Object.keys(user).includes("userIdx")
                ? user.userIdx
                : user.idx,
            imageStatus: "U",
          },
        })
        .then((res) => {
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, [user]);

  useEffect(() => {
    if (checkFunc) {
      if (checkFunc(user.nickname) === 1) {
        setInviteText("추가 됨");
      } else {
        setInviteText("추가");
      }
    }
  }, [user]);

  const memberClickHandler = () => {
    navigate(`/${folder}/otherprofile/sole/${user.idx}`, {
      state: {},
    });
  };

  const addMemberClickHandler = () => {
    // search 가 user인 경우
    if (search === "user") {
      const data = {
        orgIdx: sessionStorage.idx,
        volunteer: "N",
        status: "U",
        userIdx: user.idx,
        createdUid: sessionStorage.idx,
        updatedUid: sessionStorage.idx,
      };

      //console.log(data);

      // 회원을 기관의 소속으로 등록
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org`;
      axios
        .post(URL, data)
        .then((res) => {
          //console.log(res.data);
          setInviteText("추가 됨");
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 401) {
            alert(error.response.data);
          }
        });
    }
    // search 가 group인 경우
    else if (search === "group") {
      const data = {
        orgIdx: sessionStorage.idx,
        userIdx: user && user.idx,
        name: groupData && groupData.name,
        createdUid: sessionStorage.idx,
      };

      // group에 해당 회원 추가
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/group_list`;
      axios
        .post(URL, data)
        .then((res) => {
          // console.log(res.data);
          setInviteText("추가 됨");
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 401) {
            alert(error.response.data);
          }
        });
    }
    // search 가 placing 인 경우
    else if (search === "placing") {
      if (addFunc(user.nickname)) {
        setInviteText("추가 됨");
      }
    }
    // search 가 orgMember 인 경우
    else {
      // 그룹만들기에 이미 등록된 회원인지 ?
      // 1 : 이미 등록, 0 : 신규 등록
      const chkAddLength = groupContext.groupList.filter(
        (group) => group.nickname === user.nickname
      ).length;

      // 그룹만들기 페이지 그룹 회원 미리보기에 배열에 추가
      if (chkAddLength === 0) {
        groupContext.addGroup(user);
        setInviteText("추가 됨");
      } else {
        alert("그룹만들기에 이미 추가된 회원입니다.");
        setInviteText("추가 됨");
      }
    }
  };

  const deleteMemberClickHandler = () => {
    if (search === "placing") {
      deleteFunc(user.nickname);
      setInviteText("추가");
    }
  };

  // const cancelMemberClickHandler = () => {
  //   setInviteText("추가");
  // }

  const inviteItem = () => {
    switch (inviteText) {
      case "승인 대기 중":
        return (
          <div className="button button--small button--secondary button--full">
            {inviteText}
          </div>
        );
      case "추가":
        return (
          <div
            className="button button--small button--main button--full"
            onClick={addMemberClickHandler}
          >
            {inviteText}
          </div>
        );
      case "추가 됨":
        return (
          <div
            className="button button--small button--secondary button--full"
            onClick={deleteMemberClickHandler}
          >
            {inviteText}
          </div>
        );
      case "알림 보내기":
        return (
          <div className="button button--small button--main button--full">
            {inviteText}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="card card--style-thumb">
      <div
        className="card__thumb card__thumb--round-corners-full"
        onClick={memberClickHandler}
        style={{ cursor: "pointer" }}
      >
        <img src={imgPath} alt="" />
      </div>
      <h4 className="card__username text-aline-center">
        {user && user.nickname}
      </h4>
      <h4 className="card__title text-aline-center">{user && user.name}</h4>
      {inviteItem()}
    </div>
  );
}

export default InviteMemberItem;
