const useConfirm = (message = null, onConfirm, onCancel = null) => {
  if (!onConfirm || typeof onConfirm !== "function") {
    return;
  }

  const confirmAction = () => {

    const result = window.confirm(message);

    if (result) {
      onConfirm();
    }else{
      if(onCancel && typeof onCancel === "function"){
          onCancel();
      }
    }
  };
  return confirmAction;
};

export default useConfirm;
