import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import TimeItem from "../../../components/TimeItem";
import CheckLoginInfo from "../../../services/login-validation";

const timeam = ["00", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
const timepm = ["12", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];

function ScheduleList(props) {
  const navigate = useNavigate();

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="테스트 일정" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar mt-48 mb-24">
              <h1 className="page__title">일정</h1>
            </div>
            {timeam.map((item) => (
              <TimeItem item={item} time="오전" />
            ))}
            {timepm.map((item) => (
              <TimeItem item={item} time="오후" />
            ))}
            <div className="form__row mt-40">
              <input
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="확인"
              />
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default ScheduleList;
