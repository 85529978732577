import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { convertTimeFormat } from "../../../util/convert-date-function";
import SubHeader from "../../../components/SubHeader";
import ConformPopup from "../../common/popup/ConformPopup";
import { makeSchedule_new } from "../../../util/schedule-function";

const ScheduleAdd = ({
  toggleSchedulePopup,
  addSchedules,
  hideRecruitNum,
  isPopup,
}) => {
  const navigate = useNavigate();
  const { activityIdx } = useParams();

  const [orgIdx, setOrgIdx] = useState(0);

  // 반복 타입
  const [repeatType, setRepeatType] = useState("default");
  const onChangeRepeatType = (e) => {
    setRepeatType(e.target.value);
  };

  // 반복 종료 날짜
  const [repeatDate, setRepeatDate] = useState("");
  const onChangeRepeatDate = (e) => {
    setRepeatDate(e.target.value);
  };

  // 선택 일자
  const [scheduleInfo, setScheduleInfo] = useState({
    date: "",
    startTime: "",
    endTime: "",
  });
  const onChangeScheduleInfo = (e) => {
    // 당일 일정 추가 불가능
    if (e.target.name === "date") {
      const today = new Date();
      const year = parseInt(e.target.value.split("-")[0]);
      const month = parseInt(e.target.value.split("-")[1]);
      const date = parseInt(e.target.value.split("-")[2]);

      if (
        today.getFullYear() === year &&
        today.getMonth() === month &&
        today.getDate() === date
      ) {
        alert("당일 일정은 추가하실 수 없습니다.");

        return false;
      }
    }

    setScheduleInfo({
      ...scheduleInfo,
      [e.target.name]: e.target.value,
    });
  };

  // 모집 인원
  const [recruitNum, setRecruitNum] = useState(0);
  const onChansgeRecruitNum = (e) => {
    if (parseInt(e.target.value) < 1) {
      alert("모집할 활동자 수는 한명 이상이여야 합니다.");
      return false;
    }

    setRecruitNum(parseInt(e.target.value));
  };

  const onClickCancelBtn = () => {
    if (isPopup) toggleSchedulePopup();
    else navigate(-1);
  };

  const regHelpRequest = () => {
    let scheduleList = [];

    if (repeatType === "X") {
      scheduleList.push({
        id: `${scheduleInfo.date}/${scheduleInfo.startTime}`,
        activityDate: scheduleInfo.date,
        activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
        activityTimeValue: scheduleInfo.startTime,
        activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
        activityTimeValue_end: scheduleInfo.endTime,
        recruitNum: recruitNum,
        value: scheduleInfo.startTime,
      });
    } else {
      // 반복을 시작할 date = 사용자가 선택한 일정의 일자
      const startDate = new Date(scheduleInfo.date);
      // 반복을 끝낼 date = 사용자가 선택한 반복 종료 일자
      const endDate = new Date(repeatDate);

      scheduleList = makeSchedule_new(
        startDate,
        endDate,
        repeatType,
        scheduleInfo,
        recruitNum
      );
    }

    console.log(scheduleList);

    const userIdx = window.sessionStorage.getItem("idx");
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/${userIdx}`;

    scheduleList.map((schedule, idx) => {
      const params = {
        helpUserIdx: userIdx,
        orgIdx: orgIdx,
        content: "F",
        activityIdx: activityIdx,
        activityDate: schedule.activityDate,
        activityTime: schedule.activityTime,
        activityTimeEnd: schedule.activityTime_end,
        recruitNum: recruitNum,
        createdUid: userIdx,
      };

      axios
        .post(URL, null, {
          params: params,
        })
        .then((res) => {
          if (idx === scheduleList.length - 1) {
            alert("등록되었습니다.");
            navigate(-1);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const onClickSaveBtn = () => {
    // 반복
    if (repeatType === "default") {
      alert("반복 타입을 선택해주세요.");
      return false;
    }

    // 반복 종료 날짜 -> 반복을 선택하지 않은 경우에만
    if (repeatType !== "X" && repeatDate === "") {
      alert("반복 종료 날짜를 선택해주세요.");
      return false;
    }

    // 날짜 선택
    if (scheduleInfo.date === "") {
      alert("활동 일자를 선택해주세요.");
      return false;
    }

    // 시작 시간
    if (scheduleInfo.startTime === "") {
      alert("시작 시간을 선택해주세요.");
      return false;
    }

    // 종료 시간
    if (scheduleInfo.endTime === "") {
      alert("종료 시작을 선택해주세요.");
      return false;
    }

    // 필요인원
    if (hideRecruitNum !== true && recruitNum < 1) {
      alert("필요인원(1명 이상)를 입력해주세요.");
      return false;
    }

    // 반복 타입, 반복 종료 날짜
    // 스케줄 정보(활동 일자, 시작 시간, 종료 시간)
    // 필요인원
    if (isPopup) addSchedules(repeatType, repeatDate, scheduleInfo, recruitNum);
    else regHelpRequest();
  };

  const getActivity = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_USER_SERVICE_API}/activity/${activityIdx}`)
      .then((res) => {
        const data = res.data;
        setOrgIdx(data.orgIdx);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [activityIdx]);

  useEffect(() => {
    if (!isPopup) getActivity();
  }, [getActivity, isPopup]);

  return (
    <div className="page page--main" data-page="shop">
      <SubHeader title="일정" toggleModal={toggleSchedulePopup} />

      <div className="page__content page_section page__content page_section--with-header-68 ">
        <div className="fieldsets">
          <div className="d-flex justify-space align-items-center">
            <h2 className="mb-0">일정 정보</h2>
          </div>
          <div className="form">
            <form className="">
              <h4 className="">반복(선택)</h4>
              <div className="form__row mb-20">
                <div className="form__select">
                  <select
                    name="repeatType"
                    className="required"
                    onChange={(e) => onChangeRepeatType(e)}
                    value={repeatType}
                  >
                    <option value="default" disabled>
                      선택
                    </option>
                    <option value="X">반복없음</option>
                    <option value="D">매일</option>
                    <option value="W">매주</option>
                    <option value="M">매달</option>
                  </select>
                </div>
              </div>
              {repeatType !== "X" && (
                <>
                  <h4 className="">반복 종료 날짜</h4>
                  <div className="mb-20 form__row">
                    <input
                      type="date"
                      name="repeatDate"
                      value={repeatDate}
                      className="form__input required"
                      placeholder="Date"
                      onChange={(e) => onChangeRepeatDate(e)}
                    />
                  </div>
                </>
              )}
            </form>
          </div>
          <div className="form">
            <form className="" id="ContactForm">
              <h4 className="">날짜 선택</h4>
              <div className="form__row mb-20">
                <div className="mb-20 form__row">
                  <input
                    type="date"
                    name="date"
                    value={scheduleInfo.date}
                    className="form__input required"
                    placeholder="Date"
                    onChange={(e) => onChangeScheduleInfo(e)}
                  />
                </div>
              </div>
              <div className="form__row mb-20">
                <h4 className="">시작 시간 선택</h4>
                <div className="mb-20 form__row">
                  <input
                    type="time"
                    name="startTime"
                    value={scheduleInfo.startTime}
                    className="form__input required"
                    placeholder="time"
                    onChange={(e) => onChangeScheduleInfo(e)}
                  />
                </div>
              </div>
              <div className="form__row mb-20">
                <h4 className="">종료 시간 선택</h4>
                <div className="mb-20 form__row">
                  <input
                    type="time"
                    name="endTime"
                    value={scheduleInfo.endTime}
                    className="form__input required"
                    placeholder="time"
                    onChange={(e) => onChangeScheduleInfo(e)}
                  />
                </div>
              </div>
              {hideRecruitNum ? null : (
                <div className="form__row mb-20">
                  <h4 className="">필요인원</h4>
                  <div className="mb-20 form__row">
                    <input
                      type="number"
                      name="recruitNum"
                      value={recruitNum}
                      className="form__input required"
                      placeholder="0"
                      onChange={(e) => onChansgeRecruitNum(e)}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="form__row mt-40">
          <input
            type="submit"
            name="submit"
            data-popup="conform_cancle"
            className="form__submit button button--gray button--50 open-popup"
            id="submit"
            value="취소"
          />
          <input
            type="button"
            name="submit"
            className="form__submit button button--main button--50 open-popup"
            data-popup="conform_addSchedule"
            id="submit"
            value="확인"
          />
        </div>
        <div className="space-h-100"></div>
      </div>

      <div id="bottom-toolbar" className="bottom-toolbar"></div>
      <ConformPopup
        text="일정 추가를 취소하시겠습니까? 추가하신 일정은 저장되지 않습니다."
        popupType="cancel"
        conformClick={() => onClickCancelBtn()}
      />
      <ConformPopup
        text="일정을 추가하시겠습니까?"
        popupType="addSchedule"
        conformClick={() => onClickSaveBtn()}
      />
    </div>
  );
};

export default ScheduleAdd;
