import axios from "axios";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

function ActivityGroupList(props) {
  const { activityIdx } = useParams();
  const { btnType, actIdx, groupInfo, changeVolunteerStatus } = props;
  const navigate = useNavigate();

  const chatDClickHandler = () => {
    navigate(`/org/chat/chatd/o2s`, {
      state: {
        orgId: 5, // 기관 Id
        userId: 5, // 사용자 Id
      },
    });
  };

  const chatDGroupHandler = () => {
    alert("채팅방 입장이 어렵습니다.");

    // navigate(`/org/chat/chatd/a2g`, {
    //   state: {
    //     activityId: actIdx ? actIdx : 1,
    //     groupId: groupInfo.groupIdx,
    //   },
    // });
  };

  // 불참 -> 참
  const changeNonePartToPart = () => {
    let data = new Object();

    data.activityScheduleIdx = groupInfo.scheduleIdx;
    data.orgIdx = groupInfo.orgIdx;
    data.groupName = groupInfo.groupName;
    data.updatedUid = window.sessionStorage.getItem("idx");

    changeVolunteerStatus("part", "group", data);
  };

  // 참 -> 불참
  const changePartToNonePart = () => {
    let data = new Object();

    data.activityScheduleIdx = groupInfo.scheduleIdx;
    data.orgIdx = groupInfo.orgIdx;
    data.groupName = groupInfo.groupName;
    data.updatedUid = window.sessionStorage.getItem("idx");

    changeVolunteerStatus("none-part", "group", data);
  };

  const requestActComplete = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-list/request-complete/alarm/${groupInfo.actListIdx}`;
    try {
      const response = await axios.post(url);

      if (response.status === 201) {
        alert("완료 요청을 보냈습니다.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const listActivityHistoryHandler = () => {
    navigate(`/org/profile/reportdetail/${groupInfo.actListIdx}`, {
      state: {},
    });
  };

  return (
    <div className="card mt-20 card--style-inline card--style-inline-bg card--style-round-corners p-10">
      <div
        className="user-details__thumb"
        onClick="location.href='#'"
        style={{ cursor: "pointer" }}
      >
        <img src={groupInfo.img} alt="" />
      </div>
      <div className="card__details card__details_100">
        <Link to={`/org/otherprofile/org/${groupInfo.orgIdx}`}>
          <h4 className="card__username fc_point1"> {groupInfo.groupName}</h4>
        </Link>

        <Link
          className=""
          to={`/org/profile/groupacthistory/${actIdx}/${groupInfo.scheduleIdx}/${groupInfo.groupIdx}`}
        >
          그룹 회원 보기 ({groupInfo.totalMemberCnt})
        </Link>

        <p className="card__info">{`${groupInfo.scheduleDate} ${groupInfo.scheduleTime}`}</p>
        <p className="card__info">{groupInfo.contact}</p>
        {groupInfo && groupInfo.volunteer === "F" && (
          <p className="card__info">참여 완료</p>
        )}

        {btnType === "nonParticipation" ? (
          <>
            <div
              onClick={() => changeNonePartToPart()}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              전체 미참여 처리 취소
            </div>
          </>
        ) : btnType === "history" ? (
          <>
            {groupInfo.reportStatus === "Y" ? (
              <div
                onClick={listActivityHistoryHandler}
                style={{ cursor: "pointer" }}
                className="button button--small button--gray float-right ml-10"
              >
                활동 내역 보기
              </div>
            ) : (
              <div
                onClick={requestActComplete}
                style={{ cursor: "pointer" }}
                className="button button--small button--gray float-right ml-10"
              >
                완료 요청
              </div>
            )}

            {groupInfo && groupInfo.volunteer !== "F" && (
              <div
                onClick={() => changePartToNonePart()}
                style={{ cursor: "pointer" }}
                className="button button--small button--gray float-right ml-10"
              >
                전체 미참여 처리
              </div>
            )}
          </>
        ) : (
          <>
            <div
              onClick={requestActComplete}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              완료 요청
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10 open-popup"
              data-popup="conform_participation"
            >
              참여 처리
            </div>
          </>
        )}

        {/* <div
          onClick={chatDGroupHandler}
          style={{ cursor: "pointer" }}
          className="button button--small button--main float-right ml-10"
        >
          그룹 메세지
        </div> */}
      </div>
    </div>
  );
}

export default ActivityGroupList;
