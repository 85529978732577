import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PartGroupListItem = ({
  userInfo,
  activityIdx,
  scheduleInfo,
  setSeletedInfo,
  updateStatusToCancelNonePart,
  updateStatusToNonePart,
}) => {
  const navigate = useNavigate();

  const soleClickHandler = () => {
    navigate(`/org/otherprofile/sole/${userInfo.userIdx}`, {
      state: {},
    });
  };

  const chatDGroupHandler = () => {
    alert("채팅방 입장이 어렵습니다.");

    // navigate(`/org/chat/chatd/a2g`, {
    //   state: {
    //     activityId: activityIdx,
    //     groupId: groupInfo.groupIdx,
    //   },
    // });
  };

  return (
    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
      <div
        className="user-details__thumb"
        onClick={soleClickHandler}
        style={{ cursor: "pointer" }}
      >
        <img src={userInfo.img} alt="" />
      </div>
      <div className="card__details">
        <Link to={`/org/otherprofile/sole/${userInfo.idx}`}>
          <h4 className="card__username fc_point1">{userInfo.nickname}</h4>
        </Link>
        <Link to={`/org/otherprofile/org/${userInfo.orgIdx}`}>
          <p className="card__info">{`${userInfo.orgTitle}/${userInfo.groupName}`}</p>
        </Link>
        <p className="card__info">{`${scheduleInfo.date} ${scheduleInfo.time}`}</p>
        <p className="card__info">{userInfo.contact}</p>
        <div
          onClick={chatDGroupHandler}
          style={{ cursor: "pointer" }}
          className="button button--small button--main float-right ml-10"
        >
          메세지
        </div>
        {
          {
            W: (
              <div
                onClick={() =>
                  // setSeletedInfo(userInfo.actListIdx, userInfo.volunteer)
                  updateStatusToNonePart(userInfo.actListIdx)
                }
                style={{ cursor: "pointer" }}
                data-popup="conform_absent"
                className="button button--small button--secondary float-right ml-10"
              >
                결석처리
              </div>
            ),
            N: (
              <div
                onClick={() =>
                  // setSeletedInfo(userInfo.actListIdx, userInfo.status)
                  updateStatusToCancelNonePart(userInfo.actListIdx)
                }
                style={{ cursor: "pointer" }}
                data-popup="conform_cancle"
                className="button button--small button--secondary float-right ml-10"
              >
                결석처리 취소
              </div>
            ),
          }[userInfo.volunteer]
        }
      </div>
    </div>
  );
};

export default PartGroupListItem;
