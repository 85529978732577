import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ActivitySchedule(props) {
  const { schedule } = props;
  const navigate = useNavigate();

  // 모집 중, 모집완료 className, Link 분기
  const [mainclassName, setMainClassName] = useState(
    "checkbox-option checkbox-option--half"
  );
  const [textClassName, setTextClassName] = useState("card__username");
  const [clickLink, setClickLink] = useState(
    `/org/profile/listactivity/${schedule && schedule.idx}/${schedule && schedule.activityScheduleIdx
    }`
  );

  useEffect(() => {
    if (schedule === undefined) return;

    if (schedule && schedule.recruit === "모집 마감") {
      setMainClassName("checkbox-option checkbox-option--half schedule-ended");
      setTextClassName("card__username fc_point1");
      setClickLink(
        `/org/profile/listactivityfinished/${schedule && schedule.activityScheduleIdx
        }`
      );
    }
  }, [schedule]);

  const listactivityClickHandler = () => {
    navigate(clickLink, {
      state: {
        actIdx: schedule.idx,
      },
    });
  };

  return (
    <div
      className={mainclassName}
      onClick={listactivityClickHandler}
      style={{ cursor: "pointer" }}
    >
      <div className="card__details">
        <h4 className={textClassName}>
          {schedule && schedule.recruitNum === "0"
            ? "제한없음"
            : `${schedule.recruitedNum}/${schedule.recruitNum}`}
          {schedule && `( ${schedule.recruit})`}
        </h4>
        {/* <p className="card__info">2020.01.01 오후 1시</p> */}
        <p className="card__info">
          {schedule && schedule.activityDate + " " + schedule.activityTime}
        </p>
      </div>
    </div>
  );
}

export default ActivitySchedule;
