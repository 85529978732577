import React from 'react';
import { Link } from 'react-router-dom';

function MainHeader(props) {
  return (
    <header className="header header--page header--fixed">
      <div className="header__inner">
        <div className="" data-panel="left">
          <Link to="">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </Link>
        </div>
        <div className="header__logo header__logo--text">
          <Link to="#">
            <img
              width="240px"
              src="/assets/images/logo-white.png"
              alt=""
              title=""
            />
          </Link>
        </div>
        <div className="header__icon header__icon--cart"></div>
      </div>
    </header>
  );
}

export default MainHeader;
