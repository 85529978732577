import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

function OrgItem(props) {
  const { folder, org } = props;
  const navigate = useNavigate();

  const itemClickHandler = () => {
    navigate(
      parseInt(window.sessionStorage.getItem("idx")) === org.id
        ? "/org/profile/org"
        : `/${folder}/otherprofile/org/${org.id}`,
      {
        state: {},
      }
    );
  };

  const defaultImgPath = "/assets/images/photos/img-p-org.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (org) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: org && org.id,
            imageStatus: "O",
          },
        })
        .then((res) => {
          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  const chageDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <Fragment>
      <div className="card card--style-thumb">
        <div
          className="card__thumb card__thumb--round-corners-full"
          onClick={itemClickHandler}
          style={{ cursor: "pointer" }}
        >
          <img src={imgPath} alt="" title="" onError={chageDefaultImg} />
        </div>
        <h4 className="card__username text-aline-center">
          {org && org.orgTitle}
        </h4>
      </div>
    </Fragment>
  );
}

export default OrgItem;
