import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import Panel from "../../../components/Panel";
import ActivityDetail from "../../../components/ActivityDetail";
import ActivityDetailHeader from "../../../components/ActivityDetailHeader";
import ScheduleContext from "../../../context/Schedule.context";
import axios from "axios";
import ConformPopup from "../../common/popup/ConformPopup";
import HelpScheduleList from "../../../components/HelpScheduleList";
import CheckLoginInfo from "../../../services/login-validation";
import { checkActivityPageAccess } from "../../../services/page-access-validation";

function DetailBene(props) {
  const { clearSchedule } = useContext(ScheduleContext);
  const { actIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const userIdx = sessionStorage.idx;

  const [activityData, setActivityData] = useState([]);
  const [helpConfirmScheduleData, setHelpConfirmScheduleData] = useState([]);
  const [helpRequestScheduleData, setHelpRequestScheduleData] = useState([]);

  const [helpConfirmSchedulePage, setHelpConfirmSchedulePage] = useState(1);
  const [helpRequestSchedulePage, setHelpRequestSchedulePage] = useState(1);

  const offset = 6;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 도움 요청 승인된 일정 조회
  const getHelpConfirmSchedule = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/reqeust/done_sec/${userIdx}`;

    axios
      .get(URL, {
        params: {
          activityIdx: actIdx,
        },
      })
      .then((res) => {
        const { totalRows, data } = res.data;
        console.log(data);
        setHelpConfirmScheduleData(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 도움 요청한 일정 조회
  const getHelpRequestSchedule = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/reqeust/not_done/${userIdx}`;
    axios
      .get(URL, {
        params: {
          activityIdx: actIdx,
        },
      })
      .then((res) => {
        const { totalRows, data } = res.data;
        setHelpRequestScheduleData(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getActivity = () => {
    // 활동, 활동상세, 기관정보 조회
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;
    axios.get(URL).then((res) => {
      //console.log("활동");
      //console.log(res.data);

      if (res.data.beneType.includes("X") || res.data.beneType.includes("O")) {
        alert("개인 수요자를 모집하지 않는 활동입니다.");

        if (location.state && location.state.isRedirect === true) {
          navigate(-2);
        } else {
          navigate(-1);
        }
      }

      setActivityData(res.data);
      setHideContent(false);
    });
  };

  const calendarClickHandler = () => {
    // 일정 선택 전에 ScheduleContext  초기화
    clearSchedule();

    navigate("/sole/activity/calendar", {
      state: {
        actIdx: actIdx,
      },
    });
  };

  const chatClickHandler = () => {
    navigate("/sole/chat/chatd/s2a", {
      state: {
        userId: window.sessionStorage.getItem("idx"),
        activityId: actIdx,
      },
    });
  };

  const [selHelpScheduleIdx, setSelHelpScheduleIdx] = useState("");

  // 내 도움 일정 취소, 요청취소, 삭제
  const deleteHelpSchedule = (helpStatus) => {
    if (selHelpScheduleIdx !== "") {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/${selHelpScheduleIdx}`;
      axios
        .delete(URL)
        .then((res) => {
          // console.log(res.data);
          navigate(0);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const [hideContent, setHideContent] = useState(true);

  // 활동, 활동상세, 기관정보 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        checkActivityPageAccess(actIdx).then((res) => {
          if (res === "ERROR") {
            navigate("/");
          } else if (res === "TRUE") {
            getActivity();
            getHelpConfirmSchedule();
            getHelpRequestSchedule();
          } else {
            navigate(res, {
              state: {
                isRedirect: true,
              },
            });
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [actIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <ActivityDetailHeader title="수요자 모집 활동 상세" actIdx={actIdx} />
        {/* PAGE CONTENT */}
        <div className="page__content page__content page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <ActivityDetail folder="sole" activityData={activityData} />
          </div>
          <div className="page_section">
            <div className="page__title-bar mt-48 mb-24">
              <h1 className="page__title">내 활동 일정</h1>
            </div>
            <div className="page__title-bar mt-48">
              <h2>도움 요청 승인된 일정({helpConfirmScheduleData.length})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {helpConfirmScheduleData &&
                helpConfirmScheduleData
                  .map((helpSchedule, idx) => (
                    <HelpScheduleList
                      key={idx}
                      helpSchedule={helpSchedule}
                      setSelHelpScheduleIdx={setSelHelpScheduleIdx}
                      showQrBtn={false}
                    />
                  ))
                  .slice(0, helpConfirmSchedulePage * offset)}
              <div className="buttons buttons--centered mb-20">
                {helpConfirmScheduleData.length >
                  helpConfirmSchedulePage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(
                        setHelpConfirmSchedulePage,
                        helpConfirmSchedulePage
                      );
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="page__title-bar mt-48">
              <h2>도움 요청한 일정({helpRequestScheduleData.length})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {helpRequestScheduleData &&
                helpRequestScheduleData
                  .map((helpSchedule, idx) => (
                    <HelpScheduleList
                      key={idx}
                      helpSchedule={helpSchedule}
                      setSelHelpScheduleIdx={setSelHelpScheduleIdx}
                      showQrBtn={false}
                    />
                  ))
                  .slice(0, helpRequestSchedulePage * offset)}
              <div className="buttons buttons--centered mb-20">
                {helpRequestScheduleData.length >
                  helpRequestSchedulePage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(
                        setHelpRequestSchedulePage,
                        helpRequestSchedulePage
                      );
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="under_button">
              <div className="form__row mt-40">
                <input
                  onClick={calendarClickHandler}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--50"
                  id="submit"
                  value="도움 요청"
                />
                <input
                  onClick={chatClickHandler}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--50"
                  id="submit"
                  value="문의하기"
                />
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformPopup
        popupType="delete"
        text="정말 삭제하시겠습니까?"
        conformClick={deleteHelpSchedule}
      />
      <ConformPopup
        popupType="requestcancle"
        text="정말 요청 취소하시겠습니까?"
        conformClick={deleteHelpSchedule}
      />
      <ConformPopup
        popupType="schedulecancle"
        text="정말 일정 취소하시겠습니까?"
        conformClick={deleteHelpSchedule}
      />
    </Fragment>
  );
}

export default DetailBene;
