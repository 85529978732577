import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

const isStatusOff = (status) => {
  if (status === "Y") {
    return true;
  } else {
    return false;
  }
};

function Alert() {
  const [scheduleAlarm, setScheduleAlarm] = useState(null);
  const [requestAlarm, setRequestAlarm] = useState(null);
  const [eventNoticeAlarm, setEventNoticeAlarm] = useState(null);

  const onChangeAlarm = async (id) => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/setting/alarm/${window.sessionStorage.getItem("idx")}`;

    let data = new Object();
    data.alarmType = id;

    switch (id) {
      case "S":
        data.status = scheduleAlarm ? "N" : "Y";
        setScheduleAlarm(!scheduleAlarm);
        break;
      case "R":
        data.status = requestAlarm ? "N" : "Y";
        setRequestAlarm(!requestAlarm);
        break;
      case "E":
        data.status = eventNoticeAlarm ? "N" : "Y";
        setEventNoticeAlarm(!eventNoticeAlarm);
        break;
      default:
        break;
    }

    try {
      await axios.put(url, data);
    } catch (e) {
      console.log(e);
      alert("알림 설정 중, 오류가 발생하였습니다.");
    }
  };

  const getAlarmStatus = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/setting/alarm/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const {
          scheduledActivityAlarm,
          activityApplicationsRequestAlarm,
          eventAlarm,
        } = response.data;

        setScheduleAlarm(isStatusOff(scheduledActivityAlarm));
        setRequestAlarm(isStatusOff(activityApplicationsRequestAlarm));
        setEventNoticeAlarm(isStatusOff(eventAlarm));
      }
    } catch (e) {
      console.log(e);
      alert("알림 상태 조회 중, 오류가 발생하였습니다.");
    }
  }, []);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getAlarmStatus();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getAlarmStatus]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="알림 설정" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          <ul className="list-menu">
            <li className="list-menu__row">
              <div className="list-menu__details">
                <div className="list-menu__t">예정된 활동에 대한 미리 알림</div>
              </div>
              <div className="list-menu__info default__icon">
                <div className="list-menu__icon">
                  <div className="switch">
                    <input
                      type="checkbox"
                      hidden="hidden"
                      checked={scheduleAlarm}
                    />
                    <label
                      className="switch__label"
                      htmlFor="enable"
                      onClick={() => onChangeAlarm("S")}
                    />
                  </div>
                </div>
              </div>
            </li>
            <li className="list-menu__row">
              <div className="list-menu__details">
                <div className="list-menu__t">
                  활동 신청 및 요청에 대한 알림
                </div>
              </div>
              <div className="list-menu__info default__icon">
                <div className="list-menu__icon">
                  <div className="switch">
                    <input
                      type="checkbox"
                      hidden="hidden"
                      checked={requestAlarm}
                    />
                    <label
                      className="switch__label"
                      htmlFor="enable"
                      onClick={() => onChangeAlarm("R")}
                    />
                  </div>
                </div>
              </div>
            </li>
            <li className="list-menu__row">
              <div className="list-menu__details">
                <div className="list-menu__t">이벤트 및 공지 알림</div>
              </div>
              <div className="list-menu__info default__icon">
                <div className="list-menu__icon">
                  <div className="switch">
                    <input
                      type="checkbox"
                      hidden="hidden"
                      checked={eventNoticeAlarm}
                    />
                    <label
                      className="switch__label"
                      htmlFor="enable"
                      onClick={() => onChangeAlarm("E")}
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default Alert;
