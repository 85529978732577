import React, { useState } from "react";
import SubHeader from "../../../components/SubHeader";
import ConformDelete from "../../common/popup/ConformDelete";
import ConformPopup from "../../common/popup/ConformPopup";

const ScheduleEdit = ({
  toggleEditScheduleModal,
  editSchedule,
  deleteSchedule,
  prevInfo,
  hideRecruitNum,
}) => {
  // 선택 일자
  const [scheduleInfo, setScheduleInfo] = useState({
    id: prevInfo.id,
    date: prevInfo.activityDate,
    startTime: prevInfo.activityTimeValue,
    endTime: prevInfo.activityTimeValue_end,
  });
  const onChangeScheduleInfo = (e) => {
    // 당일 일정 추가 불가능
    if (e.target.name === "date") {
      const today = new Date();
      const date = new Date(e.target.value);

      if (
        today.getFullYear() === date.getFullYear() &&
        today.getMonth() === date.getMonth() &&
        today.getDate() === date.getDate()
      ) {
        alert("당일 일정은 추가하실 수 없습니다.");
        return;
      }
    }

    setScheduleInfo({
      ...scheduleInfo,
      [e.target.name]: e.target.value,
    });
  };

  // 모집 인원
  const [recruitNum, setRecruitNum] = useState(prevInfo.recruitNum);
  const onChansgeRecruitNum = (e) => {
    if (parseInt(e.target.value) < 1) {
      alert("모집할 활동자 수는 한명 이상이여야 합니다.");
      return false;
    }

    setRecruitNum(parseInt(e.target.value));
  };

  const onClickCancelBtn = () => {
    toggleEditScheduleModal();
  };

  const onClickSaveBtn = () => {
    // 날짜 선택
    if (scheduleInfo.date === "") {
      alert("활동 일자를 선택해주세요.");
      return false;
    }

    // 시작 시간
    if (scheduleInfo.startTime === "") {
      alert("시작 시간을 선택해주세요.");
      return false;
    }

    // 종료 시간
    if (scheduleInfo.endTime === "") {
      alert("종료 시작을 선택해주세요.");
      return false;
    }

    // 필요인원
    if (hideRecruitNum !== true && recruitNum < 1) {
      alert("필요인원(1명 이상)를 입력해주세요.");
      return false;
    }

    // 스케줄 정보(활동 일자, 시작 시간, 종료 시간)
    // 필요인원

    if (typeof scheduleInfo.id === "number") {
      editSchedule(scheduleInfo, recruitNum, false);
    } else {
      editSchedule(scheduleInfo, recruitNum, true);
    }
  };

  const onClickDelBtn = () => {
    if (typeof scheduleInfo.id === "number") {
      deleteSchedule(scheduleInfo.id, prevInfo.recruitNum, false);
    } else {
      deleteSchedule(scheduleInfo.id, prevInfo.recruitNum, true);
    }
  };

  return (
    <div className="page page--main" data-page="shop">
      <SubHeader title="일정" toggleModal={toggleEditScheduleModal} />

      <div className="page__content page_section page__content page_section--with-header-68 ">
        <div className="fieldsets">
          <div className="d-flex justify-space align-items-center">
            <h2 className="mb-0">일정 정보</h2>
          </div>
          <div className="form">
            <form className="" id="ContactForm" method="post" action="">
              <h4 className="">날짜 선택</h4>
              <div className="form__row mb-20">
                <div className="mb-20 form__row">
                  <input
                    type="date"
                    name="date"
                    value={scheduleInfo.date}
                    className="form__input required"
                    placeholder="Date"
                    onChange={(e) => onChangeScheduleInfo(e)}
                  />
                </div>
              </div>
              <div className="form__row mb-20">
                <h4 className="">시작 시간 선택</h4>
                <div className="mb-20 form__row">
                  <input
                    type="time"
                    name="startTime"
                    value={scheduleInfo.startTime}
                    className="form__input required"
                    placeholder="time"
                    onChange={(e) => onChangeScheduleInfo(e)}
                  />
                </div>
              </div>
              <div className="form__row mb-20">
                <h4 className="">종료 시간 선택</h4>
                <div className="mb-20 form__row">
                  <input
                    type="time"
                    name="endTime"
                    value={scheduleInfo.endTime}
                    className="form__input required"
                    placeholder="time"
                    onChange={(e) => onChangeScheduleInfo(e)}
                  />
                </div>
              </div>
              {hideRecruitNum ? null : (
                <div className="form__row mb-20">
                  <h4 className="">필요인원</h4>
                  <div className="mb-20 form__row">
                    <input
                      type="number"
                      name="recruitNum"
                      value={recruitNum}
                      className="form__input required"
                      placeholder="0"
                      onChange={(e) => onChansgeRecruitNum(e)}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="form__row mt-40">
          <input
            type="submit"
            name="submit"
            data-popup="conform_cancle"
            className="form__submit button button--gray button--50 open-popup"
            id="submit"
            value="취소"
          />
          <input
            type="button"
            name="submit"
            className="form__submit button button--main button--50 open-popup"
            data-popup="conform_editSchedule"
            id="submit"
            value="확인"
          />
        </div>
        <div className="form__row">
          <input
            type="submit"
            name="submit"
            data-popup="conform_delete"
            className="form__submit button button--gray button--full open-popup"
            id="submit"
            value="삭제"
          />
        </div>
        <div className="space-h-100"></div>
      </div>

      <div id="bottom-toolbar" className="bottom-toolbar"></div>
      <ConformPopup
        text="일정 수정을 취소하시겠습니까? 수정하신 일정은 저장되지 않습니다."
        popupType="cancel"
        conformClick={() => onClickCancelBtn()}
      />
      <ConformPopup
        text="일정을 수정하시겠습니까?"
        popupType="editSchedule"
        conformClick={() => onClickSaveBtn()}
      />
      <ConformDelete conformClick={onClickDelBtn} />
    </div>
  );
};

export default ScheduleEdit;
