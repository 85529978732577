import React from "react";

const calcLastPage = (itemNum, onePage) => {
  if (itemNum % onePage === 0) {
    return parseInt(itemNum / onePage);
  } else {
    return parseInt(itemNum / onePage) + 1;
  }
};

const CommunityPageNav = ({ pageNumber, getPageNumber, totalNum, count }) => {
  const lastPage = calcLastPage(totalNum, count);

  const calcFirstPage = () => {
    if (pageNumber % 5 === 0) {
      return pageNumber - 5 + 1;
    } else {
      return pageNumber - parseInt(pageNumber % 5) + 1;
    }
  };

  const calcEndPage = () => {
    let endpage;

    if (pageNumber % 5 === 0) {
      endpage = pageNumber;
    } else {
      endpage = (parseInt(pageNumber / 5) + 1) * 5;
    }

    if (endpage >= lastPage) {
      return lastPage;
    } else {
      return endpage;
    }
  };

  const goPickPage = (e) => {
    getPageNumber(parseInt(e.target.innerText));
  };

  const goPrevPage = () => {
    getPageNumber(pageNumber - 1);
  };

  const goNextPage = () => {
    getPageNumber(pageNumber + 1);
  };

  const drawPaging = () => {
    let pageAry = [];

    for (let i = calcFirstPage(); i <= calcEndPage(); i++) {
      pageAry.push(
        <li className={i === pageNumber ? "page-item active" : "page-item"}>
          <span
            className="page-link"
            aria-label={`${i}`}
            onClick={(e) => goPickPage(e)}
            key={i}
          >
            {i}
          </span>
        </li>
      );
    }

    return pageAry;
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination pagination-sm ">
        <li className={pageNumber === 1 ? "page-item disabled" : "page-item"}>
          <span
            className="page-link"
            onClick={() => pageNumber !== 1 && getPageNumber(1)}
            aria-label="Previous"
          >
            <span aria-hidden="true" className="material-icons">
              처음
            </span>
          </span>
        </li>
        <li className={pageNumber === 1 ? "page-item disabled" : "page-item"}>
          <span
            className="page-link"
            aria-label="Previous"
            onClick={() => pageNumber !== 1 && goPrevPage()}
          >
            <span aria-hidden="true" className="material-icons">
              이전
            </span>
          </span>
        </li>
        {drawPaging()}
        <li
          className={
            pageNumber === lastPage ? "page-item disabled" : "page-item"
          }
        >
          <span
            className="page-link"
            aria-label="Next"
            onClick={() => pageNumber !== lastPage && goNextPage()}
          >
            <span className="sr-only">다음</span>
          </span>
        </li>
        <li
          className={
            pageNumber === lastPage ? "page-item disabled" : "page-item"
          }
        >
          <span
            className="page-link"
            onClick={() => pageNumber !== lastPage && getPageNumber(lastPage)}
            aria-label="Next"
          >
            <span className="sr-only">마지막</span>
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default CommunityPageNav;
