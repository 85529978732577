import React from "react";
import { Link, useNavigate } from "react-router-dom";

const parseTime = (time) => {
  if (typeof time === "undefined" || time === "" || time === null) {
    return "";
  }

  let hour = parseInt(time.split(":")[0]);
  let min = parseInt(time.split(":")[1]);
  let sec = parseInt(time.split(":")[2]);

  let result = "";

  if (hour !== 0) {
    result += `${hour}시간 `;
  }

  if (min !== 0) result += `${min}분 `;

  if (sec !== 0) result += `${sec}초`;

  return result;
};

const WaitingOnSchedule = ({ scheduleInfo }) => {
  const navigate = useNavigate();

  const chatdgroupClickHandler = () => {
    alert("채팅방 입장이 어렵습니다.");

    return;
    // navigate("/org/chat/chatd/a2g", {
    //   state: {
    //     activityId: scheduleInfo.actId,
    //     groupId: scheduleInfo.scheduleId,
    //   },
    // });
  };

  const actdetailClickHandler = () => {
    navigate(`/org/profile/actdetail/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const detailpartClickHandler = () => {
    navigate(`/org/activity/detailpart/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const detailbeneClickHandler = () => {
    navigate(`/org/activity/detailbene/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const listactivityClickHandler = () => {
    navigate(
      `/org/profile/listactivity/${scheduleInfo.actId}/${scheduleInfo.scheduleId}`,
      {
        state: {},
      }
    );
  };

  const setLabel = () => {
    if (scheduleInfo.type === "BENE") {
      return (
        <span className="caption__category tag_bene card__list--centered">
          활동 수요자
        </span>
      );
    } else {
      if (scheduleInfo.orgType === "O") {
        return (
          <span className="caption__category card__list--centered tag_operator">
            주관
          </span>
        );
      } else {
        return (
          <span className="caption__category tag_part card__list--centered">
            활동 활동자
          </span>
        );
      }
    }
  };

  const setButtonGroup = () => {
    if (scheduleInfo.type === "BENE") {
      return (
        <div className="form__row mt-20">
          <input
            onClick={detailbeneClickHandler}
            style={{ cursor: "pointer" }}
            type="submit"
            name="submit"
            className="form__submit button button--main button--full"
            id="submit"
            value="활동보기"
          />
        </div>
      );
    } else {
      if (scheduleInfo.orgType === "Q") {
        return (
          <div className="form__row mt-20">
            {/* <input
              onClick={chatdgroupClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--333"
              id="submit"
              value="그룹 메세지"
            /> */}
            <input
              onClick={actdetailClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--50"
              id="submit"
              value="활동보기"
            />
            <input
              onClick={listactivityClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--50"
              id="submit"
              value="QR코드 보기"
            />
          </div>
        );
      } else {
        return (
          <div className="form__row mt-20">
            <input
              onClick={detailpartClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--full"
              id="submit"
              value="활동보기"
            />
          </div>
        );
      }
    }
  };

  return (
    <div className="card card--style-icon card--style-round-corners">
      <h2 className="text-aline-center">알림!</h2>
      <h4 className="card__list card__list--centered fc_point1">
        {scheduleInfo.activityTitle}
      </h4>
      <h4 className="card__list card__list--centered">활동이</h4>
      <div className="card__plan fc_point1">
        {parseTime(scheduleInfo.leftTime)}
      </div>
      <h4 className="card__list--centered">뒤에 시작합니다.</h4>
      <h4 className="card__list--centered">
        시작 시간 : {scheduleInfo.startTime}
      </h4>
      {scheduleInfo.type === "PART" && (
        <Link to={`/org/schedule/userlistpart/${scheduleInfo.scheduleId}`}>
          <h3 className="card__list--centered lh-2">{`참여(지정) ${scheduleInfo.recruitNum
            }명 / ${scheduleInfo.totalRecruitNum === 0
              ? "제한없음"
              : `필요인원 ${scheduleInfo.totalRecruitNum}명`
            }`}</h3>
        </Link>
      )}
      {setLabel()}
      {setButtonGroup()}
    </div>
  );
};

export default WaitingOnSchedule;
