import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UrlContext from "../context/Url.context";

function ActGroupMember(props) {
  const { actGroupMember } = props;
  const urlContext = useContext(UrlContext);

  const defaultImgPath = "/assets/images/photos/img-p-sole.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (actGroupMember) {
      const params = {
        pkIdx: actGroupMember && actGroupMember.userIdx,
        imageStatus: "U",
      };

      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: params,
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, [actGroupMember]);

  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <div class="card card--style-thumb">
      <div class="card__thumb card__thumb--round-corners-full">
        <Link
          to={`/org/otherprofile/sole/${
            actGroupMember && actGroupMember.userIdx
          }`}
        >
          <img src={imgPath} alt="" title="" onError={changeDefaultImg} />
        </Link>
      </div>
      <a href="../other-profile/sole.html">
        <h4 class="card__username text-aline-center">
          {actGroupMember && actGroupMember.nickname}
        </h4>
      </a>
      <div class="form__row mb-20">
        <div class="form__select">
          <select
            name="status"
            class="required"
            value={actGroupMember && actGroupMember.groupAdminStatus}
          >
            <option value="U">일반</option>
            <option value="A">관리자</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default ActGroupMember;
