import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import { convertDashToDot } from "../../../util/convert-date-function";
import ConformPopup from "../../common/popup/ConformPopup";
import parseArrayToString from "../../../util/util-function";
import CheckLoginInfo from "../../../services/login-validation";
import FileUpload from "../../common/FileUpload";

function Report() {
  const { actListIdx } = useParams();
  const navigate = useNavigate();

  const [activityInfo, setActivityInfo] = useState(null);
  const [reportInfo, setReportInfo] = useState(null);

  const [imgFiles, setImgFiles] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);

  const [imgId, setImgId] = useState(0);

  const [deletedImgIdxList, setDeletedImgIdxList] = useState([]);

  const onChangeImgFile = (e) => {
    const imgFileAry = e.target.files;

    setImgFiles(imgFiles.concat({ file: imgFileAry[0], id: imgId }));

    for (let i = 0; i < imgFileAry.length; i++) {
      if (imgFileAry[i]) {
        let reader = new FileReader();

        // 1. 파일 읽어서 버퍼에 저장
        reader.readAsDataURL(imgFileAry[i]);

        // 파일 상태 업데이트
        reader.onloadend = () => {
          // 읽기 완료시, 아래 코드 실행
          const base64 = reader.result;

          if (base64) {
            var base64Sub = base64.toString();

            // 파일 base64 상태 업데이트
            setImgBase64((imgBase64) => [
              ...imgBase64,
              { img: base64Sub, id: imgId + i },
            ]);
          }
        };
      }

      if (imgFileAry.length - 1 === i) {
        setImgId(imgId + i + 1);
      }
    }
  };

  const onClickDeleteBtn = (rId) => {
    let fileAry = imgFiles;
    let base64Ary = imgBase64;

    fileAry = fileAry.filter((file) => file.id !== rId);
    base64Ary = base64Ary.filter((base64) => base64.id !== rId);

    setImgFiles(fileAry);
    setImgBase64(base64Ary);
  };

  const onClickDeleteBtnOriginal = (rId) => {
    let fileAry = reportInfo.images;

    fileAry = fileAry.filter((file) => file.idx !== rId);

    setReportInfo({
      ...reportInfo,
      images: fileAry,
    });

    let delAry = deletedImgIdxList;
    delAry.push(rId);

    setDeletedImgIdxList(delAry);
  };

  const onChangeMoreInformation = (data) => {
    setReportInfo({
      ...reportInfo,
      moreInformation: data,
    });
  };

  const cancelReport = () => {
    goBack();
  };

  const updateReport = async () => {
    let data = new Object();

    data.idx = reportInfo.idx;
    data.actListIdx = actListIdx;
    data.content = reportInfo.moreInformation;

    if (deletedImgIdxList.length > 0) {
      data.deletedImages = parseArrayToString(deletedImgIdxList);
    }

    data.updatedUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/report`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        let formData = null;

        if (imgFiles.length === 0) {
          axios
            .post(
              `${process.env.REACT_APP_USER_SERVICE_API}/report/review/alarm/${actListIdx}`
            )
            .then((res) => {
              if (res.status === 201) {
                alert("수정되었습니다.");
                navigate("/sole/profilesole/sole");
              }
            });
        } else {
          for (let i = 0; i < imgFiles.length; i++) {
            if (imgFiles[i] !== undefined) {
              formData = new FormData();

              formData.append("file", imgFiles[i].file);
              formData.append("pkIdx", reportInfo.idx);
              formData.append("imageStatus", "R");

              if (imgFiles.length === i + 1) {
                addImageFile(formData, true);
              } else {
                addImageFile(formData, false);
              }
            }
          }
        }
      }
    } catch (e) {
      alert("활동 내용 수정 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const addImageFile = async (data, endFlag) => {
    const url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/uploadfile`;

    try {
      const resposne = await axios.post(url, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (endFlag) {
        // 활동 내역 보완 알림 송부
        axios
          .post(
            `${process.env.REACT_APP_USER_SERVICE_API}/report/review/alarm/${actListIdx}`
          )
          .then((res) => {
            if (res.status === 201) {
              alert("수정되었습니다.");
              navigate("/sole/profilesole/sole");
            }
          });
      }
    } catch (e) {
      console.log(e);
      alert("리포트 이미지 추가 중,오류가 발생하였습니다.");
    }
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onHandleImgError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`;
  };

  const setImageList = () => {
    let ary = [];

    // 기존 이미지 파일
    reportInfo.images.map((imgInfo) =>
      ary.push(
        <div className="card card--style-thumb" key={imgInfo.idx}>
          <div className="card__thumb card__thumb--round-corners">
            <img
              src={`${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${imgInfo.imgUrl}`}
              alt=""
              onError={(e) => onHandleImgError(e)}
            />
          </div>
          {reportInfo.status !== "D" && (
            <div
              className="button button--full button--gray"
              onClick={() => onClickDeleteBtnOriginal(imgInfo.idx)}
            >
              삭제
            </div>
          )}
        </div>
      )
    );

    // 유저가 추가한 이지 파일
    imgBase64.map((base64) =>
      ary.push(
        <div className="card card--style-thumb" key={base64.id}>
          <div className="card__thumb card__thumb--round-corners">
            {/* <Link to="/sole/activity/detailcarriedout"> */}
            <img src={base64.img} alt="" />
            {/* </Link> */}
          </div>
          <div
            className="button button--full button--gray"
            onClick={() => onClickDeleteBtn(base64.id)}
          >
            삭제
          </div>
        </div>
      )
    );

    return ary;
  };

  const [hideContent, setHideContent] = useState(true);

  const [showFileModal, setShowFileModal] = useState(false);
  const toggleFileModal = () => {
    setShowFileModal(!showFileModal);
  };
  const getFile = (file, name, imgUrl, type) => {
    if (type === "img") {
      setImgFiles(imgFiles.concat({ file: file, id: imgId }));

      if (file) {
        let reader = new FileReader();

        // 1. 파일 읽어서 버퍼에 저장
        reader.readAsDataURL(file);

        // 파일 상태 업데이트
        reader.onloadend = () => {
          // 읽기 완료시, 아래 코드 실행
          const base64 = reader.result;

          if (base64) {
            var base64Sub = base64.toString();

            // 파일 base64 상태 업데이트
            setImgBase64((imgBase64) => [
              ...imgBase64,
              { img: base64Sub, id: imgId },
            ]);
          }
        };
      }
      setImgId(imgId + 1);
    }

    toggleFileModal();
  };

  const buttonView = () => {
    switch (reportInfo.status) {
      case "D":
        return (
          <div className="cards cards--6" id="loadlist-01">
            <div className="table card card--style-thumb mb-20">
              <div className="card__details">
                <p className="card__list text-center fc-color-gray">
                  완료 처리된 활동 내역입니다.
                </p>
              </div>
            </div>
          </div>
        );
      case "W":
      case "R":
      default:
        return (
          <div className="form__row mt-40">
            <input
              onClick="location.href='#'"
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_completion"
              id="submit"
              value="완료하기"
            />
          </div>
        );
    }
  };

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getActivityScheduleInfo();
        getActivityReportInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getActivityScheduleInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/simple-info/act-list/${actListIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setActivityInfo({
            title: response.data.title,
            activityDate: response.data.activityDate,
            activityTime: response.data.activityTime,
            activityIdx: response.data.activityIdx,
            scheduleIdx: response.data.activityScheduleIdx,
            actListIdx: response.data.actListIdx,
          });
        }
      } catch (e) {
        console.log(e);
        alert("존재하지 않는 활동 또는 스케줄 입니다.");
        goBack();
      }
    };

    const getActivityReportInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/report/${actListIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const data = response.data;

          setReportInfo({
            idx: data.idx,
            moreInformation: data.content,
            images: data.images,
            status: data.status,
          });
        }
      } catch (e) {
        console.log(e);
        alert("");
      }
    };
  }, [actListIdx, goBack]);

  if (hideContent) {
    return null;
  }

  if (showFileModal) {
    return (
      <FileUpload
        type="img"
        getFile={getFile}
        toggleFileModal={toggleFileModal}
      />
    );
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 내역" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="d-flex justify-space align-items-center">
            <h2 className="mb-0">{activityInfo && activityInfo.title}</h2>
          </div>
          <div className="fieldsets">
            <ul className="custom-listing mb-20">
              <li>
                <span>
                  활동 일시 : <br />
                </span>
                {activityInfo &&
                  `${convertDashToDot(activityInfo.activityDate)} ${
                    activityInfo.activityTime
                  }`}
              </li>
            </ul>
          </div>
          <div className="cards cards--12" id="loadlist-01">
            {reportInfo && setImageList()}
          </div>
          <div className="fieldsets">
            {reportInfo && reportInfo.status !== "D" && (
              <div className="upload-filebox">
                <form id="_frm" method="post" enctype="multipart/form-data">
                  <label htmlFor="ex_file">사진 업로드</label>
                  <input
                    // type={
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? "button"
                    //     : "file"
                    // }
                    // type="button"
                    type={"file"}
                    // style={
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? { display: "none" }
                    //     : { display: "block" }
                    // }
                    // style={{ display: "none" }}
                    id="ex_file"
                    accept=".jpg, .jpeg, .png"
                    // accept="image/*"
                    // onClick={() =>
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? toggleFileModal()
                    //     : null
                    // }
                    // onClick={() => toggleFileModal()}
                    capture="camera"
                    // onChange={(e) =>
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? null
                    //     : onChangeImgFile(e)
                    // }
                    onChange={(e) => onChangeImgFile(e)}
                  />
                </form>
              </div>
            )}
          </div>
          <div className="form mt-40">
            <form id="ContactForm" method="post" action>
              <h4>활동 내용</h4>
              <div className="form__row mb-20">
                <textarea
                  name="ContactComment"
                  className="form__textarea required"
                  placeholder="내용을 기입해주세요."
                  defaultValue={reportInfo && reportInfo.moreInformation}
                  onChange={(e) => onChangeMoreInformation(e.target.value)}
                  readOnly={reportInfo && reportInfo.status === "D"}
                />
              </div>
            </form>
          </div>
          {reportInfo && buttonView()}
          <div className="space-h-100" />
        </div>
      </div>
      <ConformPopup
        text="취소하시겠습니까?작성하신 내용은 저장되지 않습니다."
        popupType="cancle"
        conformClick={cancelReport}
      />
      <ConformPopup
        text="완료하시겠습니까?작성하신 내용을 저장합니다."
        popupType="completion"
        conformClick={updateReport}
      />
      <Navigationbar />
    </Fragment>
  );
}

export default Report;
