import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import dayjs from "dayjs";

function CalendarLibrary(props) {
  const {
    folder,
    saveFunc,
    chk,
    readonly,
    actSchduelIdx,
    type,
    actIdx,
    actListIdx,
    area,
  } = props;

  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());

  const onChange = (date) => {
    setDate(date);
  };

  const itemClickHandler = (date) => {
    const nowDate = new Date();
    nowDate.setHours(0, 0, 0, 0);
    if (date < nowDate) {
      alert("이전 날짜는 선택할 수 없습니다.");
      return;
    }

    if (readonly) {
      return;
    }

    let url = "";

    if (saveFunc && saveFunc !== null) saveFunc();
    url = `/${folder}/activity/schedulelist/${date}`;

    if (chk === "schedulelist") {
      url = `/sole/okphone/schedulelist/${date}`;
    } else if (chk === "schedulelist2") {
      url = `/${folder}/activity/schedulelist2/${date}`;
    }

    navigate(url, {
      state:
        chk === "schedulelist"
          ? {
              date: date,
              actSchduelIdx: actSchduelIdx,
              type: type,
              actIdx: actIdx,
              actListIdx: actListIdx,
              area: area,
            }
          : {
              date: date,
              actSchduelIdx: actSchduelIdx,
              type: type,
              actIdx: actIdx,
              actListIdx: actListIdx,
            },
    });
  };

  return (
    <CalendarContainer>
      <Calendar
        className="calender"
        onChange={onChange}
        formatDay={(locale, date) => dayjs(date).format("DD")}
        calendarType="US"
        value={date}
        onClickDay={itemClickHandler}
        tileContent={({ date, view }) => {
          let html = [];

          switch (area) {
            case "법률":
              if (date.getDay() === 1 || date.getDay() === 3) {
                html.push(
                  <div
                    className="dot"
                    style={{ backgroundColor: "#63d0b6" }}
                  ></div>
                );
              }
              break;
            case "세무":
              if (date.getDay() === 2) {
                html.push(
                  <div
                    className="dot"
                    style={{ backgroundColor: "#fcc631" }}
                  ></div>
                );
              }
              break;
            case "건축":
              if (date.getDay() === 3) {
                html.push(
                  <div
                    className="dot"
                    style={{ backgroundColor: "#fb836f" }}
                  ></div>
                );
              }
              break;
            case "법무":
              if (date.getDay() === 4) {
                html.push(
                  <div
                    className="dot"
                    style={{ backgroundColor: "#c2d74c" }}
                  ></div>
                );
              }
              break;
            case "노무":
              if (date.getDay() === 5) {
                html.push(
                  <div
                    className="dot"
                    style={{ backgroundColor: "#8553ac" }}
                  ></div>
                );
              }
              break;
            default:
              break;
          }

          return (
            <>
              <div>{html}</div>
            </>
          );
        }}
      />
    </CalendarContainer>
  );
}

export default CalendarLibrary;

const CalendarContainer = styled.div`
  .react-calendar {
    width: 100%;
    border: none;
    margin-top: 30px;
  }
`;
