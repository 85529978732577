import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UrlContext from "../context/Url.context";
import { convertTimeStampToString } from "../util/convert-date-function";
import { onHandleImgError, setAddressString } from "../util/util-function";

function ActivitySwiperItem(props) {
  const { activity } = props;
  const urlContext = useContext(UrlContext);

  const defaultImgPath = "/assets/images/food/img-default.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (activity) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: activity && activity.idx,
            imageStatus: "A",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  return (
    <div className="swiper-slide slider-thumbs__slide slider-thumbs__slide--1h">
      <div className="card__thumb slider-thumbs__image slider-thumbs__image--round-corners">
        <Link
          to={`/sole/activity/detailcarriedout/${activity && activity.idx}`}
        >
          <img src={imgPath} alt="" onError={(e) => onHandleImgError(e, "A")} />
        </Link>
      </div>
      <div className="card__details">
        <a className="card__username fc_point1">
          {activity && activity.orgTitle}
        </a>
        <h3 className="card__title">{activity && activity.title}</h3>
        <p className="card__info">
          {activity && convertTimeStampToString(activity.createdAt)}

          {activity &&
            (activity.activityType === "F"
              ? ` / ${setAddressString(activity.address1, activity.address2)}`
              : " / 온라인")}
        </p>
      </div>
    </div>
  );
}

export default ActivitySwiperItem;
