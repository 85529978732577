import React from "react";
import { Link } from "react-router-dom";
import { onHandleImgError } from "../util/util-function";

function ActivityHistoryAllList(props) {
  const { info } = props;

  const setBeneType = () => {
    if (info.beneType === "A" || info.beneType === "AX") {
      return "개인, 기관/단체";
    } else if (info.beneType === "U" || info.beneType === "UX") {
      return "개인";
    } else if (info.beneType === "O" || info.beneType === "OX") {
      return "기관/단체";
    } else {
      return "없음";
    }
  };

  return (
    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
      <div className="img-details__thumb">
        <img src={info.img} alt="" onError={(e) => onHandleImgError(e, "A")} />
      </div>
      <div className="card__details">
        <h4 className="card__list">{info.title}</h4>
        {/* <p className="card__info">{info.dateTime}</p>
        <p className="card__info">수요자 : {setBeneType()}</p>
        <p className="card__info">활동인원 : {info.recruitNumber}인</p> */}
        {info.totalSchedulRows === 0 ? (
          <p className="card__info">활동 내역이 없습니다</p>
        ) : (
          <Link
            className="mt-40"
            to={`/org/profile/listactivityhistory/${info.idx}`}
          >
            {info.totalSchedulRows}건의 활동 내역 상세보기
          </Link>
        )}
      </div>
    </div>
  );
}

export default ActivityHistoryAllList;
