import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ActivityList from "../../../components/ActivityList";
import ActivityMemberList from "../../../components/ActivityMemberList";
import ListActivitySearchComponent from "../../../components/ListActivitySearchComponent";
import { convertDashToDot } from "../../../util/convert-date-function";
import axios from "axios";
import ActivityGroupList from "../../../components/ActivityGroupList";
import CheckLoginInfo from "../../../services/login-validation";

function ListActivityHistory(props) {
  const { activityIdx } = useParams();

  const [searchInfo, setSearchInfo] = useState({
    startDate: "",
    endDate: "",
  });

  const onChangeSearchInfo = (e) => {
    setSearchInfo({
      ...searchInfo,
      [e.target.name]: e.target.value,
    });
  };

  const setPeriodString = () => {
    if (searchInfo.startDate === "" && searchInfo.endDate === "") {
      return "전체 기간 조회";
    }

    let period;

    if (searchInfo.startDate === "") {
      period = "";
    } else {
      period = `${convertDashToDot(searchInfo.startDate)}`;
    }

    if (searchInfo.endDate === "") {
      period += " ~ ";
    } else {
      period += ` ~ ${convertDashToDot(searchInfo.endDate)}`;
    }

    return period;
  };

  const [scheduleList, setScheduleList] = useState(null);
  const [scheduleTotalRows, setScheduleTotalRows] = useState(0);

  const [absenteeList, setAbsenteeList] = useState(null);
  const [absenteeTotalRow, setAbsenteeTotalRows] = useState(0);

  const getScheduleList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/history/detail/part/${activityIdx}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
        },
      });

      if (response.status === 200) {
        const { totalRows, activityInfo, participantList } = response.data;

        setScheduleTotalRows(totalRows);

        let ary = [];

        let partList = [];
        let schedule = null;

        for (let i = 0; i < participantList.length; i++) {
          partList = [];

          const { scheduleInfo, partUserList, partGroupList } =
            participantList[i];

          schedule = {
            activityScheduleIdx: scheduleInfo.id,
            activityDate: scheduleInfo.activityDate,
            activityTime: scheduleInfo.activityTime,
            activityTimeEnd : scheduleInfo.activityTimeEnd,
            recruitedNum: scheduleInfo.joinNum,
            recruitNum: scheduleInfo.recruitNum,
            recruit:
              scheduleInfo.joinNum < scheduleInfo.recruitNum
                ? "모집 중"
                : "모집 마감",
            beneType: activityInfo.beneType,
            orgTitle: Object.keys(scheduleInfo).includes("beneOrgTitle")
              ? scheduleInfo.beneOrgTitle
              : null,
            orgIdx: scheduleInfo.helpUserIdx,
          };

          for (let j = 0; j < partUserList.length; j++) {
            partList.push({
              actListIdx: partUserList[j].id,
              userIdx: partUserList[j].userIdx,
              img: Object.keys(partUserList[j]).includes("img")
                ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partUserList[j].img}`
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
              nickname: partUserList[j].nickname,
              scheduleDate: convertDashToDot(scheduleInfo.activityDate),
              scheduleTime: scheduleInfo.activityTime,
              phoneNum: partUserList[j].contact,
              scheduleIdx: scheduleInfo.id,
              type: "U",
              reportStatus: partUserList[j].activityReportStatus,
            });
          }

          for (let j = 0; j < partGroupList.length; j++) {
            partList.push({
              actListIdx: partGroupList[j].adminActListIdx,
              orgIdx: partGroupList[j].orgIdx,
              groupIdx: partGroupList[j].groupIdx,
              img: Object.keys(partGroupList[j]).includes("img")
                ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partGroupList[j].img}`
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
              groupName: partGroupList[j].groupName,
              totalMemberCnt: partGroupList[j].groupMemberCnt,
              scheduleDate: convertDashToDot(scheduleInfo.activityDate),
              scheduleTime: scheduleInfo.activityTime,
              contact: partGroupList[j].contact,
              scheduleIdx: scheduleInfo.id,
              type: "O",
              reportStatus: partGroupList[j].activityReportStatus,
            });
          }

          ary.push({
            scheduleInfo: schedule,
            partUserList: partList,
          });
        }

        setScheduleList(ary);
      }
    } catch (e) {
      alert("활동 스케줄 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [activityIdx, searchInfo]);

  const getAbsenteeList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/history/detail/none-part/${activityIdx}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
        },
      });

      if (response.status === 200) {
        const { totalRows, nonePartUserList, nonePartGroupList } =
          response.data;

        setAbsenteeTotalRows(totalRows);

        let ary = [];

        for (let i = 0; i < nonePartUserList.length; i++) {
          ary.push({
            userIdx: nonePartUserList[i].userIdx,
            img: Object.keys(nonePartUserList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${nonePartUserList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            nickname: nonePartUserList[i].nickname,
            scheduleDate: convertDashToDot(nonePartUserList[i].activityDate),
            scheduleTime: nonePartUserList[i].activityTime,
            phoneNum: nonePartUserList[i].contact,
            scheduleIdx: nonePartUserList[i].activityScheduleIdx,
            type: "U",
          });
        }

        for (let i = 0; i < nonePartGroupList.length; i++) {
          ary.push({
            orgIdx: nonePartGroupList[i].orgIdx,
            groupIdx: nonePartGroupList[i].idx,
            img: Object.keys(nonePartGroupList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${nonePartGroupList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            groupName: nonePartGroupList[i].groupName,
            totalMemberCnt: nonePartGroupList[i].groupMemberCnt,
            scheduleDate: convertDashToDot(nonePartGroupList[i].activityDate),
            scheduleTime: nonePartGroupList[i].activityTime,
            contact: nonePartGroupList[i].contact,
            scheduleIdx: nonePartGroupList[i].activityScheduleIdx,
            type: "O",
          });
        }

        setAbsenteeList(ary);
      }
    } catch (e) {
      console.log(e);
      alert("결석/미참여 활동자 목록 조회에 실패하였습니다.");
    }
  }, [activityIdx, searchInfo]);

  const changeVolunteerStatus = async (status, partUserType, data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/volunteer-status/${partUserType}/${status}/${activityIdx}`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        getScheduleList();
        getAbsenteeList();
      }
    } catch (e) {
      alert(
        `${partUserType === "user" ? "일반" : "그룹"
        } 참여자의 참여 상태 변경 중, 오류가 발생하였습니다.`
      );

      console.log(e);
    }
  };

  const setFilePeriod = () => {
    if (searchInfo.startDate !== "" && searchInfo.endDate !== "") {
      return `${searchInfo.startDate} ~ ${searchInfo.endDate}`;
    }

    let today = new Date();

    if (searchInfo.startDate !== "" && searchInfo.endDate === "") {
      return `${searchInfo.startDate} ~ ${today.toISOString().slice(0, 10)}`;
    }

    if (searchInfo.startDate === "" && searchInfo.endDate !== "") {
      return `~ ${searchInfo.endDate}`;
    }

    return "전체";
  };

  const getFile = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/api/v1/excel/activity/report/${activityIdx}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
          type: searchInfo.target === "default" ? null : searchInfo.target,
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const file_url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );

        const link = document.createElement("a");
        link.href = file_url;
        link.setAttribute(
          "download",
          `기관 활동 일정 내역 - (${setFilePeriod()}).xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getScheduleList();
        getAbsenteeList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getAbsenteeList, getScheduleList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main page--act-history" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 내역" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <ListActivitySearchComponent
            searchInfo={searchInfo}
            onChangeSearchInfo={onChangeSearchInfo}
            hideType={true}
          />
          <div className="d-flex justify-space align-items-center pt-40">
            <h2>
              기간 :
              {setPeriodString()}
              {/* ({scheduleTotalRows}) */}
            </h2>
            <div>
              <input
                type="submit"
                name="submit"
                className="form__submit button button--main"
                id="submit"
                onClick={() => getFile()}
                value="활동 내역 다운로드"
              />
            </div>
          </div>
          <div className="page__title-bar mt-48">
            <h2>일정 목록 ({scheduleTotalRows})</h2>
          </div>
          <div className="cards cards--6" id="loadlist-01">
            {scheduleList &&
              scheduleList.map((scheduleInfo) => (
                <ActivityList
                  type="history"
                  folder="org"
                  schedule={scheduleInfo.scheduleInfo}
                  partUserList={scheduleInfo.partUserList}
                  changeVolunteerStatus={changeVolunteerStatus}
                  actIdx={activityIdx}
                />
              ))}
          </div>
          <div className="page__title-bar mt-48">
            <h2>결석/미참여 활동자 목록 ({absenteeTotalRow})</h2>
          </div>
          <div className="cards cards--11">
            {absenteeList &&
              absenteeList.map((absenteeInfo) =>
                absenteeInfo.type === "U" ? (
                  <ActivityMemberList
                    btnType="nonParticipation"
                    part={absenteeInfo}
                    changeVolunteerStatus={changeVolunteerStatus}
                  />
                ) : (
                  <ActivityGroupList
                    btnType="nonParticipation"
                    actIdx={activityIdx}
                    groupInfo={absenteeInfo}
                    changeVolunteerStatus={changeVolunteerStatus}
                  />
                )
              )}
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default ListActivityHistory;
