import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import { Modal } from "../../../components/DaumPostModal";
import SelectedTime from "../../../components/SelectedTime";
import AddActScList from "../profile-add-act/AddActScList";
import OrgList from "../../../components/OrgList";
import ConformSave from "../../common/popup/ConformSave";
import CheckLoginInfo from "../../../services/login-validation";
import ScheduleAdd from "../profile-add-act/ScheduleAdd";
import { makeSchedule_new } from "../../../util/schedule-function";
import {
  convertDotToDash,
  convertTimeFormat,
} from "../../../util/convert-date-function";
import ScheduleEdit from "../profile-add-act/ScheduleEdit";
import FileUpload from "../../common/FileUpload";

function RegisterRequested(props) {
  const navigate = useNavigate();
  const { activityIdx } = useParams();

  const [categoryList, setCategoryList] = useState(null);

  const [activityInfo, setActivityInfo] = useState({
    title: "",
    categoryId: "default",
    activityType: "default",
    address1: "",
    address2: "",
    postCode: "",
    partType: "default",
    isPrivate: "default",
  });

  const [partActivityInfo, setPartActivityInfo] = useState({
    isRepeat: "default",
    repeatDate: "",
    totalTime: "default",
    joinType: "default",
    recruitDate: "default",
    memo: "",
    recruitNum: 0,
  });

  const [requestedOrgInfo, setRequestedOrgInfo] = useState(null);

  const [addrRadio, setAddrRadio] = useState("0");
  const onClickRadioBtn = (value) => {
    setAddrRadio(value);
  };

  const [imgFile, setImgFile] = useState(null);
  const [imgFileUrl, setImgFileUrl] = useState(
    `${process.env.PUBLIC_URL}/assets/images/food/img-default.jpg`
  );

  const onChangeActivityInfo = (e) => {
    setActivityInfo({
      ...activityInfo,
      [e.target.name]: e.target.value,
    });
  };

  const onChangePartActivityInfo = (e) => {
    setPartActivityInfo({
      ...partActivityInfo,
      [e.target.name]: e.target.value,
    });
  };

  const setImageFromFile = (file) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = function () {
        setImgFileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeImgFile = (file) => {
    if (file) {
      const fileEx = file.name.substring(
        file.name.lastIndexOf("."),
        file.name.length
      );

      if (fileEx !== ".jpg" && fileEx !== ".jpeg" && fileEx !== ".png") {
        alert('이미지 확장자는 ".jpg, .jpeg, .png"만 업로드 가능합니다.');
        return;
      }

      if (file.size > 10000000) {
        alert("이미지 파일크기는 10M를 초과할 수 없습니다.");
        return;
      }

      setImgFile(file);
      setImageFromFile(file);
    }
  };

  const setCategoryOption = () => {
    let ary = [];

    ary.push(
      <option value="default" key="default" disabled>
        선택
      </option>
    );

    for (let i = 0; i < categoryList.length; i++) {
      ary.push(
        <option
          value={categoryList[i].categoryId}
          key={categoryList[i].categoryId}
        >
          {categoryList[i].categoryName}
        </option>
      );
    }

    return ary;
  };

  const [showAddressModal, setShowAddresModal] = useState(false);

  const toggleAddressModal = () => {
    setShowAddresModal(!showAddressModal);
  };

  const onChangeAddress1 = (address1) => {
    setActivityInfo({
      ...activityInfo,
      address1: address1,
    });
  };

  const onChangePostCode = (postCode) => {
    setActivityInfo({
      ...activityInfo,
      postCode: postCode,
    });
  };

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const toggleScheduleModal = () => {
    setShowScheduleModal(!showScheduleModal);
  };

  const [scheduleList, setScheduleList] = useState([]);
  const [originalScheduleList, setOriginalScheduleList] = useState([]);

  // 스케줄 추가
  // 반복 타입, 반복 종료 날짜
  // 스케줄 정보(시작 시간, 종료 시간, 필요인원)
  const addSchedule = (repeatType, repeatDate, scheduleInfo, recruitNum) => {
    toggleScheduleModal();

    // TODO : 중복 일정 검사

    let response = scheduleList;

    // 반복 타입 입력 오류
    if (repeatType === "" || repeatType === null) {
      alert("반복 타입 입력 오류");

      return false;
    }
    // 반복 없음
    else if (repeatType === "X") {
      response.push({
        id: `${scheduleInfo.date}/${scheduleInfo.startTime}`,
        activityDate: scheduleInfo.date,
        activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
        activityTimeValue: scheduleInfo.startTime,
        activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
        activityTimeValue_end: scheduleInfo.endTime,
        recruitNum: recruitNum,
        value: scheduleInfo.startTime,
      });
    }
    // 반복
    else {
      // TODO : 반복 종료 날짜까지 사용자가 선택한 일정들을 메일/매주/매달에 따라 반복시키면 됨

      // 반복을 시작할 date = 사용자가 선택한 일정의 일자
      const startDate = new Date(scheduleInfo.date);
      // 반복을 끝낼 date = 사용자가 선택한 반복 종료 일자
      const endDate = new Date(repeatDate);

      let _schedule = makeSchedule_new(
        startDate,
        endDate,
        repeatType,
        scheduleInfo,
        recruitNum
      );

      _schedule.map((schedule) => response.push(schedule));

      calcTotalRecruitNumber(_schedule, "new");
    }

    setScheduleList(response);
  };

  const calcTotalRecruitNumber = (ary, type) => {
    let sum_num = 0;

    // 새로운 스케줄일 경우
    if (type === "new") {
      for (let i = 0; i < ary.length; i++) {
        sum_num += ary[i].recruitNum;
      }
      for (let i = 0; i < originalScheduleList.length; i++) {
        sum_num += originalScheduleList[i].recruitNum;
      }
    }
    // 기존 스케줄일 경우
    else {
      for (let i = 0; i < scheduleList.length; i++) {
        sum_num += scheduleList[i].recruitNum;
      }
      for (let i = 0; i < ary.length; i++) {
        sum_num += ary[i].recruitNum;
      }
    }

    setPartActivityInfo({
      ...partActivityInfo,
      recruitNum: sum_num,
    });
  };

  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false);
  const toggleEditScheduleModal = () => {
    setShowEditScheduleModal(!showEditScheduleModal);
  };

  const [editScheduleInfo, setEditScheduleInfo] = useState(null);
  const getEditScheduleInfo = (info) => {
    setEditScheduleInfo(info);
    toggleEditScheduleModal();
  };

  // db로 수정 요청 보낼 스케줄
  const [editScheduleList, setEditScheduleList] = useState([]);

  // 스케줄 정보(id, 활동일자, 시작 시간, 종료 시간)
  // 활동 모집자 수
  const editSchedule = (scheduleInfo, recruitNum, isNewSchedule) => {
    // 새로 추가된 스케줄 수정
    if (isNewSchedule) {
      let ary = scheduleList;

      for (let i = 0; i < ary.length; i++) {
        if (ary[i].id === scheduleInfo.id) {
          ary[i] = {
            id: `${scheduleInfo.date}/${scheduleInfo.startTime}`,
            activityDate: scheduleInfo.date,
            activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
            activityTimeValue: scheduleInfo.startTime,
            activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
            activityTimeValue_end: scheduleInfo.endTime,
            recruitNum: recruitNum,
            value: scheduleInfo.startTime,
          };

          break;
        }
      }

      setScheduleList(ary);
      calcTotalRecruitNumber(ary, "new");
    }
    // 기존의 스케줄 수정
    else {
      let ary = originalScheduleList;
      let edit_ary = editScheduleList;

      for (let i = 0; i < ary.length; i++) {
        if (ary[i].id === scheduleInfo.id) {
          ary[i] = {
            id: scheduleInfo.id,
            activityDate: scheduleInfo.date,
            activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
            activityTimeValue: scheduleInfo.startTime,
            activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
            activityTimeValue_end: scheduleInfo.endTime,
            recruitNum: recruitNum,
            value: scheduleInfo.startTime,
          };

          // 기존에 수정한 이력이 있는지 체크
          let j = 0;

          for (j = 0; j < edit_ary.length; j++) {
            // 기존에 수정한 이력이 있는 경우
            if (edit_ary[j].id === scheduleInfo.id) {
              edit_ary[j] = {
                id: scheduleInfo.id,
                activityDate: scheduleInfo.date,
                activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
                activityTimeValue: scheduleInfo.startTime,
                activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
                activityTimeValue_end: scheduleInfo.endTime,
                recruitNum: recruitNum,
                value: scheduleInfo.startTime,
              };

              break;
            }
          }

          // 새로운 수정
          if (j === 0 || j === edit_ary.length - 1) {
            edit_ary.push({
              id: scheduleInfo.id,
              activityDate: scheduleInfo.date,
              activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
              activityTimeValue: scheduleInfo.startTime,
              activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
              activityTimeValue_end: scheduleInfo.endTime,
              recruitNum: recruitNum,
              value: scheduleInfo.startTime,
            });
          }

          break;
        }
      }

      setOriginalScheduleList(ary);
      setEditScheduleList(edit_ary);

      calcTotalRecruitNumber(ary, "old");
    }

    toggleEditScheduleModal();
  };

  const deleteSchedule = (delId, delNum, isNewSchedule) => {
    if (delId === null) {
      alert("일정 삭제 중, 오류가 발생하였습니다.");
      return false;
    }

    // 새로 추가한 리스트에서 삭제가 일어날 경우
    if (isNewSchedule) {
      let ary = scheduleList;
      ary = ary.filter((schedule) => schedule.id !== delId);

      setScheduleList(ary);
    } else {
      let ary = originalScheduleList;
      ary = ary.filter((schedule) => schedule.id !== delId);

      setOriginalScheduleList(ary);

      let dary = delOriginScheduleIdList;
      dary.push(delId);

      setDelOriginScheduleIdList(dary);
    }

    // 필요인원 빼기
    setPartActivityInfo({
      ...partActivityInfo,
      recruitNum: partActivityInfo.recruitNum - delNum,
    });

    // 일정 수정 모달 닫기
    toggleEditScheduleModal();

    return;
  };

  const [delOriginScheduleIdList, setDelOriginScheduleIdList] = useState([]);

  const [showScCoinModal, setShowScCoinModal] = useState(false);
  const onToggleScCoinModal = () => {
    setShowScCoinModal(!showScCoinModal);
  };

  const [scCoinId, setScCoinId] = useState(null);
  const getScCoinId = (selectedId) => {
    setScCoinId(selectedId);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getCategoryList();
        getImgFile();
        getActivityInfo();
        getActivityDetailInfo();
        getActicityScheduleInfo();
        getRequestedOrgInfo();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
    const getCategoryList = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/category`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { data } = response.data;
          let ary = [];

          for (let i = 0; i < data.length; i++) {
            ary.push({
              categoryId: data[i].idx,
              categoryName: data[i].category,
            });
          }

          setCategoryList(ary);
        }
      } catch (e) {
        alert("카테고리 목록 조회 중, 오류가 발생하였습니다,");
        console.log(e);
      }
    };

    const getImgFile = async () => {
      const url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;

      try {
        const res = await axios.get(url, {
          params: {
            pkIdx: activityIdx,
            imageStatus: "A",
          },
        });

        if (res.status === 200) {
          setImgFileUrl(
            `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`.replaceAll(
              " ",
              "%20"
            )
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getActivityInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${activityIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setActivityInfo({
            title: response.data.title,
            categoryId: response.data.categoryIdx,
            activityType: response.data.activityType,
            address1: response.data.address1,
            address2: Object.keys(response.data).includes("address2")
              ? response.data.address2
              : "",
            postCode: "",
            partType: Object.keys(response.data).includes("partType")
              ? response.data.partType
              : "default",
            isPrivate: Object.keys(response.data).includes("isPrivate")
              ? response.data.isPrivate
              : "default",
          });

          if (response.data.address1 === "") {
            setAddrRadio("1");
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getActivityDetailInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity_details/${activityIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setPartActivityInfo({
            isRepeat: response.data.isRepeat,
            repeatDate: response.data.repeatDate,
            totalTime: response.data.totalTime,
            joinType: response.data.joinType,
            recruitDate: response.data.recruitDate,
            memo: response.data.memo,
            recruitNum: response.data.recruitNum,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getActicityScheduleInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/recruit/mine/${activityIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { data } = response.data;

          let ary = [];
          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: data[i].activityScheduleIdx,
              activityDate: data[i].activityDate,
              activityTime: data[i].activityTime,
              activityTimeValue: data[i].activityTime24,
              activityTime_end: data[i].activityTimeEnd, // 종료 시간
              activityTimeValue_end: data[i].activityTime24End,
              recruitNum: parseInt(data[i].recruitNum),
            });
          }

          setOriginalScheduleList(ary);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getRequestedOrgInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/requested/org/${activityIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setRequestedOrgInfo({
            idx: response.data.id,
            contact: response.data.contact,
            orgTitle: response.data.orgTitle,
            img: Object.keys(response.data).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${response.data.img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };
  }, [activityIdx]);

  const conformClick = (e) => {
    if (activityInfo.title.trim() === "") {
      alert("제목을 입력해주세요");
      return;
    }

    if (activityInfo.categoryId === "default") {
      alert("카테고리를 선택해주세요.");
      return;
    }

    if (activityInfo.activityType === "default") {
      alert("활동 방식을 선택해주세요.");
      return;
    } else if (activityInfo.activityType === "F" && addrRadio === 0) {
      if (
        activityInfo.address1 === undefined ||
        activityInfo.address1.trim === ""
      ) {
        alert("주소를 검색해주세요.");
        return;
      }

      if (activityInfo.address2.trim() === "") {
        alert("상세주소를 입력해주세요.");
        return;
      }
    } else {
      setActivityInfo({
        ...activityInfo,
        address1: "",
        address2: "",
      });
    }

    if (activityInfo.partType === "default") {
      alert("활동자 모집을 선택해주세요.");
      return;
    }

    if (activityInfo.isPrivate === "default" || activityInfo.isPrivate === "") {
      alert("공개여부를 선택해주세요.");
      return;
    }

    // 활동자 모집안함이 아닐경우만 활동자 모집 선택사항 체크
    if (activityInfo.partType !== "X") {
      // if (partActivityInfo.isRepeat === "default") {
      //   alert("반복을 선택해주세요.");
      //   return;
      // } else {
      //   // 반복 없음이 아닌 경우 반복 종료 날짜 필수 기입
      //   if (partActivityInfo.isRepeat !== "X") {
      //     if (partActivityInfo.repeatDate === "") {
      //       alert("반복종료 날짜를 선택해주세요.");
      //       return;
      //     }
      //   }
      // }

      // if (partActivityInfo.totalTime === "default") {
      //   alert("소요 시간을 선택해주세요.");
      //   return;
      // }

      if (partActivityInfo.joinType === "default") {
        alert("활동 참여 방식을 선택해주세요.");
        return;
      }

      if (partActivityInfo.recruitDate === "default") {
        alert("모집 마감을 선택해주세요");
        return;
      }
    }

    const activity = {
      orgIdx: sessionStorage.idx,
      userIdx: sessionStorage.idx,
      title: activityInfo.title,
      categoryIdx: activityInfo.categoryId,
      activityType: activityInfo.activityType,
      address1: activityInfo.address1,
      address2: activityInfo.address2,
      beneType: "E",
      partType: activityInfo.partType,
      isPrivate: activityInfo.isPrivate,
      createdUid: window.sessionStorage.getItem("idx"),
    };

    const actSelect = {
      id: activityIdx,
      isRepeat: null,
      repeatDate: null,
      totalTime: partActivityInfo.totalTime,
      recruitDate: partActivityInfo.recruitDate,
      recruitNum: partActivityInfo.recruitNum,
      memo: partActivityInfo.memo,
      joinType: partActivityInfo.joinType,
      createdUid: window.sessionStorage.getItem("idx"),
    };

    axios
      .put(
        `${process.env.REACT_APP_USER_SERVICE_API}/request/activity/${activityIdx}`,
        activity
      )
      .then((res) => {
        // 활동자 모집이 아닌경우 activity_details, activity_schedule

        // 이미지 등록
        if (imgFile !== "" && imgFile !== undefined && imgFile !== null) {
          const formData = new FormData();
          formData.append("file", imgFile);
          formData.append("pkIdx", activityIdx);
          formData.append("imageStatus", "A");

          let uploadUrl = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/uploadfile`;

          axios
            .post(uploadUrl, formData)
            .then((res) => {
              // console.log(res.data);
            })
            .catch((err) => {
              alert("이미지 업로드 에러");
              console.log(err.resonse);
            });
        }

        axios
          .put(
            `${process.env.REACT_APP_USER_SERVICE_API}/activity_details/${activityIdx}`,
            actSelect
          )
          .then((res) => {
            // 일정 수정
            // 1. 기존 일정 중, 삭제된 일정이 있다면 삭제
            if (delOriginScheduleIdList.length > 0) {
              let deleteIds = "";
              for (let i = 0; i < delOriginScheduleIdList.length; i++) {
                if (delOriginScheduleIdList.length - 1 === i) {
                  deleteIds += delOriginScheduleIdList[i];
                } else {
                  deleteIds += `${delOriginScheduleIdList[i]},`;
                }
              }

              axios.delete(
                `${process.env.REACT_APP_USER_SERVICE_API}/activity-schedule/${activityIdx}`,
                {
                  params: {
                    deleteIds: deleteIds,
                    updatedUid: window.sessionStorage.getItem("idx"),
                  },
                }
              );
            }

            // 2. 새롭게 등록된 일정 추가
            scheduleList.forEach((item) => {
              let activitySchedule = {
                activityDate: convertDotToDash(item.activityDate),
                activityTime: item.activityTime,
                activityTimeEnd: item.activityTime_end,
                recruitNum: item.recruitNum,
                createdUid: sessionStorage.idx,
              };

              axios
                .post(
                  `${process.env.REACT_APP_USER_SERVICE_API}/activity-schedule/${activityIdx}`,
                  activitySchedule
                )
                .then((res) => { })
                .catch((error) => {
                  console.log(error.response);
                });
            });

            // 3. 기존 일정 수정
            editScheduleList.forEach((item, index) => {
              let activitySchedule = {
                activityDate: convertDotToDash(item.activityDate),
                activityTime: item.activityTime,
                activityTimeEnd: item.activityTime_end,
                recruitNum: item.recruitNum,
                updatedUid: sessionStorage.idx,
              };

              axios
                .put(
                  `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/${item.id}`,
                  activitySchedule
                )
                .then(() => {
                  if (scheduleList.length - 1 === index) {
                    alert("등록되었습니다.");
                    navigate("/org/profile/org");
                  }
                })
                .catch((error) => {
                  console.log(error.response);
                });
            });

            alert("등록되었습니다.");
            navigate("/org/profile/org");
          })
          .catch((error) => {
            console.log(error.response);
          });
      })
      .catch((error) => {
        alert("활동 수정 에러");
        console.log(error.response);
      });
  };

  const [showFileModal, setShowFileModal] = useState(false);
  const toggleFileModal = () => {
    setShowFileModal(!showFileModal);
  };
  const getFile = (file, name, imgUrl, type) => {
    if (type === "img") {
      onChangeImgFile(file);
    } else {
    }

    toggleFileModal();
  };

  if (hideContent) {
    return null;
  }

  if (showFileModal) {
    return (
      <FileUpload
        type="img"
        getFile={getFile}
        toggleFileModal={toggleFileModal}
      />
    );
  }

  if (showScCoinModal) {
    return (
      <AddActScList
        toggleModal={onToggleScCoinModal}
        getScCoinId={getScCoinId}
        scCoinId={scCoinId}
      />
    );
  }

  if (showScheduleModal) {
    return (
      <ScheduleAdd
        toggleSchedulePopup={toggleScheduleModal}
        addSchedules={addSchedule}
        hideRecruitNum={activityInfo.partType === "E"}
        isPopup={true}
      />
    );
  }

  if (showEditScheduleModal) {
    return (
      <ScheduleEdit
        toggleEditScheduleModal={toggleEditScheduleModal}
        editSchedule={editSchedule}
        deleteSchedule={deleteSchedule}
        prevInfo={editScheduleInfo}
        hideRecruitNum={activityInfo.partType === "E"}
      />
    );
  }

  return (
    <Fragment>
      <Modal
        open={showAddressModal}
        close={toggleAddressModal}
        setModalOpen={toggleAddressModal}
        setAddress={onChangeAddress1}
        setPostcode={onChangePostCode}
      />
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="도움 요청된 활동 등록하기" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="fieldsets">
            <div
              className="swiper-container slider-simple slider-simple  mb-20 slider-init"
              data-paginationtype="bullets"
              data-spacebetweenitems={0}
              data-itemsperview={1}
            >
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide slider-simple__slide"
                  style={{
                    backgroundImage: `url(${imgFileUrl})`,
                  }}
                ></div>
              </div>
            </div>
            <div className="upload-filebox mt-20">
              <form id="_frm" method="post" enctype="multipart/form-data">
                <label htmlFor="ex_file">사진 업로드</label>
                <input
                  // type={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? "button"
                  //     : "file"
                  // }
                  // type="button"
                  type={"file"}
                  // style={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? { display: "none" }
                  //     : { display: "block" }
                  // }
                  // style={{ display: "none" }}
                  id="ex_file"
                  accept=".jpg, .jpeg, .png"
                  // accept="image/*"
                  capture="camera"
                  // onClick={() =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? toggleFileModal()
                  //     : null
                  // }
                  // onClick={() => toggleFileModal()}
                  // onChange={(e) =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? null
                  //     : onChangeImgFile(e.target.files[0])
                  // }
                  onChange={(e) => onChangeImgFile(e.target.files[0])}
                />
              </form>
            </div>
            {/* <div
              onClick={onToggleScCoinModal}
              style={{ cursor: "pointer" }}
              className="button button--full button--line mt-40"
            >
              서초코인 활동목록 불러오기
            </div> */}
          </div>
          <div className="fieldsets mt-48">
            <div className="d-flex justify-space align-items-center">
              <h2 className="mb-0">활동 개요</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <h4>제목</h4>
                <div className="form__row mb-20">
                  <input
                    type="text"
                    name="title"
                    className="form__input required"
                    placeholder="활동 제목을 입력해주세요"
                    value={activityInfo.title}
                    onChange={(e) => onChangeActivityInfo(e)}
                  />
                </div>
                <h4>카테고리</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="categoryId"
                      className="required"
                      value={activityInfo.categoryId}
                      // key={activityInfo.categoryId}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      {categoryList && setCategoryOption()}
                    </select>
                  </div>
                </div>
                <h4>활동방식</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="activityType"
                      className="required"
                      value={activityInfo.activityType}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value="O">온라인</option>
                      <option value="F">오프라인</option>
                    </select>
                  </div>
                </div>
                {activityInfo.activityType !== "O" && (
                  <>
                    <h4>활동 장소/주소</h4>
                    <div className="form__radio">
                      <input
                        type="radio"
                        id="contactChoice1"
                        name="contact"
                        value={0}
                        checked={addrRadio === "0"}
                        onClick={(e) => onClickRadioBtn(e.target.value)}
                      />
                      <label htmlFor="contactChoice1">직접입력</label>

                      <input
                        type="radio"
                        id="contactChoice2"
                        name="contact"
                        value={1}
                        checked={addrRadio === "1"}
                        onClick={(e) => onClickRadioBtn(e.target.value)}
                      />
                      <label htmlFor="contactChoice2">일정별 상이</label>
                    </div>
                    {addrRadio === "0" && (
                      <>
                        <div className="form__row d-flex align-items-center justify-space  mb-40">
                          <input
                            type="adress"
                            name="adress1"
                            className="form__input required"
                            placeholder="활동 장소/주소를 입력해주세요."
                            value={activityInfo.address1}
                            readOnly
                            onDoubleClick={() => toggleAddressModal}
                          />
                          <div className="form__button--13">
                            <input
                              type="button"
                              name="submit"
                              className="form__submit button button--main"
                              id="submit"
                              value="검색"
                              onClick={() => toggleAddressModal()}
                            />
                          </div>
                        </div>
                        <h4>상세 주소</h4>
                        <div className="form__row d-flex align-items-center justify-space  mb-40">
                          <input
                            type="address"
                            name="address2"
                            value={activityInfo.address2}
                            onChange={(e) => onChangeActivityInfo(e)}
                            className="form__input required"
                            placeholder="상세 주소를 입력해주세요"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                <h4>공개여부</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="isPrivate"
                      className="required"
                      value={activityInfo.isPrivate}
                      key={activityInfo.isPrivate}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value="O">공개</option>
                      <option value="F">비공개</option>
                    </select>
                  </div>
                </div>
                <h4>상세</h4>
                <div className="form__row">
                  <textarea
                    name="memo"
                    className="form__textarea required"
                    placeholder="활동분야, 활동자 정보, 활동가능한 일정 및 시간대 등 장소마련 요청 등 상세 내용을 기입해주세요."
                    value={partActivityInfo.memo}
                    onChange={onChangePartActivityInfo}
                  />
                </div>
              </form>
            </div>
            <div className="d-flex justify-space align-items-center mt-100 mb-20">
              <h2 className="mb-0">모집 사항</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <h4>활동자 모집</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="partType"
                      className="required"
                      value={activityInfo.partType}
                      key={activityInfo.partType}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      {/* <option value="X">해당없음</option> */}
                      {/* <option value="A">전체</option> */}
                      <option value="U">일반</option>
                      {/* <option value="O">기관</option> */}
                      <option value="E">모집 안함</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className="d-flex justify-space align-items-center mt-100 mb-20">
              <h2 className="mb-0">활동자 모집 정보</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <h4>활동 참여방식</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="joinType"
                      className="required"
                      onChange={onChangePartActivityInfo}
                      value={partActivityInfo.joinType}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value="A">자동 참여</option>
                      <option value="Q">QR코드 인증 참여</option>
                    </select>
                  </div>
                </div>

                <h4>모집 마감</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="recruitDate"
                      className="required"
                      value={partActivityInfo.recruitDate}
                      onChange={onChangePartActivityInfo}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value={7}>일정 시작7일전까지</option>
                      <option value={3}>일정 시작3일전까지</option>
                      <option value={2}>일정 시작2일전까지</option>
                      <option value={1}>일정 시작1일전까지</option>
                      <option value={0.5}>일정 시작12시간전까지</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className="d-flex justify-space align-items-center mt-100 mb-20">
              <h2 className="mb-0">활동 일정 및 인원 설정</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <div
                  onClick={toggleScheduleModal}
                  style={{ cursor: "pointer" }}
                  className="button button--full button--line mb-20"
                >
                  일정 추가하기 +
                </div>

                <div className="cart cart--page">
                  <div
                    className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal"
                    id="item1"
                  >
                    {originalScheduleList &&
                      originalScheduleList.map((schedule) => (
                        <SelectedTime
                          schedule={schedule}
                          mode="addActivity"
                          getEditScheduleInfo={getEditScheduleInfo}
                          // setDelScheduleId={setDelOriginScheduleId}
                          key={schedule.id}
                        />
                      ))}
                    {scheduleList &&
                      scheduleList.map((schedule) => (
                        <SelectedTime
                          schedule={schedule}
                          mode="addActivity"
                          getEditScheduleInfo={getEditScheduleInfo}
                          // setDelScheduleId={setDelScheduleId}
                          key={schedule.id}
                        />
                      ))}
                  </div>
                </div>
              </form>
            </div>

            <div className="cards cards--11 mt-40">
              <div className="d-flex justify-space align-items-center">
                <h2 className="mb-0">의뢰 기관/단체 :</h2>
              </div>
              {requestedOrgInfo && <OrgList orgprofile={requestedOrgInfo} />}
            </div>
          </div>
          <div className="form__row mt-40">
            <input
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_save"
              id="submit"
              value="확인"
            />
          </div>
          <div className="space-h-100" />
        </div>
        {/* PAGE END */}
        {/* Bottom navigation */}
        <div id="bottom-toolbar" className="bottom-toolbar" />
        <ConformSave conformClick={conformClick} />
      </div>
    </Fragment>
  );
}

export default RegisterRequested;
