import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Map from "./Map";
import Tag from "./Tag";
import ActivityContext from "../context/Activity.context";
import ScheduleContext from "../context/Schedule.context";

function ActivityDetail(props) {
  const { profile, folder, activityData } = props;
  const { initActivity } = useContext(ActivityContext);
  const { initScheduleList } = useContext(ScheduleContext);

  // console.log("활동");
  // console.log(activityData);
  const navigate = useNavigate();

  const [categoryText, setCategoryText] = useState("");
  const [actData, setActData] = useState([]);
  const [actDetailData, setActDetailData] = useState([]);
  const [actScheduleData, setActScheduleData] = useState([]);

  // 활동 소식받기
  const [actNewsCountData, setActNewsCountData] = useState([]);

  // 이미지 파일
  const defaultImgPath = "/assets/images/food/img-default.jpg";

  const [imgPath, setImgPath] = useState(defaultImgPath);

  const [totalScCoin, setTotalScCoin] = useState(null);

  useEffect(() => {
    if (activityData === undefined) return;
    if (activityData.idx === undefined) return;

    // 카티고리 idx로 카테고리명 검색
    if (activityData && activityData.categoryIdx) {
      axios
        .get(
          `${process.env.REACT_APP_USER_SERVICE_API}/category/${activityData.categoryIdx}`
        )
        .then((res) => {
          setCategoryText(res.data.category);
        });
    }

    // 활동 정보 조회
    let URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/${activityData.idx}`;
    axios
      .get(URL)
      .then((res) => {
        const resJson = res.data;
        // console.log(resJson);
        setActData(resJson);
      })
      .catch((err) => {
        console.log(err.response);
      });

    // 활동 상세정보 조회
    URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_details/${activityData.idx}`;
    axios
      .get(URL)
      .then((res) => {
        const resJson = res.data;
        // console.log(resJson);
        setActDetailData(resJson);
      })
      .catch((err) => {
        console.log(err.response);
      });

    // 활동 상세정보 조회
    URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/recruit/${activityData.idx}`;
    axios
      .get(URL)
      .then((res) => {
        const resJson = res.data.data;
        // console.log(resJson);
        setActScheduleData(resJson);
      })
      .catch((err) => {
        console.log(err.response);
      });

    // 이미지 파일 조회
    URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
    axios
      .get(URL, {
        params: {
          pkIdx: activityData.idx,
          imageStatus: "A",
        },
      })
      .then((res) => {
        // console.log("이미지명");
        // console.log(res.data);

        // 파일 조회시 업로드 이미지 Path Set
        if (res.data !== "") {
          const uploadImgpath =
            `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`.replaceAll(
              " ",
              "%20"
            );
          setImgPath(uploadImgpath);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });

    // 활동 소식받기 수 조회
    URL = `${process.env.REACT_APP_USER_SERVICE_API}/news_request/count/${activityData.idx}`;
    axios
      .get(URL)
      .then((res) => {
        // console.log(res.data);
        setActNewsCountData(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });

    // 서초코인 활동일 경우 총 코인수 조회
    URL = `${process.env.REACT_APP_USER_SERVICE_API}/sc-coin/total/${activityData.idx}`;
    axios
      .get(URL)
      .then((res) => {
        setTotalScCoin(res.data);
      })
      .catch((e) => { });
  }, [activityData]);

  // 활동방식 view
  const viewTag = (activityType) => {
    if (activityType === "O") {
      return <Tag tagName="온라인" />;
    } else {
      return <Tag tagName="오프라인" />;
    }
  };

  // 활동 대상 View
  const viewPartType = (partType, beneType) => {
    if (partType === "E" && beneType === "E") {
      return "모집안함";
    }

    let partText = "";
    let beneText = "";

    switch (partType) {
      case "A":
      case "AX":
        partText = "활동자(기관, 회원)";
        break;
      case "O":
      case "OX":
        partText = "활동자(기관)";
        break;
      case "U":
      case "UX":
        partText = "활동자(회원)";
        break;
      default:
        partText = "";
    }

    switch (beneType) {
      case "A":
      case "AX":
        beneText = "수요자(기관, 회원)";
        break;
      case "O":
      case "OX":
        beneText = "수요자(기관)";
        break;
      case "U":
      case "UX":
        beneText = "수요자(회원)";
        break;
      default:
        beneText = "";
    }

    let sep = "";

    if (partText !== "" && beneText !== "") {
      sep = "/";
    } else if (partText === "" || beneText === "") {
      sep = "";
    }

    return `${partText} ${sep} ${beneText}`;
  };

  // editAct 페이지 이동 클릭 핸들러
  const linkEditActClickHandler = () => {
    initActivity(actData, actDetailData);
    initScheduleList(actScheduleData);

    navigate(`/org/profileaddact/editact/${activityData.idx}`);
  };

  return (
    <Fragment>
      <div className="act_thumbnail">
        <img src={imgPath} alt="" title="" />
      </div>

      {/* <div className="swiper-wrapper">
          <div
            className="swiper-slide slider-simple__slide"
            style={{ backgroundImage: `url(${imgPath})` }}
          ></div>
        </div>
        <div className="swiper-pagination slider-simple__pagination" />*/}

      <div className="d-flex justify-space align-items-center">
        <h2 className="mb-0">{activityData && activityData.title}</h2>
      </div>
      <h4>기본 정보</h4>
      <div className="fieldsets">
        <ul className="custom-listing mb-20">
          <li>
            <span>주관 : </span>
            <Link
              to={`/${folder}/otherprofile/org/${activityData && activityData.idx
                }`}
            >
              {activityData && activityData.orgTitle}
            </Link>{" "}
          </li>
          <li>
            <span>카테고리 : </span> {categoryText}
          </li>
          <li>
            <span>모집 : </span>{" "}
            {activityData &&
              viewPartType(activityData.partType, activityData.beneType)}{" "}
          </li>
          <li>
            <span>활동방식 : </span>{" "}
            {viewTag(activityData && activityData.activityType)}{" "}
          </li>
          <li>
            <span>참여방식 : </span>{" "}
            {activityData &&
              {
                A: "자동 참여",
                Q: "QR 참여",
              }[activityData.joinType]}
          </li>
          <li>
            <span>모집 마감 : </span>{" "}
            {activityData &&
              (activityData.recruitDate === "0.5"
                ? "12시간 전까지"
                : `${parseInt(activityData.recruitDate)}일 전까지`)}
          </li>
          {/* <li>
            <span>필요인원 : </span> {activityData && activityData.recruitNum}{" "}
            명
          </li> */}
          {/* <li>
            <span>소요시간 : </span> {activityData && activityData.totalTime}{" "}
            시간
          </li> */}
          {activityData &&
            activityData.activityType === "F" &&
            activityData.address1 !== "" && (
              <li>
                <span>활동장소 : </span>{" "}
                {activityData &&
                  activityData.address1 + " " + activityData.address2}
              </li>
            )}

          <li>
            <span>일정별 적립 코인 : </span>
            {activityData && activityData.coinStatus === "Y"
              ? `${activityData.coinVal} 코인`
              : "적립없음"}
          </li>
          <li>
            <span>총 적립해준 코인 : </span>
            {totalScCoin}코인
          </li>
        </ul>
        {activityData &&
          activityData.activityType === "F" &&
          activityData.address1 !== "" && (
            <div className="fieldset">
              <Map
                width="100%"
                height="200px"
                address={activityData && activityData.address1}
              />
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193578.74109041138!2d-73.97968099999997!3d40.70331274999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York+NYC%2C+New+York%2C+Statele+Unite+ale+Americii!5e0!3m2!1sro!2s!4v1425027721891" width="100%" height={200} frameBorder={0} style={{border: 0}} /> */}
            </div>
          )}

        <ul className="custom-listing mb-20">
          <li>
            <span>
              상세 : <br />
            </span>
            {activityData && Object.keys(activityData).includes("memo") && (
              <div
                className="description-form mb-20"
                dangerouslySetInnerHTML={{
                  __html: activityData.memo.replace(/\n/gi, "<br/>"),
                }}
              ></div>
            )}
            {/* {activityData && activityData.memo.replace(/\n/gi, "<br/>")} */}
          </li>
        </ul>
        {profile ? (
          <Fragment>
            <div className="cards cards--6" id="loadlist-01">
              <div className="table card card--style-thumb mb-20">
                <div className="card__details">
                  <p className="card__list text-center fc-color-gray">
                    {actNewsCountData}명의 활동자로부터
                    <br />
                    소식받기가 신청되었습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="form__row mt-40">
              <input
                type="submit"
                name="submit"
                className="form__submit button button--border button--50 open-popup"
                data-popup="conform_delete"
                id="submit"
                value="삭제"
              />
              <input
                onClick={linkEditActClickHandler}
                type="submit"
                name="submit"
                className="form__submit button button--border button--50"
                id="submit"
                value="수정"
              />
              {/* <input
                type="submit"
                name="submit"
                className="mt-10 form__submit button button--secondary button--50 open-popup"
                data-popup="conform_partclose"
                id="submit"
                value="활동자 모집 마감하기"
              />
              <input
                type="submit"
                name="submit"
                className="mt-10 form__submit button button--secondary button--50 open-popup"
                data-popup="conform_beneclose"
                id="submit"
                value="수요자 모집 마감하기"
              /> */}
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}

export default ActivityDetail;
