import React from "react";
import { Link } from "react-router-dom";
import { convertDashToDot } from "../util/convert-date-function";

const CoinRewardListItem = ({ coinLedgerInfo }) => {
  return (
    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
      <div className="card__details">
        <Link to={`/sole/activity/detailpart/${coinLedgerInfo.activityIdx}`}>
          <h4 className="card__list">{coinLedgerInfo.activityTitle}</h4>
        </Link>
        <h2 className="card__info">{`${convertDashToDot(
          coinLedgerInfo.activityDate
        )} ${coinLedgerInfo.activityTime}`}</h2>
        <p className="card__info">주관 : {coinLedgerInfo.hostOrgTitle}</p>
        <p className="card__info">
          서초코인 : {coinLedgerInfo.coin}코인 {coinLedgerInfo.status}완료
        </p>
      </div>
    </div>
  );
};

export default CoinRewardListItem;
