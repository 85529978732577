import React from "react";
import CoinRewardListItem from "./CoinRewardListItem";

const CoinRewardList = ({ list, totalRows, goNextPage }) => {
  return (
    <div className="cards cards--11">
      {list.map((info) => (
        <CoinRewardListItem coinLedgerInfo={info} key={info.coinLedgerIdx} />
      ))}

      {totalRows !== list.length && (
        <div className="buttons buttons--centered mb-20">
          <div className="button button--more" onClick={goNextPage}>
            더 보기
          </div>
        </div>
      )}
    </div>
  );
};

export default CoinRewardList;
