import React, {Fragment, useRef, useEffect} from "react";
import {Link} from "react-router-dom";
import {getFileSize} from "../util/file";

function ChatItem({folder, msgInfo}) {
    const messageEnd = useRef();

    const scrollBottom = () => {
        messageEnd.current.scrollIntoView();
    };

    useEffect(() => {
        scrollBottom();
    }, []);

    const chatView = () => {
        switch (msgInfo.status) {
            case "received":
                return (
                    <li
                        className={`chating__row chat__row--${msgInfo.status}`}
                        ref={messageEnd}
                    >
                        <div className="conversations__avatar pd-10">
                            <Link to={`/${folder}/otherprofile/sole/${msgInfo.senderIdx}`}>
                                <img
                                    src={
                                        msgInfo.img
                                            ? msgInfo.img
                                            : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`
                                    }
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div className="chat__content">
                            <Link to={`/${folder}/otherprofile/sole/${msgInfo.senderIdx}`}>
                                <span className="chat__username">{msgInfo.name}</span>
                            </Link>
                            <p>{Object.keys(msgInfo).includes("fileUrl") ? `${msgInfo.fileName}(${getFileSize(msgInfo.fileSize)}Mb)` : msgInfo.content}</p>
                            <p className="chat__time">{msgInfo.created_time}</p>
                            {Object.keys(msgInfo).includes("fileUrl") && (
                                <button
                                    className="st_bt_st"
                                    type="button"
                                >
                                    <a
                                        href={`${process.env.REACT_APP_CHAT_SERVICE_API}${msgInfo.fileUrl}`}
                                        download={decodeURI(msgInfo.fileName)}
                                    >
                                        파일 다운로드
                                    </a>
                                </button>
                            )}
                        </div>
                    </li>
                );
            case "sent":
                return (
                    <li
                        className={`chating__row chat__row--${msgInfo.status}`}
                        ref={messageEnd}
                    >
                        <div className="chat__content">
                            <p>{Object.keys(msgInfo).includes("fileUrl") ? `${msgInfo.fileName}(${getFileSize(msgInfo.fileSize)}Mb)` : msgInfo.content}</p>
                            <p className="chat__time">{msgInfo.created_time}</p>
                            {Object.keys(msgInfo).includes("fileUrl") && (
                                <button
                                    className="st_bt_st"
                                    type="button"
                                >
                                    <a
                                        href={`${process.env.REACT_APP_CHAT_SERVICE_API}${msgInfo.fileUrl}`}
                                        download={decodeURI(msgInfo.fileName)}
                                    >
                                        파일 다운로드
                                    </a>
                                </button>
                            )}
                        </div>
                        <div className="conversations__avatar pd-10">
                            <img
                                src={
                                    msgInfo.img
                                        ? msgInfo.img
                                        : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`
                                }
                                alt=""
                            />
                        </div>
                    </li>
                );
            default:
                return (
                    // <li className="hat__row--received chat__row--unread">
                    //   <div className="conversations__avatar">
                    //     <Link to={`/${folder}/otherprofile/sole`}>
                    //       <img
                    //         src={`${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`}
                    //         alt=""
                    //       />
                    //     </Link>
                    //   </div>
                    //   <div className="chat__content">
                    //     <Link to={`/${folder}/otherprofile/sole`}>
                    //       <span className="chat__username">{name}</span>
                    //     </Link>
                    //     <p>{content}</p>
                    //     <span className="chat__time">{created_time}</span>
                    //   </div>
                    // </li>
                    null
                );
        }
    };

    return <Fragment>{chatView()}</Fragment>;
}

export default ChatItem;
