import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import ActivityItem from "../../../components/ActivityItem";
import Navigationbar from "../../../components/Navigationbar";
import OrgItem from "../../../components/OrgItem";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

const viewTagList = ["activityType"];

function SearchPart(props) {
  const { searchKeyword } = useParams();
  const [activityData, setActivityData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);
  const [closedActivityData, setClosedActivityData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const [activityPage, setActivitiyPage] = useState(1);
  const [consumerPage, setConsumerPage] = useState(1);
  const [closedPage, setClosedPage] = useState(1);
  const [orgPage, setOrgPage] = useState(1);

  const offsetActivityPage = 6;
  const offsetOrgPage = 8;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  const [hideContent, setHideContent] = useState(true);

  // 특정 키워드 활동명 검색
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        // axios URL
        let url1 = `${process.env.REACT_APP_USER_SERVICE_API}/activity/title?title=${searchKeyword}`;
        axios.get(url1).then((res) => {
          const json = res.data.data;

          // 활동의 기관명 추가
          json.map((item) => {
            axios
              .get(
                `${process.env.REACT_APP_USER_SERVICE_API}/org/${item.orgIdx}`
              )
              .then((res) => {
                item.orgTitle = res.data.orgTitle;

                setActivityData(
                  json &&
                    json.filter(
                      (json) =>
                        (json.partType === "A" || json.partType === "U") &&
                        json.isPrivate === "O"
                    )
                );
                setConsumerData(
                  json &&
                    json.filter(
                      (json) =>
                        (json.beneType === "A" || json.beneType === "U") &&
                        json.isPrivate === "O"
                    )
                );
                setClosedActivityData(
                  json &&
                    json.filter(
                      (json) => json.partType === "X" && json.beneType === "X"
                    )
                );
              });
          });
        });

        // axios URL
        // 특정 키워드 기관명 검색
        let url2 = `${process.env.REACT_APP_USER_SERVICE_API}/org/title?title=${searchKeyword}`;
        axios.get(url2).then((res) => {
          setOrgData(res.data.data);
        });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title={searchKeyword} />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header ">
          <div className="search__form">
            <form>
              <input
                type="text"
                className="search__input"
                value=""
                placeholder="검색..."
              />
              <input type="submit" className="search__submit" value="Send" />
            </form>
          </div>
          <div className="page__title-bar mt-48">
            <h2>활동자 모집 ({activityData.length})</h2>
          </div>
          <div className="cards cards--12" id="loadlist-01">
            {activityData &&
              activityData
                .map((activity) => (
                  <ActivityItem
                    key={activity.idx}
                    url={`/sole/activity/detailpart/${activity.idx}`}
                    folder="org"
                    activity={activity}
                  />
                ))
                .slice(0, activityPage * offsetActivityPage)}
          </div>
          <div className="buttons buttons--centered mb-20">
            {activityData.length > activityPage * offsetActivityPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setActivitiyPage, activityPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="page__title-bar mt-48">
            <h2>진행한 활동 ({closedActivityData.length})</h2>
          </div>
          <div className="cards cards--12" id="loadlist-01">
            {closedActivityData &&
              closedActivityData
                .map((activity) => (
                  <ActivityItem
                    key={activity.idx}
                    url={`/sole/activity/detailcarriedout/${activity.idx}`}
                    folder="org"
                    activity={activity}
                    viewTagList={viewTagList}
                  />
                ))
                .slice(0, closedPage * offsetActivityPage)}
            <div className="buttons buttons--centered mb-20">
              {closedActivityData.length > closedPage * offsetActivityPage ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => {
                    addPage(setClosedPage, closedPage);
                  }}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="page__title-bar mt-48">
            <h2>기관/단체({orgData.length})</h2>
          </div>
          <div className="cards cards--14">
            {orgData &&
              orgData
                .map((org) => <OrgItem key={org.idx} folder="org" org={org} />)
                .slice(0, orgPage * offsetOrgPage)}
          </div>
          <div className="buttons buttons--centered mb-20">
            {orgData.length > orgPage * offsetOrgPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setOrgPage, orgPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default SearchPart;
