import React from "react";
import NoticeListItem from "./NoticeListItem";

const NoticeList = ({ noticeList, pageNumber, count }) => {
  let no = (pageNumber - 1) * count + 1;

  return (
    <div className="table table--3cols mb-20">
      <div className="table__row">
        <div className="table__section table__section--nr table__section--header">
          No.
        </div>
        <div className="table__section table__section--grow table__section--header">
          제목
        </div>
        <div className="table__section table__section--header">작성일</div>
      </div>
      {noticeList.map((noticeInfo) => (
        <NoticeListItem noticeInfo={noticeInfo} no={no++} key={no} />
      ))}
    </div>
  );
};

export default NoticeList;
