import axios from "axios";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import GroupContext from "../context/Group.context";
import { onHandleImgError } from "../util/util-function";

function GroupMemberItem(props) {
  const {
    title,
    folder,
    orgMember,
    setGroupData,
    groupFunc,
    type,
    idx,
    setOrgMemberData,
    selectedMember,
  } = props;
  const groupContext = useContext(GroupContext);

  const [isSelected, setIsSelected] = useState(null);
  const onChangeSelected = () => {
    console.log(isSelected);

    setIsSelected(!isSelected);
    selectedMember(orgMember.userIdx, !isSelected);
  };

  const navigate = useNavigate();

  // 소속회원 삭제
  const deleteOrgMember = () => {
    if (
      window.confirm(
        `${orgMember && orgMember.nickname} 회원을 탈퇴시키겠습니까?`
      )
    ) {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/${
        orgMember && orgMember.idx
      }`;
      // console.log(URL);
      axios.delete(URL).then((res) => {
        // console.log(res.data);
        navigate(0);
      });
    }
  };

  // 그룹만들기 회원 제외
  const deleteGroup = () => {
    if (
      window.confirm(
        `${orgMember && orgMember.nickname} 회원을 제외시키겠습니까?`
      )
    ) {
      // 활동 참여자 배치인 경우
      if (type === "placingMember") {
        groupFunc(orgMember.nickname);
      }
      // 그룹 만들기인 경우
      else if (type === "addGroup") {
        const groupList = groupContext && groupContext.groupList;
        const changedGroupLsit = groupList.filter(
          (group) => group.nickname !== orgMember.nickname
        );

        groupContext.changeGroupList(changedGroupLsit);
        setGroupData(changedGroupLsit);
      }
      // 그룹 상세 페이지
      else if (type === "groupDetail") {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/group_list/${
          orgMember && orgMember.groupIdx
        }`;
        axios.delete(URL).then((res) => {
          // console.log(res.data);
          navigate(0);
        });
      } else {
        alert("type 지정 X");
      }
    }
  };

  const addGroup = () => {
    if (
      window.confirm(
        `${orgMember && orgMember.nickname} 회원을 추가하겠습니까?`
      )
    ) {
      if (type === "placingMember") {
        groupFunc(orgMember && orgMember.nickname);
      }
    }
  };

  const btnView = () => {
    switch (title) {
      case "없음":
        return null;
      case "삭제":
        return (
          <div
            className="caption__category mini_button"
            onClick={deleteOrgMember}
            style={{ cursor: "pointer" }}
          >
            삭제
          </div>
        );
      case "제외":
        return (
          <div
            className="button button--small-full button--gray mt-10"
            onClick={deleteGroup}
          >
            {title}
          </div>
        );
      case "placingMember":
        return (
          <div className="button button--small-full checkbox-option">
            {" "}
            <input
              type="checkbox"
              name="checkboxoption1"
              // id="cp001"
              value={orgMember.userIdx}
              checked={isSelected}
            />
            <label htmlFor="cp001" onClick={() => onChangeSelected()}>
              &#10003; 선택
            </label>
          </div>
        );
      default:
        return (
          <div
            className="button button--small-full button--main mt-10"
            onClick={addGroup}
          >
            {title}
          </div>
        );
    }
  };

  const getOrgMember = () => {
    // 소속 회원 조회
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${sessionStorage.idx}`;
    axios.get(url).then((res) => {
      const orgMemberArray = [];
      const orgMemberList = res.data.data;
      // console.log(orgMemberList);
      axios
        .all(
          orgMemberList.map((orgMember) => {
            return axios.get(
              `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
            );
          })
        )
        .then((res) => {
          res.map((org, index) => {
            orgMemberArray.push(Object.assign(org.data, orgMemberList[index]));
          });

          // console.log("== 기관 그룹 회원 전체 조회 ==");
          // console.log(orgMemberArray);

          setOrgMemberData(orgMemberArray);
        });
    });
  };

  // 소속 회원 권한 변경
  const updateOrgMemberAuth = async (authCode) => {
    let chkSuccess = false;

    // A : 관리자, U : 일반
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/status/${orgMember.idx}?status=${authCode}`;
    // console.log(URL);
    try {
      const response = await axios.put(URL);

      if (response.status === 200) {
        chkSuccess = true;
      }
    } catch (e) {
      console.log(e);
    }

    return chkSuccess;
  };

  const [orgMemberStatus, setOrgMemberStatus] = useState(orgMember.status);

  const statusHandlerChange = (e) => {
    const selIdx = e.target.selectedIndex;
    const authText = e.target[selIdx].text;
    const authCode = e.target.value;

    if (
      window.confirm(
        `${orgMember.nickname} 회원의 권한을 ${authText}로 변경하시겠습니까?`
      )
    ) {
      if (groupContext.groupList.length !== 0) {
        const chkSuccess = updateOrgMemberAuth(authCode);

        chkSuccess.then((result) => {
          if (result) {
            const groupList = groupContext && groupContext.groupList;

            groupList[idx].status = authCode;

            groupContext.changeGroupList(groupList);
          }
        });
      } else {
        const chkSuccess = updateOrgMemberAuth(authCode);

        chkSuccess.then((result) => {
          if (result) {
            navigate(0);
          }
        });
      }
    }
  };

  useEffect(() => {
    setIsSelected(orgMember.isSelected);
  }, [orgMember.isSelected]);

  return (
    <Fragment>
      <div className="card card--style-thumb">
        <div className="card__thumb card__thumb--round-corners-full">
          <Link to={`/${folder}/otherprofile/sole/${orgMember.userIdx}`}>
            <img
              src={
                Object.keys(orgMember).includes("img")
                  ? orgMember.img.includes("https")
                    ? orgMember.img
                    : `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${orgMember.img}`
                  : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`
              }
              alt=""
              title=""
              onError={(e) => onHandleImgError(e, "U")}
            />
          </Link>
        </div>
        <Link to={`/${folder}/otherprofile/sole/${orgMember.userIdx}`}>
          <h4 className="card__username text-aline-center">
            {orgMember.nickname}
          </h4>
          <h4 className="card__title text-aline-center">{orgMember.name}</h4>
        </Link>
        <div className="form__row mb-20">
          <div className="form__select">
            <select
              name="status"
              className="required"
              value={orgMember.status}
              onChange={statusHandlerChange}
            >
              <option value="U">일반</option>
              <option value="A">관리자</option>
            </select>
          </div>
          {btnView()}
        </div>
      </div>
    </Fragment>
  );
}

export default GroupMemberItem;
