import axios from "axios";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

function OrgProfile(props) {
    const navigate = useNavigate();

    const {org, type, folder} = props;

    const defaultImgPath = "/assets/images/photos/img-p-sole.jpg";
    const [imgPath, setImgPath] = useState(defaultImgPath);
    const [mainDivClassName, setMainDivClassName] = useState("user-profile mb-20");

    // 파일명 조회
    useEffect(() => {
        if (org) {
            const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
            axios
                .get(URL, {
                    params: {
                        pkIdx: org && org.id,
                        imageStatus: "O",
                    },
                })
                .then((res) => {

                    // 파일 조회시 업로드 이미지 Path Set
                    if (res.data !== "") {
                        setImgPath(
                            `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}?date=${Date.now()}`
                        );
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }

        // otherprofile인 경우 className 변경
        if (type === "otherprofile") {
            setMainDivClassName("user-profile mb-20 mt-40");
        }
    }, [org]);

    const chageDefaultImg = () => {
        setImgPath(defaultImgPath);
    };

    const chatDClickHandler = (url) => {
        if (window.location.href.includes("sole")) {
            navigate("/sole/chat/chatd/s2o", {
                state: {
                    userId: window.sessionStorage.getItem("idx"), // 보내는 기관 id
                    orgId: org.id,
                },
            });
        } else {
            navigate("/org/chat/chatd/o2o", {
                state: {
                    senderOrgId: window.sessionStorage.getItem("idx"), // 보내는 기관 id
                    targetOrgId: org.id,
                },
            });
        }
    };

    const viewContent = () => {
        switch (type) {
            case "profile":
                return (
                    <div className="user-profile__info">
                        주소 : {org && `${org.address1} ${org.address2}`}
                    </div>
                );
            case "otherprofile":
                return (
                    <div className="user-profile__number">
                        전화번호 : {org && org.contact}
                    </div>
                );
            default:
                return;
        }
    };

    const btnView = () => {
        if (folder === "org") {
            if (type === "profile") {
                return (
                    <Link
                        to="/org/profileedit/edit"
                        className="button button--orange button--edit button--ex-small ml-10"
                    >
                        편집
                    </Link>
                );
            } else {
                if (folder !== "org")
                    return [
                        <span
                            onClick={() => chatDClickHandler("/org/chat/chatd/o2s")}
                            className="button button--orange button--ex-small ml-10"
                        >
              메세지
            </span>,
                    ];
                else return null;
            }
        } else if (folder === "sole") {
        }
    };

    return (
        <div className={mainDivClassName}>
            <div className="user-profile__thumb">
                <img src={imgPath} alt="" onError={chageDefaultImg}/>
            </div>
            <div className="user-profile__name">{org && org.orgTitle}</div>
            {viewContent()}
            <div className="user-profile__info">소개글 : {org && org.bio}</div>
            <div className="buttons buttons--centered">
                {window.sessionStorage.getItem("orgType") !== "A" && (
                    <>
                        <div className="info-box">
                            <span>수요자 모집</span> {org && org.beneTypeNum}건
                        </div>
                        <div className="info-box">
                            <span>활동자 모집</span> {org && org.partTypeNum}건
                        </div>
                    </>

                )}
                {btnView()}
            </div>
        </div>
    );
}

export default OrgProfile;
