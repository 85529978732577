import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ConformSave from "../../common/popup/ConformSave";
import { Modal } from "../../../components/DaumPostModal";
import CheckLoginInfo from "../../../services/login-validation";
import FileUpload from "../../common/FileUpload";
const bcrypt = require("bcryptjs");

function SoleEdit(props) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();

  // 다음 주소찾기
  const [modalOpen, setModalOpen] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [address1, setAddress1] = useState("");

  const [values, setValues] = useState();

  const [prevcheck, setPrevcheck] = useState("null");

  const [isNotSame, setIsNotSame] = useState(false);

  // 기관 파일명
  const [orgFileName, setOrgFileName] = useState("");

  // 이전 비밀번호 복호화하여 현재 입력한 이전 비밀번호와 비교
  const comparePassword = (inputpassword, encryptedPwd) => {
    bcrypt.compare(inputpassword, encryptedPwd, (err, succes) => {
      setPrevcheck(succes);

      return succes;
    });
  };

  // 각 폼 내용 변경핸들러
  const handleChangeForm = (e) => {
    if (e.target.name === "file") {
      // 파일 객체
      const file = e.target.files[0];

      if (file) {
        // 파일 확장자 추출
        const fileLen = file.name.length;
        const lastDotIdx = file.name.lastIndexOf(".");
        const fileEx = file.name.substring(lastDotIdx, fileLen).toLowerCase();

        // 파일 크기
        const fileSize = file.size;
        if (fileEx !== ".jpg" && fileEx !== ".jpeg" && fileEx !== ".png") {
          alert("이미지 확장자는 .jpg, .jpeg, .png 만 업로드가 가능합니다.");
          return;
        }

        if (fileSize > 10000000) {
          alert("이미지 파일크기는 10M를 초과할 수 없습니다.");
          return;
        }

        setImageFromFile(file);

        setValues({
          ...values,
          [e.target.name]: file,
          ["img"]: file.name,
        });
      }
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [isChangePwd, setIsChangePwd] = useState(false);

  // 비밀번호 변경
  const onChangePwd = (chk) => {
    setIsChangePwd(chk);
  };

  // 닉네임 중복확인
  const [nicknamecheck, setNicknamecheck] = useState("null");
  const checkNickname = (e) => {
    e.preventDefault();
    let nickname = {
      nickname: values.nickname,
    };
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/duplicatedNickname`;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, nickname, config)
      .then((res) => {
        if (res.status === 200) {
          setNicknamecheck(200);
        }
      })
      .catch((err) => {
        setNicknamecheck(" ");
      });
  };

  // 이메일 중복확인
  const [emailcheck, setEmailcheck] = useState("null");
  const checkEmail = (e) => {
    e.preventDefault();
    let email = {
      email: values.email,
    };
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/duplicatedEmail`;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, email, config)
      .then((res) => {
        if (res.status === 200) {
          setEmailcheck(200);
        }
      })
      .catch((err) => {
        setEmailcheck(" ");
      });
  };

  // 회원 수정
  const updateUser = (e) => {
    e.preventDefault();
    // pattern
    let emailpattern =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    let idPattern = /^[a-zA-Z]+[a-zA-Z0-9]{5,19}$/g; // 영문 대소문자로 시작,숫자도 포함 가능,5 ~ 19자리 사이
    let pwdpattern =
      /^.*(?=.{8,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    let telpattern = /^[0-9\b -]{0,13}$/;
    // axios URL
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users`;

    // 비밀번호 변경시에만 체크
    if (isChangePwd) {
      if (
        comparePassword(values.prevpassword, userData.encryptedPwd) === false
      ) {
        alert("이전 비밀번호가 일치하지 않습니다.");
        return;
      }

      if (values.password !== values.passwordcheck) {
        alert("비밀번호가 일치하지않습니다.");

        setIsNotSame(true);
        return;
      }

      if (pwdpattern.test(values.password) === false) {
        alert("숫자,문자,특수문자를 조합해서 최소 8자 이상 입력해 주세요.");
        return;
      }
    }

    let user;

    if (isChangePwd) {
      user = {
        idx: sessionStorage.idx,
        nickname: values.nickname,
        name: values.name,
        password: values.password,
        phoneNum: values.tel,
        email: values.email,
        birth: values.birthdate,
        address1: address1,
        address2: values.addressdetail,
        gender: values.gender,
        openStatus: values.openStatus,
        bio: values.bio,
      };
    } else {
      user = {
        idx: sessionStorage.idx,
        nickname: values.nickname,
        name: values.name,
        phoneNum: values.tel,
        email: values.email,
        birth: values.birthdate,
        address1: address1,
        address2: values.addressdetail,
        gender: values.gender,
        openStatus: values.openStatus,
        bio: values.bio,
      };
    }
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (nicknamecheck !== 200) {
      // 현재 닉네임과 다른 닉네임 기입시에만 중복체크
      if (userData.nickname !== values.nickname) {
        alert("ID 중복확인을 해주세요.");
        return;
      }
    }

    // if (idPattern.test(values.nickname) === false) {
    //   alert(
    //     "ID가 형식에 맞지 않습니다. ID는 아이디는 영문자로 시작하는 6~20자 영문자 또는 숫자이어야 합니다."
    //   );
    // }

    if (emailpattern.test(values.email) === false) {
      alert("이메일 형식에 맞게 작성해 주세요.");
      return;
    }
    if (emailcheck !== 200) {
      // 현재 이메일과 다른 이메일 기입시에만 중복체크
      if (userData.email !== values.email) {
        alert("이메일 중복확인을 해주세요.");
        return;
      }
    }

    if (telpattern.test(values.tel) === false) {
      alert("숫자만 입력해 주세요.( ex : 01098765432 )");
      return;
    }

    axios
      .put(url, user, config)
      .then((res) => {
        // 이미지 등록
        if (renderImgFile) {
          const formData = new FormData();
          formData.append("file", renderImgFile);
          formData.append("pkIdx", sessionStorage.idx);
          formData.append("imageStatus", "U");

          let uploadUrl = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/uploadfile`;

          axios.post(uploadUrl, formData).then((res) => {
            alert("회원수정 완료");
            navigate(-1);
          });
        } else {
          alert("회원수정 완료");
          navigate(-1);
        }
      })
      .catch((err) => {
        alert("회원수정 실패");
      });
  };

  // 다음주소찾기 모달 열기, 닫기
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const daumPostModalOpenHandler = () => {
    openModal(true);
  };

  // 이미지 미리보기 및 업로드
  const [renderImgFile, setRenderImagFile] = useState(null);
  const [renderImgUrl, setRenderImagUrl] = useState(
    "/assets/images/photos/img-p-sole.jpg"
  );

  const setImageFromFile = (file) => {
    let reader = new FileReader();
    reader.onload = function () {
      setRenderImagFile(file);
      setRenderImagUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [showFileModal, setShowFileModal] = useState(false);
  const toggleFileModal = () => {
    setShowFileModal(!showFileModal);
  };
  const getFile = (file, name, imgUrl, type) => {
    if (type === "img") {
      setValues({
        ...values,
        file: file,
        img: name,
      });

      setRenderImagUrl(imgUrl);
    } else {
    }

    toggleFileModal();
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        // 회원 조회
        axios
          .get(
            `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${sessionStorage.idx}`
          )
          .then((res) => {
            setUserData(res.data);
            const user = res.data;

            // 비밀번호 복호화하여 비교
            bcrypt.compare("test123!@#", user.encryptedPwd, (err, succes) => {
              // console.log(err);
              // console.log(succes);
            });

            setValues({
              nickname: user.nickname,
              name: user.name,
              prevpassword: "",
              password: "",
              passwordcheck: "",
              tel: user.phoneNum,
              email: user.email,
              birthdate: user.birth,
              address: user.address1,
              addressdetail: user.address2,
              gender: user.gender,
              openStatus: user.openStatus,
              bio: user.bio,
            });

            setAddress1(user.address1);
          });

        // 파일명 조회
        axios
          .get(`${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`, {
            params: {
              pkIdx: sessionStorage.idx,
              imageStatus: "U",
            },
          })
          .then((res) => {
            if (res.data !== "") {
              setOrgFileName(
                `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
              );
              setRenderImagUrl(
                `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
              );
            }
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  if (showFileModal) {
    return (
      <FileUpload
        type="img"
        getFile={getFile}
        toggleFileModal={toggleFileModal}
      />
    );
  }

  return (
    <Fragment>
      <Modal
        open={modalOpen}
        close={closeModal}
        setModalOpen={setModalOpen}
        setAddress={setAddress1}
        setPostcode={setPostcode}
      />
      <Panel />
      <div className="page page--main" data-page="shop">
        <div className="page__content page_section">
          {/* HEADER */}
          <SubHeader title="편집" />
          {/* PAGE CONTENT */}
          <div className="page__content  page_section--with-header-68">
            {/* SLIDER SIMPLE */}
            <div className="fieldsets mt-48">
              <div className="user-profile__thumb">
                <img
                  src={renderImgUrl}
                  alt={renderImgFile && renderImgFile.name}
                />
              </div>
              <div className="upload-filebox mt-20">
                <form id="_frm" method="post" enctype="multipart/form-data">
                  <label htmlFor="ex_file">사진 업로드</label>
                  <input
                    // type={
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? "button"
                    //     : "file"
                    // }
                    // type="button"
                    type={"file"}
                    // style={
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? { display: "none" }
                    //     : { display: "block" }
                    // }
                    // style={{ display: "none" }}
                    name="file"
                    accept=".jpg, .jpeg, .png"
                    // accept="image/*"
                    capture="camera"
                    id="ex_file"
                    // onClick={() =>
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? toggleFileModal()
                    //     : null
                    // }
                    // onClick={() => toggleFileModal()}
                    // onChange={(e) =>
                    //   isAndroid() &&
                    //   typeof window.Android !== "undefined" &&
                    //   window.Android !== null
                    //     ? null
                    //     : handleChangeForm(e)
                    // }
                    onChange={(e) => handleChangeForm(e)}
                  />
                </form>
              </div>
            </div>
            <div className="fieldsets">
              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>ID</h4>
                  <div className="form__row mb-20">
                    <input
                      type="text"
                      value={values && values.nickname}
                      name="nickname"
                      className="form__input_70 required login-form__input_min"
                      placeholder="입력"
                      onChange={handleChangeForm}
                    />
                    <input
                      type="submit"
                      name="submit"
                      className="button button--main button--half2"
                      id="submit"
                      value="중복확인"
                      onClick={checkNickname}
                    />
                    {nicknamecheck &&
                    nicknamecheck === "null" ? null : nicknamecheck &&
                      nicknamecheck === 200 ? (
                      <p>사용 가능한 ID입니다.</p>
                    ) : (
                      <p className="font-red">이미 사용 중인 ID입니다.</p>
                    )}
                  </div>
                </form>
              </div>
              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>현재 비밀번호</h4>
                  <div className="form__row mb-20">
                    <input
                      type="password"
                      name="prevpassword"
                      value={values && values.prevpassword}
                      className="form__input required"
                      placeholder="입력"
                      onChange={handleChangeForm}
                    />
                  </div>
                  {prevcheck === false ? (
                    <p className="font-red">비밀번호가 일치하지 않습니다.</p>
                  ) : null}
                </form>
              </div>
              <div className="form mt-48" style={{ display: "flex" }}>
                <input
                  type="checkbox"
                  name="password"
                  checked={isChangePwd}
                  onChange={(e) => onChangePwd(e.target.checked)}
                />
                <h4>비밀번호 변경하기</h4>
              </div>
              {isChangePwd && (
                <div className="form mt-48">
                  <form id="ContactForm">
                    <h4>새 비밀번호</h4>
                    <div className="form__row mb-20">
                      <input
                        type="password"
                        name="password"
                        value={values && values.password}
                        className="form__input required"
                        placeholder="입력"
                        onChange={handleChangeForm}
                      />
                    </div>
                  </form>
                </div>
              )}
              {isChangePwd && (
                <div className="form mt-48">
                  <form id="ContactForm">
                    <h4>새 비밀번호 확인</h4>
                    <div className="form__row mb-20">
                      <input
                        type="password"
                        name="passwordcheck"
                        value={values && values.passwordcheck}
                        className="form__input required"
                        placeholder="입력"
                        onChange={handleChangeForm}
                      />
                    </div>
                    {isNotSame === true && (
                      <p className="font-red">비밀번호가 일치하지 않습니다.</p>
                    )}
                  </form>
                </div>
              )}

              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>전화번호</h4>
                  <div className="form__row mb-20">
                    <input
                      type="tel"
                      name="tel"
                      value={values && values.tel}
                      className="form__input required"
                      placeholder="입력"
                      onChange={handleChangeForm}
                    />
                  </div>
                </form>
              </div>
              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>성별</h4>
                  <div className="form__select">
                    <select
                      name="gender"
                      className="required"
                      value={values && values.gender}
                      onChange={handleChangeForm}
                    >
                      <option value="N">선택 안함</option>
                      <option value="M">남성</option>
                      <option value="F">여성</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>이메일</h4>
                  <div className="form__row mb-20">
                    <input
                      type="email"
                      value={values && values.email}
                      name="email"
                      className="form__input_70 required login-form__input_min"
                      placeholder="입력"
                      onChange={handleChangeForm}
                    />
                    <input
                      type="submit"
                      name="submit"
                      className="button button--main button--half2"
                      id="submit"
                      value="중복확인"
                      onClick={checkEmail}
                    />
                  </div>
                  {emailcheck && emailcheck === "null" ? null : emailcheck &&
                    emailcheck === 200 ? (
                    <p>사용할 수 있는 이메일 주소입니다.</p>
                  ) : (
                    <p className="font-red">
                      이미 사용 중인 이메일 주소입니다.
                    </p>
                  )}
                </form>
              </div>
              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>생년월일</h4>
                  <div className="form__row mb-20">
                    <input
                      type="date"
                      name="birthdate"
                      value={values && values.birthdate}
                      className="form__input required"
                    />
                  </div>
                </form>
              </div>
              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>주소(주소)</h4>
                  <div className="form__row mb-20">
                    <input
                      type="adress"
                      name="address"
                      value={address1 && address1}
                      className="form__input_70 required login-form__input_min"
                      placeholder="입력"
                      onChange={handleChangeForm}
                      readOnly
                      onClick={daumPostModalOpenHandler}
                    />
                    <input
                      type="button"
                      name="submit"
                      className="button button--main button--half2"
                      id="submit"
                      value="검색"
                      onClick={daumPostModalOpenHandler}
                    />
                  </div>
                </form>
              </div>
              <div className="form mt-48">
                <form id="ContactForm">
                  <h4>상세주소</h4>
                  <div className="form__row mb-20">
                    <input
                      type="adress"
                      name="addressdetail"
                      value={values && values.addressdetail}
                      className="login-form__min required form__input"
                      placeholder="상세 주소는 두글자 이상 입력하십시오"
                      onChange={handleChangeForm}
                    />
                  </div>
                </form>
              </div>
              <div className="form mt-48">
                <h4>공개여부</h4>
                <div className="form__select">
                  <select
                    name="openStatus"
                    className="required"
                    value={values && values.openStatus}
                    onChange={handleChangeForm}
                  >
                    <option value="" disabled>
                      선택
                    </option>
                    <option value="O">공개</option>
                    <option value="C">비공개</option>
                  </select>
                </div>
              </div>

              <div className="fieldsets mt-48">
                <div className="form">
                  <form id="ContactForm" method="post" action>
                    <h4>소개</h4>
                    <div className="form__row">
                      <textarea
                        name="bio"
                        className="form__textarea required"
                        placeholder="내용을 기입해주세요."
                        value={values && values.bio}
                        onChange={handleChangeForm}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="form__row mt-40">
              <input
                type="submit"
                name="submit"
                onClick={() => {
                  navigate(-1);
                }}
                data-popup="conform_cancle"
                className="form__submit button button--gray button--50 open-popup"
                id="submit"
                value="취소"
              />
              <input
                type="submit"
                name="submit"
                className="form__submit button button--main button--50 open-popup"
                data-popup="conform_save"
                id="submit"
                value="저장"
              />
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformSave conformClick={updateUser} />
    </Fragment>
  );
}

export default SoleEdit;
