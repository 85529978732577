import React, { Fragment, useContext, useEffect, useState } from "react";
import ActivityMemberList from "./ActivityMemberList";
import UrlContext from "../context/Url.context";
import axios from "axios";
import PartMemberList from "./PartMemberList";
import PartGroupList from "./PartGroupList";

function ApplicationPartList(props) {
  const { activitySchedule, setSelActListIdx } = props;
  const urlContext = useContext(UrlContext);

  const [partData, setPartData] = useState([]);
  const [partGroupData, setPartGroupData] = useState([]);

  const convertDate = (inputDate) => {
    //console.log(inputDate);
    const date = new Date(inputDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;

    return `${month}월 ${day}일`;
  };

  // 일정에 대한 참여리스트 조회
  useEffect(() => {
    const URL1 = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/org/profile/listapplicationpart/${activitySchedule.activityScheduleIdx}`;
    axios.get(URL1).then((res) => {
      //console.log(res.data.data);
      setPartData(res.data.data);
    });

    const URL2 = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/org/profile/listapplicationpart-group/${activitySchedule.activityScheduleIdx}`;
    axios.get(URL2).then((res) => {
      setPartGroupData(res.data.data);
    });
  }, []);

  return (
    <Fragment>
      <div className="page__title-bar mb-24">
        <h1 className="page__title">
          {activitySchedule &&
            `${convertDate(activitySchedule.activityDate)} ${activitySchedule.activityTime
            } 
            ( ${activitySchedule.recruit} ${activitySchedule.recruitedNum}/${activitySchedule.recruitNum
            })
            `}
        </h1>
      </div>
      <div className="cards cards--11">
        {partData &&
          partData.map((part, idx) => (
            <PartMemberList
              key={idx}
              part={part}
              setSelActListIdx={setSelActListIdx}
            />
          ))}
        {partGroupData &&
          partGroupData.map((part, idx) => (
            <PartGroupList
              key={`${part.orgIdx}_${idx}`}
              part={part}
              setSelActListIdx={setSelActListIdx}
            />
          ))}
      </div>
    </Fragment>
  );
}

export default ApplicationPartList;
