import React from "react";

const ListActivitySearchComponent = ({
  searchInfo,
  onChangeSearchInfo,
  hideType,
}) => {
  return (
    <>
      <div className="form__row  mt-40">
        <h3 className="pb-20">시작 날짜</h3>
        <input
          className="form__input required"
          placeholder="Date"
          type="date"
          name="startDate"
          value={searchInfo.startDate}
          onChange={(e) => onChangeSearchInfo(e)}
        />
      </div>
      <div className="form__row  mt-40">
        <h3 className="pb-20">마지막 날짜</h3>
        <input
          type="date"
          className="form__input required"
          placeholder="Date"
          name="endDate"
          value={searchInfo.endDate}
          onChange={(e) => onChangeSearchInfo(e)}
        />
      </div>
      {/* {hideType ? null : (
        <div className="form__row  mt-40">
          <h3 className="pb-20">검색 대상</h3>
          <div className="form__select">
            <select
              className="required"
              name="target"
              defaultValue={searchInfo.target}
              key={searchInfo.target}
              onChange={(e) => onChangeSearchInfo(e)}
            >
              <option value="default" disabled>
                선택
              </option>
              <option value="A">전체</option>
              <option value="O">기관/단체</option>
              <option value="U">일반</option>
            </select>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ListActivitySearchComponent;
