import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CalendarLibrary from "../../../components/CalendarLibrary";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function Calendar(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const actSchduelIdx = location.state && location.state.actSchduelIdx;
  const type = location.state && location.state.type;
  const actIdx = location.state && location.state.actIdx;
  const actListIdx = location.state && location.state.actListIdx;

  const scheduleListClickHandler = () => {
    navigate("/org/profile/schedulelist", {
      state: {},
    });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="날짜" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <CalendarLibrary
              folder="org"
              chk="schedulelist2"
              actSchduelIdx={actSchduelIdx}
              type={type}
              actIdx={actIdx}
              actListIdx={actListIdx}
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default Calendar;
