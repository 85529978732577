import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ActivityGroupList from "./ActivityGroupList";
import ActivityMemberList from "./ActivityMemberList";

function ActivityList(props) {
  const { type, folder, schedule, partUserList, changeVolunteerStatus, actIdx } = props;

  const navigate = useNavigate();

  const choosemembergroupClickHandler = () => {
    navigate("/org/activity/choosemembergroup", {
      state: {},
    });
  };

  const bottomView = () => {
    switch (type) {
      case "rejected":
        return (
          <Fragment>
            <div
              className="button button--small button--secondary float-right open-popup"
              data-popup="conform_cancle"
              id="submit"
            >
              반려 취소
            </div>
          </Fragment>
        );
      case "request":
        return (
          <Fragment>
            <div
              className="button button--small button--main float-right ml-10"
              onClick={choosemembergroupClickHandler}
            >
              승인
            </div>
            <div
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_dismissal"
            >
              반려
            </div>
          </Fragment>
        );
      case "schedule":
        return (
          <Fragment>
            <div
              className="button button--small button--main float-right ml-10"
              onClick={choosemembergroupClickHandler}
            >
              참여 신청
            </div>
          </Fragment>
        );
      case "history":
        return (
          <Fragment>
            {partUserList.map((partInfo) =>
              partInfo.type === "U" ? (
                <ActivityMemberList
                  btnType="history"
                  part={partInfo}
                  changeVolunteerStatus={changeVolunteerStatus}
                />
              ) : (
                <ActivityGroupList
                  btnType="history"
                  actIdx={5}
                  groupInfo={partInfo}
                  changeVolunteerStatus={changeVolunteerStatus}
                />
              )
            )}
          </Fragment>
        );
      default:
        break;
    }
  };

  const convertDate = (inputDate) => {
    //console.log(inputDate);
    const date = new Date(inputDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;

    return `${year}년 ${month}월 ${day}일`;
  };

  // 모집 중, 모집완료 className, Link 분기
  const [mainclassName, setMainClassName] = useState(
    "table card card--style-thumb mb-20"
  );
  const [textClassName, setTextClassName] = useState("fc_point1");
  const [clickLink, setClickLink] = useState(
    `/org/profile/listactivity/${(schedule && schedule.idx) ?? actIdx}/${
      schedule && schedule.activityScheduleIdx
    }`
  );

  const setBeneType = () => {
    if (schedule && Object.keys(schedule).includes("beneType")) {
      switch (schedule.beneType) {
        case "AX":
        case "A":
          return (
            <>
              개인{""}
              {schedule && schedule.orgTitle !== null ? (
                <Link to={`/${folder}/otherprofile/org/${schedule.orgIdx}`}>
                  {schedule && schedule.orgTitle && `, ${schedule.orgTitle}`}
                </Link>
              ) : (
                ", 단체/기관"
              )}
            </>
          );
        case "OX":
        case "O":
          return (
            <>
              {schedule && schedule.orgTitle !== null ? (
                <Link to={`/${folder}/otherprofile/org/${schedule.orgIdx}`}>
                  {schedule && schedule.orgTitle && ` ${schedule.orgTitle}`}
                </Link>
              ) : (
                "단체/기관"
              )}
            </>
          );
        case "UX":
        case "U":
          return "개인";
        default:
          return "없음";
      }
    }

    return (
      <Link to={`/${folder}/otherprofile/org/${schedule.orgIdx}`}>
        {schedule && schedule.orgTitle}
      </Link>
    );
  };

  useEffect(() => {
    if (schedule === undefined) return;

    // 시작 일자가 지금보다 작은 경우 -> 종료
    // if (
    //   schedule &&
    //   new Date(`${schedule.activityDate} ${schedule.activityTime24}`) <
    //     new Date()
    // ) {
    //   setMainClassName("table card card_point card--style-thumb mb-20");
    //   setTextClassName("point-white");
    //   setClickLink(
    //     `/org/profile/listactivityfinished/${
    //       schedule && schedule.activityScheduleIdx
    //     }`
    //   );
    // }
    if (schedule.recruit === "모집 마감") {
      setMainClassName("table card card_point card--style-thumb mb-20");
      setTextClassName("point-white");
      setClickLink(
        `/org/profile/listactivityfinished/${
          (schedule && schedule.activityScheduleIdx) ?? (schedule && schedule.id)
        }`
      );
    } else {
      setMainClassName("table card card--style-thumb mb-20");
      setTextClassName("fc_point1");
      setClickLink(
        `/org/profile/listactivity/${(schedule && schedule.idx) ?? actIdx}/${
          schedule && schedule.activityScheduleIdx
        }`
      );
    }
  }, [schedule]);

  return (
    <div className={mainclassName}>
      <div className="card__details">
        <Link to={clickLink} state={{ actIdx: schedule && schedule.idx }}>
          <h2 className={textClassName}>
            {schedule &&
              convertDate(schedule.activityDate) + " " + schedule.activityTime}
          </h2>
        </Link>
        <p className="card__list">
          활동 인원 :{" "}
          <Link to="#">
            {schedule &&
              `참여(지정) ${schedule.recruitedNum}명 / ${
                schedule.recruitNum === "0"
                  ? "제한없음"
                  : `필요인원 ${schedule.recruitNum}명`
              } (${schedule.recruit})`}
          </Link>
        </p>
        <p className="card__list">수요자 : {setBeneType()}</p>
        <p className="card__list">{` 활동시간 : ${schedule.activityTime} ~ ${schedule.activityTimeEnd}`}</p>
        {bottomView()}
      </div>
    </div>
  );
}

export default ActivityList;
