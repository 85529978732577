import React, { Fragment, useEffect, useState } from "react";
import Panel from "../../../components/Panel";
import ActivityDetail from "../../../components/ActivityDetail";
import ActivityDetailHeader from "../../../components/ActivityDetailHeader";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CheckLoginInfo from "../../../services/login-validation";
import { checkActivityPageAccess } from "../../../services/page-access-validation";

function DetailCarriedOut() {
  const { actIdx } = useParams();
  const navigate = useNavigate();
  const [activityData, setActivityData] = useState([]);

  const [hideContent, setHideContent] = useState(true);

  // 활동, 활동상세, 기관정보 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        checkActivityPageAccess(actIdx).then((res) => {
          if (res === "ERROR") {
            navigate("/");
          } else if (res === "TRUE") {
            // 활동, 활동상세, 기관정보 조회
            let URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;
            axios.get(URL).then((res) => {
              setActivityData(res.data);
            });

            setHideContent(false);
          } else {
            navigate(res, {
              state: {
                isRedirect: true,
              },
            });
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [actIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <ActivityDetailHeader title="활동 상세" actIdx={actIdx} />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <ActivityDetail folder="sole" activityData={activityData} />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default DetailCarriedOut;
