import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";

import Navigation from "../../../components/Navigation";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import ScheduleList from "../../../components/schedule-component/ScheduleList";
import ScheduleItem from "../../../components/ScheduleItem";
import isMobile from "../../../util/mobile-function";
import CheckLoginInfo from "../../../services/login-validation";

const isMoreThan30Min = (time) => {
  const timeAry = time.split(":");

  if (parseInt(timeAry[0]) > 0) {
    return true;
  } else {
    if (parseInt(timeAry[1]) > 30) {
      return true;
    } else {
      return false;
    }
  }
};

function Schedule(props) {
  const [attendingScheduleList, setAttendingScheduleList] = useState(null);
  const [startedScheduleList, setStartedScheduleList] = useState(null);
  const [nextScheduleList, setNextScheduleList] = useState(null);

  const [futureScheduleList, setFutureScheduleList] = useState({
    totalRows: 0,
    list: [],
  });

  const [pageNumber, setPageNumber] = useState(1);

  const getSchedule = useCallback(async () => {
    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/schedule/${
      window.sessionStorage.getItem("orgStatus") === "O" ? "org" : "sole"
    }/list/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const { totalRows, nextData, willStart, attending, started } =
          response.data;

        let attendingAry = [];
        let startedAry = [];
        let nextAry = [];

        for (let i = 0; i < attending.length; i++) {
          attendingAry.push({
            id: attending[i].id,
            scheduleId: attending[i].id,
            actId: attending[i].activityIdx,
            state: "WORKING",
            activityTitle: attending[i].title,
            passedTime: attending[i].joinAt,
            startTime: attending[i].activityTime,
            totalRecruitNum: attending[i].recruitNum,
            recruitNum: attending[i].attendedNum,
            // attendedNum : attending[i].attendedNum,
            type: "PART",
            orgType: "O",
            volunteer : attending[i].volunteer ?? null,
            joinType: attending[i].joinType,
          });
        }

        setAttendingScheduleList(attendingAry);

        for (let i = 0; i < willStart.length; i++) {
          // 아직 시작되지 않은 스케줄 -> remainTime
          if (Object.keys(willStart[i]).includes("remainTime")) {
            // 30분 넘게 남음
            if (isMoreThan30Min(willStart[i].remainTime)) {
              nextAry.push({
                id: willStart[i].id,
                actId: willStart[i].activityIdx,
                partType: willStart[i].partType,
                beneType: willStart[i].beneType,
                actType: "F",
                title: willStart[i].title,
                actDate: willStart[i].activityDate,
                actTime: willStart[i].activityTime,
                orgTitle: willStart[i].orgTitle,
                orgType: "O",
                recruitNum: willStart[i].recruitNum,
                img: Object.keys(willStart[i]).includes("imageUrl")
                  ? willStart[i].imageUrl
                  : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
                orgIdx: willStart[i].orgIdx,
                joinNum: willStart[i].joinNum + willStart[i].attendedNum,
                joinType: willStart[i].joinType,
              });
            }
            // startedData -> Wait
            else {
              startedAry.push({
                scheduleId: willStart[i].id,
                actId: willStart[i].activityIdx,
                state: "WAIT",
                activityTitle: willStart[i].title,
                startTime: willStart[i].activityTime,
                type: "PART",
                orgType: "O",
                totalRecruitNum: willStart[i].recruitNum,
                recruitNum: willStart[i].joinNum,
                attendedNum: willStart[i].attendedNum,
                leftTime: willStart[i].remainTime,
                joinType: willStart[i].joinType,
              });
            }
          }
          // 시작된 스케줄 -> elapsedTime
          else if (Object.keys(willStart[i]).includes("elapsedTime")) {
            startedAry.push({
              id: willStart[i].id,
              scheduleId: willStart[i].id,
              actId: willStart[i].activityIdx,
              state: "WORKING",
              activityTitle: willStart[i].title,
              passedTime: willStart[i].elapsedTime,
              startTime: willStart[i].activityTime,
              totalRecruitNum: willStart[i].recruitNum,
              recruitNum: willStart[i].attendedNum,
              // attendedNum : willStart[i].attendedNum,
              type: "PART",
              orgType: "O",
              joinType: willStart[i].joinType,
            });
          }
        }

        for (let i = 0; i < started.length; i++) {
          startedAry.push({
            scheduleId: started[i].id,
            actId: started[i].activityIdx,
            state: "STARTED",
            activityTitle: started[i].title,
            startTime: started[i].activityTime,
            type: "PART",
            orgType: "U",
            joinType: started[i].joinType,
            attendedNum: started[i].attendedNum,
          });
        }

        setStartedScheduleList(startedAry);

        for (let i = 0; i < nextData.length; i++) {
          nextAry.push({
            id: nextData[i].id,
            actId: nextData[i].activityIdx,
            orgType: "O",
            partType: nextData[i].partType,
            beneType: nextData[i].beneType,
            actType: nextData[i].activityType,
            title: nextData[i].title,
            actDate: nextData[i].activityDate,
            actTime: nextData[i].activityTime,
            orgTitle: nextData[i].orgTitle,
            recruitNum: nextData[i].recruitNum,
            joinNum: nextData[i].joinNum + nextData[i].attendedNum,
            img: Object.keys(nextData[i]).includes("imageUrl")
              ? nextData[i].imageUrl
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            joinType: nextData[i].joinType,
          });
        }

        setNextScheduleList({
          totalRows: totalRows,
          list: nextAry,
        });
      }
    } catch (e) {
      alert("스케줄 조회중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, []);

  const getSchedule_future = useCallback(async () => {
    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/schedule/${
      window.sessionStorage.getItem("orgStatus") === "O" ? "org" : "sole"
    }/list/future/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: pageNumber,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let nextAry = [];
        for (let i = 0; i < data.length; i++) {
          nextAry.push({
            id: data[i].id,
            actId: data[i].activityIdx,
            partType: data[i].partType,
            beneType: data[i].beneType,
            actType: data[i].activityType,
            title: data[i].title,
            actDate: data[i].activityDate,
            actTime: data[i].activityTime,
            orgTitle: data[i].orgTitle,
            orgType: "O",
            recruitNum: data[i].recruitNum,
            img: Object.keys(data[i]).includes("imageUrl")
              ? data[i].imageUrl
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            createdIdx: data[i].createdUid,
            orgIdx: data[i].orgIdx,
            joinNum: data[i].joinNum,
            joinType: data[i].joinType,
          });
        }

        if (pageNumber === 1) {
          setFutureScheduleList({
            totalRows: totalRows,
            list: nextAry,
          });
        } else {
          setFutureScheduleList({
            totalRows: totalRows,
            list: futureScheduleList.list.concat(nextAry),
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [pageNumber]);

  const joinAct = async (scheduleId) => {
    let data = new Object();
    data.activityScheduleIdx = scheduleId;

    const url = `${
      process.env.REACT_APP_SERVICE_API
    }/api/v1/schedule/org/join/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        alert("활동에 참여되었습니다.");
        getSchedule();
      }
    } catch (e) {
      alert("활동 참여 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const cancelJoinedAct = async (scheduleId) => {
    let data = new Object();
    data.activityScheduleIdx = scheduleId;

    const url = `${
      process.env.REACT_APP_SERVICE_API
    }/api/v1/schedule/org/cancel/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        alert("활동 참여가 취소되었습니다.");
        getSchedule();
      }
    } catch (e) {
      alert("활동 참여 취소 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const endActivity = async (scheduleId) => {
    const url = `${
      process.env.REACT_APP_SERVICE_API
    }/api/v1/schedule/finish/${window.sessionStorage.getItem("idx")}`;

    let data = new Object();
    data.activityScheduleIdx = scheduleId;

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        alert("활동이 종료되었습니다.");
        getSchedule();
      }
    } catch (e) {
      alert("활동을 종료하던 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getSchedule();
        getSchedule_future();

        if (isMobile()) {
          if (
            typeof window.Android !== "undefined" &&
            window.Android !== null
          ) {
            window.Android.storeUidToken(
              window.sessionStorage.getItem("idx"),
              "temp_token"
            );
          }
        }

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getSchedule, getSchedule_future]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header page__content page_section--with-bottom-nav pt-20">
          <h2>
            운영중인 활동({startedScheduleList && startedScheduleList.length})
          </h2>
          {startedScheduleList && (
              <ScheduleList
                  list={startedScheduleList}
                  joinAct={joinAct}
                  cancelJoinedAct={cancelJoinedAct}
                  endActivity={endActivity}
              />
          )}

          <h2>
            참여중인 활동({attendingScheduleList && attendingScheduleList.length})
          </h2>
          {attendingScheduleList && (
            <ScheduleList
              list={attendingScheduleList}
              joinAct={joinAct}
              cancelJoinedAct={cancelJoinedAct}
              endActivity={endActivity}
            />
          )}

          <h2>
            오늘 예정된 일정({nextScheduleList && nextScheduleList.list.length})
          </h2>
          <div className="cards cards--11">
            {nextScheduleList &&
              nextScheduleList.list.map((scheduleInfo) => (
                <ScheduleItem
                  folder="org"
                  scheduleInfo={scheduleInfo}
                  key={scheduleInfo.id}
                  isToday={true}
                />
              ))}
          </div>
          <h2>다음 예정된 일정({futureScheduleList.totalRows})</h2>
          <div className="cards cards--11">
            {futureScheduleList.list.map((scheduleInfo) => (
              <ScheduleItem
                folder="org"
                scheduleInfo={scheduleInfo}
                key={scheduleInfo.id}
                isToday={false}
              />
            ))}

            <div className="buttons buttons--centered mb-20">
              {futureScheduleList.totalRows > futureScheduleList.list.length ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <div id="bottom-toolbar_org" className="bottom-toolbar_org">
        <Navigation folder={"org"} />
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default Schedule;
