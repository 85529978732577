import React, { Fragment, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import Navigationbar from "../../../components/Navigationbar";
import ScheduleContext from "../../../context/Schedule.context";
import axios from "axios";
import CheckLoginInfo from "../../../services/login-validation";

const timeList = [
  "10:00~10:30",
  "10:30~11:00",
  "11:00~11:30",
  "11:30~12:00",
  "12:00~12:30",
  "12:30~13:00",
  "13:00~13:30",
  "13:30~14:00",
  "14:00~14:30",
  "14:30~15:00",
  "15:00~15:30",
  "15:30~16:00",
  "16:00~16:30",
  "16:30~17:00",
  "17:00~17:30",
  "17:30~18:00",
  "18:00~18:30",
  "18:30~19:00",
  "19:00~19:30",
];

function ScheduleList({ props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { addSchedule, clearSchedule } = useContext(ScheduleContext);

  const year = location.state && location.state.date.getFullYear();
  const month = location.state && location.state.date.getMonth() + 1;
  const date = location.state && location.state.date.getDate();
  const area = location.state && location.state.area;
  const nowdate = `${year}-${month >= 10 ? month : "0" + month}-${date >= 10 ? date : "0" + date
    }`;

  const [checkDateData, setCheckDateData] = useState([]);

  const [chktime, setChktime] = useState({
    checkboxoption1: "",
  });

  const getCheckDateData = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVICE_API}/api/v1/ok/phone/check-date/${nowdate}`
      )
      .then((res) => {
        const { categories } = res.data;
        setCheckDateData(categories);
      });
  };

  const checkHandler = (schedule, checkDate, expertIdx) => {
    if (schedule.yeyakYn === "N") {
      alert(`${nowdate} ${schedule.time} 시간대는 예약 불가능한 시간입니다.`);
      return;
    }

    // setChktime({
    //   ...chktime,
    //   [e.target.name]: e.target.value
    // })

    clearSchedule();

    if (chktime) {
      let phoneWhen = {
        consultationDate: nowdate,
        consultationTime: schedule.time,
        category: checkDate.category,
        id: checkDate.id,
        expertIdx: expertIdx,
      };
      addSchedule(phoneWhen);
    }

    navigate(-1);
  };

  const confirmHandler = () => {
    clearSchedule();
    if (chktime) {
      let phoneWhen = {
        consultationDate: nowdate,
        consultationTime: timeList[chktime.checkboxoption1],
      };
      addSchedule(phoneWhen);
    }
    navigate(-1);
  };

  const [categoryList, setCategoryList] = useState([]);

  const viewCategory = () => {
    let category = [];
    let preCateogry = "";

    area &&
      checkDateData &&
      checkDateData.forEach((checkDate, index) => {
        if (checkDate.category === area) {
          if (preCateogry !== checkDate.category) {
            category.push(
              <div className="page__title-bar">
                <h2 className="page__title">{checkDate.category}</h2>
              </div>
            );
          }

          preCateogry = checkDate.category;

          category.push(
            viewSchedule(checkDate, String(index), checkDate.expertIdx)
          );
        }
      });

    // setCategoryList(category);

    return category.length === 0 ? (
      <h2>해당 날짜에 상담 가능한 일정이 없습니다.</h2>
    ) : (
      category
    );
  };

  const viewSchedule = (checkDate, mainIdx, expertIdx) => {
    let ScheduleList = [];

    checkDate.schedules &&
      checkDate.schedules.forEach((schedule, index) => {
        const idx = mainIdx + index;

        ScheduleList.push(
          <div className="cart cart--page">
            <div
              className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal"
              id="item1"
            >
              <div className="checkbox-option checkbox-option--full">
                <input
                  type="checkbox"
                  name={`checkboxoption${idx}`}
                  checked={false}
                  id={`cp${idx}`}
                  value={idx}
                  onChange={() => checkHandler(schedule, checkDate, expertIdx)}
                />
                <label htmlFor={`cp${idx}`}>
                  <span>
                    {schedule.time}{" "}
                    {schedule.yeyakYn === "Y" ? "(예약가능)" : "(예약불가능)"}
                  </span>
                </label>
              </div>
            </div>
          </div>
        );
      });

    return ScheduleList;
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getCheckDateData();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title={`일정 (${nowdate})`} />
        {/* PAGE CONTENT */}
        <div className="page__content page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar mt-48 mb-24">
              <h1 className="page__title">일정</h1>
            </div>
            {checkDateData.length === 0 ? (
              <h2>해당 날짜에 상담 가능한 일정이 없습니다.</h2>
            ) : (
              viewCategory()
            )}
            {/* {viewCategory().length === 0 ? (
              <h2>해당 날짜에 상담 가능한 일정이 없습니다.</h2>
            ) : (
              categoryList
            )} */}
          </div>
          {/* <div className="under_button">
            <div className="form__row mt-40">
              <input onClick={confirmHandler} style={{cursor: 'pointer'}} type="button" name="submit" className="form__submit button button--main button--full" id="submit" value="확인" />
            </div>
          </div> */}
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default ScheduleList;
