const isMobile = () => {
  return /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     navigator.userAgent
  //   );
};

const isAndroid = () => {
  return /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

const isIos = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export { isAndroid, isIos };

export default isMobile;
