import React from "react";
import StartedSchedule from "./StartedSchedule";
import WaitingOnSchedule from "./WaitingOnSchedule";

import WorkingOnSchedule from "./WorkingOnSchedule";

const ScheduleList = ({
  list,
  joinAct,
  cancelJoinedAct,
  endActivity,
  endSoleSchedule,
}) => {
  const setScheduleList = () => {
    let ary = [];

    for (let i = 0; i < list.length; i++) {
      if (list[i].state === "WORKING") {
        ary.push(
          <WorkingOnSchedule
            scheduleInfo={list[i]}
            endActivity={endActivity}
            cancelJoinedAct={cancelJoinedAct}
            endSoleSchedule={endSoleSchedule}
            key={i}
          />
        );
      } else if (list[i].state === "WAIT") {
        ary.push(<WaitingOnSchedule scheduleInfo={list[i]} key={i} />);
      } else {
        ary.push(
          <StartedSchedule
            scheduleInfo={list[i]}
            joinAct={joinAct}
            cancelJoinedAct={cancelJoinedAct}
            key={i}
          />
        );
      }
    }

    return ary;
  };

  return <div className="cards cards--11">{setScheduleList()}</div>;
};

export default ScheduleList;
