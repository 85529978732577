import React from "react";
import { useNavigate } from "react-router-dom";

const UserGuideListItem = ({ userGuideInfo }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        navigate(`/common/community/helpdetail/${userGuideInfo.idx}`)
      }
      type="link"
      className="card card--style-inline card--style-inline-bg card--style-round-corners"
    >
      <div className="card__details card__details_nonpadding">
        <h3 className="card__title">{userGuideInfo.title}</h3>
      </div>
    </div>
  );
};

export default UserGuideListItem;
