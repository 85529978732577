import React, { Fragment, useEffect, useState } from "react";
import GroupMemberItem from "../../../components/GroupMemberItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function GroupMember() {
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="그룹명" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40 mb-40">
              <h2 className="lh60">그룹 회원(12)</h2>
            </div>
            <div className="cards cards--14">
              <GroupMemberItem folder="sole" title="없음" />
              <GroupMemberItem folder="sole" title="없음" />
              <GroupMemberItem folder="sole" title="없음" />
              <GroupMemberItem folder="sole" title="없음" />
              <GroupMemberItem folder="sole" title="없음" />
              <GroupMemberItem folder="sole" title="없음" />
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default GroupMember;
