import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { convertDashToDot } from "../util/convert-date-function";
import { onHandleImgError } from "../util/util-function";
import Tag from "./Tag";

function ActivityRequestList(props) {
  const {
    folder,
    activity,
    status,
    selectDeleteSchedule,
    isRequest,
    selectedScheduleInfo,
  } = props;

  const btnView = () => {
    switch (status) {
      case "R":
      case "N":
        if (isRequest === true) {
          return (
            <Fragment>
              <div
                className="button button--small button--secondary float-right ml-10 open-popup"
                data-popup="conform_cancle"
                onClick={() =>
                  selectDeleteSchedule
                    ? selectDeleteSchedule(activity.id)
                    : selectedScheduleInfo(activity.id, true)
                }
              >
                반려 취소
              </div>
            </Fragment>
          );
        }

        return (
          <Fragment>
            <div
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_delete"
              onClick={() =>
                selectDeleteSchedule
                  ? selectDeleteSchedule(activity.id)
                  : selectedScheduleInfo(activity.id, false)
              }
            >
              삭제
            </div>
          </Fragment>
        );
      case "W":
        if (isRequest === true) {
          return (
            <Fragment>
              <div
                data-popup="conform_success"
                className="button button--small button--main float-right ml-10 open-popup"
                onClick={() => selectedScheduleInfo(activity.id, true)}
              >
                승인
              </div>
              <div
                className="button button--small button--secondary float-right ml-10 open-popup"
                data-popup="conform_dismissal"
                onClick={() => selectedScheduleInfo(activity.id, true)}
              >
                반려
              </div>
            </Fragment>
          );
        }

        return (
          <Fragment>
            <div
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_cancle"
              onClick={() =>
                selectDeleteSchedule
                  ? selectDeleteSchedule(activity.id)
                  : selectedScheduleInfo(activity.id, false)
              }
            >
              신청 취소
            </div>
          </Fragment>
        );
      case "A":
        return <Fragment></Fragment>;
      case "D":
        if (activity.recruitState === "Y") {
          return (
            <Fragment>
              <div
                className="button button--small button--secondary float-right ml-10 open-popup"
                data-popup="conform_cancle"
                onClick={() =>
                  selectDeleteSchedule
                    ? selectDeleteSchedule(activity.id)
                    : selectedScheduleInfo(activity.id, false)
                }
              >
                신청 취소
              </div>
            </Fragment>
          );
        } else {
          return null;
        }
      default:
        break;
    }
  };

  const tagView = () => {
    switch (status) {
      case "R":
      case "N":
        return (
          <Fragment>
            <Tag tagName="반려" />
          </Fragment>
        );
      case "D":
        return (
          <Fragment>
            <Tag tagName="승인됨" />
          </Fragment>
        );
      case "W":
        return (
          <Fragment>
            <Tag tagName="대기 중" />
          </Fragment>
        );
      default:
        break;
    }
  };

  const setBeneOption = () => {
    switch (activity.beneType) {
      case "A":
      case "AX":
        return (
          <>
            {" "}
            회원
            <Link
              to={
                activity.beneOrgTitle
                  ? `/${folder}/otherprofile/org/${activity.beneOrgIdx}`
                  : ""
              }
            >
              {activity.beneOrgTitle
                ? `, ${activity.beneOrgTitle}`
                : ", 기관/단체"}
            </Link>
          </>
        );
      case "U":
      case "UX":
        return "회원";
      case "O":
      case "OX":
        return (
          <Link
            to={
              activity.beneOrgTitle
                ? `/${folder}/otherprofile/org/${activity.beneOrgIdx}`
                : ""
            }
          >
            {activity.beneOrgTitle ? `${activity.beneOrgTitle}` : "기관/단체"}
          </Link>
        );
      default:
        return "회원";
    }
  };

  return (
    <Fragment>
      <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
        <div className="img-details__thumb">
          <img
            src={activity.img}
            onError={(e) => onHandleImgError(e, "A")}
            alt=""
          />
        </div>
        <div className="card__details">
          <Link to={`/${folder}/activity/detailbene/${activity.activityIdx}`}>
            <h4 className="card__title">{activity.title}</h4>
          </Link>
          <Tag
            tagName={activity.activityType === "O" ? "온라인" : "오프라인"}
          />
          <p className="card__info">{activity.address}</p>
          {/* <p className="card__info">{`${convertDashToDot(
            activity.activityDate
          )} ${activity.activityTime}/${activity.address}`}</p> */}
          <Link to={`/sole/otherprofile/sole/${activity.userIdx}`}>
            <h4 className="card__username fc_point1">{activity.nickName}</h4>
          </Link>
        </div>
      </div>
      <div className="card card--style-inline card--style-inline-bg card--style-round-corners margin-t-m40">
        <div className="card__details">
          <h2 className="fc_point1">{`${convertDashToDot(
            activity.activityDate
          )} ${activity.activityTime}`}</h2>
          <p className="card__list">
            활동 인원 :{" "}
            <Link to="">{`참여(지정) ${activity.recruitNum}명 / ${
              activity.totalRecruitNum === 0
                ? "제한없음"
                : `필요인원 ${activity.totalRecruitNum}명`
            } (${
              activity.recruitState === "Y" ? "모집 중" : "모집 마감"
            })`}</Link>
          </p>
        <p className="card__list">{`활동 시간 : ${activity.activityTime} ~ ${activity.activityTimeEnd}`}</p>
          <p className="card__list">
            {activity.beneType !== "X" && `수요자 : `}
            {setBeneOption()}
          </p>
          {tagView()}
          {btnView()}
        </div>
      </div>
    </Fragment>
  );
}

export default ActivityRequestList;
