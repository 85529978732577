import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import TimeItem2 from "../../../components/TimeItem2";
import CheckLoginInfo from "../../../services/login-validation";

const time = [
  "오전 00시 00분",
  "오전 00시 30분",
  "오전 1시 00분",
  "오전 1시 30분",
  "오전 2시 00분",
  "오전 2시 30분",
  "오전 3시 00분",
  "오전 3시 30분",
  "오전 4시 00분",
  "오전 4시 30분",
  "오전 5시 00분",
  "오전 5시 30분",
  "오전 6시 00분",
  "오전 6시 30분",
  "오전 7시 00분",
  "오전 7시 30분",
  "오전 8시 00분",
  "오전 8시 30분",
  "오전 9시 00분",
  "오전 9시 30분",
  "오전 10시 00분",
  "오전 10시 30분",
  "오전 11시 00분",
  "오전 11시 30분",
  "오후 12시 00분",
  "오후 12시 30분",
  "오후 1시 00분",
  "오후 1시 30분",
  "오후 2시 00분",
  "오후 2시 30분",
  "오후 3시 00분",
  "오후 3시 30분",
  "오후 4시 00분",
  "오후 4시 30분",
  "오후 5시 00분",
  "오후 5시 30분",
  "오후 6시 00분",
  "오후 6시 30분",
  "오후 7시 00분",
  "오후 7시 30분",
  "오후 8시 00분",
  "오후 8시 30분",
  "오후 9시 00분",
  "오후 9시 30분",
  "오후 10시 00분",
  "오후 10시 30분",
  "오후 11시 00분",
  "오후 11시 30분",
];

function ScheduleList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [chkClick, setChkClick] = useState(false);
  const [reset, setReset] = useState(false);

  const year = location.state && location.state.date.getFullYear();
  const month = location.state && location.state.date.getMonth() + 1;
  const date = location.state && location.state.date.getDate();
  const actSchduelIdx = location.state && location.state.actSchduelIdx;
  // actList, actSchedual
  const type = location.state && location.state.type;
  const actIdx = location.state && location.state.actIdx;
  const actListIdx = location.state && location.state.actListIdx;

  const nowdate = `${year}-${month >= 10 ? month : "0" + month}-${date >= 10 ? date : "0" + date
    }`;

  const [lastSelTimee, setLastSelTime] = useState("");
  const [lastSelcnt, setLastSelCnt] = useState("");

  const checkedItemHandler = (id, isChecked, cnt) => {
    setLastSelCnt(cnt);
    if (isChecked) {
      checkedItems.add(id);
      setCheckedItems(checkedItems);
      setLastSelTime(id);
    } else if (!isChecked && checkedItems.has(id)) {
      setLastSelTime("");
      checkedItems.delete(id);
      setCheckedItems(checkedItems);
    }

    if (checkedItems.size > 1) {
      checkedItems.clear();
      setReset(true);
    } else {
      setReset(false);
    }
  };

  const hasCheckedItem = (id) => {
    return checkedItems.has(id);
  };

  // 확인 버튼 (type : actList, actSchedual)
  const confirmHandler = () => {
    if (lastSelTimee === "") {
      alert("일정을 선택해주세요.");
      return;
    }

    // 활동자 일정수정
    if (type === "actList") {
      const params = {
        activityDate: nowdate,
        activityTime: lastSelTimee,
        activityIdx: actIdx,
        recruitNum: lastSelcnt,
        actListIdx: actListIdx,
        createdUid: sessionStorage.idx,
        updatedUid: sessionStorage.idx,
      };

      console.log(params);

      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/getSearchDateTime`;
      axios
        .get(URL, {
          params: params,
        })
        .then((res) => {
          // console.log(res.data);
          navigate(`/org/profile/listactivity/${actIdx}/${actSchduelIdx}`);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
    // 해당 일정수정
    else if (type === "actSchedual") {
      const params = {
        activityDate: nowdate,
        activityTime: lastSelTimee,
        recruitNum: lastSelcnt,
        updatedUid: window.sessionStorage.getItem("idx"),
      };

      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/full_fix/${actSchduelIdx}`;
      axios
        .put(URL, null, {
          params: params,
        })
        .then((res) => {
          // console.log(res.data);
          navigate(-2);
        })
        .catch((err) => {
          const resErr = err.response;
          console.log(resErr);
          if (resErr.status === 401) {
            alert("이미 등록되어 있는 날짜입니다.");
          }
        });
    }
  };

  const timeitem = time.map((item, index) => (
    <TimeItem2
      key={index}
      item={item}
      index={index}
      checkedItemHandler={checkedItemHandler}
      setSchedule={location.state && location.state.setSchedule}
      chkClick={chkClick}
      nowdate={nowdate}
      reset={reset}
      lastSelTimee={lastSelTimee}
    />
  ));

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        {nowdate && nowdate.includes("null") ? (
          <SubHeader title="일정" />
        ) : (
          <SubHeader title={`일정 (${nowdate})`} />
        )}
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar pt-40 mb-24">
              <h1 className="page__title">일정</h1>
            </div>
            {timeitem}
            <div className="form__row mt-40">
              <input
                onClick={confirmHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="확인"
              />
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default ScheduleList;
