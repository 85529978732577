import React, { useContext } from 'react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UrlContext from '../context/Url.context';

function OkItem(props) {
  const {type} = props;
  const urlContext = useContext(UrlContext);
  const navigate = useNavigate();

  const title = type === "okonline" ? "온라인 상담" : "전화 상담 예약";
  const imgPath = type === "okonline" ? "ONLINE/e3d01255-1001-4eb2-bf50-c8e43ff42c5f.bmp" : "PHONE/6ac75603-3c7c-4d43-a053-3a5259d6ee99.bmp";

  const orgClickHandler = () => {
    navigate(`/sole/otherprofile/org`, {
      staet : {

      }
    })
  }

  return (
    <div className="card card--style-thumb">
      <div className="card__thumb card__thumb--round-corners">
        <Link to={`/sole/${type}/detail`}><img src={`${urlContext.value}/admin-service/ok/${imgPath}`} alt="" /></Link>
      </div>
      <div className="card__details" onClick={orgClickHandler} style={{cursor: 'pointer'}}>
        <h4 className="card__username fc_point1">서초민원센터</h4>
        <h4 className="card__title">{title}</h4>
        <p className="card__info">매주 월,화,수,목,금</p>
      </div>
    </div>
  );
}

export default OkItem;