import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Tag from "../components/Tag";
import { maskingName } from "../util/util-function";

function OkOnlineDetail(props) {
  const { onconIdx } = useParams();
  const [detailList, setDetailList] = useState();
  const [writeuser, setWriteuser] = useState();
  const [nickname, setNickname] = useState();
  const [name, setName] = useState();

  // useEffect(() => {
  //   let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation`
  //   axios.get(url)
  //   .then(res => {
  //     const data = res.data.data
  //     {
  //       data && data.map(item => {
  //         if (item.idx == onconIdx){
  //           setDetailList(item)
  //           setWriteuser(item.createdUid)
  //         }
  //       })
  //     }
  //     if (writeuser) {

  //       let userurl = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${writeuser}`
  //       axios.get(userurl)
  //       .then(res => {
  //         const data = res.data
  //         setNickname(data.nickname)
  //         setName(data.name)
  //       })
  //       .catch(err => {
  //         console.log(err)
  //       })
  //     }
  //     })
  //     .catch(err =>  {
  //       console.log(err)
  //     })
  // },[writeuser])

  useEffect(() => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/${onconIdx}`;
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        setDetailList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const tagView = (e) => {
    return <Tag tagName={detailList && detailList.area} />;
  };

  const convertDate = (inputDate) => {
    if (inputDate !== "" && inputDate !== undefined) {
      const date = new Date(inputDate);
      console.log(date);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let min = date.getMinutes();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour > 12 ? "오후 " + (hour - 12) : "오전 " + hour;

      // return `${year}.${month}.${day}
      //         ${hour}시 ${min}분`;
      return `${year}.${month}.${day}`;
    } else {
      return ``;
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-space align-items-center pt-40">
        {/* formatdate */}
        <p className="card__info">
          {detailList && convertDate(detailList.createdAt)}
        </p>
        {/* <p className="card__info">2021.01.04 오후 9시 20분</p> */}
      </div>
      <div className="fieldsets">
        <ul className="custom-listing mb-20">
          <li className="mt-20">
            <h2>{detailList && detailList.title}</h2>
          </li>
          <li>
            <span>이용자 : </span>
            {detailList && `${detailList.userNickname}(${detailList.userName})`}
          </li>
          <li>
            <span>상담 분야 : </span>
            {tagView()}
          </li>
          <li>
            <span>
              상세 : <br />
            </span>
            <p
              dangerouslySetInnerHTML={{
                __html: detailList && detailList.content,
              }}
            />
          </li>
        </ul>
      </div>
      <div className="d-flex justify-space align-items-center pt-40">
        <h2 className="page__title mb-0">답변</h2>
        <p className="card__info">
          {detailList && detailList.consultationStatus === "D"
            ? detailList && convertDate(detailList.answeredAt)
            : ""}
        </p>
      </div>
      <div className="fieldsets">
        <ul className="custom-listing mb-20">
          {detailList && detailList.consultationStatus === "D" ? (
            <>
              <li>
                <span>전문가 : </span>
                {detailList && maskingName(detailList.expertName)}
              </li>
              <li>
                <span>
                  답변 : <br />
                </span>

                <p
                  dangerouslySetInnerHTML={{
                    __html: detailList.answer && detailList.answer,
                  }}
                />
              </li>
            </>
          ) : (
            <>
              <li>
                <span>전문가 : </span>
                {detailList && detailList.expertName}
              </li>
              <li>
                <span>
                  답변 : <br />
                </span>
                아직 답변이 등록되지 않았습니다.
              </li>
            </>
          )}
        </ul>
      </div>
    </Fragment>
  );
}

export default OkOnlineDetail;
