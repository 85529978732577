import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isAndroid, isIos } from "../../util/mobile-function";

function Login(props) {
  const [values, setValues] = useState({
    nickname: "",
    password: "",
  });

  const handleChangeForm = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const login = (e) => {
    e.preventDefault();

    // 기억하기
    if (isRemember) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 30);

      setCookie_id("__seocho__volunteer__save__id__", values.nickname, {
        path: "/",
        expires: expires,
      });
      setCookie_pwd("__seocho__volunteer__save__pwd__", values.password, {
        path: "/",
        expires: expires,
      });
    } else {
      removeCookie_id("__seocho__volunteer__save__id__");
      removeCookie_pwd("__seocho__volunteer__save__pwd__");
    }

    // axios URL
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/login`;
    let user = {
      nickname: values.nickname,
      password: values.password,
    };
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, user, config)
      .then((res) => {
        sessionStorage.setItem("idx", res.data.idx);
        sessionStorage.setItem("nickname", res.data.nickname);
        sessionStorage.setItem("email", res.data.email);
        sessionStorage.setItem("phoneNum", res.data.phoneNum);
        sessionStorage.setItem("name", res.data.name);
        sessionStorage.setItem("orgStatus", res.data.orgStatus);
        sessionStorage.setItem("expertStatus", res.data.expertStatus);
        sessionStorage.setItem("coinStatus", res.data.coinStatus);

        if (res.data.orgStatus === "O") {
          sessionStorage.setItem("orgType", res.data.orgType);

          sessionStorage.setItem("scCoinCenterId", res.data.scCoinCenterId);
          sessionStorage.setItem("scCoinAdminId", res.data.scCoinAdminId);
        }

        // console.log(res.data.idx);
        // console.log(window.sessionStorage.getItem("idx"));

        if (
          isAndroid() &&
          typeof window.Android !== "undefined" &&
          window.Android !== null
        ) {
          window.Android.sendFcmToken(res.data.idx);
        } else if (isIos()) {
          try {
            window.webkit.messageHandlers.callbackHandler4Fcm.postMessage(
              "uid=" + window.sessionStorage.getItem("idx")
            );
          } catch (err) {
            alert(err);
          }
        }

        // sole, org 인지 확인 orgstatus,
        if (res.data.orgStatus === "O") {
          document.location.href = "/org/home/org";
        } else if (res.data.orgStatus === "W" || res.data.orgStatus === "N") {
          document.location.href = "/common/org-login/waiting";
        } else {
          document.location.href = "/sole/home/solepart";
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
        //alert(err.response.data);
        alert("로그인 정보를 확인해주세요.");
      });
  };

  const [isRemember, setIsRemember] = useState(false);
  const [cookie_id, setCookie_id, removeCookie_id] = useCookies(
    "__seocho__volunteer__save__id__"
  );
  const [cookie_pwd, setCookie_pwd, removeCookie_pwd] = useCookies(
    "__seocho__volunteer__save__pwd__"
  );

  const onChangeCheckBox = () => {
    setIsRemember(!isRemember);
  };

  useEffect(() => {
    if (
      cookie_id.__seocho__volunteer__save__id__ !== undefined &&
      cookie_pwd.__seocho__volunteer__save__pwd__ !== undefined
    ) {
      setValues({
        nickname: cookie_id.__seocho__volunteer__save__id__,
        password: cookie_pwd.__seocho__volunteer__save__pwd__,
      });

      setIsRemember(true);
    }
  }, []);

  // 서버 점검 팝업
  // return (
  //   <Fragment>
  //     <div
  //       clasName=""
  //       style={{
  //         textAlign: "center",
  //         width: "100%",
  //         marginTop: "40vw",
  //         color: "black",
  //         fontSize: "38px",
  //         fontWeight: "700",
  //       }}
  //     >
  //       점검중입니다.{"\n"}
  //     </div>
  //     <div
  //       clasName=""
  //       style={{
  //         textAlign: "center",
  //         width: "100%",
  //         fontSize: "20px",
  //         marginTop: "10vw",
  //         color: "red",
  //       }}
  //     >
  //       서버 점검중입니다.
  //     </div>
  //     <div
  //       clasName=""
  //       style={{
  //         textAlign: "center",
  //         width: "100%",
  //         fontSize: "20px",
  //         color: "black",
  //       }}
  //     >
  //       불편을 끼쳐드려서 죄송합니다.
  //     </div>
  //   </Fragment>
  // );

  return (
    <Fragment>
      <div className="page page--login" data-page="login">
        {/* HEADER */}
        <header className="header header--fixed">
          <div className="header__inner">
            <div className="header__icon">
              {/* <a href="splash.html"><img src="../../assets/images/icons/gradient-orange/arrow-back.svg" alt="" /></a> */}
            </div>
          </div>
        </header>
        <div className="login">
          <div className="login__content">
            <h2 className="login__title">로그인</h2>
            <p className="login__text">로그인 해주세요.</p>
            <div className="login-form">
              <form id="LoginForm">
                {/* <form id="LoginForm" method="post" action="/org/home/org"> */}
                <div className="login-form__row">
                  <label className="login-form__label">ID</label>
                  <input
                    type="text"
                    name="nickname"
                    value={values.nickname}
                    onChange={handleChangeForm}
                    className="login-form__input required"
                  />
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">비밀번호</label>
                  <input
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChangeForm}
                    className="login-form__input required"
                  />
                </div>
                <div className="login-form__row">
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      name="rememb-check"
                      id="rememb-check"
                      checked={isRemember}
                      onClick={() => onChangeCheckBox()}
                    />{" "}
                    비밀번호 기억하기
                  </label>
                </div>
                <div className="login-form__row">
                  <input
                    type="submit"
                    onClick={login}
                    name="submit"
                    className="login-form__submit button button--main button--full"
                    id="submit"
                    value="로그인"
                  />
                </div>
              </form>
              <br />
              <br />
              <div className="login-form__forgot-idpass">
                <Link to="/common/forgotid" className="mr-40">
                  ID 찾기
                </Link>
                <Link to="/common/forgotpassword" className="mr-40">
                  비밀번호 찾기
                </Link>
                {/* <Link to="/common/community/privacypolicy">
                  개인정보 처리방침
                </Link> */}
              </div>
              <div className="login-form__bottom">
                <p>아직 회원이 아니십니까?</p>
                <Link
                  to="/common/privacy-policy"
                  className="button button--secondary button--full"
                >
                  회원가입
                </Link>
              </div>
              {/* DID인증 로그인 추후 개발 예정 */}
              {/* <div className="login-form__bottom">
                <a href="#" className="button button--secondary button--full">
                  DID 인증 로그인하기
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
