import React, { useEffect, useState, useCallback, Fragment } from "react";
import axios from "axios";

import ActivityRequestList from "../../../components/ActivityRequestList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";

const HelpScheduleHistory = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const onChangePageNumber = () => {
    setPageNumber(pageNumber + 1);
  };

  const [totalRows, setTotalRows] = useState(0);
  const [activityList, setActivityList] = useState(null);

  const [delIdx, setDelIdx] = useState(null);

  const selectDeleteSchedule = (seletedIdx) => {
    setDelIdx(seletedIdx);
  };

  const deleteRequestSchedule = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/${delIdx}`;

    try {
      const response = await axios.delete(url);

      if (response.status) {
        alert("처리되었습니다.");
        getRequestOperationList();
      }
    } catch (e) {
      console.log(e);
      alert("처리 중, 오류가 발생하였습니다.");
    }
  };

  const cancelActivity = () => {
    deleteRequestSchedule();
  };

  const rejectActivity = () => {
    alert("반려됨");
    // 반려 처리 코드
  };

  const deleteActivity = () => {
    deleteRequestSchedule();
  };

  const acceptActivity = () => {
    alert("승인됨");
    // 승인 처리 코드
  };

  const getRequestOperationList = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/request/help-schedule/list/org/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: pageNumber,
          count: 5,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            id: data[i].activityScheduleIdx,
            activityIdx: data[i].activityIdx,
            img:
              Object.keys(data[i]).includes("img") && data[i].img != null
                ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${data[i].img}`
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            title: data[i].title,
            activityType: data[i].activityType,
            address: `${data[i].address1} ${
              Object.keys(data[i]).includes("address2") && data[i].address2
            }`,
            nickName: data[i].helpUserNickname,
            userIdx: data[i].helpUserIdx,
            activityDate: data[i].activityDate,
            activityTime: data[i].activityTime,
            recruitNum: data[i].joinNum,
            totalRecruitNum: data[i].recruitNum,
            recruitState: data[i].recruit === "모집 중" ? "Y" : "N",
            beneType: data[i].beneType,
            beneOrgTitle: Object.keys(data[i]).includes("beneOrgTitle")
              ? data[i].beneOrgTitle
              : null,
            beneOrgIdx: data[i].helpUserIdx,
            activityState: data[i].helpStatus,
          });
        }

        setTotalRows(totalRows);

        if (pageNumber === 1) {
          setActivityList(ary);
        } else {
          setActivityList(activityList.concat(ary));
        }
      }
    } catch (e) {
      alert("도움 요청한 활동 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [pageNumber]);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getRequestOperationList();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getRequestOperationList]);

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="도움 요청한 활동" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar">
              <h2>활동 ({totalRows})</h2>
            </div>
            <div className="cards cards--11">
              {activityList &&
                activityList.map((activity) => (
                  <ActivityRequestList
                    folder="org"
                    activity={activity}
                    status={activity.activityState}
                    key={activity.id}
                    selectDeleteSchedule={selectDeleteSchedule}
                  />
                ))}
              {activityList && totalRows !== activityList.length && (
                <div className="buttons buttons--centered mb-20">
                  <div
                    className="button button--more"
                    onClick={onChangePageNumber}
                  >
                    더 보기
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        text="신청을 취소하시겠습니까?"
        popupType="cancle"
        conformClick={cancelActivity}
      />
      <ConformPopup
        text="활동 요청을 반려하시겠습니까?"
        popupType="dismissal"
        conformClick={rejectActivity}
      />
      <ConformPopup
        text="반려된 도움 요청 스케줄을 삭제하시겠습니까?"
        popupType="delete"
        conformClick={deleteActivity}
      />
      <ConformPopup
        text="활동 요청을 승인하시겠습니까?"
        popupType="success"
        conformClick={acceptActivity}
      />
      <Navigationbar />
    </Fragment>
  );
};

export default HelpScheduleHistory;
