import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { onHandleImgError } from "../util/util-function";
import FormatDate from "./FormatDate";

function PhoneconsultItem(props) {
  const { item, expertdetail } = props;

  const navigate = useNavigate();

  const tagView = () => {
    switch (expertdetail && expertdetail.area) {
      case "법률":
        return "law";
      case "세무":
        return "tax";
      case "법무":
        return "legal";
      case "건축":
        return "cons";
      case "노무":
        return "labor";
      default:
        return "law";
    }
  };

  const [phoneconuser, setPhoneconuser] = useState();
  // 전화 상담 예약자 개인정보 조회
  useEffect(() => {
    if (!Object.keys(item).includes("nickname")) {
      let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${item.createdUid}`;
      axios
        .get(url)
        .then((res) => {
          setPhoneconuser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [item]);

  const gookphoneconsult = () => {
    let url = `/sole/profileexpert/okphonereportd/${item.idx}`; // 답변 완료 시
    if (item.consultationStatus === "W") {
      url = `/sole/profileexpert/okphonereport/${item.idx}`; // 답변 대기 중일 시
    }

    navigate(url, {
      state: {
        params: item.idx,
        phoneconuser: Object.keys(item).includes("nickname")
          ? item
          : phoneconuser,
        expertdetail: expertdetail,
        item: item,
      },
    });
  };

  return (
    <Fragment>
      <div className="card card--style-inline card--style-inline-bg card--style-round-corners ">
        <div className="user-details__thumb">
          <img
            src={
              Object.keys(item).includes("imageUrl")
                ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${item.imageUrl}`
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`
            }
            onError={(e) => onHandleImgError(e, "U")}
            alt=""
          />
        </div>
        <div className="card__details">
          <h4
            className="card__username fc_point1"
            onClick={gookphoneconsult}
            style={{ cursor: "pointer" }}
          >
            {item.content}
          </h4>
          {/* <Link to={`/sole/otherprofile/sole/${item.userIdx}`}> */}
          <h4 className="card__title">
            {Object.keys(item).includes("nickname")
              ? item.nickname
              : phoneconuser && phoneconuser.nickname}
          </h4>
          {/* </Link> */}
          <p className="card__info">
            연락처 :{" "}
            {Object.keys(item).includes("phoneNum")
              ? item.phoneNum
              : phoneconuser && phoneconuser.phoneNum}
          </p>
          <div>
            {item.consultationStatus &&
              {
                W: (
                  <>
                    <p
                      className="card__info"
                      onClick={gookphoneconsult}
                      style={{ cursor: "pointer" }}
                    >
                      <FormatDate date={item.consultationDate} />{" "}
                      {item.consultationTime}
                    </p>
                    <a className={`caption__category tag_expert_${tagView()}`}>
                      {item.area}
                    </a>

                    {Object.keys(item).includes("isToday") &&
                      {
                        T: (
                          <a className="caption__category tag_operator">오늘</a>
                        ),
                        P: (
                          <a className="caption__category tag_finished">
                            일정지남
                          </a>
                        ),
                        F: null,
                      }[item.isToday]}
                    <a className="caption__category tag_waiting">대기 중</a>
                    <div>
                      <div
                        className="button button--small button--main float-right ml-10"
                        onClick={gookphoneconsult}
                      >
                        내역 작성하기
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="button button--small button--main float-right ml-10"
                      >
                        <a
                          href={`tel: ${
                            Object.keys(item).includes("phoneNum")
                              ? item.phoneNum
                              : phoneconuser && phoneconuser.phoneNum
                          }`}
                        >
                          전화하기
                        </a>
                      </div>
                    </div>
                  </>
                ),
                D: (
                  <>
                    <p className="card__info" onClick={gookphoneconsult}>
                      <FormatDate date={item.consultationDate} />{" "}
                      {item.consultationTime}
                    </p>
                    <a className={`caption__category tag_expert_${tagView()}`}>
                      {item.area}
                    </a>
                    {Object.keys(item).includes("isToday") &&
                      {
                        T: (
                          <a className="caption__category tag_operator">오늘</a>
                        ),
                        // P: (
                        //   <a className="caption__category tag_finished">
                        //     일정지남
                        //   </a>
                        // ),
                        F: null,
                      }[item.isToday]}
                    <a className="caption__category tag_finished">완료</a>
                  </>
                ),
              }[item.consultationStatus]}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PhoneconsultItem;
