import React from 'react';

const ConformPopup = (props) => {
  const {conformClick, text, popupType} = props;

  return (
    <div id={`popup--conform_${popupType}`}>
      <div className={`popup popup--centered popup--shadow popup--conform_${popupType}`} >
        <div className="popup__close"><a className="close-popup" data-popup={`conform_${popupType}`}><img src="/assets/images/icons/blue/close.svg" alt="" /></a></div>
        <div className="popup__icon"><img src="/assets/images/icons/blue/alert.svg" alt="" /></div>
        <h2 className="popup__title">확인 !</h2>
        <p className="popup__text">{text}</p>
        <div className="form__row mt-40">
          <input data-popup={`conform_${popupType}`} style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--gray button--50 close-popup" id="submit" value="취소" />
          <input data-popup={`conform_${popupType}`} onClick={conformClick} style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--main button--50 close-popup" id="submit" value="확인" />
        </div>
      </div>
    </div>
  );
}

export default ConformPopup;