import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ForgotIdD from "./ForgotIdD";

function ForgotId(props) {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
  });

  const [verificationCode, setVerificationCode] = useState("");

  const onChangeUserInfo = (name, data) => {
    setUserInfo({
      ...userInfo,
      [name]: data,
    });
  };

  const onChangeVerificationCode = (data) => {
    setVerificationCode(data);
  };

  const inputRef = useRef();

  const onClickVerificationBtn = async () => {
    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/sms/find-nickname`;

    let data = new Object();
    data.name = userInfo.name;
    // data.email = userInfo.email;
    data.phoneNum = userInfo.email;

    try {
      const response = await axios.post(url, data);

      if (response.status === 200) {
        const { status, error } = response.data;

        if (status === "OK") {
          alert("인증코드가 휴대폰으로 전달되었습니다.");
          inputRef.current.disabled = false;
        } else {
          alert(error);
        }
      }
    } catch (e) {
      alert("인증 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const [showNickName, setShowNickName] = useState(false);
  const closeShowNickNameModal = () => {
    setShowNickName(false);
  };

  const [nickName, setNickName] = useState("");

  const onClickFindNickName = async () => {
    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/sms/confirm-nickname`;

    let data = new Object();
    data.name = userInfo.name;
    // data.email = userInfo.email;
    data.phoneNum = userInfo.email;
    data.authKey = verificationCode;

    try {
      const response = await axios.post(url, data);

      if (response.status === 200) {
        const { status, nickname, error } = response.data;

        if (status === "OK") {
          setNickName(nickname);
          setShowNickName(true);
        } else {
          alert(error);
        }
      }
    } catch (e) {
      alert("ID 찾기 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  if (showNickName) {
    return (
      <ForgotIdD closeModal={closeShowNickNameModal} nickName={nickName} />
    );
  }

  return (
    <div className="page page--login" data-page="login">
      {/* HEADER */}
      <header className="header header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <Link to="" onClick={() => navigate(-1)}>
              <img
                src="/assets/images/icons/gradient-orange/arrow-back.svg"
                alt=""
              />
            </Link>
          </div>
        </div>
      </header>
      <div className="login">
        <div className="login__content">
          <h2 className="login__title">ID 찾기</h2>
          <p className="login__text">휴대폰 인증으로 ID 찾기</p>
          <div className="login-form">
            <form id="LoginForm">
              <div className="login-form__row">
                <label className="login-form__label">이름</label>
                <input
                  type="text"
                  name="name"
                  value={userInfo.name}
                  onChange={(e) =>
                    onChangeUserInfo(e.target.name, e.target.value)
                  }
                  className="login-form__input required"
                />
              </div>
              <div className="login-form__row">
                <label className="login-form__label">휴대폰 번호</label>
                <input
                  name="email"
                  value={userInfo.phone}
                  onChange={(e) =>
                    onChangeUserInfo(e.target.name, e.target.value)
                  }
                  className="login-form__input_min-50 required email"
                />
                <input
                  type="button"
                  className="button button--main button--half-50"
                  value="인증번호 발송"
                  onClick={() => onClickVerificationBtn()}
                />
              </div>

              <div className="login-form__row">
                <label className="login-form__label">인증번호 입력</label>
                <input
                  type="text"
                  ref={inputRef}
                  disabled
                  defaultValue={verificationCode}
                  onChange={(e) => onChangeVerificationCode(e.target.value)}
                  className="login-form__input"
                />
              </div>
              <div className="login-form__row">
                <input
                  onClick={() => onClickFindNickName()}
                  className="login-form__submit button button--main button--full"
                  value="확인"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotId;
