import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InviteMemberItem from "../../../components/InviteMemberItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function UserAdd(props) {
  const navigate = useNavigate();
  const location = useLocation();

  // console.log("== userAdd location ==");
  // console.log(location);

  // type
  // placing : 활동자 배치 - 회원 추가
  const type = location.state && location.state.type;
  const groupData = location.state && location.state.groupData;

  const activityIdx = location.state && location.state.activityIdx;
  const activityScheduleIdx =
    location.state && location.state.activityScheduleIdx;

  // console.log("== userAdd type, groupData ==");
  // console.log(type, groupData);

  const [orgMemberData, setOrgMemberData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [placingMemberData, setPlacingMemberData] = useState([]);

  // 더보기 페이징
  const [orgMemberPage, setOrgMemberPage] = useState(1);
  const offset = 8;
  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  const soleClickHandler = () => {
    navigate("/org/otherprofile/sole", {
      state: {},
    });
  };

  const [hideContent, setHideContent] = useState(true);

  // 기관 회원 전체 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        axios
          .get(
            `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${sessionStorage.idx}`
          )
          .then((res) => {
            const orgMemberList = res.data.data;
            const orgMemberArray = [];
            axios
              .all(
                orgMemberList.map((orgMember) => {
                  return axios.get(
                    `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
                  );
                })
              )
              .then((res) => {
                res.map((org, index) => {
                  orgMemberArray.push(
                    Object.assign(orgMemberList[index], org.data)
                  );
                });

                // console.log("== 기관 그룹 회원 전체 조회 ==");
                // console.log(orgMemberArray);

                setOrgMemberData(orgMemberArray);
              });
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const searchNickname = () => {
    if (searchKeyword !== "") {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/nickname/`;
      axios
        .get(url, {
          params: {
            nickname: searchKeyword,
            orgIdx: sessionStorage.idx,
          },
        })
        .then((res) => {
          const resData = res.data.data;
          //console.log(resData);
          setOrgMemberData(resData);
        });
    } else {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${sessionStorage.idx}`;
      axios.get(url).then((res) => {
        const orgMemberList = res.data.data;
        //console.log(resData);
        const orgMemberArray = [];

        axios
          .all(
            orgMemberList.map((orgMember) => {
              return axios.get(
                `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
              );
            })
          )
          .then((res) => {
            res.map((user, index) => {
              orgMemberArray.push(
                Object.assign(user.data, orgMemberList[index])
              );
            });

            //console.log(res);

            setOrgMemberData(orgMemberArray);
          });
      });
    }
  };

  const searchKeywordChangeHandler = (e) => {
    setSearchKeyword(e.target.value);
  };

  // 엔터키 검색 Keyup 이벤트 핸들러
  const enterKeyupHandler = () => {
    const enterKeyCode = 13;

    if (window.event.keyCode === enterKeyCode) {
      searchNickname();
    }
  };

  // 활동배치 회원 추가
  const addPlacingMember = (nickname) => {
    const orgMember = orgMemberData.filter(
      (orgMember) => orgMember.nickname === nickname
    );

    const checkGroup = groupData.filter(
      (group) => group.nickname === orgMember[0].nickname
    );

    if (checkGroup.length === 1) {
      alert("이미 그룹에 포함된 회원입니다.");
      return false;
    }

    setPlacingMemberData(placingMemberData.concat(orgMember));

    return true;
  };

  // 활동배치 회원 존재유무 체크
  const checkPlacingMember = (nickname) => {
    // 1: 이미 추가된 회원 , 0: 추가 안된 회원
    const checkExist = placingMemberData.filter(
      (orgMember) => orgMember.nickname === nickname
    ).length;

    return checkExist;
  };

  // 활동배치 회원 삭제
  const deletePlacingMember = (nickname) => {
    const orgMemeberList = placingMemberData.filter(
      (placingMember) => placingMember.nickname !== nickname
    );

    setPlacingMemberData(orgMemeberList);
  };

  // 취소버튼 클릭 이벤트 핸들러
  const cancelClickHandler = () => {
    // 뒤로가기
    navigate(-1);
  };

  // 확인버튼 클릭 이벤트 핸들러
  // 활동 참여
  const conformClickHandler = () => {
    // console.log(groupData);
    // console.log(placingMemberData);
    if (type === "placing") {
      const actListAry = [];

      groupData.map((group) => {
        const actList = {
          activityIdx: activityIdx,
          userIdx: group.user_idx,
          activityScheduleIdx: activityScheduleIdx,
          orgIdx: sessionStorage.idx,
          groupIdx: group.idx,
          volunteer: "W",
          time: "0",
          createdUid: sessionStorage.idx,
          updatedUid: sessionStorage.idx,
        };

        actListAry.push(actList);
      });

      placingMemberData.map((placingMember) => {
        const actList = {
          activityIdx: activityIdx,
          userIdx: placingMember.userIdx,
          activityScheduleIdx: activityScheduleIdx,
          orgIdx: sessionStorage.idx,
          groupIdx: "0",
          volunteer: "W",
          time: "0",
          createdUid: sessionStorage.idx,
          updatedUid: sessionStorage.idx,
        };

        actListAry.push(actList);
      });

      // console.log(actListAry);

      axios
        .all(
          actListAry.map((actList) => {
            return axios.post(
              `${process.env.REACT_APP_USER_SERVICE_API}/act_list`,
              actList
            );
          })
        )
        .then((res) => {
          alert("활동 참여 신청 완료");
          navigate(`/org/activity/detailpart/${activityIdx}`);
        })
        .catch((error) => {
          alert("이미 참여 신청한 활동입니다.");
          console.log(error.response);
        });
    }
  };

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="사용자 검색" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68">
          <div className="search__form mt-40">
            <form>
              <input hidden="hidden" />
              <input
                type="text"
                className="search__input"
                placeholder="검색..."
                onChange={searchKeywordChangeHandler}
                onKeyUp={enterKeyupHandler}
              />
              <input
                type="button"
                className="search__submit"
                value="Send"
                onClick={searchNickname}
                style={{ cursor: "pointer" }}
              />
            </form>
          </div>
          <div className="page__title-bar mt-48">
            <h2>회원 ({orgMemberData.length})</h2>
          </div>
          <div className="cards cards--14">
            {orgMemberData &&
              orgMemberData
                .map((orgMember) => (
                  <InviteMemberItem
                    invite="추가"
                    user={orgMember}
                    search={type === "placing" ? "placing" : "group"}
                    folder="org"
                    addFunc={addPlacingMember}
                    checkFunc={checkPlacingMember}
                    deleteFunc={deletePlacingMember}
                    groupData={groupData}
                  />
                ))
                .slice(0, orgMemberPage * offset)}
            <div className="buttons buttons--centered mb-20">
              {orgMemberData.length > orgMemberPage * offset ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => {
                    addPage(setOrgMemberPage, orgMemberPage);
                  }}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="form__row mt-40">
            <input
              onClick={cancelClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              onClick={conformClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--50"
              id="submit"
              value="확인"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default UserAdd;
