import React, { Fragment, useState, useEffect, useContext } from "react";
import UrlContext from "../context/Url.context";
import axios from "axios";
import { convertTimeStamp } from "../util/convert-date-function";
import { Link } from "react-router-dom";

const PartGroupList = ({ part, setSelActListIdx }) => {
  const urlContext = useContext(UrlContext);

  const defaultImgPath = "/assets/images/photos/img-default.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  const updateSelActListIdx = () => {
    setSelActListIdx({
      status: "group",
      idx: part.activityScheduleIdx,
      groupIdx: part.groupIdx,
      orgIdx: part.orgIdx,
    });
  };

  // 파일명 조회
  useEffect(() => {
    if (part) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: part && part.orgIdx,
            imageStatus: "O",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  const btnView = () => {
    switch (part && part.volunteer) {
      case "W":
        return (
          <Fragment>
            <div
              className="button button--small button--main float-right ml-10 open-popup"
              data-popup="conform_accept"
              onClick={updateSelActListIdx}
            >
              승인
            </div>
            <div
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_dismissal"
              onClick={updateSelActListIdx}
            >
              반려
            </div>
          </Fragment>
        );
      case "D":
        return (
          <div
            className="button button--small button--secondary float-right ml-10 open-popup"
            data-popup="conform_cancle"
            onClick={updateSelActListIdx}
          >
            승인 취소
          </div>
        );
      case "R":
        return (
          <div
            className="button button--small button--secondary float-right ml-10 open-popup"
            data-popup="conform_cancle"
            onClick={updateSelActListIdx}
          >
            반려 취소
          </div>
        );
      case "Y":
        return (
          <div className="button button--small button--secondary float-right ml-10">
            참여 완료
          </div>
        );
      default:
        return "";
    }
  };

  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <Fragment>
      <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
        <div
          className="user-details__thumb"
          //   onClick={soleClickHandler}
          style={{ cursor: "pointer" }}
        >
          <img src={imgPath} alt="" onError={changeDefaultImg} />
        </div>
        <div className="card__details">
          <Link to={`/org/otherprofile/org/${part && part.orgIdx}`}>
            <h4 className="card__username fc_point1">
              {part &&
                `${part.orgTitle} - ${part.groupName}(${part.groupMemberCnt})`}
            </h4>
          </Link>

          {/* <Link
          className=""
          to={`/org/activity/placingmember`}
        >
          그룹 회원 보기 ({groupInfo.totalMemberCnt})
        </Link> */}

          <p className="card__info">참여&#40;지정&#41;시간 &#58;
            {part && Object.keys(part).includes("createdAt")
              ? convertTimeStamp(part.createdAt)
              : null}
          </p>
          <p className="card__info">전화번호 &#58; &#32;
            {part && part.contact}</p>
          {btnView()}
        </div>
      </div>
    </Fragment>
  );
};

export default PartGroupList;
