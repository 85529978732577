import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";
import ConformSave from "../../common/popup/ConformSave";

function Dismissal(props) {
  const navigate = useNavigate();
  const { actIdx } = useParams();
  const [dismissalInfo, setDismissalInfo] = useState(null);

  const onChangeDismissalInfo = (e) => {
    setDismissalInfo({
      ...dismissalInfo,
      [e.target.name]: e.target.value,
    });
  };

  const activityDismissal = async () => {
    if (dismissalInfo.code === "default") {
      alert("반려 사유를 선택해주세요.");
      return;
    }

    if (dismissalInfo.reason.trim() === "") {
      alert("상세 사유를 입력해주세요.");
      return;
    }

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/dismissal/${actIdx}`;

    const data = new Object();
    data.dismissal = dismissalInfo.code;
    data.reason = dismissalInfo.reason;
    data.createdUid = window.sessionStorage.getItem("idx");

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        alert("반려 처리 되었습니다.");
        navigate(-1);
      }
    } catch (e) {
      console.log(e);
      alert("반려 처리 중, 오류가 발생하였습니다.");
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setDismissalInfo({
          code: "default",
          reason: "",
        });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="반려" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          {dismissalInfo && (
            <div className="fieldsets pt-40">
              <div className="form">
                <form id="ContactForm">
                  <h4>반려 사유</h4>
                  <div className="form__row mb-20">
                    <div className="form__select">
                      <select
                        name="code"
                        className="required"
                        defaultValue={dismissalInfo.code}
                        key={dismissalInfo.code}
                        onChange={(e) => onChangeDismissalInfo(e)}
                      >
                        <option value="default" disabled>
                          선택
                        </option>
                        <option value="S">정보 불충분</option>
                        <option value="F">허위 자료 제출</option>
                        <option value="E">기타</option>
                      </select>
                    </div>
                  </div>
                  <h4>상세</h4>
                  <div className="form__row">
                    <textarea
                      name="reason"
                      className="form__textarea required"
                      placeholder="입력"
                      value={dismissalInfo.reason}
                      onChange={(e) => onChangeDismissalInfo(e)}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
          <div className="form__row mt-40">
            <input
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_save"
              id="submit"
              value="확인"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
      <ConformSave conformClick={() => activityDismissal()} />
    </Fragment>
  );
}

export default Dismissal;
