import React from 'react';

const ConformCompletion = (props) => {
  const {conformClick} = props;

  return (
    <div className="popup popup--centered popup--shadow popup--conform_completion">
      <div className="popup__close"><a href="#" className="close-popup" data-popup="conform_completion"><img src="/assets/images/icons/blue/close.svg" alt="" title /></a></div>
      <div className="popup__icon"><img src="../../assets/images/icons/blue/alert.svg" alt="" title /></div>
      <h2 className="popup__title">확인 !</h2>
      <p className="popup__text">정말 완료하시겠습니까??</p>
      <div className="form__row mt-40">
        <input style={{cursor: 'pointer'}} type="submit" name="submit" data-popup="conform_cancle" className="form__submit button button--gray button--50 open-popup" id="submit" value="취소" />
        <input onClick={conformClick} style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--main button--50" id="submit" value="확인" />
      </div>
    </div>
  );
}

export default ConformCompletion;