import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

function ChatHeader({ title, popup, toggleChatOption }) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <header className="header header--page header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <a onClick={() => navigate(-1)}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icons/white/arrow-back.svg`}
                alt=""
              />
            </a>
          </div>
          <div className="header__title">{title}</div>
          <div className="header__icons-group header__icons-group--right">
            <div className="header__icon header__icon--more">
              <span
                data-popup={`${popup}`}
                className="open-popup"
                onClick={toggleChatOption}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/white/more.svg`}
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
}

export default ChatHeader;
