import React from "react";
import QnaListItem from "./QnaListItem";

const QnaList = ({ qnaList, pageNumber, count }) => {
  let no = (pageNumber - 1) * count + 1;

  return (
    <div className="table table--4cols mb-20">
      {/* 공개 시 클릭 가능, 비공개 시 클릭 불가 */}
      <div className="table__row">
        <div className="table__section table__section--nr table__section--header">
          No.
        </div>
        <div className="table__section table__section--grow table__section--header">
          제목
        </div>
        <div className="table__section table__section--header">작성자</div>
        <div className="table__section table__section--header">작성일</div>
        <div className="table__section table__section--header">공개여부</div>
      </div>
      {qnaList.map((qnaInfo) => (
        <QnaListItem qnaInfo={qnaInfo} no={no++} key={qnaInfo.idx} />
      ))}
    </div>
  );
};

export default QnaList;
