import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import OkOnlineList from "../../../components/OkOnlineList";
import OkPhoneList from "../../../components/OkPhoneList";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";
import ConformDelete from "../../common/popup/ConformDelete";

function OkHistory(props) {
  const navigate = useNavigate();

  const useIdx = sessionStorage.idx;

  const [phoneConsultingData, setPhoneConsultingData] = useState([]);
  const [onlineConsultingData, setOnlineConsultingData] = useState([]);

  // 내 전화 상담 이력 조회
  const getPhoneConsulting = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/myinfo/${useIdx}`;
    axios
      .get(URL)
      .then((res) => {
        const { totalRows, data } = res.data;
        setOnlineConsultingData(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 내 온라인 상담 이력 조회
  const getOnlineConsulting = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/phone_consultation/myinfo/${useIdx}`;
    axios
      .get(URL)
      .then((res) => {
        const { totalRows, data } = res.data;
        setPhoneConsultingData(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 삭제할 상담 IDX
  const [delUserIdx, setDelUserIdx] = useState("");
  const [delId, setDelId] = useState(0);

  // 상담 삭제
  const deleteClick = () => {
    if (delUserIdx === "") {
      return;
    }

    if (delId === 0) {
      return;
    }

    let url = `${process.env.REACT_APP_USER_SERVICE_API}/phone_consultation/${delUserIdx}/${delId}`;
    axios
      .delete(url)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getPhoneConsulting();
        getOnlineConsulting();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="서초OK생활 자문단 상담이력" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center">
              <h2 className="page__title">
                전화 상담({phoneConsultingData.length})
              </h2>
            </div>
            <div className="cards cards--11">
              {phoneConsultingData &&
                phoneConsultingData.map((phoneConsulting) => (
                  <OkPhoneList
                    item={phoneConsulting}
                    setDelUserIdx={setDelUserIdx}
                    setDelId={setDelId}
                  />
                ))}
            </div>
            <div className="d-flex justify-space align-items-center">
              <h2 className="page__title">
                온라인 상담({onlineConsultingData.length})
              </h2>
            </div>
            <div className="cards cards--11">
              {onlineConsultingData &&
                onlineConsultingData.map((onlineConsulting) => (
                  <OkOnlineList item={onlineConsulting} ok="online" />
                ))}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformDelete conformClick={deleteClick} />
    </Fragment>
  );
}

export default OkHistory;
