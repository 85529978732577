import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActHistoryList from "../../../components/ActHistoryList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SoleProfile from "../../../components/SoleProfile";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function Sole(props) {
  const { userIdx } = useParams();

  // DB 데이터
  const [user, setUser] = useState();
  const [actHistoryData, setActHistoryData] = useState([]);

  // 더보기 페이징
  const [actHistoryPage, setActHistoryPage] = useState(1);

  const offset = 3;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 회원 상세 조회
  const getUserData = () => {
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${userIdx}`;
    axios
      .get(url)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 회원 활동이력 조회
  const getActHistoryData = () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/activity_history/${userIdx}`;
    axios
      .get(url)
      .then((res) => {
        const resActivityData = res.data.data;
        setActHistoryData(resActivityData);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  // 회원 상세 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getUserData();
        getActHistoryData();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content">
          <SubHeader title="" />
          <SoleProfile user={user} type="otherprofile" folder="sole" />
          <div className="page_section">
            <div className="page__title-bar">
              <h2>활동이력({actHistoryData.length})</h2>
            </div>
            <div className="cards cards--11">
              {actHistoryData &&
                actHistoryData
                  .map((actHistory, idx) => (
                    <ActHistoryList
                      key={idx}
                      folder="sole"
                      actHistory={actHistory}
                      type="otherprofile"
                    />
                  ))
                  .slice(0, actHistoryPage * offset)}
              <div className="buttons buttons--centered mb-20">
                {actHistoryData.length > actHistoryPage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setActHistoryPage, actHistoryPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default Sole;
