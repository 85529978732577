import React from "react";
import UserGuideListItem from "./UserGuideListItem";

const UserGuideList = ({ userGuideList }) => {
  return (
    <div className="cards cards--11">
      {userGuideList.map((userGuideInfo) => (
        <UserGuideListItem
          userGuideInfo={userGuideInfo}
          key={userGuideInfo.id}
        />
      ))}
    </div>
  );
};

export default UserGuideList;
