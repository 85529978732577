import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import UrlContext from "../context/Url.context";

function ScheduleInviteMemberItem(props) {
  const { member, invite, actIdx, actSchduelIdx } = props;
  const urlContext = useContext(UrlContext);
  const navigate = useNavigate();

  const [inviteText, setInviteText] = useState(invite);

  // 일정에 활동자 지정
  const addScheduleActList = () => {
    if (
      window.confirm(
        `${member && member.nickname
        } 회원을 해당 일정의 활동자로 지정하시겠습니까?`
      )
    ) {
      const actList = {
        activityIdx: actIdx,
        userIdx: member.idx,
        activityScheduleIdx: actSchduelIdx,
        orgIdx: sessionStorage.idx,
        groupIdx: "0",
        volunteer: "D",
        time: "0",
        createdUid: sessionStorage.idx,
        updatedUid: sessionStorage.idx,
      };

      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list`;
      axios
        .post(URL, actList)
        .then((res) => {
          //console.log(res.data);
          navigate(0);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 401) {
            alert("이미 해당 일정에 참여중인 회원입니다.");
          }
        });
    }
  };

  // 일정 활동자 초대
  const inviteScheduleActList = () => {
    if (
      window.confirm(`${member && member.nickname} 회원을 초대 하시겠습니까?`)
    ) {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_request`;

      const data = {
        activityScheduleIdx: actSchduelIdx,
        responseIdx: member && member.idx,
        volunteer: "W",
        createdUid: sessionStorage.idx,
      };

      axios
        .post(URL, data)
        .then((res) => {
          // console.log(res.data);
          navigate(0);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  // 활동 알람 보내기
  const sendAlarm = () => {
    if (actIdx === undefined) return;
    if (
      window.confirm(
        `${member && member.nickname} 회원에게 알람을 보내시겠습니까?`
      )
    ) {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/alarm/invite/${actIdx}`;
      const params = {
        sendIdx: sessionStorage.idx,
        receiveIdx: member.idx,
      };

      console.log(params);
      axios
        .post(URL, null, {
          params: params,
        })
        .then((res) => {
          const resJson = res.data;
          alert(`${member && member.nickname} 회원에게 알람을 보냈습니다.`);
          // console.log(resJson);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  // 추가버튼 클릭 핸들러
  const addMemberClickHandler = () => {
    addScheduleActList();
  };

  // 삭제버튼 클릭 핸들러
  const deleteMemberClickHandler = () => { };

  // 알림보내기 버튼 클릭 핸들러
  const sendAlarmClickHandler = () => {
    sendAlarm();
  };

  const defaultImgPath = "/assets/images/photos/img-p-sole.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (member) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: member && member.idx,
            imageStatus: "U",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  const inviteItem = () => {
    switch (inviteText) {
      case "승인 대기 중":
      case "W":
        return (
          <div className="button button--small button--secondary button--full">
            승인 대기 중
          </div>
        );
      case "추가":
        return (
          <div
            className="button button--small button--main button--full"
            onClick={addMemberClickHandler}
          >
            추가
          </div>
        );
      case "초대":
      case "0":
        return (
          <div
            className="button button--small button--main button--full"
            onClick={inviteScheduleActList}
          >
            초대
          </div>
        );
      case "추가 됨":
        return (
          <div className="button button--small button--secondary button--full">
            추가 됨
          </div>
        );
      case "알림 보내기":
        return (
          <div
            className="button button--small button--main button--full"
            onClick={sendAlarm}
          >
            알림 보내기
          </div>
        );
      default:
        break;
    }
  };

  const chageDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <div className="card card--style-thumb">
      <div
        className="card__thumb card__thumb--round-corners-full"
        style={{ cursor: "pointer" }}
      >
        <Link to={`/org/otherprofile/sole/${member.idx}`}>
          <img src={imgPath} alt="" onError={chageDefaultImg} />
        </Link>
      </div>
        <Link to={`/org/otherprofile/sole/${member.idx}`}>
          <h4 className="card__username text-aline-center">{member && member.nickname}</h4>
        </Link>
      {inviteItem()}
    </div>
  );
}

export default ScheduleInviteMemberItem;
