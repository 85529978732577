import React, { useEffect, useState, Fragment, useCallback } from "react";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ActivityList from "../../../components/ActivityList";
import { useNavigate, useParams, useLocation } from "react-router";
import ActivityMemberList from "../../../components/ActivityMemberList";
import ActivityGroupList from "../../../components/ActivityGroupList";
import axios from "axios";
import printJS from "print-js";
import { convertDashToDot } from "../../../util/convert-date-function";
import CheckLoginInfo from "../../../services/login-validation";
import GroupList from "../../../components/GroupList";

function ListActivityFinished(props) {
  const location = useLocation();
  const actIdx = location.state && location.state.actIdx;
  const { actSchduelIdx } = useParams();
  const navigate = useNavigate();

  // DB 데이터
  const [actScheduleData, setActScheduleData] = useState();

  const [totalPart, setTotalPart] = useState(null);
  const [partUserList, setPartUserList] = useState(null);

  const [totalNonePart, setTotalNonePart] = useState(null);
  const [nonePartUserList, setNonePartUserList] = useState(null);

  const getActivityData = useCallback(() => {
    // 활동, 활동상세, 기관정보 조회
    let URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;
    axios.get(URL).then((res) => {
      setActivityData(res.data);
    });
  }, [actIdx]);

  const getAbsenteeList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/history/detail/none-part/${actIdx}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const { totalRows, nonePartUserList, nonePartGroupList } =
          response.data;

        let ary = [];

        for (let i = 0; i < nonePartUserList.length; i++) {
          if (nonePartUserList[i].activityScheduleIdx === actSchduelIdx)
            ary.push({
              userIdx: nonePartUserList[i].userIdx,
              img: Object.keys(nonePartUserList[i]).includes("img")
                ? nonePartUserList[i].img
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
              nickname: nonePartUserList[i].nickname,
              scheduleDate: convertDashToDot(nonePartUserList[i].activityDate),
              scheduleTime: nonePartUserList[i].activityTime,
              phoneNum: nonePartUserList[i].contact,
              scheduleIdx: nonePartUserList[i].activityScheduleIdx,
              type: "U",
            });
        }

        for (let i = 0; i < nonePartGroupList.length; i++) {
          if (nonePartGroupList[i].activityScheduleIdx === actSchduelIdx)
            ary.push({
              orgIdx: nonePartGroupList[i].orgIdx,
              groupIdx: nonePartGroupList[i].idx,
              img: Object.keys(nonePartGroupList[i]).includes("img")
                ? nonePartGroupList[i].img
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
              groupName: nonePartGroupList[i].groupName,
              totalMemberCnt: nonePartGroupList[i].groupMemberCnt,
              scheduleDate: convertDashToDot(nonePartGroupList[i].activityDate),
              scheduleTime: nonePartGroupList[i].activityTime,
              contact: nonePartGroupList[i].contact,
              scheduleIdx: nonePartGroupList[i].activityScheduleIdx,
              type: "O",
            });
        }

        setTotalNonePart(ary.length);
        setNonePartUserList(ary);
      }
    } catch (e) {
      console.log(e);
      setNonePartUserList([]);
      setTotalNonePart(0);
      alert("결석/미참여 활동자 목록 조회에 실패하였습니다.");
    }
  }, [actIdx, actSchduelIdx]);

  const getPartList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-list/finished/${actSchduelIdx}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const {
          partUserList,
          nonePartUserList,
          partGroupList,
          nonePartGroupList,
        } = response.data;

        let partList = [];
        let nonPartList = [];

        for (let j = 0; j < partUserList.length; j++) {
          partList.push({
            actListIdx: partUserList[j].id,
            userIdx: partUserList[j].userIdx,
            img: Object.keys(partUserList[j]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partUserList[j].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            nickname: partUserList[j].nickname,
            scheduleDate: convertDashToDot(partUserList[j].activityDate),
            scheduleTime: partUserList[j].activityTime,
            phoneNum: partUserList[j].contact,
            scheduleIdx: partUserList[j].activityScheduleIdx,
            type: "U",
            reportStatus: partUserList[j].activityReportStatus,
            volunteer: partUserList[j].volunteer,
          });
        }

        for (let j = 0; j < partGroupList.length; j++) {
          partList.push({
            actListIdx: partGroupList[j].adminActListIdx,
            orgIdx: partGroupList[j].orgIdx,
            groupIdx: partGroupList[j].groupIdx,
            img: Object.keys(partGroupList[j]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partGroupList[j].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            groupName: `${partGroupList[j].groupName} - ${convertDashToDot(
              partGroupList[j].activityDate
            )} 활동 참여자`,
            totalMemberCnt: partGroupList[j].groupMemberCnt,
            scheduleDate: convertDashToDot(partGroupList[j].activityDate),
            scheduleTime: partGroupList[j].activityTime,
            contact: partGroupList[j].contact,
            scheduleIdx: partGroupList[j].activityScheduleIdx,
            type: "O",
            reportStatus: partGroupList[j].activityReportStatus,
            volunteer: partGroupList[j].volunteer,
          });
        }

        for (let i = 0; i < nonePartUserList.length; i++) {
          nonPartList.push({
            userIdx: nonePartUserList[i].userIdx,
            img: Object.keys(nonePartUserList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${nonePartUserList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            nickname: nonePartUserList[i].nickname,
            scheduleDate: convertDashToDot(nonePartUserList[i].activityDate),
            scheduleTime: nonePartUserList[i].activityTime,
            phoneNum: nonePartUserList[i].contact,
            scheduleIdx: nonePartUserList[i].activityScheduleIdx,
            type: "U",
            status: nonePartUserList[i].volunteer,
            volunteer: nonePartUserList[i].volunteer,
          });
        }

        for (let i = 0; i < nonePartGroupList.length; i++) {
          nonPartList.push({
            orgIdx: nonePartGroupList[i].orgIdx,
            groupIdx: nonePartGroupList[i].groupIdx,
            img: Object.keys(nonePartGroupList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${nonePartGroupList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            groupName: `${nonePartGroupList[i].groupName} - ${convertDashToDot(
              nonePartGroupList[i].activityDate
            )} 활동 참여자`,
            totalMemberCnt: nonePartGroupList[i].groupMemberCnt,
            scheduleDate: convertDashToDot(nonePartGroupList[i].activityDate),
            scheduleTime: nonePartGroupList[i].activityTime,
            contact: nonePartGroupList[i].contact,
            scheduleIdx: nonePartGroupList[i].activityScheduleIdx,
            type: "O",
            status: nonePartGroupList[i].volunteer,
            volunteer: nonePartGroupList[i].volunteer,
          });
        }

        setPartUserList(partList);
        setTotalPart(partList.length);

        setNonePartUserList(nonPartList);
        setTotalNonePart(nonPartList.length);
      }
    } catch (e) {
      console.log(e);
      alert("사용자 목록 조회 중, 오류가 발생하였습니다.");
    }
  }, [actSchduelIdx]);

  const [hideContent, setHideContent] = useState(true);

  const [groupData, setGroupData] = useState([]);

  // 그룹 조회
  const retrieveGroupData = useCallback(() => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/group_list/total/${sessionStorage.idx}`;
    axios.get(url).then((res) => {
      setGroupData(res.data.data);
    });
  }, []);

  // 활동 일정 상세정보
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/org/profile/list_activity/${actSchduelIdx}`;
        axios
          .get(URL)
          .then((res) => {
            setActScheduleData(res.data);
            getPartList();
            retrieveGroupData();
            setHideContent(false);
          })
          .catch((err) => {
            console.log(err.response);
          });

        getActivityData();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [actSchduelIdx, getPartList, getActivityData]);

  const [activityData, setActivityData] = useState();

  const convertDate = (inputDate) => {
    //console.log(inputDate);
    const date = new Date(inputDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;

    return `${year}년 ${month}월 ${day}일`;
  };

  // QR 인쇄하기
  const printQR = () => {
    console.log(activityData);
    const header = `<h1>${activityData && activityData.title}</h1>
                  <h1>${actScheduleData &&
      convertDate(actScheduleData.activityDate) +
      " " +
      actScheduleData.activityTime
      }</h1>`;
    printJS({
      printable: "qr",
      type: "html",
      style: `.qrImg {width: 100%;}`,
      header: header,
      scanStyles: false,
    });
  };

  const changeVolunteerStatus = async (status, partUserType, data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/volunteer-status/${partUserType}/${status}/${actIdx}`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        getPartList();
      }
    } catch (e) {
      alert(
        `${partUserType === "user" ? "일반" : "그룹"
        } 참여자의 참여 상태 변경 중, 오류가 발생하였습니다.`
      );

      console.log(e);
    }
  };

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="일정 상세" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="cards cards--6 pt-40" id="loadlist-01">
              {actScheduleData && (
                <ActivityList folder="org" schedule={actScheduleData} />
              )}
            </div>
            {activityData && activityData.joinType === "Q" && (
              <div className="table card card--style-thumb mb-20">
                <div className="card__details">
                  <h2 className="">QR 코드</h2>
                  <div
                    id="qr"
                    // className="card__thumb card__thumb--round-corners"
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ padding: "50px" }}>
                      <img
                        className="qrImg"
                        style={{ cursor: "pointer", maxWidth: "100%" }}
                        onClick={() =>
                          navigate(`/org/profile/actdetail/${actIdx}`)
                        }
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=activityScheduleIdx=${actSchduelIdx}`}
                        alt="qrCode"
                      />
                    </div>
                  </div>
                  <input
                    type="submit"
                    name="submit"
                    className="form__submit button button--gray button--full open-popup"
                    data-popup="conform_cancle"
                    id="submit"
                    onClick={printQR}
                    value="인쇄하기"
                  />
                </div>
              </div>
            )}
            <div className="page__title-bar mt-48">
              <h2>활동자 목록 ({totalPart})</h2>
            </div>
            <div className="cards cards--11">
              {partUserList &&
                partUserList.map((part) =>
                  part.type === "U" ? (
                    <ActivityMemberList
                      actIdx={actIdx} // 수정요청
                      part={part}
                      btnType={
                        part.reportStatus === "Y" ? "history" : "activity"
                      }
                      actSchduelIdx={actSchduelIdx}
                      changeVolunteerStatus={changeVolunteerStatus}
                    />
                  ) : (
                    <ActivityGroupList
                      actIdx={actIdx}
                      groupInfo={part}
                      btnType="history"
                      changeVolunteerStatus={changeVolunteerStatus}
                    />
                  )
                )}
            </div>
            <div className="d-flex justify-space align-items-center">
              <input
                type="button"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="활동자 지정"
                onClick={() =>
                  navigate(
                    `/org/activity/placingmember/individually/${actIdx}/${actSchduelIdx}`,
                    {
                      state: { isFinished: true },
                    }
                  )
                }
              />
            </div>
            <br />

            <div className="d-flex justify-space align-items-center">
              <h2>회원 그룹({groupData.length})</h2>
            </div>
            <div className="sections">
              <div className="form__row">
                {groupData &&
                  groupData.map((group, idx) => (
                    <GroupList
                      key={idx}
                      addition={true}
                      group={group}
                      actIdx={actIdx}
                      actSchduelIdx={actSchduelIdx}
                      isFinished={true}
                    />
                  ))}
              </div>
            </div>
            <br />
            <div className="d-flex justify-space align-items-center">
              <h2>결석/미참여 활동자 목록 ({totalNonePart})</h2>
            </div>
            <div className="cards cards--11 card-100">
              {nonePartUserList &&
                nonePartUserList.map((part) =>
                  part.type === "U" ? (
                    <ActivityMemberList
                      btnType={
                        part.status === "N"
                          ? "nonParticipation"
                          : "participation"
                      }
                      actIdx={actIdx} // 수정요청
                      part={part}
                      changeVolunteerStatus={changeVolunteerStatus}
                    />
                  ) : (
                    <ActivityGroupList
                      btnType={
                        part.status === "N"
                          ? "nonParticipation"
                          : "participation"
                      }
                      actIdx={actIdx}
                      groupInfo={part}
                      changeVolunteerStatus={changeVolunteerStatus}
                    />
                  )
                )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default ListActivityFinished;
