import React, { Fragment } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ConformPopup from "../popup/ConformPopup";

function Withdrawal() {
  const navigate = useNavigate();

  const requestWithdraw = async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/user/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.delete(url);

      if (response.status === 200) {
        // session 삭제
        window.sessionStorage.clear();

        alert("탈퇴되었습니다.");

        // 로그인 페이지로 이동
        navigate("/");
      }
    } catch (e) {
      console.log(e);
      alert("회원 탈퇴 처리중, 오류가 발생하였습니다.");
    }
  };

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="회원탈퇴" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar">
              <h2>활동탈퇴 안내</h2>
            </div>
            <div className="d-flex justify-space align-items-center">
              <span className="lh-2">
                회원탈퇴 시 회원정보 및 서비스 이용기록은 모두 삭제됩니다. 또한
                회원님 아이디로 이용하셨던 서비스의 이용이 모두 불가능하게
                됩니다.
              </span>
            </div>
            <div className="page__title-bar">
              <h2>회원탈퇴 신청</h2>
            </div>
            {/* <div className="ustify-space align-items-center">
                            <li className="lh-2">이메일 : admin@ksbpartners.co.kr</li>
                            <li className="lh-2">필수 기재사항 : 서비스명(서초사회공헌플랫폼)회원아이디/성명/연락처</li>
                            <span className="lh-2">*메일 송부 후 탈퇴처리 기간은 최대 7일정도 소요가 되며 지연될 경우 등록센터(복지관, 자치회관) 관리자에게 문의하시기 바랍니다.
                            </span>

                        </div> */}
            <input
              type="button"
              className="button button--main button--full open-popup"
              data-popup="conform_withdraw"
              value="탈퇴하기"
            />
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformPopup
        text="정말 탈퇴하시겠습니까?"
        popupType="withdraw"
        conformClick={requestWithdraw}
      />
    </Fragment>
  );
}

export default Withdrawal;
