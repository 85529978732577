import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import UrlContext from "../context/Url.context";

function InviteOrgItem(props) {
  const { invite, org, actIdx, actSchduelIdx } = props;
  const navigate = useNavigate();
  const urlContext = useContext(UrlContext);

  // console.log("== 기관 ==");
  // console.log(org);

  const [inviteText, setInviteText] = useState(invite);

  const orgClickHandler = () => {
    navigate("/org/otherprofile/org", {
      state: {},
    });
  };

  // 알람 보내기 클릭 핸들러
  const sendAlarmClickHandler = () => {
    if (actIdx === undefined) return;

    if (
      window.confirm(`${org && org.orgTitle} 기관에게 알람을 보내시겠습니까?`)
    ) {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/alarm/invite/${actIdx}`;
      const params = {
        sendIdx: sessionStorage.idx,
        receiveIdx: org.idx,
      };
      axios
        .post(URL, null, {
          params: params,
        })
        .then((res) => {
          alert(`${org && org.orgTitle} 기관에게 알람을 보냈습니다.`);
          const resJson = res.data;
          // console.log(resJson);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  // 일정 활동자 초대
  const inviteScheduleActList = () => {
    if (
      window.confirm(`${org && org.orgTitle} 기관을 활동초대 하시겠습니까?`)
    ) {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_request`;

      const data = {
        activityScheduleIdx: actSchduelIdx,
        responseIdx: org && org.idx,
        volunteer: "W",
        createdUid: sessionStorage.idx,
      };

      axios
        .post(URL, data)
        .then((res) => {
          // console.log(res.data);
          navigate(0);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const defaultImgPath = "/assets/images/photos/img-p-org.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (org) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: org && org.idx,
            imageStatus: "O",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  const chageDefualtImg = () => {
    setImgPath(defaultImgPath);
  };

  const inviteItem = () => {
    switch (inviteText) {
      case "승인 대기 중":
      case "W":
        return (
          <div className="button button--small button--secondary button--full">
            승인 대기 중
          </div>
        );
      case "추가":
        return (
          <div className="button button--small button--main button--full">
            추가
          </div>
        );
      case "활동 초대":
      case "0":
        return (
          <div
            className="button button--small button--main button--full"
            onClick={inviteScheduleActList}
          >
            활동 초대
          </div>
        );
      case "추가 됨":
        return (
          <div className="button button--small button--secondary button--full">
            추가 됨
          </div>
        );
      case "알림 보내기":
        return (
          <div
            className="button button--small button--main button--full"
            onClick={sendAlarmClickHandler}
          >
            알람 보내기
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="card card--style-thumb">
      <div
        onClick={orgClickHandler}
        style={{ cursor: "pointer" }}
        className="card__thumb card__thumb--round-corners-full"
      >
        <img src={imgPath} alt="" onError={chageDefualtImg} />
      </div>
      <Link to="/org/otherprofile/org">
        <h4 className="card__username text-aline-center">
          {org && org.orgTitle}
        </h4>
      </Link>
      {/* <div className="user-profile__info">최대12인</div> */}
      {inviteItem()}
    </div>
  );
}

export default InviteOrgItem;
