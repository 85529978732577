import { Fragment, useEffect } from "react";
import ConformSave from '../pages/common/popup/ConformSave';

function Navigationbar(){
	useEffect(() => {
		const script = document.createElement('script');
    script.src = "/vendor/jquery/jquery-3.5.1.min.js";
    script.async = true;
    const script2 = document.createElement('script');
    script2.src = "/vendor/jquery/jquery.validate.min.js";
    script2.async = true;
    const script3 = document.createElement('script');
    script3.src = "/vendor/swiper/swiper.min.js";
    script3.async = true;
    const script4 = document.createElement('script');
    script4.src = "/assets/js/swiper-init.js";
    script4.async = true;
    const script5 = document.createElement('script');
    script5.src = "/assets/js/jquery.custom.js";
    script5.async = true;
    document.body.appendChild(script);
    document.body.appendChild(script2);
    document.body.appendChild(script3);
    document.body.appendChild(script4);
    document.body.appendChild(script5);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
      document.body.removeChild(script4);
      document.body.removeChild(script5);
    }
	}, [])

	return(
		<Fragment>
			<ConformSave/>
			{/* <div id="popup-success" /> */}
			{/* <div id="popup-social" />
			<div id="popup-alert" />
			<div id="popup-cancle-and-delete" />
			<div id="popup--conform_cancle" />
			<div id="popup--conform_participation" />
			<div id="popup--conform_delete" />
			<div id="popup--conform_save" />
			<div id="popup--conform_secession" />
			<div id="popup--conform_close" />
			<div id="popup--conform_dismissal" />
			<div id="popup--conform_completion" />
			<div id="popup--conform_absent"/>
			<div id="popup--conform_export_member"/>
			<div id="popup--recruitment" />
			<div id="popup-notifications" />
			<div id="popup--declaration" />
			<div id="popup--chatall" />
			<div id="popup--chatalls" />
			<div id="popup--chatg" />
			<div id="popup--chatds" />
			<div id="popup--chatgs" />
			<div id="popup--activity_edit" />
			<div id="popup--act_request_edit" />
			<div id="popup--conform_accept" />
			<div id="popup--conform_part" />
			<div id="popup--conform_appgroup" /> */}

			{/* 없음..? */}
			{/* <div id="popup--conform_application-group" /> */}
			
		</Fragment>
	);
}

export default Navigationbar;