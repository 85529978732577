import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal } from "../../components/DaumPostModal";
import { setCookie } from "../../util/cookies";

import DatePicker from "react-mobile-datepicker";
import {
  convertDateFormatDash,
  calcOriginAge,
  convertDashFormat,
} from "../../util/convert-date-function";

function Signup(props) {
  const navigate = useNavigate();

  // 다음 주소찾기
  const [modalOpen, setModalOpen] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [address1, setAddress1] = useState("");

  const [birthDay, setBirthDay] = useState(new Date());
  const [toggleDatePicker, setToggleDatePicker] = useState(false);

  const onToggleDatePicker = () => {
    setToggleDatePicker(!toggleDatePicker);
  };

  const onSelectBirthDay = (selectedDate) => {
    setBirthDay(selectedDate);
    onToggleDatePicker();
    setValues({
      ...values,
      birthdate: convertDateFormatDash(selectedDate),
    });
  };

  const [values, setValues] = useState({
    nickname: "",
    name: "",
    password: "",
    passwordcheck: "",
    tel: "",
    email: "",
    birthdate: "",
    address: "",
    addressdetail: "",
    gender: "N",
    di: "",
  });

  // 닉네임 중복확인

  const [nicknamecheck, setNicknamecheck] = useState("null");
  const checkNickname = (e, tabId) => {
    e.preventDefault();

    let idPattern = /^[a-zA-Z]+[a-zA-Z0-9]{5,19}$/g; // 영문 대소문자로 시작,숫자도 포함 가능,5 ~ 19자리 사이

    if (tabId === 0) {
      if (idPattern.test(values.nickname) === false) {
        alert(
          "ID가 형식에 맞지 않습니다. ID는 아이디는 영문자로 시작하는 6~20자 영문자 또는 숫자이어야 합니다. 한글, 스페이스 및 일부 특수문자는 사용할 수 없습니다."
        );
        return false;
      }
    } else {
      let idPattern = /^[a-zA-Z]+[a-zA-Z0-9]{5,19}$/g; // 영문 대소문자로 시작,숫자도 포함 가능,5 ~ 19자리 사이
      if (idPattern.test(orgSignUpInfo.nickName) === false) {
        alert(
          "ID가 형식에 맞지 않습니다. ID는 아이디는 영문자로 시작하는 6~20자 영문자 또는 숫자이어야 합니다. 한글, 스페이스 및 일부 특수문자는 사용할 수 없습니다."
        );
        return false;
      }
    }

    let nickname = {
      nickname: tabId === 0 ? values.nickname : orgSignUpInfo.nickName,
    };
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/duplicatedNickname`;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, nickname, config)
      .then((res) => {
        if (res.status === 200) {
          if (tabId === 0) setNicknamecheck(200);
          else
            setValidationStatus({
              ...validationStatus,
              isNicknameOverlap: 1,
            });
        }
      })
      .catch((err) => {
        if (tabId === 0) setNicknamecheck(" ");
        else
          setValidationStatus({
            ...validationStatus,
            isNicknameOverlap: 2,
          });
      });
  };

  // 이메일 중복확인
  const [emailcheck, setEmailcheck] = useState("null");
  const checkEmail = (e, tabId) => {
    e.preventDefault();
    let email = {
      email: tabId === 0 ? values.email : orgSignUpInfo.email,
    };
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/duplicatedEmail`;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, email, config)
      .then((res) => {
        if (res.status === 200) {
          tabId === 0
            ? setEmailcheck(200)
            : setValidationStatus({
                ...validationStatus,
                isEmailOverlap: 1,
              });
        }
      })
      .catch((err) => {
        tabId === 0
          ? setEmailcheck(" ")
          : setValidationStatus({
              ...validationStatus,
              isEmailOverlap: 2,
            });
      });
  };

  // 비밀번호 일치 여부 확인
  const [isPwdMatched, setIsPwdMatched] = useState(true);

  const handleChangeForm = (e) => {
    if (e.target.name === "passwordcheck") {
      setIsPwdMatched(values.password === e.target.value);
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // 다음주소찾기 모달 열기, 닫기
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const daumPostModalOpenHandler = () => {
    openModal(true);
  };

  const nameInput = useRef();
  const parentNameInput = useRef();

  const [isNameVerificate, setIsNameVerificate] = useState(null);

  const checkOverlapJoin = useCallback(async (data) => {
    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/user/check-di`;

    try {
      const response = await axios.post(url, data);

      if (response.status === 200) {
        console.log(response.data);
        return response.data;
      }
    } catch (e) {
      if (e.response.status === 404 && e.response.data === "NOT EXISTS") {
        return e.response.data;
      } else {
        console.log(e);
        alert("중복 가입여부 조회 중, 오류가 발생하였습니다.");
        return "ERROR";
      }
    }
  }, []);

  // const onClickVerificationName_none = async (e, target) => {
  //   e.preventDefault();
  //
  //   if (target === "user" && values.name.trim() === "") {
  //     alert("이름을 입력하신 뒤, 실명 인증을 진행해주세요.");
  //     return;
  //   }
  //
  //   if (target === "parent" && parentInfo.name.trim() === "") {
  //     alert("보호자 성함을 입력하신 뒤, 실명 인증을 진행해주세요.");
  //     return;
  //   }
  //
  //   setVerificateTarget(target);
  //
  //   window.open(
  //     "http://localhost:3030/mobileAuth/request/request",
  //     "verificationWindow",
  //     "width=455px , height=100%"
  //   );
  // };

  const onClickVerificationName = async (e, target) => {
    e.preventDefault();

    if (target === "user" && values.name.trim() === "") {
      alert("이름을 입력하신 뒤, 실명 인증을 진행해주세요.");
      return;
    }

    if (target === "parent" && parentInfo.name.trim() === "") {
      alert("보호자 성함을 입력하신 뒤, 실명 인증을 진행해주세요.");
      return;
    }

    setVerificateTarget(target);

    window.open(
      "https://volunteer.seocho.go.kr:8443/mobileAuth/request/request.jsp",
      "verificationWindow",
      "width=455px , height=100%"
    );
  };

  const ckeckVerification = () => {
    let emailpattern =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    let pwdpattern =
      /^.*(?=.{8,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    let telpattern = /^[0-9\b -]{0,13}$/;

    if (nicknamecheck !== 200) {
      alert("ID 중복확인을 해주세요.");
      return false;
    }

    let idPattern = /^[a-zA-Z]+[a-zA-Z0-9]{5,19}$/g; // 영문 대소문자로 시작,숫자도 포함 가능,5 ~ 19자리 사이
    if (idPattern.test(values.nickname) === false) {
      alert(
        "ID가 형식에 맞지 않습니다. ID는 아이디는 영문자로 시작하는 6~20자 영문자 또는 숫자이어야 합니다. 한글, 스페이스 및 일부 특수문자는 사용할 수 없습니다."
      );
      return false;
    }

    if (emailpattern.test(values.email) === false) {
      alert("이메일 형식에 맞게 작성해 주세요.");
      return false;
    }
    if (emailcheck !== 200) {
      alert("이메일 중복확인을 해주세요.");
      return false;
    }

    if (values.password !== values.passwordcheck) {
      alert("비밀번호가 일치하지않습니다.");

      setIsPwdMatched(false);

      return false;
    }

    if (pwdpattern.test(values.password) === false) {
      alert("숫자,문자,특수문자를 조합해서 최소 8자 이상 입력해 주세요.");
      return false;
    }
    if (telpattern.test(values.tel) === false) {
      alert("숫자만 입력해 주세요.( ex : 01098765432 )");
      return false;
    }

    if (isNameVerificate === false || values.di.trim() === "") {
      alert("실명인증을 완료해주세요.");
      return false;
    }

    if (address1.trim() === "") {
      alert("주소를 입력해주세요.");
      return false;
    }

    if (values.addressdetail.trim() === "") {
      alert("상세 주소를 입력해주세요.");
      return false;
    }

    // 만 14세 이상
    if (isUnder14 === true) {
      if (parentInfo.name.trim() === "") {
        alert("보호자 이름을 입력 후, 실명인증을 진행해주세요.");
        return false;
      }

      if (parentInfo.isAdult === null || parentInfo.isVerificated === null) {
        alert("보호자 실명 인증을 완료해주세요");
        return false;
      }

      if (parentInfo.isAdult === false) {
        alert("보호자는 만 19세 이상의 성인이어야 합니다.");
        return false;
      }

      if (parentInfo.isVerificated === false) {
        alert("보호자 실명 인증을 완료해주세요");
        return false;
      }
    }

    return true;
  };

  const signup = (e) => {
    e.preventDefault();

    if (!ckeckVerification()) {
      return;
    }

    // axios URL
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users`;

    let user = {
      nickname: values.nickname,
      name: values.name,
      password: values.password,
      phoneNum: values.tel,
      email: values.email,
      birth: values.birthdate,
      address1: address1,
      address2: values.addressdetail,
      gender: values.gender,
      openstatus: "O",
      volunteerTime: "00:00",
      di: values.di,
      parentName: isUnder14 === true ? parentInfo.name : null,
      orgStatus : "U",
    };

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, user, config)
      .then((res) => {
        setValues(res.data);
        alert("회원가입 완료");
        document.location.href = "/common/login";
      })
      .catch((err) => {
        alert("회원가입 실패");
      });
  };

  const [verificateTarget, setVerificateTarget] = useState("user");
  const [isUnder14, setIsUnder14] = useState(false);
  const [parentInfo, setParentInfo] = useState({
    name: "",
    tel: "",
    isVerificated: null,
    isAdult: null,
  });

  const successToVerification = useCallback(
    async (e) => {
      if (e.data.message === "OK") {
        let data = new Object();
        data.di = e.data.di;
        data.phone = e.data.cellNo;

        let birth = Object.keys(e.data).includes("birth") ? e.data.birth : null;

        // 이름 유효성 검사 -> O
        // setIsNameVerificate(true);

        // setValues({
        //   ...values,
        //   tel: e.data.cellNo,
        //   di: e.data.di,
        // });

        // 회원일 경우
        if (verificateTarget === "user") {
          // 중복 검사 기능
          switch (await checkOverlapJoin(data)) {
            case "EXISTS":
              alert(
                "이미 가입되어있는 사용자입니다. 로그인 페이지로 이동됩니다."
              );
              // navigate(-1);
              window.location.href = "/";
              break;
            case "NOT EXISTS":
              // 이름 유효성 검사 -> O
              setIsNameVerificate(true);

              setValues({
                ...values,
                tel: e.data.cellNo,
                di: e.data.di,
                birthdate: birth === null ? "" : convertDashFormat(birth),
              });

              if (birth !== null) {
                let age = calcOriginAge(birth);
                if (age <= 14) {
                  setIsUnder14(true);
                } else {
                  setIsUnder14(false);
                }
              }

              nameInput.current.disabled = true;

              break;
            default:
              break;
          }
        }
        // 보호자일 경우
        else {
          if (birth !== null) {
            let age = calcOriginAge(birth);

            if (19 <= age) {
              setParentInfo({
                ...parentInfo,
                tel: e.data.cellNo,
                isVerificated: true,
                isAdult: true,
              });

              parentNameInput.current.disabled = true;
            } else {
              setParentInfo({
                ...parentInfo,
                tel: e.data.cellNo,
                isVerificated: true,
                isAdult: false,
              });

              parentNameInput.current.disabled = false;
            }
          } else {
            setParentInfo({
              ...parentInfo,
              tel: e.data.cellNo,
              isVerificated: true,
              isAdult: null,
            });

            parentNameInput.current.disabled = true;
          }
        }
      } else {
        if (verificateTarget === "user") {
          // 이름 유효성 검사 결과 -> X
          setIsNameVerificate(false);

          nameInput.current.disabled = false;
          nameInput.current.focus();
          nameInput.current.style =
            "background:#FBFBEF; border: 1px solid #ef5797;";
        } else {
          setParentInfo({
            ...parentInfo,
            isVerificated: false,
          });

          parentNameInput.current.disabled = false;
          parentNameInput.current.focus();
          parentNameInput.current.style =
            "background:#FBFBEF; border: 1px solid #ef5797;";
        }
      }
    },
    [checkOverlapJoin, parentInfo, values, verificateTarget]
  );

  // 일반/기관 토글 tab
  const [tabId, setTabId] = useState(0);

  const onChangeTabId = (selectedId) => {
    setTabId(selectedId);

    // 일반 사용자 클릭
    if (selectedId === 0) {
      // 기관/단체 사용자 정보 지우기
      setSignUpInfo({
        nickName: "",
        pwd: "",
        pwd_confirm: "",
        email: "",
      });
    } else {
      // 일반 사용자 정보 지우기
      setValues({
        nickname: "",
        name: "",
        password: "",
        passwordcheck: "",
        tel: "",
        email: "",
        birthdate: "",
        address: "",
        addressdetail: "",
        gender: "",
        di: "",
      });

      setBirthDay(new Date());

      setAddress1("");
    }
  };

  const [orgSignUpInfo, setSignUpInfo] = useState({
    nickName: "",
    pwd: "",
    pwd_confirm: "",
    email: "",
  });

  const onChangeOrgSignUpInfo = (e) => {
    if (e.target.name === "pwd_confirm") {
      setValidationStatus({
        ...validationStatus,
        isMatchedPwd: orgSignUpInfo.pwd === e.target.value,
      });
    }

    setSignUpInfo({
      ...orgSignUpInfo,
      [e.target.name]: e.target.value,
    });
  };

  // validation
  const [validationStatus, setValidationStatus] = useState({
    isNicknameOverlap: 0, // 1 : true
    isMatchedPwd: orgSignUpInfo.pwd === orgSignUpInfo.pwd_confirm, // true
    isEmailOverlap: null, // 1 : true
  });

  const continueOrgSignUp = () => {
    if (orgSignUpInfo.nickName.trim() === "") {
      alert("ID을 입력해주세요.");

      return false;
    }

    if (orgSignUpInfo.pwd.trim() === "") {
      alert("비밀번호를 입력해주세요.");

      return false;
    }

    if (orgSignUpInfo.email.trim() === "") {
      alert("이메일을 입력해주세요.");
      return false;
    }

    const { isNicknameOverlap, isMatchedPwd, isEmailOverlap } =
      validationStatus;

    if (isNicknameOverlap === 0) {
      alert("ID 중복확인이 필요합니다.");
      return false;
    } else if (isNicknameOverlap === 2) {
      alert("이미 사용중인 ID입니다. 다른 ID을 입력해주세요.");
      return false;
    }

    // 1 -> true

    // 비밀번호 검사
    if (!isMatchedPwd) {
      alert("비밀번호가 일치하지 않습니다.");
      return false;
    }

    if (orgSignUpInfo.pwd === orgSignUpInfo.pwd_confirm) {
      setValidationStatus({
        ...validationStatus,
        isMatchedPwd: true,
      });
    } else {
      setValidationStatus({
        ...validationStatus,
        isMatchedPwd: false,
      });

      return false;
    }

    // true일 경우
    let pwdpattern =
      /^.*(?=.{8,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    if (pwdpattern.test(orgSignUpInfo.pwd) === false) {
      alert("숫자,문자,특수문자를 조합해서 최소 8자 이상 입력해 주세요.");
      return false;
    }

    if (isEmailOverlap === 0) {
      alert("이메일 중복확인이 필요합니다.");
      return false;
    } else if (isEmailOverlap === 2) {
      alert("이미 사용중인 이메일 입니다. 다른 이메일을 입력해주세요.");
      return false;
    }
    // 1일 경우
    // -> 이메일 유효성 검사
    let emailpattern =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (emailpattern.test(orgSignUpInfo.email) === false) {
      alert("숫자,문자,특수문자를 조합해서 최소 8자 이상 입력해 주세요.");
      return false;
    }

    // cookie에 데이터 저장
    setCookie(
      "___seochoVolunteer__org___signup___nickname___",
      orgSignUpInfo.nickName,
      {
        secure: true,
        sameSite: "none",
      }
    );

    setCookie("___seochoVolunteer__org___signup___pwd___", orgSignUpInfo.pwd, {
      secure: true,
      sameSite: "none",
    });

    setCookie(
      "___seochoVolunteer__org___signup___email___",
      orgSignUpInfo.email,
      {
        secure: true,
        sameSite: "none",
      }
    );

    navigate("/common/request-org");
  };

  useEffect(() => {
    window.addEventListener("message", successToVerification, false);

    return () => {
      window.removeEventListener("message", successToVerification, false);
    };
  }, [successToVerification]);

  return (
    <div className="page page--login" data-page="login">
      {/* HEADER */}

      <header className="header header--page header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <Link to="" onClick={() => navigate(-1)}>
              <img src="../assets/images/icons/white/arrow-back.svg" alt="" />
            </Link>
          </div>
          <div className="header__logo header__logo--text">
            <a href="#">회원가입</a>
          </div>
          <div className="header__icon header__icon--empty-space"></div>
        </div>
      </header>

      <div className="join">
        <Modal
          open={modalOpen}
          close={closeModal}
          setModalOpen={setModalOpen}
          setAddress={setAddress1}
          setPostcode={setPostcode}
        />
        <div className="login__content login-form page_section--with-header-68">
          <div className="tabs tabs--style1 mb-20">
            <input
              type="radio"
              name="tabs1"
              className="tabs__radio"
              id="tab1"
              checked={tabId === 0 ? "checked" : null}
              onClick={() => onChangeTabId(0)}
            />
            <label
              className="tabs__label tabs__left tabs__label--12"
              for="tab1"
            >
              일반 사용자
            </label>
            <div className="tabs__content mt-48">
              <form id="LoginForm">
                <div className="login-form__row">
                  <label className="login-form__label">ID</label>
                  <input
                    type="text"
                    name="nickname"
                    value={values.nickname}
                    onChange={handleChangeForm}
                    className="login-form__input_min required"
                    autoComplete="off"
                  />
                  <input
                    type="submit"
                    name="submit"
                    className="button button--main button--half"
                    id="submit"
                    value="중복확인"
                    onClick={(e) => checkNickname(e, 0)}
                  />
                  {nicknamecheck &&
                  nicknamecheck === "null" ? null : nicknamecheck &&
                    nicknamecheck === 200 ? (
                    <p>사용할 수 있는 ID입니다.</p>
                  ) : (
                    <p className="font-red">이미 사용 중인 ID입니다.</p>
                  )}
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">이름</label>
                  <input
                    type="text"
                    name="name"
                    ref={nameInput}
                    value={values.name}
                    onChange={handleChangeForm}
                    className="login-form__input_min required"
                  />
                  <input
                    type="button"
                    className="button button--main button--half"
                    value="실명인증"
                    onClick={(e) => onClickVerificationName(e, "user")}
                    // onClick={(e) => onClickVerificationName_none(e, "user")}
                  />
                  {isNameVerificate === false ? (
                    <p className="font-red">실명인증에 실패하였습니다.</p>
                  ) : isNameVerificate === true ? (
                    <p>실명인증에 성공하였습니다.</p>
                  ) : null}
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">비밀번호</label>
                  <input
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChangeForm}
                    className="login-form__input required"
                    autoComplete="new-password"
                  />
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">비밀번호 재입력</label>
                  <input
                    type="password"
                    name="passwordcheck"
                    value={values.passwordcheck}
                    onChange={handleChangeForm}
                    className="login-form__input required"
                    autoComplete="new-password"
                  />
                  {isPwdMatched === false && (
                    <p className="font-red">비밀번호가 일치하지 않습니다.</p>
                  )}
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">전화번호</label>
                  <input
                    type="tel"
                    name="tel"
                    value={values.tel}
                    onChange={handleChangeForm}
                    disabled={true}
                    className="login-form__input required"
                  />
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">성별</label>
                  <div className="form__select">
                    <select
                      name="gender"
                      className="required"
                      onChange={handleChangeForm}
                    >
                      <option value="N" selected>
                        선택 안함
                      </option>
                      <option value="M">남성</option>
                      <option value="F">여성</option>
                    </select>
                  </div>
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChangeForm}
                    className="login-form__input_min required email"
                  />
                  <input
                    type="submit"
                    name="submit"
                    className="button button--main button--half"
                    id="submit"
                    value="중복확인"
                    onClick={(e) => checkEmail(e, 0)}
                  />
                  {emailcheck && emailcheck === "null" ? null : emailcheck &&
                    emailcheck === 200 ? (
                    <p>사용할 수 있는 이메일 주소입니다.</p>
                  ) : (
                    <p className="font-red">
                      이미 사용 중인 이메일 주소입니다.
                    </p>
                  )}
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">생년월일</label>
                  <input
                    type="text"
                    name="birthdate"
                    onClick={onToggleDatePicker}
                    className="login-form__input_min required"
                    placeholder="연도. 월. 일."
                    value={values.birthdate}
                    disabled="true"
                  />
                  <input
                    type="button"
                    name="submit"
                    className="button button--main button--half"
                    id="submit"
                    value="날짜 선택"
                    onClick={(e) => onToggleDatePicker(e, 1)}
                  />
                  <DatePicker
                    value={birthDay}
                    isOpen={toggleDatePicker}
                    onSelect={onSelectBirthDay}
                    onCancel={onToggleDatePicker}
                    confirmText="확인"
                    cancelText="취소"
                    headerFormat="YYYY년 MM월 DD일"
                    max={new Date()}
                    min={new Date(1900, 0, 1)}
                    dateConfig={{
                      year: {
                        format: "YYYY",
                        caption: "년",
                        step: 1,
                      },
                      month: {
                        format: "MM",
                        caption: "월",
                        step: 1,
                      },
                      date: {
                        format: "DD",
                        caption: "일",
                        step: 1,
                      },
                    }}
                  />
                  {isUnder14 && (
                    <p className="font-red">
                      만 14세 미만의 어린이는 보호자 동의가 필요합니다.
                    </p>
                  )}
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">주소</label>
                  <input
                    type="text"
                    name="address"
                    value={address1}
                    className="login-form__input_min required email"
                    onClick={daumPostModalOpenHandler}
                    readOnly
                  />
                  <input
                    type="button"
                    name="submit"
                    className="button button--main button--half"
                    id="submit"
                    value="검색"
                    onClick={daumPostModalOpenHandler}
                  />
                  <br />
                  <br />
                  <label className="login-form__label">상세 주소</label>
                  <input
                    type="text"
                    name="addressdetail"
                    value={values.addressdetail}
                    onChange={handleChangeForm}
                    className="login-form__input required email"
                  />
                </div>
                {isUnder14 && (
                  <>
                    <hr />
                    <div className="page__title-bar mt-48">
                      <h2>보호자 인증</h2>
                    </div>
                    <div className="login-form__row">
                      <label className="login-form__label">보호자 이름</label>
                      <input
                        type="text"
                        name="name"
                        ref={parentNameInput}
                        value={parentInfo.name}
                        onChange={(e) =>
                          setParentInfo({
                            ...parentInfo,
                            name: e.target.value,
                          })
                        }
                        className="login-form__input_min required"
                      />
                      <input
                        type="button"
                        className="button button--main button--half"
                        value="실명인증"
                        onClick={(e) => onClickVerificationName(e, "parent")}
                        // onClick={(e) =>
                        //   onClickVerificationName_none(e, "parent")
                        // }
                      />
                      {parentInfo.isAdult === false && (
                        <p className="font-red">
                          보호자는 만 19세 이상의 성인이어야 합니다.
                        </p>
                      )}
                      {parentInfo.isAdult === true &&
                        parentInfo.isVerificated === true && (
                          <p>보호자 실명인증에 성공하였습니다.</p>
                        )}
                      {parentInfo.isAdult === true &&
                        parentInfo.isVerificated === false && (
                          <p className="font-red">
                            보호자 실명인증에 실패하였습니다.
                          </p>
                        )}
                    </div>
                    <div className="login-form__row">
                      <label className="login-form__label">전화번호</label>
                      <input
                        type="tel"
                        name="tel"
                        value={parentInfo.tel}
                        disabled={true}
                        onChange={(e) =>
                          setParentInfo({
                            ...parentInfo,
                            tel: e.target.value,
                          })
                        }
                        className="login-form__input required"
                      />
                    </div>
                  </>
                )}

                <div className="login-form__row">
                  <input
                    type="submit"
                    name="submit"
                    onClick={signup}
                    className="login-form__submit button button--main button--full"
                    id="submit"
                    value="회원가입"
                  />
                </div>
              </form>
            </div>
            <input
              type="radio"
              name="tabs1"
              className="tabs__radio"
              id="tab2"
              checked={tabId === 1 ? "checked" : null}
              onClick={() => onChangeTabId(1)}
            />
            <label
              className="tabs__label tabs__right tabs__label--12"
              for="tab2"
            >
              기관/단체 사용자
            </label>
            <div className="tabs__content mt-48">
              <form>
                <div className="login-form__row">
                  <label className="login-form__label">ID</label>
                  <input
                    type="text"
                    name="nickName"
                    value={orgSignUpInfo.nickName}
                    onChange={(e) => onChangeOrgSignUpInfo(e)}
                    placeholder="로그인 시 사용할 ID 입력"
                    className="login-form__input_min required"
                    autoComplete="off"
                  />
                  <input
                    type="button"
                    name="submit"
                    className="button button--main button--half"
                    id="submit"
                    value="중복확인"
                    onClick={(e) => checkNickname(e, 1)}
                  />
                  {
                    {
                      0: null,
                      1: <p>사용할 수 있는 ID입니다.</p>,
                      2: <p className="font-red">이미 사용 중인 ID입니다.</p>,
                    }[validationStatus.isNicknameOverlap]
                  }
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">비밀번호</label>
                  <input
                    type="password"
                    name="pwd"
                    value={orgSignUpInfo.pwd}
                    onChange={(e) => onChangeOrgSignUpInfo(e)}
                    className="login-form__input required"
                    autoComplete="new-password"
                  />
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">비밀번호 재입력</label>
                  <input
                    type="password"
                    name="pwd_confirm"
                    value={orgSignUpInfo.pwd_confirm}
                    onChange={(e) => onChangeOrgSignUpInfo(e)}
                    className="login-form__input required"
                    autoComplete="new-password"
                  />
                  {validationStatus.isMatchedPwd === false && (
                    <p className="font-red">비밀번호가 일치하지 않습니다.</p>
                  )}
                </div>
                <div className="login-form__row">
                  <label className="login-form__label">Email</label>
                  <input
                    type="text"
                    name="email"
                    value={orgSignUpInfo.email}
                    onChange={(e) => onChangeOrgSignUpInfo(e)}
                    className="login-form__input_min required email"
                  />
                  <input
                    type="button"
                    name="submit"
                    className="button button--main button--half"
                    id="submit"
                    value="중복확인"
                    onClick={(e) => checkEmail(e, 1)}
                  />
                  {
                    {
                      0: null,
                      1: <p>사용할 수 있는 이메일입니다.</p>,
                      2: (
                        <p className="font-red">이미 사용 중인 이메일입니다.</p>
                      ),
                    }[validationStatus.isEmailOverlap]
                  }
                </div>
                <div className="login-form__row">
                  <input
                    name="submit"
                    onClick={() => {
                      continueOrgSignUp();
                    }}
                    type="link"
                    className="login-form__submit button button--main button--full"
                    id="submit"
                    value="다음"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
