import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";

import ActivityDetail from "../../../components/ActivityDetail";
import Navigationbar from "../../../components/Navigationbar";
import OrgList from "../../../components/OrgList";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ScheduleList from "../../../components/ScheduleList";
import CheckLoginInfo from "../../../services/login-validation";
import ConformPopup from "../../common/popup/ConformPopup";

function RequestOperationDetailConform(props) {
  const navigate = useNavigate();
  const { actIdx } = useParams();

  const [requestedOrgInfo, setRequestedOrgInfo] = useState(null);
  const [activityInfo, setActivityInfo] = useState();

  const [scheduleTotalRows, setScheduleTotalRows] = useState(0);
  const [scheduleList, setScheduleList] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);

  const detailBeneClickHandler = () => {
    navigate(`/org/activity/detailbene/${actIdx}`, {
      state: {},
    });
  };

  const activityRequestClickHandler = () => {
    navigate(
      `/org/otherprofile/activityrequest/${
        activityInfo && activityInfo.orgIdx
      }`,
      {
        state: {},
      }
    );
  };

  // 활동 삭제
  const deleteActivity = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/${actIdx}`;
    axios
      .delete(URL)
      .then((res) => {
        alert("삭제되었습니다.");
        navigate(-1);
      })
      .catch((err) => {
        alert("홛동 삭제 중, 오류가 발생하였습니다.");
        console.log(err.response);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getRequestedOrgInfo();
        getActivityInfo();
        getScheduleList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getRequestedOrgInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/requested/org/${actIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setRequestedOrgInfo({
            idx: response.data.id,
            contact: response.data.contact,
            orgTitle: response.data.orgTitle,
            img: Object.keys(response.data).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_API}${response.data.img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getActivityInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setActivityInfo(response.data);
        }
      } catch (e) {
        console.log(e);
        alert("활동 정보 조회 중, 오류가 발생하였습니다.");
      }
    };

    const getScheduleList = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/recruit/${actIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { data } = response.data;
          let ary = [];

          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: data[i].activityScheduleIdx,
              date: data[i].activityDate,
              time: data[i].activityTime,
              recruitNum: data[i].recruitNum,
            });
          }

          setScheduleList(ary);
          setScheduleTotalRows(ary.length);
        }
      } catch (e) {
        setScheduleList([]);
        setScheduleTotalRows(0);
        console.log(e);
        // alert("스케줄 목록 조회 중, 오류가 발생하였습니다.");
      }
    };
  }, [actIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 상세" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="cards cards--11 mt-40">
              <div className="d-flex justify-space align-items-center">
                <h2 className="mb-0">의뢰 요청 기관/단체 :</h2>
              </div>
              {requestedOrgInfo && <OrgList orgprofile={requestedOrgInfo} />}
            </div>

            {activityInfo &&
              {
                D: (
                  <>
                    <div className="cards cards--6" id="loadlist-01">
                      <div className="table card card--style-thumb mb-20">
                        <div className="card__details text-aline-center">
                          <h2 className="point-blue">
                            도움 요청된 활동이 승인 되었습니다.
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={detailBeneClickHandler}
                      style={{ cursor: "pointer" }}
                      className="button button--full button--main float-right ml-10 mt-40 mb-24"
                    >
                      활동 보러가기
                    </div>
                  </>
                ),
                W: <></>,
                N: (
                  <div className="cards cards--6" id="loadlist-01">
                    <div className="table card card--style-thumb mb-20">
                      <div className="card__details text-aline-center">
                        <h2 className="point-red">
                          도움 요청된 활동이 반려 되었습니다.
                        </h2>
                        <p className="point-red">
                          {activityInfo.dismissalReason}
                        </p>
                      </div>
                    </div>
                  </div>
                ),
              }[activityInfo.helpStatus]}

            {activityInfo && <ActivityDetail activityData={activityInfo} />}
          </div>

          <div className="page_section">
            <div className="page__title-bar mt-48">
              <h2>일정({scheduleTotalRows !== null && scheduleTotalRows})</h2>
            </div>
            {scheduleList && (
              <ScheduleList
                list={scheduleList}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalRows={scheduleTotalRows}
              />
            )}
            <div className="under_button">
              <div className="form__row mt-40">
                {activityInfo && activityInfo.helpStatus !== "D" && (
                  <input
                    style={{ cursor: "pointer" }}
                    name="submit"
                    data-popup="conform_delete"
                    className="form__submit button button--gray button--50 open-popup"
                    id="submit"
                    value="삭제"
                  />
                )}

                <input
                  onClick={activityRequestClickHandler}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className={`form__submit button button--main ${
                    activityInfo && activityInfo.helpStatus === "D"
                      ? "button--full"
                      : "button--50"
                  }`}
                  id="submit"
                  value="수정하여 재요청 하기"
                />
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
      <ConformPopup
        popupType="delete"
        conformClick={deleteActivity}
        text="정말 삭제하시겠습니까?"
      />
    </Fragment>
  );
}

export default RequestOperationDetailConform;
