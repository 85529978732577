import React from "react";
import DaumPostcode from "react-daum-postcode";

const DaumPost = (props) => {
  const { setAddress, setPostcode, setModalOpen, setFullAddress } = props;

  const handleComplete = (data) => {
    // console.log("주소찾기 데이터");
    // console.log(data);

    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    // console.log(fullAddress);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'

    setAddress(fullAddress);
    setModalOpen(false);
  };

  return <DaumPostcode onComplete={handleComplete} {...props} />;
};

export default DaumPost;
