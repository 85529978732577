import axios from "axios";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import CoinRewardList from "../../../components/CoinRewardList";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";

const CoinReward = () => {
  const [totalCoin, setTotalCoin] = useState(null);

  const [totalRows, setTotalRows] = useState(null);
  const [coinRewardList, setCoinRewardList] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);

  const goNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const getCoinRewardList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/sc-coin/ledger/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          cout: 6,
          page: pageNumber,
        },
      });

      if (response.status === 200) {
        const { totalCoin, totalRows, data } = response.data;

        setTotalCoin(totalCoin);
        setTotalRows(totalRows);

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            coinLedgerIdx: data[i].coinLedgerIdx,
            coin: data[i].coin,
            paid: data[i].paid,
            status: data[i].status,
            activityIdx: data[i].activityIdx,
            activityTitle: data[i].activityTitle,
            activityScheduleIdx: data[i].activityScheduleIdx,
            activityDate: data[i].activityDate,
            activityTime: data[i].activityTime,
            hostOrgIdx: data[i].hostOrgIdx,
            hostOrgTitle: data[i].hostOrgTitle,
          });
        }

        if (pageNumber === 1) {
          setCoinRewardList(ary);
        } else {
          setCoinRewardList(coinRewardList.concat(ary));
        }
      }
    } catch (e) {
      console.log(e);
      alert("코인 적립 내역 조회 중, 오류가 발생하였습니다.");

      setTotalCoin(0);
      setCoinRewardList([]);
      setTotalRows(0);
    }
  }, [pageNumber]);

  useEffect(() => {
    getCoinRewardList();
  }, [getCoinRewardList]);

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        <SubHeader title="서초코인 적립 내역" />
        <div className="page__content page_section--with-header-68">
          <div className="page_section">
            <div className="page__title-bar mb-40 display-block pt-40 ">
              <h1 className="fc_point1">총 보유코인 : {totalCoin}코인</h1>
            </div>

            <div className="page__title-bar display-block">
              <h2 className="lh-2">적립내역 ({totalRows})</h2>
              <span className="fc-color-gray">
                사회공헌플랫폼내 일정별 적립내역입니다.
              </span>
            </div>
            <div className="page__title-bar"></div>

            {coinRewardList && (
              <CoinRewardList
                list={coinRewardList}
                totalRows={totalRows}
                goNextPage={goNextPage}
              />
            )}
            <div className="space-h-100"></div>
          </div>
          <div className="space-h-100"></div>
        </div>
      </div>
    </Fragment>
  );
};
export default CoinReward;
