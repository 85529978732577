import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function QnaWrite() {
  const navigate = useNavigate();

  const [qnaInfo, setQnaInfo] = useState({
    title: "",
    content: "",
    openStatus: "default",
  });

  const onChangeQnaInfo = (name, data) => {
    setQnaInfo({
      ...qnaInfo,
      [name]: data,
    });
  };

  const addQna = async (data) => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/qna`;

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        alert("등록되었습니다.");
        navigate("/common/community/qna");
      }
    } catch (e) {
      alert("문의사항 등록 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const onClickSaveBtn = (e) => {
    if (qnaInfo.openStatus === "default") {
      alert("공개 여부를 선택하여주세요.");

      return;
    }

    // "userIdx": 11,
    // "title": "테스트 질문1",
    // "content": "테스트 내용1",
    // "openStatus": "C"

    let data = new Object();

    data.userIdx = window.sessionStorage.getItem("idx");
    data.title = qnaInfo.title;
    data.content = qnaInfo.content;
    data.openStatus = qnaInfo.openStatus;

    addQna(data);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="문의하기" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="fieldsets">
              <div className="form">
                <div className="pt-40" id="ContactForm">
                  <div className="form__row">
                    <h4>제목</h4>
                    <input
                      type="text"
                      className="form__input required"
                      placeholder="제목"
                      name="title"
                      value={qnaInfo.title}
                      onChange={(e) =>
                        onChangeQnaInfo(e.target.name, e.target.value)
                      }
                    />
                  </div>
                  <div className="form__row mt-20">
                    <h4>상세</h4>
                    <textarea
                      name="content"
                      value={qnaInfo.content}
                      onChange={(e) =>
                        onChangeQnaInfo(e.target.name, e.target.value)
                      }
                      className="form__textarea required"
                      placeholder="사실관계 및 질문의 요지를 1,000자 이내로 입력해주세요."
                    />
                  </div>
                  <div className="form__row mt-20">
                    <h4>공개여부</h4>
                    <div className="form__select">
                      <select
                        name="openStatus"
                        onChange={(e) =>
                          onChangeQnaInfo(e.target.name, e.target.value)
                        }
                        className="required"
                        defaultValue={qnaInfo.openStatus}
                        key={qnaInfo.openStatus}
                      >
                        <option value="default" disabled>
                          선택
                        </option>
                        <option value="O">공개</option>
                        <option value="C">비공개</option>
                      </select>
                    </div>
                  </div>
                  <div className="form__row mt-40">
                    <input
                      data-popup="conform_cancle"
                      className="form__submit button button--gray button--50 open-popup"
                      id="submit"
                      value="취소"
                    />
                    <input
                      type="submit"
                      className="form__submit button button--main button--50 open-popup"
                      data-popup="conform_save"
                      onClick={() => onClickSaveBtn()}
                      value="저장"
                    />
                  </div>
                  <div className="space-h-100" />
                </div>
              </div>
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default QnaWrite;
