import React, { Fragment, useEffect, useCallback, useState } from "react";
import axios from "axios";

import CommunityPageNav from "../../../components/CommunityPageNav";
import NoticeList from "../../../components/NoticeList";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function Notice() {
  const [pageNumber, setPageNumber] = useState(1);
  const getPageNumber = (seletedPage) => {
    setPageNumber(seletedPage);
  };

  const [totalRows, setTotalRows] = useState(null);
  const [noticeList, setNoticeList] = useState(null);

  const getNoticeList = useCallback(async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community`;

    try {
      const response = await axios.get(url, {
        params: {
          type: "NOTICE",
          page: pageNumber,
          rows: 10,
          state: "POST",
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            idx: data[i].idx, // idx
            title: data[i].title, // 제목
            createDate: data[i].createdDateStr, // 등록일
          });
        }

        setTotalRows(totalRows);
        setNoticeList(ary);
      }
    } catch (e) {
      alert("공지사항 목록 조회중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [pageNumber]);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getNoticeList();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getNoticeList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page-section page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="공지사항" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68">
          <div className="page__title-bar">
            <h2>공지사항 ({totalRows})</h2>
          </div>
          {noticeList && (
            <NoticeList
              noticeList={noticeList}
              pageNumber={pageNumber}
              count={10}
            />
          )}
          <CommunityPageNav
            pageNumber={pageNumber}
            getPageNumber={getPageNumber}
            totalNum={totalRows}
            count={10}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default Notice;
