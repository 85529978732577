import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function TermsOfUse() {
  const [termsInfo, setTermsInfo] = useState(null);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getTermsInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getTermsInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/terms`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setTermsInfo(
            Object.keys(response.data).includes("moreInformation")
              ? response.data.moreInformation
              : ""
          );
        }
      } catch (e) {
        alert("이용약관 상세조회 중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="이용약관" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40"></div>
            {termsInfo && (
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: termsInfo,
                      }}
                    ></div>
                  </li>
                </ul>
              </div>
            )}
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default TermsOfUse;
