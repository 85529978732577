import React, { useState, useEffect, useCallback, Fragment } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import printJS from "print-js";

import SubHeader from "../../../components/SubHeader";
import Panel from "../../../components/Panel";
import ActivityList from "../../../components/ActivityList";

const DetailBeneQrView = () => {
  const { actIdx, activityScheduleIdx } = useParams();
  const navigate = useNavigate();

  const [activityData, setActivityData] = useState(null);
  const [actScheduleData, setActScheduleData] = useState(null);

  const getActivityData = useCallback(() => {
    // 활동, 활동상세, 기관정보 조회
    let URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;
    axios.get(URL).then((res) => {
      setActivityData(res.data);
    });
  }, [actIdx]);

  const getActScheduleData = useCallback(() => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/org/profile/list_activity/${activityScheduleIdx}`;
    axios
      .get(URL)
      .then((res) => {
        setActScheduleData(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [activityScheduleIdx]);

  const convertDate = (inputDate) => {
    const date = new Date(inputDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;

    return `${year}년 ${month}월 ${day}일`;
  };

  const printQR = () => {
    const header = `<h1>${activityData && activityData.title}</h1>
                      <h1>${actScheduleData &&
      convertDate(actScheduleData.activityDate) +
      " " +
      actScheduleData.activityTime
      }</h1>`;
    printJS({
      printable: "qr",
      type: "html",
      style: `.qrImg {width: 100%;}`,
      header: header,
      scanStyles: false,
    });
  };

  useEffect(() => {
    getActivityData();
    getActScheduleData();
  }, [getActivityData, getActScheduleData]);

  return (
    <Fragment>
      <Panel />

      <iframe
        id="ifmcontentstoprint"
        title="printQr"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="QR코드 인증" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="cards cards--6 mt-40" id="loadlist-01">
              {actScheduleData && (
                <ActivityList folder="org" schedule={actScheduleData} />
              )}
              {activityData && activityData.joinType === "Q" && (
                <div className="table card card--style-thumb mb-20">
                  <div className="card__details">
                    <h2 className="">QR 코드</h2>
                    <div
                      id="qr"
                      // className="card__thumb card__thumb--round-corners"
                      style={{ textAlign: "center" }}
                    >
                      <div style={{ padding: "50px" }}>
                        <img
                          className="qrImg"
                          style={{ cursor: "pointer", maxWidth: "100%" }}
                          onClick={() =>
                            navigate(`/org/activity/detailbene/${actIdx}`)
                          }
                          src={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=activityScheduleIdx=${activityScheduleIdx}`}
                          alt="qrCode"
                        />
                      </div>
                    </div>
                    <input
                      type="submit"
                      name="submit"
                      className="form__submit button button--gray button--full"
                      id="submit"
                      value="인쇄하기"
                      onClick={printQR}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
    </Fragment>
  );
};

export default DetailBeneQrView;
