import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ConformPopup from "../pages/common/popup/ConformPopup";
import { onHandleImgError } from "../util/util-function";

function ActivityMemberList(props) {
  const {
    btnType,
    part,
    setSelActListIdx,
    actSchduelIdx,
    actIdx,
    changeVolunteerStatus,
    setReportedIdx,
  } = props;

  // console.log("== 활동자 ==");
  // console.log(part);

  const navigate = useNavigate();

  const canlendarClickHandler = () => {
    navigate(`/org/profile/calendar`, {
      state: {
        actSchduelIdx: actSchduelIdx,
        actListIdx: part.actListIdx,
        actIdx: actIdx,
        type: "actList",
      },
    });
  };

  const chatActivityToUser = () => {
    navigate(`/org/chat/chatd/a2s`, {
      state: {
        activityId: actIdx, // 활동 id
        userId: part.userIdx, // 사용자 id
        scheduleId: actSchduelIdx,
      },
    });
  };

  const chatDClickHandler = () => {
    navigate(`/org/chat/chatd/o2s`, {
      state: {
        orgId: window.sessionStorage.getItem("idx"), // 기관 id
        userId: part.userIdx, // 사용자 id
      },
    });
  };

  const soleClickHandler = () => {
    navigate(`/org/otherprofile/sole`, { state: {} });
  };

  const requestActComplete = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-list/request-complete/alarm/${part.actListIdx}`;
    try {
      const response = await axios.post(url);

      if (response.status === 201) {
        alert("완료 요청을 보냈습니다.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const listActivityHistoryHandler = () => {
    navigate(`/org/profile/reportdetail/${part.actListIdx}`, { state: {} });
  };

  const defaultImgPath = `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`;
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (part) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: part && part.userIdx,
            imageStatus: "U",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  // 활동 보안 요청
  const requestActSupplement = () => {};

  // 참여처리
  const updatePart = () => {};

  // 참여 미처리
  const updateNonPart = () => {};

  // 디폴트 이미지로 변경
  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  const updateSelorgMemberIdx = () => {
    setSelActListIdx(part.idx);
  };

  const updateSelActListIdx = () => {
    setSelActListIdx(part.actListIdx);
  };

  const convertDate = (inputDate) => {
    if (inputDate !== "" && inputDate !== undefined) {
      const date = new Date(inputDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let min = date.getMinutes();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour > 12 ? "오후 " + (hour - 12) : "오전 " + hour;

      return `${year}.${month}.${day} 
              ${hour}시 ${min}분`;
    } else {
      return ``;
    }
  };

  const changeNonePartToPart = () => {
    let data = new Object();

    data.activityScheduleIdx = part.scheduleIdx;
    data.userIdx = part.userIdx;
    data.updatedUid = window.sessionStorage.getItem("idx");

    changeVolunteerStatus("part", "user", data);
  };

  const changPartToNonePart = () => {
    let data = new Object();

    data.activityScheduleIdx = part.scheduleIdx;
    data.userIdx = part.userIdx;
    data.updatedUid = window.sessionStorage.getItem("idx");

    changeVolunteerStatus("none-part", "user", data);
  };

  const btnView = () => {
    switch (btnType) {
      case "groupRequest":
        return (
          <Fragment>
            <div
              onClick={updateSelorgMemberIdx}
              className="button button--small button--main float-right ml-10 open-popup"
              data-popup="conform_accept"
            >
              승인
            </div>
            <div
              onClick={updateSelorgMemberIdx}
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_delete"
            >
              삭제
            </div>
          </Fragment>
        );
      case "history":
        return (
          <Fragment>
            <div
              onClick={chatDClickHandler}
              style={{ cursor: "pointer" }}
              className="button button--small button--main float-right ml-10"
            >
              메세지
            </div>
            {part && part.reportStatus === "Y" ? (
              <div
                onClick={listActivityHistoryHandler}
                style={{ cursor: "pointer" }}
                className="button button--small button--gray float-right ml-10"
              >
                활동 내역 보기
              </div>
            ) : (
              <>
                <div
                  onClick={requestActComplete}
                  style={{ cursor: "pointer" }}
                  className="button button--small button--gray float-right ml-10"
                >
                  완료 요청
                </div>
                <div
                  onClick={changPartToNonePart}
                  style={{ cursor: "pointer" }}
                  className="button button--small button--gray float-right ml-10"
                >
                  미참여 처리
                </div>
              </>
            )}
          </Fragment>
        );
      case "participation":
        return (
          <Fragment>
            <div
              onClick={chatDClickHandler}
              style={{ cursor: "pointer" }}
              className="button button--small button--main float-right ml-10"
            >
              메세지
            </div>
            {/* <div
              onClick={changPartToNonePart}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              미참여 처리
            </div> */}
            <div
              onClick={changeNonePartToPart}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              참여처리
            </div>
          </Fragment>
        );
      case "nonParticipation":
        return (
          <Fragment>
            <div
              onClick={chatDClickHandler}
              style={{ cursor: "pointer" }}
              className="button button--small button--main float-right ml-10"
            >
              메세지
            </div>
            <div
              onClick={changeNonePartToPart}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              미참여 처리 취소
            </div>
          </Fragment>
        );
      case "activity":
        return (
          <Fragment>
            <div
              onClick={chatActivityToUser}
              style={{ cursor: "pointer" }}
              className="button button--small button--main float-right ml-10"
            >
              메세지
            </div>
            {/* <div
              onClick="location.href='#'"
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              활동 보완 요청
            </div> */}
            <div
              onClick={requestActComplete}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              완료 요청
            </div>
            <div
              onClick={changPartToNonePart}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              미참여 처리
            </div>
          </Fragment>
        );
      case "schedule":
        return (
          <Fragment>
            <div
              onClick={chatDClickHandler}
              style={{ cursor: "pointer" }}
              className="button button--small button--main float-right ml-10"
            >
              메세지
            </div>
            <div
              onClick={updateSelActListIdx}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10 open-popup"
              data-popup="conform_actlistcanclea"
            >
              일정에서 제외
            </div>
            {/* <div
              onClick={canlendarClickHandler}
              style={{ cursor: "pointer" }}
              className="button button--small button--gray float-right ml-10"
            >
              일정 수정
            </div> */}
          </Fragment>
        );
      case "userlist":
        return (
          <Fragment>
            {window.sessionStorage.getItem("orgStatus") === "O" && (
              <div
                onClick={chatDClickHandler}
                style={{ cursor: "pointer" }}
                className="button button--small button--main float-right ml-10"
              >
                메세지
              </div>
            )}
            <div
              className="button button--small button--gray float-right ml-10 open-popup"
              data-popup="conform_report"
              onClick={() => setReportedIdx(part.idx)}
            >
              신고하기
            </div>
            {/* <div
              className="button button--small button--gray float-right ml-10 open-popup"
              data-popup="export_member"
            >
              내보내기
            </div> */}
          </Fragment>
        );
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <div className="card mt-20 card--style-inline card--style-inline-bg card--style-round-corners p-10">
        <div
          className="user-details__thumb"
          onClick={soleClickHandler}
          style={{ cursor: "pointer" }}
        >
          <img
            src={
              part && (Object.keys(part).includes("img") ? part.img : imgPath)
            }
            alt=""
            onError={(e) => onHandleImgError(e, "U")}
          />
        </div>
        <div className="card__details card__details_100">
          <Link to={`/org/otherprofile/sole/${part && part.userIdx}`}>
            <h4 className="card__username fc_point1">
              {part && part.nickname}
            </h4>
          </Link>
          {btnType !== "userlist" && (
            <p className="card__info">
              참여&#40;지정&#41;시간 &#58;
              {part &&
                (Object.keys(part).includes("createdAt")
                  ? convertDate(part.createdAt)
                  : `${part.scheduleDate} ${part.scheduleTime}`)}
            </p>
          )}
          {/* <p className="card__info">{part && part.createAt}</p> */}
          <p className="card__info">
            전화번호 &#58; &#32;
            {part && part.phoneNum}
          </p>
          {part && part.volunteer === "F" && <p className="card__info">완료</p>}
          {btnView()}
        </div>
      </div>
    </Fragment>
  );
}

export default ActivityMemberList;
