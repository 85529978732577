import {useState, useEffect} from "react";

const LocationExample = () => {

    const [position, setPosition] = useState({
        lat : null,
        lng : null,
    });

    const [errMsg, setErrMsg] = useState(null)

    const onClick = (e) => {
        e.preventDefault();

        navigator.geolocation ? navigator.geolocation.getCurrentPosition(getPosition, getError) : setErrMsg("Not Found Geolocation");
    }

    const getPosition = (pos) => {
        console.log("position", pos);
        window.getLocation(pos.coords.latitude, pos.coords.longitude);
    };



    const getError = (err) => {
        console.log(err);
        setErrMsg(err.message);
    }

    useEffect(() => {
        window.getLocation =  function (lat, lng){
            alert(lat, lng);

            setPosition({
                lat : lat,
                lng : lng,
            });

            return "success";
        }

    },[])


    return (
        <div>
            <h1>{`Location : lat - ${position.lat ?? "알수없음"}, lng = ${position.lng ?? "알수없음"}`}</h1>
            <button
                onClick={onClick}
                type={"button"}
            >
                location
            </button>
            <h1>{`Error : ${errMsg ?? ""}`}</h1>
        </div>
    )
}

export default LocationExample;