import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import GroupHeader from "../../../components/GroupHeader";
import GroupMemberList from "../../../components/GroupMemberList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import CheckLoginInfo from "../../../services/login-validation";

function GroupActHistory(props) {
  const { activityIdx, scheduleIdx, groupIdx } = useParams();

  const navigate = useNavigate();

  const [groupInfo, setGroupInfo] = useState(null);

  const [partList, setPartList] = useState(null);
  const [notPartList, setNotPartList] = useState(null);

  const [adminActListIdx, setAdminActListIdx] = useState(null);

  const reportDetailClickHandler = () => {
    navigate(`/org/profile/reportdetail/${adminActListIdx}`, {
      state: {},
    });
  };

  const requestActCompelete = () => {
    alert("활동 요청");
  };

  const getGroupMemberList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/part/group/member/${scheduleIdx}/${groupIdx}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const {
          groupInfo,
          partUserList,
          nonPartUserList,
          partUserCnt,
          nonPartUserCnt,
        } = response.data;

        setGroupInfo({
          idx: groupInfo.idx,
          groupName: groupInfo.groupName,
          orgIdx: groupInfo.orgIdx,
          orgTitle: groupInfo.orgTitle,
          reportStatus: groupInfo.reportStatus,
        });

        let partList = [];
        for (let i = 0; i < partUserList.length; i++) {
          partList.push({
            idx: partUserList[i].userIdx,
            img: Object.keys(partUserList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partUserList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`,
            nickName: partUserList[i].nickname,
            grade:
              partUserList[i].groupAdminStatus === "A" ? "관리자" : "일반 회원",
            contact: partUserList[i].contact,
            volunteer: partUserList[i].volunteer,
          });

          if (partUserList[i].groupAdminStatus === "A") {
            setAdminActListIdx(partUserList[i].idx);
          }
        }

        setPartList({
          totalRows: partUserCnt,
          list: partList,
        });

        let nonPartList = [];
        for (let i = 0; i < nonPartUserList.length; i++) {
          nonPartList.push({
            idx: nonPartUserList[i].userIdx,
            img: Object.keys(nonPartUserList[i]).includes("img")
              ? nonPartUserList[i].img
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`,
            nickName: nonPartUserList[i].nickname,
            grade:
              nonPartUserList[i].groupAdminStatus === "A"
                ? "관리자"
                : "일반 회원",
            contact: nonPartUserList[i].contact,
            volunteer: nonPartUserList[i].volunteer,
          });

          if (nonPartUserList[i].groupAdminStatus === "A") {
            setAdminActListIdx(nonPartUserList[i].idx);
          }
        }

        setNotPartList({
          totalRows: nonPartUserCnt,
          list: nonPartList,
        });
      }
    } catch (e) {
      alert("그룹 멤버 목록 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [groupIdx, scheduleIdx]);

  const changeVolunteerStatus = async (status, data) => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/volunteer-status/user/${status}/${activityIdx}`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        getGroupMemberList();
      }
    } catch (e) {
      alert("참여자의 참여 상태 변경 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getGroupMemberList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getGroupMemberList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        {groupInfo && (
          <GroupHeader
            participation={true}
            orgTitle={groupInfo.orgTitle}
            groupName={groupInfo.groupName}
          />
        )}
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40">
              <h2>활동자 목록({partList && partList.totalRows})</h2>
            </div>
            <div
              onClick={
                groupInfo && groupInfo.reportStatus === "Y"
                  ? reportDetailClickHandler
                  : requestActCompelete
              }
              style={{ cursor: "pointer" }}
              className="button button--full button--gray mb-20"
            >
              {groupInfo && groupInfo.reportStatus === "Y"
                ? "활동 내역 보기"
                : "완료 요청"}
            </div>
            <div className="cards cards--11">
              {partList &&
                partList.list.map((memberInfo) => (
                  <GroupMemberList
                    participation={true}
                    memberInfo={memberInfo}
                    key={memberInfo.idx}
                    changeVolunteerStatus={changeVolunteerStatus}
                  />
                ))}
            </div>
            <div className="d-flex justify-space align-items-center pt-40">
              <h2>미참여자 목록({notPartList && notPartList.totalRows})</h2>
            </div>
            <div className="cards cards--11">
              {notPartList &&
                notPartList.list.map((memberInfo) => (
                  <GroupMemberList
                    participation={false}
                    memberInfo={memberInfo}
                    key={memberInfo.idx}
                    changeVolunteerStatus={changeVolunteerStatus}
                  />
                ))}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default GroupActHistory;
