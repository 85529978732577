import React from "react";
import { Link } from "react-router-dom";

const OrgLoginW = () => {
  return (
    <div className="page page--main" data-page="shop">
      <header className="header header--page header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icons/white/arrow-back.svg`}
                alt=""
              />
            </Link>
          </div>
          <div className="header__logo header__logo--text">
            <Link to="#">기관 등록 요청</Link>
          </div>
          <div className="header__icon header__icon--empty-space" />
        </div>
      </header>
      {/* PAGE CONTENT */}
      <div className="page__content page_section page__content page_section--with-header-68 ">
        {/* SLIDER SIMPLE */}
        {/* 대기 중 */}
        {sessionStorage && sessionStorage.orgStatus === "W" ? (
          <div className="cards cards--6" id="loadlist-01">
            <div className="table card card--style-thumb mb-20 mt-100">
              <div className="card__details text-aline-center">
                <h2 className="point-blue">기관 등록 대기 중</h2>
                <p className="point-red">
                  신청 후 등록까지 3일에서 14일이 소요될 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {/* 반려 */}
        {sessionStorage && sessionStorage.orgStatus === "N" ? (
          <div className="cards cards--6" id="loadlist-01">
            <div className="table card card--style-thumb mb-20 mt-100">
              <div className="card__details text-aline-center">
                <h2 className="point-red">기관 등록요청이 반려 되었습니다.</h2>
                <p className="point-red">반려 사유입니다.</p>
              </div>
            </div>
          </div>
        ) : null}
        {/* 기관 전환 신청 */}
        <div className="space-h-100" />
      </div>
    </div>
  );
};

export default OrgLoginW;
