import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import UrlContext from "../context/Url.context";

function OrgList({ orgprofile, requestOrgIdx }) {
  const urlContext = useContext(UrlContext);
  const navigate = useNavigate();

  const soleClickHandler = () => {
    navigate(`/org/otherprofile/sole`, {
      state: {},
    });
  };

  const chatDClickHandler = () => {
    navigate(`/org/chat/chatd/o2o`, {
      state: {
        senderOrgId: window.sessionStorage.getItem("idx"),
        targetOrgId:
          orgprofile.id !== null
            ? orgprofile.id
            : requestOrgIdx !== undefined
            ? requestOrgIdx
            : "", // 구현 후 수정 필요
      },
    });
  };

  const [orgImg, setOrgImg] = useState(
    `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`
  );

  useEffect(() => {
    const getOrgImage = async () => {
      if (orgprofile) {
        const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;

        try {
          const response = await axios.get(URL, {
            params: {
              pkIdx: orgprofile && orgprofile.id,
              imageStatus: "O",
            },
          });

          if (response.status === 200) {
            const { pkIdx, imageStatus, fileName } = response.data;

            setOrgImg(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${pkIdx}/${imageStatus}/${fileName}`
            );
          }
        } catch (err) {
          console.log(err.response);
        }
      }
    };

    getOrgImage();
  }, [orgprofile, urlContext.imgUploadPath, urlContext.value]);

  return (
    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
      <div
        className="user-details__thumb"
        onClick={soleClickHandler}
        style={{ cursor: "pointer" }}
      >
        <img
          src={
            Object.keys(orgprofile).includes("img") ? orgprofile.img : orgImg
          }
          alt=""
        />
      </div>
      <div className="card__details">
        <Link to={`/org/otherprofile/org/${orgprofile.idx}`}>
          <h4 className="card__username fc_point1">
            {orgprofile && orgprofile.orgTitle}
          </h4>
        </Link>
        <p className="card__info">
          전화번호 : {orgprofile && orgprofile.contact}
        </p>
      </div>
      {/* <div
        onClick={chatDClickHandler}
        style={{ cursor: "pointer" }}
        className="button button--small button--main float-right ml-10"
      >
        메세지
      </div> */}
    </div>
  );
}

export default OrgList;
