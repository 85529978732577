import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
// import CheckLoginInfo from "../../../services/login-validation";

function PrivacyPolicy() {
  const [policyInfo, setPolicyInfo] = useState(null);

  // const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    // CheckLoginInfo()
    //   .then(() => {
    //     getPolicyInfo();
    //     setHideContent(false);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });

    const getPolicyInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/privacy`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setPolicyInfo(
            Object.keys(response.data).includes("moreInformation")
              ? response.data.moreInformation
              : ""
          );
        }
      } catch (e) {
        alert("개인정보 처리방침 중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };

    getPolicyInfo();
  }, []);

  // if (hideContent) {
  //   return null;
  // }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="개인정보 처리방침" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40">
              {policyInfo && (
                <div className="fieldsets">
                  <ul className="custom-listing mb-20">
                    <li>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: policyInfo,
                        }}
                      ></div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default PrivacyPolicy;
