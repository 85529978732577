import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import OkOnlineDetail from "../../../components/OkOnlineDetail";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import axios from "axios";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";

function DetailAD(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { onconIdx } = useParams();
  const item = location.state && location.state.item;

  const [detailList, setDetailList] = useState();
  const [writeuser, setWriteuser] = useState();

  const modifyClickHandler = () => {
    navigate(`/sole/okonline/detaila`, {
      state: {
        detailList: detailList,
        modi: "1",
      },
    });
  };

  const cancleClickHandler = () => {
    navigate(`/sole/okonline/detail`, {
      state: {},
    });
  };

  const deleteClickHandler = () => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/${
      item && item.idx
    }`;
    axios
      .delete(url)
      .then((res) => {
        navigate(`/sole/okonline/detail`, {
          state: {},
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation`;
        axios
          .get(url)
          .then((res) => {
            const data = res.data.data;

            data &&
              data.map((item) => {
                if (item.idx === onconIdx) {
                  setDetailList(item);
                  setWriteuser(item.createdUid);
                }
              });
          })
          .catch((err) => {
            console.log(err);
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [onconIdx, writeuser]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="문의하기" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <OkOnlineDetail />
            {writeuser && writeuser === sessionStorage.idx ? (
              <div className="form__row mt-40">
                <input
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  data-popup="conform_cancle"
                  className="form__submit button button--gray button--333 open-popup"
                  id="submit"
                  value="취소"
                />
                <input
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  data-popup="conform_delete"
                  className="form__submit button button--gray button--333 open-popup"
                  id="submit"
                  value="삭제"
                />
                <input
                  onClick={modifyClickHandler}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--333"
                  id="submit"
                  value="수정하기"
                />
              </div>
            ) : null}
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        popupType="delete"
        text="정말 삭제하시겠습니까?"
        conformClick={deleteClickHandler}
      />
      <ConformPopup
        popupType="cancle"
        text="정말 취소하시겠습니까?"
        conformClick={cancleClickHandler}
      />
    </Fragment>
  );
}

export default DetailAD;
