import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Modal } from "../../../components/DaumPostModal";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ConformSave from "../../common/popup/ConformSave";
import PopupSuccess from "../../common/popup/PopupSuccess";
import SelectedTime from "../../../components/SelectedTime";
import AddActScList from "./AddActScList";
import CheckLoginInfo from "../../../services/login-validation";
import { makeSchedule_new } from "../../../util/schedule-function";
import ScheduleAdd from "./ScheduleAdd";
import { convertTimeFormat } from "../../../util/convert-date-function";
import ScheduleEdit from "./ScheduleEdit";
import FileUpload from "../../common/FileUpload";

function AddAct(props) {
  const navigate = useNavigate();

  // 카테고리 옵션
  const [categoryList, setCategoryList] = useState([]);
  const setCategoryOption = () => {
    let ary = [];

    ary.push(
      <option value="default" key="default" disabled>
        선택
      </option>
    );

    for (let i = 0; i < categoryList.length; i++) {
      ary.push(
        <option
          value={categoryList[i].categoryId}
          key={categoryList[i].categoryId}
        >
          {categoryList[i].categoryName}
        </option>
      );
    }

    return ary;
  };

  // 다음 주소찾기
  const [showAddressModal, setShowAddresModal] = useState(false);
  const toggleAddressModal = () => {
    setShowAddresModal(!showAddressModal);
  };
  const onChangeAddress1 = (address1) => {
    setActivityInfo({
      ...activityInfo,
      address1: address1,
    });
  };
  const onChangePostCode = (postCode) => {
    setActivityInfo({
      ...activityInfo,
      postCode: postCode,
    });
  };

  // 활동 정보
  const [activityInfo, setActivityInfo] = useState({
    title: "",
    categoryId: "default",
    activityType: "default",
    address1: "",
    address2: "",
    postCode: "",
    partType: "default",
    beneType: "default",
    isPrivate: "default",
  });
  const onChangeActivityInfo = (e) => {
    setActivityInfo({
      ...activityInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [selectedActivityInfo, setSelectedActivityInfo] = useState({
    recruitDate: "default",
    totalTime: "00:00",
    recruitNum: 0,
    joinType: "default",
    memo: "",
  });
  const onChangeSelectedActivityInfo = (e) => {
    setSelectedActivityInfo({
      ...selectedActivityInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [addrRadio, setAddrRadio] = useState("0");
  const onClickRadioBtn = (value) => {
    setAddrRadio(value);
  };

  const [coinRadio, setCoinRadio] = useState("N");
  const onClickCoinRadioBtn = (value) => {
    setCoinRadio(value);

    if (value === "N") {
      setAccumCoinValue(0);
    }
  };

  const [accumCoinValue, setAccumCoinValue] = useState(null);
  const onChangeAccumCoinValue = (value) => {
    if (value < 1) {
      alert("적립하실 코인은 1코인 이상 설정부터 가능합니다.");
      return;
    }

    setAccumCoinValue(value);
  };

  // 활동 이미지
  const [imgFile, setImgFile] = useState(null);
  const [imgFileUrl, setImgFileUrl] = useState(
    `${process.env.PUBLIC_URL}/assets/images/food/img-default.jpg`
  );

  const setImageFromFile = (file) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = function () {
        setImgFileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeImgFile = (file) => {
    if (file) {
      const fileEx = file.name.substring(
        file.name.lastIndexOf("."),
        file.name.length
      );

      if (fileEx !== ".jpg" && fileEx !== ".jpeg" && fileEx !== ".png") {
        alert('이미지 확장자는 ".jpg, .jpeg, .png"만 업로드 가능합니다.');
        return;
      }

      if (file.size > 10000000) {
        alert("이미지 파일크기는 10M를 초과할 수 없습니다.");
        return;
      }

      setImgFile(file);
      setImageFromFile(file);
    }
  };

  // 활동 이미지 저장
  const requestActivityImg = async (activityIdx) => {
    if (imgFile === null || imgFile === undefined || imgFile === "") {
      return;
    }

    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("pkIdx", activityIdx);
    formData.append("imageStatus", "A");

    const url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/uploadfile`;

    try {
      axios.post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    } catch (e) {
      console.log(e);
      alert("활동 이미지 업로드 중, 오류가 발생하였습니다.");
    }
  };

  const checkValidation = () => {
    if (activityInfo.title.trim() === "") {
      alert("제목을 입력해주세요");
      return false;
    }

    if (activityInfo.categoryId === "default") {
      alert("카테고리를 선택해주세요.");
      return false;
    }

    if (activityInfo.activityType === "default") {
      alert("활동 방식을 선택해주세요.");
      return false;
    }
    // 오프라인, 직접 입력
    else if (activityInfo.activityType === "F" && addrRadio === "0") {
      if (activityInfo.address1.trim() === "") {
        alert("주소를 검색해주세요.");
        return false;
      }
      if (activityInfo.address2.trim() === "") {
        alert("상세주소를 입력해주세요.");
        return false;
      }
    } else {
      setActivityInfo({
        ...activityInfo,
        address1: "",
        address2: "",
      });
    }

    if (activityInfo.beneType === "default") {
      alert("수요자 모집을 선택해주세요.");
      return false;
    }

    if (activityInfo.partType === "default") {
      alert("활동자 모집을 선택해주세요.");
      return false;
    }

    if (activityInfo.isPrivate === "default") {
      alert("공개여부를 선택해주세요.");
      return false;
    }

    // 활동자 모집안함이 아닐경우만 활동자 모집 선택사항 체크
    // if (activityInfo.partType !== "X") {
    //   if (selectedActivityInfo.isRepeat === "default") {
    //     alert("반복을 선택해주세요.");
    //     return false;
    //   } else {
    //     // 반복 없음이 아닌 경우 반복 종료 날짜 필수 기입
    //     if (selectedActivityInfo.isRepeat !== "X") {
    //       if (selectedActivityInfo.repeatDate === "") {
    //         alert("반복종료 날짜를 선택해주세요.");
    //         return false;
    //       }
    //     }
    //   }
    // }

    if (selectedActivityInfo.joinType === "default") {
      alert("활동 참여 방식을 선택해주세요.");
      return false;
    }

    // if (selectedActivityInfo.recruitDate === "default") {
    //   alert("모집 마감을 선택해주세요");
    //   return false;
    // }

    // 코인 적립
    if (coinRadio === "Y") {
      if (accumCoinValue === null) {
        alert("적립하실 코인을 입력해주세요.");

        return false;
      }

      if (accumCoinValue < 1) {
        alert("적립하실 코인은 1코인 이상 설정부터 가능합니다.");

        return false;
      }
    }

    return true;
  };

  // 활동 저장
  const conformClick = () => {
    if (!checkValidation()) return;

    const activity = {
      orgIdx: sessionStorage.idx,
      userIdx: sessionStorage.idx,
      title: activityInfo.title,
      categoryIdx: activityInfo.categoryId,
      activityType: activityInfo.activityType,
      address1: activityInfo.address1,
      address2: activityInfo.address2,
      beneType: activityInfo.beneType,
      partType: activityInfo.partType,
      isPrivate: activityInfo.isPrivate,
      coinStatus: coinRadio,
      coinVal: accumCoinValue === null ? 0 : accumCoinValue,
      createdUid: sessionStorage.idx,
    };

    const actSelect = {
      isRepeat: null,
      repeatDate: null,
      totalTime: selectedActivityInfo.totalTime,
      recruitDate: selectedActivityInfo.recruitDate,
      recruitNum: selectedActivityInfo.recruitNum,
      memo: selectedActivityInfo.memo,
      joinType: selectedActivityInfo.joinType,
      createdUid: sessionStorage.idx,
    };

    axios
      .post(`${process.env.REACT_APP_USER_SERVICE_API}/activity`, activity)
      .then((res) => {
        const activityIdx = res.data.idx;

        // 활동자 모집이 아닌경우 activity_details, activity_schedule

        requestActivityImg(activityIdx);

        actSelect.id = activityIdx;

        // 활동 상세 저장
        axios
          .post(
            `${process.env.REACT_APP_USER_SERVICE_API}/activity_details`,
            actSelect
          )
          .then((res) => {
            if (scheduleList.length !== 0) {
              scheduleList.map((item, idx) => {
                let activitySchedule = {
                  activityIdx: activityIdx,
                  activityDate: item.activityDate,
                  activityTime: item.activityTime,
                  activityTimeEnd: item.activityTime_end,
                  recruitNum: item.recruitNum,
                  createdUid: sessionStorage.idx,
                };

                axios
                  .post(
                    `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule`,
                    activitySchedule
                  )
                  .then((res) => {
                    // 서초코인 적립 활동이면 서초코인쪽에 활동 저장 요청
                    if (idx === scheduleList.length - 1 && coinRadio === "Y") {
                      requestAddScCoinActivity(activityIdx);
                    }
                  })
                  .catch((error) => {
                    console.log(error.response);
                  });
              });
            } else {
              requestAddScCoinActivity(activityIdx);
            }
          })
          .catch((error) => {
            console.log(error.response);
          });

        // Provider 초기화
        navigate("/org/profile/org");
      })
      .catch((error) => {
        alert("활동 등록 중, 오류가 발생하였습니다.");
        console.log(error.response);
      });
  };

  const requestAddScCoinActivity = async (activityIdx) => {
    const requestScCoinUrl = `${process.env.REACT_APP_USER_SERVICE_API}/sc-coin/activity/${activityIdx}`;

    try {
      const response = await axios.post(requestScCoinUrl);

      if (response.status === 201) {
        alert("성공");
      } else if (response.status === 200) {
        alert(response.data);
      }
    } catch (e) {
      console.log(e);
      alert("서초 코인 활동 등록 요청중, 오류가 발생하였습니다.");
    }
  };

  // 일정 추가
  const [showScCoinModal, setShowScCoinModal] = useState(false);
  const onToggleScCoinModal = () => {
    setShowScCoinModal(!showScCoinModal);
  };

  const [scCoinId, setScCoinId] = useState(null);
  const getScCoinId = (selectedId) => {
    setScCoinId(selectedId);
    getCoinActivityInfo(selectedId);
  };

  // 서초코인 활동 상세조회
  const getCoinActivityInfo = async (id) => {
    let url = `${process.env.REACT_APP_SEOCHO_COIN_SERVICE_API}/api/scp/activities/${id}`;

    try {
      const response = await axios.post(url, {
        token: process.env.REACT_APP_SEOCHO_COIN_TOKEN,
      });

      if (response.status === 200) {
        const { result, activity } = response.data;

        //  {
        //     "id": "act000152",
        //     "startDate": "2022/06/21",
        //     "endDate": "2022/07/28",
        //     "activityType": "교육",
        //     "activityCategory": "15",

        //     "recruitStartDate": "2022-06-10",
        //     "recruitEndDate": "2022-06-20",
        // }

        setActivityInfo({
          ...activityInfo,
          title: activity.title,
          categoryId: "default", // set category id
          address1: activity.address,
          address2: activity.activityArea,
        });

        setSelectedActivityInfo({
          ...selectedActivityInfo,
          recruitDate: "default",
          recruitNum: activity.activityLimit,
          joinType: "default",
          memo: Object.keys(activity).includes("detail") ? activity.detail : "",
        });
      }
    } catch (e) {
      console.log(e);
      alert("서초코인 활동 상세 조회 중, 오류가 발생하였습니다.");
    }
  };

  // 스케줄 선택 모달 toggle
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const toggleScheduleModal = () => {
    setShowScheduleModal(!showScheduleModal);
  };

  // 스케줄 추가
  // 반복 타입, 반복 종료 날짜
  // 스케줄 정보(시작 시간, 종료 시간, 필요인원)
  const addSchedule = (repeatType, repeatDate, scheduleInfo, recruitNum) => {
    toggleScheduleModal();

    // TODO : 중복 일정 검사

    let response = scheduleList;

    // 반복 타입 입력 오류
    if (repeatType === "" || repeatType === null) {
      alert("반복 타입 입력 오류");

      return false;
    }
    // 반복 없음
    else if (repeatType === "X") {
      response.push({
        id: `${scheduleInfo.date}/${scheduleInfo.startTime}`,
        activityDate: scheduleInfo.date,
        activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
        activityTimeValue: scheduleInfo.startTime,
        activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
        activityTimeValue_end: scheduleInfo.endTime,
        recruitNum: recruitNum,
        value: scheduleInfo.startTime,
      });
    }
    // 반복
    else {
      // TODO : 반복 종료 날짜까지 사용자가 선택한 일정들을 메일/매주/매달에 따라 반복시키면 됨

      // 반복을 시작할 date = 사용자가 선택한 일정의 일자
      const startDate = new Date(scheduleInfo.date);
      // 반복을 끝낼 date = 사용자가 선택한 반복 종료 일자
      const endDate = new Date(repeatDate);

      let _schedule = makeSchedule_new(
        startDate,
        endDate,
        repeatType,
        scheduleInfo,
        recruitNum
      );

      _schedule.map((schedule) => response.push(schedule));

      calcTotalRecruitNumber(_schedule);
    }

    setScheduleList(response);
  };

  const [scheduleList, setScheduleList] = useState([]);

  const calcTotalRecruitNumber = (ary) => {
    let sum_num = 0;

    for (let i = 0; i < ary.length; i++) {
      sum_num += ary[i].recruitNum;
    }

    setSelectedActivityInfo({
      ...selectedActivityInfo,
      recruitNum: sum_num,
    });
  };

  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false);
  const toggleEditScheduleModal = () => {
    setShowEditScheduleModal(!showEditScheduleModal);
  };

  const [editScheduleInfo, setEditScheduleInfo] = useState(null);
  const getEditScheduleInfo = (info) => {
    setEditScheduleInfo(info);
    toggleEditScheduleModal();
  };

  // 스케줄 정보(id, 활동일자, 시작 시간, 종료 시간)
  // 활동 모집자 수
  const editSchedule = (scheduleInfo, recruitNum, isNewSchedule) => {
    let ary = scheduleList;

    for (let i = 0; i < ary.length; i++) {
      if (ary[i].id === scheduleInfo.id) {
        ary[i] = {
          id: `${scheduleInfo.date}/${scheduleInfo.startTime}`,
          activityDate: scheduleInfo.date,
          activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
          activityTimeValue: scheduleInfo.startTime,
          activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
          activityTimeValue_end: scheduleInfo.endTime,
          recruitNum: recruitNum,
          value: scheduleInfo.startTime,
        };

        break;
      }
    }

    setScheduleList(ary);
    calcTotalRecruitNumber(ary);

    toggleEditScheduleModal();
  };

  const deleteSchedule = (delId, delNum, isNewSchedule) => {
    if (delId === null) {
      alert("일정 삭제 중, 오류가 발생하였습니다.");
      return;
    }

    let ary = scheduleList;
    ary = ary.filter((schedule) => schedule.id !== delId);

    // 필요인원 빼기
    setSelectedActivityInfo({
      ...selectedActivityInfo,
      recruitNum: selectedActivityInfo.recruitNum - delNum,
    });

    setScheduleList(ary);
    toggleEditScheduleModal();
  };

  const [showFileModal, setShowFileModal] = useState(false);
  const toggleFileModal = () => {
    setShowFileModal(!showFileModal);
  };
  const getFile = (file, name, imgUrl, type) => {
    if (type === "img") {
      setImgFile(file);
      setImageFromFile(file);
    }

    toggleFileModal();
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getCategoryList();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getCategoryList = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/category`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { data } = response.data;
          let ary = [];

          for (let i = 0; i < data.length; i++) {
            ary.push({
              categoryId: data[i].idx,
              categoryName: data[i].category,
            });
          }

          setCategoryList(ary);
        }
      } catch (e) {
        alert("카테고리 목록 조회 중, 오류가 발생하였습니다,");
        console.log(e);
      }
    };
  }, []);

  if (hideContent) {
    return null;
  }

  if (showFileModal) {
    return (
      <FileUpload
        type="img"
        getFile={getFile}
        toggleFileModal={toggleFileModal}
      />
    );
  }

  if (showScCoinModal) {
    return (
      <AddActScList
        toggleModal={onToggleScCoinModal}
        getScCoinId={getScCoinId}
        scCoinId={scCoinId}
      />
    );
  }

  if (showScheduleModal) {
    return (
      <ScheduleAdd
        toggleSchedulePopup={toggleScheduleModal}
        addSchedules={addSchedule}
        hideRecruitNum={activityInfo.partType === "E"}
        isPopup={true}
      />
    );
  }

  if (showEditScheduleModal) {
    return (
      <ScheduleEdit
        toggleEditScheduleModal={toggleEditScheduleModal}
        editSchedule={editSchedule}
        deleteSchedule={deleteSchedule}
        prevInfo={editScheduleInfo}
        hideRecruitNum={activityInfo.partType === "E"}
      />
    );
  }

  return (
    <Fragment>
      <Modal
        open={showAddressModal}
        close={toggleAddressModal}
        setModalOpen={toggleAddressModal}
        setAddress={onChangeAddress1}
        setPostcode={onChangePostCode}
      />

      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 등록하기" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="fieldsets">
            <div
              className="swiper-container slider-simple slider-simple  mb-20 slider-init"
              data-paginationtype="bullets"
              data-spacebetweenitems={0}
              data-itemsperview={1}
            >
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide slider-simple__slide"
                  style={{ backgroundImage: `url(${imgFileUrl})` }}
                ></div>
              </div>
            </div>
            <div className="upload-filebox mt-20">
              <form id="_frm" method="post" enctype="multipart/form-data">
                <label htmlFor="ex_file">사진 업로드</label>
                <input
                  // type={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? "button"
                  //     : "file"
                  // }
                  // type="button"
                  type={"file"}
                  // style={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? { display: "none" }
                  //     : { display: "block" }
                  // }
                  // style={{ display: "none" }}
                  name="file"
                  accept=".jpg, .jpeg, .png"
                  // accept="image/*"
                  id="ex_file"
                  capture="camera"
                  // onClick={() =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? toggleFileModal()
                  //     : null
                  // }
                  // onClick={() => toggleFileModal()}
                  // onChange={(e) =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? null
                  //     : onChangeImgFile(e.target.files[0])
                  // }
                  onChange={(e) => onChangeImgFile(e.target.files[0])}
                />
              </form>
            </div>
            {/* <div
              onClick={onToggleScCoinModal}
              style={{ cursor: "pointer" }}
              className="button button--full button--line mt-40"
            >
              서초코인 활동목록 불러오기
            </div> */}
          </div>
          <div className="fieldsets mt-48">
            <div className="d-flex justify-space align-items-center">
              <h2 className="mb-0">활동 개요</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <h4>제목</h4>
                <div className="form__row mb-20">
                  <input
                    type="text"
                    name="title"
                    className="form__input required"
                    placeholder="활동명 입력"
                    value={activityInfo.title}
                    onChange={(e) => onChangeActivityInfo(e)}
                  />
                </div>
                <h4>카테고리</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="categoryId"
                      className="required"
                      value={activityInfo.categoryId}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      {categoryList && setCategoryOption()}
                    </select>
                  </div>
                </div>
                <h4>활동방식</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="activityType"
                      className="required"
                      value={activityInfo.activityType}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value="O">온라인</option>
                      <option value="F">오프라인</option>
                    </select>
                  </div>
                </div>
                {window.sessionStorage.getItem("coinStatus") === "Y" && (
                  <>
                    <h4>서초 코인 적립 여부</h4>
                    <div className="form__radio">
                      <input
                        type="radio"
                        value={"N"}
                        checked={coinRadio === "N"}
                        onClick={(e) => onClickCoinRadioBtn(e.target.value)}
                      />
                      <label htmlFor="contactChoice1">미적립</label>

                      <input
                        type="radio"
                        value={"Y"}
                        checked={coinRadio === "Y"}
                        onClick={(e) => onClickCoinRadioBtn(e.target.value)}
                      />
                      <label htmlFor="contactChoice2">적립</label>
                    </div>
                  </>
                )}
                {coinRadio === "Y" && (
                  <>
                    <h4>일정별 코인 적립</h4>
                    <div className="form__row d-flex align-items-center justify-space  mb-40">
                      <input
                        type="number"
                        name="title"
                        className="form__input required"
                        placeholder="적립할 코인 수"
                        value={accumCoinValue}
                        onChange={(e) => onChangeAccumCoinValue(e.target.value)}
                      />
                    </div>
                  </>
                )}
                {activityInfo.activityType !== "O" && (
                  <>
                    <h4>활동 장소/주소</h4>
                    <div className="form__radio">
                      <input
                        type="radio"
                        name="contact"
                        value={0}
                        checked={addrRadio === "0"}
                        onClick={(e) => onClickRadioBtn(e.target.value)}
                      />
                      <label htmlFor="contactChoice1">직접입력</label>

                      <input
                        type="radio"
                        name="contact"
                        value={1}
                        checked={addrRadio === "1"}
                        onClick={(e) => onClickRadioBtn(e.target.value)}
                      />
                      <label htmlFor="contactChoice2">일정별 상이</label>
                    </div>
                    {addrRadio === "0" && (
                      <>
                        <div className="form__row d-flex align-items-center justify-space mb-40">
                          <input
                            type="address"
                            name="address1"
                            value={activityInfo.address1}
                            className="form__input required"
                            placeholder="입력"
                            readOnly
                            onClick={() => toggleAddressModal()}
                            onChange={(e) => onChangeActivityInfo(e)}
                          />
                          <div className="form__button--13">
                            <input
                              type="button"
                              name="submit"
                              className="form__submit button button--main"
                              id="submit"
                              value="검색"
                              onClick={() => toggleAddressModal()}
                            />
                          </div>
                        </div>
                        <h4>상세 주소</h4>
                        <div className="form__row d-flex align-items-center justify-space  mb-40">
                          <input
                            type="address"
                            name="address2"
                            onChange={(e) => onChangeActivityInfo(e)}
                            value={activityInfo.address2}
                            className="form__input required"
                            placeholder="상세주소"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                <h4>공개여부</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="isPrivate"
                      className="required"
                      value={activityInfo.isPrivate}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value="O">공개</option>
                      <option value="F">비공개</option>
                    </select>
                  </div>
                </div>
                <h4>상세</h4>
                <div className="form__row">
                  <textarea
                    name="memo"
                    className="form__textarea required"
                    placeholder="활동분야, 활동자 정보, 활동가능한 일정 및 시간대 등 장소마련 요청 등 상세 내용을 기입해주세요."
                    onChange={(e) => onChangeSelectedActivityInfo(e)}
                    value={selectedActivityInfo.memo}
                  />
                </div>
              </form>
            </div>

            <div className="d-flex justify-space align-items-center mt-100 mb-20">
              <h2 className="mb-0">모집 사항</h2>
            </div>
            <div className="form">
              <form id="ContactForm" method="post">
                <h4>수요자 모집</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="beneType"
                      className="required"
                      value={activityInfo.beneType}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      {/* <option value="X">해당없음</option> */}
                      {/* <option value="A">전체</option> */}
                      {/* <option value="U">일반</option> */}
                      <option value="O">기관</option>
                      <option value="E">모집 안함</option>
                    </select>
                  </div>
                </div>
                <h4>활동자 모집</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="partType"
                      className="required"
                      value={activityInfo.partType}
                      onChange={(e) => onChangeActivityInfo(e)}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      {/* <option value="X">해당없음</option> */}
                      {/* <option value="A">전체</option> */}
                      <option value="U">일반</option>
                      {/* <option value="O">기관</option> */}
                      <option value="E">모집 안함</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className="d-flex justify-space align-items-center mt-100 mb-20">
              <h2 className="mb-0">활동자 모집 정보</h2>
            </div>
            <div className="form">
              <form id="ContactForm" method="post">
                <h4>활동 참여방식</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="joinType"
                      className="required"
                      onChange={(e) => onChangeSelectedActivityInfo(e)}
                      value={selectedActivityInfo.joinType}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value="A">자동 참여</option>
                      <option value="Q">QR코드 인증 참여(시작 인증)</option>
                      {/* 위치기반 QR 코드 인증 기능 */}
                      {/* <option value="0000000000">QR코드 인증 참여(시작과 종료 2회 인증)</option> */}
                    </select>
                  </div>
                </div>
                {/* 위치기반 QR 코드 인증 기능 */}
                {/* <h4>QR코드 생성</h4>
                <div className="form__radio mb-20">
                  <input type="radio" value={0} />
                  <label htmlFor="00000">일정별 QR코드 생성</label>
                  <input type="radio" value={1} />
                  <label htmlFor="00000">모든 일정 공용 QR코드 생성</label>
                </div>
                <h4>위치 인증(활동장소 100m 이내 참여 인증 제한)</h4>
                <div className="form__radio mb-20">
                  <input type="radio" value={0} />
                  <label htmlFor="00000">적용</label>
                  <input type="radio" value={1} />
                  <label htmlFor="00000">적용안함</label>
                </div> */}
                <h4>모집 마감</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="recruitDate"
                      className="required"
                      onChange={(e) => onChangeSelectedActivityInfo(e)}
                      value={selectedActivityInfo.recruitDate}
                    >
                      <option value="default" disabled>
                        선택
                      </option>
                      <option value="7">일정 시작7일전까지</option>
                      <option value="3">일정 시작3일전까지</option>
                      <option value="2">일정 시작2일전까지</option>
                      <option value="1">일정 시작1일전까지</option>
                      <option value="0.5">일정 시작12시간전까지</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex justify-space align-items-center mt-100 mb-20">
              <h2 className="mb-0">활동 일정 및 인원 설정</h2>
            </div>
            <div className="form">
              <form className="" id="ContactForm" method="post" action="">
                <div
                  onClick={toggleScheduleModal}
                  style={{ cursor: "pointer" }}
                  className="button button--full button--line mb-20"
                >
                  일정 추가하기 +
                </div>
                <div className="cart cart--page">
                  <div
                    className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal"
                    id="item1"
                  >
                    {scheduleList &&
                      scheduleList.map((schedule) => (
                        <SelectedTime
                          schedule={schedule}
                          mode="addActivity"
                          getEditScheduleInfo={getEditScheduleInfo}
                          key={schedule.id}
                        />
                      ))}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="form__row mt-40">
            <input
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
              onClick={() => navigate(-1)}
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_save"
              id="submit"
              value="확인"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <div id="bottom-toolbar" className="bottom-toolbar"></div>
      <ConformSave conformClick={conformClick} />
      <PopupSuccess />
      {/* <Navigationbar /> */}
    </Fragment>
  );
}

export default AddAct;
