import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UrlContext from "../context/Url.context";

function SoleProfile(props) {
  const { user, type, folder } = props;
  const urlContext = useContext(UrlContext);

  const navigate = useNavigate();

  const defaultImgPath = "/assets/images/photos/img-p-sole.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);
  const [mainDivClassName, setMainDivClassName] =
    useState("user-profile mb-20");

  // console.log("== 유저 ==");
  // console.log(user);

  // 파일명 조회
  useEffect(() => {
    if (user) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: user && user.idx,
            imageStatus: "U",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}?date=${Date.now()}`
            );
          }
        });
    }

    // otherprofile인 경우 className 변경
    if (type === "otherprofile") {
      setMainDivClassName("user-profile mb-20 mt-40");
    }
  }, [user]);

  // 활동시간으로 레벨 계산
  const calLevel = (volunteerTime) => {
    if (volunteerTime >= 1200) return "6";
    else if (volunteerTime >= 800) return "5";
    else if (volunteerTime >= 350) return "4";
    else if (volunteerTime >= 100) return "3";
    else if (volunteerTime >= 30) return "2";
    else return "1";
  };

  const chatDClickHandler = (url) => {
    navigate(url, {
      state: {
        orgId: window.sessionStorage.getItem("idx"), // 기관 id
        userId: user.idx, // 사용자 id
      },
    });
  };

  const contactView = () => {
    if (folder === "org") {
      return (
        <div className="user-profile__number">
          전화번호 : {user && user.phoneNum}
        </div>
      );
    } else {
      return (
        <div className="user-profile__info">
          {user && user.bio !== null
            ? `소개글 : ${user.bio}`
            : "등록된 소개글이 없습니다"}
        </div>
      );
    }
  };

  const btnView = () => {
    if (folder === "org") {
      return (
        <span
          onClick={() => chatDClickHandler("/org/chat/chatd/o2s")}
          className="button button--orange button--ex-small ml-10"
        >
          메세지
        </span>
      );
    } else if (folder === "sole") {
      if (type === "profile") {
        return (
          <Link
            to="/sole/profilesole/soleedit"
            className="button button--orange button--edit button--ex-small ml-10"
          >
            편집
          </Link>
        );
      }
    }
  };

  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <div className={mainDivClassName}>
      <div className="user-profile__thumb">
        <img src={imgPath} alt="" onError={changeDefaultImg} />
      </div>
      <div className="user-profile__name">{user && user.nickname}</div>
      {contactView()}
      <div className="buttons buttons--centered">
        <div className="info-box">
          <span>활동시간</span>{" "}
          {/* {user && user.volunteerTime ? user.volunteerTime : "0"}시간 */}
          {user && user.volunteerTime
            ? parseInt(user.volunteerTime.split(":")[0])
            : "0"}
          시간
        </div>
        <div className="info-box">
          <span>레벨</span>{" "}
          {calLevel(parseInt(user && user.volunteerTime?.split(":")[0]))}
        </div>
        {btnView()}
      </div>
    </div>
  );
}

export default SoleProfile;
