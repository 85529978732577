const convertDateFormatDot = (date) => {
  if (date === null || typeof date === "undefined" || date === "") {
    return "";
  }

  let dateString = date.getFullYear() + ".";

  if (date.getMonth() < 9) {
    dateString += "0";
  }
  dateString += date.getMonth() + 1 + ".";

  if (date.getDate() < 10) {
    dateString += "0";
  }
  dateString += +date.getDate();

  return dateString;
};

const convertDateFormatDash = (date) => {
  let dateString = date.getFullYear() + "-";

  if (date.getMonth() < 9) {
    dateString += "0";
  }
  dateString += date.getMonth() + 1 + "-";

  if (date.getDate() < 10) {
    dateString += "0";
  }
  dateString += +date.getDate();

  return dateString;
};

const convertTimeStamp = (timeStamp) => {
  let ary = timeStamp.split("T");
  let timeArea = ary[1].split(":");

  let result = `${ary[0].replace(/-/gi, ".")} `;
  result += `${timeArea[0]}:${timeArea[1]}:${parseInt(timeArea[2])}`;

  return result;
};

const convertTimeStampToString = (timeStamp) => {
  let ary = timeStamp.split("T");
  let timeArea = ary[1];

  let result = `${ary[0].replace(/-/gi, ".")} `;
  result += convertTimeFormat(timeArea);
  //  `${timeArea[0]}:${timeArea[1]}:${parseInt(timeArea[2])}`;

  return result;
};

const convertTimeFormat = (time) => {
  let hour = parseInt(time.split(":")[0]);
  let min = parseInt(time.split(":")[1]);

  let timeString;

  if (hour < 12) {
    timeString = `오전 ${hour}시 `;
  } else {
    timeString = `오후 ${hour === 12 ? hour : hour - 12}시 `;
  }

  if (min !== 0) {
    if (min < 10) {
      timeString += `0${min}분`;
    } else {
      timeString += `${min}분`;
    }
  } else timeString += "00분";

  return timeString;
};

const convertDashToDot = (date) => {
  if (date === null || date === "" || date === undefined) return "";

  return date.replace(/-/gi, ".");
};

const convertDotToDash = (date) => {
  if (date === null || date === "" || date === undefined) return "";

  return date.replace(/[.]/gi, "-");
};

const convertSlashToDash = (date) => {
  if (date === null || date === "" || date === undefined) return "";

  return date.replace(/\//gi, "-");
};

const convertDashFormat = (date) => {
  if (date === null || typeof date === undefined) {
    return "";
  }

  if (date.length !== 8) {
    return "";
  }

  return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6)}`;
};

const convertDateString = (inputDate) => {
  //console.log(inputDate);
  const date = new Date(inputDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;

  return `${year}년 ${month}월 ${day}일`;
};

const calcOriginAge = (birth) => {
  if (birth === null || typeof birth === undefined) {
    return "FAILED";
  }

  if (birth.length !== 8) {
    return "FAULED";
  }

  const today = new Date();

  let age = today.getFullYear() - parseInt(birth.slice(0, 4));

  let month = today.getMonth() - parseInt(birth.slice(4, 6));
  if (
    month < 0 ||
    (month === 0 && today.getDate() < parseInt(birth.slice(6)))
  ) {
    age -= 1;
  }

  return age;
};

export {
  convertTimeStamp,
  convertTimeFormat,
  convertDashToDot,
  convertDateFormatDash,
  convertDateString,
  convertDotToDash,
  convertSlashToDash,
  convertTimeStampToString,
  calcOriginAge,
  convertDashFormat,
};

export default convertDateFormatDot;
