import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import { convertDashToDot } from "../../../util/convert-date-function";
import Navigationbar from "../../../components/Navigationbar";
import CheckLoginInfo from "../../../services/login-validation";

const ReportReadOnly = () => {
  const navigate = useNavigate();
  const { actListIdx } = useParams();

  const [activityInfo, setActivityInfo] = useState(null);
  const [reportInfo, setReportInfo] = useState(null);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onHandleImgError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`;
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getActivityScheduleInfo();
        getActivityReportInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getActivityScheduleInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/simple-info/act-list/${actListIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setActivityInfo({
            title: response.data.title,
            activityDate: response.data.activityDate,
            activityTime: response.data.activityTime,
            activityIdx: response.data.activityIdx,
            scheduleIdx: response.data.activityScheduleIdx,
            actListIdx: response.data.actListIdx,
          });
        }
      } catch (e) {
        console.log(e);
        alert("존재하지 않는 활동 또는 스케줄 입니다.");
        goBack();
      }
    };

    const getActivityReportInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/report/${actListIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const data = response.data;

          setReportInfo({
            idx: data.idx,
            content: data.content,
            images: data.images,
            nickName: data.writerNickname,
            name: data.writerName,
            userIdx: data.writerUserIdx,
          });
        }
      } catch (e) {
        console.log(e);
        alert("");
      }
    };
  }, [actListIdx, goBack]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 내역" />
        {/* PAGE CONTENT */}
        {reportInfo && (
          <div className="page__content page_section page__content page_section--with-header-68 ">
            {/* SLIDER SIMPLE */}
            <div className="d-flex justify-space align-items-center">
              <h2 className="mb-0">{activityInfo && activityInfo.title}</h2>
            </div>
            <div className="fieldsets">
              <ul className="custom-listing mb-20">
                <li>
                  <span>
                    활동인원 : <br />
                  </span>
                  <Link
                    to={`/org/otherprofile/sole/${reportInfo && reportInfo.userIdx
                      }`}
                  >
                    {reportInfo && `${reportInfo.nickName}(${reportInfo.name})`}
                  </Link>
                </li>
                <li>
                  <span>
                    활동 일시 : <br />
                  </span>
                  {activityInfo &&
                    `${convertDashToDot(activityInfo.activityDate)} ${activityInfo.activityTime
                    }`}
                </li>
              </ul>
            </div>

            <div className="cards cards--12" id="loadlist-01">
              {reportInfo !== null &&
                reportInfo.images.map((imgInfo) => (
                  <div className="card card--style-thumb" key={imgInfo.idx}>
                    <div className="card__thumb card__thumb--round-corners">
                      <img
                        src={imgInfo.imgUrl}
                        alt=""
                        onError={(e) => onHandleImgError(e)}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="form mt-40">
              <form>
                <h4>{reportInfo && reportInfo.content}</h4>
              </form>
            </div>
            <div className="space-h-100" />
          </div>
        )}
        {/* PAGE END */}
        <Navigationbar />
      </div>
    </Fragment>
  );
};

export default ReportReadOnly;
