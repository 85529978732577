import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import { Modal } from "../../../components/DaumPostModal";
import { useNavigate } from "react-router-dom";
import CategoryList from "../../../components/CategoryList";
import ConformPopup from "../../common/popup/ConformPopup";

import { isAndroid } from "../../../util/mobile-function";
import { getCookie, removeCookie } from "../../../util/cookies";
import FileUpload from "../../common/FileUpload";

function RequestOrgConform(props) {
  const navigate = useNavigate();
  // 다음 주소
  const [modalOpen, setModalOpen] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [address1, setAddress1] = useState("");

  // input 데이터
  const [values, setValues] = useState({
    categoryIdx: "",
    orgTitle: "",
    address2: "",
    contact: "",
    email: "",
    type: "",
    bio: "",
    orgFile: "",
    openStatus: "",
    file: "",
    file2: "",
  });

  // DB 카테고리 데이터
  const [categoryData, setCategoryData] = useState([]);
  // 추가된 카테고리 데이터
  const [addedCategoryData, setAddedCategoryData] = useState([]);
  // 삭제 버튼 누른 카테고리 IDX
  const [delCategoryIdx, setDelCategoryIdx] = useState(0);

  // 카테고리 조회
  const getCategoryData = () => {
    // axios URL
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/category`;
    axios
      .get(url)
      .then((res) => {
        setCategoryData(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 활동 매칭 카테고리 추가
  const addCategory = (categoryIdx) => {
    if (categoryIdx === "") {
      alert("카테고리를 선택해주세요.");
      return;
    }

    // 이미 추가된 카테고리 여부
    const addedCategoryYn = addedCategoryData.filter(
      (category) => category.idx === Number(categoryIdx)
    ).length;

    if (addedCategoryYn !== 0) {
      alert("이미 추가된 카테고리입니다.");
      return;
    }

    // 추가될 카테고리 필터링
    const addedCategory = categoryData.filter(
      (category) => category.idx === Number(categoryIdx)
    );

    if (addedCategory) {
      setAddedCategoryData(addedCategoryData.concat(addedCategory[0]));
    }
  };

  // 기관 등록
  const requestorg = async (e) => {
    // 0. 유효성 검사
    // 0-1. 일반 사용자
    if (address1.trim() === "") {
      alert("주소를 검색해주세요.");
      return;
    }

    if (values.address2.trim() === "") {
      alert("상세주소를 입력해주세요.");
      return;
    }

    if (values.contact.trim() === "") {
      alert("연락처를 입력해주세요.");
      return;
    }

    // 0-2. 기관 사용자
    if (values.orgTitle.trim() === "") {
      alert("기관/단체 이름을 입력해주세요.");
      return;
    }

    if(values.orgTitle.length > 20){
      alert("기관/단체명은 한글, 영문, 띄어쓰기 포함 20자 이내로 입력해주세요.");
      return ;
    }

    if (values.type === "") {
      alert("기관 타입을 선택해주세요.");
      return;
    }

    if (values.openStatus === "") {
      alert("공개여부를 선택해주세요");
      return;
    }

    if (!renderImgFile) {
      alert("기관 이미지를 업로드 해주세요.");
      return;
    }

    if (values.file2 === "" || values.file2 === undefined) {
      alert("기관/단체 등록증명서 파일을 선택해주세요.");
      return;
    }

    let requestOrg = {
      email : getCookie("___seochoVolunteer__org___signup___email___"),
      nickname : getCookie("___seochoVolunteer__org___signup___nickname___"),
      address1 : address1,
      address2 : values.address2,
      openStatus: values.openStatus,
      password : getCookie("___seochoVolunteer__org___signup___pwd___"),
      categoryIdx : values.categoryIdx,
      categories: addedCategoryData.map((category) => category.idx),
      orgTitle: values.orgTitle,
      contact: values.contact,
      type: values.type,
      bio: values.bio,
    }

    const formData = new FormData();
    formData.append("orgImage", renderImgFile);
    formData.append("orgDocument", values.file2);
    formData.append("org", new Blob([JSON.stringify(requestOrg)], {type : "application/json"}));

    try{
      const response = await axios.post(`${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/org`, formData, {headers : {"Content-type" : "multipart/form-data"}});

      if(response.status === 201){
        // 성공시, 쿠키 삭제
        removeCookie("___seochoVolunteer__org___signup___nickname___");
        removeCookie("___seochoVolunteer__org___signup___pwd___");
        removeCookie("___seochoVolunteer__org___signup___email___");

        window.sessionStorage.setItem("idx", response.data.id);
        sessionStorage.setItem("orgStatus", "W");

        navigate("/common/org-login/waiting", { state: {} });
      }
    }catch(e){
      console.log(e);
      alert("기관 사용자 가입에 실패하였습니다.");
    }
  };

  const [renderImgFile, setRenderImagFile] = useState(null);
  const [renderImgUrl, setRenderImagUrl] = useState(
    "/assets/images/photos/img-p-org.jpg"
  );

  const handleChangeForm = (e) => {
    if (e.target.name === "file") {
      // 파일 객체
      const file = e.target.files[0];
      if (file) {
        // 파일 확장자 추출
        const fileLen = file.name.length;
        const lastDotIdx = file.name.lastIndexOf(".");
        const fileEx = file.name.substring(lastDotIdx, fileLen).toLowerCase();

        // 파일 크기
        const fileSize = file.size;
        if (fileEx !== ".jpg" && fileEx !== ".jpeg" && fileEx !== ".png") {
          alert("이미지 확장자는 .jpg, .jpeg, .png 만 업로드가 가능합니다.");
          return;
        }

        if (fileSize > 10000000) {
          alert("이미지 파일크기는 10M를 초과할 수 없습니다.");
          return;
        }

        setImageFromFile(file);

        setValues({
          ...values,
          [e.target.name]: file,
        });
      }
    } else if (e.target.name === "file2") {
      // 파일 객체
      const file = e.target.files[0];
      if (file) {
        // 파일 확장자
        const fileEx = file.name.split(".")[1];

        // 파일 크기
        const fileSize = file.size;

        if (fileSize > 10000000) {
          alert("등록증명서 파일크기는 10M를 초과할 수 없습니다.");
          return;
        }

        setValues({
          ...values,
          [e.target.name]: file,
        });
      }
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setImageFromFile = (file) => {
    let reader = new FileReader();
    reader.onload = function () {
      setRenderImagFile(file);
      setRenderImagUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // 다음주소찾기 모달 열기, 닫기
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const daumPostModalOpenHandler = () => {
    openModal(true);
  };

  const delCategoryClickHandler = () => {
    setAddedCategoryData(
      addedCategoryData.filter(
        (addedCategory) => addedCategory.idx !== delCategoryIdx
      )
    );
  };

  const [showFileModal, setShowFileModal] = useState(false);
  const toggleFileModal = () => {
    setShowFileModal(!showFileModal);
  };
  const getFile = (file, name, imgUrl, type) => {
    if (type === "img") {
      // 파일 객체
      if (file) {
        setImageFromFile(file);
        setValues({
          ...values,
          file: file,
        });
      } else {
      }
    }

    toggleFileModal();
  };

  useEffect(() => {
    getCategoryData();

    return (() => {
      // 페이지 빠져나갈 시에 쿠키 삭제
      removeCookie("___seochoVolunteer__org___signup___nickname___");
      removeCookie("___seochoVolunteer__org___signup___pwd___");
      removeCookie("___seochoVolunteer__org___signup___email___");
    });
  }, []);

  if (showFileModal) {
    return (
      <FileUpload
        type="img"
        getFile={getFile}
        toggleFileModal={toggleFileModal}
      />
    );
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        <Modal
          open={modalOpen}
          close={closeModal}
          setModalOpen={setModalOpen}
          setAddress={setAddress1}
          setPostcode={setPostcode}
        />
        {/* HEADER */}
        <SubHeader title="기관 등록 요청" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          <div className="fieldsets mt-48">
            <div className="user-profile__thumb">
              {/* <img src="../../assets/images/photos/img-p-org.jpg" alt="" /> */}
              <img
                src={renderImgUrl}
                alt={renderImgFile && renderImgFile.name}
              />
            </div>

            {/* 반려 */}
            {sessionStorage && sessionStorage.orgStatus === "N" ? (
              <div className="cards cards--6" id="loadlist-01">
                <div className="table card card--style-thumb mb-20 mt-100">
                  <div className="card__details text-aline-center">
                    <h2 className="point-red">
                      기관 등록요청이 반려 되었습니다.
                    </h2>
                    <p className="point-red">반려 사유입니다.</p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="upload-filebox mt-20">
            <form id="_frm" method="post" enctype="multipart/form-data">
              <label htmlFor="ex_file">기관 이미지 업로드</label>
              <input
                type={"file"}
                id="ex_file"
                accept=".jpg, .jpeg, .png"
                capture="camera"
                name="file"
                onChange={handleChangeForm}
              />
            </form>
          </div>

          <div className="fieldsets mt-48">
            <div className="d-flex justify-space align-items-center">
              <h2 className="mb-0">기관/단체 정보</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <h4>기관/단체 이름</h4>
                <div className="form__row mb-20">
                  <input
                    type="text"
                    name="orgTitle"
                    value={values.orgTitle}
                    onChange={handleChangeForm}
                    className="form__input required"
                    placeholder="입력"
                  />
                </div>
                <h4>주소</h4>
                <div className="form__row d-flex align-items-center justify-space mb-20">
                  <div className="form__select form__input form__select--23 mr-16">
                    <input
                      type="text"
                      name="address1"
                      value={address1}
                      onChange={handleChangeForm}
                      onClick={daumPostModalOpenHandler}
                      className="form__input required"
                      placeholder="주소"
                    />
                  </div>
                  <div className="form__button--13">
                    <input
                      type="button"
                      name="address1"
                      className="form__submit button button--main"
                      id="submit"
                      value="검색"
                      onClick={daumPostModalOpenHandler}
                    />
                  </div>
                </div>
                <h4>상세 주소</h4>
                <div className="form__row mb-20">
                  <input
                    type="text"
                    name="address2"
                    value={values.address2}
                    onChange={handleChangeForm}
                    className="form__input required"
                    placeholder="입력"
                  />
                </div>
                <h4>연락처</h4>
                <div className="form__row mb-20">
                  <input
                    type="tel"
                    name="contact"
                    value={values.contact}
                    onChange={handleChangeForm}
                    className="form__input required"
                    placeholder="ex
           010-1234-5678"
                  />
                </div>
                <h4>기관/단체 등록증명서</h4>
                <div className="form__row d-flex align-items-center justify-space  mb-20">
                  <div className="form__select form__input form__select--23 mr-16">
                    <input
                      type="text"
                      name="file2"
                      className="form__input required"
                      placeholder=""
                      value={values.file2.name}
                      readOnly
                    />
                  </div>
                  <div className="form__button--13">
                    <div className="upload-filebox mt-20">
                      <form
                        id="__frm"
                        method="post"
                        enctype="multipart/form-data"
                      >
                        <label
                          htmlFor="ex_file2"
                          style={{ border: "none" }}
                          className="form__submit button button--main"
                        >
                          파일선택
                        </label>
                        <input
                          type="file"
                          id="ex_file2"
                          name="file2"
                          onChange={handleChangeForm}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                {/* <h4>이메일</h4>
                    <div className="form__row mb-20">
                      <input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChangeForm}
                        className="form__input required"
                        placeholder="입력"
                      />
                    </div> */}

                <h4>기관 타입</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select
                      name="type"
                      className="required"
                      value={values.type}
                      onChange={handleChangeForm}
                    >
                      <option value="" disabled selected>
                        선택
                      </option>
                      <option value="C">활동기관</option>
                      <option value="A">수요기관</option>
                      <option value="CA">활동, 수요기관</option>
                    </select>
                  </div>
                </div>
                <h4>카테고리</h4>
                <div className="form__row d-flex align-items-center justify-space  mb-20">
                  <div className="form__select form__input form__select--23 mr-16">
                    <select
                      name="categoryIdx"
                      className="required"
                      value={values.categoryIdx}
                      onChange={handleChangeForm}
                    >
                      <option value="" disabled>
                        선택
                      </option>
                      {categoryData &&
                        categoryData.map((category, idx) => (
                          <option key={idx} value={category.idx}>
                            {category.category}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form__button--13">
                    <input
                      type="button"
                      name="categoryname"
                      onClick={() => addCategory(values.categoryIdx)}
                      className="form__submit button button--main"
                      id="submit"
                      value="추가"
                    />
                  </div>
                </div>
                <div className="cards cards--14">
                  {addedCategoryData &&
                    addedCategoryData.map((category, idx) => (
                      <CategoryList
                        key={idx}
                        category={category}
                        setDelCategoryIdx={setDelCategoryIdx}
                      />
                    ))}
                </div>
                <h4>소개</h4>
                <div className="form__row">
                  <textarea
                    name="bio"
                    className="form__textarea required"
                    placeholder="내용을 기입해주세요."
                    value={values.bio}
                    onChange={handleChangeForm}
                  />
                </div>
                <div className="form mt-48">
                  <h4>공개여부</h4>
                  <div className="form__select">
                    <select
                      name="openStatus"
                      className="required"
                      value={values.openStatus}
                      onChange={handleChangeForm}
                    >
                      <option value="" disabled selected>
                        선택
                      </option>
                      <option value="O">공개</option>
                      <option value="C">비공개</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="form__row mt-40">
            <input
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              data-popup="conform_save"
              className="form__submit button button--main button--50 open-popup"
              id="submit"
              value="저장"
            />
          </div>
          <div className="space-h-100" />
        </div>
        <ConformPopup
          popupType="delete"
          text="정말 삭제하시겠습니까?"
          conformClick={delCategoryClickHandler}
        />
        <ConformPopup
          popupType="cancle"
          text="정말 취소하시겠습니까?"
          conformClick={() => navigate(-1)}
        />
        <ConformPopup
          popupType="save"
          text="해당 내용을 저장하시겠습니까?"
          conformClick={requestorg}
        />
      </div>
    </Fragment>
  );
}

export default RequestOrgConform;
