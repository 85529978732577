import React from "react";
import { useNavigate } from "react-router-dom";
import useConfirm from "../../util/cofirm-alert";
import isMobile, { isAndroid, isIos } from "../../util/mobile-function";

const StartedSchedule = ({ scheduleInfo, joinAct, cancelJoinedAct }) => {
  const navigate = useNavigate();

  const chatdgroupClickHandler = () => {
    alert("채팅방 입장이 어렵습니다.");

    return;
    // navigate("/org/chat/chatd/a2g", {
    //   state: {
    //     activityId: scheduleInfo.actId,
    //     groupId: scheduleInfo.scheduleId,
    //   },
    // });
  };

  const actdetailClickHandler = () => {
    navigate(`/org/profile/actdetail/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const detailpartClickHandler = () => {
    navigate(`/org/activity/detailpart/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const detailbeneClickHandler = () => {
    navigate(`/org/activity/detailbene/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const listactivityClickHandler = () => {
    navigate(
      `/org/profile/listactivity/${scheduleInfo.actId}/${scheduleInfo.scheduleId}`,
      {
        state: {},
      }
    );
  };

  const joinWithQrCode = () => {
    if (
      isAndroid() &&
      typeof window.Android !== "undefined" &&
      window.Android !== null
    ) {
      window.Android.callQrcode();
    } else if (isIos()) {
      try {
        window.webkit.messageHandlers.callbackHandler.postMessage(
          "uid=" +
          window.sessionStorage.getItem("idx") +
          "&token=" +
          "temp_token"
        );
      } catch (err) {
        alert(err);
      }
    }

    // navigate(`/sole/schedule/scancode/${scheduleInfo.scheduleId}`, {
    //   state: {},
    // });
  };

  const setLabel = () => {
    if (scheduleInfo.type === "BENE") {
      return (
        <span className="caption__category tag_bene card__list--centered">
          활동 수요자
        </span>
      );
    } else {
      if (scheduleInfo.orgType === "O") {
        return (
          <span className="caption__category card__list--centered tag_operator">
            주관
          </span>
        );
      } else {
        return (
          <span className="caption__category tag_part card__list--centered">
            활동 활동자
          </span>
        );
      }
    }
  };

  const setButtonGroup = () => {
    if (scheduleInfo.type === "BENE") {
      return (
        <div className="form__row mt-40">
          <input
            onClick={detailbeneClickHandler}
            style={{ cursor: "pointer" }}
            type="submit"
            name="submit"
            className="form__submit button button--main button--full"
            id="submit"
            value="활동보기"
          />
        </div>
      );
    } else {
      if (scheduleInfo.orgType === "O") {
        return (
          <div className="form__row mt-40">
            {/* <input
              onClick={chatdgroupClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--333"
              id="submit"
              value="그룹 메세지"
            /> */}
            <input
              onClick={actdetailClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--50"
              id="submit"
              value="활동보기"
            />
            {scheduleInfo.joinType === "Q" && (
              <input
                onClick={listactivityClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--50"
                id="submit"
                value="QR코드 보기"
              />
            )}
          </div>
        );
      } else {
        return (
          <div className="form__row mt-40">
            <input
              onClick={onClickCancel}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--secondary button--333"
              id="submit"
              value="취소하기"
            />
            <input
              onClick={detailpartClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--333"
              id="submit"
              value="활동보기"
            />
            {scheduleInfo.joinType === "A" ? (
              <input
                onClick={onClickJoinAct}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--333"
                id="submit"
                value="지금 참여하기"
              />
            ) : (
              <input
                onClick={joinWithQrCode}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--333"
                id="submit"
                value="QR인증 참여하기"
              />
            )}
          </div>
        );
      }
    }
  };

  const onHandleJoinAct = () => {
    joinAct(scheduleInfo.scheduleId);
  };

  const onClickJoinAct = useConfirm(
    `"${scheduleInfo.activityTitle}(${scheduleInfo.startTime})" 활동에 참여하시겠습니까?`,
    onHandleJoinAct
  );

  const onHandleCancelAct = () => {
    cancelJoinedAct(scheduleInfo.scheduleId);
  };

  const onClickCancel = useConfirm(
    `"${scheduleInfo.activityTitle}(${scheduleInfo.startTime})" 활동 참여를 취소하시겠습니까?`,
    onHandleCancelAct
  );

  return (
    <div className="card card--style-icon card--style-round-corners">
      <h2 className="text-aline-center">알림!</h2>
      <h4 className="card__list card__list--centered fc_point1">
        {scheduleInfo.activityTitle}
      </h4>
      <h4 className="card__list card__list--centered">
        활동이 시작되었습니다.
      </h4>
      <h4 className="card__list--centered">
        시작 시간 : {scheduleInfo.startTime}
      </h4>
      {setLabel()}
      {setButtonGroup()}
    </div>
  );
};

export default StartedSchedule;
