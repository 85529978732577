import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import PartUserListItem from "../../../components/PartUserListItem";
import UserListHeader from "../../../components/UserListHeader";
import PartGroupListItem from "../../../components/PartGroupListItem";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";

function UserListPart(props) {
  const { scheduleId } = useParams();

  const [scheduleInfo, setScheduleInfo] = useState({
    actIdx: null,
    date: null,
    time: null,
  });

  const [partUserList, setPartUserList] = useState(null);
  const [partGroupList, setPartGroupList] = useState(null);
  const [nonePartUserList, setNonePartUserList] = useState(null);
  const [nonePartGroupList, setNonePartGroupList] = useState(null);

  const [selectedInfo, setSelectedInfo] = useState({
    idx: null,
    status: null,
  });

  const setSeletedInfo = (selectedIdx, status) => {
    setSelectedInfo({
      idx: selectedIdx,
      status: status,
    });
  };

  // 불참으로 상태 변경
  const updateStatusToNonePart = async (idx) => {
    // if (selectedInfo.idx === null || selectedInfo.status === null) {
    //   return;
    // }

    let data = new Object();
    data.updatedUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-list/none-part/${idx}`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        getParticipantList();
        getNoneParticipantList();
      }
    } catch (e) {
      alert("참여 상태 변경 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  // 불참 취소(대기) 상태 변경
  const updateStatusToCancelNonePart = async (idx) => {
    // if (selectedInfo.idx === null || selectedInfo.status === null) {
    //   return;
    // }

    let data = new Object();

    data.updatedUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act-list/cancel/none-part/${idx}`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        getParticipantList();
        getNoneParticipantList();
      }
    } catch (e) {
      alert("참여 상태 변경 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const getParticipantList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/part/${scheduleId}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const { partUserList, partGroupList } = response.data;

        let userList = [];
        let groupList = [];

        for (let i = 0; i < partUserList.length; i++) {
          userList.push({
            actListIdx: partUserList[i].id,
            userIdx: partUserList[i].userIdx,
            img: Object.keys(partUserList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partUserList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            nickName: partUserList[i].nickname,
            contact: partUserList[i].contact,
            status: partUserList[i].volunteer,
          });
        }

        setPartUserList(userList);

        for (let i = 0; i < partGroupList.length; i++) {
          groupList.push({
            userIdx: partGroupList[i].userIdx,
            nickname: partGroupList[i].nickname,
            contact: partGroupList[i].contact,
            volunteer: partGroupList[i].volunteer,
            actListIdx: partGroupList[i].actListIdx,
            groupName: partGroupList[i].groupName,
            orgIdx: partGroupList[i].orgIdx,
            orgTitle: partGroupList[i].orgTitle,
            img: Object.keys(partGroupList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partGroupList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
          });
        }

        setPartGroupList(groupList);
      }
    } catch (e) {
      console.log(e);
      alert("");
    }
  }, [scheduleId]);

  const getNoneParticipantList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/none-part/${scheduleId}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const { partUserList, partGroupList } = response.data;

        let userList = [];
        let groupList = [];

        for (let i = 0; i < partUserList.length; i++) {
          userList.push({
            actListIdx: partUserList[i].id,
            userIdx: partUserList[i].userIdx,
            img: Object.keys(partUserList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partUserList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            nickName: partUserList[i].nickname,
            contact: partUserList[i].contact,
            status: partUserList[i].volunteer,
          });
        }

        setNonePartUserList(userList);

        for (let i = 0; i < partGroupList.length; i++) {
          groupList.push({
            userIdx: partGroupList[i].userIdx,
            nickname: partGroupList[i].nickname,
            contact: partGroupList[i].contact,
            volunteer: partGroupList[i].volunteer,
            actListIdx: partGroupList[i].actListIdx,
            groupName: partGroupList[i].groupName,
            orgIdx: partGroupList[i].orgIdx,
            orgTitle: partGroupList[i].orgTitle,
            img: Object.keys(partGroupList[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${partGroupList[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
          });
        }

        setNonePartGroupList(groupList);
      }
    } catch (e) {
      console.log(e);
      alert("");
    }
  }, [scheduleId]);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getScheduleInfo();
        getParticipantList();
        getNoneParticipantList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getScheduleInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/${scheduleId}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setScheduleInfo({
            actIdx: response.data.activityIdx,
            date: response.data.activityDate,
            time: response.data.activityTime,
          });
        }
      } catch (e) {
        alert("스케줄 정보 조회에 실패하였습니다.");
        console.log(e);
      }
    };
  }, [getNoneParticipantList, getParticipantList, scheduleId]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <UserListHeader title="활동 상세" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar mb-24">
              <h1 className="page__title">참여 중</h1>
            </div>
            <div className="cards cards--11">
              {partGroupList &&
                partGroupList.map((userInfo) => (
                  <PartGroupListItem
                    userInfo={userInfo}
                    activityIdx={scheduleInfo.actIdx}
                    scheduleInfo={scheduleInfo}
                    setSeletedInfo={setSeletedInfo}
                    updateStatusToCancelNonePart={updateStatusToCancelNonePart}
                    updateStatusToNonePart={updateStatusToNonePart}
                    key={userInfo.actListIdx}
                  />
                ))}
              {partUserList &&
                partUserList.map((userInfo) => (
                  <PartUserListItem
                    userInfo={userInfo}
                    activityIdx={scheduleInfo.actIdx}
                    scheduleInfo={scheduleInfo}
                    setSeletedInfo={setSeletedInfo}
                    updateStatusToCancelNonePart={updateStatusToCancelNonePart}
                    updateStatusToNonePart={updateStatusToNonePart}
                    key={userInfo.idx}
                  />
                ))}
            </div>
          </div>
          <div className="page_section">
            <div className="page__title-bar mt-48 mb-24">
              <h1 className="page__title">미참여</h1>
            </div>
            <div className="cards cards--11">
              {nonePartGroupList &&
                nonePartGroupList.map((userInfo) => (
                  <PartGroupListItem
                    userInfo={userInfo}
                    activityIdx={scheduleInfo.actIdx}
                    scheduleInfo={scheduleInfo}
                    setSeletedInfo={setSeletedInfo}
                    updateStatusToCancelNonePart={updateStatusToCancelNonePart}
                    updateStatusToNonePart={updateStatusToNonePart}
                    key={userInfo.actListIdx}
                  />
                ))}
              {nonePartUserList &&
                nonePartUserList.map((userInfo) => (
                  <PartUserListItem
                    userInfo={userInfo}
                    activityIdx={scheduleInfo.actIdx}
                    scheduleInfo={scheduleInfo}
                    setSeletedInfo={setSeletedInfo}
                    updateStatusToCancelNonePart={updateStatusToCancelNonePart}
                    updateStatusToNonePart={updateStatusToNonePart}
                    key={userInfo.idx}
                  />
                ))}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
      <ConformPopup
        popupType="cancle"
        text="결석 처리를 취소하시겠습니까?"
        conformClick={updateStatusToCancelNonePart}
      />
      <ConformPopup
        popupType="absent"
        text="결석 처리하시겠습니까?"
        conformClick={updateStatusToNonePart}
      />
    </Fragment>
  );
}

export default UserListPart;
