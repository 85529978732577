import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";

import ActivityItem from "../../../components/ActivityItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

const viewTagList = ["activityType", "helpStatus"];

function RequestToParticipateList(props) {
  const [totalRows, setTotalRows] = useState(0);
  const [conformList, setConformList] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);

  const increasePageNumber = () => {
    setPageNumber(pageNumber + 1);
  };

  const getRequestList = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/request/help/org/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: pageNumber,
          count: 6,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            activityScheduleIdx: data[i].activityScheduleIdx, // activity schedule idx
            helpIdx: data[i].helpIdx, // help_idx
            idx: data[i].activityIdx, // activity idx
            img: Object.keys(data[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_API}data[i].img`
              : `${process.env.PUBLIC_URL}/assets/images/food/img-default.jpg`,
            orgTitle: data[i].orgTitle,
            orgIdx: data[i].orgIdx,
            title: data[i].title,
            activityDate: Object.keys(data[i]).includes("activityDate")
              ? data[i].activityDate
              : "",
            activityTime: Object.keys(data[i]).includes("activityTime")
              ? data[i].activityTime
              : "",
            address1: data[i].address1,
            address2: Object.keys(data[i]).includes("address2")
              ? data[i].address2
              : "",
            activityType: data[i].activityType,
            isPrivate: data[i].isPrivate,
            beneType: data[i].beneType,
            partType: data[i].partType,
            helpStatus: data[i].helpStatus,
          });
        }

        setTotalRows(totalRows);

        if (pageNumber === 1) {
          setConformList(ary);
        } else {
          setConformList(conformList.concat(ary));
        }
      }
    } catch (e) {
      alert("도움 요청 목록 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [pageNumber]);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getRequestList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getRequestList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="참여 요청 받은 활동" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header">
          <div className="search__form">
            <form>
              <input
                type="text"
                className="search__input"
                value=""
                placeholder="검색..."
              />
              <input type="submit" className="search__submit" value="Send" />
            </form>
          </div>
          <div className="cards cards--12" id="loadlist-01">
            {conformList &&
              conformList.map((activity) => (
                <ActivityItem
                  url={`/org/profile/requestoperationdetailconform/${activity.idx}`}
                  activity={activity}
                  folder="org"
                  viewTagList={viewTagList}
                  key={activity.helpIdx}
                />
              ))}
          </div>
          {totalRows > conformList.length && (
            <div className="buttons buttons--centered mb-20">
              <div className="button button--more" onClick={increasePageNumber}>
                더 보기
              </div>
            </div>
          )}
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default RequestToParticipateList;
