import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tag from "./Tag";

function MyScheduleGroupList(props) {
  const {
    type,
    folder,
    scheduleGroup,
    setSelGroupName,
    setSelActScheduleIdx,
    setSelActListIdx,
    setSelOrgIdx,
  } = props;

  const changeSelGroupNameAndActScheduleIdx = () => {
    setSelOrgIdx(scheduleGroup.activityOrgIdx);
    setSelGroupName(scheduleGroup.groupListName);
    setSelActScheduleIdx(scheduleGroup.activityScheduleIdx);
    setSelActListIdx(scheduleGroup.actListIdx);
  };

  console.log(scheduleGroup);

  const viewStatusAndButton = () => {
    if (folder === "org" || scheduleGroup.groupNum === 0) {
      if (type === "confirm") {
        return (
          <div>
            <div
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_partcancel"
              onClick={changeSelGroupNameAndActScheduleIdx}
            >
              참여 취소
            </div>
            <br />
          </div>
        );
      } else if (type === "request") {
        switch (scheduleGroup.volunteer) {
          case "R":
            return (
              <div>
                <Tag tagName="반려" />
                <div
                  className="button button--small button--secondary float-right ml-10 open-popup"
                  data-popup="conform_reParticipation"
                  onClick={changeSelGroupNameAndActScheduleIdx}
                >
                  재신청
                </div>
              </div>
            );
          case "W":
            return (
              <div>
                <Tag tagName="대기 중" />
                <div
                  className="button button--small button--secondary float-right ml-10 open-popup"
                  data-popup="conform_requestcancel"
                  onClick={changeSelGroupNameAndActScheduleIdx}
                >
                  신청 취소
                </div>
              </div>
            );
          case "C":
            return (
              <div>
                <Tag tagName="참여 취소" />
                <div
                  className="button button--small button--secondary float-right ml-10 open-popup"
                  data-popup="conform_reParticipation"
                  onClick={changeSelGroupNameAndActScheduleIdx}
                >
                  재신청
                </div>
              </div>
            );
          default:
            break;
        }
      }
    } else if (folder === "sole") {
      if (type === "request") {
        switch (scheduleGroup.volunteer) {
          case "R":
            return (
              <div>
                <Tag tagName="반려" />
              </div>
            );
          case "W":
            return (
              <div>
                <Tag tagName="대기 중" />
              </div>
            );
          default:
            break;
        }
      }
    }
  };

  const dateform = (date) => {
    const changedate = new Date(date);
    let year = changedate.getFullYear();
    let month = changedate.getMonth() + 1;
    let day = changedate.getDate();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;

    return `${year}년 ${month}월 ${day}일`;
  };

  const [cardBorderCss, setCardBorderCss] = useState("card_border");

  useEffect(() => {
    if (type === "request") {
      setCardBorderCss("");
    }
  }, [type]);

  const viewGroupInfo = () => {
    if (scheduleGroup.groupListName !== "0") {
      return (
        <div>
          <p className="card__list_g">
            {scheduleGroup &&
              `${scheduleGroup.groupListName}(${scheduleGroup.groupNum})`}
            <Link
              className="ml-10"
              to={`/${folder}/activity/groupmember`}
              state={{
                groupName: scheduleGroup && scheduleGroup.groupListName,
                activityScheduleIdx:
                  scheduleGroup && scheduleGroup.activityScheduleIdx,
                groupOrgIdx: scheduleGroup && scheduleGroup.groupOrgIdx,
              }}
            >
              그룹 회원 보기
            </Link>
          </p>
          <p className="card__info">연락처 : 010-0000-0000</p>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <div className={`table card ${cardBorderCss} card--style-thumb mb-20`}>
        <div className="card__details">
          <h2 className="fc_point1">
            {scheduleGroup &&
              `${dateform(scheduleGroup.activityDate)} ${
                scheduleGroup.activityTime
              }`}
          </h2>
          <p className="card__list">
            활동 인원 :{" "}
            <a>
              {scheduleGroup &&
                `참여(지정) ${scheduleGroup.recruitedNum}명 / ${
                  scheduleGroup.recruitNum === "0"
                    ? "제한없음"
                    : `필요인원 ${scheduleGroup.recruitNum}명`
                } (${scheduleGroup.recruit})`}
            </a>
          </p>
          <p className="card__list">{`활동시간 : ${scheduleGroup.activityTime} ~ ${scheduleGroup.activityTimeEnd}`}</p>
          <p className="card__list">
            수요자 :{" "}
            <Link
              to={`/${folder}/otherprofile/org/${scheduleGroup.activityOrgIdx}`}
            >
              {scheduleGroup && scheduleGroup.orgTitle}
            </Link>
          </p>
          {viewStatusAndButton()}
          {viewGroupInfo()}
        </div>
      </div>
    </Fragment>
  );
}

export default MyScheduleGroupList;
