import React from 'react';
import { Fragment } from 'react';

function Panel(props) {
  return (
    <Fragment>
      {/* <!-- Overlay panel --> */}
      <div className="body-overlay"></div>
      {/* <!-- Left panel --> */}
      <div id="panel-left"></div>
      {/* <!-- Right panel --> */}
      <div id="panel-right"></div>
    </Fragment>
  );
}

export default Panel;
