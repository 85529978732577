import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// org
import HomeOrg from "./pages/org/home/Org";
import HomeCategory from "./pages/org/home/Category";
import HomeSearch from "./pages/org/home/Search";
import NotiNotification from "./pages/org/notification/Notification";
import OtprActivityRequest from "./pages/org/other-profile/ActivityRequest";
import OtprOrg from "./pages/org/other-profile/Org";
import OtprScheduleList from "./pages/org/other-profile/ScheduleList";
import PfActDetail from "./pages/org/profile/ActDetail";
import OtprSole from "./pages/org/other-profile/Sole";
import PfAddGroup from "./pages/org/profile/AddGroup";
import PfCalendar from "./pages/org/profile/Calendar";
import PfGroupActHistory from "./pages/org/profile/GroupActHistory";
import PfGroupDetail from "./pages/org/profile/GroupDetail";
import PfListActivityFinished from "./pages/org/profile/ListActivityFinished";
import PfListActivityHistoryAll from "./pages/org/profile/ListActivityHistoryAll";
import PfListActivityHistory from "./pages/org/profile/ListActivityHistory";
import PfListActivity from "./pages/org/profile/ListActivity";
import PfListApplicationPart from "./pages/org/profile/ListApplicationPart";
import PfListRequest from "./pages/org/profile/ListRequest";
import PfOrg from "./pages/org/profile/Org";
import PfRegisterRequested from "./pages/org/profile/RegisterRequested";
import PfReportDetail from "./pages/org/profile/ReportDetail";
import PfRequestOperationConformList from "./pages/org/profile/RequestOperationConformList";
import PfRequestOperationDetailConformDismissal from "./pages/org/profile/RequestOperationDetailConformDismissal";
import PfRequestOperationDetailConformPassed from "./pages/org/profile/RequestOperationDetailConformPassed";
import PfRequestOperationDetailConform from "./pages/org/profile/RequestOperationDetailConform";
import PfRequestOperationDetail from "./pages/org/profile/RequestOperationDetail";
import PfRequestOperation from "./pages/org/profile/RequestOperation";
import PfRequestToParticipateList from "./pages/org/profile/RequestToParticipateList";
import PfScheduleList from "./pages/org/profile/ScheduleList";
import PfUserSearch from "./pages/org/profile/UserSearch";
import ActiCalendar from "./pages/org/activity/Calendar";
import ActiChooseMemberGroup from "./pages/org/activity/ChooseMemberGroup";
import ActiDetailBene from "./pages/org/activity/DetailBene";
import ActiDetailCarriedOut from "./pages/org/activity/DetailCarriedOut";
import ActiDetailPart from "./pages/org/activity/DetailPart";
import ActiGroupMember from "./pages/org/activity/GroupMember";
import ActiPlacingMember from "./pages/org/activity/PlacingMember";
import ActiScheduleList from "./pages/org/activity/ScheduleList";
import ActiScheduleList2 from "./pages/org/activity/ScheduleList2";
import ChatChatDAll from "./pages/org/chat/ChatDAll";
import ChatChatDGroup from "./pages/org/chat/ChatDGroup";
import ChatChatD from "./pages/org/chat/ChatD";
import ChatChat, { Chat } from "./pages/org/chat/Chat";
import ChatUserListAll from "./pages/org/chat/UserListAll";
import ChatUserListGroup from "./pages/org/chat/UserListGroup";
import CommDismissal from "./pages/org/common/Dismissal";
import CommUserAdd from "./pages/org/common/UserAdd";
import PraaAddAct from "./pages/org/profile-add-act/AddAct";
import PraaAddActScList from "./pages/org/profile-add-act/AddActScList";
import PraaScheduleList from "./pages/org/profile-add-act/ScheduleList";
import PredAlert from "./pages/org/profile-edit/Alert";
import PredEdit from "./pages/org/profile-edit/Edit";
import PredSetting from "./pages/org/profile-edit/Setting";
import ScdSchedule from "./pages/org/schedule/Schedule";
import ScdUserListPart from "./pages/org/schedule/UserListPart";

// sole
import SActiCalendar from "./pages/sole/activity/Calendar";
import SActiDetailBene from "./pages/sole/activity/DetailBene";
import SActiDetailCarriedOut from "./pages/sole/activity/DetailCarriedOut";
import SActiDetailPart from "./pages/sole/activity/DetailPart";
import SActiGroupMember from "./pages/sole/activity/GroupMember";
import SActiScheduleList from "./pages/sole/activity/ScheduleList";
import SChatChatDAll from "./pages/sole/chat/ChatDAll";
import SChatChatDGroup from "./pages/sole/chat/ChatDGroup";
import SChatChatD from "./pages/sole/chat/ChatD";
import SChatChat from "./pages/sole/chat/Chat";
import SOkphDetail from "./pages/sole/ok-phone/Detail";
import SOkphScheduleList from "./pages/sole/ok-phone/ScheduleList";
import SOkphSelectSchedule from "./pages/sole/ok-phone/SelectSchedule";
import SPredAlert from "./pages/sole/profile-edit/Alert";
import SpredSetting from "./pages/sole/profile-edit/Setting";
import SpredEventMatching from "./pages/sole/profile-edit/EventMatching";
import SPrexExpert from "./pages/sole/profile-expert/Expert";
import SPrexExpertEdit from "./pages/sole/profile-expert/ExpertEdit";
import SPrexOkOnlineAnswer from "./pages/sole/profile-expert/OkOnlineAnswer";
import SPrexOkOnlineReportD from "./pages/sole/profile-expert/OkOnlineReportD";
import SPrexOkOnlineReportList from "./pages/sole/profile-expert/OkOnlineReportList";
import SPrexOkPhoneReport from "./pages/sole/profile-expert/OkPhoneReport";
import SPrexOkPhoneReportD from "./pages/sole/profile-expert/OkPhoneReportD";
import SPrexOkPhoneReportList from "./pages/sole/profile-expert/OkPhoneReportList";
import SScdReport from "./pages/sole/schedule/Report";
import SScdSchedule from "./pages/sole/schedule/Schedule";
import ShomeCategoryBene from "./pages/sole/home/CategoryBene";
import ShomeCategoryPart from "./pages/sole/home/CategoryPart";
import ShomeSearchBene from "./pages/sole/home/SearchBene";
import ShomeSearchPart from "./pages/sole/home/SearchPart";
import ShomeSoleBene from "./pages/sole/home/SoleBene";
import ShomeSolePart from "./pages/sole/home/SolePart";
import SNotiNotification from "./pages/sole/notification/Notification";
import SOkonDetail from "./pages/sole/ok-online/Detail";
import SOkonDetailA from "./pages/sole/ok-online/DetailA";
import SOkonDetailAD from "./pages/sole/ok-online/DetailAD";
import SOkonDetailDefault from "./pages/sole/ok-online/DetailDefault";
import SOtprExpert from "./pages/sole/other-profile/Expert";
import SOtprOrg from "./pages/sole/other-profile/Org";
import SOtprSole from "./pages/sole/other-profile/Sole";
import SPrsoActivityHistory from "./pages/sole/profile-sole/ActivityHistory";
import SPrsoActRequestApplication from "./pages/sole/profile-sole/ActRequestApplication";
import SPrsoOkHistory from "./pages/sole/profile-sole/OkHistory";
import SPrsoOrgList from "./pages/sole/profile-sole/OrgList";
import SPrsoRequestOperationList from "./pages/sole/profile-sole/RequestOperationList";
import SPrsoRequestOrg from "./pages/sole/profile-sole/RequestOrg";
import SPrsoRequestOrgConform from "./pages/sole/profile-sole/RequestOrgConform";
import SPrsoSole from "./pages/sole/profile-sole/Sole";
import SPrsoSoleEdit from "./pages/sole/profile-sole/SoleEdit";
import ForgotIdD from "./pages/common/ForgotIdD";
import ForgotId from "./pages/common/ForgotId";
import ForgotPassword from "./pages/common/ForgotPassword";
import Login from "./pages/common/Login";
import PasswordSetting from "./pages/common/PasswordSetting";
import Signup from "./pages/common/Signup";
import ConformSave from "./pages/common/popup/ConformSave";

import UrlProvider from "./components/UrlProvider/UrlProvider";
import ScheduleProvider from "./components/ScheduleProvider/ScheduleProvider";
import ActivityProvider from "./components/ActivityProvider/ActivityProvider";
import GroupProvider from "./components/GroupProvider/GroupProvider";
import EventMactching from "./pages/org/profile-edit/EventMactching";
import EditAct from "./pages/org/profile-add-act/EditAct";
import OtprOrgPrivate from "./pages/org/other-profile/OrgPrivate";
import OtprSolePrivate from "./pages/org/other-profile/SolePrivate";

import SOtprOrgPrivate from "./pages/sole/other-profile/SolePrivate";

import ConComDictionary from "./pages/common/community/Dictionary";
import ConComFaqDetail from "./pages/common/community/FaqDetail";
import ConComFaq from "./pages/common/community/Faq";
import ConComHelpDetail from "./pages/common/community/HelpDetail";
import ConComHelp from "./pages/common/community/Help";
import ConComNoticeDetail from "./pages/common/community/NoticeDetail";
import ConComNotice from "./pages/common/community/Notice";
import ConComPrivacyPolicy from "./pages/common/community/PrivacyPolicy";
import ConComWithdrawal from "./pages/common/community/Withdrawal";
import ConComQna from "./pages/common/community/Qna";
import ConComQnaDetail from "./pages/common/community/QnaDetail";
import ConComQnaDetailMine from "./pages/common/community/QnaDetailMine";
import ConComQnaWrite from "./pages/common/community/QnaWrite";
import ConComTermsOfUse from "./pages/common/community/TermsOfUse";
import QnaEdit from "./pages/common/community/QnaEdit";
import ScanCode from "./pages/org/schedule/ScanCode";
import AddReport from "./pages/sole/schedule/AddReport";
import ReportReadOnly from "./pages/sole/schedule/ReportReadOnly";
import LoginTest from "./pages/common/LoginTest";

import SignUpTest from "./pages/common/Signup_test";
import OrgLoginW from "./pages/common/OrgLoginW";
import PrivacyPolicy from "./pages/common/PrivacyPolicy";
import PlacingIndividualMember from "./pages/org/activity/PlacingIndividualMember";
import ScheduleAdd from "./pages/org/profile-add-act/ScheduleAdd";
import FileUpload from "./pages/common/FileUpload";

// import ActStatstics from "./pages/org/profile/ActStatistics";
import DetailBeneQrView from "./pages/org/activity/DetailBeneQrView";
import HelpScheduleHistory from "./pages/org/profile/HelpScheduleHistory";
import CoinReward from "./pages/sole/profile-sole/CoinReward";
import LocationExample from "./pages/common/LocationExample";

// 리액트 테스트
// npm run build
// npx serve -s build

function App() {
  return (
    <GroupProvider>
      <ActivityProvider>
        <ScheduleProvider>
          <UrlProvider>
            <BrowserRouter>
              <Routes>
                {/* common */}
                <Route path="/" element={<Login />}></Route>
                {/* <Route path="/test/login" element={<LoginTest />}></Route> */}
                <Route
                  path="/common/org-login/waiting"
                  element={<OrgLoginW />}
                ></Route>
                <Route
                  path="/common/community/dictionary"
                  element={<ConComDictionary />}
                ></Route>
                <Route
                  path="/common/community/faqdetail/:communityIdx"
                  element={<ConComFaqDetail />}
                ></Route>
                <Route
                  path="/common/community/faq"
                  element={<ConComFaq />}
                ></Route>
                <Route
                  path="/common/community/helpdetail/:communityIdx"
                  element={<ConComHelpDetail />}
                ></Route>
                <Route
                  path="/common/community/help"
                  element={<ConComHelp />}
                ></Route>
                <Route
                  path="/common/community/noticedetail/:communityIdx"
                  element={<ConComNoticeDetail />}
                ></Route>
                <Route
                  path="/common/community/notice"
                  element={<ConComNotice />}
                ></Route>
                <Route
                  path="/common/community/privacypolicy"
                  element={<ConComPrivacyPolicy />}
                ></Route>
                <Route
                  path="/common/community/withdrawal"
                  element={<ConComWithdrawal />}
                ></Route>
                <Route
                  path="/common/community/qna"
                  element={<ConComQna />}
                ></Route>
                <Route
                  path="/common/community/qnadetail/:qnaIdx"
                  element={<ConComQnaDetail />}
                ></Route>
                <Route
                  path="/common/community/qnadetailmine/:qnaIdx"
                  element={<ConComQnaDetailMine />}
                ></Route>
                <Route
                  path="/common/community/qnawrite"
                  element={<ConComQnaWrite />}
                ></Route>
                <Route
                  path="/common/community/qnaedit/:qnaIdx"
                  element={<QnaEdit />}
                ></Route>
                <Route
                  path="/common/community/termsofuse"
                  element={<ConComTermsOfUse />}
                ></Route>

                <Route path="/common/forgotid" element={<ForgotId />}></Route>
                <Route
                  path="/common/forgotpassword"
                  element={<ForgotPassword />}
                ></Route>
                <Route exact path="/common/login" element={<Login />}></Route>
                <Route exact path="/common/signup" element={<Signup />}></Route>

                <Route
                  exact
                  path="/common/privacy-policy"
                  element={<PrivacyPolicy />}
                ></Route>

                {/* <Route
                  exact
                  path="/test/signup"
                  element={<SignUpTest />}
                ></Route> */}

                <Route
                  path="/common/popup/conformsave"
                  element={<ConformSave />}
                ></Route>

                {/* org */}
                <Route exact path="/org/home/org" element={<HomeOrg />}></Route>
                <Route
                  path="/org/home/category/:categoryIdx"
                  element={<HomeCategory />}
                ></Route>
                <Route
                  path="/org/home/search/:searchKeyword"
                  element={<HomeSearch />}
                ></Route>

                <Route
                  path="/org/notification/notification"
                  element={<NotiNotification />}
                ></Route>

                <Route
                  path="/org/otherprofile/activityrequest/:requestOrgIdx"
                  element={<OtprActivityRequest />}
                ></Route>

                {/* <Route
                  path="/org/otherprofile/activityrequest/edit/:requestOrgIdx/:activityIdx"
                  element={<OtprActivityRequest />}
                ></Route> */}

                <Route
                  path="/org/otherprofile/org/:orgIdx"
                  element={<OtprOrg />}
                ></Route>
                <Route
                  path="/org/otherprofile/schedulelist"
                  element={<OtprScheduleList />}
                ></Route>
                <Route
                  path="/org/otherprofile/sole/:userIdx"
                  element={<OtprSole />}
                ></Route>
                <Route
                  path="/org/otherprofile/orgprivate/:orgIdx"
                  element={<OtprOrgPrivate />}
                ></Route>
                <Route
                  path="/org/otherprofile/soleprivate/:userIdx"
                  element={<OtprSolePrivate />}
                ></Route>

                <Route
                  path="/org/profile/actdetail/:actIdx"
                  element={<PfActDetail />}
                ></Route>
                <Route
                  path="/org/profile/addgroup"
                  element={<PfAddGroup />}
                ></Route>
                <Route
                  path="/org/profile/calendar"
                  element={<PfCalendar />}
                ></Route>
                <Route
                  path="/org/profile/groupacthistory/:activityIdx/:scheduleIdx/:groupIdx"
                  element={<PfGroupActHistory />}
                ></Route>
                <Route
                  path="/org/profile/groupdetail"
                  element={<PfGroupDetail />}
                ></Route>
                <Route
                  path="/org/profile/listactivityfinished/:actSchduelIdx"
                  element={<PfListActivityFinished />}
                ></Route>
                <Route
                  path="/org/profile/listactivityhistoryall"
                  element={<PfListActivityHistoryAll />}
                ></Route>
                <Route
                  path="/org/profile/listactivityhistory/:activityIdx"
                  element={<PfListActivityHistory />}
                ></Route>
                <Route
                  path="/org/profile/listactivity/:actIdx/:actSchduelIdx"
                  element={<PfListActivity />}
                ></Route>
                <Route
                  path="/org/profile/listapplicationpart/:actIdx"
                  element={<PfListApplicationPart />}
                ></Route>
                <Route
                  path="/org/profile/listrequest/:actIdx"
                  element={<PfListRequest />}
                ></Route>
                <Route path="/org/profile/org" element={<PfOrg />}></Route>
                <Route
                  path="/org/profile/registerrequested/:activityIdx"
                  element={<PfRegisterRequested />}
                ></Route>
                <Route
                  path="/org/profile/reportdetail/:actListIdx"
                  element={<PfReportDetail />}
                ></Route>
                <Route
                  path="/org/profile/requestoperationconformlist"
                  element={<PfRequestOperationConformList />}
                ></Route>
                {/* <Route
                  path="/org/profile/requestoperationdetailconformdismissal"
                  element={<PfRequestOperationDetailConformDismissal />}
                ></Route>
                <Route 
                  path="/org/profile/requestoperationDetailconformpassed"
                  element={<PfRequestOperationDetailConformPassed />}
                ></Route> */}
                {/* 반려, 통과, 대기중 상태 하나의 페이지로 통합 */}
                <Route
                  path="/org/profile/requestoperationdetailconform/:actIdx"
                  element={<PfRequestOperationDetailConform />}
                ></Route>
                <Route
                  path="/org/profile/requestoperationdetail/:actIdx"
                  element={<PfRequestOperationDetail />}
                ></Route>
                <Route
                  path="/org/profile/requestoperation"
                  element={<PfRequestOperation />}
                ></Route>
                <Route
                  path="/org/profile/requesttoparticipatelist"
                  element={<PfRequestToParticipateList />}
                ></Route>
                <Route
                  path="/org/profile/schedulelist"
                  element={<PfScheduleList />}
                ></Route>
                <Route
                  path="/org/profile/usersearch"
                  element={<PfUserSearch />}
                ></Route>

                <Route
                  path="/org/profile/help-schedule"
                  element={<HelpScheduleHistory />}
                ></Route>

                <Route
                  path="/org/activity/calendar"
                  element={<ActiCalendar />}
                ></Route>
                <Route
                  path="/org/activity/choosemembergroup"
                  element={<ActiChooseMemberGroup />}
                ></Route>
                <Route
                  path="/org/activity/detailbene/:actIdx"
                  element={<ActiDetailBene />}
                ></Route>
                <Route
                  path="/org/activity/detailbene/qr/:actIdx/:activityScheduleIdx"
                  element={<DetailBeneQrView />}
                ></Route>
                <Route
                  path="/org/activity/detailcarriedout/:actIdx"
                  element={<ActiDetailCarriedOut />}
                ></Route>
                <Route
                  path="/org/activity/detailpart/:actIdx"
                  element={<ActiDetailPart />}
                ></Route>
                <Route
                  path="/org/activity/groupmember"
                  element={<ActiGroupMember />}
                ></Route>
                <Route
                  path="/org/activity/placingmember"
                  element={<ActiPlacingMember />}
                ></Route>
                <Route
                  path="/org/activity/placingmember/individually/:activityIdx/:scheduleIdx"
                  element={<PlacingIndividualMember />}
                ></Route>
                <Route
                  path="/org/activity/schedulelist/:date"
                  element={<ActiScheduleList />}
                ></Route>
                <Route
                  path="/org/activity/schedulelist2/:date"
                  element={<ActiScheduleList2 />}
                ></Route>

                {/* <Route
                  path="/org/chat/chatdall"
                  element={<ChatChatDAll />}
                ></Route> */}
                {/* <Route
                  path="/org/chat/chatdgroup/:type"
                  element={<ChatChatDGroup />}
                ></Route> */}
                <Route
                  path="/org/chat/chatd/:type"
                  element={<ChatChatD />}
                ></Route>
                <Route path="/org/chat/chat" element={<ChatChat />}></Route>
                <Route
                  path="/org/chat/userlistall/:topic"
                  element={<ChatUserListAll />}
                ></Route>
                <Route
                  path="/org/chat/userlistgroup"
                  element={<ChatUserListGroup />}
                ></Route>

                <Route
                  path="/org/common/dismissal/:actIdx"
                  element={<CommDismissal />}
                ></Route>
                <Route
                  path="/org/common/useradd"
                  element={<CommUserAdd />}
                ></Route>

                <Route
                  path="/org/profileaddact/addact"
                  element={<PraaAddAct />}
                ></Route>

                <Route
                  path="/org/profile-add-act/schedule-add/:activityIdx"
                  element={<ScheduleAdd isPopup={false} />}
                ></Route>

                {/* <Route
                  path="/org/profileaddact/addactsclist"
                  element={<PraaAddActScList />}
                ></Route> */}
                <Route
                  path="/org/profileaddact/schedulelist"
                  element={<PraaScheduleList />}
                ></Route>
                <Route
                  path="/org/profileaddact/editact/:activityIdx"
                  element={<EditAct />}
                ></Route>

                <Route
                  path="/org/profileedit/alert"
                  element={<PredAlert />}
                ></Route>
                <Route
                  path="/org/profileedit/edit"
                  element={<PredEdit />}
                ></Route>
                <Route
                  path="/org/profileedit/setting"
                  element={<PredSetting />}
                ></Route>
                <Route
                  path="/org/profileedit/eventmatching"
                  element={<EventMactching />}
                ></Route>

                <Route
                  path="/org/schedule/schedule"
                  element={<ScdSchedule />}
                ></Route>
                <Route
                  path="/org/schedule/userlistpart/:scheduleId"
                  element={<ScdUserListPart />}
                ></Route>

                {/* sole */}
                <Route
                  path="/sole/activity/calendar"
                  element={<SActiCalendar />}
                ></Route>
                <Route
                  path="/sole/activity/detailbene/:actIdx"
                  element={<SActiDetailBene />}
                ></Route>
                <Route
                  path="/sole/activity/detailcarriedout/:actIdx"
                  element={<SActiDetailCarriedOut />}
                ></Route>
                <Route
                  path="/sole/activity/detailpart/:actIdx"
                  element={<SActiDetailPart />}
                ></Route>
                <Route
                  path="/sole/activity/groupmember"
                  element={<SActiGroupMember />}
                ></Route>
                <Route
                  path="/sole/activity/schedulelist/:date"
                  element={<SActiScheduleList />}
                ></Route>

                {/* <Route
                  path="/sole/chat/chatdall/:type/:id"
                  element={<SChatChatDAll />}
                ></Route>
                <Route 
                  path="/sole/chat/chatdgroup/:type/:id"
                  element={<SChatChatDGroup />}
                ></Route> */}
                <Route
                  path="/sole/chat/chatd/:type"
                  element={<SChatChatD />}
                ></Route>
                <Route path="/sole/chat/chat" element={<SChatChat />}></Route>

                <Route
                  path="/sole/okphone/detail"
                  element={<SOkphDetail />}
                ></Route>
                <Route
                  path="/sole/okphone/schedulelist/:date"
                  element={<SOkphScheduleList />}
                ></Route>
                <Route
                  path="/sole/okphone/selectschedule"
                  element={<SOkphSelectSchedule />}
                ></Route>

                <Route
                  path="/sole/profileedit/alert"
                  element={<SPredAlert />}
                ></Route>
                <Route
                  path="/sole/profileedit/setting"
                  element={<SpredSetting />}
                ></Route>
                <Route
                  path="/sole/profileedit/eventmatching"
                  element={<SpredEventMatching />}
                ></Route>

                <Route
                  path="/sole/profileexpert/expert/:expertid"
                  element={<SPrexExpert />}
                ></Route>
                <Route
                  path="/sole/profileexpert/expertedit"
                  element={<SPrexExpertEdit />}
                ></Route>
                <Route
                  path="/sole/profileexpert/okonlineanswer/:idx"
                  element={<SPrexOkOnlineAnswer />}
                ></Route>
                <Route
                  path="/sole/profileexpert/okonlinereportd/:idx"
                  element={<SPrexOkOnlineReportD />}
                ></Route>
                <Route
                  path="/sole/profileexpert/okonlinereportlist"
                  element={<SPrexOkOnlineReportList />}
                ></Route>
                <Route
                  path="/sole/profileexpert/okphonereport/:idx"
                  element={<SPrexOkPhoneReport />}
                ></Route>
                <Route
                  path="/sole/profileexpert/okphonereportd/:idx"
                  element={<SPrexOkPhoneReportD />}
                ></Route>
                <Route
                  path="/sole/profileexpert/okphonereportlist"
                  element={<SPrexOkPhoneReportList />}
                ></Route>

                {/* report 상세조회 및 수정 */}
                <Route
                  path="/sole/schedule/report/:actListIdx"
                  element={<SScdReport />}
                ></Route>

                {/* report 추가 페이지 */}
                <Route
                  path="/sole/schedule/add-report/:activityScheduleIdx"
                  element={<AddReport />}
                ></Route>

                <Route
                  path="/sole/schedule/read-report/:actListIdx"
                  element={<ReportReadOnly />}
                ></Route>

                <Route
                  path="/sole/schedule/schedule"
                  element={<SScdSchedule />}
                ></Route>

                <Route
                  path="/sole/home/categorybene/:categoryIdx"
                  element={<ShomeCategoryBene />}
                ></Route>
                <Route
                  path="/sole/home/categorypart/:categoryIdx"
                  element={<ShomeCategoryPart />}
                ></Route>
                <Route
                  path="/sole/home/searchbene/:searchKeyword"
                  element={<ShomeSearchBene />}
                ></Route>
                <Route
                  path="/sole/home/searchpart/:searchKeyword"
                  element={<ShomeSearchPart />}
                ></Route>
                <Route
                  path="/sole/home/solebene"
                  element={<ShomeSoleBene />}
                ></Route>
                <Route
                  path="/sole/home/solepart"
                  element={<ShomeSolePart />}
                ></Route>

                <Route
                  path="/sole/notification/notification"
                  element={<SNotiNotification />}
                ></Route>

                <Route
                  path="/sole/okonline/detail"
                  element={<SOkonDetail />}
                ></Route>
                <Route
                  path="/sole/okonline/detaila"
                  element={<SOkonDetailA />}
                ></Route>
                <Route
                  path="/sole/okonline/detailad/:onconIdx"
                  element={<SOkonDetailAD />}
                ></Route>
                {/* <Route exact path="/sole/okonline/:onconIdx" element={<SOkonDetailDefault/>} ></Route> */}

                <Route
                  path="/sole/otherprofile/expert"
                  element={<SOtprExpert />}
                ></Route>
                <Route
                  path="/sole/otherprofile/org/:orgIdx"
                  element={<SOtprOrg />}
                ></Route>
                <Route
                  path="/sole/otherprofile/sole/:userIdx"
                  element={<SOtprSole />}
                ></Route>
                <Route
                  path="/sole/otherprofile/soleprivate/:userIdx"
                  element={<SOtprOrgPrivate />}
                ></Route>

                <Route
                  path="/sole/profilesole/activityhistory"
                  element={<SPrsoActivityHistory />}
                ></Route>
                <Route
                  path="/sole/profilesole/actrequestapplication"
                  element={<SPrsoActRequestApplication />}
                ></Route>
                <Route
                  path="/sole/profilesole/okhistory"
                  element={<SPrsoOkHistory />}
                ></Route>
                <Route
                  path="/sole/profilesole/orglist"
                  element={<SPrsoOrgList />}
                ></Route>
                <Route
                  path="/sole/profilesole/requestoperationlist"
                  element={<SPrsoRequestOperationList />}
                ></Route>
                <Route
                  path="/sole/profilesole/requestorg"
                  element={<SPrsoRequestOrg />}
                ></Route>
                <Route
                  path="/common/request-org"
                  element={<SPrsoRequestOrgConform />}
                ></Route>
                <Route
                  path="/sole/profilesole/sole"
                  element={<SPrsoSole />}
                ></Route>
                <Route
                  path="/sole/profilesole/soleedit"
                  element={<SPrsoSoleEdit />}
                ></Route>
                <Route
                  path="/sole/schedule/scancode/:scheduleId"
                  element={<ScanCode />}
                ></Route>
                <Route
                  path="/sole/profile-sole/coin-reward"
                  element={<CoinReward />}
                ></Route>

                {/*  파일 업로드  */}
                <Route
                  path="/common/file-upload"
                  element={<FileUpload type="file" />}
                ></Route>

                <Route element={<h2>Page Not Found</h2>} />

                {/* <Route
                  path="/org/profile/act-statistics"
                  element={<ActStatstics />}
                ></Route> */}

                  <Route
                      path={"/common/location"}
                      element={<LocationExample/>}
                  />

              </Routes>
            </BrowserRouter>
          </UrlProvider>
        </ScheduleProvider>
      </ActivityProvider>
    </GroupProvider>
  );
}

export default App;
