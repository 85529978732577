import React, { useState, useEffect } from "react";
import SubHeader from "../../components/SubHeader";

const FileUpload = ({ type, getFile, toggleFileModal }) => {
  const [renderFile, setRenderFile] = useState(null);
  const [renderImgUrl, setRenderImagUrl] = useState(null);

  const setImageFromFile = (file) => {
    let reader = new FileReader();
    reader.onload = function () {
      setRenderFile(file);
      setRenderImagUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (type === "img") {
        // 파일 확장자 추출
        const fileLen = file.name.length;
        const lastDotIdx = file.name.lastIndexOf(".");
        const fileEx = file.name.substring(lastDotIdx, fileLen).toLowerCase();

        // 파일 크기
        const fileSize = file.size;
        if (fileEx !== ".jpg" && fileEx !== ".jpeg" && fileEx !== ".png") {
          alert("이미지 확장자는 .jpg, .jpeg, .png 만 업로드가 가능합니다.");
          return;
        }

        if (fileSize > 10000000) {
          alert("이미지 파일크기는 10M를 초과할 수 없습니다.");
          return;
        }

        setImageFromFile(file);
      } else {
        // 파일 크기
        const fileSize = file.size;

        if (fileSize > 10000000) {
          alert("등록증명서 파일크기는 10M를 초과할 수 없습니다.");
          return;
        }

        setRenderFile(file);
      }
    }
  };

  const onClickSave = () => {
    if (typeof Android !== "undefined" && window.Android !== null) {
      window.Android.sendImage();
    } else {
      try {
        window.webkit.messageHandlers.callbackHandler.postMessage(
          "uid=" + window.sessionStorage.getItem("idx") + "&token=__token__"
        );
      } catch (err) {
        alert(err);
      }
    }

    getFile(renderFile, renderFile.name, renderImgUrl, type);
  };

  useEffect(() => {
    if (typeof window.Android !== "undefined" && window.Android !== null) {
      window.Android.checkMainPage(window.location.href);
    }
  });

  return (
    <div className="page page--main" data-page="shop">
      <div className="page__content page_section">
        <SubHeader title="파일 업로드" toggleModal={toggleFileModal} />
        <div className="page__content  page_section--with-header-68">
          <br />

          <p className="st_bt_txt">
            <form id="_frm" method="post" encType="multipart/form-data">
              <input type="hidden" name="id" id="uid" value="mem000811" />
              <input
                className="st_bt_st_o"
                name="imagesFile"
                id="imagesFile"
                type="file"
                accept={type === "img" && ".jpg, .jpeg, .png"}
                capture="camera"
                onChange={(e) => onChangeFile(e)}
              />
            </form>
          </p>
          <button
            className="st_bt_st"
            type="button"
            id="btn_camera"
            onClick={() => onClickSave()}
          >
            <p className="st_bt_txt">전송하기</p>
          </button>
          <button
            className="st_bt_st_gray"
            type="button"
            id="btn_next"
            onClick={toggleFileModal}
          >
            <p className="st_bt_txt">건너띄기</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
