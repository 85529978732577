import axios from "axios";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import useConfirm from "../../../util/cofirm-alert";
import { sliceIdx } from "../../../util/util-function";
import { useNavigate } from "react-router-dom";

// [chatType]
// chatalls, chatds => 삭제
// chatall => 전체 회원 보기
// chatgs => 삭제, 활동일정보기
// chatg => 전체 회원 보기, 활동 일정 보기
// declaration => 신고하기
function PopupListChat(props) {
  const { popupType, toggleChatOption, topic, type } = props;
  const navigate = useNavigate();

  const viewNav = () => {
    switch (popupType) {
      case "chatalls":
      case "chatds":
        return (
          <Fragment>
            <li>
              <a onClick={handleDelBtn}>
                <span>삭제</span>
              </a>
            </li>
          </Fragment>
        );
      case "chatall":
        return (
          <Fragment>
            <li>
              <Link to={`/org/chat/userlistall/${topic}`}>
                <span>전체 회원 보기</span>
              </Link>
            </li>
          </Fragment>
        );
      case "chatgs":
        return (
          <Fragment>
            <li>
              <a onClick={handleDelBtn}>
                <span>삭제</span>
              </a>
            </li>
            <li>
              {/* 활동 idx */}
              <Link to={`/sole/activity/detailpart/${sliceIdx(topic, "a")}`}>
                <span>활동보기</span>
              </Link>
            </li>
          </Fragment>
        );
      case "chatg":
        return (
          <Fragment>
            <li>
              <Link to="/org/chat/userlistgroup">
                <span>전체 회원 보기</span>
              </Link>
            </li>
            <li>
              {/* 일정 idx */}
              <Link to="/org/profile/listactivity">
                <span>활동 일정 보기</span>
              </Link>
            </li>
          </Fragment>
        );
      case "declaration":
        return (
          <Fragment>
            <li>
              <a href="#">
                <span>신고하기</span>
              </a>
            </li>
          </Fragment>
        );
      case "activity_edit":
        return (
          <Fragment>
            <li>
              <a href="../other-profile/activity-request.html">
                <span>수정</span>
              </a>
            </li>
            <li>
              <a onClick={handleDelBtn}>
                <span>삭제</span>
              </a>
            </li>
          </Fragment>
        );
        break;
      default:
        break;
    }
  };

  const deleteMessage = async () => {
    // let url = `${process.env.REACT_APP_CHAT_SERVICE_API}/api/v1/chat/setting/${type}/${topic}`;
      let url = `${process.env.REACT_APP_CHAT_SERVICE_API}/api/v1/chat/setting/${topic}`;

      try {
      const response = await axios.delete(url, {params : {userIdx : window.sessionStorage.getItem("idx")}});

      if (response.status === 200) {
        alert("삭제되었습니다.");
        navigate(-1);
      }
    } catch (e) {
      console.log(e);
      alert("메세지 삭제 중, 오류가 발생하였습니다");
      // navigate(-1);
    }
  };

  const handleDelBtn = useConfirm(
    "삭제된 메세지창은 복구가 어렵습니다. 정말 삭제하시겠습니까?",
    deleteMessage
  );

  return (
    <div className={`popup popup--half popup--${popupType}`}>
      <div className="popup__close">
        <span
          className="close-popup"
          data-popup={popupType}
          onClick={toggleChatOption}
        >
          <img src="/assets/images/icons/blue/close.svg" alt="" title="" />
        </span>
      </div>
      <h2 className="popup__title">메뉴</h2>
      <nav className="social-nav">
        <ul>{viewNav()}</ul>
      </nav>
    </div>
  );
}

export default PopupListChat;
