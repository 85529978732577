import axios from "axios";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import CommunityPageNav from "../../../components/CommunityPageNav";
import Panel from "../../../components/Panel";
import QnaList from "../../../components/QnaList";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function Qna() {
  const [pageNumber, setPageNumber] = useState(1);
  const getPageNumber = (seletedPage) => {
    setPageNumber(seletedPage);
  };

  const [totalRows, setTotalRows] = useState(null);
  const [qnaList, setQnaList] = useState(null);

  const getQnaList = useCallback(async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/qna`;

    try {
      const response = await axios.get(url, {
        params: {
          page: pageNumber,
          rows: 10,
        },
      });

      if (response.status === 200) {
        const { data, totalRows } = response.data;

        setTotalRows(totalRows);

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            idx: data[i].idx,
            title: data[i].title,
            nickName: data[i].nickname,
            createDate: data[i].createdAtStr,
            openStatus: data[i].openStatus,
          });
        }

        setQnaList(ary);
      }
    } catch (e) {
      alert("문의하기 목록 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, [pageNumber]);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getQnaList();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getQnaList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page-section page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="문의하기" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68">
          <div className="page__title-bar">
            <h2>문의하기 ({totalRows})</h2>
          </div>
          {qnaList && (
            <QnaList qnaList={qnaList} pageNumber={pageNumber} count={10} />
          )}
          <CommunityPageNav
            pageNumber={pageNumber}
            getPageNumber={getPageNumber}
            totalNum={totalRows}
            count={10}
          />
          <Link to="/common/community/qnawrite">
            <div className="buttons buttons--centered mt-20">
              <div className="button button--main button--full">글쓰기</div>
            </div>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default Qna;
