import React from 'react';
import { Link } from 'react-router-dom';

function ExpertCategory(props) {
  const {expert} = props;

  const viewExpert = () => {
    switch (expert) {
      case "법률":
      case "법률 전문가":
        return "law"
      case "세무":
      case "세무 전문가":  
        return "tax"
      case "법무":
      case "법무 전문가":
        return "legal"
      case "건축":
      case "건축 전문가":
        return "cons"
      case "노무":
      case "노무 전문가":
        return "labor"
      default:
        break;
    }
  } 


  return (
    <Link to="" className={`caption__category tag_expert_${viewExpert()}`}>{expert}</Link>
  );
}

export default ExpertCategory;