import axios from "axios";
import React, { Fragment, useRef, useEffect, useState } from "react";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ConformPopup from "../../common/popup/ConformPopup";
import { useNavigate } from "react-router-dom";
import CheckLoginInfo from "../../../services/login-validation";
import { isAndroid } from "../../../util/mobile-function";
import FileUpload from "../../common/FileUpload";

function RequestOrg(props) {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    address: "",
    contact: "010-1213-4231",
    email: "",
    proofImg: "a",
    type: "",
    bio: "",
    img: "",
    file: "",
  });

  // const [ categoryDatas, setCategorydatas ] = useState([
  //   {
  //     id: '',
  //     categoryname: ''
  //   }
  // ]);

  const [categoryData, setCategoryData] = useState([]);
  const nextId = useRef();

  const requestorg = (e) => {
    // axios URL
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/org`;
    let data = {
      idx: sessionStorage.idx,
      address: values.address,
      contact: values.contact,
      email: values.email,
      proofImg: values.proofImg,
      type: values.type,
      bio: values.bio,
      img: values.img,
      file: values.file,
    };
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios.post(url, data, config).then((res) => {
      // let userurl = '/api/user-service/users'
      // let orgStatus = {
      //   "orgStatus": 'W',
      //   "idx": sessionStorage.idx
      // }
      // axios.put(userurl, orgStatus, config)
      // .then(res => {
      // })
    });
  };

  const [renderImgFile, setRenderImagFile] = useState(null);
  const [renderImgUrl, setRenderImagUrl] = useState(
    "/assets/images/photos/img-p-org.jpg"
  );

  const handleChangeForm = (e) => {
    if (e.target.name === "file") {
      // 파일 객체
      const file = e.target.files[0];

      if (file) {
        // 파일 확장자 추출
        const fileLen = file.name.length;
        const lastDotIdx = file.name.lastIndexOf(".");
        const fileEx = file.name.substring(lastDotIdx, fileLen).toLowerCase();

        // 파일 크기
        const fileSize = file.size;

        if (fileEx !== ".jpg" && fileEx !== ".jpeg" && fileEx !== ".png") {
          alert("이미지 확장자는 .jpg, .jpeg, .png 만 업로드가 가능합니다.");

          return;
        }

        if (fileSize > 10000000) {
          alert("이미지 파일크기는 10M를 초과할 수 없습니다.");

          return;
        }

        setImageFromFile(file);

        setValues({
          ...values,
          [e.target.name]: file,
          ["img"]: file.name,
        });
      }
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setImageFromFile = (file) => {
    let reader = new FileReader();
    reader.onload = function () {
      setRenderImagFile(file);
      setRenderImagUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const addCategory = () => {
    const categorydata = {
      id: nextId.current,
    };
    // setCategorydatas(categoryname.concat(categorydata))

    nextId.current += 1;
  };

  const [hideContent, setHideContent] = useState(true);

  const [showFileModal, setShowFileModal] = useState(false);
  const toggleFileModal = () => {
    setShowFileModal(!showFileModal);
  };
  const getFile = (file, name, imgUrl, type) => {
    if (type === "img") {
      setValues({
        ...values,
        file: file,
        img: name,
      });

      setRenderImagUrl(imgUrl);
    } else {
    }

    toggleFileModal();
  };

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        // axios URL
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/category`;
        axios.get(url).then((res) => {
          setCategoryData(res.data.data);
          setHideContent(false);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  if (showFileModal) {
    return (
      <FileUpload
        type="img"
        getFile={getFile}
        toggleFileModal={toggleFileModal}
      />
    );
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="기관 등록 요청" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="fieldsets mt-48">
            <div className="user-profile__thumb">
              {/* <img src="/assets/images/photos/img-p-org.jpg" alt="" /> */}
              <img
                src={renderImgUrl}
                alt={renderImgFile && renderImgFile.name}
              />
            </div>
            <div className="upload-filebox mt-20">
              <form id="_frm" method="post" enctype="multipart/form-data">
                <label htmlFor="ex_file">기관 이미지 업로드</label>
                <input
                  // type={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? "button"
                  //     : "file"
                  // }
                  // type="button"
                  type={"file"}
                  // style={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? { display: "none" }
                  //     : { display: "block" }
                  // }
                  // style={{ display: "none" }}
                  id="ex_file"
                  accept=".jpg, .jpeg, .png"
                  // accept="image/*"
                  capture="camera"
                  name="file"
                  // onClick={() =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? toggleFileModal()
                  //     : null
                  // }
                  // onClick={() => toggleFileModal()}
                  // onChange={(e) =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? null
                  //     : handleChangeForm(e)
                  // }
                  onChange={handleChangeForm}
                />
              </form>
            </div>
          </div>
          <div className="fieldsets mt-48">
            <div className="d-flex justify-space align-items-center">
              <h2 className="mb-0">기관/단체 정보</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <h4>기관/단체 이름</h4>
                <div className="form__row mb-20">
                  <input
                    type="text"
                    name="ContactName"
                    value=""
                    className="form__input required"
                    placeholder="입력"
                  />
                </div>
                <h4>주소</h4>
                <div className="form__row mb-20">
                  <input
                    type="text"
                    name="address"
                    value={values.address}
                    onChange={handleChangeForm}
                    className="form__input required"
                    placeholder="입력"
                  />
                </div>
                <h4>연락처</h4>
                <div className="form__row mb-20">
                  <input
                    type="tel"
                    name="contact"
                    value={values.contact}
                    onChange={handleChangeForm}
                    className="form__input required"
                    placeholder="입력"
                  />
                </div>
                <h4>이메일</h4>
                <div className="form__row mb-20">
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChangeForm}
                    className="form__input required"
                    placeholder="입력"
                  />
                </div>
                <h4>기관/단체 등록증명서</h4>
                <div className="form__row d-flex align-items-center justify-space  mb-20">
                  <div className="form__select form__input form__select--23 mr-16">
                    <input
                      type="text"
                      name="ContactName"
                      value={values.proofImg}
                      onChange={handleChangeForm}
                      className="form__input required"
                      placeholder="파일명.jpg"
                    />
                  </div>
                  <div className="form__button--13">
                    <input
                      type="submit"
                      name="submit"
                      className="form__submit button button--main"
                      id="submit"
                      value="파일선택"
                    />
                  </div>
                </div>
                <h4>기관 타입</h4>
                <div className="form__row mb-20">
                  <div className="form__select">
                    <select name="ContactSelect" className="required">
                      <option value disabled selected>
                        선택
                      </option>
                      <option value="활동기관">활동기관</option>
                      <option value="수요기관">수요기관</option>
                      <option value="활동, 수요기관">활동, 수요기관</option>
                    </select>
                  </div>
                </div>
                {/* <h4>카테고리</h4>
                <div className="form__row d-flex align-items-center justify-space  mb-20">
                  <div className="form__select form__input form__select--23 mr-16">
                    <select name="ContactSelect" className="required">
                      <option value disabled selected>선택</option>
                      <option value="카테고리01">카테고리01</option>
                      <option value="카테고리02">카테고리02</option>
                    </select>
                  </div>
                  <div className="form__button--13">
                    <input type="submit" name="categoryname" onCreate={addCategory} className="form__submit button button--main" id="submit" value="추가" />
                  </div>
                </div> */}
                {/* <div className="cards cards--14">
                  <EditCategory categoryname="카테고리00" onCreate={addCategory}/> */}
                {/* {
                    categoryData.map((category)=> (
                      <EditCategory categoryname="카테고리00"/>
                    ))
                  } */}
                {/* </div> */}
                <h4>소개</h4>
                <div className="form__row">
                  <textarea
                    className="form__textarea required"
                    placeholder="내용을 기입해주세요."
                    name="bio"
                    value={values.bio}
                    onChange={handleChangeForm}
                  />
                </div>
                <div className="form mt-48">
                  <h4>공개여부</h4>
                  <div className="form__select">
                    <select name="ContactSelect" className="required">
                      <option value disabled selected>
                        선택
                      </option>
                      <option value={1}>공개</option>
                      <option value={2}>비공개</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="form__row mt-40">
            <input
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소22"
            />
            <input
              type="submit"
              name="submit"
              onClick={requestorg}
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_save"
              id="submit"
              value="저장"
            />
          </div>
          <div className="space-h-100" />
        </div>
        <ConformPopup
          popupType="cancle"
          text="정말 취소하시겠습니까?"
          conformClick={() => navigate(-1)}
        />
        <ConformPopup
          popupType="save"
          text="해당 내용을 저장하시겠습니까?"
          conformClick={requestorg}
        />
      </div>
      {/* PAGE END */}
    </Fragment>
  );
}

export default RequestOrg;
