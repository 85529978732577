import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import TimeItem from "../../../components/TimeItem";
import Navigationbar from "../../../components/Navigationbar";
import CheckLoginInfo from "../../../services/login-validation";

const time = [
  "오전 10시 00분",
  "오전 10시 30분",
  "오전 11시 00분",
  "오전 11시 30분",
  "오후 12시 00분",
  "오후 12시 30분",
  "오후 1시 00분",
  "오후 1시 30분",
  "오후 2시 00분",
  "오후 2시 30분",
  "오후 3시 00분",
  "오후 3시 30분",
  "오후 4시 00분",
  "오후 4시 30분",
  "오후 5시 00분",
  "오후 5시 30분",
  "오후 6시 00분",
  "오후 6시 30분",
  "오후 7시 00분",
];

function ScheduleList(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [chkClick, setChkClick] = useState(false);

  const year = location.state && location.state.date.getFullYear();
  const month = location.state && location.state.date.getMonth() + 1;
  const date = location.state && location.state.date.getDate();

  const nowdate = `${year}-${month >= 10 ? month : "0" + month}-${date >= 10 ? date : "0" + date
    }`;

  const checkedItemHandler = (id, isChecked) => {
    if (isChecked) {
      checkedItems.add(id);
      setCheckedItems(checkedItems);
    } else if (!isChecked && checkedItems.has(id)) {
      {
        checkedItems.delete(id);
        setCheckedItems(checkedItems);
      }
    }
  };

  const confirmHandler = () => {
    setChkClick(true);
    navigate(-1);
  };

  const timeitem = time.map((item, index) => (
    <TimeItem
      item={item}
      index={index}
      checkedItemHandler={checkedItemHandler}
      setSchedule={location.state && location.state.setSchedule}
      chkClick={chkClick}
      nowdate={nowdate}
    />
  ));

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        {nowdate && nowdate.includes("null") ? (
          <SubHeader title="일정" />
        ) : (
          <SubHeader title={`일정 (${nowdate})`} />
        )}
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar pt-40 mb-24">
              <h1 className="page__title">일정</h1>
            </div>
            {timeitem}
            <div className="form__row mt-40">
              <input
                onClick={confirmHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="확인"
              />
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default ScheduleList;
