import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Navigation from "../../../components/Navigation";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import ScheduleList from "../../../components/schedule-component/ScheduleList";
import ScheduleItem from "../../../components/ScheduleItem";
import isMobile from "../../../util/mobile-function";
import CheckLoginInfo from "../../../services/login-validation";

function Schedule() {
  const navigate = useNavigate();
  const [startedScheduleList, setStartedScheduleList] = useState(null);
  const [nextScheduleList, setNextScheduleList] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [futureScheduleList, setFutureScheduleList] = useState({
    totalRows: 0,
    list: [],
  });

  const getSchedule = useCallback(async () => {
    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/schedule/${
      window.sessionStorage.getItem("orgStatus") === "O" ? "org" : "sole"
    }/list/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const { totalRows, nextData, attending, started } = response.data;

        let startedAry = [];
        let nextAry = [];

        for (let i = 0; i < attending.length; i++) {
          startedAry.push({
            id: attending[i].id,
            scheduleId: attending[i].id,
            actId: attending[i].activityIdx,
            state: "WORKING",
            activityTitle: attending[i].title,
            passedTime: attending[i].joinAt,
            startTime: attending[i].activityTime,
            totalRecruitNum: attending[i].recruitNum,
            recruitNum: attending[i].attendedNum,
            type: "PART",
            orgType: "U",
            joinType: attending[i].joinType,
          });
        }

        for (let i = 0; i < started.length; i++) {
          startedAry.push({
            scheduleId: started[i].id,
            actId: started[i].activityIdx,
            state: "STARTED",
            activityTitle: started[i].title,
            startTime: started[i].activityTime,
            type: "PART",
            orgType: "U",
            joinType: started[i].joinType,
          });
        }

        setStartedScheduleList(startedAry);

        for (let i = 0; i < nextData.length; i++) {
          nextAry.push({
            id: nextData[i].id,
            actId: nextData[i].activityIdx,
            orgType: "U",
            partType: nextData[i].partType,
            beneType: nextData[i].beneType,
            actType: nextData[i].activityType,
            title: nextData[i].title,
            actDate: nextData[i].activityDate,
            actTime: nextData[i].activityTime,
            orgTitle: nextData[i].orgTitle,
            recruitNum: nextData[i].recruitNum,
            joinNum: nextData[i].joinNum + nextData[i].attendedNum,
            img: nextData[i].imageUrl,
            joinType: nextData[i].joinType,
          });
        }

        setNextScheduleList({
          totalRows: totalRows,
          list: nextAry,
        });
      }
    } catch (e) {
      alert("일정 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, []);

  const getSchedule_future = useCallback(async () => {
    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/schedule/${
      window.sessionStorage.getItem("orgStatus") === "O" ? "org" : "sole"
    }/list/future/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          page: pageNumber,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let nextAry = [];
        for (let i = 0; i < data.length; i++) {
          nextAry.push({
            id: data[i].id,
            actId: data[i].activityIdx,
            partType: data[i].partType,
            beneType: data[i].beneType,
            actType: data[i].activityType,
            title: data[i].title,
            actDate: data[i].activityDate,
            actTime: data[i].activityTime,
            orgTitle: data[i].orgTitle,
            orgType: "U",
            recruitNum: data[i].recruitNum,
            joinNum: data[i].joinNum + data[i].attendedNum,
            img: Object.keys(data[i]).includes("imageUrl")
              ? data[i].imageUrl
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            joinType: data[i].joinType,
          });
        }

        if (pageNumber === 1) {
          setFutureScheduleList({
            totalRows: totalRows,
            list: nextAry,
          });
        } else {
          setFutureScheduleList({
            totalRows: totalRows,
            list: futureScheduleList.list.concat(nextAry),
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [pageNumber]);

  const joinAct = async (scheduleId) => {
    let data = new Object();
    data.activityScheduleIdx = scheduleId;

    const url = `${
      process.env.REACT_APP_SERVICE_API
    }/api/v1/schedule/sole/join/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        alert("활동에 참여되었습니다.");
        getSchedule();
      }
    } catch (e) {
      alert("활동 참여 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const cancelJoinedAct = async (scheduleId) => {
    let data = new Object();
    data.activityScheduleIdx = scheduleId;

    const url = `${
      process.env.REACT_APP_SERVICE_API
    }/api/v1/schedule/sole/cancel/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        alert("활동 참여가 취소되었습니다.");
        getSchedule();
      }
    } catch (e) {
      alert("활동 참여 취소 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  };

  const endSoleSchedule = async (scheduleId) => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/report/${scheduleId}/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        if (response.data === -1) {
          navigate(`/sole/schedule/add-report/${scheduleId}`);
        } else {
          navigate(`/sole/schedule/report/${response.data}`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const endSoleSchedule = async (scheduleId) => {
  //   let data = new Object();
  //   data.activityScheduleIdx = scheduleId;

  //   const url = `${
  //     process.env.REACT_APP_USER_SERVICE_API
  //   }/api/v1/schedule/finish/user/${window.sessionStorage.getItem("idx")}`;

  //   try {
  //     const response = await axios.post(url, data);

  //     if (response.status === 201) {
  //       //
  //       navigate(`/sole/schedule/add-report/${scheduleId}`);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getSchedule();
        getSchedule_future();

        if (isMobile()) {
          if (
            typeof window.Android !== "undefined" &&
            window.Android !== null
          ) {
            window.Android.storeUidToken(
              window.sessionStorage.getItem("idx"),
              "temp_token"
            );
          } else {
            // alert("Can not store login info");
          }
        }

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getSchedule, getSchedule_future]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header page__content page_section--with-bottom-nav pt-20">
          {startedScheduleList && (
            <ScheduleList
              list={startedScheduleList}
              joinAct={joinAct}
              cancelJoinedAct={cancelJoinedAct}
              endSoleSchedule={endSoleSchedule}
            />
          )}
          <h2>
            오늘 예정된 일정({nextScheduleList && nextScheduleList.totalRows})
          </h2>
          <div className="cards cards--11">
            {nextScheduleList &&
              nextScheduleList.list.map((scheduleInfo) => (
                <ScheduleItem
                  folder="sole"
                  scheduleInfo={scheduleInfo}
                  key={scheduleInfo.id}
                  isToday={false}
                />
              ))}
          </div>
          <h2>다음 예정된 일정({futureScheduleList.totalRows})</h2>
          <div className="cards cards--11">
            {futureScheduleList.list.map((scheduleInfo) => (
              <ScheduleItem
                folder="sole"
                scheduleInfo={scheduleInfo}
                key={scheduleInfo.id}
                isToday={false}
              />
            ))}

            <div className="buttons buttons--centered mb-20">
              {futureScheduleList.totalRows > futureScheduleList.list.length ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => setPageNumber(pageNumber + 1)}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <div id="bottom-toolbar_sole" className="bottom-toolbar_sole">
        <Navigation folder={"sole"} />
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default Schedule;
