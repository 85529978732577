import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import Navigationbar from "../../../components/Navigationbar";
import OrgItem from "../../../components/OrgItem";
import OrgTagItem from "../../../components/OrgTagItem";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function OrgList(props) {
  // 소속된 기관
  const [orgMemberData, setOrgMemberData] = useState([]);
  // 신청한 기관
  const [requestOrgData, setRequestOrgData] = useState([]);

  // 더보기 페이징
  const [orgMemberPage, setOrgMemberPage] = useState(1);
  const [requestorgPage, setRequestOrgPage] = useState(1);

  // 페이징 기본 수
  const offsetPage = 8;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  const [hideContent, setHideContent] = useState(true);

  // 회원 소속 기관 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        axios
          .get(
            `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/user/${sessionStorage.idx}`
          )
          .then((res) => {
            const orgMemberList = res.data.data;

            axios
              .all(
                res.data.data.map((orgMember) => {
                  return axios.get(
                    `${process.env.REACT_APP_USER_SERVICE_API}/org/${orgMember.orgIdx}`
                  );
                })
              )
              .then((res2) => {
                const orgMemberArray = [];
                res2.map((org, index) => {
                  orgMemberArray.push(
                    Object.assign(res.data.data[index], org.data)
                  );
                });

                setOrgMemberData(
                  orgMemberArray.filter(
                    (orgMember) =>
                      orgMember.status === "U" || orgMember.status === "A"
                  )
                );
                setRequestOrgData(
                  orgMemberArray.filter((orgMember) => orgMember.status === "W")
                );
                //setUserData(res);
              });
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="내가 소속된 기관" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar">
              <h2>전체 기관 ({orgMemberData.length})</h2>
            </div>
            <div className="cards cards--14">
              {orgMemberData &&
                orgMemberData
                  .map((orgMember) => (
                    <OrgItem
                      key={orgMember.id}
                      id={orgMember.id}
                      folder="sole"
                      org={orgMember}
                    />
                  ))
                  .slice(0, orgMemberPage * offsetPage)}
            </div>
            {orgMemberData.length > orgMemberPage * offsetPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setOrgMemberPage, orgMemberPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="page_section">
            <div className="page__title-bar">
              <h2>신청 이력 ({requestOrgData.length})</h2>
            </div>
            <div className="cards cards--14">
              {requestOrgData &&
                requestOrgData
                  .map((orgMember) => (
                    <OrgTagItem
                      key={orgMember.id}
                      folder="sole"
                      org={orgMember}
                    />
                  ))
                  .slice(0, requestorgPage * offsetPage)}
            </div>
            <div className="buttons buttons--centered mb-20">
              {requestOrgData.length > requestorgPage * offsetPage ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => {
                    addPage(setRequestOrgPage, requestorgPage);
                  }}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default OrgList;
