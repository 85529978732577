import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tag from "./Tag";
import axios from "axios";
import { setAddressString } from "../util/util-function";

function ActivityItem(props) {
  const { folder, url, activity, viewTagList } = props;

  const navigate = useNavigate();

  const orgClickHandler = (e) => {
    e.preventDefault();
    navigate(`/org/otherprofile/org`, {
      state: {},
    });
  };

  const convertDate = (inputDate) => {
    if (inputDate !== "" && inputDate !== undefined) {
      const date = new Date(inputDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let min = date.getMinutes();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour > 12 ? "오후 " + (hour - 12) : "오전 " + hour;

      return `${year}.${month}.${day} 
              ${hour}시 ${min}분`;
    } else {
      return ``;
    }
  };

  const defaultImgPath = "/assets/images/food/img-default.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (activity) {
      if (Object.keys(activity).includes("img")) {
        setImgPath(activity.img);
      } else {
        const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
        axios
          .get(URL, {
            params: {
              pkIdx: activity && activity.idx,
              imageStatus: "A",
            },
          })
          .then((res) => {
            // 파일 조회시 업로드 이미지 Path Set
            if (res.data !== "") {
              setImgPath(
                `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
              );
            }
          });
      }
    }
  }, []);

  // 디폴트 이미지로 변경
  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <Fragment>
      {activity && (
        <div className="card card--style-thumb">
          <div className="card__thumb card__thumb--round-corners">
            {/* 자신이 등록한 활동이면 actdetail 페이지로 이동 */}
            <Link
              to={
                sessionStorage.orgStatus === "O" &&
                  sessionStorage.idx === activity.orgIdx
                  ? `/org/profile/actdetail/${activity.idx}`
                  : url
              }
            >
              <img src={imgPath} alt="" title="" onError={changeDefaultImg} />
            </Link>
          </div>
          <div className="card__details">
            <Link
              to={`/${folder}/otherprofile/org/${activity.orgIdx}`}
              className="card__username fc_point1"
            >
              {activity.orgTitle}
            </Link>
            <h4 className="card__list">{activity.title}</h4>
            <p className="card__info">
              {Object.keys(activity).includes("createdAt")
                ? convertDate(activity.createdAt)
                : `${activity.activityDate} ${activity.activityTime}`}
              <br></br>
              {/* {activity &&
                activity.activityType === "F" &&
                ` / ${setAddressString(activity.address1, activity.address2)}`} */}
            </p>
            {viewTagList && viewTagList.indexOf("activityType") !== -1 ? (
              activity.activityType === "O" ? (
                <Tag tagName="온라인" />
              ) : (
                <Tag tagName="오프라인" />
              )
            ) : null}
            {viewTagList && viewTagList.indexOf("isPrivate") !== -1 ? (
              activity.isPrivate === "O" ? null : (
                <Tag tagName="비공개" />
              )
            ) : null}
            {viewTagList && viewTagList.indexOf("beneType") !== -1 ? (
              activity.beneType === "X" || activity.beneType === "E" ? null : (
                <Tag tagName="수요자 모집" />
              )
            ) : null}

            {viewTagList && viewTagList.indexOf("partType") !== -1 ? (
              activity.partType === "X" || activity.partType === "E" ? null : (
                <Tag tagName="활동자 모집" />
              )
            ) : null}
            {viewTagList && viewTagList.indexOf("helpStatus") !== -1
              ? {
                W: <Tag tagName="대기 중" />,
                D: <Tag tagName="승인됨" />,
                N: <Tag tagName="반려" />,
              }[activity.helpStatus]
              : null}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ActivityItem;
