import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import GroupMemberItem from "../../../components/GroupMemberItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CheckLoginInfo from "../../../services/login-validation";

function GroupDetail(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const group = location.state && location.state.group;
  //console.log(group);

  const [groupData, setGroupDate] = useState([]);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        const params = {
          orgIdx: sessionStorage.idx,
          name: group.name,
        };

        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/group_list/org`;
        axios
          .get(URL, {
            params: params,
          })
          .then((res) => {
            let ary = [];

            const data = res.data.data;
            //console.log(data);

            for (let i = 0; i < data.length; i++) {
              ary.push({
                groupIdx: data[i].idx,
                userIdx: data[i].userIdx,
                nickname: data[i].nickname,
                status: data[i].orgMemberListStatus,
                idx: data[i].orgMemberListIdx,
                name: data[i].name,
                img: Object.keys(data[i]).includes("img")
                  ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${data[i].img}`
                  : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`,
              });
            }

            setGroupDate(ary);
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title={group && group.name} />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center mb-40">
              <h2>그룹 회원({groupData.length})</h2>
              <div
                className="form__submit button button--main"
                onClick={() =>
                  navigate(`/org/common/useradd`, {
                    state: { groupData: group },
                  })
                }
                style={{ cursor: "pointer" }}
              >
                + 회원 추가
              </div>
            </div>
            <div className="cards cards--14">
              {groupData &&
                groupData.map((group, idx) => (
                  <GroupMemberItem
                    key={idx}
                    orgMember={group}
                    folder="org"
                    title="제외"
                    type="groupDetail"
                  />
                ))}
            </div>
            <div className="under_button">
              <div className="form__row mt-40">
                <input
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  data-popup="conform_cancle"
                  className="form__submit button button--gray button--50 open-popup"
                  id="submit"
                  value="취소"
                />
                <input
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--50"
                  id="submit"
                  value="확인"
                />
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default GroupDetail;
