import axios from "axios";
import React, { Fragment, useEffect, useState, useContext } from "react";

import UrlContext from "../../../context/Url.context";

import Navigation from "../../../components/Navigation";
import Navigationbar from "../../../components/Navigationbar";
import NotificationItem from "../../../components/NotificationItem";
import { convertDashToDot } from "../../../util/convert-date-function";
import CheckLoginInfo from "../../../services/login-validation";

function Notification(props) {
  const urlContext = useContext(UrlContext);

  const [totalRows, setTotalRows] = useState(null);
  const [alarmList, setAlarmList] = useState(null);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getAlarmList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getAlarmList = async () => {
      const url = `${
        process.env.REACT_APP_USER_SERVICE_API
      }/alarm/user/${window.sessionStorage.getItem("idx")}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { totalRows, data } = response.data;

          let ary = [];
          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: i,
              senderIdx: data[i].sendIdx,
              senderType: data[i].senderType,
              nickName: data[i].nickname,
              content: data[i].content,
              dateTime: convertDashToDot(data[i].date),
              img: Object.keys(data[i]).includes("senderImage")
                ? `https://volunteer.seocho.go.kr:8443/seocho-scp-user-service/upload_files${data[i].senderImage}`
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
              activityIdx: Object.keys(data[i]).includes("activity_idx")
                ? data[i].activity_idx
                : null,
              pkIdx: Object.keys(data[i]).includes("pkIdx")
                ? data[i].pkIdx
                : null,
              alarmType: Object.keys(data[i]).includes("AlarmType")
                ? data[i].AlarmType
                : null,
            });
          }

          setTotalRows(totalRows);
          setAlarmList(ary);
        }
      } catch (e) {
        alert("알림 목록을 조회하는 중, 오류가 발생하였습니다.");
        console.log(e);

        setAlarmList([]);
        setTotalRows(0);
      }
    };
  }, [urlContext.value]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <div className="page page--main" data-page="conversations">
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-bottom-nav">
          <div className="page__title-bar">
            <h2>알림({totalRows})</h2>
          </div>
          <ul className="conversations">
            {alarmList &&
              alarmList.map((alarmInfo) => (
                <NotificationItem folder="sole" alarmInfo={alarmInfo} />
              ))}
          </ul>
        </div>
      </div>
      {/* PAGE END */}
      <div id="bottom-toolbar_sole" className="bottom-toolbar_org">
        <Navigation folder={"sole"} />
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default Notification;
