import React from "react";

import ChatRoomListItem from "./ChatRoomListItem";

const ChatRoomList = ({ chatRoomList, userStatus }) => {
  return (
    <ul className="conversations">
      {chatRoomList.map((roomInfo) => (
        <ChatRoomListItem
          roomInfo={roomInfo}
          userStatus={userStatus}
          key={roomInfo.topic}
        />
      ))}
    </ul>
  );
};

export default ChatRoomList;
