import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { onHandleImgError } from "../util/util-function";

function GroupMemberList(props) {
  const { activityIdx, scheduleIdx, groupIdx } = useParams();
  const { participation, memberInfo, changeVolunteerStatus } = props;

  const navigate = useNavigate();

  const soleClickHandler = () => {
    navigate(`/org/otherprofile/sole/${memberInfo.idx}`, {
      state: {},
    });
  };

  const chatDClickHandler = () => {
    navigate(`/org/chat/chatd/o2s`, {
      state: {
        orgId: window.sessionStorage.getItem("idx"), // 기관 id
        userId: memberInfo.idx, // 사용자 id
      },
    });
  };

  const onClickChangeVolunteerStatus = (status) => {
    let data = new Object();

    data.activityScheduleIdx = scheduleIdx;
    data.userIdx = memberInfo.idx;
    data.updatedUid = window.sessionStorage.getItem("idx");

    changeVolunteerStatus(status, data);
  };

  return (
    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
      <div
        className="user-details__thumb"
        onClick={soleClickHandler}
        style={{ cursor: "pointer" }}
      >
        <img
          src={memberInfo.img}
          alt=""
          onError={(e) => onHandleImgError(e, "U")}
        />
      </div>
      <div className="card__details">
        <Link to={`/org/otherprofile/sole/${memberInfo.idx}`}>
          <h4 className="card__username fc_point1">{memberInfo.nickName}</h4>
        </Link>
        <p className="card__info">{memberInfo.grade}</p>
        <p className="card__info">{memberInfo.contact}</p>
        {memberInfo && memberInfo.volunteer === "F" && (
          <p className="card__info">참여 완료</p>
        )}
        <div
          onClick={chatDClickHandler}
          style={{ cursor: "pointer" }}
          className="button button--small button--main float-right ml-10"
        >
          메세지
        </div>
        {memberInfo && memberInfo.volunteer !== "F" && (
          <div
            onClick={() =>
              onClickChangeVolunteerStatus(participation ? "none-part" : "part")
            }
            style={{ cursor: "pointer" }}
            className="button button--small button--gray float-right ml-10"
          >
            {memberInfo.volunteer === "Y" ? "미참여 처리" : "미참여 처리 취소"}
          </div>
        )}
      </div>
    </div>
  );
}

export default GroupMemberList;
