import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import Panel from "../../../components/Panel";
import axios from "axios";
import SoleProfile from "../../../components/SoleProfile";
import ConformPopup from "../../common/popup/ConformPopup";
import ActHistoryList from "../../../components/ActHistoryList";
import CheckLoginInfo from "../../../services/login-validation";

function Sole(props) {
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [actHistoryData, setActHistoryData] = useState([]);

  const [actHistoryPage, setActHistoryPage] = useState(1);

  const offset = 4;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 회원 상세 조회
  const getUserData = () => {
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${sessionStorage.idx}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data);
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 회원 활동이력 조회
  const getActHistoryData = () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/activity_history/${sessionStorage.idx}`;
    axios
      .get(url)
      .then((res) => {
        const resActivityData = res.data.data;
        setActHistoryData(resActivityData);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [selActHistoryIdx, setSelActHistory] = useState("");

  // type => 완료하기 : y , 결석 : n
  const updateVolunteer = (type) => {
    if (selActHistoryIdx === "" || selActHistoryIdx === undefined) {
      return;
    }

    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/volunteer/${type}/${selActHistoryIdx}`;
    axios
      .put(URL)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getUserData();
        getActHistoryData();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content page_section">
          <header className="header header--fixed">
            <div className="header__inner">
              <div className="header__icon" />
              <div className="header__icon">
                <Link to="/sole/profileedit/setting">
                  <img src="/assets/images/icons/black/settings.svg" alt="" />
                </Link>
              </div>
            </div>
          </header>
          <SoleProfile user={user} type="profile" folder="sole" />
          <div className="section__content">
            <ul className="list-menu">
              <li className="list-menu__row">
                <Link className="list-menu__row" to="/sole/profilesole/orglist">
                  <div className="list-menu__details">
                    <div className="list-menu__t">내가 소속된 기관 보기</div>
                  </div>
                  <div className="list-menu__info default__icon">
                    <div className="list-menu__icon">
                      <img
                        src="/assets/images/icons/black/arrow-right.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </li>
              <li className="list-menu__row">
                <Link
                  className="list-menu__row"
                  to="/sole/profilesole/actrequestapplication"
                >
                  <div className="list-menu__details">
                    <div className="list-menu__t">활동 참여 신청/요청 목록</div>
                  </div>
                  <div className="list-menu__info default__icon">
                    <div className="list-menu__icon">
                      <img
                        src="/assets/images/icons/black/arrow-right.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </li>

              {window.sessionStorage.getItem("coinStatus") === "Y" && (
                <li className="list-menu__row">
                  <Link
                    className="list-menu__row"
                    to="/sole/profile-sole/coin-reward"
                  >
                    <div className="list-menu__details">
                      <div className="list-menu__t">서초 코인 적립 내역</div>
                    </div>
                    <div className="list-menu__info default__icon">
                      <div className="list-menu__icon">
                        <img
                          src="/assets/images/icons/black/arrow-right.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              )}
              {/* <li className="list-menu__row">
                <div className="list-menu__details">
                  <div className="list-menu__t">
                    <Link to="/sole/profilesole/requestoperationlist">
                      도움 요청한 일정
                    </Link>
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <Link to="/sole/profilesole/requestoperationlist">
                      <img
                        src="/assets/images/icons/black/arrow-right.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </li> */}
              {/* <li className="list-menu__row">
                <div className="list-menu__details">
                  <div className="list-menu__t">
                    <Link to="/sole/profilesole/okhistory">
                      서초OK생활자문단 상담
                    </Link>
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <Link to="/sole/profilesole/okhistory">
                      <img
                        src="/assets/images/icons/black/arrow-right.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
          <div className="section__content mt-100">
            <div className="page__title-bar">
              <h2>활동이력({actHistoryData.length})</h2>
            </div>
            <div className="cards cards--11">
              {actHistoryData &&
                actHistoryData
                  .map((actHistory, idx) => (
                    <ActHistoryList
                      key={idx}
                      folder="sole"
                      actHistory={actHistory}
                      type="profile"
                      setSelActHistory={setSelActHistory}
                    />
                  ))
                  .slice(0, actHistoryPage * offset)}
              <div className="buttons buttons--centered mb-20">
                {actHistoryData.length > actHistoryPage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setActHistoryPage, actHistoryPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="space-h-100"></div>
        </div>
      </div>
      {/* PAGE END */}
      <div id="bottom-toolbar_sole" className="bottom-toolbar_org">
        <Navigation folder={"sole"} />
      </div>
      <ConformPopup
        popupType="completion"
        text="정말 완료하시겠습니까?"
        conformClick={() => updateVolunteer("y")}
      />
      <ConformPopup
        popupType="absent"
        text="정말 결석하시겠습니까?"
        conformClick={() => updateVolunteer("n")}
      />
    </Fragment>
  );
}

export default Sole;
