import React, { Fragment, useState } from "react";

function FormatDate(props) {  
  
  const { datetime, date } = props;
  const [ year, setYear ] = useState();

  const datetimeform = (datetime) => {
    const changedate = new Date(datetime);
    let year = changedate.getFullYear(); 
    let month = changedate.getMonth() + 1; 
    let day = changedate.getDate();
    let hour = changedate.getHours();
    let min = changedate.getMinutes();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour > 12 ? '오후 ' + (hour - 12) : '오전 ' + hour;

    return `${year}.${month}.${day} 
            ${hour}시 ${min}분`
  }

  const dateform = (date) => {
    const changedate = new Date(date);
    let year = changedate.getFullYear(); 
    let month = changedate.getMonth() + 1; 
    let day = changedate.getDate();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;

    return `${year}년 ${month}월 ${day}일`
  }

  return (
    <Fragment>
      {
        datetime&&datetime ?
        <>
          {datetimeform(datetime)}
        </>
        : null
      }
      {
        date&&date ?
        <>
          {dateform(date)}
        </>
        : null
      }
    </Fragment>
  )
}

export default FormatDate;