import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function FaqDetail() {
  const { communityIdx } = useParams();
  const [faqInfo, setFaqInfo] = useState(null);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getFaqInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getFaqInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/${communityIdx}`;

      try {
        const response = await axios.get(url);

        setFaqInfo({
          idx: response.data.idx,
          title: response.data.title,
          moreInformation: response.data.moreInformation,
        });
      } catch (e) {
        alert("자주 묻는 질문(FAQ) 상세조회중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
  }, [communityIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="자주 묻는 질문(FAQ)" />
        {/* PAGE CONTENT */}
        {faqInfo && (
          <div className="page__content  page_section--with-header-68">
            {/* SLIDER SIMPLE */}
            <div className="page_section">
              <div className="d-flex justify-space align-items-center pt-40"></div>
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li className="mt-20">
                    <h2>{faqInfo.title}</h2>
                  </li>
                </ul>
              </div>
            </div>
            <div className="page_section">
              <div className="d-flex justify-space align-items-center pt-40"></div>
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: faqInfo.moreInformation,
                      }}
                    ></div>
                  </li>
                </ul>
              </div>
              <div className="space-h-100" />
            </div>
            <div className="space-h-100" />
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default FaqDetail;
