import React, { Fragment, useState, useEffect } from "react";
import ActivityMemberList from "../../../components/ActivityMemberList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import UserListHeader from "../../../components/UserListHeader";
import CheckLoginInfo from "../../../services/login-validation";
import PopupListChat from "../../common/popup/PopupListChat";

function UserListGroup(props) {
  const [showOpion, setShowOption] = useState(false);
  const toggleChatOption = () => {
    setShowOption(!showOpion);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <UserListHeader title="그룹 회원" toggleChatOption={toggleChatOption} />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar pt-40 mb-24">
              <h2 className="page__title">회원(12)</h2>
            </div>
            <div className="cards cards--11">
              <ActivityMemberList btnType="userlist" />
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
      {showOpion && (
        <PopupListChat
          popupType="activity_edit"
          toggleChatOption={toggleChatOption}
        />
      )}
    </Fragment>
  );
}

export default UserListGroup;
