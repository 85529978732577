import React, { Fragment } from "react";
import { convertDashToDot } from "../util/convert-date-function";

function CoinActList(props) {
  const { actInfo, selectAct, selectedId } = props;

  return (
    <Fragment>
      <div className="card-selector">
        <input
          type="radio"
          name="excard"
          id="c1"
          value={actInfo.id}
          checked={actInfo.id === selectedId}
        />
        <label htmlFor="c1" onClick={() => selectAct(actInfo.id)}>
          {actInfo.title}
          <span>
            {actInfo.address} {convertDashToDot(actInfo.activityDate)}
          </span>
          <span className="coincount">+{actInfo.coin}코인</span>
        </label>
      </div>
    </Fragment>
  );
}

export default CoinActList;
