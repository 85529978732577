import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

function CategoryItem(props) {
  const { category, url } = props;

  const defaultImgPath = "/assets/images/food/img-default.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (category) {
      if (category.imageUrl !== undefined && category.imageUrl !== "") {
        setImgPath(category.imageUrl);
      }
    }
  }, []);

  const chageDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <Fragment>
      <div className="swiper-slide slider-thumbs__slide slider-thumbs__slide--3h">
        <div className="slider-thumbs__image slider-thumbs__image--round-corners">
          <Link
            to={`${url}/${category && category.idx}`}
            state={{ category: category }}
          >
            <img src={imgPath} alt="" title="" onError={chageDefaultImg} />
          </Link>
        </div>
        <div className="slider-thumbs__caption caption">
          <div className="caption__content">
            <h2 className="caption__title caption__title--smaller">
              {category && category.category}
            </h2>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CategoryItem;
