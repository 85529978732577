import React from 'react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import ActivityConformList from '../../../components/ActivityConformList';
import ActivityDetail from '../../../components/ActivityDetail';
import Navigationbar from '../../../components/Navigationbar';
import OrgList from '../../../components/OrgList';
import Panel from '../../../components/Panel';
import SubHeader from '../../../components/SubHeader';

function RequestOperationDetailConformPassed(props) {
  const navigate = useNavigate();

  const soleClickHandler = () => {
    navigate(`/org/otherprofile/sole`, {
      state: {

      }
    })
  }

  const detailBeneClickHandler = () => {
    navigate(`/org/activity/detailbene`, {
      state: {

      }
    })
  }

  const activityRequestClickHandler = () => {
    navigate(`/org/otherprofile/activityrequest`, {
      state: {

      }
    })
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 상세" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="cards cards--11 mt-40">
              <div className="d-flex justify-space align-items-center">
                <h2 className="mb-0">의뢰 요청 기관/단체 :</h2>
              </div>
              <OrgList />
            </div>
            <div className="cards cards--6" id="loadlist-01">
              <div className="table card card--style-thumb mb-20">
                <div className="card__details text-aline-center">
                  <h2 className="point-blue">도움 요청된 활동이 승인 되었습니다.</h2>
                </div>
              </div>
            </div>
            <div onClick={detailBeneClickHandler} style={{ cursor: 'pointer' }} className="button button--full button--main float-right ml-10 mt-40 mb-24">활동 보러가기</div>
            <ActivityDetail />
          </div>
          <div className="page_section">
            <div className="page__title-bar mt-48">
              <h2>일정(12)</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              <ActivityConformList />
              <ActivityConformList />
              <div className="buttons buttons--centered mb-20">
                <div className="button button--more">더 보기</div>
              </div>
            </div>
            <div className="under_button">
              <div className="form__row mt-40">
                <input onClick="location.href='#'" style={{ cursor: 'pointer' }} type="submit" name="submit" data-popup="conform_delete" className="form__submit button button--gray button--50 open-popup" id="submit" value="삭제" />
                <input onClick={activityRequestClickHandler} style={{ cursor: 'pointer' }} type="submit" name="submit" className="form__submit button button--main button--50" id="submit" value="수정하여 재요청 하기" />
              </div>
            </div>
            <div className="space-h-100" />
          </div>
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default RequestOperationDetailConformPassed;