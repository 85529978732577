import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

const PrivacyPolicy = () => {
  const [notAgreeTerms, setNotAgreeTerms] = useState(true);
  const [checkedTerms, setCheckedTerms] = useState(false);

  const [notAgreePersonalInfo, setNotAgreePersonalInfo] = useState(true);
  const [checkedPersonalInfo, setCheckedPersonalInfo] = useState(false);

  const handleNotAgreeClick = () => {
    setNotAgreePersonalInfo(!notAgreePersonalInfo);
    setCheckedPersonalInfo(false);
  };

  const handleAgreeClick = () => {
    setCheckedPersonalInfo(!checkedPersonalInfo);
    setNotAgreePersonalInfo(false);
  };

  const handleNotAgreeTermsClick = () => {
    setNotAgreeTerms(!notAgreeTerms);
    setCheckedTerms(false);
  };

  const handleAgreeTermsClick = () => {
    setCheckedTerms(!checkedTerms);
    setNotAgreeTerms(false);
  };

  const handleAgreeAll = () => {
    setNotAgreePersonalInfo(false);
    setCheckedPersonalInfo(true);
    setNotAgreeTerms(false);
    setCheckedTerms(true);
  };

  const navigate = useNavigate();

  const [policyContent, setPolicyContent] = useState(null);
  const [termsContent, setTermsContent] = useState(null);

  const checkedAll = (data) => {
    setCheckedPersonalInfo(data);
    setCheckedTerms(data);
  };

  const getPersonalInfo = useCallback(async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/personal-info`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        setPolicyContent(
          Object.keys(response.data).includes("moreInformation")
            ? response.data.moreInformation
            : ""
        );
      }
    } catch (e) {
      alert("개인정보 처리방침 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, []);

  const getAccessTerms = useCallback(async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/terms`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        setTermsContent(
          Object.keys(response.data).includes("moreInformation")
            ? response.data.moreInformation
            : ""
        );
      }
    } catch (e) {
      alert("개인정보 처리방침 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getPersonalInfo();
    getAccessTerms();
  }, [getPersonalInfo, getAccessTerms]);

  return (
    <div className="page page--main" data-page="shop">
      <header className="header header--page header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <Link to="" onClick={() => navigate(-1)}>
              <img
                src={`../assets/images/icons/white/arrow-back.svg`}
                alt=""
                title=""
              />
            </Link>
          </div>
          <div className="header__logo header__logo--text">
            <a href="#">회원가입</a>
          </div>
          <div className="header__icon header__icon--empty-space"></div>
        </div>
      </header>
      <div className="page__content page_section page__content page_section--with-header-68 ">
        <div className="pt-40">
          <div className="form">
            <form className="">
              <h4></h4>
              <div className="checkbox-option">
                <span
                  className="privacy-form"
                  dangerouslySetInnerHTML={{
                    __html: policyContent,
                  }}
                ></span>
                <div className="button button--50">
                  <input type="checkbox" checked={notAgreePersonalInfo} />
                  <label
                    htmlFor="cp81"
                    checked={notAgreePersonalInfo}
                    onClick={handleNotAgreeClick}
                  >
                    &#10003; 미동의
                  </label>
                </div>
                <div className="button button--50">
                  <input type="checkbox" checked={checkedPersonalInfo} />
                  <label
                    htmlFor="cp81"
                    checked={checkedPersonalInfo}
                    onClick={handleAgreeClick}
                  >
                    &#10003; 동의
                  </label>
                </div>
                <span
                  className="privacy-form"
                  dangerouslySetInnerHTML={{
                    __html: termsContent,
                  }}
                ></span>

                <div className="button button--50">
                  <input type="checkbox" checked={notAgreeTerms} />
                  <label
                    htmlFor="cp81"
                    checked={notAgreeTerms}
                    onClick={handleNotAgreeTermsClick}
                  >
                    &#10003; 미동의
                  </label>
                </div>
                <div className="button button--50">
                  <input type="checkbox" checked={checkedTerms} />
                  <label
                    htmlFor="cp81"
                    checked={checkedTerms}
                    onClick={handleAgreeTermsClick}
                  >
                    &#10003; 동의
                  </label>
                </div>
                <input
                  type="checkbox"
                  // id="cp81"
                  checked={checkedTerms && checkedPersonalInfo}
                />
                <label htmlFor="cp81" onClick={handleAgreeAll}>
                  &#10003; 전체 동의하기
                </label>
              </div>
            </form>
          </div>
        </div>
        <div className="login-form__row">
          <Link to="/">
            <input
              name="submit"
              type="button"
              className="login-form__submit button button--gray button--50"
              id="submit"
              value="취소"
            />
          </Link>
          <Link
            to={checkedPersonalInfo && checkedTerms ? "/common/signup" : "#"}
          >
            <input
              name="submit"
              type="button"
              className={
                checkedPersonalInfo && checkedTerms
                  ? "login-form__submit button button--main button--50"
                  : "login-form__submit button button--white button--50"
              }
              id="submit"
              value="다음"
            />
          </Link>
        </div>
        <div className="space-h-100"></div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
