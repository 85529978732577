import React, { Fragment, useEffect, useState } from "react";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function PrivateSole(props) {
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content">
          <SubHeader />
          <div className="user-profile mb-20 mt-40">
            <div className="user-profile__thumb">
              <img src="/assets/images/photos/img-p-sole.jpg" alt="" />
            </div>
            <div className="user-profile__name">ID</div>
            <div className="buttons buttons--centered">
              <div className="info-box">
                <span>활동시간</span> 12시간
              </div>
              <div className="info-box">
                <span>레벨</span> 레벨3
              </div>
            </div>
          </div>
          <div className="user-profile__info2">
            이 계정은 비공개 계정입니다.
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
    </Fragment>
  );
}

export default PrivateSole;
