import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import ConformPopup from '../pages/common/popup/ConformPopup';
import UrlContext from "../context/Url.context";
import { Link } from 'react-router-dom';

function CategoryList(props) {
  const urlContext = useContext(UrlContext);
  const { category, setDelCategoryIdx } = props;

  const defaultImgPath = "/assets/images/photos/img-default.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  const delClickHandler = () => {
    setDelCategoryIdx(category.idx);
  }

  // 파일명 조회
  useEffect(() => {
    if (category) {
      if (category.imageUrl !== undefined && category.imageUrl !== "") {
        setImgPath(category.imageUrl)
      }
    }
  }, [])

  const chageDefaultImg = () => {
    setImgPath(defaultImgPath);
  }

  return (
    <Fragment>
      <div className="card card--style-thumb">
        <div className="card__thumb card__thumb--round-corners">
          <img src={imgPath} alt="" onError={chageDefaultImg} />
        </div>
        <h4 className="card__list"><strong>{category && category.category}</strong></h4>
        <Link className="caption__category mini_button open-popup" data-popup="conform_delete" to="" onClick={delClickHandler}>삭제</Link>
      </div>
    </Fragment>
  );
}

export default CategoryList;