import React from "react";
import { useNavigate } from "react-router-dom";

function ChooseGroupList(props) {
  const {
    group,
    activityIdx,
    activityScheduleIdx,
    actRequestIdx,
    page,
    isMyGroup,
  } = props;
  const navigate = useNavigate();

  const itemClickHandler = () => {
    navigate("/org/activity/placingmember", {
      state: {
        groupName: group && group.name,
        activityIdx: activityIdx,
        activityScheduleIdx: activityScheduleIdx,
        actRequestIdx: actRequestIdx,
        page: page,
        isMyGroup: isMyGroup,
      },
    });
  };

  return (
    <div
      className="swiper-wrapper mb-24"
      style={{ transform: "translate3d(0px, 0px, 0px)" }}
    >
      <div className="swiper-slide cart__item-details swiper-slide-active">
        <h4 className="cart__item-title">
          {" "}
          {group && `${group.name} (${group.total})`}
        </h4>
        <div className="cart__item-qty">
          <div
            onClick={itemClickHandler}
            className="button button--small button--main float-right ml-10 float-right"
          >
            추가
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseGroupList;
