import React, { useEffect, useState } from "react";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function ScanCode(props) {
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <div>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="QR코드 인증하기" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="fieldsets mt-48">
            <div className="card__thumb card__thumb--round-corners">
              <a href="act-detail.html">
                <img
                  style={{ width: "100%" }}
                  src="/assets/images/food/code_camera.png"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
        {/* PAGE END */}
        <Navigationbar />
      </div>
    </div>
  );
}

export default ScanCode;
