import React, { useEffect } from 'react';
import KakaoMapScript from './KakaoMapScript';

function Map(props) {
  const {width, height, address} = props;
  

  useEffect(() => {
    KakaoMapScript(address);
}, [address]);

  return (
    <div id='myMap' 
      style={{
        width: width,
        height: height
    }}>
    </div>
  );
}

export default Map;