import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CategoryList from "../../../components/CategoryList";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";
import ConformPopup from "../../common/popup/ConformPopup";

function EventMatching() {
  const navigate = useNavigate();

  // DB 데이터
  const [categoryData, setCategoryData] = useState([]);
  const [addedCategoryData, setAddedCategoryData] = useState([]);

  // 삭제 버튼 누른 카테고리 IDX
  const [delCategoryIdx, setDelCategoryIdx] = useState(0);
  // 기간 선택 date show / hide
  const [selectDateShowYn, setSelectDateShowYn] = useState("none");
  // 기간 선택해주세요 텍스트 show / hide
  const [selectDateTextShowYn, setSelectDateTextShowYn] = useState("none");

  // 삭제한 IDX 리스트
  const [deleteIdxList, setDeleteIdxList] = useState(new Set());

  // Input Data
  const [values, setValues] = useState({
    matchingYn: true,
    selectDate: "A",
    category: "",
    startDate: "",
    endDate: "",
    chkMonday: "1",
    chkTueday: "1",
    chkWedday: "1",
    chkThuday: "1",
    chkFriday: "1",
    chkSatday: "1",
    chkSunday: "1",
    activityType: "A",
  });

  // 카테고리 조회
  const getCategoryData = () => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/category`;
    axios
      .get(url)
      .then((res) => {
        setCategoryData(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 카테고리 리스트 조회
  const getCategoryListData = () => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/list_category/mat/${sessionStorage.idx}`;
    axios
      .get(url)
      .then((res) => {
        const data = res.data.data;
        let ary = [];

        data.forEach((data) => {
          ary.push({
            idx: data.idx,
            category: data.category,
            imageUrl: data.imageUrl,
          });
        });

        setAddedCategoryData(ary);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getMatchingData = () => {
    // 활동 매칭 조회 AXIOS
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/matching/${sessionStorage.idx}`;

    axios
      .get(URL)
      .then((res) => {
        const data = res.data;

        setValues({
          matchingYn: data.activation === "O" ? true : false,
          chkMonday: data.mon === "1" ? true : false,
          chkTueday: data.tue === "1" ? true : false,
          chkWedday: data.wed === "1" ? true : false,
          chkThuday: data.thu === "1" ? true : false,
          chkFriday: data.fri === "1" ? true : false,
          chkSatday: data.sat === "1" ? true : false,
          chkSunday: data.sun === "1" ? true : false,
          activityType: data.activityType,
          selectDate: data.selectDate,
          startDate: data.startTime && data.startTime.split(" ")[0],
          endDate: data.endTime && data.endTime.split(" ")[0],
        });

        if (data.selectDate === "S") {
          setSelectDateShowYn("block");
          setSelectDateTextShowYn("block");
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeForm = (e) => {
    let { name, value, checked } = e.target;

    // 활동 추천받기 value set
    if (name === "matchingYn") {
      value = checked;
    }

    // 기간선택에 따라 날짜 선택 폼 show / hide
    if (name === "selectDate") {
      // 전체 클릭시 날짜 초기화
      if (value === "A") {
        setSelectDateShowYn("none");
        values.startDate = "";
        values.endDate = "";
      } else {
        setSelectDateShowYn("block");
        setSelectDateTextShowYn("block");
      }
    }

    // 시작날짜와 종료날짜 모두 선택 완료시에 날짜선택메시지 숨김
    if (name === "startDate") {
      if (value !== "" && values.endDate !== "") {
        setSelectDateTextShowYn("none");
      } else {
        setSelectDateTextShowYn("block");
      }
    }

    // 시작날짜와 종료날짜 모두 선택 완료시에 날짜선택메시지 숨김
    if (name === "endDate") {
      if (value !== "" && values.startDate !== "") {
        setSelectDateTextShowYn("none");
      } else {
        setSelectDateTextShowYn("block");
      }
    }

    // 희망 요일 선택인 경우
    if (name.indexOf("chk") !== -1) {
      value = checked;
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  // 활동 매칭 카테고리 추가
  const addCategory = (categoryIdx) => {
    if (categoryIdx === "" || categoryIdx === undefined) {
      alert("분야를 선택해주세요.");
      return;
    }

    // 이미 추가된 카테고리 여부
    const addedCategoryYn = addedCategoryData.filter(
      (category) => category.idx === Number(categoryIdx)
    ).length;

    if (addedCategoryYn !== 0) {
      alert("이미 추가된 분야입니다.");
      return;
    }

    // 추가시 삭제 리스트에서 해당 카테고리 IDX 제거
    deleteIdxList.delete(Number(categoryIdx));
    setDeleteIdxList(deleteIdxList);

    // 추가될 카테고리 필터링
    const addedCategory = categoryData.filter(
      (category) => category.idx === Number(categoryIdx)
    );

    if (addedCategory) {
      setAddedCategoryData(addedCategoryData.concat(addedCategory[0]));
    }
  };

  const delCategoryClickHandler = () => {
    // 삭제시 삭제 리스트에 해당 카테고리 IDX 추가
    deleteIdxList.add(Number(delCategoryIdx));
    setDeleteIdxList(deleteIdxList);

    setAddedCategoryData(
      addedCategoryData.filter(
        (addedCategory) => addedCategory.idx !== delCategoryIdx
      )
    );
  };

  const deleteListCategory = () => {
    let URL = `${process.env.REACT_APP_USER_SERVICE_API}/list_category`;

    deleteIdxList.forEach((deleteIdx) => {
      const data = {
        categoryIdx: deleteIdx,
        orgMatIdx: sessionStorage.idx,
        orgMatStatus: "M",
      };

      axios
        .delete(URL, {
          data: data,
        })
        .then((res) => { })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const insertListCateogry = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/list_category`;

    addedCategoryData &&
      addedCategoryData.forEach((category) => {
        const data = {
          categoryIdx: category.idx,
          orgMatIdx: sessionStorage.idx,
          createdUid: sessionStorage.idx,
          orgMatStatus: "M",
        };

        axios
          .post(URL, data)
          .then((res) => { })
          .catch((err) => console.log(err.response));
      });
  };

  const saveMatchingClickHandler = () => {
    // 기간 선택인데 기간 선택을 안했을 경우 return
    if (values.selectDate === "S") {
      if (values.startDate === "" || values.endDate === "") {
        alert("시작하는 기간과 끝나는 기간을 선택해주세요.");
        return;
      }
    }

    // 활동 매칭 json
    const matching = {
      activation: values.matchingYn === true ? "O" : "N",
      selectDate: values.selectDate,
      startTime: values.startDate === "" ? undefined : values.startDate,
      endTime: values.endDate === "" ? undefined : values.endDate,
      endDate: values.endDate,
      activityType: values.activityType,
      mon: values.chkMonday === true ? "1" : "0",
      tue: values.chkTueday === true ? "1" : "0",
      wed: values.chkWedday === true ? "1" : "0",
      thu: values.chkThuday === true ? "1" : "0",
      fri: values.chkFriday === true ? "1" : "0",
      sat: values.chkSatday === true ? "1" : "0",
      sun: values.chkSunday === true ? "1" : "0",
      updatedUid: sessionStorage.idx,
    };

    // 활동 매칭 등록 AXIOS
    let URL = `${process.env.REACT_APP_USER_SERVICE_API}/matching/${sessionStorage.idx}`;
    axios
      .put(URL, matching)
      .then((res) => {
        // 리스트_카테고리 전체 삭제 후 재등록 (수정)
        // let URL = `${process.env.REACT_APP_USER_SERVICE_API}/list_category/multiple_deleted/${sessionStorage.idx}?orgMatStatus=M`
        deleteListCategory();
        insertListCateogry();

        navigate("/sole/profileedit/setting");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getCategoryData();
        getMatchingData();
        getCategoryListData();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 매칭 설정" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="fieldsets pt-40">
            <ul className="">
              <li className="list-menu__row">
                <div className="list-menu__details">
                  <div className="list-menu__t">활동 추천 받기</div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <div className="switch">
                      <input
                        type="checkbox"
                        hidden="hidden"
                        id="enable"
                        name="matchingYn"
                        checked={values.matchingYn}
                        onChange={handleChangeForm}
                      />
                      <label className="switch__label" htmlFor="enable" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            {/* 예정된 활동에 대한 미리 알림 활성화 시에만 활동 요청서 보여주기 */}
            <div className="d-flex justify-space align-items-center mt-20 mb-20">
              <h2 className="mb-0">희망 활동 설정</h2>
            </div>
            <div className="form">
              <form id="ContactForm">
                <h4>분야</h4>
                <div className="form__row d-flex align-items-center justify-space  mb-20">
                  <div className="form__select form__input form__select--23 mr-16">
                    <select
                      name="category"
                      className="required"
                      value={values.category}
                      onChange={handleChangeForm}
                    >
                      <option value="" disabled>
                        선택
                      </option>
                      {categoryData &&
                        categoryData.map((category) => (
                          <option value={category.idx}>
                            {category.category}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form__button--13">
                    <input
                      type="button"
                      name="button"
                      className="form__submit button button--main"
                      id="submit"
                      value="추가"
                      onClick={() => addCategory(values.category)}
                    />
                  </div>
                </div>
                <div className="cards cards--14 mb-20">
                  {addedCategoryData &&
                    addedCategoryData.map((category) => (
                      <CategoryList
                        key={category.idx}
                        category={category}
                        setDelCategoryIdx={setDelCategoryIdx}
                      />
                    ))}
                </div>
                <h4>기간 선택</h4>
                <p
                  className="font-red"
                  style={{ display: selectDateTextShowYn }}
                >
                  시작하는/끝나는 기간을 선택해주세요.
                </p>
                <div className="form__row d-flex align-items-center justify-space mb-40">
                  <div className="form__select form__input form__select--23 mr-16">
                    <select
                      name="selectDate"
                      className="required"
                      value={values.selectDate}
                      onChange={handleChangeForm}
                    >
                      <option value="A">항상</option>
                      <option value="S">기간 선택</option>
                    </select>
                  </div>
                  <input
                    type="date"
                    name="startDate"
                    className="form__input_50 form__select--23 mr-16"
                    id="submit"
                    style={{ display: selectDateShowYn }}
                    value={values.startDate}
                    onChange={handleChangeForm}
                  />
                  <input
                    type="date"
                    name="endDate"
                    className="form__input_50 form__select--23 mr-16"
                    id="submit"
                    style={{ display: selectDateShowYn }}
                    value={values.endDate}
                    onChange={handleChangeForm}
                  />
                </div>
                <h4>희망 요일</h4>
                <div className="form__row justify-space mb-40">
                  <div className="checkbox-option">
                    <input
                      type="checkbox"
                      name="chkMonday"
                      id="cp4"
                      checked={values.chkMonday}
                      onChange={handleChangeForm}
                    />
                    <label htmlFor="cp4">월</label>
                  </div>
                  <div className="checkbox-option">
                    <input
                      type="checkbox"
                      name="chkTueday"
                      id="cp5"
                      checked={values.chkTueday}
                      onChange={handleChangeForm}
                    />
                    <label htmlFor="cp5">화</label>
                  </div>
                  <div className="checkbox-option">
                    <input
                      type="checkbox"
                      name="chkWedday"
                      id="cp6"
                      checked={values.chkWedday}
                      onChange={handleChangeForm}
                    />
                    <label htmlFor="cp6">수</label>
                  </div>
                  <div className="checkbox-option">
                    <input
                      type="checkbox"
                      name="chkThuday"
                      id="cp7"
                      checked={values.chkThuday}
                      onChange={handleChangeForm}
                    />
                    <label htmlFor="cp7">목</label>
                  </div>
                  <div className="checkbox-option">
                    <input
                      type="checkbox"
                      name="chkFriday"
                      id="cp8"
                      checked={values.chkFriday}
                      onChange={handleChangeForm}
                    />
                    <label htmlFor="cp8">금</label>
                  </div>
                  <div className="checkbox-option">
                    <input
                      type="checkbox"
                      name="chkSatday"
                      id="cp9"
                      checked={values.chkSatday}
                      onChange={handleChangeForm}
                    />
                    <label htmlFor="cp9">토</label>
                  </div>
                  <div className="checkbox-option">
                    <input
                      type="checkbox"
                      name="chkSunday"
                      id="cp10"
                      checked={values.chkSunday}
                      onChange={handleChangeForm}
                    />
                    <label htmlFor="cp10">일</label>
                  </div>
                </div>
                <h4>활동 방식</h4>
                <div className="form__row d-flex align-items-center justify-space  mb-20">
                  <div className="form__select form__input form__select--23 mr-16">
                    <select
                      name="activityType"
                      className="required"
                      value={values.activityType}
                      onChange={handleChangeForm}
                    >
                      <option value="A">전체</option>
                      <option value="O">온라인</option>
                      <option value="F">오프라인</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="form__row mt-40">
              <input
                type="submit"
                name="submit"
                data-popup="conform_cancle"
                className="form__submit button button--gray button--50 open-popup"
                id="submit"
                value="취소"
              />
              <input
                type="submit"
                name="submit"
                className="form__submit button button--main button--50 open-popup"
                data-popup="conform_save"
                id="submit"
                value="저장"
              />
            </div>
            {/* 예정된 활동에 대한 미리 알림 활동 요청서 끝 */}
          </div>
          <div className="space-h-100" />
        </div>
        <ConformPopup
          popupType="delete"
          text="정말 삭제하시겠습니까?"
          conformClick={delCategoryClickHandler}
        />
        <ConformPopup popupType="cancle" text="정말 취소하시겠습니까?" />
        <ConformPopup
          popupType="save"
          text="해당 내용을 저장하시겠습니까?"
          conformClick={saveMatchingClickHandler}
        />
      </div>
    </Fragment>
  );
}

export default EventMatching;
