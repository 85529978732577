import React from "react";
import { Link } from "react-router-dom";

function ForgotIdD(props) {
  const { closeModal, nickName } = props;

  return (
    <div className="page page--login" data-page="login">
      {/* HEADER */}
      <header className="header header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <Link to="" onClick={() => closeModal()}>
              <img
                src="../../assets/images/icons/gradient-orange/arrow-back.svg"
                alt=""
              />
            </Link>
          </div>
        </div>
      </header>
      <div className="login">
        <div className="login__content">
          <h2 className="login__title">ID 확인</h2>
          <p className="login__text">회원님의 ID는 "{nickName}"입니다!</p>
          <div className="login-form">
            <form id="LoginForm">
              <div className="login-form__row">
                <Link to="/">
                  <input
                    className="login-form__submit button button--main button--full"
                    value="로그인하기"
                  />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotIdD;
