import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

const NoticeDetail = () => {
  const { communityIdx } = useParams();

  const [noticeInfo, setNoticeInfo] = useState(null);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getNoticeInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getNoticeInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/${communityIdx}`;

      try {
        const response = await axios.get(url);

        setNoticeInfo({
          idx: response.data.idx,
          title: response.data.title,
          contactName: response.data.contactName,
          alarmOption: response.data.alarm,
          moreInformation: response.data.moreInformation,
          createDate: response.data.createdDateStr,
          viewCount: response.data.viewCount,
        });
      } catch (e) {
        alert("공지사항 상세조회중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
  }, [communityIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page-section page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="공지사항" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          {noticeInfo && (
            <div className="page_section">
              <div className="d-flex justify-space align-items-center pt-40"></div>
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li className="mt-20">
                    <h2>{noticeInfo.title}</h2>
                  </li>
                  <li>
                    <span>담당자 : </span>
                    {noticeInfo.contactName}
                  </li>
                  <li>
                    <span>글번호 : </span>
                    {noticeInfo.idx}
                  </li>
                  <li>
                    <span>등록일 : </span>
                    {noticeInfo.createDate}
                  </li>
                  <li>
                    <span>조회수 : </span>
                    {noticeInfo.viewCount}
                  </li>
                  <li>
                    <span>상세 : </span>
                  </li>
                  <li>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: noticeInfo.moreInformation,
                      }}
                    ></div>
                  </li>
                </ul>
              </div>
              <div className="space-h-100" />
            </div>
          )}
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
};

export default NoticeDetail;
