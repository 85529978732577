import React, { Fragment, useEffect, useState } from "react";
import ExpertCategory from "../../../components/ExpertCategory";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function Expert(props) {
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content">
          <SubHeader title="" />
          <div className="user-profile mb-20 mt-40">
            <div className="user-profile__thumb">
              <img src="/assets/images/photos/img-p-expert.jpg" alt="" />
            </div>

            <div className="user-profile__name">
              <ExpertCategory expert="법률 전문가" />
            </div>
            <div className="user-profile__name">ID</div>
            <div className="user-profile__info">소개글</div>
            <div className="buttons buttons--centered">
              <div className="info-box">
                <span>전화상담</span> 12건
              </div>
              <div className="info-box">
                <span>온라인 상담</span> 12건
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default Expert;
