import axios from "axios";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function ActivityScheduleList(props) {
  const { folder, schedule, activityData, setSelPartRequestIdx } = props;
  //console.log(activityData);

  const [recruitedCnt, setRecruitedCnt] = useState(schedule.recruitedNum);
  const navigate = useNavigate();

  const choosemembergroupClickHandler = () => {
    // 개인 참여일 경우
    if (folder === "sole") {
      if (
        window.confirm(
          `${schedule && schedule.activityDate} ${
            schedule && schedule.activityTime
          } 일정에 참여 신청하시겠습니까?`
        )
      ) {
        const actList = {
          activityIdx: activityData.idx,
          userIdx: sessionStorage.idx,
          activityScheduleIdx: schedule.activityScheduleIdx,
          orgIdx: "0",
          groupIdx: "0",
          volunteer: "W",
          time: "0",
          createdUid: sessionStorage.idx,
          updatedUid: sessionStorage.idx,
        };

        axios
          .post(`${process.env.REACT_APP_USER_SERVICE_API}/act_list`, actList)
          .then((res) => {
            //console.log(res.data);
            if (schedule.actRequestIdx !== undefined) {
              acceptPartReuqest();
            }
            navigate(0);
          })
          .catch((err) => {
            const errRes = err.response;
            console.log(errRes);
            if (errRes.status === 401) {
              alert(errRes.data);
            }
          });
      } else {
        // 취소
      }
    }
    // 기관 참여일 경우 그룹 선택 페이지로 이동
    else {
      if (setSelPartRequestIdx !== undefined) updateSelPartRequestIdx();

      navigate("/org/activity/choosemembergroup", {
        state: {
          activityIdx: activityData.idx,
          activityScheduleIdx: schedule.activityScheduleIdx,
          actRequestIdx: schedule.actRequestIdx,
        },
      });
    }
  };

  // 반려을 위한 참여 요청 idx 업데이트
  const updateSelPartRequestIdx = () => {
    setSelPartRequestIdx(schedule.actRequestIdx);
  };

  const viewBtn = () => {
    if (schedule.volunteer === undefined) {
      if (schedule.recruit !== "모집 마감") {
        return (
          <div
            className="button button--small button--main float-right ml-10"
            onClick={choosemembergroupClickHandler}
          >
            참여 신청
          </div>
        );
      }
    } else {
      return (
        <Fragment>
          <div
            className="button button--small button--main float-right ml-10"
            onClick={choosemembergroupClickHandler}
          >
            승인
          </div>
          <div
            className="button button--small button--secondary float-right ml-10 open-popup"
            data-popup="conform_dismissal"
            onClick={updateSelPartRequestIdx}
          >
            반려
          </div>
        </Fragment>
      );
    }
  };

  // 참여요청 목록 승인
  const acceptPartReuqest = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_request/volunteer/${schedule.actRequestIdx}`;

    const params = {
      volunteer: "D",
      updatedUid: sessionStorage.idx,
    };

    axios
      .put(URL, null, {
        params: params,
      })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err.reponse);
      });
  };

  return (
    <div className="table card card--style-thumb mb-20">
      <div className="card__details">
        <h2 className="fc_point1">
          {schedule && schedule.activityDate}{" "}
          {schedule && schedule.activityTime}
        </h2>
        <p className="card__list">
          활동 인원 :{" "}
          <a>
            {`참여(지정) ${recruitedCnt}명 / ${
              schedule && schedule.recruitNum === "0"
                ? "제한없음"
                : `필요인원 ${schedule.recruitNum}명`
            } (${schedule.recruit})`}
          </a>
        </p>
        <p className="card__list">
          수요자 :{" "}
          <Link to="/org/otherprofile/org">
            {activityData && activityData.orgTitle}
          </Link>
        </p>
        <p className="card__list">
          활동 시간 :{" "}
          <a>
            {`${schedule.activityTime} ~ ${schedule.activityTimeEnd}`}
          </a>
        </p>
        {viewBtn()}
      </div>
    </div>
  );
}

export default ActivityScheduleList;
