import React from "react";
import { Link, useNavigate } from "react-router-dom";

const PartUserListItem = ({
  userInfo,
  activityIdx,
  scheduleInfo,
  setSeletedInfo,
  updateStatusToCancelNonePart,
  updateStatusToNonePart,
}) => {
  const navigate = useNavigate();

  const soleClickHandler = () => {
    navigate(`/org/otherprofile/sole/${userInfo.userIdx}`, {
      state: {},
    });
  };

  const chatdClickHandler = () => {
    navigate(
      "/org/chat/chatd/a2s", // 활동 아이디
      {
        state: {
          activityId: activityIdx, // 활동 Id
          userId: userInfo.idx, // 참여하는 사용자 Id
        },
      }
    );
  };

  const onHandleImgError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`;
  };

  return (
    <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
      <div
        className="user-details__thumb"
        onClick={soleClickHandler}
        style={{ cursor: "pointer" }}
      >
        <img src={userInfo.img} alt="" onError={(e) => onHandleImgError(e)} />
      </div>
      <div className="card__details">
        <Link to={`/org/otherprofile/sole/${userInfo.userIdx}`}>
          <h4 className="card__username fc_point1">{userInfo.nickName}</h4>
        </Link>
        <p className="card__info">{`${scheduleInfo.date} ${scheduleInfo.time}`}</p>
        <p className="card__info">{userInfo.contact}</p>
        <div
          onClick={chatdClickHandler}
          style={{ cursor: "pointer" }}
          className="button button--small button--main float-right ml-10"
        >
          메세지
        </div>
        {
          {
            W: (
              <div
                onClick={() =>
                  // setSeletedInfo(userInfo.actListIdx, userInfo.status)
                  updateStatusToNonePart(userInfo.actListIdx)
                }
                style={{ cursor: "pointer" }}
                className="button button--small button--secondary float-right ml-10"
              >
                결석처리
              </div>
            ),
            N: (
              <div
                onClick={() =>
                  // setSeletedInfo(userInfo.actListIdx, userInfo.status)
                  updateStatusToCancelNonePart(userInfo.actListIdx)
                }
                style={{ cursor: "pointer" }}
                className="button button--small button--secondary float-right ml-10"
              >
                결석처리 취소
              </div>
            ),
          }[userInfo.status]
        }
      </div>
    </div>
  );
};

export default PartUserListItem;
