import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import OkOnlineList from "../../../components/OkOnlineList";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function Detail(props) {
  const navigate = useNavigate();
  const [okonlineList, setOkonlineList] = useState([]);
  const defaultImgPath = "/assets/images/food/img-default.jpg";
  const [bannerImgPath, setBannerImgPath] = useState(defaultImgPath);

  const detailAClickHandler = () => {
    navigate(`/sole/okonline/detaila`, {
      state: {},
    });
  };

  // 분야 셀렉트 박스
  const [areaSelBox, setAreaSelBox] = useState("");

  const areaSelBoxChangeHandler = (e) => {
    const { name, value } = e.target;
    setAreaSelBox(value);
  };

  const [totalRows, setTotalRows] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const getConsultationList = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation`;

    try {
      const res = await axios.get(url, {
        params: {
          page: pageNumber,
        },
      });

      if (res.status === 200) {
        const { totalRows, data, imageUrl } = res.data;

        setTotalRows(totalRows);
        if (imageUrl !== undefined) {
          setBannerImgPath(imageUrl);
        }

        if (areaSelBox === "") {
          if (pageNumber === 1) {
            setOkonlineList(data);
          } else {
            setOkonlineList(okonlineList.concat(data));
          }
        } else {
          if (pageNumber === 1) {
            setOkonlineList(
              data.filter((onlineCon) => onlineCon.area === areaSelBox)
            );
          } else {
            setOkonlineList(
              okonlineList
                .filter((onlineCon) => onlineCon.area === areaSelBox)
                .concat(
                  data.filter((onlineCon) => onlineCon.area === areaSelBox)
                )
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [areaSelBox, pageNumber]);

  const deleteClickHandler = (userIdx, id) => {
    if (window.confirm(`해당 온라인 상담 문의를 삭제하시겠습니까 ?`)) {
      let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/${userIdx}/${id}`;
      axios
        .delete(url)
        .then((res) => {
          const data = res.data.body;
          if (data.resultCode === "00") {
            alert("삭제 완료");
            getConsultationList();
          } else {
            alert("온라인상담 삭제중 오류가 발생하였습니다.");
            console.log(data.resultMsg);
          }
        })
        .catch((err) => {
          alert("온라인상담 삭제중 오류가 발생하였습니다.");
          console.log(err.response);
        });
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getConsultationList();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getConsultationList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader tilte="온라인 상담" location="/sole/home/solebene" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div class="act_thumbnail">
              <img src={bannerImgPath} alt="" title="" />
            </div>
            {/*
            <div
              className="swiper-container slider-simple slider-simple  mb-20 slider-init"
              data-paginationtype="bullets"
              data-spacebetweenitems={0}
              data-itemsperview={1}
            >
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide slider-simple__slide"
                  style={{ backgroundImage: `url(${bannerImgPath})` }}
                ></div>
              </div>
              <div className="swiper-pagination slider-simple__pagination" />
            </div>


          */}

            <div className="d-flex justify-space align-items-center">
              <h2 className="page__title mb-0">
                서초OK생활자문단 온라인 상담 문의
              </h2>
            </div>
            <div className="fieldsets">
              <ul className="custom-listing mb-20">
                <li>
                  <span>상담 분야 : </span>
                  <a className="caption__category tag_expert_law">법률</a>
                  <a className="caption__category tag_expert_tax">세무</a>
                  <a className="caption__category tag_expert_legal">법무</a>
                  <a className="caption__category tag_expert_cons">건축</a>
                  <a className="caption__category tag_expert_labor">노무</a>
                </li>
              </ul>
              <h4 className="mt-48">온라인 상담 답변제한 질의유형</h4>
              <ul className="custom-listing custom-listing--checked mb-20">
                <li>
                  행정기관, 공법인, 관리능력 또는 자력이 충분한 전문성을 가지고
                  있는 단체나 기업 등의 질의
                </li>
                <li>
                  행정관청, 수사기관, 법원 등에 계속 중인 사안의 처리결과 내지
                  양형을 예상하는 질의
                </li>
                <li>
                  단순한 실무상 절차에 관련된 사항으로서 담당기관에 문의함이
                  타당한 질의
                </li>
                <li>
                  내용이 불분명하거나 질문의 정확한 취지가 드러나지 않는 질의
                </li>
                <li>단순한 탄원성 질의</li>
                <li>
                  동일인이 동일한 내용으로 2회 이상 중복하여 하는 질의(동일인은
                  서로 다른 내용으로 각 분야별 월 2회까지 온라인 상담이
                  가능합니다.)
                </li>
                <li>
                  구체적 사건의 해결이 아닌 단순한 학리적 해석을 구하는 질의
                </li>
              </ul>
            </div>
            <div className="d-flex justify-space align-items-center pt-40">
              <h2 className="page__title">문의({totalRows})</h2>
              <div className="form__select width-200">
                <select
                  name="ContactSelect"
                  className="required"
                  value={areaSelBox}
                  onChange={areaSelBoxChangeHandler}
                >
                  <option value="" disabled>
                    전체
                  </option>
                  <option value="법률">법률</option>
                  <option value="세무">세무</option>
                  <option value="법무">법무</option>
                  <option value="건축">건축</option>
                  <option value="노무">노무</option>
                </select>
              </div>
            </div>
            <div className="cards cards--11">
              {okonlineList &&
                okonlineList.map((item, idx) => (
                  <OkOnlineList
                    item={item}
                    ok="online"
                    deleteClickHandler={deleteClickHandler}
                    key={idx}
                  />
                ))}
            </div>
            <div className="buttons buttons--centered mb-20">
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              >
                더보기
              </div>
            </div>
            <div className="buttons buttons--centered mt-20">
              <div
                id=""
                onClick={detailAClickHandler}
                className="button button--main button--full"
              >
                글쓰기
              </div>
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
    </Fragment>
  );
}

export default Detail;
