import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import Tag from "../../../components/Tag";
import axios from "axios";
import FormatDate from "../../../components/FormatDate";
import ConformCompletion from "../../common/popup/ConformCompletion";
import CheckLoginInfo from "../../../services/login-validation";

function OkPhoneReport(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { idx } = useParams();
  const expertdetail = location.state && location.state.expertdetail;
  const phoneconuser = location.state && location.state.phoneconuser;
  const item = location.state && location.state.item;
  const reply = location.state && location.state.reply;

  const tag = () => {
    return <Tag tagName={expertdetail && expertdetail.area} />;
  };

  const [value, setValue] = useState({
    usedAt: reply && reply.usedAt,
    consultdate: item && item.consultationDate,
    title: item && item.title,
    content: item && item.content,
    answer: item && Object.keys(item).includes("answer") ? item.answer : "",
  });

  const conformClick = () => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/phone_consultation/answer/${idx}`;
    let data = {
      usedAt: value.usedAt,
      consultationDate: value.consultdate,
      answer: value.answer,
      consultationStatus: "D",
      updatedUid: sessionStorage.idx,
    };
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .put(url, data, config)
      .then((res) => { })
      .catch((err) => {
        console.log(err);
      });
    let gourl = `/sole/profileexpert/expert/${sessionStorage.idx}`;
    navigate(gourl, {
      state: {},
    });
  };

  const handleChangeForm = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  // 취소
  const CancleClick = () => {
    navigate(`/sole/profileexpert/expert/${sessionStorage.idx}`);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="전화 상담 활동 내역" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="d-flex justify-space align-items-center pt-40">
            <p className="card__info">
              <FormatDate date={item && item.consultationDate} />{" "}
              {item && item.consultationTime}
            </p>
          </div>
          <div className="fieldsets">
            <ul className="custom-listing mb-20">
              <li>
                <span>이용자 : </span>
                {phoneconuser && phoneconuser.nickname}(
                {phoneconuser && phoneconuser.name})
              </li>
              <li>
                <span>상담 분야 : </span>
                {tag()}
              </li>
              <li>
                <span>
                  접수 일자 : <br />
                </span>
                <FormatDate datetime={item && item.createdAt} />
              </li>
              <li>
                <span>상담 내용 : </span>
                {value.content}
              </li>
            </ul>
          </div>
          <div className="form">
            <form id="ContactForm">
              <h4>소요시간</h4>
              <div className="form__row mb-20">
                <input
                  type="text"
                  name="usedAt"
                  className="form__input required"
                  placeholder="입력"
                  value={value.usedAt}
                  onChange={handleChangeForm}
                />
              </div>
              <h4>상담일자</h4>
              <div className="form__row d-flex align-items-center justify-space">
                <input
                  type="date"
                  name="consultdate"
                  className="form__input required"
                  placeholder="Date"
                  value={value.consultdate}
                  onChange={handleChangeForm}
                />
              </div>
              {/* <h4>상담 제목</h4>
              <div className="form__row mb-20">
                <input
                  type="text"
                  name="title"
                  className="form__input required"
                  placeholder="입력"
                  value={value.title}
                  onChange={handleChangeForm}
                />
              </div> */}
              <h4>답변 내용</h4>
              <div className="form__row mb-20">
                <textarea
                  name="answer"
                  className="form__textarea required"
                  placeholder="내용을 기입해주세요."
                  value={value.answer}
                  onChange={handleChangeForm}
                />
              </div>
            </form>
          </div>
          <div className="form__row mt-40">
            <input
              onClick={CancleClick}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_completion"
              id="submit"
              value="완료하기"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformCompletion conformClick={conformClick} />
      <Navigationbar />
    </Fragment>
  );
}

export default OkPhoneReport;
