import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import UrlContext from "../../context/Url.context";
import { Modal } from "../../components/DaumPostModal";

function SignUpTest(props) {
  const urlContext = useContext(UrlContext);

  const navigate = useNavigate();

  // 다음 주소찾기
  const [modalOpen, setModalOpen] = useState(false);
  const [postcode, setPostcode] = useState("");
  const [address1, setAddress1] = useState("");

  const [values, setValues] = useState({
    nickname: "",
    name: "",
    password: "",
    passwordcheck: "",
    tel: "",
    email: "",
    birthdate: "",
    address: "",
    addressdetail: "",
    gender: "",
    di: "",
  });

  // 닉네임 중복확인
  const [nicknamecheck, setNicknamecheck] = useState("null");
  const checkNickname = (e) => {
    e.preventDefault();
    let nickname = {
      nickname: values.nickname,
    };
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/duplicatedNickname`;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, nickname, config)
      .then((res) => {
        if (res.status === 200) {
          setNicknamecheck(200);
        }
      })
      .catch((err) => {
        setNicknamecheck(" ");
      });
  };

  // 이메일 중복확인
  const [emailcheck, setEmailcheck] = useState("null");
  const checkEmail = (e) => {
    e.preventDefault();
    let email = {
      email: values.email,
    };
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/duplicatedEmail`;
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(url, email, config)
      .then((res) => {
        if (res.status === 200) {
          setEmailcheck(200);
        }
      })
      .catch((err) => {
        setEmailcheck(" ");
      });
  };

  const signup = (e) => {
    e.preventDefault();
    // pattern
    let emailpattern =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    let pwdpattern =
      /^.*(?=.{8,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;
    let telpattern = /^[0-9\b -]{0,13}$/;
    // axios URL
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users`;

    let user = {
      nickname: values.nickname,
      name: values.name,
      password: values.password,
      phoneNum: values.tel,
      email: values.email,
      birth: values.birthdate,
      address1: address1,
      address2: values.addressdetail,
      gender: values.gender,
      openstatus: "O",
      volunteerTime: "0시간 0분",
      di: values.di,
    };
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (nicknamecheck !== 200) {
      alert("닉네임 중복확인을 해주세요.");
      return;
    }
    if (emailpattern.test(values.email) === false) {
      alert("이메일 형식에 맞게 작성해 주세요.");
      return;
    }
    if (emailcheck !== 200) {
      alert("이메일 중복확인을 해주세요.");
      return;
    }
    if (pwdpattern.test(values.password) === false) {
      alert("숫자,문자,특수문자를 조합해서 최소 8자 이상 입력해 주세요.");
      return;
    }
    if (telpattern.test(values.tel) === false) {
      alert("숫자만 입력해 주세요.( ex : 01098765432 )");
      return;
    }

    console.log(user);

    axios
      .post(url, user, config)
      .then((res) => {
        setValues(res.data);
        alert("회원가입 완료");
        document.location.href = "/common/login";
      })
      .catch((err) => {
        alert("회원가입 실패");
      });
  };

  const handleChangeForm = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  // 다음주소찾기 모달 열기, 닫기
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const daumPostModalOpenHandler = () => {
    openModal(true);
  };

  const nameInput = useRef();

  const [isNameVerificate, setIsNameVerificate] = useState(null);

  const onClickVerificationName = (e) => {
    e.preventDefault();

    if (values.name === "") {
      alert("이름을 입력하신 뒤, 실명 인증을 진행해주세요.");
      return;
    }

    let popup = window.open(
      "https://volunteer.seocho.go.kr:8443/mobileAuth/request/request.jsp",
      "verificationWindow",
      "width=455px , height=100%"
    );

    const successToVerification = (e) => {
      popup.close();
      // console.log(e);

      if (e.data.message === "OK") {
        // 이름 유효성 검사 -> O
        setIsNameVerificate(true);

        // 전화번호, di
        setValues({
          ...values,
          tel: e.data.cellNo,
          di: e.data.di,
        });

        nameInput.current.disabled = true;
      } else {
        // 이름 유효성 검사 결과 -> X
        setIsNameVerificate(false);

        nameInput.current.disabled = false;
        nameInput.current.focus();
        nameInput.current.style =
          "background:#FBFBEF; border: 1px solid #ef5797;";
      }
    };

    window.addEventListener("message", successToVerification, false);
  };

  return (
    <div className="page page--login" data-page="login">
      <Modal
        open={modalOpen}
        close={closeModal}
        setModalOpen={setModalOpen}
        setAddress={setAddress1}
        setPostcode={setPostcode}
      />
      {/* HEADER */}
      <header className="header header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <Link to="" onClick={() => navigate(-1)}>
              <img
                src="../../assets/images/icons/gradient-orange/arrow-back.svg"
                alt=""
              />
            </Link>
          </div>
        </div>
      </header>
      <div className="join">
        {/* <div className="login__content"> */}
        <div>
          <h2 className="login__title">회원가입</h2>
          <div className="login-form">
            <form id="LoginForm">
              <div className="login-form__row">
                <label className="login-form__label">닉네임</label>
                <input
                  type="text"
                  name="nickname"
                  value={values.nickname}
                  onChange={handleChangeForm}
                  className="login-form__input_min required"
                />
                <input
                  type="submit"
                  name="submit"
                  className="button button--main button--half"
                  id="submit"
                  value="중복확인"
                  onClick={checkNickname}
                />
                {nicknamecheck &&
                nicknamecheck === "null" ? null : nicknamecheck &&
                  nicknamecheck === 200 ? (
                  <p>사용할 수 있는 닉네임입니다.</p>
                ) : (
                  <p className="font-red">이미 사용 중인 닉네임입니다.</p>
                )}
              </div>
              <div className="login-form__row">
                <label className="login-form__label">이름</label>
                <input
                  type="text"
                  name="name"
                  ref={nameInput}
                  value={values.name}
                  onChange={handleChangeForm}
                  className="login-form__input_min required"
                />
                <input
                  type="button"
                  className="button button--main button--half"
                  value="실명인증"
                  onClick={onClickVerificationName}
                />
                {isNameVerificate === false ? (
                  <p className="font-red">실명인증에 실패하였습니다.</p>
                ) : isNameVerificate === true ? (
                  <p>실명인증에 성공하였습니다.</p>
                ) : null}
              </div>
              <div className="login-form__row">
                <label className="login-form__label">비밀번호</label>
                <input
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChangeForm}
                  className="login-form__input required"
                />
              </div>
              <div className="login-form__row">
                <label className="login-form__label">비밀번호 재입력</label>
                <input
                  type="password"
                  name="passwordcheck"
                  value={values.passwordcheck}
                  onChange={handleChangeForm}
                  className="login-form__input required"
                />
                {values.password === values.passwordcheck ? null : (
                  <p className="font-red">비밀번호가 일치하지 않습니다.</p>
                )}
              </div>
              <div className="login-form__row">
                <label className="login-form__label">전화번호</label>
                <input
                  type="tel"
                  name="tel"
                  value={values.tel}
                  onChange={handleChangeForm}
                  disabled={true}
                  className="login-form__input required"
                />
              </div>
              <div className="login-form__row">
                <label className="login-form__label">성별</label>
                <div className="form__select">
                  <select
                    name="gender"
                    className="required"
                    onChange={handleChangeForm}
                  >
                    <option value="" disabled selected>
                      선택
                    </option>
                    <option value="M">남성</option>
                    <option value="F">여성</option>
                  </select>
                </div>
              </div>
              <div className="login-form__row">
                <label className="login-form__label">Email</label>
                <input
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChangeForm}
                  className="login-form__input_min required email"
                />
                <input
                  type="submit"
                  name="submit"
                  className="button button--main button--half"
                  id="submit"
                  value="중복확인"
                  onClick={checkEmail}
                />
                {emailcheck && emailcheck === "null" ? null : emailcheck &&
                  emailcheck === 200 ? (
                  <p>사용할 수 있는 이메일 주소입니다.</p>
                ) : (
                  <p className="font-red">이미 사용 중인 이메일 주소입니다.</p>
                )}
              </div>
              <div className="login-form__row">
                <label className="login-form__label">생년월일</label>
                <input
                  type="date"
                  name="birthdate"
                  value={values.birthdate}
                  onChange={handleChangeForm}
                  className="login-form__input required email"
                />
              </div>
              <div className="login-form__row">
                <label className="login-form__label">주소(선택)</label>
                <input
                  type="text"
                  name="address"
                  value={address1}
                  className="login-form__input_min required email"
                  onClick={daumPostModalOpenHandler}
                  readOnly
                />
                <input
                  type="button"
                  name="submit"
                  className="button button--main button--half"
                  id="submit"
                  value="검색"
                  onClick={daumPostModalOpenHandler}
                />
                <br />
                <br />
                <label className="login-form__label">상세 주소</label>
                <input
                  type="text"
                  name="addressdetail"
                  value={values.addressdetail}
                  onChange={handleChangeForm}
                  className="login-form__input required email"
                />
              </div>
              <div className="login-form__row">
                <input
                  type="submit"
                  name="submit"
                  onClick={signup}
                  className="login-form__submit button button--main button--full"
                  id="submit"
                  value="회원가입"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpTest;
