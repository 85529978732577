import React from "react";
import { convertDateString } from "../util/convert-date-function";

const ScheduleListItem = ({ scheduleInfo }) => {
  return (
    <div className="table card card--style-thumb mb-20">
      <div className="card__details">
        <h2 className="fc_point1">
          {convertDateString(scheduleInfo.date) + " " + scheduleInfo.time}
        </h2>
        <p className="card__list">
          활동인원 : <span>{scheduleInfo.recruitNum}</span>
        </p>
      </div>
    </div>
  );
};

export default ScheduleListItem;
