import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import ActivityDetail from "../../../components/ActivityDetail";
import Navigationbar from "../../../components/Navigationbar";
import OrgList from "../../../components/OrgList";
import Panel from "../../../components/Panel";
import ScheduleList from "../../../components/ScheduleList";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

const RequestOperationDetail = () => {
  const { actIdx } = useParams();

  const [requestedOrgInfo, setRequestedOrgInfo] = useState(null);
  const [activityInfo, setActivityInfo] = useState();

  const [scheduleTotalRows, setScheduleTotalRows] = useState(0);
  const [scheduleList, setScheduleList] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  const soleClickHandler = () => {
    navigate(`/org/otherprofile/sole`, {
      state: {},
    });
  };

  const chatDClickHandler = () => {
    navigate(`/org/otherprofile/sole`, {
      state: {},
    });
  };

  const dismissalClickHandler = () => {
    navigate(`/org/common/dismissal/${actIdx}`, {
      state: {},
    });
  };

  const registerRequestedClickHandler = () => {
    navigate(`/org/profile/registerrequested/${actIdx}`);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getRequestedOrgInfo();
        getActivityInfo();
        getScheduleList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getRequestedOrgInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/requested/org/${actIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setRequestedOrgInfo({
            idx: response.data.id,
            contact: response.data.contact,
            orgTitle: response.data.orgTitle,
            img: Object.keys(response.data).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${response.data.img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getActivityInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setActivityInfo(response.data);
        }
      } catch (e) {
        console.log(e);
        alert("활동 정보 조회 중, 오류가 발생하였습니다.");
      }
    };

    const getScheduleList = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/recruit/${actIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { data } = response.data;
          let ary = [];

          for (let i = 0; i < data.length; i++) {
            ary.push({
              id: data[i].activityScheduleIdx,
              date: data[i].activityDate,
              time: data[i].activityTime,
              recruitNum: data[i].recruitNum,
            });
          }

          setScheduleList(ary);
          setScheduleTotalRows(ary.length);
        }
      } catch (e) {
        console.log(e);
        alert("스케줄 목록 조회 중, 오류가 발생하였습니다.");
      }
    };
  }, [actIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="의뢰 받은 활동 상세" />
        {/* PAGE CONTENT */}

        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          {activityInfo && (
            <div className="page_section">
              <div className="cards cards--11">
                <div className="d-flex justify-space align-items-center">
                  <h2 className="mb-0">의뢰 요청 기관/단체 :</h2>
                </div>
                {requestedOrgInfo && <OrgList orgprofile={requestedOrgInfo} />}
              </div>
              <ActivityDetail activityData={activityInfo} folder="org" />
            </div>
          )}
          <div className="page_section">
            <div className="page__title-bar mt-48">
              <h2>일정({scheduleTotalRows})</h2>
            </div>
            {scheduleList && (
              <ScheduleList
                list={scheduleList}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalRows={scheduleTotalRows}
              />
            )}
            <div className="under_button">
              <div className="form__row mt-40">
                <input
                  onClick={dismissalClickHandler}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--gray button--50"
                  value="반려"
                />
                <input
                  onClick={registerRequestedClickHandler}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--50"
                  id="submit"
                  value="승인 및 활동 등록"
                />
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
};

export default RequestOperationDetail;
