import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

function NotificationItem(props) {
  const { folder, alarmInfo } = props;

  const setLink = () => {
    switch (alarmInfo.senderType) {
      case "O":
        return `/${folder}/otherprofile/org/${alarmInfo.senderIdx}`;
      case "U":
        return `/${folder}/otherprofile/sole/${alarmInfo.senderIdx}`;
      default:
        return `#`;
    }
  };

  const setContentLink = () => {
    // if (alarmInfo.activityIdx !== null) {
    //   if (alarmInfo.content.includes("도움")) {
    //     return `/${folder}/activity/detailbene/${alarmInfo.activityIdx}`;
    //   } else {
    //     return `/${folder}/activity/detailpart/${alarmInfo.activityIdx}`;
    //   }
    // }

    switch (alarmInfo.alarmType) {
      case "A":
        if (alarmInfo.content.includes("도움")) {
          return `/${folder}/activity/detailbene/${alarmInfo.pkIdx}`;
        } else if (alarmInfo.content.includes("활동을 의뢰하였습니다.")) {
          return `/org/profile/requestoperationdetail/${alarmInfo.pkIdx}`;
        } else {
          return `/${folder}/activity/detailpart/${alarmInfo.pkIdx}`;
        }

      case "O":
        return `/${folder}/otherprofile/org/${alarmInfo.pkIdx}`;
      case "P":
        if (window.sessionStorage.getItem("expertStatus") === "E")
          return `/sole/profileexpert/okphonereport/${alarmInfo.pkIdx}`;
        else return "#";
      case "E":
        if (window.sessionStorage.getItem("expertStatus") === "E") {
          return `/sole/profileexpert/okonlineanswer/${alarmInfo.pkIdx}`;
        } else {
          return `/${folder}/okonline/detailad/${alarmInfo.pkIdx}`;
        }

      case "M":
        if (folder === "org") {
          return `/org/profile/org`;
        } else {
          return `/sole/profilesole/sole`;
        }
      case "N":
        return `/common/community/noticedetail/${alarmInfo.pkIdx}`;

      case "R":
        if (folder === "sole") {
          if (alarmInfo.content.includes("활동내역 보완요청이 있습니다.")) {
            return `/sole/schedule/report/${alarmInfo.pkIdx}`;
          } else if (alarmInfo.content.includes("완료 요청이 있습니다.")) {
            return `/sole/schedule/add-report/${alarmInfo.pkIdx}`;
          } else {
            return `/sole/schedule/add-report/${alarmInfo.pkIdx}`;
          }
        } else {
          return `/org/profile/reportdetail/${alarmInfo.pkIdx}`;
        }

      default:
        return "#";
    }
  };

  const onHandleImgError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`;
  };

  return (
    <Fragment>
      <li className="conversations__row conversations__row--undread">
        <div className="conversations__avatar">
          <span className />
          <Link to={setLink()}>
            <img
              src={alarmInfo.img}
              alt=""
              onError={(e) => onHandleImgError(e)}
            />
          </Link>
        </div>
        <div className="conversations__details">
          <div className="conversations__user">
            <Link to={setLink()}>{alarmInfo.nickName}</Link>
          </div>
          <p className="conversations__short-content">
            <Link to={setContentLink()}>{alarmInfo.content}</Link>
          </p>
        </div>
        <div className="conversations__info">
          <div className="conversations__time">{alarmInfo.dateTime}</div>
        </div>
      </li>
    </Fragment>
  );
}

export default NotificationItem;
