import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import { convertDashToDot } from "../../../util/convert-date-function";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";
import FileUpload from "../../common/FileUpload";

const AddReport = () => {
  const { activityScheduleIdx } = useParams();
  const navigate = useNavigate();

  const [activityInfo, setActivityInfo] = useState(null);

  const [imgFiles, setImgFiles] = useState([]);
  const [imgBase64, setImgBase64] = useState([]);

  const [imgId, setImgId] = useState(0);

  const onChangeImgFile = (e) => {
    const imgFileAry = e.target.files;

    setImgFiles(imgFiles.concat({ file: imgFileAry[0], id: imgId }));

    for (let i = 0; i < imgFileAry.length; i++) {
      if (imgFileAry[i]) {
        let reader = new FileReader();

        // 1. 파일 읽어서 버퍼에 저장
        reader.readAsDataURL(imgFileAry[i]);

        // 파일 상태 업데이트
        reader.onloadend = () => {
          // 읽기 완료시, 아래 코드 실행
          const base64 = reader.result;

          if (base64) {
            var base64Sub = base64.toString();

            // 파일 base64 상태 업데이트
            setImgBase64((imgBase64) => [
              ...imgBase64,
              { img: base64Sub, id: imgId + i },
            ]);
          }
        };
      }

      if (imgFileAry.length - 1 === i) {
        setImgId(imgId + i + 1);
      }
    }
  };

  const onClickDeleteBtn = (rId) => {
    let fileAry = imgFiles;
    let base64Ary = imgBase64;

    fileAry = fileAry.filter((file) => file.id !== rId);
    base64Ary = base64Ary.filter((base64) => base64.id !== rId);

    setImgFiles(fileAry);
    setImgBase64(base64Ary);
  };

  const [moreInformation, setMoreInformation] = useState("");
  const onChangeMoreInformation = (data) => {
    setMoreInformation(data);
  };

  const cancelReport = () => {
    goBack();
  };

  const completeReport = async () => {
    if (moreInformation.trim("") === "") {
      alert("활동 내용을 기입해주세요.");
      return;
    }

    const data = new Object();

    data.scheduleIdx = activityInfo.scheduleIdx;
    data.actListIdx = activityInfo.actListIdx;
    data.content = moreInformation;
    data.createdUid = window.sessionStorage.getItem("idx");

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/report`;

    try {
      const response = await axios.post(url, data);

      if (response.status === 201) {
        let formData = null;
        for (let i = 0; i < imgFiles.length; i++) {
          if (imgFiles[i] !== undefined) {
            formData = new FormData();

            formData.append("file", imgFiles[i].file);
            formData.append("pkIdx", response.data);
            formData.append("imageStatus", "R");

            if (imgFiles.length === i + 1) {
              addImageFile(formData, true);
            } else {
              addImageFile(formData, false);
            }
          }
        }

        // 활동 완료 확인 알림 보내기
      }
    } catch (e) {
      console.log(e);
      alert("활동 리포트 등록 중, 오류가 발생하였습니다.");
    }
  };

  const addImageFile = async (data, endFlag) => {
    const url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/uploadfile`;

    try {
      const response = await axios.post(url, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (endFlag) {
        axios
          .post(
            `${
              process.env.REACT_APP_USER_SERVICE_API
            }/report/alarm/${activityScheduleIdx}/${window.sessionStorage.getItem(
              "idx"
            )}`
          )
          .then((res) => {
            if (res.status === 201) {
              alert("등록되었습니다.");
              navigate("/sole/profilesole/sole");
            }
          });
      }
    } catch (e) {
      console.log(e);
      alert("리포트 이미지 추가 중,오류가 발생하였습니다.");
    }
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [hideContent, setHideContent] = useState(true);

  const [showFileModal, setShowFileModal] = useState(false);
  const toggleFileModal = () => {
    setShowFileModal(!showFileModal);
  };
  const getFile = (file, name, imgUrl, type) => {
    if (type === "img") {
      setImgFiles(imgFiles.concat({ file: file, id: imgId }));

      if (file) {
        let reader = new FileReader();

        // 1. 파일 읽어서 버퍼에 저장
        reader.readAsDataURL(file);

        // 파일 상태 업데이트
        reader.onloadend = () => {
          // 읽기 완료시, 아래 코드 실행
          const base64 = reader.result;

          if (base64) {
            var base64Sub = base64.toString();

            // 파일 base64 상태 업데이트
            setImgBase64((imgBase64) => [
              ...imgBase64,
              { img: base64Sub, id: imgId },
            ]);
          }
        };
      }
      setImgId(imgId + 1);
    }

    toggleFileModal();
  };

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        // 활동 내역 작성 여부 조회
        getReportInfo();
      })
      .catch((e) => {
        console.log(e);
      });

    const getReportInfo = async () => {
      const url = `${
        process.env.REACT_APP_USER_SERVICE_API
      }/report/${activityScheduleIdx}/${window.sessionStorage.getItem("idx")}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          if (response.data !== -1) {
            // alert("작성 이력이 있는 활동 내역입니다.");
            navigate(`/sole/schedule/report/${response.data}`, {
              state: {
                isRedirect: true,
              },
            });
          } else {
            getActivityScheduleInfo();
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getActivityScheduleInfo = async () => {
      const url = `${
        process.env.REACT_APP_USER_SERVICE_API
      }/activity/simple-info/${activityScheduleIdx}/${window.sessionStorage.getItem(
        "idx"
      )}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setActivityInfo({
            title: response.data.title,
            activityDate: response.data.activityDate,
            activityTime: response.data.activityTime,
            activityIdx: response.data.activityIdx,
            scheduleIdx: response.data.activityScheduleIdx,
            actListIdx: response.data.actListIdx,
          });

          setHideContent(false);
        }
      } catch (e) {
        console.log(e);
        alert("존재하지 않는 활동 또는 스케줄 입니다.");
        goBack();
      }
    };
  }, [activityScheduleIdx, goBack, navigate]);

  if (hideContent) {
    return null;
  }

  if (showFileModal) {
    return (
      <FileUpload
        type="img"
        getFile={getFile}
        toggleFileModal={toggleFileModal}
      />
    );
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 내역" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="d-flex justify-space align-items-center">
            <h2 className="mb-0">{activityInfo && activityInfo.title}</h2>
          </div>
          <div className="fieldsets">
            <ul className="custom-listing mb-20">
              <li>
                <span>
                  활동 일시 : <br />
                </span>
                {activityInfo &&
                  `${convertDashToDot(activityInfo.activityDate)} ${
                    activityInfo.activityTime
                  }`}
              </li>
            </ul>
          </div>
          <div className="cards cards--12" id="loadlist-01">
            {imgBase64.map((base64) => (
              <div className="card card--style-thumb" key={base64.id}>
                <div className="card__thumb card__thumb--round-corners">
                  {/* <Link to="/sole/activity/detailcarriedout"> */}
                  <img src={base64.img} alt="" />
                  {/* </Link> */}
                </div>
                <div
                  className="button button--full button--gray"
                  onClick={() => onClickDeleteBtn(base64.id)}
                >
                  삭제
                </div>
              </div>
            ))}
          </div>
          <div className="fieldsets">
            <div className="upload-filebox">
              <form id="_frm" method="post" enctype="multipart/form-data">
                <label htmlFor="ex_file">사진 업로드</label>
                <input
                  // type={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? "button"
                  //     : "file"
                  // }
                  // type="button"
                  type={"file"}
                  // style={
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? { display: "none" }
                  //     : { display: "block" }
                  // }
                  // style={{ display: "none" }}
                  id="ex_file"
                  accept=".jpg, .jpeg, .png"
                  // accept="image/*"
                  // onClick={() =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? toggleFileModal()
                  //     : null
                  // }
                  // onClick={() => toggleFileModal()}
                  capture="camera"
                  // onChange={(e) =>
                  //   isAndroid() &&
                  //   typeof window.Android !== "undefined" &&
                  //   window.Android !== null
                  //     ? null
                  //     : onChangeImgFile(e)
                  // }
                  onChange={(e) => onChangeImgFile(e)}
                />
              </form>
            </div>
          </div>
          <div className="form mt-40">
            <form id="ContactForm" method="post" action>
              <h4>활동 내용</h4>
              <div className="form__row mb-20">
                <textarea
                  name="ContactComment"
                  className="form__textarea required"
                  placeholder="내용을 기입해주세요."
                  defaultValue={moreInformation}
                  onChange={(e) => onChangeMoreInformation(e.target.value)}
                />
              </div>
            </form>
          </div>
          <div className="form__row mt-40">
            <input
              onClick="location.href='#'"
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_completion"
              id="submit"
              value="완료하기"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformPopup
        text="취소하시겠습니까?작성하신 내용은 저장되지 않습니다."
        popupType="cancle"
        conformClick={cancelReport}
      />
      <ConformPopup
        text="완료하시겠습니까?작성하신 내용을 저장합니다."
        popupType="completion"
        conformClick={completeReport}
      />
      <Navigationbar />
    </Fragment>
  );
};

export default AddReport;
