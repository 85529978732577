import axios from "axios";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneconsultItem from "../../../components/PhoneconsultItem";
import Navigation from "../../../components/Navigation";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import OnlineconsultItem from "../../../components/OnlinecounsultItem";
import { onHandleImgError } from "../../../util/util-function";
import CheckLoginInfo from "../../../services/login-validation";

function Expert() {
  const navigate = useNavigate();
  const [expertdetail, setExpertdetail] = useState(null);
  const [userdetail, setUserdetail] = useState(null);

  const [userImg, setUserImg] = useState(
    `${process.env.PUBLIC_URL}/assets/images/photos/img-p-expert.jpg`
  );

  // 전문가 상세 정보 조회
  const getExpertDetailInfo = useCallback(() => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/expert/user/${sessionStorage.idx}`;
    axios.get(url).then((res) => {
      setExpertdetail(res.data.data[0]);
    });
  }, []);

  // 전문가 개인정보 조회
  const getUserInfo = useCallback(() => {
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${sessionStorage.idx}`;
    axios
      .get(url)
      .then((res) => {
        setUserdetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 전문가 이미지 조회
  const getImageFile = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;

    try {
      const response = await axios.get(url, {
        params: {
          pkIdx: userdetail && userdetail.idx,
          imageStatus: "U",
        },
      });

      if (response.status === 200) {
        setUserImg(
          `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${response.data.pkIdx}/${response.data.imageStatus}/${response.data.fileName}`
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [userdetail]);

  const expertView = () => {
    switch (expertdetail && expertdetail.area) {
      case "법률":
        return "법률";
      case "세무":
        return "세무";
      case "법무":
        return "법무";
      case "건축":
        return "건축";
      case "노무":
        return "노무";
      default:
        return "법률";
    }
  };

  const tagView = () => {
    switch (expertdetail && expertdetail.area) {
      case "법률":
        return "law";
      case "세무":
        return "tax";
      case "법무":
        return "legal";
      case "건축":
        return "cons";
      case "노무":
        return "labor";
      default:
        return "law";
    }
  };

  const [onlineconlist, setOnlineconlist] = useState();
  // // 온라인 상담 조회
  const getOnlineConsultationList = useCallback(() => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/expert/${sessionStorage.idx}`;
    axios
      .get(url)
      .then((res) => {
        setOnlineconlist(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [phoneconlist, setPhoneconlist] = useState();

  // // 전화 상담 조회
  const getPhoneConsultationList = useCallback(async () => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/phone_consultation/expert/${sessionStorage.idx}`;
    axios
      .get(url)
      .then((res) => {
        setPhoneconlist(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const gookphonelist = () => {
    navigate(`/sole/profileexpert/okphonereportlist`, {
      state: {
        phoneconlist: phoneconlist,
        expertdetail: expertdetail,
      },
    });
  };

  const gookonlinelist = () => {
    navigate(`/sole/profileexpert/okonlinereportlist`, {
      state: {
        onlineconlist: onlineconlist,
        expertdetail: expertdetail,
      },
    });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getExpertDetailInfo();
        getUserInfo();
        getPhoneConsultationList();
        getOnlineConsultationList();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [
    getExpertDetailInfo,
    getOnlineConsultationList,
    getPhoneConsultationList,
    getUserInfo,
  ]);

  useEffect(() => {
    if (userdetail !== null && Object.keys(userdetail).includes("idx")) {
      getImageFile();
    }
  }, [getImageFile, userdetail]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content page_section">
          <header className="header header--fixed">
            <div className="header__inner">
              <div className="header__icon" />
              <div className="header__icon">
                <Link to="/sole/profileedit/setting">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/black/settings.svg`}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </header>
          <div className="user-profile mb-20">
            <div className="user-profile__thumb">
              <img
                src={userImg}
                alt=""
                onError={(e) => onHandleImgError(e, "E")}
              />
            </div>
            <div className="user-profile__name">
              <a className={`caption__category tag_expert_${tagView()}`}>
                {expertView()} 전문가
              </a>
            </div>
            <div className="user-profile__name">
              {userdetail && userdetail.nickname}
            </div>
            <div className="user-profile__number">
              전화번호 : {userdetail && userdetail.phoneNum}
            </div>
            <div className="user-profile__info">
              {userdetail && userdetail.bio !== null
                ? `소개글 : ${userdetail.bio}`
                : "등록된 소개글이 없습니다"}
            </div>
            <div className="buttons buttons--centered">
              <div className="info-box">
                <span>전화상담</span> {phoneconlist && phoneconlist.length}건
              </div>
              <div className="info-box">
                <span>온라인 상담</span> {onlineconlist && onlineconlist.length}
                건
              </div>
              <Link
                to="/sole/profilesole/soleedit"
                className="button button--orange button--edit button--ex-small ml-10"
              >
                편집
              </Link>
            </div>
          </div>
          <div className="page__title-bar">
            <h2>전화상담 예약</h2>
          </div>

          <div className="cards cards--11">
            <div className="form__row">
              <input
                onClick={gookphonelist}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--primary button--full"
                id="submit"
                value="전화상담 활동 내역 보러가기"
              />
            </div>

            {phoneconlist &&
              phoneconlist.map((item) => (
                <PhoneconsultItem item={item} expertdetail={expertdetail} />
              ))}
            {/* <div className="buttons buttons--centered mb-20">
              <div className="button button--more">더 보기</div>
            </div> */}
          </div>

          <div className="page__title-bar">
            <h2>온라인 상담</h2>
          </div>
          <div className="cards cards--11">
            <div className="form__row">
              <input
                onClick={gookonlinelist}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--primary button--full"
                id="submit"
                value="온라인상담 활동 내역 보러가기"
              />
            </div>

            {onlineconlist &&
              onlineconlist.map((item) => (
                <OnlineconsultItem item={item} expertdetail={expertdetail} />
              ))}
            {/* <div className="buttons buttons--centered mb-20">
              <div className="button button--more">더 보기</div>
            </div> */}
            <div className="page__title-bar">
              <h2>활동</h2>
            </div>
            <ul className="list-menu">
              <li className="list-menu__row">
                <Link className="list-menu__row" to="/sole/profilesole/orglist">
                  <div className="list-menu__details">
                    <div className="list-menu__t">
                      내가 소속된 기관 보기
                    </div>
                  </div>
                  <div className="list-menu__info default__icon">
                    <div className="list-menu__icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </li>
              <li className="list-menu__row">
                <Link className="list-menu__row" to="/sole/profilesole/activityhistory">
                  <div className="list-menu__details">
                    <div className="list-menu__t">

                      활동 이력
                    </div>
                  </div>
                  <div className="list-menu__info default__icon">
                    <div className="list-menu__icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </li>
              <li className="list-menu__row">
                <Link className="list-menu__row" to="/sole/profilesole/actrequestapplication">
                  <div className="list-menu__details">
                    <div className="list-menu__t">
                      활동 참여 신청/요청 목록
                    </div>
                  </div>
                  <div className="list-menu__info default__icon">
                    <div className="list-menu__icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </li>
              {/* <li className="list-menu__row">
                <div className="list-menu__details">
                  <div className="list-menu__t">
                    <Link to="/sole/profilesole/requestoperationlist">
                      도움 요청한 일정
                    </Link>
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <Link to="/sole/profilesole/requestoperationlist">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </li> */}
              {/* <li className="list-menu__row">
                <div className="list-menu__details">
                  <div className="list-menu__t">
                    <Link to="/sole/profilesole/okhistory">
                      서초OK생활자문단 상담
                    </Link>
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <Link to="/sole/profilesole/okhistory">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </li> */}
              <li className="list-menu__row">
                <div className="list-menu__details"></div>
              </li>
            </ul>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <div id="bottom-toolbar_sole" className="bottom-toolbar_org">
        <Navigation folder={"sole"} />
      </div>
      <Navigationbar />
    </Fragment >
  );
}

export default Expert;
