import React from "react";
import { useNavigate } from "react-router";

function GroupList(props) {
  const { addition, group, actIdx, actSchduelIdx, isFinished } = props;

  const navigate = useNavigate();

  const groupDetailClickHandler = () => {
    navigate(`/org/profile/groupdetail`, {
      state: {
        group: group && group,
      },
    });
  };

  const addGrouptoActivityList = (e) => {
    e.stopPropagation();

    // navigate("/org/activity/choosemembergroup", {
    //   state : {
    //     "activityIdx":actIdx,
    //     "activityScheduleIdx":actSchduelIdx,
    //     "page":"listactivity",
    //   }
    // })

    navigate("/org/activity/placingmember", {
      state: {
        groupName: group && group.name,
        activityIdx: actIdx,
        activityScheduleIdx: actSchduelIdx,
        actRequestIdx: undefined,
        page: "listactivity",
        isMyGroup: true,
        isFinished: isFinished,
      },
    });
  };

  return (
    <div
      className="checkbox-option checkbox-option--fulls"
      onClick={groupDetailClickHandler}
    >
      <input className="form__input mr-16" type="checkbox" />
      <label>
        {" "}
        {group && group.name} <span>({group && group.total})</span>
        {addition ? (
          <div
            className="button button--small button--secondary float-right"
            onClick={addGrouptoActivityList}
          >
            추가
          </div>
        ) : (
          ""
        )}
      </label>
    </div>
  );
}

export default GroupList;
