import React from "react";
import { useNavigate } from "react-router-dom";

const ChatRoomListItem = ({ roomInfo, userStatus }) => {
  const navigate = useNavigate();

  const onClickChat = () => {
    let params;

    // switch (roomInfo.type) {
    //   case "s2a":
    //     params = {
    //       userId: roomInfo.sender,
    //       activityId: roomInfo.target,
    //     };
    //     break;
    //   case "s2o":
    //     params = {
    //       userId: roomInfo.sender,
    //       orgId: roomInfo.target,
    //     };
    //     break;
    //   case "s2g":
    //     params = {
    //       userId: roomInfo.sender,
    //       groupId: roomInfo.target,
    //     };
    //     break;
    //   default:
    //     params = null;
    //     break;
    // }

    if (params !== null) {
      navigate(`/${userStatus}/chat/chatd/${roomInfo.type}`, {
        state: {
          uri: roomInfo.uri,
        },
      });
    } else {
      alert("채팅방 입장 중, 오류가 발생하였습니다.");
      return;
    }
  };

  return (
    <li
      className={
        roomInfo.unread !== null
          ? "conversations__row conversations__row--undread"
          : "conversations__row"
      }
    >
      <div className="conversations__avatar">
        <span />
        <span onClick={() => onClickChat()}>
          <img src={roomInfo.chatImage} alt="" />
        </span>
      </div>
      <div
        className={
          roomInfo.unread !== null
            ? "conversations__details chat_detail"
            : "conversations__details"
        }
      >
        <div className="conversations__user">
          <a onClick={() => onClickChat()}>{roomInfo.title}</a>
        </div>
        <p className="conversations__short-content">
          <a onClick={() => onClickChat()}></a>
        </p>
      </div>
      <div className="conversations__info">
        <div className="conversations__time">{roomInfo.lastAccessTime}</div>
        {roomInfo.unread !== null && (
          <div className="conversations__new-messages-nr">
            {roomInfo.unread}
          </div>
        )}
      </div>
    </li>
  );
};

export default ChatRoomListItem;
