import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useConfirm from "../../util/cofirm-alert";
import Timer from "./Timer";

const parseTime = (time) => {
  if (typeof time === "undefined" || time === "" || time === null) {
    return "";
  }

  return {
    hour: parseInt(time.split(":")[0]),
    min: parseInt(time.split(":")[1]),
    sec: parseInt(time.split(":")[2]),
  };
};

const WorkingOnSchedule = ({
  scheduleInfo,
  endActivity,
  cancelJoinedAct,
  endSoleSchedule,
}) => {
  const navigate = useNavigate();

  const chatdgroupClickHandler = () => {
    alert("채팅방 입장이 어렵습니다.");

    return;
    // navigate("/org/chat/chatd/a2g", {
    //   state: {
    //     activityId: scheduleInfo.actId,
    //     groupId: scheduleInfo.scheduleId,
    //   },
    // });
  };

  const actdetailClickHandler = () => {
    navigate(
      window.sessionStorage.getItem("orgStatus") === "O"
        ? `/org/profile/actdetail/${scheduleInfo.actId}`
        : `/sole/activity/detailpart/${scheduleInfo.actId}`,
      {
        state: {},
      }
    );
  };

  const listactivityClickHandler = () => {
    navigate(
      `/org/profile/listactivity/${scheduleInfo.actId}/${scheduleInfo.scheduleId}`,
      {
        state: {},
      }
    );
  };

  const detailpartClickHandler = () => {
    navigate(`/org/activity/detailpart/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const detailbeneClickHandler = () => {
    navigate(`/org/activity/detailbene/${scheduleInfo.actId}`, {
      state: {},
    });
  };

  const onHandleEndActivity = () => {
    endActivity(scheduleInfo.scheduleId);
  };

  const onClickEndActivityBtn = useConfirm(
    `"${scheduleInfo.activityTitle}(${scheduleInfo.startTime})" 활동을 종료하시겠습니까?`,
    onHandleEndActivity
  );

  const onHandleCancelAct = () => {
    cancelJoinedAct(scheduleInfo.scheduleId);
  };

  const onClickCancel = useConfirm(
    `"${scheduleInfo.activityTitle}(${scheduleInfo.startTime})" 활동 참여를 취소하시겠습니까?`,
    onHandleCancelAct
  );

  const onHandleEndSchedule = () => {
    endSoleSchedule(scheduleInfo.scheduleId);
  };

  const onClickEndSchedule = useConfirm(
    `"${scheduleInfo.activityTitle}(${scheduleInfo.startTime})" 활동 내역을 작성하시겠습니까?`,
    onHandleEndSchedule
  );

  const setLabel = () => {
    if (scheduleInfo.type === "BENE") {
      return (
        <span className="caption__category tag_bene card__list--centered">
          활동 수요자
        </span>
      );
    } else {
      if (scheduleInfo.orgType === "O") {

        if(scheduleInfo?.volunteer === "Y"){
          return (
            <span className="caption__category tag_part card__list--centered">
              활동 활동자
            </span>
          );
        }

        return (
          <span className="caption__category card__list--centered tag_operator">
            주관
          </span>
        );
      } else {
        return (
          <span className="caption__category tag_part card__list--centered">
            활동 활동자
          </span>
        );
      }
    }
  };

  const setButtonGroup = () => {
    if (scheduleInfo.type === "BENE") {
      return (
        <div className="form__row mt-40">
          <input
            onClick={detailbeneClickHandler}
            style={{ cursor: "pointer" }}
            type="submit"
            name="submit"
            className="form__submit button button--main button--full"
            id="submit"
            value="활동보기"
          />
        </div>
      );
    } else {
      if (scheduleInfo.orgType === "O") {
        if (scheduleInfo.joinType === "Q") {
          return (
            <div className="form__row mt-20">
              {/* <input
                onClick={chatdgroupClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--333"
                id="submit"
                value="그룹 메세지"
              /> */}

              {/* <input
                onClick={onClickEndActivityBtn}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--secondary button--25"
                id="submit"
                value="활동 종료하기"
              /> */}

              <input
                onClick={actdetailClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--50"
                id="submit"
                value="활동보기"
              />
              <input
                onClick={listactivityClickHandler}
                stlye={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--50"
                id="submit"
                value="QR코드 보기"
              />
            </div>
          );
        } else {
          return (
            <div className="form__row mt-20">
              {/* <input
                onClick={chatdgroupClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--50"
                id="submit"
                value="그룹 메세지"
              /> */}

              {/* <input
                onClick={onClickEndActivityBtn}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--secondary button--333"
                id="submit"
                value="활동 종료하기"
              /> */}

              <input
                onClick={actdetailClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="활동보기"
              />
            </div>
          );
        }
      } else {
        return (
          <div className="form__row mt-20">
            <input
              onClick={onClickEndSchedule} // /sole/schedule/add-report/${actListIdx}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--secondary button--50"
              id="submit"
              value="활동 내역 작성하기"
            />
            <input
              onClick={actdetailClickHandler}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              className="form__submit button button--main button--50"
              id="submit"
              value="활동보기"
            />
          </div>
        );
      }
    }
  };

  return (
    <div className="card card--style-icon card--style-round-corners">
      <h2 className="text-aline-center">활동 참여 중!</h2>
      <h4 className="card__list card__list--centered fc_point1">
        {scheduleInfo.activityTitle}
      </h4>
      <h4 className="card__list--centered">
        시작 시간 : {scheduleInfo.startTime}
      </h4>
      {parseTime(scheduleInfo.passedTime) !== "" && (
        <Timer time={parseTime(scheduleInfo.passedTime)} />
      )}
      {scheduleInfo.type === "PART" && (
        <Link
          to={
            scheduleInfo.orgType === "O" &&
            `/org/schedule/userlistpart/${scheduleInfo.scheduleId}`
          }
        >
          <h3 className="card__list--centered lh-2">{`참여(지정) ${scheduleInfo.recruitNum
            }명 / ${scheduleInfo.totalRecruitNum === 0
              ? "제한없음"
              : `필요인원 ${scheduleInfo.totalRecruitNum}명`
            }`}</h3>
        </Link>
      )}

      {setLabel()}
      {setButtonGroup()}
    </div>
  );
};

export default WorkingOnSchedule;
