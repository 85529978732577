import React from 'react';
import { Link } from 'react-router-dom';

function SoleHeader(props) {
  const { type } = props;

  return (
    <header className="header header--page header--fixed">
      <div className="header__inner">
        <div className="cal03" data-panel="left">
          <span /><span /><span /><span /><span /><span />
        </div>
        <div className="header__logo header__logo--text"><img width="240px" src="/assets/images/logo-white.png" alt="" /></div>
        <div className="header__icon header__icon--cart cal03">
        </div>
      </div>
    </header>
  );
}

export default SoleHeader;