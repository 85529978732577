import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Panel from "../../../components/Panel";
import ActivityDetail from "../../../components/ActivityDetail";
import ActivityDetailHeader from "../../../components/ActivityDetailHeader";
import axios from "axios";
import ActivityScheduleList from "../../../components/ActivityScheduleList";
import MyScheduleGroupList from "../../../components/MyScheduleGroupList";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";
import { checkActivityPageAccess } from "../../../services/page-access-validation";

function DetailPart() {
  const { actIdx } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const userIdx = sessionStorage.idx;

  const chatdClickHandler = () => {
    navigate("/sole/chat/chatd/s2a", {
      state: {
        userId: window.sessionStorage.getItem("idx"),
        activityId: actIdx,
      },
    });
  };

  const [activityData, setActivityData] = useState([]);
  const [activityScheduleData, setActivityScheduleData] = useState([]);
  const [activityScheduleFilterData, setActivityScheduleFilterData] = useState(
    []
  );

  const [partRequestData, setPartRequestData] = useState([]);
  const [partRequestDataFilterData, setPartRequestDataFilterData] = useState(
    []
  );

  const [partConfirmData, setPartConfirmData] = useState([]);
  const [partCompleteData, setPartCompleteData] = useState([]);
  const [partApptData, setPartAppData] = useState([]);

  // 더보기 페이징
  const [partRequestPage, setPartRequestPage] = useState(1);

  const [partConfirmPage, setPartConfirmPage] = useState(1);
  const [partCompletePage, setPartCompletePage] = useState(1);
  const [partApptPage, setPartAppPage] = useState(1);

  const offset = 4;

  const [activitySchedulePage, setActivitySchedulePage] = useState(1);

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 내 활동 일정 조회
  const getMyActivitySchedual = useCallback(
    async (type) => {
      // const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/sole/activity/${type}/${sessionStorage.idx}?activityIdx=${actIdx}`;
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/sole/activity/${type}/${actIdx}/${sessionStorage.idx}`;

      try {
        const response = await axios.get(URL);

        if (response.status === 200) {
          const { data, totalRows } = response.data;

          // console.log(data);

          let ary = [];

          data.forEach((data) => {
            ary.push({
              actListIdx: data.actListIdx,
              activityDate: data.activityDate,
              activityTime: data.activityTime,
              activityTimeEnd : data.activityTimeEnd,
              activityOrgIdx: data.activityOrgIdx,
              groupListName: data.groupName,
              phoneNum: data.phoneNum,
              orgTitle: data.orgTitle,
              groupNum: data.groupNum,
              recruit: data.recruit,
              recruitNum: data.recruitNum,
              recruitedNum: data.recruitedNum,
              volunteer: data.volunteer,
              activityScheduleIdx: data.activityScheduleIdx,
              orgIdx: data.activitiyOrgIdx,
              groupOrgIdx: data.actListOrgIdx,
            });
          });

          return ary;
        }
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
    },
    [actIdx]
  );

  // 활동, 활동상세, 기관정보 조회
  const getActivityData = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`
      )
      .then((res) => {
        //console.log("활동");
        //console.log(res.data);

        if (
          res.data.partType.includes("X") ||
          res.data.partType.includes("O")
        ) {
          alert("개인 활동자를 모집하지 않는 활동입니다.");

          if (location.state && location.state.isRedirect === true) {
            navigate(-2);
          } else {
            navigate(-1);
          }
        }

        setActivityData(res.data);
        setHideContent(false);
      });
  }, [actIdx, location.state, navigate]);

  // 참여 요청 목록 조회
  const getPartRequestData = useCallback(() => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_request/list/${userIdx}`;
    axios
      .get(URL, {
        params: {
          actIdx: actIdx,
        },
      })
      .then((res) => {
        const { totalRows, data } = res.data;
        console.log(data);
        setPartRequestData(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [actIdx, userIdx]);

  // 활동 일정 조회
  const getActivityScheduleData = useCallback(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_USER_SERVICE_API
        }/activity-schedule/non-attend/${actIdx}/${window.sessionStorage.getItem(
          "idx"
        )}`
      )
      .then((res) => {
        // console.log("스케줄");
        // console.log(res.data.data);
        setActivityScheduleData(res.data.data);
      });
  }, [actIdx]);

  // 신청 가능 일정 기간 선택
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const startDateChangeHandler = (e) => {
    console.log(e.target.value);
    setStartDate(e.target.value);
  };

  const endDateChangeHandler = (e) => {
    console.log(e.target.value);
    setEndDate(e.target.value);
  };

  const [selOrgIdx, setSelOrgIdx] = useState("");
  const [selGroupName, setSelGroupName] = useState("");
  const [selActScheduleIdx, setSelActScheduleIdx] = useState("");
  const [selActListIdx, setSelActListIdx] = useState("");

  const [selPartRequestIdx, setSelPartRequestIdx] = useState("");

  const updateActListStatus = (type) => {
    if (
      selGroupName === "" ||
      selActScheduleIdx === "" ||
      selActListIdx === ""
    ) {
      return;
    }

    // 그룹이 아닐경우
    if (selGroupName === "0") {
      updateActList(type);
    } else {
      updateGroupActList(type);
    }
  };

  // 활동자 변경
  const updateActList = (type) => {
    if (type === "delete" || type === "cancel") {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/${selActListIdx}`;
      axios
        .delete(URL)
        .then((res) => {
          // console.log(res.data);
          navigate(0);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else if (type === "partCancel") {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/org/profile/listapplicationpart/cancel-participation/${selActListIdx}`;
      axios
        .put(URL)
        .then((res) => {
          // console.log(res.data);
          navigate(0);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else if (type === "waiting") {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/org/profile/listapplicationpart/waiting/${selActListIdx}`;
      axios
        .put(URL)
        .then((res) => {
          // console.log(res.data);
          navigate(0);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  const updatePartRequesetStatus = (type) => {
    if (selPartRequestIdx === "" || selPartRequestIdx === undefined) {
      return;
    }

    dismissalPartReuqest();
  };

  // 참여요청 목록 반려
  const dismissalPartReuqest = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_request/volunteer/${selPartRequestIdx}`;

    const params = {
      volunteer: "N",
      updatedUid: userIdx,
    };

    axios
      .put(URL, null, {
        params: params,
      })
      .then((res) => {
        // console.log(res.data);
        navigate(0);
      })
      .catch((err) => {
        console.log(err.reponse);
      });
  };

  // 활동자 그룹 변경
  const updateGroupActList = (type) => {
    const params = {
      groupName: selGroupName,
      activityScheduleIdx: selActScheduleIdx,
    };

    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/group/${type}/${selOrgIdx}`;
    //console.log(URL);
    axios
      .put(URL, null, {
        params: params,
      })
      .then((res) => {
        //console.log(res.data);
        navigate(0);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 활동 소식받기
  const receiveActNews = () => {
    if (window.confirm(`${activityData.title} 활동 소식을 받으시겠습니까?`)) {
      const data = {
        sendIdx: actIdx,
        receiveIdx: userIdx,
      };

      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/news_request`;
      axios
        .post(URL, data)
        .then((res) => {
          // console.log(res.data);
          alert(`${activityData.title} 활동 소식받기 등록완료`);
          setNewsStatus(true);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  // 활동 소식받기 체크
  const [newsStatus, setNewsStatus] = useState(false);
  const getActNewsStatus = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/news-request/${actIdx}/${window.sessionStorage.getItem("idx")}`;
    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        if (response.data === "Y") {
          setNewsStatus(true);
        } else {
          setNewsStatus(false);
        }
      }
    } catch (e) {
      console.log(e);
      alert("활동 소식받기 상태값 조회 중, 오류가 발생하였습니다.");
    }
  }, [actIdx]);

  // 활동 소식 받기 취소
  const cancelReceiveActNews = async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/news-request/${actIdx}/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.put(url);

      if (response.status === 201) {
        alert("활동 소식받기 취소가 완료되었습니다.");
        setNewsStatus(false);
      }
    } catch (e) {
      console.log(e);
      alert("활동 소식받기 취소 중, 오류가 발생하였습니다.");
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        if (actIdx === undefined) return;

        checkActivityPageAccess(actIdx).then((res) => {
          if (res === "ERROR") {
            navigate("/");
          } else if (res === "TRUE") {
            getActivityData();
            getActivityScheduleData();

            // 참여 요청 목록 조회
            getPartRequestData();

            // 내 활동 일정 조회
            // 대기중, 참여 취소한 일정 조회
            getMyActivitySchedual("waiting").then((data) =>
              setPartAppData(data)
            );
            getMyActivitySchedual("comfirm").then((data) =>
              setPartConfirmData(data)
            );
            getMyActivitySchedual("complete").then((data) =>
              setPartCompleteData(data)
            );

            // 소식 받기 상태값 조회
            getActNewsStatus();
          } else {
            navigate(res, {
              state: {
                isRedirect: true,
              },
            });
          }
        }).catch((e) => {
          console.log(e);
          navigate(-1);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [
    actIdx,
    getActNewsStatus,
    getActivityData,
    getActivityScheduleData,
    getMyActivitySchedual,
    getPartRequestData,
    navigate,
  ]);

  // 일정 날짜 검색
  useEffect(() => {
    if (activityScheduleData) {
      // console.log(`시작일 : ${startDate} / 종료일 : ${endDate}`);
      // 검색 조건이 없을경우
      if (startDate === "" && endDate === "") {
        // 필터없이 전체 스케줄 조회
        // console.log(activityScheduleData);
        setActivityScheduleFilterData(activityScheduleData);
      }
      // 종료일만 있을경우
      else if (startDate === "" && endDate !== "") {
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) => activitySchedule.activityDate <= endDate
          )
        );
      }
      // 시작일만 있을경우
      else if (startDate !== "" && endDate === "") {
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) => startDate <= activitySchedule.activityDate
          )
        );
      }
      // 시작일 종료일 모두 있을경우
      else if (startDate !== "" && endDate !== "") {
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) =>
              startDate <= activitySchedule.activityDate &&
              activitySchedule.activityDate <= endDate
          )
        );
      }
    }
  }, [activityScheduleData.length === 0, startDate, endDate]);

  // 참여 요청 목록 날짜 검색
  useEffect(() => {
    if (partRequestData) {
      // console.log(`시작일 : ${startDate} / 종료일 : ${endDate}`);
      // 검색 조건이 없을경우
      if (startDate === "" && endDate === "") {
        // 필터없이 전체 스케줄 조회
        // console.log(activityScheduleData);
        setPartRequestDataFilterData(partRequestData);
      }
      // 종료일만 있을경우
      else if (startDate === "" && endDate !== "") {
        // console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setPartRequestDataFilterData(
          partRequestData.filter(
            (activitySchedule) => activitySchedule.activityDate <= endDate
          )
        );
      }
      // 시작일만 있을경우
      else if (startDate !== "" && endDate === "") {
        // console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setPartRequestDataFilterData(
          partRequestData.filter(
            (activitySchedule) => startDate <= activitySchedule.activityDate
          )
        );
      }
      // 시작일 종료일 모두 있을경우
      else if (startDate !== "" && endDate !== "") {
        // console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setPartRequestDataFilterData(
          partRequestData.filter(
            (activitySchedule) =>
              startDate <= activitySchedule.activityDate &&
              activitySchedule.activityDate <= endDate
          )
        );
      }
    }
  }, [partRequestData.length === 0, startDate, endDate]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <ActivityDetailHeader title="활동자 모집 상세" actIdx={actIdx} />
        {/* PAGE CONTENT */}
        <div className="page__content page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <ActivityDetail folder="sole" activityData={activityData} />
          </div>
          <div className="page_section">
            <div className="page__title-bar mt-48 mb-24">
              <h1 className="page__title">신청 가능한 일정</h1>
            </div>
            <div className="form__row">
              <h2>기간 선택</h2>
              <div className="form__row d-flex align-items-center justify-space">
                <input
                  type="date"
                  name="Date"
                  value={startDate}
                  className="form__input required"
                  placeholder="Date"
                  onChange={startDateChangeHandler}
                />
                <input
                  type="date"
                  name="Date"
                  value={endDate}
                  className="form__input required ml-10"
                  placeholder="Date"
                  onChange={endDateChangeHandler}
                />
              </div>
            </div>
            {/* 
            <div className="page__title-bar mt-48">
              <h2>참여 요청 목록({partRequestDataFilterData.length})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {partRequestDataFilterData &&
                partRequestDataFilterData
                  .map((schedule, idx) => (
                    <ActivityScheduleList
                      key={idx}
                      folder="sole"
                      schedule={schedule}
                      activityData={activityData}
                      setSelPartRequestIdx={setSelPartRequestIdx}
                    />
                  ))
                  .slice(0, partRequestPage * offset)}
              <div className="buttons buttons--centered mb-20">
                {partRequestDataFilterData.length > partRequestPage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setPartRequestPage, partRequestPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

          */}
            <div className="page__title-bar mt-48">
              <h2>일정({activityScheduleFilterData.length})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {activityScheduleFilterData &&
                activityScheduleFilterData
                  .map((schedule, idx) => (
                    <ActivityScheduleList
                      key={idx}
                      folder="sole"
                      schedule={schedule}
                      activityData={activityData}
                    />
                  ))
                  .slice(0, activitySchedulePage * offset)}
              <div className="buttons buttons--centered mb-20">
                {activityScheduleFilterData.length >
                activitySchedulePage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setActivitySchedulePage, activitySchedulePage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="page_section">
            <div className="page__title-bar mt-48 mb-24">
              <h1 className="page__title">내 활동 일정</h1>
            </div>
            <div className="page__title-bar mb-48">
              <h2>참여 확정된 일정({partConfirmData.length})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {partConfirmData &&
                partConfirmData
                  .map((mySchedule, idx) => (
                    <MyScheduleGroupList
                      key={idx}
                      folder="org"
                      type="confirm"
                      scheduleGroup={mySchedule}
                      setSelOrgIdx={setSelOrgIdx}
                      setSelGroupName={setSelGroupName}
                      setSelActScheduleIdx={setSelActScheduleIdx}
                      setSelActListIdx={setSelActListIdx}
                    />
                  ))
                  .slice(0, partConfirmPage * offset)}
              <div className="buttons buttons--centered mb-20">
                {partConfirmData.length > partConfirmPage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setPartConfirmPage, partConfirmPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="page__title-bar">
              <h2>완료한 일정({partCompleteData.length})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {partCompleteData &&
                partCompleteData
                  .map((mySchedule, idx) => (
                    <MyScheduleGroupList
                      key={idx}
                      folder="org"
                      type="complete"
                      scheduleGroup={mySchedule}
                      setSelOrgIdx={setSelOrgIdx}
                      setSelGroupName={setSelGroupName}
                      setSelActScheduleIdx={setSelActScheduleIdx}
                      setSelActListIdx={setSelActListIdx}
                    />
                  ))
                  .slice(0, partCompletePage * offset)}
              <div className="buttons buttons--centered mb-20">
                {partCompleteData.length > partCompletePage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setPartCompletePage, partCompletePage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="page__title-bar">
              <h2>참여 신청한 일정({partApptData.length})</h2>
            </div>
            <div className="cards cards--6" id="loadlist-01">
              {partApptData &&
                partApptData
                  .map((mySchedule, idx) => (
                    <MyScheduleGroupList
                      key={idx}
                      folder="org"
                      type="request"
                      scheduleGroup={mySchedule}
                      setSelOrgIdx={setSelOrgIdx}
                      setSelGroupName={setSelGroupName}
                      setSelActScheduleIdx={setSelActScheduleIdx}
                      setSelActListIdx={setSelActListIdx}
                    />
                  ))
                  .slice(0, partApptPage * offset)}
              <div className="buttons buttons--centered mb-20">
                {partApptData.length > partApptPage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setPartAppPage, partApptPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="under_button">
              <div className="form__row mt-40">
                <input
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--50"
                  id="submit"
                  value={newsStatus ? "활동 소식받기 취소" : "활동 소식받기"}
                  onClick={() =>
                    newsStatus ? cancelReceiveActNews() : receiveActNews()
                  }
                />
                <input
                  onClick={chatdClickHandler}
                  style={{ cursor: "pointer" }}
                  type="submit"
                  name="submit"
                  className="form__submit button button--main button--50"
                  id="submit"
                  value="문의하기"
                />
              </div>
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformPopup
        text="정말 반려하시겠습니까?"
        popupType="dismissal"
        conformClick={() => updatePartRequesetStatus()}
      />
      <ConformPopup
        text="정말 삭제하시겠습니까?"
        popupType="delete"
        conformClick={() => updateActListStatus("delete")}
      />
      <ConformPopup
        text="정말 참여 취소하시겠습니까?"
        popupType="partcancel"
        conformClick={() => updateActListStatus("partCancel")}
      />
      <ConformPopup
        text="정말 신청 취소하시겠습니까?"
        popupType="requestcancel"
        conformClick={() => updateActListStatus("cancel")}
      />
      <ConformPopup
        text="정말 재신청 하시겠습니까?"
        popupType="reParticipation"
        conformClick={() => updateActListStatus("waiting")}
      />
    </Fragment>
  );
}

export default DetailPart;
