import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityItem from "../../../components/ActivityItem";
import MemberItem from "../../../components/MemberItem";
import OrgProfile from "../../../components/OrgProfile";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";
import ConformPopup from "../../common/popup/ConformPopup";

function Org(props) {
  const navigate = useNavigate();
  const { orgIdx } = useParams();

  // DB 데이터
  const [activityData, setActivityData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);
  const [orgData, setOrgDate] = useState();
  const [orgMemberData, setOrgMemberData] = useState([]);

  const [helpActData, setHelpActData] = useState([]);
  const [partActData, setPartActData] = useState([]);

  // 더보기 처리
  const [activityPage, setActivitiyPage] = useState(1);
  const [consumerPage, setConsumerPage] = useState(1);
  const [orgMemberPage, setOrgMemberPage] = useState(1);

  const [helpActPage, setHelpActPage] = useState(1);
  const [partActPage, setPartActPage] = useState(1);

  const offsetActivityPage = 6;
  const offsetOrgMemberPage = 8;

  const viewOnlyActType = ["activityType"];

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 기관 상세조회
  const getOrgData = () => {
    axios
      .get(`${process.env.REACT_APP_USER_SERVICE_API}/org/${orgIdx}`)
      .then((res) => {
        setOrgDate(res.data);
      });
  };

  // 활동 조회
  const getActivityData = () => {
    // axios URL
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/${orgIdx}`;
    axios.get(url).then((res) => {
      const json = res.data.data;

      // 활동의 기관명 추가
      json.map((item) => {
        axios
          .get(`${process.env.REACT_APP_USER_SERVICE_API}/org/${item.orgIdx}`)
          .then((res) => {
            item.orgTitle = res.data.orgTitle;

            setActivityData(
              json &&
              json.filter(
                (json) =>
                  (json.partType === "A" || json.partType === "U") &&
                  json.isPrivate === "O"
              )
            );
            setConsumerData(
              json &&
              json.filter(
                (json) =>
                  (json.beneType === "A" || json.beneType === "U") &&
                  json.isPrivate === "O"
              )
            );
          });
      });
    });
  };

  // 회원 소속 기관 조회
  const getOrgMemberData = () => {
    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${orgIdx}`
      )
      .then((res) => {
        const orgMemberList = res.data.data;
        axios
          .all(
            res.data.data.map((orgMember) => {
              return axios.get(
                `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
              );
            })
          )
          .then((res2) => {
            res2.map((user, index) => {
              orgMemberList[index].nickname = user.data.nickname;
            });

            setOrgMemberData(orgMemberList);
          });
      });
  };

  // 도움 받은 활동 조회
  const getHelpActData = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/request/done/${orgIdx}`;
    axios
      .get(URL)
      .then((res) => {
        const data = res.data.data;

        let ary = [];

        data.forEach((data) => {
          ary.push({
            idx: data.idx,
            activityDate: data.activityDate,
            address1: data.address1,
            address2: data.address2,
            helpUserIdx: data.helpUserIdx,
            orgIdx: data.orgIdx,
            orgTitle: data.orgTitle,
            activityType: data.activityType,
            title: data.title,
            activityIdx: data.activityIdx,
            content: data.content,
          });
        });

        setHelpActData(ary);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 참여한 활동 조회
  const getPartActData = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/activity/participate/${orgIdx}`;
    axios
      .get(URL)
      .then((res) => {
        const data = res.data.data;

        let ary = [];

        data.forEach((data) => {
          ary.push({
            // idx: data.idx,
            activityDate: data.activityDate,
            address1: data.address1,
            address2: data.address2,
            helpUserIdx: data.helpUserIdx,
            orgIdx: data.orgIdx,
            orgTitle: data.orgTitle,
            activityType: data.activityType,
            title: data.activityTitle,
            activityIdx: data.activityIdx,
            content: data.content,
          });
        });

        setPartActData(ary);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 기관 소속회원 신청
  const requestOrgMemberClickHandler = () => {
    if (
      window.confirm(
        `${orgData && orgData.orgTitle} 기관에 소속회원 신청을 하시겠습니까?`
      )
    ) {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list`;
      const data = {
        createdUid: sessionStorage.idx,
        orgIdx: orgIdx,
        userIdx: sessionStorage.idx,
        volunteer: "N",
      };

      axios
        .post(URL, data)
        .then((res) => {
          navigate(0);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            alert("이미 소속회원 신청을 했거나, 소속된 회원입니다.");
          } else {
            alert("소속 회원 신청 에러 발생");
            console.log(err.response);
          }
        });
    }
  };

  const [cancelIdx, setCancelIdx] = useState(0);

  // 회원 탈퇴
  const cancelOrgMemberClickHandler = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/${cancelIdx}`;

    axios
      .delete(URL)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  // 활동 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getOrgData();
        getActivityData();
        getOrgMemberData();
        getHelpActData();
        getPartActData();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content page_section">
          <SubHeader title="" />
          <OrgProfile org={orgData} type="otherprofile" folder="sole" />
          <div className="tabs tabs--style1 mb-20">
            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab1"
              defaultChecked="checked"
            />
            <label
              className="tabs__label tabs__left tabs__label--12"
              htmlFor="tab1"
            >
              운영한 활동
            </label>
            <div className="tabs__content">
              <h2>활동자 모집 ({activityData.length})</h2>
              <div className="cards cards--12" id="loadlist-01">
                {activityData &&
                  activityData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/sole/activity/detailpart/${activity.idx}`}
                        folder="sole"
                        activity={activity}
                        viewTagList={viewOnlyActType}
                      />
                    ))
                    .slice(0, activityPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {activityData.length > activityPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setActivitiyPage, activityPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="page__title-bar mt-48">
                <h2>도움이 필요하신가요? ({consumerData.length})</h2>
              </div>
              <div className="cards cards--12" id="loadlist-02">
                {consumerData &&
                  consumerData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/sole/activity/detailbene/${activity.idx}`}
                        folder="sole"
                        activity={activity}
                        viewTagList={viewOnlyActType}
                      />
                    ))
                    .slice(0, consumerPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {consumerData.length > consumerPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setConsumerPage, consumerPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab2"
            />
            <label
              className="tabs__label tabs__right tabs__label--12"
              htmlFor="tab2"
            >
              도움받은 활동
            </label>
            <div className="tabs__content">
              <h2>도움받은 활동 ({helpActData.length})</h2>
              <div className="cards cards--12" id="loadlist-01">
                {helpActData &&
                  helpActData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/org/activity/detailbene/${activity.activityIdx}`}
                        folder="org"
                        activity={activity}
                        viewTagList={viewOnlyActType}
                      />
                    ))
                    .slice(0, helpActPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {helpActData.length > helpActPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setHelpActPage, helpActPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab3"
            />
            <label
              className="tabs__label tabs__right tabs__label--4 hidden"
              htmlFor="tab3"
            >
              참여한 활동
            </label>
            <div className="tabs__content">
              <h2>참여한 활동 ({partActData.length})</h2>
              <div className="cards cards--12" id="loadlist-01">
                {partActData &&
                  partActData
                    .map((activity) => (
                      <ActivityItem
                        key={activity.idx}
                        url={`/org/activity/detailpart/${activity.activityIdx}`}
                        folder="org"
                        activity={activity}
                        viewTagList={viewOnlyActType}
                      />
                    ))
                    .slice(0, partActPage * offsetActivityPage)}
              </div>
              <div className="buttons buttons--centered mb-20">
                {partActData.length > partActPage * offsetActivityPage ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setPartActPage, partActPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-space align-items-center pt-40 mb-20">
            <h2>소속 회원({orgMemberData && orgMemberData.length})</h2>
            <input
              style={{ cursor: "pointer" }}
              type="button"
              name="submit"
              className="form__submit button button--main float-right"
              value="소속 회원 신청"
              onClick={requestOrgMemberClickHandler}
            />
          </div>
          <div className="tabs tabs--style1 mb-20">
            <input
              type="radio"
              name="tabs3"
              className="tabs__radio"
              id="tab3"
              defaultChecked="checked"
            />
            <div className="cards cards--14">
              {orgMemberData &&
                orgMemberData.map((orgMember) => (
                  <MemberItem
                    folder="sole"
                    orgMember={orgMember}
                    setCancelIdx={setCancelIdx}
                  />
                ))}
            </div>
            <div className="buttons buttons--centered mb-20">
              {orgMemberData.length > orgMemberPage * offsetOrgMemberPage ? (
                <div
                  id=""
                  className="button button--more"
                  onClick={() => {
                    addPage(setOrgMemberPage, orgMemberPage);
                  }}
                >
                  더보기
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        popupType="secession"
        text="정말 회원 탈퇴하시겠습니까?"
        conformClick={cancelOrgMemberClickHandler}
      />
    </Fragment>
  );
}

export default Org;
