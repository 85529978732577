import React, { Fragment, useEffect, useState } from "react";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import Navigationbar from "../../../components/Navigationbar";
import CheckLoginInfo from "../../../services/login-validation";

function ExpertEdit() {
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        <div className="page__content page_section">
          {/* HEADER */}
          <SubHeader title="편집" />
          {/* PAGE CONTENT */}
          <div className="page__content  page_section--with-header-68">
            {/* SLIDER SIMPLE */}
            <div className="fieldsets mt-48">
              <div className="user-profile__thumb">
                <img src="../../assets/images/photos/img-p-expert.jpg" alt="" />
              </div>
              <div className="upload-filebox mt-20">
                <label htmlFor="ex_file">사진 업로드</label>
                <input type="file" id="ex_file" />
              </div>
            </div>
            <div className="fieldsets mt-48">
              <div className="form">
                <form id="ContactForm" method="post" action>
                  <h4>ID</h4>
                  <div className="form__row mb-20">
                    <input
                      type="text"
                      name="ContactName"
                      value=""
                      className="form__input required"
                      placeholder="입력"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="fieldsets mt-48">
              <div className="form">
                <form id="ContactForm" method="post" action>
                  <h4>비밀번호</h4>
                  <div className="form__row mb-20">
                    <input
                      type="password"
                      name="password"
                      value=""
                      className="form__input required"
                      placeholder="입력"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="fieldsets mt-48">
              <div className="form">
                <form id="ContactForm" method="post" action>
                  <h4>비밀번호 확인</h4>
                  <div className="form__row mb-20">
                    <input
                      type="password"
                      name="password"
                      value=""
                      className="form__input required"
                      placeholder="입력"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="fieldsets mt-48">
              <div className="form">
                <form id="ContactForm" method="post" action>
                  <h4>소개</h4>
                  <div className="form__row">
                    <textarea
                      name="ContactComment"
                      className="form__textarea required"
                      placeholder="내용을 기입해주세요."
                      value=""
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="form__row mt-40">
              <input
                type="submit"
                name="submit"
                data-popup="conform_cancle"
                className="form__submit button button--gray button--50 open-popup"
                id="submit"
                value="취소"
              />
              <input
                type="submit"
                name="submit"
                className="form__submit button button--main button--50 open-popup"
                data-popup="conform_save"
                id="submit"
                value="저장"
              />
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default ExpertEdit;
