import React from "react";
import ScheduleListItem from "./ScheduleListItem";

const ScheduleList = ({ list, pageNumber, setPageNumber, totalRows }) => {
  return (
    <div className="cards cards--6" id="loadlist-01">
      {list.map((scheduleInfo) => (
        <ScheduleListItem scheduleInfo={scheduleInfo} key={scheduleInfo.id} />
      ))}

      {totalRows > list.length && (
        <div
          className="buttons buttons--centered mb-20"
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          <div className="button button--more">더 보기</div>
        </div>
      )}
    </div>
  );
};

export default ScheduleList;
