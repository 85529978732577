import axios from "axios";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import {Modal} from "../../../components/DaumPostModal";
import CategoryList from "../../../components/CategoryList";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";
import FileUpload from "../../common/FileUpload";
import {isAndroid} from "../../../util/mobile-function";

function Edit(props) {
    const navigate = useNavigate();
    // 다음 주소
    const [modalOpen, setModalOpen] = useState(false);
    const [postcode, setPostcode] = useState("");
    const [address1, setAddress1] = useState("");

    // 삭제한 IDX 리스트
    const [deleteIdxList, setDeleteIdxList] = useState(new Set());

    // input 데이터
    const [values, setValues] = useState({
        categoryIdx: "",
        orgTitle: "",
        address2: "",
        contact: "",
        email: "",
        type: "",
        bio: "",
        orgFile: "",
        openStatus: "",
        file: "",
        file2: "",
    });

    // DB 카테고리 데이터
    const [categoryData, setCategoryData] = useState([]);
    // 추가된 카테고리 데이터
    const [addedCategoryData, setAddedCategoryData] = useState([]);
    // 삭제 버튼 누른 카테고리 IDX
    const [delCategoryIdx, setDelCategoryIdx] = useState(0);

    // 카테고리 조회
    const getCategoryData = () => {
        // axios URL
        const url = `${process.env.REACT_APP_USER_SERVICE_API}/category`;
        axios
            .get(url)
            .then((res) => {
                setCategoryData(res.data.data);
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    // 카테고리 리스트 조회
    const getCategoryListData = () => {
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/list_category/org/${sessionStorage.idx}`;
        axios
            .get(url)
            .then((res) => {
                const data = res.data.data;
                let ary = [];

                data.forEach((data) => {
                    ary.push({
                        idx: data.idx,
                        category: data.category,
                        imageUrl: data.imageUrl,
                    });
                });

                setAddedCategoryData(ary);
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    // 기관 상세 조회
    const getOrgData = () => {
        const url = `${process.env.REACT_APP_USER_SERVICE_API}/org/${sessionStorage.idx}`;
        axios
            .get(url)
            .then((res) => {
                const resJson = res.data;
                resJson.file2 = "";
                setValues(resJson);
                setAddress1(resJson.address1);
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    const getOrgProfileImg = () => {
        const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
        axios
            .get(URL, {
                params: {
                    pkIdx: sessionStorage.idx,
                    imageStatus: "O",
                },
            })
            .then((res) => {
                // 파일 조회시 업로드 이미지 Path Set
                if (res.data !== "") {
                    setRenderImagUrl(
                        `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
                    );
                }
            });
    };

    const getRegCertFile = () => {
        const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
        axios
            .get(URL, {
                params: {
                    pkIdx: sessionStorage.idx,
                    imageStatus: "OP",
                },
            })
            .then((res) => {
                // 파일 조회시 업로드 이미지 Path Set
                setRegFileName(res.data.fileName);
            });
    };

    // 활동 매칭 카테고리 추가
    const addCategory = (categoryIdx) => {
        if (categoryIdx === "") {
            alert("카테고리를 선택해주세요.");
            return;
        }

        // 추가시 삭제 리스트에서 해당 카테고리 IDX 제거
        deleteIdxList.delete(Number(categoryIdx));
        setDeleteIdxList(deleteIdxList);

        // 이미 추가된 카테고리 여부
        const addedCategoryYn = addedCategoryData.filter(
            (category) => category.idx === Number(categoryIdx)
        ).length;

        if (addedCategoryYn !== 0) {
            alert("이미 추가된 카테고리입니다.");
            return;
        }

        // 추가될 카테고리 필터링
        const addedCategory = categoryData.filter(
            (category) => category.idx === Number(categoryIdx)
        );

        if (addedCategory) {
            setAddedCategoryData(addedCategoryData.concat(addedCategory[0]));
        }
    };

    const deleteListCategory = () => {
        let URL = `${process.env.REACT_APP_USER_SERVICE_API}/list_category`;

        deleteIdxList.forEach((deleteIdx) => {
            const data = {
                categoryIdx: deleteIdx,
                orgMatIdx: sessionStorage.idx,
                orgMatStatus: "M",
            };

            axios
                .delete(URL, {
                    data: data,
                })
                .then((res) => {
                })
                .catch((err) => {
                    console.log(err.response);
                });
        });
    };

    const insertListCateogry = () => {
        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/list_category`;

        addedCategoryData &&
        addedCategoryData.forEach((category) => {
            const data = {
                categoryIdx: category.idx,
                orgMatIdx: sessionStorage.idx,
                createdUid: sessionStorage.idx,
                orgMatStatus: "O",
            };

            axios
                .post(URL, data)
                .then(res => {
                })
                .catch((err) => console.log(err.response));
        });
    };

    // 기관 프로필 편집
    const requestorg = (e) => {
        if (values.orgTitle.trim() === "") {
            alert("기관/단체 이름을 입력해주세요.");
            return;
        }

        if (address1 === "") {
            alert("주소를 검색해주세요.");
            return;
        }

        if (values.address2.trim() === "") {
            alert("상세주소를 입력해주세요.");
            return;
        }

        if (values.contact.trim() === "") {
            alert("연락처를 입력해주세요.");
            return;
        }

        if (values.email.trim() === "") {
            alert("이메일을 입력해주세요.");
            return;
        }

        if (values.type === "") {
            alert("기관 타입을 선택해주세요.");
            return;
        }

        if (values.openStatus === "") {
            alert("공개여부를 선택해주세요");
            return;
        }

        // axios URL
        // 기관 PUT
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/org/${sessionStorage.idx}`;
        let data = {
            id: sessionStorage.idx,
            categoryIdx: values.categoryIdx,
            orgTitle: values.orgTitle,
            address1: address1,
            address2: values.address2,
            contact: values.contact,
            email: values.email,
            type: values.type,
            bio: values.bio,
            orgFile: values.orgFile,
            openStatus: values.openStatus,
            updateUid: sessionStorage.idx,
        };
        var config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        axios
            .put(url, data, config)
            .then((res) => {

                // 변경된 정보 session 에 업데이트
                window.sessionStorage.setItem("email", res.data.email);
                window.sessionStorage.setItem("orgType", res.data.type);

                // 리스트_카테고리 전체 삭제 후 재등록 (수정)
                deleteListCategory();
                insertListCateogry();

                if (values.file2 !== "") {
                    const formData = new FormData();
                    formData.append("file", values.file2);
                    formData.append("pkIdx", sessionStorage.idx);
                    formData.append("imageStatus", "OP");

                    axios.post(`${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/uploadfile`, formData).then((res) => {
                        navigate(`/org/profile/org`, {state: {}});
                    });
                }

                // 이미지 등록
                if (renderImgFile) {
                    const formData = new FormData();
                    formData.append("file", renderImgFile);
                    formData.append("pkIdx", sessionStorage.idx);
                    formData.append("imageStatus", "O");

                    // 기관 이미지 post
                    axios.post(`${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/uploadfile`, formData).then((res) => {
                        navigate(`/org/profile/org`, {state: {}});
                    });
                } else {
                    navigate(`/org/profile/org`, {state: {}});
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    };

    const [renderImgFile, setRenderImagFile] = useState("");
    const defaultImgPath = "/assets/images/photos/img-p-org.jpg";
    const [renderImgUrl, setRenderImagUrl] = useState(defaultImgPath);
    // 기관/ 단체 등록 증명서 이름
    const [regFileName, setRegFileName] = useState("");

    const handleChangeForm = (e) => {
        if (e.target.name === "file") {
            // 파일 객체
            const file = e.target.files[0];

            if (file) {
                // 파일 확장자 추출
                const fileLen = file.name.length;
                const lastDotIdx = file.name.lastIndexOf(".");
                const fileEx = file.name.substring(lastDotIdx, fileLen).toLowerCase();

                // 파일 크기
                const fileSize = file.size;

                if (fileEx !== ".jpg" && fileEx !== ".jpeg" && fileEx !== ".png") {
                    alert("이미지 확장자는 .jpg, .jpeg, .png 만 업로드가 가능합니다.");
                    return;
                }

                if (fileSize > 10000000) {
                    alert("이미지 파일크기는 10M를 초과할 수 없습니다.");
                    return;
                }

                setImageFromFile(file);

                setValues({
                    ...values,
                    [e.target.name]: file,
                });
            }
        } else if (e.target.name === "file2") {

            const file = e.target.files[0];
            if (file) {
                // 파일 크기
                if (file.size > 10000000) {
                    alert("등록증명서 파일크기는 10M를 초과할 수 없습니다.");
                    return;
                }

                setRegFileName(file.name);

                setValues({
                    ...values,
                    [e.target.name]: file,
                });
            }
        } else {
            setValues({
                ...values,
                [e.target.name]: e.target.value,
            });
        }
    };

    const setImageFromFile = (file) => {
        let reader = new FileReader();
        reader.onload = function () {
            setRenderImagFile(file);
            setRenderImagUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    // 다음주소찾기 모달 열기, 닫기
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    const daumPostModalOpenHandler = () => {
        openModal(true);
    };

    const delCategoryClickHandler = () => {
        // 삭제시 삭제 리스트에 해당 카테고리 IDX 추가
        deleteIdxList.add(Number(delCategoryIdx));
        setDeleteIdxList(deleteIdxList);

        setAddedCategoryData(
            addedCategoryData.filter(
                (addedCategory) => addedCategory.idx !== delCategoryIdx
            )
        );
    };

    const chageDefualtImg = () => {
        setRenderImagUrl(defaultImgPath);
    };

    const [hideContent, setHideContent] = useState(true);

    const [showFileModal, setShowFileModal] = useState(false);
    const toggleFileModal = () => {
        setShowFileModal(!showFileModal);
    };
    const getFile = (file, name, imgUrl, type) => {
        if (type === "img") {
            setValues({
                ...values,
                file: file,
            });
            setImageFromFile(file);
        } else {
        }

        toggleFileModal();
    };

    useEffect(() => {
        CheckLoginInfo()
            .then(() => {
                getOrgData();
                getCategoryData();
                getOrgProfileImg();
                getRegCertFile();
                getCategoryListData();

                setHideContent(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    if (hideContent) {
        return null;
    }

    if (showFileModal) {
        return (
            <FileUpload
                type="img"
                getFile={getFile}
                toggleFileModal={toggleFileModal}
            />
        );
    }

    return (
        <Fragment>
            <Panel/>
            <div className="page page--main" data-page="shop">
                <Modal
                    open={modalOpen}
                    close={closeModal}
                    setModalOpen={setModalOpen}
                    setAddress={setAddress1}
                    setPostcode={setPostcode}
                />
                {/* HEADER */}
                <SubHeader title="편집"/>
                {/* PAGE CONTENT */}
                <div className="page__content page_section page__content page_section--with-header-68 ">
                    {/* SLIDER SIMPLE */}
                    <div className="fieldsets mt-48">
                        <div className="user-profile__thumb">
                            <img
                                src={renderImgUrl}
                                alt={renderImgFile && renderImgFile.name}
                                onError={chageDefualtImg}
                            />
                        </div>
                        <div className="upload-filebox mt-20">
                            <form id="_frm" method="post" enctype="multipart/form-data">
                                <label htmlFor="ex_file">기관 이미지 업로드</label>
                                <input
                                    type={"file"}
                                    id="ex_file"
                                    accept=".jpg, .jpeg, .png"
                                    capture="camera"
                                    name="file"
                                    onChange={handleChangeForm}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="fieldsets mt-48">
                        <div className="d-flex justify-space align-items-center">
                            <h2 className="mb-0">기관/단체 이름</h2>
                        </div>
                        <div className="form">
                            <form id="ContactForm" method="post" action>
                                <h4>주소</h4>
                                <div className="form__row d-flex align-items-center justify-space mb-20">
                                    <div className="form__select form__input form__select--23 mr-16">
                                        <input
                                            type="text"
                                            name="address1"
                                            value={address1}
                                            onChange={handleChangeForm}
                                            onClick={daumPostModalOpenHandler}
                                            className="form__input required"
                                            placeholder="주소"
                                        />
                                    </div>
                                    <div className="form__button--13">
                                        <input
                                            type="button"
                                            name="address1"
                                            className="form__submit button button--main"
                                            id="submit"
                                            value="검색"
                                            onClick={daumPostModalOpenHandler}
                                        />
                                    </div>
                                </div>
                                <h4>상세 주소</h4>
                                <div className="form__row mb-20">
                                    <input
                                        type="text"
                                        name="address2"
                                        value={values.address2}
                                        onChange={handleChangeForm}
                                        className="form__input required"
                                        placeholder="입력"
                                    />
                                </div>
                                <h4>연락처</h4>
                                <div className="form__row mb-20">
                                    <input
                                        type="tel"
                                        name="contact"
                                        value={values.contact}
                                        onChange={handleChangeForm}
                                        className="form__input required"
                                        placeholder="ex) 010-1234-5678"
                                    />
                                </div>
                                <h4>이메일</h4>
                                <div className="form__row mb-20">
                                    <input
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChangeForm}
                                        className="form__input required"
                                        placeholder="입력"
                                    />
                                </div>
                                <h4>기관/단체 등록증명서</h4>
                                <div className="form__row d-flex align-items-center justify-space  mb-20">
                                    <div className="form__select form__input form__select--23 mr-16">
                                        <input
                                            type="text"
                                            name="file2"
                                            className="form__input required"
                                            placeholder=""
                                            value={regFileName}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form__button--13">
                                        <div className="upload-filebox mt-20">
                                            <form
                                                id="__frm"
                                                method="post"
                                                enctype="multipart/form-data"
                                            >
                                                <label
                                                    htmlFor="ex_file2"
                                                    style={{border: "none"}}
                                                    className="form__submit button button--main"
                                                >
                                                    파일선택
                                                </label>
                                                <input
                                                    type="file"
                                                    id="ex_file2"
                                                    name="file2"
                                                    onChange={handleChangeForm}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <h4>기관 타입</h4>
                                <div className="form__row mb-20">
                                    <div className="form__select">
                                        <select
                                            name="type"
                                            className="required"
                                            value={values.type}
                                            onChange={handleChangeForm}
                                        >
                                            <option value="" disabled selected>
                                                선택
                                            </option>
                                            <option value="C">활동기관</option>
                                            <option value="A">수요기관</option>
                                            <option value="CA">활동, 수요기관</option>
                                        </select>
                                    </div>
                                </div>
                                <h4>카테고리</h4>
                                <div className="form__row d-flex align-items-center justify-space  mb-20">
                                    <div className="form__select form__input form__select--23 mr-16">
                                        <select
                                            name="categoryIdx"
                                            className="required"
                                            value={values.categoryIdx}
                                            onChange={handleChangeForm}
                                        >
                                            <option value="" disabled selected>
                                                선택
                                            </option>
                                            {categoryData &&
                                                categoryData.map((category) => (
                                                    <option value={category.idx} key={category.idx}>
                                                        {category.category}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form__button--13">
                                        <input
                                            type="button"
                                            name="categoryname"
                                            onClick={() => addCategory(values.categoryIdx)}
                                            className="form__submit button button--main"
                                            id="submit"
                                            value="추가"
                                        />
                                    </div>
                                </div>
                                <div className="cards cards--14">
                                    {addedCategoryData &&
                                        addedCategoryData.map((category) => (
                                            <CategoryList
                                                key={category.idx}
                                                category={category}
                                                setDelCategoryIdx={setDelCategoryIdx}
                                            />
                                        ))}
                                </div>
                                <h4>소개</h4>
                                <div className="form__row">
                  <textarea
                      name="bio"
                      className="form__textarea required"
                      placeholder="내용을 기입해주세요."
                      value={values.bio}
                      onChange={handleChangeForm}
                  />
                                </div>
                                {/*
                <div className="form mt-48">
                  <h4>공개여부</h4>
                  <div className="form__select">
                    <select
                      name="openStatus"
                      className="required"
                      value={values.openStatus}
                      onChange={handleChangeForm}
                    >
                      <option value="" disabled selected>
                        선택
                      </option>
                      <option value="O">공개</option>
                      <option value="C">비공개</option>
                    </select>
                  </div>
                </div>
                */}
                            </form>
                        </div>
                    </div>
                    <div className="form__row mt-40">
                        <input
                            type="submit"
                            name="submit"
                            data-popup="conform_cancle"
                            className="form__submit button button--gray button--50 open-popup"
                            id="submit"
                            value="취소"
                        />
                        <input
                            type="submit"
                            name="submit"
                            data-popup="conform_save"
                            className="form__submit button button--main button--50 open-popup"
                            id="submit"
                            value="저장"
                        />
                    </div>
                    <div className="space-h-100"/>
                </div>
                <ConformPopup
                    popupType="delete"
                    text="정말 삭제하시겠습니까?"
                    conformClick={delCategoryClickHandler}
                />
                <ConformPopup
                    popupType="cancle"
                    text="정말 취소하시겠습니까?"
                    conformClick={() => navigate(-1)}
                />
                <ConformPopup
                    popupType="save"
                    text="해당 내용을 저장하시겠습니까?"
                    conformClick={requestorg}
                />
            </div>
        </Fragment>
    );
}

export default Edit;
