import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import UrlContext from "../context/Url.context";

function Navigation(props) {
  const { folder } = props;
  const urlContext = useContext(UrlContext);

  const [todayAlarm, setTodayAlarm] = useState(0);

  useEffect(() => {
    const getTodayAlarm = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/today-alarm/${
        folder === "org" ? folder : "user"
      }/${window.sessionStorage.getItem("idx")}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setTodayAlarm(parseInt(response.data.totalRows));
        }
      } catch (e) {
        console.log(e);
        alert("오늘의 알람을 조회하는 중, 오류가 발생하였습니다.");
      }
    };

    getTodayAlarm();
  }, []);

  return (
    <div className="bottom-navigation">
      <ul className="bottom-navigation__icons">
        <li>
          <a href={`/${folder}/home/${folder === "sole" ? "solepart" : "org"}`}>
            <img src="/assets/images/icons/blue/home.svg" alt="" title="" />
            <span>홈</span>
          </a>
        </li>
        <li>
          <a href={`/${folder}/schedule/schedule`}>
            <img src="/assets/images/icons/blue/checked.svg" alt="" title="" />
            <span>일정</span>
          </a>
        </li>
        <li>
          <a href={`/${folder}/notification/notification`}>
            <img
              src="/assets/images/icons/blue/notifications.svg"
              alt=""
              title=""
            />
            <span>알림</span>
            {todayAlarm === 0 ? null : <i>{todayAlarm}</i>}
          </a>
        </li>
        <li>
          <a href={`/${folder}/chat/chat`}>
            <img src="/assets/images/icons/blue/chat.svg" alt="" title="" />
            <span>메세지</span>
          </a>
        </li>
        <li>
          {sessionStorage.expertStatus &&
          sessionStorage.expertStatus === "E" ? (
            <a href={`/sole/profileexpert/expert/${sessionStorage.idx}`}>
              <img src="/assets/images/icons/blue/user.svg" alt="" />
              <span>프로필</span>
            </a>
          ) : (
            <a
              href={`/${folder}/${
                folder === "sole" ? "profilesole" : "profile"
              }/${folder}`}
            >
              <img src="/assets/images/icons/blue/user.svg" alt="" />
              <span>프로필</span>
            </a>
          )}
        </li>
      </ul>
    </div>
  );
}

export default Navigation;
