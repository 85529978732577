import React, { Fragment, useContext, useEffect, useState } from "react";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CalendarLibrary from "../../../components/CalendarLibrary";
import ScheduleContext from "../../../context/Schedule.context";
import UrlContext from "../../../context/Url.context";
import SelectedTime from "../../../components/SelectedTime";
import ConformPopup from "../../common/popup/ConformPopup";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CheckLoginInfo from "../../../services/login-validation";

function Calendar(props) {
  const urlContext = useContext(UrlContext);
  const location = useLocation();
  const navigate = useNavigate();
  const actIdx = location.state && location.state.actIdx;
  const { scheduleList, setScheduleList, selectedSchedule, clearSchedule } =
    useContext(ScheduleContext);

  const [orgIdx, setOrgIdx] = useState(0);

  const userIdx = sessionStorage.idx;

  const getActivity = () => {
    axios
      .get(`${process.env.REACT_APP_USER_SERVICE_API}/activity/${actIdx}`)
      .then((res) => {
        const data = res.data;
        setOrgIdx(data.orgIdx);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 선택된 시간 삭제
  const deleteClick = () => {
    setScheduleList(
      scheduleList.filter(
        (item) =>
          item.activityDate !== selectedSchedule.activityDate ||
          item.activityTime !== selectedSchedule.activityTime
      )
    );
  };

  // 도움 요청 등록
  const regHelpRequest = () => {
    if (scheduleList.length === 0) {
      alert("도움 요청 할 시간을 선택해주세요.");
      return;
    }

    scheduleList.forEach((schedule) => {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/help/${userIdx}`;
      const params = {
        helpUserIdx: userIdx,
        orgIdx: orgIdx,
        content: "F",
        activityIdx: actIdx,
        activityDate: schedule.activityDate,
        activityTime: schedule.activityTime,
        recruitNum: schedule.recruitNum,
        createdUid: userIdx,
      };

      axios
        .post(URL, null, {
          params: params,
        })
        .then((res) => {
          // console.log(res.data);
          clearSchedule();
          navigate(`/sole/activity/detailbene/${actIdx}`);
        })
        .catch((err) => {
          console.log(err.response);
        });
    });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getActivity();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="날짜" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar mt-20"></div>
            <CalendarLibrary folder="sole" />
            <h4>선택된 시간</h4>
            <div className="cart cart--page">
              <div
                className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal"
                id="item1"
              >
                {scheduleList &&
                  scheduleList.map((item) => <SelectedTime schedule={item} />)}
              </div>
            </div>
          </div>
          <div className="space-h-100" />
          <div className="under_button">
            <div className="form__row mt-40">
              <input
                onClick={regHelpRequest}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="확인"
              />
            </div>
          </div>
        </div>
      </div>
      <ConformPopup
        popupType="delete"
        text="정말 삭제하시겠습니까?"
        conformClick={deleteClick}
      />
    </Fragment>
  );
}

export default Calendar;
