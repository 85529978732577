import axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";

import ChatRoomList from "../../../components/ChatRoomList";
import Navigation from "../../../components/Navigation";
import CheckLoginInfo from "../../../services/login-validation";

export function Chat(props) {
  const top = useRef();
  const scrollTop = () => {
    top.current.scrollIntoView();
  };

  const [totalRows, setTotalRows] = useState(null);
  const [chatRoomList, setChatRoomList] = useState(null);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getChatRoomList();

        setHideContent(false);

        scrollTop();
      })
      .catch((e) => {
        console.log(e);
      });

    const getChatRoomList = async () => {
      const url = `${
        process.env.REACT_APP_CHAT_SERVICE_API
      }/api/v1/chat/org/list/${window.sessionStorage.getItem("idx")}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { count, rooms } = response.data;

          setTotalRows(count);

          let ary = [];

          for (let i = 0; i < rooms.length; i++) {
            ary.push({
              title: rooms[i].title,
              type: rooms[i].type,
              uri: rooms[i].uri,
              topic: rooms[i].topic,
              // lastMsg: rooms[i].msg,
              lastAccessTime: rooms[i].lastAccessDate,
              unread: rooms[i].unread === 0 ? null : rooms[i].unread,
              chatImage: Object.keys(rooms[i]).includes("chatImage")
                ? rooms[i].chatImage
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            });
          }

          setChatRoomList(ary);
        }
      } catch (e) {
        alert("채팅 목록 조회 중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <div className="page page--main" data-page="conversations" ref={top}>
        {/* PAGE CONTENT */}
        <div className="page__content page_section">
          <div className="page__title-bar">
            <h2>메세지({totalRows})</h2>
          </div>
          {chatRoomList && (
            <ChatRoomList chatRoomList={chatRoomList} userStatus={"org"} />
          )}
        </div>
      </div>
      {/* PAGE END */}
      <div id="bottom-toolbar_org" className="bottom-toolbar_org">
        <Navigation folder={"org"} />
      </div>
    </Fragment>
  );
}

export default Chat;
