import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function SolePrivate(props) {
  const { userIdx } = useParams();

  const navigate = useNavigate();

  const chatDClickHandler = (url) => {
    navigate(url, {
      state: {
        orgId: window.sessionStorage.getItem("idx"), // 기관 id
        userId: userIdx, // 사용자 id
      },
    });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <div className="page page--main" data-page="profile">
      {/* PAGE CONTENT */}
      <div className="page__content">
        <SubHeader />
        <div className="user-profile mb-40 mt-40">
          <div className="user-profile__thumb">
            <img src="/assets/images/photos/img-p-sole.jpg" alt="" />
          </div>
          <div className="user-profile__name">ID</div>
          <div className="user-profile__number">전화번호 : 000-0000-0000</div>
          <div className="buttons buttons--centered">
            <div className="info-box">
              <span>활동시간</span> 12시간
            </div>
            <div className="info-box">
              <span>레벨</span> 레벨3
            </div>
            <span
              onClick={() => chatDClickHandler("/org/chat/chatd/o2s")}
              className="button button--orange button--ex-small ml-10"
            >
              메세지
            </span>
          </div>
        </div>
        <div className="user-profile__info2">이 계정은 비공개 계정입니다.</div>
        <div className="space-h-100" />
      </div>
    </div>
  );
}

export default SolePrivate;
