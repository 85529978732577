import React from 'react';

function ConformDelete(props) {
  const {conformClick} = props;

  return (
    <div className="popup popup--centered popup--shadow popup--conform_delete">
      <div className="popup__close"><a href="#" className="close-popup" data-popup="conform_delete"><img src="/assets/images/icons/blue/close.svg" alt="" /></a></div>
      <div className="popup__icon"><img src="/assets/images/icons/blue/alert.svg" alt="" /></div>
      <h2 className="popup__title">확인 !</h2>
      <p className="popup__text">정말 삭제하시겠습니까?</p>
      <div className="form__row mt-40">
        <input style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--gray button--50 close-popup" data-popup="conform_delete" id="submit" value="취소" />
        <input style={{cursor: 'pointer'}} type="submit" name="submit" className="form__submit button button--main button--50 close-popup" id="submit" data-popup="conform_delete" value="확인" onClick={conformClick} />
      </div>
    </div>
  );
}

export default ConformDelete;