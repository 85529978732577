import axios from "axios";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import UrlContext from "../context/Url.context";

function OrgTagItem(props) {
  const { org } = props;

  // console.log(" == 기관 == ");
  // console.log(org);

  const navigate = useNavigate();
  const urlContext = useContext(UrlContext);

  const orgClickHandler = () => {
    navigate(`/sole/otherprofile/org/${org.id}`);
  };

  // 신청 취소 클릭 핸들러
  const cancelRequestClickHandler = () => {
    if (
      window.confirm(`${org && org.orgTitle} 기관 신청을 취소하시겠습니까?`)
    ) {
      axios
        .delete(
          `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/${org.idx}`
        )
        .then((res) => {
          //console.log(res);
          navigate(0);
        });
    }
  };

  return (
    <div className="card card--style-thumb">
      <div
        className="card__thumb card__thumb--round-corners-full"
        onClick={orgClickHandler}
        style={{ cursor: "pointer" }}
      >
        <img src="/assets/images/photos/img-p-org.jpg" alt="" />
      </div>
      <h4 className="card__username text-aline-center">
        {org && org.orgTitle}
      </h4>
      <Link to="" className="caption__category tag_waiting">
        대기 중
      </Link>
      <div
        className="button button--small-full button--gray float-right ml-10 open-popup"
        data-popup="conform_cancle"
        onClick={cancelRequestClickHandler}
      >
        신청 취소
      </div>
    </div>
  );
}

export default OrgTagItem;
