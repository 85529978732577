import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";

import ActivityHistoryAllList from "../../../components/ActivityHistoryAllList";
import ListActivitySearchComponent from "../../../components/ListActivitySearchComponent";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import { convertDashToDot } from "../../../util/convert-date-function";
import CheckLoginInfo from "../../../services/login-validation";

function ListActivityHistoryAll(props) {
  const [searchInfo, setSearchInfo] = useState({
    startDate: "",
    endDate: "",
    target: "default",
  });

  const onChangeSearchInfo = (e) => {
    setSearchInfo({
      ...searchInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [activityList, setActivityList] = useState({
    totalRows: null,
    list: null,
  });

  const [delActivityList, setDelActivityList] = useState({
    totalRows: null,
    list: null,
  });

  const setPeriodString = () => {
    if (searchInfo.startDate === "" && searchInfo.endDate === "") {
      return "전체 기간 조회";
    }

    let period;

    if (searchInfo.startDate === "") {
      period = "";
    } else {
      period = `${convertDashToDot(searchInfo.startDate)}`;
    }

    if (searchInfo.endDate === "") {
      period += " ~ ";
    } else {
      period += ` ~ ${convertDashToDot(searchInfo.endDate)}`;
    }

    return period;
  };

  const getActHistory = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/activity/history/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
          type: searchInfo.target === "default" ? null : searchInfo.target,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            idx: data[i].idx,
            img: Object.keys(data[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${data[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            title: data[i].title,
            dateTime: Object.keys(data[i]).includes("activityDate")
              ? `${convertDashToDot(data[i].activityDate)} ${data[i].activityTime
              }`
              : null,
            beneType: data[i].beneType,
            recruitNumber: data[i].recruitNum,
            totalSchedulRows: data[i].totalScheduleRows,
          });
        }

        setActivityList({
          totalRows: totalRows,
          list: ary,
        });
      }
    } catch (e) {
      console.log(e);
      alert("기관 활동 내역 조회 중, 오류가 발생하였습니다.");
    }
  }, [searchInfo]);

  const getDelActHistory = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/activity/del/history/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
          type: searchInfo.target === "default" ? null : searchInfo.target,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            idx: data[i].idx,
            img: Object.keys(data[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${data[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`,
            title: data[i].title,
            dateTime: Object.keys(data[i]).includes("activityDate")
              ? `${convertDashToDot(data[i].activityDate)} ${data[i].activityTime
              }`
              : null,
            beneType: data[i].beneType,
            recruitNumber: data[i].recruitNum,
            totalSchedulRows: data[i].totalScheduleRows,
          });
        }

        setDelActivityList({
          totalRows: totalRows,
          list: ary,
        });
      }
    } catch (e) {
      console.log(e);
      alert("기관 삭제된 활동 내역 조회 중, 오류가 발생하였습니다.");
    }
  }, [searchInfo]);

  const setFilePeriod = () => {
    if (searchInfo.startDate !== "" && searchInfo.endDate !== "") {
      return `${searchInfo.startDate} ~ ${searchInfo.endDate}`;
    }

    let today = new Date();

    if (searchInfo.startDate !== "" && searchInfo.endDate === "") {
      return `${searchInfo.startDate} ~ ${today.toISOString().slice(0, 10)}`;
    }

    if (searchInfo.startDate === "" && searchInfo.endDate !== "") {
      return `~ ${searchInfo.endDate}`;
    }

    return "전체";
  };

  const getFile = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/api/v1/excel/activity/report-all/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
          type: searchInfo.target === "default" ? null : searchInfo.target,
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const file_url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );

        const link = document.createElement("a");
        link.href = file_url;
        link.setAttribute(
          "download",
          `기관 활동 내역 - ${{
            A: "전체",
            O: "기관/단체",
            U: "일반",
            default: "",
          }[searchInfo.target]
          }(${setFilePeriod()}).xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getActHistory();
        getDelActHistory();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getActHistory, getDelActHistory]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="기관 활동 내역" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <ListActivitySearchComponent
            searchInfo={searchInfo}
            onChangeSearchInfo={onChangeSearchInfo}
          />
          <div className="d-flex justify-space align-items-center pt-40">
            <h2>
              기간 :
              {setPeriodString()}
            </h2>
            <div>
              <input
                type="button"
                className="form__submit button button--main"
                id="submit"
                value="활동 내역 다운로드"
                onClick={() => getFile()}드
              />
            </div>
          </div>

          <div className="tabs tabs--style1 mb-20">
            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab1"
              defaultChecked="checked"
            />
            <label
              className="tabs__label tabs__left tabs__label--12"
              htmlFor="tab1"
            >
              진행중인 활동
            </label>
            <div className="tabs__content">
              <div className="form__row mb-40">
                <div className="page__title-bar mt-48">
                  <h2>활동 목록 ({activityList.totalRows})</h2>
                </div>
                <div className="cards cards--11">
                  {activityList.list &&
                    activityList.list.map((info) => (
                      <ActivityHistoryAllList info={info} key={info.idx} />
                    ))}
                </div>
              </div>
            </div>

            <input
              type="radio"
              name="tabs2"
              className="tabs__radio"
              id="tab2"
            />
            <label
              className="tabs__label tabs__right tabs__label--12"
              htmlFor="tab2"
            >
              삭제된 활동
            </label>
            <div className="tabs__content">
              <div className="form__row mb-40">
                <div className="page__title-bar mt-48">
                  <h2>활동 내역 ({delActivityList.totalRows})</h2>
                </div>
                <div className="cards cards--11">
                  {delActivityList.list &&
                    delActivityList.list.map((info) => (
                      <ActivityHistoryAllList info={info} key={info.idx} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-h-100" />
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default ListActivityHistoryAll;
