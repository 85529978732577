import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import PhoneconsultItem from "../../../components/PhoneconsultItem";
import CheckLoginInfo from "../../../services/login-validation";
import ListActivitySearchComponent from "../../../components/ListActivitySearchComponent";
import { convertDashToDot } from "../../../util/convert-date-function";
import axios from "axios";

function OkPhoneReportList() {
  const location = useLocation();
  const expertdetail = location.state && location.state.expertdetail;

  const [searchInfo, setSearchInfo] = useState({
    startDate: "",
    endDate: "",
    target: "default",
  });

  const onChangeSearchInfo = (e) => {
    setSearchInfo({
      ...searchInfo,
      [e.target.name]: e.target.value,
    });
  };

  const setPeriodString = () => {
    if (searchInfo.startDate === "" && searchInfo.endDate === "") {
      return "전체 기간 조회";
    }

    let period;

    if (searchInfo.startDate === "") {
      period = "";
    } else {
      period = `${convertDashToDot(searchInfo.startDate)}`;
    }

    if (searchInfo.endDate === "") {
      period += " ~ ";
    } else {
      period += ` ~ ${convertDashToDot(searchInfo.endDate)}`;
    }

    return period;
  };

  const [totalRows, setTotalRows] = useState(null);
  const [phoneList, setPhoneList] = useState(null);

  const getHistory = useCallback(async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/phone_consultation/history/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            idx: data[i].idx,
            content: data[i].title,
            userIdx: data[i].userIdx,
            nickname: data[i].nickname,
            phoneNum: data[i].phoneNum,
            imageUrl: Object.keys(data[i]).includes("imageUrl")
              ? data[i].imageUrl
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`,
            consultationStatus: data[i].consultationStatus,
            consultationDate: data[i].consultationDate,
            consultationTime: data[i].consultationTime,
            area: data[i].area,
            createdUid: data[i].createdUid,
            expertIdx: data[i].expertIdx,
          });
        }

        setPhoneList(data);
        setTotalRows(totalRows);
      }
    } catch (e) {
      console.log(e);
    }
  }, [searchInfo.endDate, searchInfo.startDate]);

  const setFilePeriod = () => {
    if (searchInfo.startDate !== "" && searchInfo.endDate !== "") {
      return `${searchInfo.startDate} ~ ${searchInfo.endDate}`;
    }

    let today = new Date();

    if (searchInfo.startDate !== "" && searchInfo.endDate === "") {
      return `${searchInfo.startDate} ~ ${today.toISOString().slice(0, 10)}`;
    }

    if (searchInfo.startDate === "" && searchInfo.endDate !== "") {
      return `~ ${searchInfo.endDate}`;
    }

    return "전체";
  };

  const getFile = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API
      }/api/v1/excel/phone_consultation/history/${window.sessionStorage.getItem(
        "idx"
      )}`;

    try {
      const response = await axios.get(url, {
        params: {
          searchStartDate:
            searchInfo.startDate === "" ? null : searchInfo.startDate,
          searchEndDate: searchInfo.endDate === "" ? null : searchInfo.endDate,
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const file_url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );

        const link = document.createElement("a");
        link.href = file_url;
        link.setAttribute(
          "download",
          `전화 상담 활동 내역(${setFilePeriod()}).xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getHistory();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getHistory]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="전화상담 활동 내역" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <ListActivitySearchComponent
            searchInfo={searchInfo}
            onChangeSearchInfo={onChangeSearchInfo}
            hideType={true}
          />

          <div className="d-flex justify-space align-items-center pt-40">
            <h2 className="lh-0">
              {setPeriodString()}({totalRows})
            </h2>
            <div>
              <input
                name="submit"
                className="form__submit button button--main"
                id="submit"
                value="전체 활동 내역 다운로드"
                onClick={() => getFile()}
              />
            </div>
          </div>
          <div className="page__title-bar mt-48">
            <h2>전화 상담 ({totalRows})</h2>
          </div>
          <div className="cards cards--11">
            {phoneList &&
              phoneList.map((item) => (
                <PhoneconsultItem
                  item={item}
                  expertdetail={expertdetail}
                  key={item.idx}
                />
              ))}
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default OkPhoneReportList;
