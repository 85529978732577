import axios from "axios";

export const checkActivityPageAccess = async (activityIdx) => {
  let currentUrl = window.location.href.replace(
    process.env.REACT_APP_FRONT_SERVICE_URL,
    ""
  );
  let apiUrl = `${process.env.REACT_APP_USER_SERVICE_API
    }/activity/${window.sessionStorage.getItem("idx")}/${activityIdx}`;

  try {
    const response = await axios.get(apiUrl);

    if (response.status === 200) {
      return new Promise((resolve, reject) => {
        // 0. 내가 등록한 활동은 내 활동 조회 페이지로 이동
        if (response.data === true) {
          if (currentUrl.includes("actdetail")) {
            resolve("TRUE");
          } else {
            resolve(`/org/profile/actdetail/${activityIdx}`);
          }
        } else {
          // 1. 로그인한 사용자가 기관일 경우
          if (sessionStorage.getItem("orgStatus") === "O") {
            if (currentUrl.includes("sole")) {
              resolve(currentUrl.replace("sole", "org"));
            } else {
              resolve("TRUE");
            }
          }
          // 2. 로그인한 사용자가 일반 사용자일 경우
          else if (sessionStorage.getItem("orgStatus") === "U") {
            if (currentUrl.includes("org")) {
              resolve(currentUrl.replace("org", "sole"));
            } else {
              resolve("TRUE");
            }
          }
          // 3. 기관 신청 대기/반려된 사용자일 경우
          else {
            alert("해당 페이지는 회원가입이 완료된 회원만 조회가 가능합니다.");
            resolve("ERROR");
          }
        }
      });
    }
  } catch (e) {
    console.log(e.response)

    alert(e.response.status === 404 ? "삭제된 활동입니다." : "내가 등록한 기관 여부를 조회하던 중, 오류가 발생하였습니다.");

    return new Promise((resolve, reject) => {
      resolve(-1);
    })
  }
};
