import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useLocation, useParams } from "react-router";
import ActivityItem from "../../../components/ActivityItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import axios from "axios";
import CheckLoginInfo from "../../../services/login-validation";

function Category(props) {
  const location = useLocation();
  const { categoryIdx } = useParams();

  const [activityData, setActivityData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);

  const [activityPage, setActivitiyPage] = useState(1);
  const [consumerPage, setConsumerPage] = useState(1);

  const offsetActivityPage = 6;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  const [hideContent, setHideContent] = useState(true);

  // 카테고리 활동 검색
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        // axios URL
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/category/${categoryIdx}`;
        axios.get(url).then((res) => {
          const json = res.data.data;
          //console.log(res.data.data);

          // 활동의 기관명 추가
          json.map((item) => {
            axios
              .get(
                `${process.env.REACT_APP_USER_SERVICE_API}/org/${item.orgIdx}`
              )
              .then((res) => {
                item.orgTitle = res.data.orgTitle;

                //console.log(json);

                setActivityData(
                  json &&
                    json.filter(
                      (json) =>
                        (json.partType === "A" || json.partType === "O") &&
                        json.isPrivate === "O"
                    )
                );
                setConsumerData(
                  json &&
                    json.filter(
                      (json) =>
                        (json.beneType === "A" || json.beneType === "O") &&
                        json.isPrivate === "O"
                    )
                );
              });
          });
        });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader
          title={
            location.state &&
            location.state.category &&
            location.state.category.category
          }
        />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header">
          <div className="search__form">
            <form>
              <input
                type="text"
                className="search__input"
                placeholder="검색..."
              />
              <input type="submit" className="search__submit" value="Send" />
            </form>
          </div>
          <div className="page__title-bar mt-48">
            <h2>활동자 모집 ({activityData.length})</h2>
          </div>
          <div className="cards cards--12" id="loadlist-01">
            {activityData &&
              activityData
                .map((activity) => (
                  <ActivityItem
                    key={activity.idx}
                    url={`/org/activity/detailpart/${activity.idx}`}
                    folder="org"
                    activity={activity}
                  />
                ))
                .slice(0, activityPage * offsetActivityPage)}
          </div>
          <div className="buttons buttons--centered mb-20">
            {activityData.length > activityPage * offsetActivityPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setActivitiyPage, activityPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="page__title-bar mt-48">
            <h2>도움이 필요하신가요? ({consumerData.length})</h2>
          </div>
          <div className="cards cards--12" id="loadlist-02">
            {consumerData &&
              consumerData
                .map((activity) => (
                  <ActivityItem
                    key={activity.idx}
                    url={`/org/activity/detailbene/${activity.idx}`}
                    folder="org"
                    activity={activity}
                  />
                ))
                .slice(0, consumerPage * offsetActivityPage)}
          </div>
          <div className="buttons buttons--centered mb-20">
            {consumerData.length > consumerPage * offsetActivityPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setConsumerPage, consumerPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default Category;
