import React, { useState } from "react";
import { Link } from "react-router-dom";

function PasswordSetting(props) {
  const { closeModal, changePwd, isNotSame } = props;

  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");

  const onChangePwd1 = (data) => {
    setPwd1(data);
  };

  const onChangePwd2 = (data) => {
    setPwd2(data);
  };

  return (
    <div className="page page--login" data-page="login">
      {/* HEADER */}
      <header className="header header--fixed">
        <div className="header__inner">
          <div className="header__icon">
            <Link to="" onClick={() => closeModal()}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icons/gradient-orange/arrow-back.svg`}
                alt=""
              />
            </Link>
          </div>
        </div>
      </header>
      <div className="login">
        <div className="login__content">
          <h2 className="login__title">비밀번호 재설정</h2>
          <p className="login__text">비밀번호를 재설정 하세요!</p>
          <div className="login-form">
            <form id="LoginForm" method="post" action="main.html">
              <div className="login-form__row">
                <label className="login-form__label">비밀번호</label>
                <input
                  type="password"
                  name="password"
                  value={pwd1}
                  onChange={(e) => onChangePwd1(e.target.value)}
                  className="login-form__input required"
                />
              </div>
              <div className="login-form__row">
                <label className="login-form__label">비밀번호 재입력</label>
                <input
                  type="password"
                  name="password"
                  value={pwd2}
                  onChange={(e) => onChangePwd2(e.target.value)}
                  className="login-form__input required"
                />
                {isNotSame === true ? (
                  <p className="font-red">비밀번호가 일치하지 않습니다.</p>
                ) : (
                  ""
                )}
              </div>

              <div className="login-form__row">
                <input
                  onClick={() => changePwd(pwd1, pwd2)}
                  className="login-form__submit button button--main button--full"
                  id="submit"
                  value="확인"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordSetting;
