import React from "react";
import { useNavigate } from "react-router-dom";

const NoticeListItem = ({ noticeInfo, no }) => {
  const navigate = useNavigate();

  return (
    <div
      className="table__row"
      onClick={() =>
        navigate(`/common/community/noticedetail/${noticeInfo.idx}`)
      }
      style={{ cursor: "pointer" }}
    >
      <div className="table__section table__section--nr">{no}</div>
      <div className="table__section table__section--grow">
        {noticeInfo.title}
      </div>
      <div className="table__section">{noticeInfo.createDate}</div>
    </div>
  );
};

export default NoticeListItem;
