import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApplicationPartList from "../../../components/ApplicationPartList";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";
import ConformPopup from "../../common/popup/ConformPopup";

function ListAplicationPart(props) {
  const { actIdx } = useParams();
  const navigate = useNavigate();

  // DB 데이터
  const [activityScheduleData, setActivityScheduleData] = useState([]);

  const [hideContent, setHideContent] = useState(true);

  // 활동 일정 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        axios
          .get(
            `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/recruit/mine/${actIdx}`
          )
          .then((res) => {
            setActivityScheduleData(res.data.data);
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // 참여 상태 변경 (신청(W)/신청완료(D)/반려(R)/참(Y)/불(N))
  // done, waiting, reject
  const UpdateVolunteer = (volunteer) => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/org/profile/listapplicationpart/${volunteer}/${selActListIdx.idx}`;
    axios
      .put(URL)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        alert("활동 참여 신청 목록 상태 변경 에러");
        console.log(err.response);
      });
  };

  const updateGroupVolunteer = (url) => {
    const updateUrl = `${process.env.REACT_APP_USER_SERVICE_API}${url}/${selActListIdx.idx}`;

    let data = new Object();
    data.updatedUid = window.sessionStorage.getItem("idx");
    data.groupIdx = selActListIdx.groupIdx;
    data.orgIdx = selActListIdx.orgIdx;

    try {
      axios.put(updateUrl, data).then(() => {
        navigate(0);
      });
    } catch (e) {
      console.log(e);
    }
  };

  // 선택된 활동자
  const [selActListIdx, setSelActListIdx] = useState({
    status: "",
    idx: "",
    groupIdx: "",
    orgIdx: "",
  });

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 참여 신청 목록" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            {activityScheduleData &&
              activityScheduleData.map((activitySchedule) => (
                <ApplicationPartList
                  activitySchedule={activitySchedule}
                  setSelActListIdx={setSelActListIdx}
                  key={activitySchedule.activityScheduleIdx}
                />
              ))}
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        text="승인하시겠습니까?"
        popupType="accept"
        conformClick={
          selActListIdx.status === "user"
            ? () => UpdateVolunteer("done")
            : () => updateGroupVolunteer("/act-list/group/volunteer/accept")
        }
      />
      <ConformPopup
        text="정말 반려하시겠습니까?"
        popupType="dismissal"
        conformClick={
          selActListIdx.status === "user"
            ? () => UpdateVolunteer("reject")
            : () => updateGroupVolunteer("/act-list/group/volunteer/reject")
        }
      />
      <ConformPopup
        text="정말 취소하시겠습니까?"
        popupType="cancle"
        conformClick={
          selActListIdx.status === "user"
            ? () => UpdateVolunteer("waiting")
            : () => updateGroupVolunteer("/act-list/group/volunteer/waiting")
        }
      />
    </Fragment>
  );
}

export default ListAplicationPart;
