import React, { Fragment, useEffect, useState } from "react";
import ActivityRequestList from "../../../components/ActivityRequestList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function ActivityHistory(props) {
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 이력" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar">
              <h2>활동 (12)</h2>
            </div>
            <div className="cards cards--11">
              <ActivityRequestList folder="sole" status="history" />
              <ActivityRequestList folder="sole" status="complete" />
              <ActivityRequestList folder="sole" status="absent" />
              <div className="buttons buttons--centered mb-20">
                <div className="button button--more">더 보기</div>
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default ActivityHistory;
