import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import GroupMemberItem from "../../../components/GroupMemberItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function PlacingMember(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const groupName = location.state && location.state.groupName;
  const activityIdx = location.state && location.state.activityIdx;
  const activityScheduleIdx =
    location.state && location.state.activityScheduleIdx;
  const actRequestIdx = location.state && location.state.actRequestIdx;
  const page = location.state && location.state.page;
  const isMyGroup = location.state && location.state.isMyGroup;
  const isFinished = location.state && location.state.isFinished;

  const [groupData, setGroupData] = useState([]);
  // 제외된 그룹 (Exception Group)
  const [exGroupData, setExGroupData] = useState([]);

  const useraddClickHandler = () => {
    navigate("/org/common/useradd", {
      state: {
        groupData: groupData,
        type: "placing",
        activityIdx: activityIdx,
        activityScheduleIdx: activityScheduleIdx,
      },
    });
  };

  const cancelClickHandler = () => {
    // 뒤로가기
    navigate(-1);
  };

  const conformClickHandler = () => {
    let exists = false;

    groupData.forEach((group, idx) => {
      if (group.status === "A") {
        exists = true;
      }
    });

    // 관리자가 1명이라도 없으면
    if (!exists) {
      alert(
        "일정에 참여하는 그룹은 관리자가 최소 1명이상 필요합니다. 관리자를 지정해주세요."
      );
      return;
    }

    const actListAry = [];

    groupData &&
      groupData.forEach((group) => {
        if (group.isSelected === true) {
          const actList = {
            activityIdx: activityIdx,
            userIdx: group.userIdx,
            activityScheduleIdx: activityScheduleIdx,
            orgIdx: sessionStorage.idx,
            groupIdx: group.groupIdx,
            volunteer:
              isMyGroup === true ? (isFinished === true ? "Y" : "D") : "W",
            time: "0",
            createdUid: sessionStorage.idx,
            updatedUid: sessionStorage.idx,
            groupName: groupName,
          };

          actListAry.push(actList);
        }
      });

    axios
      .all(
        isMyGroup === true
          ? actListAry.map((actList) => {
              return axios
                .post(
                  `${process.env.REACT_APP_USER_SERVICE_API}/act_list/my_activity`,
                  actList
                )
                .catch((error) => {
                  console.log(error.response);

                  if (error.response.status === 401) {
                    alert(error.response.data);
                  }
                });
            })
          : actListAry.map((actList) => {
              return axios
                .post(
                  `${process.env.REACT_APP_USER_SERVICE_API}/act_list`,
                  actList
                )
                .catch((error) => {
                  console.log(error.response);

                  if (error.response.status === 401) {
                    alert(error.response.data);
                  }
                });
            })
      )
      .then((res) => {
        // alert("활동 참여 신청 완료");

        if (actRequestIdx) {
          acceptPartReuqest();
        }

        if (page === "listactivity") {
          // navigate(
          // `/org/profile/listactivity/${activityIdx}/${activityScheduleIdx}`,
          // {
          //   state: {
          //     actIdx: activityIdx,
          //   },
          //   // }
          // );
          navigate(-1, {
            state: {
              actIdx: activityIdx,
            },
          });
        } else {
          navigate(`/org/activity/detailpart/${activityIdx}`);
        }
      });
    // .catch((error) => {
    //   console.log(error.response);

    //   if (error.response.status === 401) {
    //     alert(error.response.data);
    //   }
    // });
  };

  // 참여요청 목록 승인
  const acceptPartReuqest = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_request/volunteer/${actRequestIdx}`;

    const params = {
      volunteer: "D",
      updatedUid: sessionStorage.idx,
    };

    axios
      .put(URL, null, {
        params: params,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.reponse);
      });
  };

  // 활동자 배치 그룹 미리보기 추가
  const addGroup = (nickname) => {
    const addedGroup = exGroupData.filter(
      (group) => group.nickname === nickname
    );
    const remainGroup = exGroupData.filter(
      (group) => group.nickname !== nickname
    );

    const groupList = groupData.concat(addedGroup);
    // console.log(groupList);
    // console.log(remainGroup);
    setGroupData(groupList);
    setExGroupData(remainGroup);
  };

  // 활동자 배치 그룹 미리보기 제외
  const exceptGroup = (nickname) => {
    const exceptionGroup = groupData.filter(
      (group) => group.nickname === nickname
    );
    const remainGroup = groupData.filter(
      (group) => group.nickname !== nickname
    );

    const groupList = exGroupData.concat(exceptionGroup);
    // console.log(groupList);
    // console.log(remainGroup);
    setExGroupData(groupList);
    setGroupData(remainGroup);
  };

  const [selectedAll, setSelectedAll] = useState(false);

  const selectAllMember = () => {
    let ary = groupData;

    for (let i = 0; i < ary.length; i++) {
      ary[i].isSelected = !selectedAll;
    }

    setSelectedAll(!selectedAll);
    setGroupData(ary);
  };

  const selectedMember = (userIdx, selected) => {
    let ary = groupData;

    for (let i = 0; i < ary.length; i++) {
      if (ary[i].userIdx === userIdx) {
        ary[i].isSelected = selected;
      }
    }

    setGroupData(ary);
  };

  const [hideContent, setHideContent] = useState(true);

  // 해당 기관의 해당 그룹명의 회원 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        const params = {
          orgIdx: sessionStorage.idx,
          name: groupName,
        };

        const URL = `${process.env.REACT_APP_USER_SERVICE_API}/group_list/org`;
        axios
          .get(URL, {
            params: params,
          })
          .then((res) => {
            let ary = [];

            const data = res.data.data;

            for (let i = 0; i < data.length; i++) {
              ary.push({
                groupIdx: data[i].idx,
                userIdx: data[i].userIdx,
                nickname: data[i].nickname,
                status: data[i].orgMemberListStatus,
                idx: data[i].orgMemberListIdx,
                name: data[i].name,
                isSelected: false,
                img: Object.keys(data[i]).includes("img")
                  ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${data[i].img}`
                  : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`,
              });
            }

            setGroupData(ary);
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title={groupName} />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40">
              <h2 className="lh60">활동자 배치하기({groupData.length})</h2>
              {/* <div className="form__submit button button--main" onClick={useraddClickHandler} style={{cursor: 'pointer'}}>+ 회원 추가</div> */}
            </div>
            <div className="form__row mt-40">
              <div className="checkbox-option button--full button">
                <input
                  type="checkbox"
                  name="checkboxoption1"
                  // id="cp81"
                  checked={selectedAll}
                />
                <label htmlFor="cp81" onClick={() => selectAllMember()}>
                  &#10003; 전체 선택
                </label>
              </div>
            </div>
            <div className="cards cards--14">
              {groupData &&
                groupData.map((group, idx) => (
                  <GroupMemberItem
                    key={idx}
                    title="placingMember"
                    folder="org"
                    orgMember={group}
                    setGroupData={setGroupData}
                    groupFunc={exceptGroup}
                    type="placingMember"
                    selectedMember={selectedMember}
                  />
                ))}
            </div>
          </div>
          {/* <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40">
              <h2 className="lh60">제외된 회원({exGroupData.length})</h2>
            </div>
            <div className="cards cards--14">
              {exGroupData &&
                exGroupData.map((exGroup, idx) => (
                  <GroupMemberItem
                    key={idx}
                    title="추가"
                    folder="org"
                    type="placingMember"
                    orgMember={exGroup}
                    groupFunc={addGroup}
                  />
                ))}
            </div>
          </div> */}
          <div className="under_button">
            <div className="form__row mt-40">
              <input
                onClick={cancelClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                data-popup="conform_cancle"
                className="form__submit button button--gray button--50 open-popup"
                id="submit"
                value="취소"
              />
              <input
                onClick={conformClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--50"
                id="submit"
                value="확인"
              />
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default PlacingMember;
