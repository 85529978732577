import React, { Fragment, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CalendarLibrary from "../../../components/CalendarLibrary";
import SelectedTime from "../../../components/SelectedTime";
import ScheduleContext from "../../../context/Schedule.context";
import axios from "axios";
import ConformDelete from "../../common/popup/ConformDelete";
import ConformSave from "../../common/popup/ConformSave";
import CheckLoginInfo from "../../../services/login-validation";
import { onHandleImgError } from "../../../util/util-function";

function SelectSchedule() {
  const navigate = useNavigate();
  const { scheduleList, setScheduleList, selectedSchedule } =
    useContext(ScheduleContext);

  const [expert, setExpert] = useState("DELETE");

  const [okphone, setOkphone] = useState({
    area: "",
    title: "",
    content: "",
  });

  const handleChangeForm = (e) => {
    setOkphone({
      ...okphone,
      [e.target.name]: e.target.value,
    });
  };

  const expertTagView = () => {
    switch (okphone.area) {
      case "법률":
        return "법률";
      case "세무":
        return "세무";
      case "법무":
        return "법무";
      case "건축":
        return "건축";
      case "노무":
        return "노무";
      default:
        return "법률";
    }
  };

  const tagView = () => {
    switch (okphone.area) {
      case "법률":
        return "law";
      case "세무":
        return "tax";
      case "법무":
        return "legal";
      case "건축":
        return "cons";
      case "노무":
        return "labor";
      default:
        return "law";
    }
  };

  const expertView = () => {
    if (okphone.area !== "" && okphone.area !== undefined) {
      if (expert === "DELETE") {
        return null;
      } else if (expert === "NONE") {
        return (
          <div className="user-profile mb-20">
            <div className="user-profile__name point-red">
              죄송합니다. 현재 {okphone.area} 담당 전문가가 지정되지 않았습니다.
            </div>
          </div>
        );
      } else {
        return (
          <div className="user-profile mb-20">
            <div className="user-profile__thumb">
              <img
                src={imgPath}
                alt=""
                onError={(e) => onHandleImgError(e, "E")}
              />
            </div>
            <div className="item-center">
              <a className={`caption__category tag_expert_${tagView()}`}>
                {expertTagView()} 전문가
              </a>
            </div>
            <div className="user-profile__name">{expert.name}</div>
            <div className="user-profile__info">
              {expert.bio !== undefined && expert.bio !== null
                ? `소개글 : ${expert.bio}`
                : "등록된 소개글이 없습니다"}
            </div>
            <div className="buttons buttons--centered">
              <div className="info-box">
                <span>전화 상담</span> {expert.phone_consultation_num}건
              </div>
              <div className="info-box">
                <span>온라인 상담</span> {expert.online_consultation_num}건
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const conformClick = () => {
    if (expert === undefined) {
      alert(
        `${okphone.area} 담당 전문가가 지정되지 않아 전화 상담 문의가 불가능합니다.`
      );
      return;
    }

    if (scheduleList.length === 0) {
      alert("날짜를 선택해주세요");
      return;
    }

    if (okphone.area === "") {
      alert("상담분야를 선택해주세요.");
      return;
    }

    // if (okphone.title.trim() === "") {
    //   alert("제목을 입력해주세요.");
    //   return;
    // }

    if (okphone.content.trim() === "") {
      alert("상세내용을 입력해주세요.");
      return;
    }

    let url = `${process.env.REACT_APP_USER_SERVICE_API}/phone_consultation`;
    let data = {
      userIdx: sessionStorage.idx,
      expertIdx: Object.keys(expert).includes("idx") ? expert.idx : null,
      consultationDate: scheduleList[0].consultationDate,
      consultationTime: scheduleList[0].consultationTime,
      id: scheduleList[0].id,
      area: okphone.area,
      consultationStatus: "W",
      createdUid: sessionStorage.idx,
      // title: okphone.title,
      content: okphone.content,
    };

    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const data = res.data.head;

        if (data.resultCode === "00") {
          navigate(`/sole/okphone/detail`);
        } else if (data.resultCode === "06") {
          alert(data.resultMsg);
        } else {
          alert("전화상담 등록에 오류가 발생하였습니다.");
          console.log(data.resultMsg);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 선택된 시간 삭제
  const deleteClick = () => {
    setScheduleList(
      scheduleList.filter(
        (item) =>
          item.consultationDate !== selectedSchedule.consultationDate ||
          item.consultationTime !== selectedSchedule.consultationTime
      )
    );

    setExpert("DELETE");
  };

  const defaultImgPath = `${process.env.PUBLIC_URL}/assets/images/photos/img-p-expert.jpg`;
  const [imgPath, setImgPath] = useState(defaultImgPath);

  const getFileName = (expert) => {
    if (expert === undefined) return;
    const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
    axios
      .get(URL, {
        params: {
          pkIdx: expert && expert.idx,
          imageStatus: "U",
        },
      })
      .then((res) => {
        // 파일 조회시 업로드 이미지 Path Set
        if (res.data !== "") {
          setImgPath(
            `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
          );
        }
      });
  };

  const [selectedArea, setSeletedArea] = useState("법률");

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        if (scheduleList.length !== 0) {
          setOkphone({
            ...okphone,
            ["area"]: scheduleList[0].category,
          });
        }

        // 전문가 프로필 조회
        if (scheduleList.length !== 0) {
          if (scheduleList[0].expertIdx !== 0) {
            const url = `${process.env.REACT_APP_USER_SERVICE_API}/consultation/expert/${scheduleList[0].expertIdx}`;

            axios.get(url).then((res) => {
              setExpert(res.data);
              getFileName(res.data);
            });
          } else {
            setExpert("NONE");
          }
        } else {
          setExpert("DELETE");
        }

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [scheduleList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="전화상담 예약하기" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="fieldsets">
              <div className="form">
                <form className="pt-40" id="ContactForm">
                  <div className="form__row mt-20">
                    <h4>상담 분야 선택</h4>
                    <div className="form__row mb-20 ok_cate_form">
                      <a
                        className={`caption__category tag_expert_law ${
                          selectedArea === "법률" && "active"
                        }`}
                        onClick={() => setSeletedArea("법률")}
                        style={{ cursor: "pointer" }}
                      >
                        법률(월,수)
                      </a>
                      <a
                        className={`caption__category tag_expert_tax ${
                          selectedArea === "세무" && "active"
                        }`}
                        onClick={() => setSeletedArea("세무")}
                        style={{ cursor: "pointer" }}
                      >
                        세무(화)
                      </a>
                      <a
                        className={`caption__category tag_expert_cons ${
                          selectedArea === "건축" && "active"
                        }`}
                        onClick={() => setSeletedArea("건축")}
                        style={{ cursor: "pointer" }}
                      >
                        건축(수)
                      </a>
                      <a
                        className={`caption__category tag_expert_legal ${
                          selectedArea === "법무" && "active"
                        }`}
                        onClick={() => setSeletedArea("법무")}
                        style={{ cursor: "pointer" }}
                      >
                        법무(목)
                      </a>
                      <a
                        className={`caption__category tag_expert_labor ${
                          selectedArea === "노무" && "active"
                        }`}
                        onClick={() => setSeletedArea("노무")}
                        style={{ cursor: "pointer" }}
                      >
                        노무(금)
                      </a>
                    </div>
                    <h4>날짜 및 시간 선택</h4>
                    <div className=" form__row">
                      <div className="toggle mb-20">
                        <div className="toggle__item toggle--calendar">
                          <input
                            className="toggle__input"
                            id="togg1"
                            type="checkbox"
                            name="toggle"
                          />
                          <label className="toggle__label" htmlFor="togg1">
                            날짜 및 시간 선택
                          </label>
                          <div className="toggle__content">
                            <CalendarLibrary
                              folder="sole"
                              chk="schedulelist"
                              area={selectedArea}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h4>선택된 시간</h4>
                    <div className="cart cart--page">
                      <div
                        className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal"
                        id="item1"
                      >
                        {scheduleList && scheduleList.length !== 0 ? (
                          <SelectedTime
                            etc="no"
                            schedule={scheduleList && scheduleList}
                            deleteClick={deleteClick}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {expertView()}
                  <div className="form__row mt-20">
                    <h4>상담 내용</h4>
                    <textarea
                      name="content"
                      className="form__textarea required"
                      placeholder="사실관계 및 질문의 요지를 1,000자 이내로 입력해주세요."
                      value={okphone.content}
                      onChange={handleChangeForm}
                    />
                  </div>
                  <div className="form__row mt-40">
                    <input
                      type="button"
                      name="submit"
                      className="form__submit button button--main button--full open-popup"
                      data-popup="conform_save"
                      id="submit"
                      value="확인"
                    />
                  </div>
                  <div className="space-h-100" />
                </form>
              </div>
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformSave conformClick={conformClick} />
      <ConformDelete conformClick={deleteClick} />
      {/* <Navigationbar /> */}
    </Fragment>
  );
}

export default SelectSchedule;
