import {
  convertDateFormatDash,
  convertTimeFormat,
} from "../util/convert-date-function";
import styled from "styled-components";

const makeSchedule = (startDate, endDate, repeatDate, scheduleInfo) => {
  let date = startDate;
  let nextDate;
  let res = [];

  while (true) {
    res.push({
      id: convertDateFormatDash(date) + "/" + scheduleInfo.value,
      activityDate: convertDateFormatDash(date),
      activityTime: scheduleInfo.label,
      activityTimeValue: scheduleInfo.value,
      recruitNum: scheduleInfo.recruitNumber,
      value: scheduleInfo.value,
    });

    switch (repeatDate) {
      case "D":
        nextDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + 1
        );
        break;
      case "W":
        nextDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + 7
        );
        break;
      case "M":
        nextDate = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate()
        );
        break;
      default:
        break;
    }

    // 종료조건 : 현재 날짜가 같거나, 미래의 날짜가 종료 날짜보다 클 경우
    // 종료 조건을 만족했을 경우 : 탈출
    if (
      endDate < nextDate ||
      (endDate.getFullYear() === date.getFullYear() &&
        endDate.getMonth() === date.getMonth() &&
        endDate.getDate() === date.getDate())
    ) {
      break;
    }
    // 종료조건을 만족하지 못했을 경우
    // 반복 일정 날짜 만큼 +
    else {
      date = nextDate;
    }
  }

  return res;
};

const makeSchedule_new = (
  startDate,
  endDate,
  repeatDate,
  scheduleInfo,
  recruitNum
) => {
  let date = startDate;
  let nextDate;
  let res = [];

  while (true) {
    res.push({
      id: `${convertDateFormatDash(date)}/${scheduleInfo.startTime}`,
      activityDate: convertDateFormatDash(date),
      activityTime: convertTimeFormat(scheduleInfo.startTime), // 시작 시간
      activityTimeValue: scheduleInfo.startTime,
      activityTime_end: convertTimeFormat(scheduleInfo.endTime), // 종료 시간
      activityTimeValue_end: scheduleInfo.endTime,
      recruitNum: recruitNum,
      value: scheduleInfo.startTime,
    });

    switch (repeatDate) {
      case "D":
        nextDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + 1
        );
        break;
      case "W":
        nextDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + 7
        );
        break;
      case "M":
        nextDate = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate()
        );
        break;
      default:
        break;
    }

    // 종료조건 : 현재 날짜가 같거나, 미래의 날짜가 종료 날짜보다 클 경우
    // 종료 조건을 만족했을 경우 : 탈출
    if (
      endDate < nextDate ||
      (endDate.getFullYear() === date.getFullYear() &&
        endDate.getMonth() === date.getMonth() &&
        endDate.getDate() === date.getDate())
    ) {
      break;
    }
    // 종료조건을 만족하지 못했을 경우
    // 반복 일정 날짜 만큼 +
    else {
      date = nextDate;
    }
  }

  return res;
};

const CalendarContainer = styled.div`
  .react-calendar {
    width: 100%;
    border: none;
    margin-top: 30px;
  }
`;

export { CalendarContainer, makeSchedule_new };
export default makeSchedule;
