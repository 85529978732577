import { Fragment } from 'react';
import DaumPost from './DaumPost';
import "../assets/css/modal.css";

export const Modal = ( props ) => {
  // 열기, 닫기, 모달 헤더 텍스트를 부모로부터 받아옴
  //const { open, close, header, setAddress, setPostcode, setModalOpen, setFullAddress } = props;
  const { open, close, setAddress, setPostcode, setModalOpen, setFullAddress } = props;

  // 바깥 검은 바탕 클릭 시 모달 종료
  const handlClick = () => {
      close();
  }

  return (
  <Fragment>
      {/* // 모달이 열릴때 openModal 클래스가 생성된다. */}
      <div className={ open ? 'openModal modal' : 'modal' } onClick={handlClick}>
          { open ? ( 
              <DaumPost close={close} setAddress={setAddress} setPostcode={setPostcode} setModalOpen={setModalOpen} setFullAddress={setFullAddress}></DaumPost>
          ) : null }
      </div>
  </Fragment>
  )
}