import axios from "axios";
import React, { useState, useContext, Fragment, useEffect } from "react";
import { useNavigate } from "react-router";
import GroupMemberItem from "../../../components/GroupMemberItem";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import GroupContext from "../../../context/Group.context";
import CheckLoginInfo from "../../../services/login-validation";

function AddGroup(props) {
  const navigate = useNavigate();
  const groupContext = useContext(GroupContext);

  const [groupData, setGroupData] = useState(groupContext.groupList);
  const [groupName, setGroupName] = useState("");

  const userSearchonClickHandler = () => {
    navigate(`/org/profile/usersearch`, {
      state: {
        search: "orgMember",
      },
    });
  };

  // 취소 클릭 이벤트 (그룹 만들기 취소)
  const cancelClickHandler = () => {
    groupContext.clearGroup();
    navigate(`/org/profile/org`);
  };

  // 확인버튼 클릭 이벤트 (그룹 만들기)
  const conformClickHandler = () => {
    if (groupName.trim() === "") {
      alert("그룹명을 입력해주세요.");
      return;
    }

    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/group_list/add`;
    const DATA = {
      orgIdx: sessionStorage.idx,
      name: groupName,
    };
    axios
      .post(URL, DATA)
      .then((res) => {
        const MESSAGE = res.data;

        if (MESSAGE === "동일한 이름의 그룹이 있습니다.") {
          alert(MESSAGE);
          return;
        }

        let exists = false;
        if (groupContext.groupList.length !== 0) {
          groupContext.groupList.forEach((group, idx) => {
            console.log(group.status);
            if (group.status === "A") {
              exists = true;
            }
          });

          // 관리자가 1명이라도 없으면
          if (!exists) {
            alert(
              "그룹에는 관리자가 최소 1명이상 필요합니다. 관리자를 지정해주세요."
            );
            return;
          }

          groupContext.groupList.forEach((group, idx) => {
            const data = {
              orgIdx: sessionStorage.idx,
              userIdx: group.userIdx,
              name: groupName,
              createdUid: sessionStorage.idx,
            };

            // console.log("그룹만들기 Data");
            // console.log(data);

            const url = `${process.env.REACT_APP_USER_SERVICE_API}/group_list`;
            axios
              .post(url, data)
              .then((res) => {
                //console.log(res.data);
                groupContext.clearGroup();
                navigate(`/org/profile/org`);
              })
              .catch((error) => {
                console.log(error.response);
              });
          });
        } else {
          alert("그룹에 등록할 회원을 추가해주세요.");
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 그룹명 입력
  const groupNameChangeHandler = (e) => {
    setGroupName(e.target.value);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="chat">
        <SubHeader title="그룹 만들기" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header ">
          <div className="form">
            <form id="Form">
              <div className="form__row mt-100">

                <input
                  type="text"
                  name="groupName"
                  className="form__input required"
                  placeholder="그룹명"
                  value={groupName}
                  onChange={groupNameChangeHandler}
                />
              </div>
            </form>
          </div>
          <div className="d-flex justify-space align-items-center pt-40">
            <h2>그룹 회원({groupData.length})</h2>
            <div
              className="form__submit button button--main"
              onClick={userSearchonClickHandler}
              style={{ cursor: "pointer" }}
            >
              + 회원 추가
            </div>
          </div>
          <div className="cards cards--14">
            {groupData &&
              groupData.map((orgMember, idx) => (
                <GroupMemberItem
                  key={idx}
                  idx={idx}
                  title="제외"
                  orgMember={orgMember}
                  folder="org"
                  setGroupData={setGroupData}
                  type="addGroup"
                />
              ))}
          </div>
          <div className="form__row mt-40">
            <input
              onClick={cancelClickHandler}
              style={{ cursor: "pointer" }}
              type="button"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              onClick={conformClickHandler}
              style={{ cursor: "pointer" }}
              type="button"
              name="submit"
              className="form__submit button button--main button--50"
              id="submit"
              value="확인"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <Navigationbar />
    </Fragment>
  );
}

export default AddGroup;
