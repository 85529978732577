import axios from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ActGroupMember from "../../../components/ActGroupMember";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function GroupMember() {
  const location = useLocation();

  const groupName = location.state && location.state.groupName;
  const activityScheduleIdx =
    location.state && location.state.activityScheduleIdx;
  const groupOrgIdx = location.state && location.state.groupOrgIdx;

  // alert(`${groupName} / ${activityScheduleIdx}`)

  const [actGroupMemberData, setActGroupMemberData] = useState([]);

  // 해당 활동 일정의 그룹 회원 조회
  const getActGroupMember = useCallback(async (type) => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/group_list/${groupOrgIdx}`;

    const params = {
      groupName: groupName,
      activityScheduleIdx: activityScheduleIdx,
    };

    try {
      const response = await axios.get(URL, { params: params });

      if (response.status === 200) {
        const { data, totalRows } = response.data;

        let ary = [];

        data.forEach((data) => {
          ary.push({
            groupIdx: data.groupIdx,
            orgMemberIdx: data.orgMemberIdx,
            userIdx: data.userIdx,
            nickname: data.nickname,
            groupAdminStatus: data.groupAdminStatus,
          });
        });
        setActGroupMemberData(ary);
      }
    } catch (err) {
      console.log(err.response);
    }
  });

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getActGroupMember();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title={groupName} />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center mb-40">
              <h2 className="lh60">그룹 회원({actGroupMemberData.length})</h2>
            </div>
            <div className="cards cards--14">
              {actGroupMemberData &&
                actGroupMemberData.map((actGroupMember, idx) => (
                  <ActGroupMember
                    key={idx}
                    actGroupMember={actGroupMember}
                    folder="org"
                  />
                ))}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default GroupMember;
