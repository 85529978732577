import React, { Fragment, useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import FormatDate from "../../../components/FormatDate";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import Tag from "../../../components/Tag";
import ConformCompletion from "../../common/popup/ConformCompletion";
import axios from "axios";
import CheckLoginInfo from "../../../services/login-validation";

function OkOnlineAnswer(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { idx } = useParams();
  const expertdetail = location.state && location.state.expertdetail;
  const onlineconuser = location.state && location.state.onlineconuser;
  const item = location.state && location.state.item;
  const reply = location.state && location.state.reply;

  const tag = () => {
    return <Tag tagName={expertdetail && expertdetail.area} />;
  };

  const [value, setValue] = useState({
    answer: reply && reply.answer,
  });
  const ChangeForm = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const CancleClick = () => {
    navigate(`/sole/profileexpert/expert/${sessionStorage.idx}`);
  };

  const conformClick = () => {
    let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/answer/${idx}`;
    let data = {
      answer: value.answer,
      consultationStatus: "D",
      idx: idx,
    };
    var config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .put(url, data, config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    navigate(`/sole/profileexpert/expert/${sessionStorage.idx}`);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="답변하기" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="d-flex justify-space align-items-center pt-40">
            <h2 className="page__title mb-0">상담 내용</h2>
            <p className="card__info">
              <FormatDate datetime={item && item.createdAt} />
            </p>
          </div>
          <div className="fieldsets">
            <ul className="custom-listing mb-20">
              <li className="mt-20">
                <h2>{item && item.title}</h2>
              </li>
              <li>
                <span>이용자 : </span>
                {onlineconuser && onlineconuser.nickname}(
                {onlineconuser && onlineconuser.name})
              </li>
              <li>
                <span>상담 분야 : </span>
                {tag()}
              </li>
              <li>
                <span>
                  상세 : <br />
                </span>
                {item && item.content}
              </li>
            </ul>
          </div>
          <div className="d-flex justify-space align-items-center pt-40">
            <h2 className="page__title mb-0">답변</h2>
            {reply && reply.answeredAt ? (
              <p className="card__info">
                <FormatDate datetime={reply.answeredAt} />
              </p>
            ) : null}
          </div>
          <div className="fieldsets">
            <div className="form__row mt-20">
              <span>답변하기 : </span>
              <textarea
                name="answer"
                className="form__textarea required"
                placeholder="내용을 기입해주세요."
                value={value.answer}
                onChange={ChangeForm}
              />
            </div>
          </div>
          <div className="form__row mt-40">
            <input
              onClick={CancleClick}
              style={{ cursor: "pointer" }}
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_completion"
              id="submit"
              value="완료하기"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformCompletion conformClick={conformClick} />
      <Navigationbar />
    </Fragment>
  );
}

export default OkOnlineAnswer;
