import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { convertDateString } from "../util/convert-date-function";

function BeneRequestList(props) {
  const { scheduleInfo, updateSelectedScheduleInfo } = props;

  return (
    <div className="table card card--style-thumb mb-20">
      <div className="card__details">
        <h2 className="fc_point1">{`${convertDateString(
          scheduleInfo.activityDate
        )} ${scheduleInfo.activityTime}`}</h2>
        <p className="card__list">
          활동인원 :{" "}
          {scheduleInfo.status !== undefined || scheduleInfo.status === null
            ? {
                W: scheduleInfo.recruitNum,
                N: scheduleInfo.recruitNum,
                D: `${scheduleInfo.joinNum}/${scheduleInfo.recruitNum}`,
              }[scheduleInfo.status]
            : 0}
        </p>
        <p className="card__list">
          수요자 :
          {
            {
              A: (
                <>
                  개인{""}
                  {scheduleInfo && scheduleInfo.beneOrgTitle !== null ? (
                    <Link to={`/org/otherprofile/org/${scheduleInfo.orgIdx}`}>
                      {scheduleInfo &&
                        scheduleInfo.beneOrgTitle &&
                        `, ${scheduleInfo.beneOrgTitle}`}
                    </Link>
                  ) : (
                    ", 단체/기관"
                  )}
                </>
              ),
              O: (
                <>
                  {scheduleInfo && scheduleInfo.beneOrgTitle !== null ? (
                    <Link to={`/org/otherprofile/org/${scheduleInfo.orgIdx}`}>
                      {scheduleInfo &&
                        scheduleInfo.beneOrgTitle &&
                        ` ${scheduleInfo.beneOrgTitle}`}
                    </Link>
                  ) : (
                    "단체/기관"
                  )}
                </>
              ),
              U: "개인",
              E: " 모집 마감",
            }[scheduleInfo.beneType]
          }
        </p>
        {
          {
            W: (
              <Fragment>
                <div
                  className="button button--small button--main float-right ml-10 open-popup"
                  data-popup="conform_accept"
                  onClick={() =>
                    updateSelectedScheduleInfo(
                      scheduleInfo.helpIdx,
                      "accept",
                      scheduleInfo.scheduleIdx,
                      scheduleInfo.joinNum > 0
                    )
                  }
                >
                  승인
                </div>
                <div
                  className="button button--small button--secondary float-right ml-10 open-popup"
                  data-popup="conform_dismissal"
                  onClick={() =>
                    updateSelectedScheduleInfo(
                      scheduleInfo.helpIdx,
                      "reject",
                      scheduleInfo.scheduleIdx,
                      scheduleInfo.joinNum > 0
                    )
                  }
                >
                  반려
                </div>
              </Fragment>
            ),
            D: (
              <Fragment>
                <div
                  className="button button--small button--secondary float-right open-popup"
                  data-popup="conform_cancleA"
                  id="submit"
                  onClick={() =>
                    updateSelectedScheduleInfo(
                      scheduleInfo.helpIdx,
                      "reject",
                      scheduleInfo.scheduleIdx,
                      scheduleInfo.joinNum > 0
                    )
                  }
                >
                  승인 취소
                </div>
              </Fragment>
            ),
            N: (
              <Fragment>
                <div
                  className="button button--small button--secondary float-right open-popup"
                  data-popup="conform_cancleR"
                  id="submit"
                  onClick={() =>
                    updateSelectedScheduleInfo(
                      scheduleInfo.helpIdx,
                      "cancel-reject",
                      scheduleInfo.joinNum > 0
                    )
                  }
                >
                  반려 취소
                </div>
              </Fragment>
            ),
          }[scheduleInfo.status]
        }
      </div>
    </div>
  );
}

export default BeneRequestList;
