import React, { Fragment, useEffect, useState, useContext } from "react";
import ScheduleContext from "../context/Schedule.context";

function TimeItem2({ item, index, checkedItemHandler, setSchedule, chkClick, nowdate, reset, lastSelTimee }) {

	const [cnt, setCnt] = useState(0);
	const [bChecked, setChecked] = useState(false);
	const { scheduleList, addSchedule, clearSchedule } = useContext(ScheduleContext);

	const minusClick = ({ target }) => {
		if (cnt !== 0) {
			setCnt(cnt - 1)
			setChecked(true);
			checkedItemHandler(item, true, cnt - 1);
		}
		if (cnt === 1) {
			setCnt(cnt - 1)
			setChecked(false);
			checkedItemHandler(item, false, cnt - 1);
		}
	}

	const plusClick = ({ target }) => {
		setCnt(cnt + 1)
		setChecked(true);
		checkedItemHandler(item, true, cnt + 1);
	}


	useEffect(() => {
		if (reset) {
			if (lastSelTimee === item) return;
			setChecked(false);
			setCnt(0);
		}
	}, [reset])

	const changeHandler = () => {

	}

	return (
		<Fragment>
			<div className="cart cart--page">
				<div className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal" id={index}>
					<div className="checkbox-option checkbox-option--full">
						<input type="checkbox" name="checkboxoption1" checked={bChecked} readOnly />
						<label htmlFor={`cp${index}`}>
							<span>{item}</span>
							<div className="float-right mt-m-15">
								필요인원 :
								<input type="button" value="-" className="quantity__button quantity__button--minus" field={`quantity${index}`}
									onClick={minusClick} />
								<input type="text" value={cnt} className="quantity__input" onChange={changeHandler} />
								<input type="button" value="+" className="quantity__button quantity__button--plus" field={`quantity${index}`}
									onClick={plusClick} />
							</div>
						</label>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default TimeItem2;