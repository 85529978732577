import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function Setting(props) {
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.clear();
    navigate(`/`);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="설정" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          <ul className="list-menu">
            <li className="list-menu__row">
              <Link className="list-menu__row" to="/org/profileedit/alert">
                <div className="list-menu__details">
                  <div className="list-menu__t">알림 설정
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                    />
                  </div>
                </div>
              </Link>
            </li>
            <li className="list-menu__row">
              <Link className="list-menu__row" to="/org/profileedit/eventmatching">
                <div className="list-menu__details">
                  <div className="list-menu__t">
                    활동 매칭 설정
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                      title=""
                    />
                  </div>
                </div>
              </Link>
            </li>
            <li className="list-menu__row">
              <Link className="list-menu__row" to="/common/community/notice">
                <div className="list-menu__details">
                  <div className="list-menu__t">공지사항
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                      title=""
                    />
                  </div>
                </div>
              </Link>
            </li>
            {/* <li className="list-menu__row">
              <div className="list-menu__details">
                <div className="list-menu__t"><Link to="/common/community/termsofuse">이용약관</Link></div>
              </div>
              <div className="list-menu__info default__icon">
                <div className="list-menu__icon"><Link to="/common/community/termsofuse" /><img src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`} alt="" /></div>
              </div>
            </li> */}
            <li className="list-menu__row">
              <Link className="list-menu__row" to="/common/community/faq">
                <div className="list-menu__details">
                  <div className="list-menu__t">자주 묻는 질문(FAQ)
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                    />
                  </div>
                </div>
              </Link>
            </li>
            <li className="list-menu__row">
              <Link className="list-menu__row" to="/common/community/help">
                <div className="list-menu__details">
                  <div className="list-menu__t">사용가이드
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                      title=""
                    />
                  </div>
                </div>
              </Link>
            </li>
            <li className="list-menu__row">
              <Link className="list-menu__row" to="/common/community/privacypolicy">
                <div className="list-menu__details">
                  <div className="list-menu__t">
                    개인정보처리방침
                  </div>
                </div>
                <div className="list-menu__info default__icon">
                  <div className="list-menu__icon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                      title=""
                    />
                  </div>
                </div>
              </Link>
            </li>
            <h2 className="mt-40">계정</h2>
            <li className="list-menu__row">
              <div className="list-menu__details">
                <div className="list-menu__t">
                  <a onClick={logout}>로그아웃</a>
                </div>
              </div>
              <div className="list-menu__info default__icon">
                <div className="list-menu__icon">
                  <Link to="/common/login">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </li>



            <li className="list-menu__row">
              <div className="list-menu__details">
                <div className="list-menu__t">
                  <Link to="/common/community/withdrawal">
                    회원탈퇴
                  </Link>
                </div>
              </div>
              <div className="list-menu__info default__icon">
                <div className="list-menu__icon">
                  <Link to="/common/community/withdrawal">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icons/black/arrow-right.svg`}
                      alt=""
                      title=""
                    />
                  </Link>
                </div>
              </div>
            </li>


          </ul>
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default Setting;
