import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import ActivityItem from "../../../components/ActivityItem";
import ActivitySwiperItem from "../../../components/ActivitySwiperItem";
import CategoryItem from "../../../components/CategoryItem";
import Navigation from "../../../components/Navigation";
import Navigationbar from "../../../components/Navigationbar";
import OkItem from "../../../components/OkItem";
import OrgItem from "../../../components/OrgItem";
import Panel from "../../../components/Panel";
import SoleHeader from "../../../components/SoleHeader";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CheckLoginInfo from "../../../services/login-validation";

function SoleBene(props) {
  const navigate = useNavigate();

  // DB 데이터
  const [categoryData, setCategoryData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);
  const [closedActivityData, setClosedActivityData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  // 더보기 처리
  const [activityPage, setActivitiyPage] = useState(1);
  const [consumerPage, setConsumerPage] = useState(1);
  const [orgPage, setOrgPage] = useState(1);

  const offsetActivityPage = 6;
  const offsetOrgPage = 8;

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 검색
  const [searchKeyword, setSearchKeyword] = useState("");

  const searchTextChangeHandler = (e) => {
    setSearchKeyword(e.target.value);
  };

  const enterKeyupHandler = (e) => {
    const enterKeyCode = 13;

    if (window.event.keyCode === enterKeyCode) {
      searchBeneClickHander();
    }
  };

  const [hideContent, setHideContent] = useState(true);

  // 카테고리 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        // axios URL
        let url1 = `${process.env.REACT_APP_USER_SERVICE_API}/category`;
        axios.get(url1).then((res) => {
          setCategoryData(res.data.data);
        });

        // axios URL
        // 기관 조회
        let url2 = `${process.env.REACT_APP_USER_SERVICE_API}/org`;
        axios.get(url2).then((res) => {
          setOrgData(res.data.data);
        });

        // axios URL
        // 활동 조회
        let url3 = `${process.env.REACT_APP_USER_SERVICE_API}/activity`;
        axios.get(url3).then((res) => {
          const json = res.data.data;

          // 활동의 기관명 추가
          json.map((item) => {
            axios
              .get(
                `${process.env.REACT_APP_USER_SERVICE_API}/org/${item.orgIdx}`
              )
              .then((res) => {
                item.orgTitle = res.data.orgTitle;

                setActivityData(
                  json &&
                  json.filter(
                    (json) =>
                      (json.partType === "A" || json.partType === "U") &&
                      json.isPrivate === "O"
                  )
                );
                setConsumerData(
                  json &&
                  json.filter(
                    (json) =>
                      (json.beneType === "A" || json.beneType === "U") &&
                      json.isPrivate === "O"
                  )
                );
                setClosedActivityData(
                  json &&
                  json.filter(
                    (json) =>
                      json.isPrivate === "O" &&
                      (json.beneType === "E" ||
                        (json.partType === "X" && json.beneType === "X"))
                  )
                );
              });
          });
        });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // searchPart 페이지 이동 버튼 클릭 Handler
  const searchBeneClickHander = () => {
    navigate(`/sole/home/searchBene/${searchKeyword}`);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/vendor/jquery/jquery-3.5.1.min.js";
    script.async = true;
    const script2 = document.createElement("script");
    script2.src = "/vendor/jquery/jquery.validate.min.js";
    script2.async = true;
    const script3 = document.createElement("script");
    script3.src = "/vendor/swiper/swiper.min.js";
    script3.async = true;
    const script4 = document.createElement("script");
    script4.src = "/assets/js/swiper-init.js";
    script4.async = true;
    const script5 = document.createElement("script");
    script5.src = "/assets/js/jquery.custom.js";
    script5.async = true;
    document.body.appendChild(script);
    document.body.appendChild(script2);
    document.body.appendChild(script3);
    document.body.appendChild(script4);
    document.body.appendChild(script5);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
      document.body.removeChild(script4);
      document.body.removeChild(script5);
    };
  });

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SoleHeader type="bene" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header page__content page_section--with-bottom-nav">
          <h1 className="featured__title mt-20 mt-100">어떤 분야의</h1>
          <h1 className="featured__title pb-10">활동이 필요하신가요?</h1>
          <br />
          <div className="button_tab">
            <Link to="/sole/home/solepart">활동자 페이지로 이동</Link>
          </div>
          <div className="search__form">
            <form>
              <input hidden="hidden" />
              <input
                type="text"
                className="search__input"
                value={searchKeyword}
                onChange={searchTextChangeHandler}
                onKeyUp={enterKeyupHandler}
                placeholder="검색..."
              />
              <input
                type="button"
                onClick={searchBeneClickHander}
                className="search__submit"
                value="Send"
              />
            </form>
          </div>
          <div className="page__title-bar">
            <h2>카테고리</h2>
            <div className="page__title-right">
              <div className="swiper-button-prev slider-thumbs__prev" />
              <div className="swiper-button-next slider-thumbs__next" />
            </div>
          </div>
          {/* SLIDER THUMBS 3h */}
          <div
            className="swiper-container slider-thumbs slider-init mb-20"
            data-paginationtype="progressbar"
            data-spacebetweenitems={10}
            data-itemsperview="auto"
          >
            <div className="swiper-wrapper">
              {categoryData &&
                categoryData.map((category) => (
                  <CategoryItem
                    key={category.idx}
                    category={category}
                    url="/sole/home/categorybene"
                  />
                ))}
            </div>
            <div className="swiper-pagination slider-thumbs__pagination" />
          </div>
          <div className="page__title-bar2 mt-48">
            <h2 className="pb-0">서초OK생활자문단</h2>
            <p className="text-align-left">법률/세무/법무/건축/노무 무료상담</p>
          </div>
          <div className="cards cards--12  mb-20" id="loadlist-01">
            <OkItem type="okonline" />
            <OkItem type="okphone" />
          </div>
          {/* <div className="page__title-bar mt-48">
            <h2>도움이 필요하신가요? ({consumerData.length})</h2>
          </div>
          <div className="cards cards--12" id="loadlist-01">
            {consumerData &&
              consumerData
                .map((activity) => (
                  <ActivityItem
                    key={activity.idx}
                    url={`/sole/activity/detailbene/${activity.idx}`}
                    folder="sole"
                    activity={activity}
                  />
                ))
                .slice(0, consumerPage * offsetActivityPage)}
          </div>
          <div className="buttons buttons--centered mb-20">
            {consumerData.length > consumerPage * offsetActivityPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setConsumerPage, consumerPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div> */}
          <div className="page__title-bar mt-48">
            <h2>진행한 활동({closedActivityData.length})</h2>
            <div className="page__title-right">
              <div className="swiper-button-prev slider-thumbs__prev" />
              <div className="swiper-button-next slider-thumbs__next" />
            </div>
          </div>
          {/* SLIDER THUMBS */}
          <div
            className="swiper-container slider-thumbs slider-init mb-20"
            data-paginationtype="progressbar"
            data-spacebetweenitems={10}
            data-itemsperview="auto"
          >
            <div className="swiper-wrapper">
              {closedActivityData &&
                closedActivityData
                  .map((activity) => (
                    <ActivitySwiperItem
                      key={activity.idx}
                      activity={activity}
                    />
                  ))
                  .slice(0, activityPage * offsetActivityPage)}
            </div>
            <div className="swiper-pagination slider-thumbs__pagination" />
          </div>
          <div className="page__title-bar mt-48">
            <h2>기관/단체({orgData.length})</h2>
          </div>
          <div className="cards cards--14">
            {orgData &&
              orgData
                .map((org) => <OrgItem key={org.id} folder="sole" org={org} />)
                .slice(0, orgPage * offsetOrgPage)}
          </div>
          <div className="buttons buttons--centered mb-20">
            {orgData.length > orgPage * offsetOrgPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setOrgPage, orgPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}

      <div id="bottom-toolbar_sole" className="bottom-toolbar_org">
        <Navigation folder={"sole"} />
      </div>

      <Navigationbar />
    </Fragment>
  );
}

export default SoleBene;
