import React from "react";
import FaqListItem from "./FaqListItem";

const FaqList = ({ faqList }) => {
  return (
    <div className="cards cards--11">
      {faqList.map((faqInfo) => (
        <FaqListItem faqInfo={faqInfo} key={faqInfo.idx} />
      ))}
    </div>
  );
};

export default FaqList;
