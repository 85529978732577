import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_CHAT_SERVICE_API}/api`,
});

const chatAPI = {
  getMessage: (groupId) => {
    return api.get(`/messages/${groupId}`);
  },

  sendMessage: (sender_idx, username, text, topic, sender_type, files = null) => {
    let msg = {
      sender_idx: sender_idx,
      sender: username,
      content: text,
      topic: topic,
      sender_type: sender_type,
    };

    const formData = new FormData();

    formData.append("message", new Blob([JSON.stringify(msg)], {type : "application/json"}));
    formData.append("files", files ?? null);

    return api.post(`/send`, formData, {headers : {"Content-type" : "multipart/form-data"}});
  },

  setSenderType: (type, title, orgTitle) => {
    let senderType;

    let typeAry = type.split("2");

    let status = window.sessionStorage.getItem("orgStatus");

    // 개인
    switch (status) {
      case "U":
        // 개인 -> s
        if (typeAry[0] === "s" || typeAry[1] === "s") {
          senderType = "U";
        }
        // 그룹 -> 개인
        else if (typeAry[0] === "g" || typeAry[1] === "g") {
          senderType = "G";
        }
        // 전체 -> 개인
        else if (typeAry[1] === "all") {
          senderType = "U";
        }

        break;
      case "O":
        // 기관 -> o
        if (typeAry[0] === "o" || typeAry[1] === "o") {
          if (title.includes(orgTitle)) {
            senderType = "O";
          } else {
            senderType = "A";
          }
        }
        // 활동 -> a
        else if (typeAry[0] === "a" || typeAry[1] === "a") {
          senderType = "A";
        }

        break;
      default:
        senderType = "U";
        break;
    }

    return senderType;
  },
};

export default chatAPI;
