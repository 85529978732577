import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { onHandleImgError } from "../util/util-function";

const MemberListItem = ({ member, selectedMember }) => {
  const [isSelected, setIsSelected] = useState(null);

  const onChangeSelected = () => {
    setIsSelected(!isSelected);
    selectedMember(member.idx, !isSelected);
  };

  useEffect(() => {
    setIsSelected(member.isSelected);
  }, [member.isSelected]);

  return (
    <div className="card card--style-thumb">
      <div className="card__thumb card__thumb--round-corners-full">
        <Link to={`/org/otherprofile/sole/${member.idx}`}>
          <img
            src={member.img}
            onError={(e) => onHandleImgError(e, "U")}
            alt=""
          />
        </Link>
      </div>
      <Link to={`/org/otherprofile/sole/${member.idx}`}>
        <h4 className="card__username text-aline-center">{member.nickname}</h4>
      </Link>
      <h4 className="card__title">{member.name}</h4>
      <div className="form__row mb-20">
        <div className="button button--small-full checkbox-option">
          {" "}
          <input
            type="checkbox"
            name="checkboxoption1"
            // id="cp001"
            value={member.idx}
            checked={isSelected}
          />
          <label htmlFor="cp001" onClick={() => onChangeSelected()}>
            &#10003; 선택
          </label>
        </div>
      </div>
    </div>
  );
};

export default MemberListItem;
