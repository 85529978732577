import axios from "axios";
import React, { Fragment, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function QnaDetail() {
  const { qnaIdx } = useParams();

  const [qnaInfo, setQnaInfo] = useState(null);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getQnaInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getQnaInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/qna/${qnaIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setQnaInfo({
            title: response.data.title,
            nickName: response.data.nickname,
            userIdx: response.data.userIdx,
            content: response.data.content,
            createDate: response.data.createdAtStr,
            viewCount: response.data.clickCnt,
            answeredDate: Object.keys(response.data).includes("answeredAtStr")
              ? response.data.answeredAtStr
              : "-", // 답변일
            answer: Object.keys(response.data).includes("answer")
              ? response.data.answer
              : "-",
          });
        }
      } catch (e) {
        alert("문의하기 상세조회 중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
  }, [qnaIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="문의하기" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          {qnaInfo && (
            <div className="page_section">
              <div className="d-flex justify-space align-items-center"></div>
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li className="">
                    <h2>{qnaInfo.title}</h2>
                  </li>
                  <li>
                    <span>작성자 : </span>
                    <Link to={`/sole/otherprofile/sole/${qnaInfo.userIdx}`}>
                      {qnaInfo.nickName}
                    </Link>
                  </li>
                  <li>
                    <span>글번호 : </span>
                    {qnaIdx}
                  </li>
                  <li>
                    <span>등록일 : </span>
                    {qnaInfo.createDate}
                  </li>
                  <li>
                    <span>조회수 : </span>
                    {qnaInfo.viewCount}
                  </li>
                  <li>
                    <span>상세 : </span>
                  </li>
                  <li>{qnaInfo.content}</li>
                </ul>
              </div>
              <div className="fieldsets">
                <ul className="custom-listing mb-20">
                  <li>
                    <span>답변일 : </span>
                    {qnaInfo.answeredDate}
                  </li>
                  <li>
                    <span>답변 : </span>
                  </li>
                  <li>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: qnaInfo.answer,
                      }}
                    ></div>
                  </li>
                </ul>
              </div>
              <div className="space-h-100" />
            </div>
          )}
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default QnaDetail;
