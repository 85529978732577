import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CategoryItem from "../../../components/CategoryItem";
import ActivityItem from "../../../components/ActivityItem";
import MainHeader from "../../../components/MainHeader";
import OrgItem from "../../../components/OrgItem";
import Panel from "../../../components/Panel";
import axios from "axios";
import Navigation from "../../../components/Navigation";
import CheckLoginInfo from "../../../services/login-validation";
import Banner from "../../../components/Banner";

import {convertDateFormatDash} from "../../../util/convert-date-function";

const viewTagList = ["activityType"];

function Org(props) {
    const navigate = useNavigate();

    // DB 데이터
    const [categoryData, setCategoryData] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [consumerData, setConsumerData] = useState([]);
    const [closedActivityData, setClosedActivityData] = useState([]);
    const [orgData, setOrgData] = useState([]);

    // 더보기 페이징
    const [activityPage, setActivitiyPage] = useState(1);
    const [consumerPage, setConsumerPage] = useState(1);
    const [closedPage, setClosedPage] = useState(1);
    const [orgPage, setOrgPage] = useState(1);

    const [searchKeyword, setSearchKeyword] = useState("");

    const offsetActivityPage = 6;

    const offsetOrgPage = 8;

    const addPage = (setPageFunc, currentPage) => {
        setPageFunc(currentPage + 1);
    };

    const [bannerList, setBannerList] = useState();
    const closeBanner = (selectedIdx) => {
        let ary = bannerList;

        for (let i = 0; i < ary.length; i++) {
            if (ary[i].idx === selectedIdx) {
                ary[i].isClosed = true;

                break;
            }
        }

        setBannerList(ary);
    };

    const getPopupBanner = useCallback(() => {
        let url_banner = `${process.env.REACT_APP_SERVICE_API}/api/v1/menu/banner/main`;
        axios
            .get(url_banner)
            .then((res) => {
                const {totalRows, data} = res.data;

                let ary = [];

                for (let i = 0; i < data.length; i++) {
                    ary.push({
                        idx: data[i].idx,
                        link: data[i].category,
                        img: `${process.env.REACT_APP_SERVICE_API}/main/${data[i].folder}/${data[i].images}`,
                        isClosed: false,
                    });
                }

                setBannerList(ary);
            })
            .catch((e) => {
                console.log(e);
                alert("배너 목록 조회 중, 오류가 발생하였습니다.");
            });
    }, []);

    const [hideContent, setHideContent] = useState(true);

    useEffect(() => {
        CheckLoginInfo()
            .then(() => {
                // 카테고리 조회
                axios.get(`${process.env.REACT_APP_USER_SERVICE_API}/category`).then((res) => {
                    setCategoryData(res.data.data);
                });

                // 활동 조회
                axios.get(`${process.env.REACT_APP_USER_SERVICE_API}/activity`).then((res) => {
                    const json = res.data.data;

                    // 활동의 기관명 추가
                    json.map((item) => {
                        axios
                            .get(
                                `${process.env.REACT_APP_USER_SERVICE_API}/org/${item.orgIdx}`
                            )
                            .then((res) => {
                                item.orgTitle = res.data.orgTitle;

                                setActivityData(
                                    json &&
                                    json.filter(
                                        (json) =>
                                            (json.partType === "A" || json.partType === "O") &&
                                            json.isPrivate === "O"
                                    )
                                );
                                setConsumerData(
                                    json &&
                                    json.filter(
                                        (json) =>
                                            (json.beneType === "A" || json.beneType === "O") &&
                                            json.isPrivate === "O"
                                    )
                                );
                                setClosedActivityData(
                                    json &&
                                    json.filter(
                                        (json) =>
                                            json.isPrivate === "O" &&
                                            (json.partType === "E" ||
                                                json.beneType === "E" ||
                                                (json.partType === "X" && json.beneType === "X"))
                                    )
                                );
                            });
                    });
                });

                // 기관 조회
                axios.get(`${process.env.REACT_APP_USER_SERVICE_API}/org`).then((res) => {
                    setOrgData(res.data.data);
                });

                let popupDate = window.sessionStorage.getItem(
                    "___seochoVolunteer___popup____date___"
                );

                if (popupDate !== null && popupDate !== undefined) {
                    let today = convertDateFormatDash(new Date());

                    if (popupDate !== today) {
                        getPopupBanner();
                    }
                } else {
                    getPopupBanner();
                }

                setHideContent(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [getPopupBanner]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/vendor/jquery/jquery-3.5.1.min.js";
        script.async = true;
        const script2 = document.createElement("script");
        script2.src = "/vendor/jquery/jquery.validate.min.js";
        script2.async = true;
        const script3 = document.createElement("script");
        script3.src = "/vendor/swiper/swiper.min.js";
        script3.async = true;
        const script4 = document.createElement("script");
        script4.src = "/assets/js/swiper-init.js";
        script4.async = true;
        const script5 = document.createElement("script");
        script5.src = "/assets/js/jquery.custom.js";
        script5.async = true;
        document.body.appendChild(script);
        document.body.appendChild(script2);
        document.body.appendChild(script3);
        document.body.appendChild(script4);
        document.body.appendChild(script5);
        return () => {
            document.body.removeChild(script);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
            document.body.removeChild(script4);
            document.body.removeChild(script5);
        };
    });

    const searchActivity = () => {
        navigate(`/org/home/search/${searchKeyword}`);
    };

    const enterKeyupHandler = (e) => {
        const enterKeyCode = 13;

        if (window.event.keyCode === enterKeyCode) {
            searchActivity();
        }
    };

    const searchTextChangeHandler = (e) => {
        setSearchKeyword(e.target.value);
    };

    if (hideContent) {
        return null;
    }

    return (
        <Fragment>
            <Panel/>

            <div className="page page--main" data-page="shop">
                {/* <!-- HEADER --> */}
                <MainHeader/>
                {/* <!-- PAGE CONTENT --> */}
                <div
                    className="page__content page_section page__content page_section--with-header page__content page_section--with-bottom-nav">
                    {/* <!-- 프로모션 배너 시작--> */}
                    {bannerList &&
                        bannerList.map((bannerInfo) => (
                            <Banner bannerInfo={bannerInfo} closeBanner={closeBanner}/>
                        ))}
                    {/* <!-- 프로모션 배너 끝--> */}
                    <h1 className="featured__title mt-20 pb-10 mt-100">어떤 분야의</h1>
                    <h1 className="featured__title pb-10">활동을 찾으시나요?</h1>
                    <br/>
                    <div className="search__form">
                        <form>
                            <input hidden="hidden"/>
                            <input
                                type="text"
                                className="search__input"
                                id=""
                                name=""
                                value={searchKeyword}
                                onChange={searchTextChangeHandler}
                                onKeyUp={enterKeyupHandler}
                                placeholder="검색..."
                            />
                            <input
                                type="button"
                                onClick={searchActivity}
                                className="search__submit"
                                value="Send"
                                style={{cursor: "pointer"}}
                            />
                        </form>
                    </div>

                    <div className="page__title-bar">
                        <h2>카테고리</h2>
                        <div className="page__title-right">
                            <div className="swiper-button-prev slider-thumbs__prev"></div>
                            <div className="swiper-button-next slider-thumbs__next"></div>
                        </div>
                    </div>

                    {/* <!-- SLIDER THUMBS 3h --> */}
                    <div
                        className="swiper-container slider-thumbs slider-init mb-20"
                        data-paginationtype="progressbar"
                        data-spacebetweenitems="10"
                        data-itemsperview="auto"
                    >
                        <div className="swiper-wrapper">
                            {categoryData &&
                                categoryData.map((category) => (
                                    <CategoryItem
                                        key={category.idx}
                                        category={category}
                                        url="/org/home/category"
                                    />
                                ))}
                        </div>
                        <div className="swiper-pagination slider-thumbs__pagination"></div>
                    </div>
                    {/* <div className="page__title-bar mt-48">
            <h2>활동자 모집 ({activityData.length})</h2>
          </div>

          <div className="cards cards--12" id="loadlist-01">
            {activityData &&
              activityData
                .map((activity) => (
                  <ActivityItem
                    key={activity.idx}
                    url={`/org/activity/detailpart/${activity.idx}`}
                    folder="org"
                    activity={activity}
                    viewTagList={viewTagList}
                  />
                ))
                .slice(0, activityPage * offsetActivityPage)}
          </div>
          <div className="buttons buttons--centered mb-20">
            {activityData.length > activityPage * offsetActivityPage ? (
              <div
                id=""
                className="button button--more"
                onClick={() => {
                  addPage(setActivitiyPage, activityPage);
                }}
              >
                더보기
              </div>
            ) : (
              ""
            )}
          </div> */}
                    {
                        window.sessionStorage.getItem("orgType") !== "C" && (
                            <>
                                <div className="page__title-bar mt-48">
                                    <h2>도움이 필요하신가요? ({consumerData.length})</h2>
                                </div>
                                <div className="cards cards--12" id="loadlist-02">
                                    {consumerData &&
                                        consumerData
                                            .map((activity) => (
                                                <ActivityItem
                                                    key={activity.idx}
                                                    url={`/org/activity/detailbene/${activity.idx}`}
                                                    folder="org"
                                                    activity={activity}
                                                    viewTagList={viewTagList}
                                                />
                                            ))
                                            .slice(0, consumerPage * offsetActivityPage)}
                                </div>
                                <div className="buttons buttons--centered mb-20">
                                    {consumerData.length > consumerPage * offsetActivityPage ? (
                                        <div
                                            id=""
                                            className="button button--more"
                                            onClick={() => {
                                                addPage(setConsumerPage, consumerPage);
                                            }}
                                        >
                                            더보기
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </>
                        )
                    }
                    <div className="page__title-bar mt-48">
                        <h2>진행한 활동 ({closedActivityData.length})</h2>
                    </div>

                    <div className="cards cards--12" id="loadlist-01">
                        {closedActivityData &&
                            closedActivityData
                                .map((activity) => (
                                    <ActivityItem
                                        key={activity.idx}
                                        url={`/org/activity/detailcarriedout/${activity.idx}`}
                                        folder="org"
                                        activity={activity}
                                        viewTagList={viewTagList}
                                    />
                                ))
                                .slice(0, closedPage * offsetActivityPage)}
                    </div>
                    <div className="buttons buttons--centered mb-20">
                        {closedActivityData.length > closedPage * offsetActivityPage ? (
                            <div
                                id=""
                                className="button button--more"
                                onClick={() => {
                                    addPage(setClosedPage, closedPage);
                                }}
                            >
                                더보기
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="page__title-bar mt-48">
                        <h2>기관/단체({orgData.length})</h2>
                    </div>
                    <div className="cards cards--14">
                        {orgData &&
                            orgData
                                .map((org) => (
                                    <OrgItem id={org.id} folder="org" org={org} key={org.id}/>
                                ))
                                .slice(0, orgPage * offsetOrgPage)}
                    </div>
                    <div className="buttons buttons--centered mb-20">
                        {orgData.length > orgPage * offsetOrgPage ? (
                            <div
                                id=""
                                className="button button--more"
                                onClick={() => {
                                    addPage(setOrgPage, orgPage);
                                }}
                            >
                                더보기
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="space-h-100"></div>
                </div>
            </div>
            {/* <!-- PAGE END --> */}

            {/* <!-- Bottom navigation --> */}
            <div id="bottom-toolbar_org" className="bottom-toolbar_org">
                <Navigation folder={"org"}/>
            </div>
        </Fragment>
    );
}

export default Org;
