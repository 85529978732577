import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityList from "../../../components/ActivityList";
import ActivityMemberList from "../../../components/ActivityMemberList";
import GroupList from "../../../components/GroupList";
import Panel from "../../../components/Panel";
import PartMemberList from "../../../components/PartMemberList";
import SubHeader from "../../../components/SubHeader";
import ConformPopup from "../../common/popup/ConformPopup";
import printJS from "print-js";
import CheckLoginInfo from "../../../services/login-validation";
import ScheduleEdit from "../profile-add-act/ScheduleEdit";
import { convertTimeFormat } from "../../../util/convert-date-function";
import ScheduleInviteMemberItem from "../../../components/ScheduleInviteMemberItem";

function ListActivity(props) {
  const { actIdx, actSchduelIdx } = useParams();
  const navigate = useNavigate();

  // DB 데이터
  const [activityData, setActivityData] = useState();
  const [actScheduleData, setActScheduleData] = useState();
  const [partData, setPartData] = useState([]);
  const [requestPartData, setRequestPartData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [orgMemberData, setOrgMemberData] = useState([]);
  const [recoOrgData, setRecoOrgData] = useState([]);
  const [recoSoleData, setRecoSoleData] = useState([]);

  // 더보기 페이징
  // const offset = 8;
  // const [orgMemberPage, setOrgMemberPage] = useState(1);
  // const [recoOrgPage, setRecoOrgPage] = useState(1);
  // const [recoSolePage, setRecoSolePage] = useState(1);

  // const addPage = (setPageFunc, currentPage) => {
  //   setPageFunc(currentPage + 1);
  // };

  const getActivityData = () => {
    // 활동, 활동상세, 기관정보 조회
    let URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;
    axios.get(URL).then((res) => {
      setActivityData(res.data);
    });
  };

  const getActScheduleData = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/org/profile/list_activity/${actSchduelIdx}`;
    axios
      .get(URL)
      .then((res) => {
        setActScheduleData(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 활동자 목록 조회
  const retrievePartData = (type) => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/org/profile/list_activity?activityScheduleIdx=${actSchduelIdx}&volunteer=D`;
    axios.get(URL).then((res) => {
      const partList = res.data;

      if (type === "") {
        setPartData(partList);
      } else if (type === "org") {
        setPartData(partList.filter((part) => part.orgIdx !== "0"));
      } else if (type === "sole") {
        setPartData(partList.filter((part) => part.orgIdx === "0"));
      }
    });
  };

  // 활동 신청 목록 조회
  const retrieveRequestPartData = (type) => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/org/profile/listapplicationpart/${actSchduelIdx}`;
    axios.get(URL).then((res) => {
      const resJson = res.data.data;

      if (type === "") {
        setRequestPartData(resJson);
      } else if (type === "org") {
      } else if (type === "sole") {
      }
    });
  };

  // 그룹 조회
  const retrieveGroupData = () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/group_list/total/${sessionStorage.idx}`;
    axios.get(url).then((res) => {
      setGroupData(res.data.data);
    });
  };

  // 내 기관 소속 회원 조회
  const retrieveOrgMemberData = () => {
    if (searchKeyword !== "") {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/nickname?orgIdx=${sessionStorage.idx}&nickname=${searchKeyword}`;
      axios.get(URL).then((res) => {
        setOrgMemberData(res.data.data);
      });
    } else {
      const URL = `${process.env.REACT_APP_USER_SERVICE_API}/org_member_list/org/${sessionStorage.idx}`;
      axios.get(URL).then((res) => {
        const orgMemberArray = [];
        const orgMemberList = res.data.data;
        axios
          .all(
            orgMemberList.map((orgMember) => {
              return axios.get(
                `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${orgMember.userIdx}`
              );
            })
          )
          .then((res) => {
            res.map((org, index) => {
              orgMemberArray.push(
                Object.assign(org.data, orgMemberList[index])
              );
            });
            setOrgMemberData(orgMemberArray);
          });
      });
    }
  };

  // 추천 기관 조회
  // const retrieveRecoOrgData = () => {
  //   const URL = `${process.env.REACT_APP_USER_SERVICE_API}/matching/org/list_activity/${actSchduelIdx}`;
  //   axios.get(URL).then((res) => {
  //     const resJson = res.data.data;
  //     setRecoOrgData(resJson);
  //   });
  // };

  // 추천 회원 조회
  const retrieveRecoSoleData = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/matching/list_activity/${actIdx}/${actSchduelIdx}`;
    axios.get(URL).then((res) => {
      const resJson = res.data.data;
      setRecoSoleData(resJson);
    });
  };

  const searchContent = () => {
    retrieveOrgMemberData();
    // retrieveGroupData();
    // retrieveRecoOrgData();
    // retrieveRecoSoleData();
  };

  // 셀렉트 박스 (전체 : "" , 기관 : org, 개인 : sole)
  const [partListSelBox, setPartListSelBox] = useState("");
  const [requestPartListSelBox, setRequestPartListSelBox] = useState("");

  // 활동자 목록 셀렉트박스 변경 핸들러
  const partListSelboxChangeHandler = (e) => {
    const { name, value } = e.target;
    setPartListSelBox(value);
  };

  // 활동 신청 목록 셀렉트박스 변경 핸들러
  const requestPartListSelboxChangeHandler = (e) => {
    const { name, value } = e.target;
    setRequestPartListSelBox(value);
  };

  const [hideContent, setHideContent] = useState(true);

  // 데이터 조회
  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getActivityData();
        getActScheduleData();
        retrieveGroupData();
        retrieveOrgMemberData();
        // retrieveRecoOrgData();
        retrieveRecoSoleData();

        // 활동자 목록
        retrievePartData(partListSelBox);

        // 활동 신청 목록
        retrieveRequestPartData(requestPartListSelBox);

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    // return;
    // retrieveQrData();
  }, [partListSelBox, requestPartListSelBox]);

  const convertDate = (inputDate) => {
    const date = new Date(inputDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;

    return `${year}년 ${month}월 ${day}일`;
  };

  // QR 인쇄하기
  const printQR = () => {
    const header = `<h1>${activityData && activityData.title}</h1>
                  <h1>${actScheduleData &&
      convertDate(actScheduleData.activityDate) +
      " " +
      actScheduleData.activityTime
      }</h1>`;
    printJS({
      printable: "qr",
      type: "html",
      style: `.qrImg {width: 100%;}`,
      header: header,
      scanStyles: false,
    });
  };

  // 모집 마감하기 버튼 클릭 핸들러
  const recruitmentDeadlinClickHandler = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/recruitment_deadline/${actSchduelIdx}?recruitNum=${actScheduleData.recruitedNum}`;
    axios
      .get(URL)
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 전체 일정 취소 버튼 클릭 핸들러
  const cancelScheduleClickHandler = () => {
    if (actScheduleData.isHelpSchedule === "Y") {
      alert(
        "해당일정에 도움요청이 있습니다. 도움요청 승인 취소 후 일정취소 해주세요."
      );
      navigate(`/org/profile/listrequest/${actIdx}`);

      return;
    } else if (partData.length !== 0) {
      alert("활동자가 지정/모집된 상태에서는 일정 취소가 불가능합니다.");

      return;
    }

    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/${actSchduelIdx}`;
    axios
      .delete(URL)
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false);
  const toggleEditScheduleModal = () => {
    setShowEditScheduleModal(!showEditScheduleModal);
  };

  // 검색어
  const [searchKeyword, setSearchKeyword] = useState("");

  // 검색어 변경 핸들러
  const searchKeywordChangeHandler = (e) => {
    const { name, value } = e.target;
    setSearchKeyword(value);
  };

  const chatGroupDClickHandler = () => {
    alert("채팅방 입장이 어렵습니다.");

    // navigate(`/org/chat/chatd/a2g`, {
    //   state: {
    //     activityId: actIdx ? actIdx : 1, // 활동 id
    //     groupId: actSchduelIdx, // 그룹 id
    //   },
    // });
  };

  // 검색 버튼 클릭 핸들러
  const searchClickHandler = () => {
    searchContent();
  };

  // 검색 엔터 키업 핸들러
  const enterKeyupHandler = (e) => {
    const enterKeyCode = 13;

    if (window.event.keyCode === enterKeyCode) {
      // 엔터 클릭 시 수행될 Func
      searchContent();
    }
  };

  // 선택된 활동자
  const [selActListIdx, setSelActListIdx] = useState("");

  // 참여 상태 변경 (신청(W)/신청완료(D)/반려(R)/참(Y)/불(N))
  // done, waiting, reject
  const UpdateVolunteer = (volunteer) => {
    if (selActListIdx === null) return;

    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/org/profile/listapplicationpart/${volunteer}/${selActListIdx.idx}`;
    axios.put(URL).then(() => {
      navigate(0);
    });
  };

  // 일정에 활동자 지정
  const addScheduleActList = (nicknae, userIdx) => {
    if (window.confirm(`${nicknae}를 해당 일정의 활동자로 지정하시겠습니까?`)) {
      const actList = {
        activityIdx: actIdx,
        userIdx: userIdx,
        activityScheduleIdx: actSchduelIdx,
        orgIdx: "0",
        groupIdx: "0",
        volunteer: "W",
        time: "0",
        createdUid: sessionStorage.idx,
        updatedUid: sessionStorage.idx,
      };

      axios
        .post(`${process.env.REACT_APP_USER_SERVICE_API}/act_list`, actList)
        .then((res) => {
          alert("참여 완료");
        })
        .catch((error) => {
          alert(error.response.data);
        });
    } else {
      // 취소
    }
  };

  // 일정 취소
  const cancelSchedual = () => {
    if (selActListIdx === null) return;

    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/mine/${selActListIdx}`;
    axios
      .delete(URL)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // const [showCalendar, setShowCalendar] = useState(false);

  if (hideContent) {
    return null;
  }

  // 스케줄 정보(id, 활동일자, 시작 시간, 종료 시간)
  // 활동 모집자 수
  const editSchedule = async (scheduleInfo, recruitNum, isNewSchedule) => {
    // 기존의 스케줄 수정
    let activitySchedule = {
      activityDate: scheduleInfo.date,
      activityTime: convertTimeFormat(scheduleInfo.startTime),
      activityTimeEnd: convertTimeFormat(scheduleInfo.endTime),
      recruitNum: recruitNum,
      updatedUid: sessionStorage.idx,
    };

    axios
      .put(
        `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/${actSchduelIdx}`,
        activitySchedule
      )
      .then(() => {
        alert("수정되었습니다.");
        getActScheduleData();
      })
      .catch((error) => {
        console.log(error.response);
      });

    toggleEditScheduleModal();
  };

  const deleteSchedule = (delId, delNum, isNewSchedule) => {
    toggleEditScheduleModal();

    axios
      .delete(
        `${process.env.REACT_APP_USER_SERVICE_API}/activity-schedule/${activityData.idx}`,
        {
          params: {
            deleteIds: delId,
            updatedUid: window.sessionStorage.getItem("idx"),
          },
        }
      )
      .then(() => {
        alert("삭제되었습니다.");
        navigate(-1);
      });
  };

  if (showEditScheduleModal && actScheduleData) {
    return (
      <ScheduleEdit
        toggleEditScheduleModal={toggleEditScheduleModal}
        editSchedule={editSchedule}
        deleteSchedule={deleteSchedule}
        prevInfo={{
          id: actSchduelIdx,
          activityDate: actScheduleData.activityDate,
          activityTimeValue: actScheduleData.activityTime24,
          activityTimeValue_end: actScheduleData.activityTime24End,
        }}
        hideRecruitNum={activityData.partType === "E"}
      />
    );
  }

  return (
    <Fragment>
      <Panel />

      <iframe
        id="ifmcontentstoprint"
        title="printQr"
        style={{ height: "0px", width: "0px", position: "absolute" }}
      />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="일정 상세" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="cards cards--6 pt-40" id="loadlist-01">
              {actScheduleData && (
                <ActivityList folder="org" schedule={actScheduleData} />
              )}
              {activityData && activityData.joinType === "Q" && (
                <div className="table card card--style-thumb mb-20">
                  <div className="card__details">
                    <h2 className="">QR 코드</h2>
                    <div
                      id="qr"
                      // className="card__thumb card__thumb--round-corners"
                      style={{ textAlign: "center" }}
                    >
                      <div style={{ padding: "50px" }}>
                        <img
                          className="qrImg"
                          style={{ cursor: "pointer", maxWidth: "100%" }}
                          onClick={() =>
                            navigate(`/org/profile/actdetail/${actIdx}`)
                          }
                          src={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=activityScheduleIdx=${actSchduelIdx}`}
                          alt="qrCode"
                        />
                        {/* <QRCode  value="naver.com" onClick={()=>navigate(`/org/profile/actdetail/${actIdx}`)} includeMargin={true} renderAs="svg" size="100%" style={{cursor:"pointer"}} /> */}
                      </div>
                    </div>
                    <input
                      type="submit"
                      name="submit"
                      className="form__submit button button--gray button--full"
                      id="submit"
                      value="인쇄하기"
                      onClick={printQR}
                    />
                  </div>
                </div>
              )}
              <div className="form__row mt-40">
                <input
                  type="submit"
                  name="submit"
                  className="form__submit button button--secondary button--333 open-popup"
                  data-popup="conform_close"
                  id="submit"
                  value="모집 마감하기"
                />
                <input
                  type="submit"
                  name="submit"
                  className="form__submit button button--secondary button--333 open-popup"
                  data-popup="conform_schdulecancle"
                  id="submit"
                  value="일정 취소"
                />
                <input
                  type="button"
                  style={{ cursor: "pointer" }}
                  name="submit"
                  className="form__submit button button--secondary button--333"
                  id="submit"
                  value="일정 수정"
                  onClick={toggleEditScheduleModal}
                />
              </div>
            </div>

            <div className="page__title-bar mt-48">
              <h2>활동자 목록 ({partData.length})</h2>
              <div className="form__full">
                <select
                  name="ContactSelect"
                  className="required"
                  value={partListSelBox}
                  onChange={partListSelboxChangeHandler}
                >
                  <option value="">전체</option>
                  <option value="sole">일반</option>
                  <option value="org">기관</option>
                </select>
              </div>
            </div>
            <div className="cards cards--11">
              {/* <div
                onClick={chatGroupDClickHandler}
                style={{ cursor: "pointer" }}
                className="button button--full button--main float-right ml-10"
              >
                활동자 단체 메세지
              </div> */}
              {partData &&
                partData.map((part) => (
                  <ActivityMemberList
                    part={part}
                    btnType="schedule"
                    actSchduelIdx={actSchduelIdx}
                    actIdx={actIdx}
                    setSelActListIdx={setSelActListIdx}
                  />
                ))}
            </div>

            <div className="d-flex justify-space align-items-center">
              <input
                type="button"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="활동자 지정"
                onClick={() =>
                  navigate(
                    `/org/activity/placingmember/individually/${actIdx}/${actSchduelIdx}`,
                    {
                      state: { isFinished: false },
                    }
                  )
                }
              />
            </div>
            <br />
            <div className="d-flex justify-space align-items-center">
              <h2>회원 그룹({groupData.length})</h2>
            </div>
            <div className="sections">
              <div className="form__row">
                {groupData &&
                  groupData.map((group, idx) => (
                    <GroupList
                      key={idx}
                      addition={true}
                      group={group}
                      actIdx={actIdx}
                      actSchduelIdx={actSchduelIdx}
                      isFinished={false}
                    />
                  ))}
              </div>
            </div>
            <div className="page__title-bar mt-48">
              <h2>활동 신청 목록 ({requestPartData.length})</h2>
              <div className="form__full">
                <select
                  name="ContactSelect"
                  className="required"
                  value={requestPartListSelBox}
                  onChange={requestPartListSelboxChangeHandler}
                >
                  <option value="">전체</option>
                  <option value="sole">일반</option>
                  <option value="org">기관</option>
                </select>
              </div>
            </div>
            <div className="cards cards--11">
              {requestPartData &&
                requestPartData.map((part, idx) => (
                  <PartMemberList
                    key={idx}
                    part={part}
                    setSelActListIdx={setSelActListIdx}
                  />
                ))}
            </div>
            {/* <div className="page__title-bar mt-48">
              <h2>활동자 추가/요청하기</h2>
            </div>
            <div className="search__form">
              <form>
                <input hidden="hidden" />
                <input
                  type="text"
                  className="search__input"
                  placeholder="검색..."
                  value={searchKeyword}
                  onChange={searchKeywordChangeHandler}
                  onKeyUp={enterKeyupHandler}
                />
                <input
                  type="button"
                  className="search__submit"
                  value="Send"
                  onClick={searchClickHandler}
                />
              </form>
            </div> */}

            {/* <div className="page__title-bar mt-48">
              <h2>내 기관 소속 회원 ({orgMemberData.length})</h2>
            </div>
            <div className="cards cards--14">
              {orgMemberData &&
                orgMemberData.map((orgMember) => (
                  <ScheduleInviteMemberItem
                    member={orgMember}
                    invite="추가"
                    actIdx={actIdx}
                    actSchduelIdx={actSchduelIdx}
                  />
                ))}
              <div className="buttons buttons--centered mb-20">
                {orgMemberData.length > orgMemberPage * offset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setOrgMemberPage, orgMemberPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div> */}

            {/*<div className="page__title-bar mt-48">*/}
            {/*  <h2>추천 기관/단체 ({recoOrgData.length})</h2>*/}
            {/*</div>*/}
            {/*<div className="cards cards--14">*/}
            {/*  {recoOrgData &&*/}
            {/*    recoOrgData*/}
            {/*      .map((recoOrg) => (*/}
            {/*        <InviteOrgItem*/}
            {/*          org={recoOrg}*/}
            {/*          invite={recoOrg.volunteer}*/}
            {/*          actSchduelIdx={actSchduelIdx}*/}
            {/*        />*/}
            {/*      ))*/}
            {/*      .slice(0, recoOrgPage * offset)}*/}
            {/*  <div className="buttons buttons--centered mb-20">*/}
            {/*    {recoOrgData.length > recoOrgPage * offset ? (*/}
            {/*      <div*/}
            {/*        id=""*/}
            {/*        className="button button--more"*/}
            {/*        onClick={() => {*/}
            {/*          addPage(setRecoOrgPage, recoOrgPage);*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        더보기*/}
            {/*      </div>*/}
            {/*    ) : (*/}
            {/*      ""*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="page__title-bar mt-48">
              <h2>추천 회원 ({recoSoleData.length})</h2>
            </div>
            <div className="cards cards--14">
              {recoSoleData &&
                recoSoleData
                  .map((recoSole) => (
                    <ScheduleInviteMemberItem
                      member={recoSole}
                      invite={recoSole.volunteer}
                      actIdx={actIdx}
                      actSchduelIdx={actSchduelIdx}
                    />
                  ))
                  // .slice(0, recoSolePage * offset)
              }
              {/*<div className="buttons buttons--centered mb-20">*/}
              {/*  {recoSoleData.length > recoSolePage * offset ? (*/}
              {/*    <div*/}
              {/*      id=""*/}
              {/*      className="button button--more"*/}
              {/*      onClick={() => {*/}
              {/*        addPage(setRecoSolePage, recoSolePage);*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      더보기*/}
              {/*    </div>*/}
              {/*  ) : (*/}
              {/*    ""*/}
              {/*  )}*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <ConformPopup
        text="승인하시겠습니까?"
        popupType="accept"
        conformClick={() => UpdateVolunteer("done")}
      />
      <ConformPopup
        text="정말 반려하시겠습니까?"
        popupType="dismissal"
        conformClick={() => UpdateVolunteer("reject")}
      />
      <ConformPopup
        text="정말 취소하시겠습니까?"
        popupType="cancle"
        conformClick={() => UpdateVolunteer("waiting")}
      />

      <ConformPopup
        text="정말 마감하시겠습니까?"
        popupType="close"
        conformClick={recruitmentDeadlinClickHandler}
      />
      <ConformPopup
        text="정말 취소하시겠습니까?"
        popupType="schdulecancle"
        conformClick={cancelScheduleClickHandler}
      />

      <ConformPopup
        text="정말 취소하시겠습니까?"
        popupType="actlistcanclea"
        conformClick={cancelSchedual}
      />
    </Fragment>
  );
}

export default ListActivity;
