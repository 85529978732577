import React from 'react';

function PopupSuccess(props) {
  return (
    <div className="popup popup--centered popup--shadow popup--success">
        <div className="popup__close"><a href="#" className="close-popup" data-popup="success"><img
                          src="../../assets/images/icons/blue/close.svg" alt="" title="" /></a></div>
        <div className="popup__icon"><img src="/assets/images/icons/blue/checked.svg" alt="" title="" /></div>
        <h2 className="popup__title">Success !</h2>
        <p className="popup__text">This is an alert example. Creativity is breaking out of established patterns to look at
              things in a different way.</p>
    </div>
  );
}

export default PopupSuccess;