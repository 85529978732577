import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormatDate from "./FormatDate";
import Tag from "./Tag";

function OkPhoneList(props) {
  const { item, setDelUserIdx, setDelId } = props;

  const defaultImgPath = "/assets/images/photos/img-p-expert.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);
  const [nickname, setNickname] = useState("전문가 배정중");

  // 파일명 조회
  useEffect(() => {
    if (item) {
      getFileName();
      getNickname();
    }
  }, []);

  const getFileName = () => {
    if (item !== null && Object.keys(item).includes("expertIdx")) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;

      axios
        .get(URL, {
          params: {
            pkIdx: item && item.expertIdx,
            imageStatus: "U",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  };

  const getNickname = () => {
    if (item !== null && Object.keys(item).includes("expertIdx")) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${item.expertIdx}`;
      axios
        .get(URL)
        .then((res) => {
          const data = res.data;
          setNickname(data.name);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  // 디폴트 이미지로 변경
  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  const deleteOkPhone = () => {
    setDelUserIdx(item.userIdx);
    setDelId(item.id);
  };

  return (
    <div className="card card--style-inline card--style-inline-bg card--style-round-corners ">
      <div className="user-details__thumb">
        <img src={imgPath} alt="" onError={changeDefaultImg} />
      </div>
      <div className="card__details">
        <h4 className="card__username fc_point1">{item.content}</h4>
        {/* 전화상담은 타이틀이 없음 */}
        {/* <h4 className="card__title">{item.title}</h4> */}
        <Link to={`/sole/otherprofile/sole/${item && item.expertIdx}`}>
          <h4 className="card__title">{nickname}</h4>
        </Link>
        <p className="card__info">{item.phoneNum}</p>
        <p className="card__info">
          <FormatDate date={item && item.consultationDate} />{" "}
          {item && item.consultationTime}
        </p>
        <Tag tagName={item.area} />
        {item.consultationStatus && item.consultationStatus === "W" ? (
          <Fragment>
            <Tag tagName="대기 중" />
            <div
              class="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_delete"
              onClick={deleteOkPhone}
            >
              삭제
            </div>
          </Fragment>
        ) : (
          <Tag tagName="승인 완료" />
        )}
      </div>
    </div>
  );
}

export default OkPhoneList;
