import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

function ActivityDetailHeader(props) {
  const { title } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const sharePage = () => {
    if (!document.queryCommandSupported("copy")) {
      return alert("공유가 지원되지 않는 브라우저 입니다.");
    }

    const textarea = document.createElement("textarea");
    textarea.value = window.location.href;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = "fixed";

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    document.execCommand("copy");
    document.body.removeChild(textarea);

    alert("링크가 복사되었습니다.");
  };

  return (
    <header className="header header--page header--fixed">
      <div className="header__inner">
        <div className="header__icon">
          <a
            onClick={() =>
              location.state && location.state.isRedirect === true
                ? navigate(-2)
                : navigate(-1)
            }
          >
            <img src="/assets/images/icons/white/arrow-back.svg" alt="" />
          </a>
        </div>
        <div className="header__logo header__logo--text">{title}</div>
        <div className="header__icons-group header__icons-group--right">
          {/* <div
            className="header__icon"
            onClick={() =>
              window.location.href.includes("actdetail") ? null : sharePage()
            }
          >
            <a
              data-popup={
                window.location.href.includes("actdetail")
                  ? "conform_share"
                  : null
              }
              className="open-popup"
            >
              <img src="/assets/images/icons/white/popup.svg" alt="" title="" />
            </a>
          </div> */}
        </div>
      </div>
    </header>
  );
}

export default ActivityDetailHeader;
