import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

function SubHeader(props) {
  const { title, location, toggleModal } = props;
  const hrefLocation = useLocation();

  const navigate = useNavigate();

  const backLocatio = (e) => {
    e.preventDefault();

    if (hrefLocation.state && hrefLocation.state.isRedirect === true) {
      navigate(-2);
    } else {
      if (location !== undefined) {
        navigate(`/sole/home/solebene`);
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <header className="header header--page header--fixed">
      <div className="header__inner">
        <div className="header__icon">
          <Link
            to=""
            onClick={
              typeof toggleModal === "function" ? toggleModal : backLocatio
            }
          >
            <img src="/assets/images/icons/white/arrow-back.svg" alt="" />
          </Link>
        </div>
        <div className="header__logo header__logo--text">
          <Link to="">{title}</Link>
        </div>
        <div className="header__icon header__icon--empty-space" />
      </div>
    </header>
  );
}

export default SubHeader;
