import React from "react";
import { useNavigate } from "react-router";

function GroupHeader(props) {
  const { orgTitle, groupName } = props;
  const navigate = useNavigate();

  return (
    <header className="header header--page header--fixed">
      <div className="header__inner">
        <div className="header__icon">
          <span onClick={() => navigate(-1)}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icons/white/arrow-back.svg`}
              alt=""
              title=""
            />
          </span>
        </div>
        <div className="header__logo header__logo--text">
          {orgTitle}/{groupName}
        </div>
        <div className="header__icons-group header__icons-group--right">
          <div className="header__icon header__icon--more"></div>
        </div>
      </div>
    </header>
  );
}

export default GroupHeader;
