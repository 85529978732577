import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Tag from "./Tag";

function HelpScheduleList(props) {
  const { helpSchedule, setSelHelpScheduleIdx, showQrBtn } = props;
  const navigate = useNavigate();

  const [cardClass, setCardClass] = useState("");

  const updateCardClass = () => {
    if (helpSchedule.recruit === undefined) return;

    // recruit : 모집 중, 모집 완료
    if (helpSchedule.recruit === "모집 중") {
      setCardClass("card_border");
    } else {
      setCardClass("card_point");
    }
  };

  const updateSelHelpSchedule = () => {
    setSelHelpScheduleIdx(helpSchedule.idx);
  };

  useEffect(() => {
    updateCardClass();
  }, []);

  const viewBottom = () => {
    if (helpSchedule && helpSchedule.helpStatus !== "D") {
      return (
        <p className="card__list">
          필요인원 : <a>{helpSchedule && helpSchedule.recruitNum}</a>
        </p>
      );
    } else {
      return (
          <>
              <p className="card__list">
                  활동 인원 :{" "}
                  <a>
                      {helpSchedule &&
                          `참여(지정) ${helpSchedule.recruitedNum}명 / ${
                              helpSchedule.recruitNum === "0"
                                  ? "제한없음"
                                  : `필요인원 ${helpSchedule.recruitNum}명`
                          } (${helpSchedule.recruit})`}
                  </a>
              </p>
              <p className="card__list">
                  활동 시간 :{" "}
                  <a>
                      {helpSchedule && `${helpSchedule.activityTime} ~ ${helpSchedule.activityTimeEnd}`}
                  </a>
              </p>
          </>
      );
    }
  };

  const viewBtn = () => {
    switch (helpSchedule && helpSchedule.helpStatus) {
      case "W":
        return (
          <Fragment>
            <div
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_requestcancle"
              onClick={updateSelHelpSchedule}
            >
              요청 취소
            </div>
            <Tag tagName="대기중" />
          </Fragment>
        );
      case "N":
        return (
          <Fragment>
            <div
              className="button button--small button--secondary float-right ml-10 open-popup"
              data-popup="conform_delete"
              onClick={updateSelHelpSchedule}
            >
              삭제
            </div>
            <Tag tagName="반려" />
          </Fragment>
        );
      case "D":
        return (
          <Fragment>
            {helpSchedule.recruit !== "모집 마감" && (
              <div
                className="button button--small button--secondary float-right ml-10 open-popup"
                data-popup="conform_schedulecancle"
                onClick={updateSelHelpSchedule}
              >
                일정 취소
              </div>
            )}
            {helpSchedule.recruit === "모집 마감" && showQrBtn && (
              <div
                className="button button--small button--primary float-right ml-10"
                onClick={() =>
                  navigate(
                    `/org/activity/detailbene/qr/${helpSchedule.activityIdx}/${helpSchedule.activityScheduleIdx}`
                  )
                }
              >
                QR코드 보기
              </div>
            )}
          </Fragment>
        );
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div className={`table card ${cardClass} card--style-thumb mb-20`}>
        <div className="card__details">
          <h2 className="fc_point1">
            {helpSchedule &&
              `${helpSchedule.activityDate} ${helpSchedule.activityTime}`}
          </h2>
          {viewBottom()}
        </div>
        {viewBtn()}
      </div>
    </Fragment>
  );
}

export default HelpScheduleList;
