const parseArrayToString = (ary) => {
  let string = "";

  for (let i = 0; i < ary.length; i++) {
    string += `${ary[i]},`;
  }

  return string.slice(0, string.length - 1);
};

const sliceIdx = (topic, char) => {
  const ary = topic.split("-");

  for (let i = 1; i < ary.length; i++) {
    if (ary[i].includes(char)) {
      return ary[i].slice(1);
    }
  }

  return null;
};

const onHandleImgError = (e, status) => {
  switch (status) {
    case "U":
      e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`;
      break;
    case "E":
      e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-p-expert.jpg`;
      break;
    case "A":
      e.target.src = `${process.env.PUBLIC_URL}/assets/images/food/img-default.jpg`;
      break;
    default:
      e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`;
      break;
  }

  return;
};

const setAddressString = (addr1, addr2) => {
  if (addr1 === null || typeof addr1 === undefined) {
    return "";
  }

  if (addr2 === null || typeof addr2 === undefined) {
    return addr1;
  }

  return `${addr1} ${addr2}`;
};

const maskingName = (name) => {
  if (typeof name === "undefined" || name === null) {
    return "";
  }

  let maskingStr = name.slice(0, 1);

  for (let i = 1; i < name.length; i++) {
    maskingStr += "*";
  }
  return maskingStr;
};

export { sliceIdx, onHandleImgError, setAddressString, maskingName };
export default parseArrayToString;
