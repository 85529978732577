import React, { Fragment } from "react";
import Panel from "../../../components/Panel";

function Dictionary() {
  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <header className="header header--page header--fixed">
          <div className="header__inner">
            <div className="header__icon"><a href="javascript:window.history.back();"><img src="../../assets/images/icons/white/arrow-back.svg" alt="" /></a></div>
            <div className="header__logo header__logo--text"><a href="#">용어사전</a></div>
            <div className="header__icon header__icon--empty-space" />
          </div>
        </header>
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40">
            </div>
            <div className="fieldsets">
              <ul className="custom-listing mb-20">
                <li>상세 내용은 어드민 페이지에서 편집 가능</li>
              </ul>
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default Dictionary;