import axios from "axios";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ActivityMemberList from "../../../components/ActivityMemberList";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import UserListHeader from "../../../components/UserListHeader";
import CheckLoginInfo from "../../../services/login-validation";
import ConformPopup from "../../common/popup/ConformPopup";
import PopupListChat from "../../common/popup/PopupListChat";

function UserListAll(props) {
  const navigate = useNavigate();
  const { topic } = useParams();

  const useraddClickHandler = () => {
    navigate("/org/profile/usersearch", {
      state: {},
    });
  };

  const [showOpion, setShowOption] = useState(false);
  const toggleChatOption = () => {
    setShowOption(!showOpion);
  };

  const [totalRows, setTotalRows] = useState(null);
  const [memberList, setMemberList] = useState(null);
  const getMemberList = useCallback(async () => {
    let url = `${process.env.REACT_APP_CHAT_SERVICE_API}/api/v1/chat/setting/${topic}/members`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const { count, activities } = response.data;

        let ary = [];
        for (let i = 0; i < activities.length; i++) {
          ary.push({
            idx: activities[i].idx,
            nickname: activities[i].nickname,
            phoneNum: activities[i].phoneNum,
            img: activities[i].img,
          });
        }

        setTotalRows(count);
        setMemberList(ary);
      }
    } catch (e) {
      console.log(e);
      alert("채팅 멤버롤 조회하던 중, 오류가 발생하였습니다.");
    }
  }, [topic]);

  const [selectedIdx, setSelectedIdx] = useState(null);

  const handleMemberReported = async () => {
    if (selectedIdx === null) return;

    const url = `${process.env.REACT_APP_SERVICE_API}/api/v1/user/${selectedIdx}/reported`;

    try {
      const response = await axios.put(url);

      if (response.status === 201) {
        alert("신고되었습니다.");
      }
    } catch (e) {
      console.log(e);
      alert("사용자 신고 중, 오류가 발생하였습니다.");
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getMemberList();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getMemberList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <UserListHeader title="전체 회원" toggleChatOption={toggleChatOption} />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="page__title-bar pt-40 mb-24">
              <h2 className="page__title">회원({totalRows})</h2>
            </div>
            <div className="form__row">
              {/* <input
                onClick={useraddClickHandler}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--primary button--full"
                id="submit"
                value="+ 회원 추가"
              /> */}
            </div>
            <div className="cards cards--11">
              {memberList &&
                memberList.map((member) => (
                  <ActivityMemberList
                    part={member}
                    btnType="userlist"
                    setReportedIdx={setSelectedIdx}
                  />
                ))}
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
      <ConformPopup
        conformClick={handleMemberReported}
        text="해당 회원을 신고하시겠습니까?"
        popupType="report"
      />
      {showOpion && (
        <PopupListChat
          popupType="activity_edit"
          toggleChatOption={toggleChatOption}
        />
      )}
    </Fragment>
  );
}

export default UserListAll;
