import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import CheckLoginInfo from "../../../services/login-validation";

function OrgPrivate(props) {
  const navigate = useNavigate();
  const { orgIdx } = useParams();

  const chatDClickHandler = (url) => {
    navigate(url, {
      state: {
        senderOrgId: window.sessionStorage.getItem("idx"), // 보내는 기관 id
        targetOrgId: orgIdx, // 받는 기관 Id
      },
    });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="profile">
        {/* PAGE CONTENT */}
        <div className="page__content page_section">
          <SubHeader />
          <div className="user-profile mb-20 mt-40">
            <div className="user-profile__thumb">
              <img src="/assets/images/photos/img-p-org.jpg" alt="" />
            </div>
            <div className="user-profile__name">기관/단체</div>
            <div className="user-profile__number">전화번호 : 000-0000-0000</div>
            <div className="user-profile__info">소개글 소개소개글</div>
            <div className="buttons buttons--centered">
              <div className="info-box">
                <span>수요자 모집</span> 12건
              </div>
              <div className="info-box">
                <span>활동자 모집</span> 12건
              </div>
              {/* <span
                onClick={() => chatDClickHandler(`/org/chat/chatd/o2o`)}
                className="button button--orange button--ex-small ml-10"
              >
                메세지
              </span> */}
            </div>
          </div>
          <div className="user-profile__info2">
            이 계정은 비공개 계정입니다.
          </div>
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default OrgPrivate;
