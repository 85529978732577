import React, { useEffect, useState } from "react";
import { convertDateFormatDash } from "../util/convert-date-function";

const Banner = ({ bannerInfo, closeBanner }) => {
  const [isClosed, setIsClosed] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const onClickCloseBtn = () => {
    closeBanner(bannerInfo.idx);
    setIsClosed(true);
  };

  const onChangeChecked = (checked) => {
    setIsChecked(checked);

    if (checked === true) {
      // 쿠키 셋팅
      let today = new Date();
      const expires = new Date();
      expires.setDate(expires.getDate() + 1);

      window.sessionStorage.setItem(
        "___seochoVolunteer___popup____date___",
        convertDateFormatDash(today)
      );
    } else {
      // 쿠키 삭제
      window.sessionStorage.removeItem("___seochoVolunteer___popup____date___");
    }
  };

  useEffect(() => {
    setIsClosed(bannerInfo.isClosed);
  }, [bannerInfo.isClosed]);

  return (
    <div
      className="promotionbanner"
      style={isClosed ? { display: "none" } : null}
    >
      <div className="promotionbanner__image">
        <a href={bannerInfo.link}>
          <img src={bannerInfo.img} alt="" title="" />
        </a>
      </div>
      <div className="promotionbanner_close">
        <p className="pt_close_right" onClick={() => onClickCloseBtn()}>
          닫기 x{" "}
        </p>
        <p className="pt_close_left">
          <input
            type="checkbox"
            name="xxx"
            value="yyy"
            checked={isChecked}
            onChange={(e) => onChangeChecked(e.target.checked)}
          />
          오늘하루 안보기{" "}
        </p>
      </div>
    </div>
  );
};

export default Banner;
