import React, { Fragment, useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import Tag from "../../../components/Tag";
import UrlContext from "../../../context/Url.context";
import axios from "axios";
import ConformDelete from "../../common/popup/ConformDelete";
import OkPhoneList from "../../../components/OkPhoneList";
import CheckLoginInfo from "../../../services/login-validation";

function Detail() {
  const navigate = useNavigate();
  const urlContext = useContext(UrlContext);
  const [conslist, setConslist] = useState();

  // 배너 고정 URL 사용으로 주석처리
  // const defaultImgPath = "/assets/images/food/img-default.jpg";
  const defaultImgPath = `${urlContext.value}/admin-service/ok/PHONE/6ac75603-3c7c-4d43-a053-3a5259d6ee99.png`;
  const [bannerImgPath, setBannerImgPath] = useState(defaultImgPath);

  const GoSelectscd = () => {
    navigate(`/sole/okphone/selectschedule`, {
      state: {},
    });
  };

  // 삭제할 상담 IDX
  const [delUserIdx, setDelUserIdx] = useState("");
  const [delId, setDelId] = useState(0);

  // 상담 삭제
  const deleteClick = () => {
    if (delUserIdx === "") {
      return;
    }

    if (delId === 0) {
      return;
    }

    let url = `${process.env.REACT_APP_USER_SERVICE_API}/phone_consultation/${delUserIdx}/${delId}`;
    axios
      .delete(url)
      .then((res) => {
        navigate(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/phone_consultation/myinfo/${sessionStorage.idx}`;
        axios
          .get(url)
          .then((res) => {
            const { totalRows, data, imageUrl } = res.data;

            setConslist(data);
          })
          .catch((err) => {
            console.log(err.response);
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="전화 상담" location="/sole/home/solebene" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div class="act_thumbnail">
              <img src={bannerImgPath} alt="" title="" />
            </div>
            {/*
            <div
              className="swiper-container slider-simple slider-simple  mb-20 slider-init"
              data-paginationtype="bullets"
              data-spacebetweenitems={0}
              data-itemsperview={1}
            >
              <div className="swiper-wrapper">
                <div
                  className="swiper-slide slider-simple__slide"
                  style={{ backgroundImage: `url(${bannerImgPath})` }}
                ></div>
              </div>
              <div className="swiper-pagination slider-simple__pagination" />
            </div>
          */}
            <div className="d-flex justify-space align-items-center">
              <h2 className="page__title mb-0">
                서초OK생활자문단 전화 상담 예약
              </h2>
            </div>
            <div className="fieldsets">
              <ul className="custom-listing mb-20">
                <li>
                  <span>상담 분야 : </span>
                  <Tag tagName="법률" />
                  <Tag tagName="세무" />
                  <Tag tagName="법무" />
                  <Tag tagName="건축" />
                  <Tag tagName="노무" />
                </li>
              </ul>
              <form className="pt-40" id="ContactForm">
                <h4 className="mt-48">전화 상담 답변제한 질의유형</h4>
                <ul className="custom-listing custom-listing--checked mb-20">
                  <li>
                    전화 상담 인터넷예약은 각 분야별(법률/세무/법무/건축/노무
                    등)로 같은달에 2회까지 가능합니다.{" "}
                  </li>
                  <li>
                    예약 취소는 적어도 상담 시간 하루 전까지 부탁드립니다.{" "}
                  </li>
                  <li>당일 예약은 되지 않습니다.</li>
                </ul>
              </form>
            </div>
            <div className="form__row mt-20">
              <input
                onClick={GoSelectscd}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--full"
                id="submit"
                value="예약하기"
              />
            </div>
            <div className="d-flex justify-space align-items-center pt-40">
              <h2 className="page__title lh60">
                내 상담({conslist && conslist.length})
              </h2>
            </div>
            <div className="cards cards--11">
              {conslist &&
                conslist.map((item) => (
                  <OkPhoneList
                    item={item}
                    setDelUserIdx={setDelUserIdx}
                    setDelId={setDelId}
                  />
                ))}
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <ConformDelete conformClick={deleteClick} />
    </Fragment>
  );
}

export default Detail;
