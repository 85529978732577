import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SubHeader from "../../../components/SubHeader";
import MemberListItem from "../../../components/MemberListItem";
import ConformPopup from "../../common/popup/ConformPopup";

const PlacingIndividualMember = () => {
  const navigate = useNavigate();
  const { activityIdx, scheduleIdx } = useParams();
  const location = useLocation();
  const isFinished = location.state.isFinished;

  const [totalRows, setTotalRows] = useState(null);
  const [members, setMembers] = useState([]);

  const [selectedAll, setSelectedAll] = useState(false);

  const getOrgMembers = useCallback(async () => {
    const url = `${
      process.env.REACT_APP_USER_SERVICE_API
    }/org_member_list/org/${window.sessionStorage.getItem("idx")}`;

    try {
      const response = await axios.get(url);

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];
        for (let i = 0; i < data.length; i++) {
          ary.push({
            idx: data[i].userIdx,
            name: data[i].name,
            nickname: data[i].nickname,
            img: Object.keys(data[i]).includes("img")
              ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${data[i].img}`
              : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`,
            isSelected: false,
          });
        }

        setTotalRows(totalRows);
        setMembers(ary);
      }
    } catch (e) {
      alert("소속 회원 조회 중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, []);

  const selectAllMember = () => {
    let ary = members;

    for (let i = 0; i < ary.length; i++) {
      ary[i].isSelected = !selectedAll;
    }

    setSelectedAll(!selectedAll);
    setMembers(ary);
  };

  const selectedMember = (idx, selected) => {
    let ary = members;

    for (let i = 0; i < ary.length; i++) {
      if (ary[i].idx === idx) {
        ary[i].isSelected = selected;
      }
    }

    setMembers(ary);
  };

  const onHandleCancel = () => {
    navigate(-1);
  };

  const onHandelAddMembers = () => {
    let nobody = true;

    for (let i = 0; i < members.length; i++) {
      if (members[i].isSelected === true) {
        nobody = false;
        break;
      }
    }

    if (nobody === true) {
      alert("배치할 활동자를 선택해주세요.");
      return false;
    }

    onHandleMembers().then(() => {
      alert("활동자 배치가 완료되었습니다.");
      navigate(-1);
    });
  };

  const onHandleMembers = async () => {
    const url = `${process.env.REACT_APP_USER_SERVICE_API}/act_list/my_activity`;

    for (let i = 0; i < members.length; i++) {
      if (members[i].isSelected === true) {
        let data = {
          activityIdx: activityIdx,
          userIdx: members[i].idx,
          activityScheduleIdx: scheduleIdx,
          orgIdx: "0",
          groupIdx: "0",
          volunteer: isFinished === true ? "Y" : "D",
          time: "0",
          createdUid: window.sessionStorage.getItem("idx"),
          updatedUid: window.sessionStorage.getItem("idx"),
        };

        await requestAddMember(url, data, members[i].name);
      }
    }
  };

  const requestAddMember = async (url, data, name) => {
    try {
      await axios.post(url, data);
    } catch (e) {
      console.log(e);

      if (e.response.status === 401) {
        alert(`${name} 회원은 이미 참여 신청한 일정입니다.`);
      }
    }
  };

  useEffect(() => {
    getOrgMembers();
  }, [getOrgMembers]);

  return (
    <div className="page page--main" data-page="shop">
      <SubHeader title="활동자 지정" />

      <div className="page__content  page_section--with-header-68">
        <div className="page_section">
          <div className="d-flex justify-space align-items-center mb-40">
            <h2>활동자 배치하기({totalRows})</h2>
          </div>
          <div className="form__row mt-40">
            <div className="checkbox-option button--full button">
              <input
                type="checkbox"
                name="checkboxoption1"
                // id="cp81"
                checked={selectedAll}
              />
              <label htmlFor="cp81" onClick={() => selectAllMember()}>
                &#10003; 전체 선택
              </label>
            </div>
          </div>
          <div className="cards cards--14">
            {members.map((member) => (
              <MemberListItem
                member={member}
                selectedMember={selectedMember}
                key={member.idx}
              />
            ))}
          </div>
          <div className="under_button">
            <div className="form__row mt-40">
              <input
                style={{ cursor: "pointer" }}
                type="button"
                name="submit"
                data-popup="conform_cancel"
                className="form__submit button button--gray button--50 open-popup"
                value="취소"
              />
              <input
                style={{ cursor: "pointer" }}
                type="button"
                name="submit"
                className="form__submit button button--main button--50 open-popup"
                data-popup="conform_addMembers"
                value="확인"
              />
            </div>
          </div>
        </div>
        <div className="space-h-100"></div>
        <ConformPopup
          text="선택하신 회원을 추가하시겠습니까?"
          popupType="addMembers"
          conformClick={onHandelAddMembers}
        />
        <ConformPopup
          text="회원 개별 추가를 취소하시겠습니까?"
          popupType="cancel"
          conformClick={onHandleCancel}
        />
      </div>
    </div>
  );
};

export default PlacingIndividualMember;
