import React from "react";
import { Link } from "react-router-dom";

function Tag(props) {
  const { tagName } = props;

  const tag = () => {
    switch (tagName) {
      // 전문가 태그
      case "법률":
      case "법률 전문가":
        return "tag_expert_law";
      case "세무":
      case "세무 전문가":
        return "tag_expert_tax";
      case "법무":
      case "법무 전문가":
        return "tag_expert_legal";
      case "건축":
      case "건축 전문가":
        return "tag_expert_cons";
      case "노무":
      case "노무 전문가":
        return "tag_expert_labor";

      // 활동 태그
      case "온라인":
        return "tag_online";
      case "오프라인":
        return "tag_offline";
      case "비공개":
        return "tag_private";
      case "수요자 모집":
      case "활동 수요자":
        return "tag_bene";
      case "활동자 모집":
      case "활동 참여자":
        return "tag_part";
      case "승인됨":
      case "D":
      case "답변 완료":
      case "상담 완료":
      case "승인 완료":
        return "tag_finished";
      case "주관":
        return "tag_operator";

      // 활동상태 태그
      case "반려":
        return "tag_dismissal";
      case "대기중":
      case "대기 중":
      case "W":
        return "tag_waiting";
      case "참여 취소":
        return "tag_offline";
      default:
        break;
    }
  };

  return (
    <Link to="" className={`caption__category ${tag()}`}>
      {tagName}
    </Link>
  );
}

export default Tag;
