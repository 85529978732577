import React from "react";
import { useNavigate } from "react-router-dom";

// 비공개일 경우에는 상세조회 불가능 하도록
const QnaListItem = ({ no, qnaInfo }) => {
  const navigate = useNavigate();

  const createLink = () => {
    if (window.sessionStorage.getItem("nickname") === qnaInfo.nickName) {
      return `/common/community/qnadetailmine/${qnaInfo.idx}`;
    }

    if (qnaInfo.openStatus === "O")
      return `/common/community/qnadetail/${qnaInfo.idx}`;

    return null;
  };

  return (
    <div
      className="table__row"
      style={{ cursor: "pointer" }}
      onClick={
        window.sessionStorage.getItem("nickname") !== qnaInfo.nickName &&
        qnaInfo.openStatus === "C"
          ? () => alert("비공개 게시물입니다.")
          : () => navigate(createLink())
      }
    >
      <div className="table__section table__section--nr">{no}</div>
      <div className="table__section table__section--grow">{qnaInfo.title}</div>
      <div className="table__section">{qnaInfo.nickName}</div>
      <div className="table__section">{qnaInfo.createDate}</div>
      <div className="table__section">
        {
          {
            O: "공개",
            C: "비공개",
          }[qnaInfo.openStatus]
        }
      </div>
    </div>
  );
};

export default QnaListItem;
