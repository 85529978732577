import React, { useState, useEffect, useRef, useCallback } from "react";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
}

const Timer = ({ time }) => {
  const [hour, setHour] = useState(null);
  const [min, setMin] = useState(null);
  const [sec, setSec] = useState(null);

  const ticktock = useCallback(() => {
    if (sec === 59) {
      if (min === 59) {
        setMin(0);
        setHour(hour + 1);
      } else {
        setMin(min + 1);
      }

      setSec(0);
    } else {
      setSec(sec + 1);
    }
  }, [hour, min, sec]);

  useInterval(() => {
    ticktock();
  }, 1000);

  useEffect(() => {
    setHour(time.hour);
    setMin(time.min);
    setSec(time.sec);
  }, [time]);

  if (hour === null || min === null || sec === null) {
    return null;
  }

  return (
    <div className="card__plan fc_point1">
      {hour < 10 && "0"}
      {hour}:{min < 10 && "0"}
      {min}:{sec < 10 && "0"}
      {sec}
    </div>
  );
};

export default Timer;
