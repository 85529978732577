import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import FormatDate from "./FormatDate";
import { onHandleImgError } from "../util/util-function";

function OnlineconsultItem(props) {
  const { item, expertdetail } = props;

  const navigate = useNavigate();

  const tagView = () => {
    switch (expertdetail && expertdetail.area) {
      case "법률":
        return "law";
      case "세무":
        return "tax";
      case "법무":
        return "legal";
      case "건축":
        return "cons";
      case "노무":
        return "labor";
      default:
        return "law";
    }
  };

  const [onlineconuser, setonlineconuser] = useState();
  // 온라인 상담 예약자 개인정보 조회
  useEffect(() => {
    if (!Object.keys(item).includes("nickname")) {
      let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${item.createdUid}`;
      axios
        .get(url)
        .then((res) => {
          setonlineconuser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [item]);

  const gookonlineanswer = () => {
    let url = `/sole/profileexpert/okonlinereportd/${item.idx}`;
    if (item.consultationStatus === "W") {
      let gourl = `/sole/profileexpert/okonlineanswer/${item.idx}`;
      navigate(gourl, {
        state: {
          params: item.idx,
          onlineconuser: Object.keys(item).includes("nickname")
            ? item
            : onlineconuser,
          expertdetail: expertdetail,
          item: item,
        },
      });
    } else {
      navigate(url, {
        state: {
          params: item.idx,
          onlineconuser: Object.keys(item).includes("nickname")
            ? item
            : onlineconuser,
          expertdetail: expertdetail,
          item: item,
        },
      });
    }
  };

  return (
    <Fragment>
      <div
        style={{ cursor: "pointer" }}
        className="card card--style-inline card--style-inline-bg card--style-round-corners " onClick={gookonlineanswer}
      >
        <div className="user-details__thumb">
          <img
            src={
              Object.keys(item).includes("imageUrl")
                ? `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${item.imageUrl}`
                : `${process.env.PUBLIC_URL}/assets/images/photos/img-p-sole.jpg`
            }
            onError={(e) => onHandleImgError(e, "U")}
            alt=""
          />
        </div>
        <div className="card__details">
          <div onClick={gookonlineanswer}>
            {item.openStatus && item.openStatus === "O" ? (
              <h4 className="card__username fc_point1">{item.title}</h4>
            ) : (
              <h4 className="card__username fc_point1">[비공개]{item.title}</h4>
            )}
            {/* <Link to={`/sole/otherprofile/sole/${item.userIdx}`}> */}
            <h4 className="card__title">
              {Object.keys(item).includes("nickname")
                ? item.nickname
                : onlineconuser && onlineconuser.nickname}
            </h4>
            {/* </Link> */}
            <p className="card__info">
              <FormatDate datetime={item && item.createdAt} />
            </p>
            {item.consultationStatus && item.consultationStatus === "W" ? (
              <>
                <a className={`caption__category tag_expert_${tagView()}`}>
                  {item.area}
                </a>
                <a className="caption__category tag_waiting">대기 중</a>
              </>
            ) : (
              <>
                <a className={`caption__category tag_expert_${tagView()}`}>
                  {item.area}
                </a>
                <a className="caption__category tag_finished">답변 완료</a>
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default OnlineconsultItem;
