import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import Tag from "../../../components/Tag";
import axios from "axios";
import FormatDate from "../../../components/FormatDate";
import CheckLoginInfo from "../../../services/login-validation";

function OkOnlineReportD() {
  const location = useLocation();
  const navigate = useNavigate();
  const { idx } = useParams();
  const expertdetail = location.state && location.state.expertdetail;
  const onlineconuser = location.state && location.state.onlineconuser;
  const item = location.state && location.state.item;

  const tag = () => {
    return <Tag tagName={expertdetail && expertdetail.area} />;
  };

  const [reply, setReply] = useState();

  // 수정
  const ModifyClick = () => {
    navigate(`/sole/profileexpert/okonlineanswer/${idx}`, {
      state: {
        params: idx,
        reply: reply,
        expertdetail: expertdetail,
        onlineconuser: onlineconuser,
        item: item,
      },
    });
  };

  // 취소
  const CancleClick = () => {
    navigate(`/sole/profileexpert/expert/${sessionStorage.idx}`);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        let url = `${process.env.REACT_APP_USER_SERVICE_API}/online_consultation/${idx}`;
        axios
          .get(url)
          .then((res) => {
            setReply(res.data);
          })
          .catch((err) => {
            console.log(err);
          });

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="문의" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center pt-40">
              <p className="card__info">
                <FormatDate datetime={item && item.createdAt} />
              </p>
            </div>
            <div className="fieldsets">
              <ul className="custom-listing mb-20">
                <li className="mt-20">
                  <h2>{item && item.title}</h2>
                </li>
                <li>
                  <span>이용자 : </span>
                  {onlineconuser && onlineconuser.nickname}(
                  {onlineconuser && onlineconuser.name})
                </li>
                <li>
                  <span>상담 분야 : </span>
                  {tag()}
                </li>
                <li>
                  <span>
                    상세 : <br />
                  </span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item && item.content,
                    }}
                  />
                </li>
              </ul>
            </div>
            <div className="d-flex justify-space align-items-center pt-40">
              <h2 className="page__title mb-0">답변</h2>
              <p className="card__info">
                <FormatDate datetime={reply && reply.answeredAt} />
              </p>
            </div>
            <div className="fieldsets">
              <ul className="custom-listing mb-20">
                <li>
                  <span>전문가 : </span>
                  {sessionStorage && sessionStorage.nickname}
                </li>
                <li>
                  <span>
                    답변 : <br />
                  </span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: reply && reply.answer,
                    }}
                  />
                </li>
              </ul>
            </div>
            <div className="form__row mt-40">
              <input
                onClick={CancleClick}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                data-popup="conform_cancle"
                className="form__submit button button--gray button--50 open-popup"
                id="submit"
                value="취소"
              />
              <input
                onClick={ModifyClick}
                style={{ cursor: "pointer" }}
                type="submit"
                name="submit"
                className="form__submit button button--main button--50"
                id="submit"
                value="수정하기"
              />
            </div>
            <div className="space-h-100" />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <Navigationbar />
    </Fragment>
  );
}

export default OkOnlineReportD;
