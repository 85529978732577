import axios from "axios";
import React, {Fragment, useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

import SockJsClient from "react-stomp";
import chatAPI from "../../../services/chatapi";

import {convertTimeStamp} from "../../../util/convert-date-function";

import ChatHeader from "../../../components/ChatHeader";
import ChatInput from "../../../components/ChatInput";
import ChatItem from "../../../components/ChatItem";
import Panel from "../../../components/Panel";
import PopupListChat from "../../common/popup/PopupListChat";
import CheckLoginInfo from "../../../services/login-validation";

const SCOKET_URL = `${process.env.REACT_APP_CHAT_SERVICE_API}/ws-chat`;

let orgTitle;

const ChatD = (props) => {
    const {type} = useParams();
    const {state} = useLocation();

    let key = 0;

    const [chatRoomInfo, setChatRoomInfo] = useState(null);
    const [messages, setMessages] = useState([]);
    const [chatType, setChatType] = useState(null);

    const onMessageReceived = (msg) => {
        console.log("received Msg : ",msg);
        if (msg.topic === chatRoomInfo.topic) {
            let msgInfo = Object.keys(msg).includes("fileUrl") ? {
                senderIdx: msg.sender_idx,
                name: msg.sender,
                content: msg.content,
                created_time: convertTimeStamp(msg.timestamp),
                img: msg.senderImage,
                fileUrl: msg.fileUrl,
                fileName: msg.fileName,
                fileSize: msg.fileSize,
            } : {
                senderIdx: msg.sender_idx,
                name: msg.sender,
                content: msg.content,
                created_time: convertTimeStamp(msg.timestamp),
                img: msg.senderImage,
            };

            if (msg.sender === window.sessionStorage.getItem("nickname")) {
                msgInfo = {
                    ...msgInfo,
                    status: "sent",
                };
            } else {
                msgInfo = {
                    ...msgInfo,
                    status: "received",
                };
            }

            setMessages(messages.concat(msgInfo));
        }
    };

    const onSendMessage = async (msgText, files = null) => {
        try {
            chatAPI.sendMessage(
                window.sessionStorage.getItem("idx"),
                window.sessionStorage.getItem("nickname"),
                msgText,
                chatRoomInfo.topic,
                chatAPI.setSenderType(type, chatRoomInfo.title, orgTitle),
                files
            );
        } catch (e) {
            alert("메시지를 보내는 중, 오류가 발생하였습니다.");
            console.log(e);
        }
    };

    const [showOpion, setShowOption] = useState(false);
    const toggleChatOption = () => {
        setShowOption(!showOpion);
    };

    const [hideContent, setHideContent] = useState(true);

    useEffect(() => {
        CheckLoginInfo()
            .then(() => {
                getChatHistory();
                getOrgTitle();
                setChatTypeByType();
                setHideContent(false);
            })
            .catch((e) => {
                console.log(e);
            });

        const setParams = (type) => {
            switch (type) {
                case "a2s":
                    return {
                        sender: state.activityId,
                        target: state.userId,
                        sch: state.scheduleId,
                    };
                case "o2a":
                    return {
                        sender: state.userId,
                        target: state.activityId,
                    };
                case "o2s":
                    return {
                        sender: state.orgId,
                        target: state.userId,
                    };
                case "o2o":
                    return {
                        sender: state.senderOrgId, // 기관 Id
                        target: state.targetOrgId, //  기관 id
                    };
                case "a2g":
                    return {
                        sender: state.activityId, // 활동 id
                        target: state.groupId, // 그룹 아이디
                    };
                case "o2g":
                    return {
                        sender: state.orgId,
                        target: state.groupId,
                    };
                case "o2all":
                    return {
                        sender: state.orgId,
                        target: state.orgId,
                        senderType: state.senderType,
                    };
                default:
                    return {
                        sender: 0, // 기관 Id
                        target: 0, //  기관 id
                    };
            }
        };

        const getChatHistory = async () => {
            const url = `${process.env.REACT_APP_CHAT_SERVICE_API}${Object.keys(state).includes("uri")
                ? state.uri
                : `/api/v1/chat/org/${type}`
            }`;

            try {
                const response = await axios.get(url, {
                    params: Object.keys(state).includes("uri") ? null : setParams(type),
                });

                if (response.status === 200) {
                    const {myImg, topic, title, history, myId} = response.data;

                    setChatRoomInfo({
                        title: title,
                        topic: topic,
                    });

                    let ary = [];
                    let historyKeys;
                    let loginUserIdx = window.sessionStorage.getItem("idx");
                    let senderKey = myId === loginUserIdx ? "me" : "other";
                    let receiverKey = myId === loginUserIdx ? "other" : "me";
                    let historyObj;

                    for (let i = 0; i < history.length; i++) {
                        // historyKeys = Object.keys(history[i]);

                        // for (let j = 0; j < historyKeys.length; j++) {
                        //
                        //     historyObj = history[i][historyKeys[j]];

                            // if (historyKeys[j] === senderKey) {
                            //     ary.push(Object.keys(historyObj).includes("fileUrl") ? {
                            //         status: "sent",
                            //         img: myImg,
                            //         content: historyObj.msg,
                            //         created_time: historyObj.date,
                            //         fileUrl: historyObj.fileUrl,
                            //         fileName: historyObj.fileName,
                            //         fileSize: historyObj.fileSize,
                            //     } : {
                            //         status: "sent",
                            //         img: myImg,
                            //         content: historyObj.msg,
                            //         created_time: historyObj.date,
                            //     });
                            // } else if (historyKeys[j] === receiverKey) {
                            //     ary.push(Object.keys(historyObj).includes("fileUrl") ? {
                            //             status: "received",
                            //             name: historyObj.id,
                            //             img: historyObj.img,
                            //             content: historyObj.msg,
                            //             created_time: historyObj.date,
                            //             senderIdx: historyObj.idx,
                            //             fileUrl: historyObj.fileUrl,
                            //             fileName: historyObj.fileName,
                            //             fileSize: historyObj.fileSize,
                            //         } : {
                            //             status: "received",
                            //             name: historyObj.id,
                            //             img: historyObj.img,
                            //             content: historyObj.msg,
                            //             created_time: historyObj.date,
                            //             senderIdx: historyObj.idx,
                            //         }
                            //     );
                            // }

                        // }

                        historyObj = history[i].msg;

                        ary.push(Object.keys(historyObj).includes("fileUrl") ? {
                            status: historyObj.idx === loginUserIdx ? "sent" : "received",
                            name: historyObj.id,
                            img: historyObj.img,
                            content: historyObj.msg,
                            created_time: historyObj.date,
                            senderIdx: historyObj.idx,
                            fileUrl: historyObj.fileUrl,
                            fileName: historyObj.fileName,
                            fileSize: historyObj.fileSize,
                        } : {
                            status: historyObj.idx === loginUserIdx ? "sent" : "received",
                            name: historyObj.id,
                            img: historyObj.img,
                            content: historyObj.msg,
                            created_time: historyObj.date,
                            senderIdx: historyObj.idx,
                        });
                    }
                    setMessages(ary);
                }
            } catch (e) {
                alert("채팅 기록을 불러오는 중, 오류가 발생하였습니다.");
                console.log(e);
            }
        };

        // 기관명 조회
        const getOrgTitle = () => {
            let url = `${process.env.REACT_APP_USER_SERVICE_API}/org/${sessionStorage.idx}`;
            axios.get(url)
                .then((res) => {
                    orgTitle = res.data.orgTitle;
                })
                .catch((err) => {console.log(err);});
        };

        const setChatTypeByType = () => {
            switch (type) {
                case "o2all":
                case "o2g":
                case "s2g":
                    setChatType("chatall");
                    break;
                case "o2s":
                case "o2o":
                case "s2o":
                    setChatType("chatds");
                    break;
                case "a2all":
                case "a2g":
                    setChatType("chatg");
                    break;
                case "a2s":
                case "o2a":
                case "s2a":
                    setChatType("chatgs");
                    break;
                default:
                    setChatType(null);
                    break;
            }
        };
    }, [state, type]);

    if (hideContent) {
        return null;
    }

    return (
        <Fragment>
            {chatRoomInfo && (
                <SockJsClient
                    url={SCOKET_URL}
                    topics={["/topic/group"]}
                    onMessage={(msg) => onMessageReceived(msg)}
                    debug={false}
                />
            )}
            <Panel/>
            <div className="page page--main" data-page="chat">
                {chatRoomInfo && (
                    <ChatHeader
                        title={
                            type === "s2o"
                                ? chatRoomInfo.title.split("-")[1]
                                : chatRoomInfo.title.split("-")[0]
                        }
                        popup={chatType}
                        toggleChatOption={toggleChatOption}
                    />
                )}
                {/* PAGE CONTENT */}
                <div className="page__content page_section page_section--with-header-68">
                    <ul className="chat">
                        {messages.map((msgInfo) => (
                            <ChatItem folder="org" msgInfo={msgInfo} key={key++}/>
                        ))}
                    </ul>
                </div>
            </div>

            {/* PAGE END */}
            {/* Bottom navigation */}
            <div id="bottom-toolbar-conversations" className="bottom-toolbar">
                <ChatInput
                    onSendMessage={onSendMessage}
                    bottom={messages.length === 0 ? 0 : -65}
                />
            </div>

            {showOpion && (
                <PopupListChat
                    popupType={chatType}
                    topic={chatRoomInfo && chatRoomInfo.topic}
                    toggleChatOption={toggleChatOption}
                    type={type}
                />
            )}
        </Fragment>
    );
};

export default ChatD;
