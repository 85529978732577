import React, { Fragment, useContext } from "react";
import ScheduleContext from "../context/Schedule.context";

function SelectedTime({
  schedule,
  etc,
  deleteClick,
  mode,
  setDelScheduleId,
  getEditScheduleInfo,
}) {
  let { setSelectedSchedule } = useContext(ScheduleContext);

  const setSelectedScheduleClickHandler = () => {
    if (etc !== undefined && etc === "no") {
      setSelectedSchedule(schedule[0]);
    } else {
      setSelectedSchedule(schedule);
    }
  };

  const chkschedule = () => {
    if (schedule !== null) {
      return (
        <div
          className="swiper-wrapper mb-24 bg-gray"
          style={{ transform: "translate3d(0px, 0px, 0px)" }}
        >
          <div className="swiper-slide cart__item-details swiper-slide-active">
            {etc && etc === "no" ? (
              <h4 className="cart__item-title">
                {schedule[0].consultationDate} {schedule[0].consultationTime}
              </h4>
            ) : (
              <h4 className="cart__item-title">
                {schedule.activityDate} {schedule.activityTime} ( 필요인원{" "}
                {schedule.recruitNum === 0 ? "제한 없음" : schedule.recruitNum}{" "}
                )
              </h4>
            )}
            <div className="cart__item-qty">
              {mode === "addActivity" ? (
                <div
                  onClick={() => getEditScheduleInfo(schedule)}
                  // onclick="location.href='../profile-add-act/schedule-edit.html'"
                  style={{ cursor: "pointer" }}
                  className="button button--small button--secondary float-right ml-10"
                >
                  수정
                </div>
              ) : (
                <div
                  className="button button--small button--secondary float-right ml-10 open-popup"
                  data-popup="conform_delete"
                  onClick={() =>
                    mode === "register"
                      ? deleteClick(schedule.id)
                      : mode === "addActivity"
                        ? setDelScheduleId(schedule.id)
                        : setSelectedScheduleClickHandler()
                  }
                >
                  삭제
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return <Fragment>{chkschedule()}</Fragment>;
}

export default SelectedTime;
