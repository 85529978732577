import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { maskingName, onHandleImgError } from "../util/util-function";
import Tag from "./Tag";

function OkOnlineList(props) {
  // type
  // 답변완료 : D
  // 대기중 : W

  // expert
  // 법률, 세무, 법무, 건축, 노무

  // page
  // detail, history
  const { item, ok, deleteClickHandler } = props;

  const navigate = useNavigate();
  const [user, setUser] = useState({
    idx: "",
    nickname: "",
    name: "전문가 배정중",
    createdAt: "",
    phoneNum: "",
  });

  const detailClickHandler = () => {
    // let url = `/sole/okonline/${page === "mine" ? "other" : (item && item.area === "W" ? "detailad" : "detaildefault")}`;
    let url = `/sole/okonline/detailad/${item && item.idx}`;
    if (
      item.openStatus === "O" ||
      item.createdUid === sessionStorage.idx ||
      item.openStatus === "N"
    ) {
      navigate(url, {
        state: {
          params: item.idx,
          item: item,
        },
      });
    } else {
      alert("비공개 게시글입니다.");
    }
  };

  useEffect(() => {
    let url = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/users/${
      item && item.expertIdx
    }`;
    axios
      .get(url)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const convertDate = (inputDate) => {
    if (inputDate !== "" && inputDate !== undefined) {
      const date = new Date(inputDate);
      // console.log(date);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let min = date.getMinutes();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour > 12 ? "오후 " + (hour - 12) : "오전 " + hour;

      // return `${year}.${month}.${day}
      //         ${hour}시 ${min}분`;
      return `${year}.${month}.${day}`;
    } else {
      return ``;
    }
  };

  // 프로필 이미지
  const defaultImgPath = "/assets/images/photos/img-p-expert.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (item) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: item && item.expertIdx,
            imageStatus: "U",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  // 디폴트 이미지로 변경
  const changeDefaultImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <div
      style={{ cursor: "pointer", position: "relative", zIndex: 1 }}
      className="card card--style-inline card--style-inline-bg card--style-round-corners "
    >
      <div className="user-details__thumb">
        <img src={imgPath} alt="" onError={(e) => onHandleImgError(e, "E")} />
      </div>
      <div className="card__details" onClick={detailClickHandler}>
        {item && item.openStatus === "O" ? (
          <h4 className="card__username fc_point1">{item && item.title}</h4>
        ) : (
          <h4 className="card__username fc_point1">
            [비공개]{item && item.title}
          </h4>
        )}
        <Link to="">
          <h4 className="card__title">전문가 : {maskingName(user.name)}</h4>
        </Link>
        {ok && ok === "online" ? null : <p className="card__info">{}</p>}
        <p className="card__info">{item && convertDate(item.createdAt)}</p>
        {/* <p className="card__info">{user && user.createdAt}</p> */}
        <Tag tagName={item && item.area} />
        <Link
          to=""
          className={`caption__category tag_${
            item && item.consultationStatus === "W" ? "waiting" : "finished"
          }`}
        >
          {item && item.consultationStatus === "W" ? "대기 중" : "답변 완료"}
        </Link>
        {item &&
        item.consultationStatus === "W" &&
        item.userIdx === sessionStorage.idx ? (
          <div
            className="button button--small button--secondary float-right ml-10 open-popup"
            onClick={() => deleteClickHandler(item.userIdx, item.id)}
            style={{ position: "relative", zIndex: 100 }}
          >
            삭제
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default OkOnlineList;
