import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tag from "./Tag";

function ActHistoryList(props) {
  // type (profile, otherprofile)
  // otherprofile은 버튼 hidden
  const { folder, actHistory, type, setSelActHistory } = props;
  const navigate = useNavigate();

  const [status, setStatus] = useState("");

  const updateSelActHistory = () => {
    setSelActHistory(actHistory.idx);
  };

  const btnView = () => {
    if (type === "otherprofile" || setSelActHistory === undefined) {
      return "";
    }

    if (actHistory.volunteer === "D") {
      return (
        <Fragment>
          <div
            className="button button--small button--secondary float-right ml-10 open-popup"
            data-popup="conform_absent"
            onClick={updateSelActHistory}
          >
            결석
          </div>
          <div
            className="button button--small button--secondary float-right ml-10 open-popup"
            data-popup="conform_completion"
            onClick={updateSelActHistory}
          >
            완료하기
          </div>
        </Fragment>
      );
    } else if (actHistory.volunteer === "Y" || actHistory.volunteer === "F") {
      if (actHistory.activityReport === "Y") {
        // 그룹 관리자 이거나, 일반 참여자 일 때
        if (
          actHistory.groupAdminStatus === "A" ||
          actHistory.groupAdminStatus === "0"
        ) {
          return (
            <Fragment>
              <div
                onClick={() =>
                  navigate(`/${folder}/schedule/report/${actHistory.idx}`)
                }
                style={{ cursor: "pointer" }}
                className="button button--small button--secondary float-right ml-10"
              >
                {actHistory.volunteer === "Y"
                  ? "활동 내역 보완"
                  : "활동 내역 조회"}
              </div>
            </Fragment>
          );
        }
        // 그룹 참가자이지만 관리자가 아닌 경우, 조회만 가능하도록
        else {
          return (
            <Fragment>
              <div
                onClick={() =>
                  navigate(`/sole/schedule/read-report/${actHistory.idx}`)
                }
                style={{ cursor: "pointer" }}
                className="button button--small button--secondary float-right ml-10"
              >
                활동 내역 조회
              </div>
            </Fragment>
          );
        }
      } else {
        // 그룹 관리자 이거나, 일반 참여자 일 때만 활동 내역 작성 가능
        if (
          actHistory.groupAdminStatus === "A" ||
          actHistory.groupAdminStatus === "0"
        ) {
          return (
            <Fragment>
              <div
                onClick={() =>
                  navigate(
                    `/${folder}/schedule/add-report/${actHistory.activityScheduleIdx}`
                  )
                }
                style={{ cursor: "pointer" }}
                className="button button--small button--secondary float-right ml-10"
              >
                활동 내역 작성
              </div>
            </Fragment>
          );
        }
      }
    }
  };

  const tagView = () => {
    switch (status) {
      case "rejected":
        return (
          <Fragment>
            <Tag tagName="반려" />
          </Fragment>
        );
      case "request":
        return <Fragment></Fragment>;
      case "waiting":
        return (
          <Fragment>
            <Tag tagName="대기 중" />
          </Fragment>
        );
      default:
        break;
    }
  };

  const dateform = (date) => {
    const changedate = new Date(date);
    let year = changedate.getFullYear();
    let month = changedate.getMonth() + 1;
    let day = changedate.getDate();

    month = month >= 10 ? month : "0" + month;
    day = day >= 10 ? day : "0" + day;

    return `${year}년 ${month}월 ${day}일`;
  };

  const convertDate = (inputDate) => {
    if (inputDate !== "" && inputDate !== undefined) {
      const date = new Date(inputDate);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let min = date.getMinutes();

      month = month >= 10 ? month : "0" + month;
      day = day >= 10 ? day : "0" + day;
      hour = hour > 12 ? "오후 " + (hour - 12) : "오전 " + hour;

      return `${year}.${month}.${day} 
              ${hour}시 ${min}분`;
    } else {
      return ``;
    }
  };

  const onClickDeleteActivity = () => {
    alert("삭제된 활동입니다.");
  };

  const defaultImgPath = "/assets/images/photos/img-default.jpg";
  const [imgPath, setImgPath] = useState(defaultImgPath);

  // 파일명 조회
  useEffect(() => {
    if (actHistory) {
      const URL = `${process.env.REACT_APP_USER_UPLOAD_SERVICE_API}/file/name`;
      axios
        .get(URL, {
          params: {
            pkIdx: actHistory && actHistory.activityIdx,
            imageStatus: "A",
          },
        })
        .then((res) => {
          // console.log("이미지명");
          // console.log(res.data);

          // 파일 조회시 업로드 이미지 Path Set
          if (res.data !== "") {
            setImgPath(
              `${process.env.REACT_APP_USER_SERVICE_IMG_PATH}/${res.data.pkIdx}/${res.data.imageStatus}/${res.data.fileName}`
            );
          }
        });
    }
  }, []);

  const chageDefualtImg = () => {
    setImgPath(defaultImgPath);
  };

  return (
    <Fragment>
      <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
        <div className="img-details__thumb">
          <img src={imgPath} alt="" onError={chageDefualtImg} />
        </div>
        <div className="card__details">
          <Link
            to={
              actHistory &&
              actHistory.isDeleted === "N" &&
              `/${folder}/activity/detailpart/${actHistory.activityIdx}`
            }
            onClick={() => {
              actHistory &&
                actHistory.isDeleted === "Y" &&
                onClickDeleteActivity();
            }}
          >
            <h4 className="card__title">{actHistory && actHistory.title}</h4>
          </Link>
          <Tag tagName="오프라인" />
          <p className="card__info">
            {actHistory &&
              `${convertDate(actHistory.createdAt)}/${actHistory.address1} ${
                actHistory.address2
              }`}
          </p>
          <Link to="">
            <h4 className="card__username fc_point1">
              {actHistory && actHistory.userNickName}
            </h4>
          </Link>
        </div>
      </div>
      <div className="card card--style-inline card--style-inline-bg card--style-round-corners margin-t-m40">
        <div className="card__details">
          <h2 className="fc_point1">
            {actHistory &&
              `${dateform(actHistory.activityDate)} ${actHistory.activityTime}`}
          </h2>
          <p className="card__list">
            활동인원 :{" "}
            <Link to="">
              {actHistory &&
                `참여(지정) ${actHistory.recruitedNum}명 / ${
                  actHistory.recruitNum === 0
                    ? "제한없음"
                    : `필요인원 ${actHistory.recruitNum}명`
                } (${actHistory.recruit})`}
            </Link>
          </p>
          <p className="card__list">{`활동시간 : ${actHistory.activityTime} ~ ${actHistory.activityTimeEnd}`}</p>
          <p className="card__list">
            수요자 :{" "}
            <Link
              to={`/${folder}/otherprofile/org/${
                actHistory && actHistory.userIdx
              }`}
            >
              {actHistory && actHistory.orgTitle}
            </Link>
          </p>
          <p className="card__list">
            상태 :{" "}
            {
              {
                F: "완료",
                Y: "참여",
                D: "미참여",
                N: "결석",
              }[actHistory && actHistory.volunteer]
            }
          </p>
          {tagView()}
          {btnView()}
        </div>
      </div>
    </Fragment>
  );
}

export default ActHistoryList;
