import React, { Fragment, useEffect, useState } from "react";

import CoinActList from "../../../components/CoinActList";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import ConformPopup from "../../common/popup/ConformPopup";

function AddActScList(props) {
  const { toggleModal, getScCoinId, scCoinId } = props;

  const [totalRows, setTotalRows] = useState(null);
  const [scCoinActList, setScCoinActList] = useState(null);

  const [selectedId, setSelectedId] = useState(null);

  const selectAct = (select) => {
    setSelectedId(select);
  };

  const onClickSave = () => {
    getScCoinId(selectedId);
    toggleModal();
    // 확인 후 실행 코드
  };

  const onClickCancle = () => {
    toggleModal();
    // 취소 후 실행 코드
  };

  useEffect(() => {
    setTotalRows(5);
    setScCoinActList([
      {
        id: 1,
        title: "서초 코인 활동 제목 1",
        address: "서초 코인 활동 주소 1",
        activityDate: "2022-02-07",
        coin: 2,
      },
      {
        id: 2,
        title: "서초 코인 활동 제목 2",
        address: "서초 코인 활동 주소 2",
        activityDate: "2022-02-06",
        coin: 3,
      },
      {
        id: 3,
        title: "서초 코인 활동 제목 3",
        address: "서초 코인 활동 주소 3",
        activityDate: "2022-02-03",
        coin: 1,
      },
      {
        id: 4,
        title: "서초 코인 활동 제목 4",
        address: "서초 코인 활동 주소 4",
        activityDate: "2022-02-01",
        coin: 5,
      },
      {
        id: 5,
        title: "서초 코인 활동 제목 5",
        address: "서초 코인 활동 주소 5",
        activityDate: "2022-02-09",
        coin: 2,
      },
    ]);
    setSelectedId(scCoinId);
  }, [scCoinId]);

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="서초코인 활동 목록" toggleModal={toggleModal} />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68 ">
          {/* SLIDER SIMPLE */}
          <div className="fieldsets mt-40">
            <div className="page__title-bar mt-48">
              <h2>활동 목록({totalRows})</h2>
            </div>
          </div>
          <div className="sub-fieldset">
            {scCoinActList &&
              scCoinActList.map((actInfo) => (
                <CoinActList
                  actInfo={actInfo}
                  selectAct={selectAct}
                  key={actInfo.id}
                  selectedId={selectedId}
                />
              ))}
          </div>
          <div className="form__row mt-40">
            <input
              type="submit"
              name="submit"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              id="submit"
              value="취소"
            />
            <input
              type="submit"
              name="submit"
              className="form__submit button button--main button--50 open-popup"
              data-popup={selectedId !== null ? "conform_save" : null}
              id="submit"
              value="확인"
            />
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      <div id="bottom-toolbar" className="bottom-toolbar"></div>
      <ConformPopup
        popupType="save"
        text="해당 항목을 선택하시겠습니까?"
        conformClick={onClickSave}
      />
      <ConformPopup
        popupType="cancle"
        text="취소하시겠습니까?"
        conformClick={onClickCancle}
      />
      {/* <Navigationbar /> */}
    </Fragment>
  );
}

export default AddActScList;
