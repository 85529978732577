import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

import { convertDashToDot } from "../util/convert-date-function";

function ScheduleItem({ folder, scheduleInfo, isToday }) {
  const navigate = useNavigate();
  const setTag = () => {
    let ary = [];

    // 참여 기관
    if (scheduleInfo.orgType === "O") {
      if (
        scheduleInfo.orgIdx === parseInt(window.sessionStorage.getItem("idx"))
      ) {
        ary.push(
          <span
            className="caption__category tag_operator pdrl-5"
            key="operator"
          >
            주관
          </span>
        );
      } else {
        ary.push(
          <span className="caption__category tag_bene" key="bene">
            수요기관
          </span>
        );
      }
    } else {
      if (scheduleInfo.partType !== "X") {
        ary.push(
          <span className="caption__category tag_part" key="part">
            활동 참여자
          </span>
        );
      } else if (scheduleInfo.beneType !== "X") {
        ary.push(
          <span className="caption__category tag_bene" Key="bene">
            활동 수요자
          </span>
        );
      }
    }
    return ary;
  };

  const setLink = () => {
    // if (scheduleInfo.orgType === "O") {
    //   return `profile/actdetail/${scheduleInfo.actId}`;
    // } else {
    //   if (scheduleInfo.partType !== "X") {
    //     return `activity/detailpart/${scheduleInfo.actId}`;
    //   } else {
    //     return `activity/detailpart/${scheduleInfo.actId}`;
    //   }
    // }

    if (scheduleInfo.orgType === "O") {
      if (
        scheduleInfo.orgIdx === parseInt(window.sessionStorage.getItem("idx"))
      ) {
        return `profile/actdetail/${scheduleInfo.actId}`;
      } else {
        return `activity/detailbene/${scheduleInfo.actId}`;
      }
    } else {
      if (scheduleInfo.partType !== "X") {
        return `activity/detailpart/${scheduleInfo.actId}`;
      } else if (scheduleInfo.beneType !== "X") {
        return `activity/detailbene/${scheduleInfo.actId}`;
      }
    }
  };

  return (
    <Fragment>
      <div className="card card--style-inline card--style-inline-bg card--style-round-corners">
        <div className="img-details__thumb">
          <img src={scheduleInfo.img} alt="" />
        </div>
        <div className="card__details">
          {setTag()}
          {
            {
              O: <span className="caption__category tag_online">온라인</span>,
              F: (
                <span className="caption__category tag_offline">오프라인</span>
              ),
            }[scheduleInfo.actType]
          }

          <Link to={`/${folder}/${setLink()}`}>
            <h4 className="card__list mt-5 pb-0 fc_point1">
              {scheduleInfo.title}
            </h4>
          </Link>
          <p className="card__list pb-0">
            {convertDashToDot(scheduleInfo.actDate)} {scheduleInfo.actTime}
          </p>
          <p className="card__info">주관 : {scheduleInfo.orgTitle}</p>
          <p className="card__info">
            활동 인원 : 참여(지정) {scheduleInfo.joinNum}명 /{" "}
            {scheduleInfo.recruitNum === 0
              ? "제한없음"
              : `필요인원 ${scheduleInfo.recruitNum}명`}
          </p>
          <p className="card__info">
            활동 방식 :{" "}
            {
              {
                Q: "QR 코드 인증 참여",
                A: "자동 참여",
              }[scheduleInfo.joinType]
            }
          </p>
          {scheduleInfo.orgType === "O" &&
            scheduleInfo.orgIdx ===
              parseInt(window.sessionStorage.getItem("idx")) && (
              <div
                className="button button--small button--gray float-right mt-m-40"
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/org/profile/listactivity/${scheduleInfo.actId}/${scheduleInfo.id}`
                  )
                }
              >
                일정 보기
              </div>
            )}
          {isToday &&
            scheduleInfo.orgType === "O" &&
            scheduleInfo.orgIdx !==
              parseInt(window.sessionStorage.getItem("idx")) &&
            scheduleInfo.joinType === "Q" && (
              <div
                className="button button--small button--gray float-right mt-m-40"
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/org/activity/detailbene/qr/${scheduleInfo.actId}/${scheduleInfo.id}`
                  )
                }
              >
                QR코드 보기
              </div>
            )}
        </div>
      </div>
    </Fragment>
  );
}

export default ScheduleItem;
