import React, { Fragment, useState, useRef } from "react";
// import useConfirm from "../util/cofirm-alert";
import useConfirm from "../util/confirm";
import TextareaAutosize from "react-textarea-autosize";

const ChatInput = ({ onSendMessage, bottom }) => {
  const fileRef = useRef();
  const { confirm } = useConfirm();

  const [text, setText] = useState("");

  const onChangeText = (inputText) => {
    if (inputText !== "\n") setText(inputText);
  };

  const onClickAddFileBtn = (e) => {
    e.preventDefault();

    fileRef.current.click();
  };

  const onSubmit = () => {
    if (text !== "") {
      onSendMessage(text);
      setText("");
    } else {
      return;
    }
  };

  const onChangeFiles = (e) => {
    const files = e.target.files[0];

    confirm(`파일(${files?.name})을 전송하시겠습니까?`, () =>
      onSendMessage("", files)
    );
  };

  return (
    <Fragment>
      <div
        className="bottom-navigation-chat chat_f_section"
        style={{ bottom: `${bottom}px` }}
      >
        <form className="chat-form">
          <TextareaAutosize
            type="textarea"
            className="chat-form__textarea"
            placeholder="메시지를 입력해주세요."
            value={text}
            onChange={(e) => onChangeText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onSubmit();
              }
            }}
            style={{
              overflow: "auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          />
          <span className="chat-form__submit" onClick={() => onSubmit()}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icons/blue/send.svg`}
              alt=""
              title="true"
            />
          </span>
          <span className="chat-form__add-file" onClick={onClickAddFileBtn}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icons/blue/plus-bold.svg`}
              alt=""
              title="true"
            />
          </span>
          <input
            type={"file"}
            style={{ display: "none" }}
            ref={fileRef}
            onChange={onChangeFiles}
          />
        </form>
      </div>
    </Fragment>
  );
};

export default ChatInput;
