import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import SubHeader from "../../../components/SubHeader";
import Panel from "../../../components/Panel";
import CheckLoginInfo from "../../../services/login-validation";

function HelpDetail() {
  const { communityIdx } = useParams();

  const [title, setTitle] = useState(null);
  const [imgList, setImgList] = useState(null);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getGuideInfo();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });

    const getGuideInfo = async () => {
      const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community/userguide/${communityIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const { title, filesCount, files } = response.data;

          setTitle(title);

          let id = 0;
          let ary = [];
          for (let i = 0; i < filesCount; i++) {
            ary.push({
              id: id++,
              imgSrc: `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/community/${files[i].folder}/files/${files[i].filename}`,
            });
          }
          setImgList(ary);
        }
      } catch (e) {
        alert("사용자 가이드 상세조회 중, 오류가 발생하였습니다.");
        console.log(e);
      }
    };
  }, [communityIdx]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page-section page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="사용자 가이드" />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section">
            <div className="d-flex justify-space align-items-center"></div>
            {title && (
              <div className="fieldsets">
                <ul className="custom-listing">
                  <li className="">
                    <h2>{title}</h2>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {imgList && (
            <div className="page_section">
              <div className="d-flex justify-space align-items-center pt-40"></div>
              <div className="fieldsets">
                <ul className="custom-listing">
                  <li>
                    <div>
                      {imgList.map((imgInfo) => (
                        <img src={imgInfo.imgSrc} key={imgInfo.id} alt="" />
                      ))}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="space-h-100" />
            </div>
          )}
          <div className="space-h-100" />
        </div>
      </div>
    </Fragment>
  );
}

export default HelpDetail;
