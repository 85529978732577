import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityList from "../../../components/ActivityList";
import Panel from "../../../components/Panel";
import ActivitySchedule from "../../../components/ActivitySchedule";
import InviteOrgItem from "../../../components/InviteOrgItem";
import ActivityDetail from "../../../components/ActivityDetail";
import ActivityDetailHeader from "../../../components/ActivityDetailHeader";
import axios from "axios";
import CalendarLibrary from "../../../components/CalendarLibrary";
import ScheduleInviteMemberItem from "../../../components/ScheduleInviteMemberItem";
import ConformPopup from "../../common/popup/ConformPopup";
import CheckLoginInfo from "../../../services/login-validation";
import SharePopup from "../../common/popup/SharePopup";
import { checkActivityPageAccess } from "../../../services/page-access-validation";

function ActDetail(props) {
  const { actIdx } = useParams();
  const navigate = useNavigate();

  // DB 데이터
  const [activityData, setActivityData] = useState();
  const [activityScheduleData, setActivityScheduleData] = useState([]);
  const [activityScheduleFilterData, setActivityScheduleFilterData] = useState(
    []
  );
  const [recoOrgData, setRecoOrgData] = useState([]);
  const [recoSoleData, setRecoSoleData] = useState([]);

  const [requestCount, setRequestCount] = useState(null);
  const [helpCount, setHelpCount] = useState(null);

  // 페이징
  const offset = 4;
  const recoOffset = 8;

  const [activitySchedulePage, setActivitySchedulePage] = useState(1);
  const [recoOrgPage, setRecoOrgPage] = useState(1);
  const [recoSolePage, setRecoSolePage] = useState(1);

  const addPage = (setPageFunc, currentPage) => {
    setPageFunc(currentPage + 1);
  };

  // 신청 가능 일정 기간 선택
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // 모집여부 (모집 중, 모집 마감)
  const [recruit, setRecruit] = useState("모집 중");

  const startDateChangeHandler = (e) => {
    //console.log(e.target.value);
    setStartDate(e.target.value);
  };

  const endDateChangeHandler = (e) => {
    //console.log(e.target.value);
    setEndDate(e.target.value);
  };

  const recruitChangeHandler = (e) => {
    //console.log(e.target.value);
    setRecruit(e.target.value);
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        // url로 접근 할 경우
        checkActivityPageAccess(actIdx).then((res) => {
          // 조회 불가능한 회원
          if (res === "ERROR") {
            navigate("/");
          } else if (res === "TRUE") {
            // 활동, 활동상세, 기관정보 조회
            let URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/act/${actIdx}`;
            axios.get(URL).then((res) => {

              if(res.data){
                setActivityData(res.data);
              }else{
                alert("삭제된 활동입니다.");
                navigate(-1);
              }
            });

            // 활동 일정 조회
            URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/org/profile/recruit/mine/${actIdx}`;
            axios.get(URL).then((res) => {
              // console.log("스케줄");
              // console.log(res.data.data);
              setActivityScheduleData(res.data.data);
            });

            URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/count/${actIdx}`;
            axios.get(URL).then((res) => {
              const resJson = res.data;

              setRequestCount(resJson.activityCount);
            });

            // 도움 요청 목록 조회
            URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity_schedule/request/cnt/${actIdx}`;
            axios.get(URL).then((res) => {
              setHelpCount(res.data);
            });

            // 추천 기관 조회
            URL = `${process.env.REACT_APP_USER_SERVICE_API}/matching/org/activity/${actIdx}`;
            axios.get(URL).then((res) => {
              const resJson = res.data.data;
              // console.log(resJson);
              setRecoOrgData(resJson);
            });

            // 추천 회원 조회
            URL = `${process.env.REACT_APP_USER_SERVICE_API}/matching/activity/${actIdx}`;
            axios.get(URL).then((res) => {
              const resJson = res.data.data;
              // console.log(resJson);
              setRecoSoleData(resJson);
            });

            // 서초 코인

            setHideContent(false);
          } else {
            navigate(res, {
              state: {
                isRedirect: true,
              },
            });
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [actIdx]);

  // 일정 필터링
  useEffect(() => {
    if (recruit === "") {
      //onsole.log(`시작일 : ${startDate} / 종료일 : ${endDate}`);
      // 검색 조건이 없을경우
      if (startDate === "" && endDate === "") {
        // 필터없이 전체 스케줄 조회
        //console.log(activityScheduleData);
        setActivityScheduleFilterData(activityScheduleData);
      }
      // 종료일만 있을경우
      else if (startDate === "" && endDate !== "") {
        //console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) => activitySchedule.activityDate <= endDate
          )
        );
      }
      // 시작일만 있을경우
      else if (startDate !== "" && endDate === "") {
        //console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) => startDate <= activitySchedule.activityDate
          )
        );
      }
      // 시작일 종료일 모두 있을경우
      else if (startDate !== "" && endDate !== "") {
        //console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) =>
              startDate <= activitySchedule.activityDate &&
              activitySchedule.activityDate <= endDate
          )
        );
      }
    } else {
      if (startDate === "" && endDate === "") {
        // 필터없이 전체 스케줄 조회
        //console.log(activityScheduleData);
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) => activitySchedule.recruit === recruit
          )
        );
      }
      // 종료일만 있을경우
      else if (startDate === "" && endDate !== "") {
        //console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) =>
              activitySchedule.activityDate <= endDate &&
              activitySchedule.recruit === recruit
          )
        );
      }
      // 시작일만 있을경우
      else if (startDate !== "" && endDate === "") {
        //console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) =>
              startDate <= activitySchedule.activityDate &&
              activitySchedule.recruit === recruit
          )
        );
      }
      // 시작일 종료일 모두 있을경우
      else if (startDate !== "" && endDate !== "") {
        //console.log(activityScheduleData.filter((activitySchedule => endDate < activitySchedule.activityDate)));
        setActivityScheduleFilterData(
          activityScheduleData.filter(
            (activitySchedule) =>
              startDate <= activitySchedule.activityDate &&
              activitySchedule.activityDate <= endDate &&
              activitySchedule.recruit === recruit
          )
        );
      }
    }
  }, [activityScheduleData.length === 0, startDate, endDate, recruit]);

  // 페이지 이동 클릭 이벤트 핸들러
  const aplicationPartClickHandler = () => {
    navigate(`/org/profile/listapplicationpart/${actIdx}`, {
      state: {},
    });
  };

  const requestClickHandler = () => {
    navigate(`/org/profile/listrequest/${actIdx}`, {
      state: {},
    });
  };

  const activityHistoryClickHandler = () => {
    navigate(`/org/profile/listactivityhistory/${actIdx}`, {
      state: {},
    });
  };

  const userSearchClickHandler = () => {
    navigate("/org/profile/usersearch", {
      state: {},
    });
  };

  // 활동 삭제
  const deleteActivity = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/${actIdx}`;
    axios
      .delete(URL)
      .then((res) => {
        const resJson = res.data;
        navigate(`/org/profile/org`);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const tabMenuChangeHander = (e) => {
    resetSchedualSearchData();
  };

  // 일정 필터링 초기화
  const resetSchedualSearchData = () => {
    setStartDate("");
    setEndDate("");
    setRecruit("");
    setActivitySchedulePage(1);
  };

  // 활동자 모집 마감하기
  const closePartRecruitment = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/part_type/${actIdx}`;
    axios
      .get(URL)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // 수요자 모집 마감하기
  const closeBeneRecruitment = () => {
    const URL = `${process.env.REACT_APP_USER_SERVICE_API}/activity/bene_type/${actIdx}`;
    axios
      .get(URL)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <ActivityDetailHeader title="활동 상세" actIdx={actIdx} />
        {/* PAGE CONTENT */}
        <div className="page__content  page_section--with-header-68">
          {/* SLIDER SIMPLE */}
          <div className="page_section pb-40">
            <ActivityDetail
              folder="org"
              activityData={activityData}
              profile={true}
            />
          </div>
          <div className="page_section">
            <div className="form__row mb-40 pt-40">
              <div
                className="checkbox-option checkbox-option--fulls"
                onClick={aplicationPartClickHandler}
              >
                <label>
                  활동 참여 신청 목록 <span>({requestCount})</span>
                </label>
              </div>
              <div
                className="checkbox-option checkbox-option--fulls"
                onClick={requestClickHandler}
              >
                <label>
                  도움 요청 목록 <span>({helpCount})</span>
                </label>
              </div>
              <div
                className="checkbox-option checkbox-option--fulls"
                onClick={activityHistoryClickHandler}
              >
                <label>
                  활동 내역 보기 <span />
                </label>
              </div>
              {/* <div className="checkbox-option checkbox-option--fulls">
                <label>
                  활동 통계 보기 <span />
                </label>
              </div> */}
            </div>
            <div className="page__title-bar mt-48">
              <h1 className="page__title">전체 일정</h1>
            </div>
            <div className="tabs tabs--style1">
              <input
                type="radio"
                name="tabs2"
                className="tabs__radio"
                id="tab1"
                defaultChecked="checked"
                onChange={tabMenuChangeHander}
              />
              <label
                className="tabs__label tabs__left tabs__label--12 hidden"
                htmlFor="tab1"
              >
                리스트
              </label>
              <div className="tabs__content">
                <form id="Form" method="post" action="checkout.html">
                  <div className="form__row">
                    <h2>기간 선택</h2>
                    <div className="form__row d-flex align-items-center justify-space">
                      <input
                        type="date"
                        name="Date"
                        value={startDate}
                        className="form__input required"
                        placeholder="Date"
                        onChange={startDateChangeHandler}
                      />
                      <input
                        type="date"
                        name="Date"
                        value={endDate}
                        className="form__input required ml-10"
                        placeholder="Date"
                        onChange={endDateChangeHandler}
                      />
                    </div>
                    <div className="form__row mb-20">
                      <div className="form__select">
                        <select
                          name="recruit"
                          className="required"
                          value={recruit}
                          onChange={recruitChangeHandler}
                        >
                          <option value="">전체</option>
                          <option value="모집 중">모집 중</option>
                          <option value="모집 마감">모집 마감</option>
                        </select>
                      </div>
                    </div>
                    <div className="cards cards--6" id="loadlist-01">
                      {activityScheduleFilterData &&
                        activityScheduleFilterData
                          .map((schedule, idx) => (
                            <ActivityList
                              key={idx}
                              folder="org"
                              schedule={schedule}
                            />
                          ))
                          .slice(0, activitySchedulePage * offset)}
                      <div className="buttons buttons--centered mb-20">
                        {activityScheduleFilterData.length >
                          activitySchedulePage * offset ? (
                          <div
                            id=""
                            className="button button--more"
                            onClick={() => {
                              addPage(
                                setActivitySchedulePage,
                                activitySchedulePage
                              );
                            }}
                          >
                            더보기
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <input
                type="radio"
                name="tabs2"
                className="tabs__radio"
                id="tab2"
                onChange={tabMenuChangeHander}
              />
              <label
                className="tabs__label tabs__right tabs__label--12"
                htmlFor="tab2"
              >
                캘린더뷰
              </label> */}
              <div className="tabs__content  mt-48">
                <CalendarLibrary folder="org" readonly="true" />
                <div className="form">
                  <form id="Form">
                    <div className="form__row">
                      <div className="page__title-bar mb-20">
                        <h2>일정 ({activityScheduleFilterData.length})</h2>
                        <div className="form__select width-200">
                          <select name="ContactSelect" className="required">
                            <option value="">전체</option>
                            <option value={1}>모집 중</option>
                            <option value={2}>모집 마감</option>
                          </select>
                        </div>
                      </div>
                      {activityScheduleFilterData &&
                        activityScheduleFilterData
                          .map((schedule) => (
                            <ActivitySchedule
                              key={schedule.id}
                              schedule={schedule}
                              activityData={activityData}
                            />
                          ))
                          .slice(0, activitySchedulePage * offset)}
                    </div>
                  </form>
                  <div className="buttons buttons--centered mb-20">
                    {activityScheduleFilterData.length >
                      activitySchedulePage * offset ? (
                      <div
                        id=""
                        className="button button--more"
                        onClick={() => {
                          addPage(
                            setActivitySchedulePage,
                            activitySchedulePage
                          );
                        }}
                      >
                        더보기
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="page__title-bar mt-48">
              <h2>추천 기관/단체 ({recoOrgData.length})</h2>
            </div>
            <div className="cards cards--14">
              {recoOrgData &&
                recoOrgData
                  .map((recoOrg) => (
                    <InviteOrgItem
                      org={recoOrg}
                      invite="알림 보내기"
                      actIdx={actIdx}
                    />
                  ))
                  .slice(0, recoOrgPage * recoOffset)}
              <div className="buttons buttons--centered mb-20">
                {recoOrgData.length > recoOrgPage * recoOffset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setRecoOrgPage, recoOrgPage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="page__title-bar mt-48">
              <h2>추천 회원 ({recoSoleData.length})</h2>
            </div>
            <div className="cards cards--14">
              {recoSoleData &&
                recoSoleData
                  .map((recoSole) => (
                    <ScheduleInviteMemberItem
                      member={recoSole}
                      invite="알림 보내기"
                      actIdx={actIdx}
                    />
                  ))
                  .slice(0, recoSolePage * recoOffset)}
              <div className="buttons buttons--centered mb-20">
                {recoSoleData.length > recoSolePage * recoOffset ? (
                  <div
                    id=""
                    className="button button--more"
                    onClick={() => {
                      addPage(setRecoSolePage, recoSolePage);
                    }}
                  >
                    더보기
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="space-h-100" />
        </div>
      </div>
      {/* PAGE END */}
      <SharePopup
        activityIdx={actIdx}
        beneType={activityData && activityData.beneType}
        partType={activityData && activityData.partType}
      />
      <ConformPopup
        popupType="delete"
        conformClick={deleteActivity}
        text="정말 삭제하시겠습니까?"
      />
      <ConformPopup
        popupType="partclose"
        conformClick={closePartRecruitment}
        text="정말 활동자 모집을 마감하시겠습니까?"
      />
      <ConformPopup
        popupType="beneclose"
        conformClick={closeBeneRecruitment}
        text="정말 수요자 모집을 마감하시겠습니까?"
      />
    </Fragment>
  );
}

export default ActDetail;
