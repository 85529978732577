
const useConfirm = () => {

    const confirm = (message, onConfirm, onCancel = null) => {
        if(!message || !onConfirm || typeof onConfirm !== "function" )
            return ;

        const result = window.confirm(message);

        if(result)
            onConfirm();
        else
            if(onCancel && typeof onCancel === "function")
                onCancel();
    }

    return {confirm};
}

export default useConfirm;