import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import ConformPopup from "../../common/popup/ConformPopup";
import Navigationbar from "../../../components/Navigationbar";
import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import { convertDashToDot } from "../../../util/convert-date-function";
import CheckLoginInfo from "../../../services/login-validation";

function ReportDetail(props) {
  const navigate = useNavigate();
  const { actListIdx } = useParams();

  const [activityInfo, setActivityInfo] = useState(null);
  const [reportInfo, setReportInfo] = useState(null);

  const onClickCompletion = () => {
    updateReportStatus("complete");
  };

  const onClickRequestSupplement = () => {
    updateReportStatus("reject");
  };

  const updateReportStatus = async (status) => {
    let data = new Object();
    data.updatedUid = window.sessionStorage.getItem("idx");
    data.actListIdx = actListIdx;

    const url = `${process.env.REACT_APP_USER_SERVICE_API}/report/${status}`;

    try {
      const response = await axios.put(url, data);

      if (response.status === 201) {
        alert("처리되었습니다.");
      }
    } catch (e) {
      console.log(e);
      alert("처리 중, 오류가 발생하였습니다.");
    }
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onHandleImgError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/assets/images/photos/img-default.jpg`;
  };

  const buttonView = () => {
    switch (reportInfo.status) {
      case "D":
        return (
          <div className="cards cards--6" id="loadlist-01">
            <div className="table card card--style-thumb mb-20">
              <div className="card__details">
                <p className="card__list text-center fc-color-gray">
                  완료 처리된 활동 내역입니다.
                </p>
              </div>
            </div>
          </div>
        );
      case "W":
      case "R":
      default:
        return (
          <div className="form__row mt-40">
            <input
              style={{ cursor: "pointer" }}
              type="button"
              data-popup="conform_cancle"
              className="form__submit button button--gray button--50 open-popup"
              value="활동 보완 요청"
            />
            <input
              type="button"
              style={{ cursor: "pointer" }}
              className="form__submit button button--main button--50 open-popup"
              data-popup="conform_completion"
              value="완료 처리"
            />
          </div>
        );
    }
  };

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getActivityScheduleInfo();
        getActivityReportInfo();

        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
    const getActivityScheduleInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/activity/simple-info/act-list/${actListIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setActivityInfo({
            title: response.data.title,
            activityDate: response.data.activityDate,
            activityTime: response.data.activityTime,
            activityIdx: response.data.activityIdx,
            scheduleIdx: response.data.activityScheduleIdx,
            actListIdx: response.data.actListIdx,
            joinType: response.data.joinType,
          });
        }
      } catch (e) {
        console.log(e);
        alert("존재하지 않는 활동 또는 스케줄 입니다.");
        goBack();
      }
    };

    const getActivityReportInfo = async () => {
      const url = `${process.env.REACT_APP_USER_SERVICE_API}/report/${actListIdx}`;

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const data = response.data;

          setReportInfo({
            idx: data.idx,
            content: data.content,
            images: data.images,
            nickName: data.writerNickname,
            name: data.writerName,
            userIdx: data.writerUserIdx,
            status: data.status,
          });
        }
      } catch (e) {
        console.log(e);
        alert("");
      }
    };
  }, [actListIdx, goBack]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="활동 내역" />
        {/* PAGE CONTENT */}
        {reportInfo && (
          <div className="page__content page_section page__content page_section--with-header-68 ">
            {/* SLIDER SIMPLE */}
            <div className="d-flex justify-space align-items-center">
              <h2 className="mb-0">{activityInfo && activityInfo.title}</h2>
            </div>
            <div className="fieldsets">
              <ul className="custom-listing mb-20">
                <li>
                  <span>
                    활동인원 : <br />
                  </span>
                  <Link
                    to={`/org/otherprofile/sole/${
                      reportInfo && reportInfo.userIdx
                    }`}
                  >
                    {reportInfo && `${reportInfo.nickName}(${reportInfo.name})`}
                  </Link>
                </li>
                <li>
                  <span>
                    활동 일시 : <br />
                  </span>
                  {activityInfo &&
                    `${convertDashToDot(activityInfo.activityDate)} ${
                      activityInfo.activityTime
                    }`}
                </li>
                <li>
                  <span>
                    활동 참여 타입 : <br />
                  </span>
                  {activityInfo &&
                    {
                      Q: "QR코드 인증 참여",
                      A: "자동 참여",
                    }[activityInfo.joinType]}
                </li>
              </ul>
            </div>

            <div className="cards cards--12" id="loadlist-01">
              {reportInfo !== null &&
                reportInfo.images.map((imgInfo) => (
                  <div className="card card--style-thumb" key={imgInfo.idx}>
                    <div className="card__thumb card__thumb--round-corners">
                      {/* <Link to="/org/activity/detailcarriedout">
                <img src="/assets/images/food/img-default.jpg" alt="" />
              </Link> */}

                      <img
                        src={`${process.env.REACT_APP_USER_SERVICE_IMG_PATH}${imgInfo.imgUrl}`}
                        alt=""
                        onError={(e) => onHandleImgError(e)}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="form mt-40">
              <form>
                <h4>{reportInfo && reportInfo.content}</h4>
              </form>
            </div>
            {reportInfo && buttonView()}
            <div className="space-h-100" />
          </div>
        )}
        {/* PAGE END */}
        <Navigationbar />
        <ConformPopup
          conformClick={onClickRequestSupplement}
          text="활동 내역을 보완 요청하시겠습니까?"
          popupType="cancle"
        />
        <ConformPopup
          conformClick={onClickCompletion}
          text="활동 완료 처리 후에는 완료 처리 취소가 불가능 합니다. 정말 완료 처리 하시겠습니까? "
          popupType="completion"
        />
      </div>
    </Fragment>
  );
}

export default ReportDetail;
