import React, { Fragment, useEffect, useState, useCallback } from "react";
import axios from "axios";

import Panel from "../../../components/Panel";
import SubHeader from "../../../components/SubHeader";
import UserGuideList from "../../../components/UserGuideList";
import CheckLoginInfo from "../../../services/login-validation";

function Help() {
  const [totalRows, setTotalRows] = useState(null);
  const [userGuideList, setUserGuideList] = useState(null);

  const getFaqList = useCallback(async () => {
    const url = `${process.env.REACT_APP_COMMUNITY_SERVICE_API}/api/v1/community`;

    try {
      const response = await axios.get(url, {
        params: {
          type: "GUIDE",
          page: 1,
          rows: 20,
          state: "POST",
        },
      });

      if (response.status === 200) {
        const { totalRows, data } = response.data;

        let ary = [];

        for (let i = 0; i < data.length; i++) {
          ary.push({
            idx: data[i].idx, // idx
            title: data[i].title, // 제목
          });
        }

        setTotalRows(totalRows);
        setUserGuideList(ary);
      }
    } catch (e) {
      alert("자주 묻는 질문(FAQ) 목록 조회중, 오류가 발생하였습니다.");
      console.log(e);
    }
  }, []);

  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    CheckLoginInfo()
      .then(() => {
        getFaqList();
        setHideContent(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [getFaqList]);

  if (hideContent) {
    return null;
  }

  return (
    <Fragment>
      <Panel />
      <div className="page page-section page--main" data-page="shop">
        {/* HEADER */}
        <SubHeader title="사용자 가이드" />
        {/* PAGE CONTENT */}
        <div className="page__content page_section page__content page_section--with-header-68">
          <div className="page__title-bar">
            <h2>사용자 가이드 ({totalRows})</h2>
          </div>
          {userGuideList && <UserGuideList userGuideList={userGuideList} />}
        </div>
      </div>
    </Fragment>
  );
}

export default Help;
