import React, { useState } from "react";
import ActivityContext from "../../context/Activity.context";

const ActivityProvider = ({ children }) => {
  const saveActImg = (actImg) => {
    setActivity(prevState => {
      return {
        ...prevState,
        actImg: actImg,
      };
    });
  };

  const initActivity = (activity, activityDetail) => {
    setActivity(prevState => {
      return {
        ...prevState,
        activity: activity,
        activityDetail: activityDetail,
        originalActivity: activity,
        originalActivityDetail: activityDetail
      };
    });
  };

  const saveActivity = (activity, activityDetail) => {
    setActivity(prevState => {
      return {
        ...prevState,
        activity: activity,
        activityDetail: activityDetail
      };
    });
  };

  const clearActivity = (activity, activityDetail) => {
    setActivity(prevState => {
      return {
        ...prevState,
        activity: {
          // 활동명
          title: '',
          // 카테고리 번호
          categoryId: '',
          // 활동타입
          // 온라인 일 시 O(nline) 오프라인일 시 F(fline)'
          activityType: '',
          // 장소2
          address1: '',
          // 장소2
          address2: '',
          // 수요자 모집
          // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
          beneType: '',
          // 활동자 모집
          // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
          partType: '',
          // 공개 여부
          // 공개일 시 O(pen), 비공개일 시 C(losed) 
          isPrivate: '',
        },
        activityDetail: {
          // 반복
          isRepeat: '',
          // 반복종료날짜
          repeatDate: '',
          // 소요시간
          totalTime: '',
          // 모집 마감
          recruitDate: '',
          // 필요인원
          recruitNum: '',
          // 상세
          memo: '',
          // 활동참여방식
          joinType: ''
        },
        originalActivity: {
          // 활동명
          title: '',
          // 카테고리 번호
          categoryId: '',
          // 활동타입
          // 온라인 일 시 O(nline) 오프라인일 시 F(fline)'
          activityType: '',
          // 장소2
          address2: '',
          // 수요자 모집
          // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
          beneType: '',
          // 활동자 모집
          // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
          partType: '',
          // 공개 여부
          // 공개일 시 O(pen), 비공개일 시 C(losed) 
          isPrivate: '',
        },
        originalActivityDetail: {
          // 반복
          isRepeat: '',
          // 반복종료날짜
          repeatDate: '',
          // 소요시간
          totalTime: '',
          // 모집 마감
          recruitDate: '',
          // 필요인원
          recruitNum: '',
          // 상세
          memo: '',
          // 활동참여방식
          joinType: ''
        },
      };
    });
  };

  const initialState = {
    actImg: "",
    activity: {
      // 활동명
      title: '',
      // 카테고리 번호
      categoryId: '',
      // 활동타입
      // 온라인 일 시 O(nline) 오프라인일 시 F(fline)'
      activityType: '',
      // 장소2
      address2: '',
      // 수요자 모집
      // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
      beneType: '',
      // 활동자 모집
      // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
      partType: '',
      // 공개 여부
      // 공개일 시 O(pen), 비공개일 시 C(losed) 
      isPrivate: '',
    },
    activityDetail: {
      // 반복
      isRepeat: '',
      // 반복종료날짜
      repeatDate: '',
      // 소요시간
      totalTime: '',
      // 모집 마감
      recruitDate: '',
      // 필요인원
      recruitNum: '',
      // 상세
      memo: '',
      // 활동참여방식
      joinType: ''
    },
    originalActivity: {
      // 활동명
      title: '',
      // 카테고리 번호
      categoryId: '',
      // 활동타입
      // 온라인 일 시 O(nline) 오프라인일 시 F(fline)'
      activityType: '',
      // 장소2
      address2: '',
      // 수요자 모집
      // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
      beneType: '',
      // 활동자 모집
      // '모집안함일시 N(O) 전체일 시 A(ll), 일반일 시  U(ser), 기관일 시 O(rg)'
      partType: '',
      // 공개 여부
      // 공개일 시 O(pen), 비공개일 시 C(losed) 
      isPrivate: '',
    },
    originalActivityDetail: {
      // 반복
      isRepeat: '',
      // 반복종료날짜
      repeatDate: '',
      // 소요시간
      totalTime: '',
      // 모집 마감
      recruitDate: '',
      // 필요인원
      recruitNum: '',
      // 상세
      memo: '',
      // 활동참여방식
      joinType: ''
    },
    saveActImg,
    saveActivity,
    initActivity,
    clearActivity,
  };
  const [activity, setActivity] = useState(initialState);

  return (
    <ActivityContext.Provider value={activity}>
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityProvider;