import React, { Fragment, useEffect, useState, useContext } from "react";
import ScheduleContext from "../context/Schedule.context";

function TimeItem({ item, index, checkedItemHandler, setSchedule, chkClick, nowdate }) {

	const [cnt, setCnt] = useState(0);
	const [bChecked, setChecked] = useState(false);
	const { scheduleList, addSchedule, clearSchedule, deleteScheduleList, setDeleteScheduleList } = useContext(ScheduleContext);

	const minusClick = ({ target }) => {
		if (cnt !== 0) {
			setCnt(cnt - 1)
			setChecked(true);
			checkedItemHandler(item.id, target.checked);
		}
		if (cnt === 1) {
			setCnt(cnt - 1)
			setChecked(false);
		}
	}

	const plusClick = ({ target }) => {
		setCnt(cnt + 1)
		setChecked(true);
		checkedItemHandler(item.id, target.checked);
	}

	const checkHandler = ({ target }) => {
		if (cnt !== 0) {
			setChecked(true);
			checkedItemHandler(item.id, target.checked);
		}
	};

	useEffect(() => {
		if (chkClick) {
			if (cnt > 0) {
				let actWhen = {
					activityDate: nowdate,
					activityTime: item,
					recruitNum: cnt,
					createdUid: sessionStorage.idx
				};

				deleteScheduleList.delete(`${nowdate},${item},${cnt}`);
				setDeleteScheduleList(deleteScheduleList);
				console.log(deleteScheduleList);

				addSchedule(actWhen);
			}
		}
	}, [chkClick])

	return (
		<Fragment>
			<div className="cart cart--page">
				<div className="swiper-container slider-init-swipe cart__item c1 swiper-container-initialized swiper-container-horizontal" id={index}>
					<div className="checkbox-option checkbox-option--full">
						<input type="checkbox" name="checkboxoption1" id={`cp${index}`} checked={bChecked} onChange={(e) => checkHandler(e)} />
						<label htmlFor={`cp${index}`}>
							<span>{item}</span>
							<div className="float-right mt-m-15">
								필요인원 :
								<input type="button" value="-" className="quantity__button quantity__button--minus" field={`quantity${index}`} onClick={minusClick} id={`cp${index}`} checked={bChecked} onChange={(e) => checkHandler(e)} />
								<input type="text" name={`quantity${index}`} value={cnt} className="quantity__input" />
								<input type="button" value="+" className="quantity__button quantity__button--plus" field={`quantity${index}`} onClick={plusClick} id={`cp${index}`} checked={bChecked} onChange={(e) => checkHandler(e)} />
							</div>
						</label>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default TimeItem;